export const conditionNames = {
  new: "New",
  used: "Used",
  demo: "Dealer Demo",
};

export type Options = {
  allowUnofficialNames?: boolean;
  odometer?: number;
};

export const getConditionName = (condition: string, options: Options = {}) => {
  if (
    options.allowUnofficialNames &&
    typeof options.odometer === "number" &&
    options.odometer < 2000 &&
    (condition === "used" || condition === "demo")
  ) {
    return "Used / Near New";
  }

  return conditionNames[condition] || condition;
};
