import { PricingConfigV2 } from "@vyro-x/pricing-client";
import { useShowroomSettings } from "../showrooms/useShowroomSettings";

type PricingLabelProps = {
  pricingConfig: PricingConfigV2;
  options?: {
    driveawaySuffix?: string;
  };
};

export const PricingLabel = ({ pricingConfig, options }: PricingLabelProps) => {
  const settings = useShowroomSettings(['pricing_excl_on_roads_label', 'pricing_drive_away_label']);
  return <>{getPricingLabel(pricingConfig, settings, options)}</>;
};

export const getPricingLabel = (
  pricingConfig: PricingConfigV2,
  settings: {
    pricing_excl_on_roads_label: string;
    pricing_drive_away_label: string;
  },
  options?: {
    driveawaySuffix?: string;
  }
): string => {
  const res = pricingConfig?.isExclOnRoads
    ? settings.pricing_excl_on_roads_label || "From"
    : settings.pricing_drive_away_label || "Driveaway";

  return options?.driveawaySuffix ? `${res} ${options?.driveawaySuffix}` : res;
};
