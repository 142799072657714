import { usePricingCodes } from './pricingCodes/usePricingCodes';

export type UseGovtIncentivesArgs = {
  fuelType: string;
  driveAwayPrice?: number;
  dutiableValue?: number;
  onRoadValue?: number;
  condition: string;
};

type Incentive = {
  name: string;
  code: string;
  value?: number;
};

export type GetGovtIncentivesArgs = {
  fuelType: string;
  driveAwayPrice?: number;
  dutiableValue?: number;
  onRoadValue?: number;
  condition: string;
  country: string;
  region: string;
};

export const getGovtIncentives = ({
  fuelType,
  driveAwayPrice,
  onRoadValue,
  dutiableValue,
  condition,
  country,
  region,
}: GetGovtIncentivesArgs): Array<Incentive> => {
  if (fuelType !== 'electric') {
    return [];
  }

  if (!country) {
    return [];
  }

  if (country === 'AU') {
    // NSW
    if (region === 'NSW') {
      return [
        condition === 'new' &&
          dutiableValue &&
          dutiableValue < 68_750_00 && {
            name: '$3,000 rebate',
            code: 'au-nsw-3000-rebate',
          },

        dutiableValue &&
          dutiableValue < 78_000_00 && {
            name: 'Free stamp duty',
            code: 'au-nsw-free-stamp-duty',
          },
        dutiableValue &&
          dutiableValue < 84_916_00 && {
            name: 'FBT Exemption (Novated Leasing)',
            code: 'au-fbt-exemption',
          },
      ].filter(Boolean);
    }

    // ACT
    if (region === 'ACT') {
      return [
        { name: 'Free registration', code: 'au-act-free-registration' },
        condition === 'new' && {
          name: 'Free stamp duty',
          code: 'au-act-free-stamp-duty',
        },
        dutiableValue &&
          dutiableValue < 84_916_00 && {
            name: 'Up to $15k interest-free (Sustainable Household Scheme)',
            code: 'au-act-15000-shs',
          },
        dutiableValue &&
          dutiableValue < 84_916_00 && {
            //REVIEW - Is this overriding the prev value?
            name: 'FBT Exemption (Novated Leasing)',
            code: 'au-fbt-exemption',
          },
      ].filter(Boolean);
    }

    // SA
    if (region === 'SA') {
      return [
        ['new', 'demo'].includes(condition) &&
          dutiableValue &&
          dutiableValue < 68_750_00 && {
            name: 'Free registration',
            code: 'au-sa-free-registration',
          },
        ['new', 'demo'].includes(condition) &&
          dutiableValue &&
          dutiableValue < 68_750_00 && {
            name: '$3,000 ',
            code: 'au-sa-3000-subsidy',
          },
        dutiableValue &&
          dutiableValue < 84_916_00 && {
            name: 'FBT Exemption (Novated Leasing)',
            code: 'au-fbt-exemption',
          },
      ].filter(Boolean);
    }
  }

  return [];
};

export const useGovtIncentives = (args: UseGovtIncentivesArgs): Array<Incentive> => {
  const { pricingCode } = usePricingCodes();

  return getGovtIncentives({ ...args, country: pricingCode?.country, region: pricingCode?.region });
};
