import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  date: any;
  geography: any;
  geometry: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};


export type ApiKeyDataOutput = {
  __typename?: 'ApiKeyDataOutput';
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
};

export type ApiKeyOutput = {
  __typename?: 'ApiKeyOutput';
  code: Scalars['String'];
  data: ApiKeyDataOutput;
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CancelVehicleOrderOutput = {
  __typename?: 'CancelVehicleOrderOutput';
  id: Scalars['uuid'];
};

export type GetAutoGrabValuationOutput = {
  __typename?: 'GetAutoGrabValuationOutput';
  success: Scalars['Boolean'];
};

export type GetAutoGrabVehicleByRegNumberOutput = {
  __typename?: 'GetAutoGrabVehicleByRegNumberOutput';
  success: Scalars['Boolean'];
};

export type GetAutoGrabVehiclesBySearchOutput = {
  __typename?: 'GetAutoGrabVehiclesBySearchOutput';
  autograb_vehicle_id: Scalars['String'];
  title: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LoopitSubscriptionUrl = {
  __typename?: 'LoopitSubscriptionUrl';
  url: Scalars['String'];
};

export type MediaGetSignedDownloadOutput = {
  __typename?: 'MediaGetSignedDownloadOutput';
  url: Scalars['String'];
};

export type MediaSignedUploadOutput = {
  __typename?: 'MediaSignedUploadOutput';
  base_url: Scalars['String'];
  post_fields: Scalars['jsonb'];
  post_url: Scalars['String'];
};

export type PaymentUrlOutput = {
  __typename?: 'PaymentUrlOutput';
  payment_url: Scalars['String'];
};

export type SetVehicleByAutograbVehicleIdOutput = {
  __typename?: 'SetVehicleByAutograbVehicleIdOutput';
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "activities" */
export type Activities = {
  __typename?: 'activities';
  /** An object relationship */
  action_by?: Maybe<Users>;
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  eventuated_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  note?: Maybe<Notes>;
  note_id?: Maybe<Scalars['uuid']>;
  rental_reservation?: Maybe<Rental_Reservations>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  submitted_form_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  title: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "activities" */
export type Activities_Aggregate = {
  __typename?: 'activities_aggregate';
  aggregate?: Maybe<Activities_Aggregate_Fields>;
  nodes: Array<Activities>;
};

export type Activities_Aggregate_Bool_Exp = {
  count?: Maybe<Activities_Aggregate_Bool_Exp_Count>;
};

export type Activities_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Activities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Activities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "activities" */
export type Activities_Aggregate_Fields = {
  __typename?: 'activities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Activities_Max_Fields>;
  min?: Maybe<Activities_Min_Fields>;
};


/** aggregate fields of "activities" */
export type Activities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Activities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "activities" */
export type Activities_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Activities_Max_Order_By>;
  min?: Maybe<Activities_Min_Order_By>;
};

/** input type for inserting array relation for remote table "activities" */
export type Activities_Arr_Rel_Insert_Input = {
  data: Array<Activities_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Activities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "activities". All fields are combined with a logical 'AND'. */
export type Activities_Bool_Exp = {
  _and?: Maybe<Array<Activities_Bool_Exp>>;
  _not?: Maybe<Activities_Bool_Exp>;
  _or?: Maybe<Array<Activities_Bool_Exp>>;
  action_by?: Maybe<Users_Bool_Exp>;
  action_by_id?: Maybe<Uuid_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  eventuated_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<Notes_Bool_Exp>;
  note_id?: Maybe<Uuid_Comparison_Exp>;
  rental_reservation_id?: Maybe<Uuid_Comparison_Exp>;
  submitted_form_id?: Maybe<Uuid_Comparison_Exp>;
  test_drive_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "activities" */
export enum Activities_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivitiesPkey = 'activities_pkey'
}

/** input type for inserting data into table "activities" */
export type Activities_Insert_Input = {
  action_by?: Maybe<Users_Obj_Rel_Insert_Input>;
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventuated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Notes_Obj_Rel_Insert_Input>;
  note_id?: Maybe<Scalars['uuid']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  submitted_form_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Activities_Max_Fields = {
  __typename?: 'activities_max_fields';
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventuated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note_id?: Maybe<Scalars['uuid']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  submitted_form_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "activities" */
export type Activities_Max_Order_By = {
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  eventuated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  submitted_form_id?: Maybe<Order_By>;
  test_drive_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Activities_Min_Fields = {
  __typename?: 'activities_min_fields';
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventuated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note_id?: Maybe<Scalars['uuid']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  submitted_form_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "activities" */
export type Activities_Min_Order_By = {
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  eventuated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  submitted_form_id?: Maybe<Order_By>;
  test_drive_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "activities" */
export type Activities_Mutation_Response = {
  __typename?: 'activities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Activities>;
};

/** on_conflict condition type for table "activities" */
export type Activities_On_Conflict = {
  constraint: Activities_Constraint;
  update_columns?: Array<Activities_Update_Column>;
  where?: Maybe<Activities_Bool_Exp>;
};

/** Ordering options when selecting data from "activities". */
export type Activities_Order_By = {
  action_by?: Maybe<Users_Order_By>;
  action_by_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  eventuated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Notes_Order_By>;
  note_id?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  submitted_form_id?: Maybe<Order_By>;
  test_drive_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: activities */
export type Activities_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "activities" */
export enum Activities_Select_Column {
  /** column name */
  ActionById = 'action_by_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventuatedAt = 'eventuated_at',
  /** column name */
  Id = 'id',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  RentalReservationId = 'rental_reservation_id',
  /** column name */
  SubmittedFormId = 'submitted_form_id',
  /** column name */
  TestDriveId = 'test_drive_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "activities" */
export type Activities_Set_Input = {
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventuated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note_id?: Maybe<Scalars['uuid']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  submitted_form_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "activities" */
export type Activities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Activities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Activities_Stream_Cursor_Value_Input = {
  /** The ID of the user who performed the action resulting in this activity record. */
  action_by_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  eventuated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note_id?: Maybe<Scalars['uuid']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  submitted_form_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "activities" */
export enum Activities_Update_Column {
  /** column name */
  ActionById = 'action_by_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventuatedAt = 'eventuated_at',
  /** column name */
  Id = 'id',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  RentalReservationId = 'rental_reservation_id',
  /** column name */
  SubmittedFormId = 'submitted_form_id',
  /** column name */
  TestDriveId = 'test_drive_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Activities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Activities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Activities_Bool_Exp;
};

/** columns and relationships of "api_clients" */
export type Api_Clients = {
  __typename?: 'api_clients';
  /** An object relationship */
  app: Apps;
  app_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  encrypted_secret: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "api_clients" */
export type Api_ClientsSessionsArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


/** columns and relationships of "api_clients" */
export type Api_ClientsSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};

/** aggregated selection of "api_clients" */
export type Api_Clients_Aggregate = {
  __typename?: 'api_clients_aggregate';
  aggregate?: Maybe<Api_Clients_Aggregate_Fields>;
  nodes: Array<Api_Clients>;
};

export type Api_Clients_Aggregate_Bool_Exp = {
  count?: Maybe<Api_Clients_Aggregate_Bool_Exp_Count>;
};

export type Api_Clients_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Api_Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Api_Clients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "api_clients" */
export type Api_Clients_Aggregate_Fields = {
  __typename?: 'api_clients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Api_Clients_Max_Fields>;
  min?: Maybe<Api_Clients_Min_Fields>;
};


/** aggregate fields of "api_clients" */
export type Api_Clients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Api_Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "api_clients" */
export type Api_Clients_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Api_Clients_Max_Order_By>;
  min?: Maybe<Api_Clients_Min_Order_By>;
};

/** input type for inserting array relation for remote table "api_clients" */
export type Api_Clients_Arr_Rel_Insert_Input = {
  data: Array<Api_Clients_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Api_Clients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "api_clients". All fields are combined with a logical 'AND'. */
export type Api_Clients_Bool_Exp = {
  _and?: Maybe<Array<Api_Clients_Bool_Exp>>;
  _not?: Maybe<Api_Clients_Bool_Exp>;
  _or?: Maybe<Array<Api_Clients_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  encrypted_secret?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  sessions?: Maybe<Sessions_Bool_Exp>;
  sessions_aggregate?: Maybe<Sessions_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "api_clients" */
export enum Api_Clients_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApiClientsPkey = 'api_clients_pkey'
}

/** input type for inserting data into table "api_clients" */
export type Api_Clients_Insert_Input = {
  app?: Maybe<Apps_Obj_Rel_Insert_Input>;
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_secret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sessions?: Maybe<Sessions_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Api_Clients_Max_Fields = {
  __typename?: 'api_clients_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_secret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "api_clients" */
export type Api_Clients_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  encrypted_secret?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Api_Clients_Min_Fields = {
  __typename?: 'api_clients_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_secret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "api_clients" */
export type Api_Clients_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  encrypted_secret?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "api_clients" */
export type Api_Clients_Mutation_Response = {
  __typename?: 'api_clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Api_Clients>;
};

/** input type for inserting object relation for remote table "api_clients" */
export type Api_Clients_Obj_Rel_Insert_Input = {
  data: Api_Clients_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Api_Clients_On_Conflict>;
};

/** on_conflict condition type for table "api_clients" */
export type Api_Clients_On_Conflict = {
  constraint: Api_Clients_Constraint;
  update_columns?: Array<Api_Clients_Update_Column>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "api_clients". */
export type Api_Clients_Order_By = {
  app?: Maybe<Apps_Order_By>;
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  encrypted_secret?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sessions_aggregate?: Maybe<Sessions_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: api_clients */
export type Api_Clients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "api_clients" */
export enum Api_Clients_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EncryptedSecret = 'encrypted_secret',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "api_clients" */
export type Api_Clients_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_secret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "api_clients" */
export type Api_Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Clients_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  encrypted_secret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "api_clients" */
export enum Api_Clients_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EncryptedSecret = 'encrypted_secret',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Api_Clients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Api_Clients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Api_Clients_Bool_Exp;
};

/** columns and relationships of "app_actions" */
export type App_Actions = {
  __typename?: 'app_actions';
  /** An object relationship */
  app: Apps;
  app_id: Scalars['uuid'];
  /** An object relationship */
  app_version?: Maybe<App_Versions>;
  config: Scalars['jsonb'];
  id: Scalars['uuid'];
  type: Scalars['String'];
  version_num: Scalars['String'];
};


/** columns and relationships of "app_actions" */
export type App_ActionsConfigArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "app_actions" */
export type App_Actions_Aggregate = {
  __typename?: 'app_actions_aggregate';
  aggregate?: Maybe<App_Actions_Aggregate_Fields>;
  nodes: Array<App_Actions>;
};

export type App_Actions_Aggregate_Bool_Exp = {
  count?: Maybe<App_Actions_Aggregate_Bool_Exp_Count>;
};

export type App_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<App_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<App_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "app_actions" */
export type App_Actions_Aggregate_Fields = {
  __typename?: 'app_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_Actions_Max_Fields>;
  min?: Maybe<App_Actions_Min_Fields>;
};


/** aggregate fields of "app_actions" */
export type App_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app_actions" */
export type App_Actions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Actions_Max_Order_By>;
  min?: Maybe<App_Actions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Actions_Append_Input = {
  config?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "app_actions" */
export type App_Actions_Arr_Rel_Insert_Input = {
  data: Array<App_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<App_Actions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "app_actions". All fields are combined with a logical 'AND'. */
export type App_Actions_Bool_Exp = {
  _and?: Maybe<Array<App_Actions_Bool_Exp>>;
  _not?: Maybe<App_Actions_Bool_Exp>;
  _or?: Maybe<Array<App_Actions_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  app_version?: Maybe<App_Versions_Bool_Exp>;
  config?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  version_num?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_actions" */
export enum App_Actions_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppActionsPkey = 'app_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Actions_Delete_At_Path_Input = {
  config?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Actions_Delete_Elem_Input = {
  config?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Actions_Delete_Key_Input = {
  config?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "app_actions" */
export type App_Actions_Insert_Input = {
  app?: Maybe<Apps_Obj_Rel_Insert_Input>;
  app_id?: Maybe<Scalars['uuid']>;
  app_version?: Maybe<App_Versions_Obj_Rel_Insert_Input>;
  config?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Actions_Max_Fields = {
  __typename?: 'app_actions_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "app_actions" */
export type App_Actions_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type App_Actions_Min_Fields = {
  __typename?: 'app_actions_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "app_actions" */
export type App_Actions_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** response of any mutation on the table "app_actions" */
export type App_Actions_Mutation_Response = {
  __typename?: 'app_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Actions>;
};

/** on_conflict condition type for table "app_actions" */
export type App_Actions_On_Conflict = {
  constraint: App_Actions_Constraint;
  update_columns?: Array<App_Actions_Update_Column>;
  where?: Maybe<App_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "app_actions". */
export type App_Actions_Order_By = {
  app?: Maybe<Apps_Order_By>;
  app_id?: Maybe<Order_By>;
  app_version?: Maybe<App_Versions_Order_By>;
  config?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** primary key columns input for table: app_actions */
export type App_Actions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Actions_Prepend_Input = {
  config?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "app_actions" */
export enum App_Actions_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Config = 'config',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  VersionNum = 'version_num'
}

/** input type for updating data in table "app_actions" */
export type App_Actions_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  config?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "app_actions" */
export type App_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Actions_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  config?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** update columns of table "app_actions" */
export enum App_Actions_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Config = 'config',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  VersionNum = 'version_num'
}

export type App_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<App_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<App_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<App_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<App_Actions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<App_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<App_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Actions_Bool_Exp;
};

/** columns and relationships of "app_events" */
export type App_Events = {
  __typename?: 'app_events';
  app_id: Scalars['uuid'];
  /** An object relationship */
  app_version?: Maybe<App_Versions>;
  /** Whether to compress the signed event payload */
  compress: Scalars['Boolean'];
  endpoint: Scalars['String'];
  entity: Scalars['String'];
  id: Scalars['uuid'];
  operation: Scalars['String'];
  version_num: Scalars['String'];
};

/** aggregated selection of "app_events" */
export type App_Events_Aggregate = {
  __typename?: 'app_events_aggregate';
  aggregate?: Maybe<App_Events_Aggregate_Fields>;
  nodes: Array<App_Events>;
};

export type App_Events_Aggregate_Bool_Exp = {
  bool_and?: Maybe<App_Events_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<App_Events_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<App_Events_Aggregate_Bool_Exp_Count>;
};

export type App_Events_Aggregate_Bool_Exp_Bool_And = {
  arguments: App_Events_Select_Column_App_Events_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<App_Events_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type App_Events_Aggregate_Bool_Exp_Bool_Or = {
  arguments: App_Events_Select_Column_App_Events_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<App_Events_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type App_Events_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<App_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<App_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "app_events" */
export type App_Events_Aggregate_Fields = {
  __typename?: 'app_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_Events_Max_Fields>;
  min?: Maybe<App_Events_Min_Fields>;
};


/** aggregate fields of "app_events" */
export type App_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app_events" */
export type App_Events_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Events_Max_Order_By>;
  min?: Maybe<App_Events_Min_Order_By>;
};

/** input type for inserting array relation for remote table "app_events" */
export type App_Events_Arr_Rel_Insert_Input = {
  data: Array<App_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<App_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "app_events". All fields are combined with a logical 'AND'. */
export type App_Events_Bool_Exp = {
  _and?: Maybe<Array<App_Events_Bool_Exp>>;
  _not?: Maybe<App_Events_Bool_Exp>;
  _or?: Maybe<Array<App_Events_Bool_Exp>>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  app_version?: Maybe<App_Versions_Bool_Exp>;
  compress?: Maybe<Boolean_Comparison_Exp>;
  endpoint?: Maybe<String_Comparison_Exp>;
  entity?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  operation?: Maybe<String_Comparison_Exp>;
  version_num?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_events" */
export enum App_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppEventsIdKey = 'app_events_id_key',
  /** unique or primary key constraint on columns "id" */
  AppEventsPkey = 'app_events_pkey'
}

/** input type for inserting data into table "app_events" */
export type App_Events_Insert_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  app_version?: Maybe<App_Versions_Obj_Rel_Insert_Input>;
  /** Whether to compress the signed event payload */
  compress?: Maybe<Scalars['Boolean']>;
  endpoint?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Events_Max_Fields = {
  __typename?: 'app_events_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  endpoint?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "app_events" */
export type App_Events_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  endpoint?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  operation?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type App_Events_Min_Fields = {
  __typename?: 'app_events_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  endpoint?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "app_events" */
export type App_Events_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  endpoint?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  operation?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** response of any mutation on the table "app_events" */
export type App_Events_Mutation_Response = {
  __typename?: 'app_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Events>;
};

/** on_conflict condition type for table "app_events" */
export type App_Events_On_Conflict = {
  constraint: App_Events_Constraint;
  update_columns?: Array<App_Events_Update_Column>;
  where?: Maybe<App_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "app_events". */
export type App_Events_Order_By = {
  app_id?: Maybe<Order_By>;
  app_version?: Maybe<App_Versions_Order_By>;
  compress?: Maybe<Order_By>;
  endpoint?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  operation?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** primary key columns input for table: app_events */
export type App_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "app_events" */
export enum App_Events_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Compress = 'compress',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Entity = 'entity',
  /** column name */
  Id = 'id',
  /** column name */
  Operation = 'operation',
  /** column name */
  VersionNum = 'version_num'
}

/** select "app_events_aggregate_bool_exp_bool_and_arguments_columns" columns of table "app_events" */
export enum App_Events_Select_Column_App_Events_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Compress = 'compress'
}

/** select "app_events_aggregate_bool_exp_bool_or_arguments_columns" columns of table "app_events" */
export enum App_Events_Select_Column_App_Events_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Compress = 'compress'
}

/** input type for updating data in table "app_events" */
export type App_Events_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  /** Whether to compress the signed event payload */
  compress?: Maybe<Scalars['Boolean']>;
  endpoint?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "app_events" */
export type App_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Events_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  /** Whether to compress the signed event payload */
  compress?: Maybe<Scalars['Boolean']>;
  endpoint?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** update columns of table "app_events" */
export enum App_Events_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Compress = 'compress',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Entity = 'entity',
  /** column name */
  Id = 'id',
  /** column name */
  Operation = 'operation',
  /** column name */
  VersionNum = 'version_num'
}

export type App_Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<App_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Events_Bool_Exp;
};

/** columns and relationships of "app_inputs" */
export type App_Inputs = {
  __typename?: 'app_inputs';
  /** An object relationship */
  app: Apps;
  app_id: Scalars['uuid'];
  /** An object relationship */
  app_version?: Maybe<App_Versions>;
  default_value?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  options?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  showroom_apps_inputs: Array<Showroom_Apps_Inputs>;
  /** An aggregate relationship */
  showroom_apps_inputs_aggregate: Showroom_Apps_Inputs_Aggregate;
  type: Scalars['String'];
  version_num: Scalars['String'];
};


/** columns and relationships of "app_inputs" */
export type App_InputsDefault_ValueArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "app_inputs" */
export type App_InputsOptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "app_inputs" */
export type App_InputsShowroom_Apps_InputsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


/** columns and relationships of "app_inputs" */
export type App_InputsShowroom_Apps_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};

/** aggregated selection of "app_inputs" */
export type App_Inputs_Aggregate = {
  __typename?: 'app_inputs_aggregate';
  aggregate?: Maybe<App_Inputs_Aggregate_Fields>;
  nodes: Array<App_Inputs>;
};

export type App_Inputs_Aggregate_Bool_Exp = {
  count?: Maybe<App_Inputs_Aggregate_Bool_Exp_Count>;
};

export type App_Inputs_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<App_Inputs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<App_Inputs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "app_inputs" */
export type App_Inputs_Aggregate_Fields = {
  __typename?: 'app_inputs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_Inputs_Max_Fields>;
  min?: Maybe<App_Inputs_Min_Fields>;
};


/** aggregate fields of "app_inputs" */
export type App_Inputs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Inputs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app_inputs" */
export type App_Inputs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Inputs_Max_Order_By>;
  min?: Maybe<App_Inputs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Inputs_Append_Input = {
  default_value?: Maybe<Scalars['jsonb']>;
  options?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "app_inputs" */
export type App_Inputs_Arr_Rel_Insert_Input = {
  data: Array<App_Inputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<App_Inputs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "app_inputs". All fields are combined with a logical 'AND'. */
export type App_Inputs_Bool_Exp = {
  _and?: Maybe<Array<App_Inputs_Bool_Exp>>;
  _not?: Maybe<App_Inputs_Bool_Exp>;
  _or?: Maybe<Array<App_Inputs_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  app_version?: Maybe<App_Versions_Bool_Exp>;
  default_value?: Maybe<Jsonb_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  options?: Maybe<Jsonb_Comparison_Exp>;
  showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
  showroom_apps_inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Bool_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  version_num?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_inputs" */
export enum App_Inputs_Constraint {
  /** unique or primary key constraint on columns "app_id", "version_num", "name" */
  AppInputsPkey = 'app_inputs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Inputs_Delete_At_Path_Input = {
  default_value?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Inputs_Delete_Elem_Input = {
  default_value?: Maybe<Scalars['Int']>;
  options?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Inputs_Delete_Key_Input = {
  default_value?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "app_inputs" */
export type App_Inputs_Insert_Input = {
  app?: Maybe<Apps_Obj_Rel_Insert_Input>;
  app_id?: Maybe<Scalars['uuid']>;
  app_version?: Maybe<App_Versions_Obj_Rel_Insert_Input>;
  default_value?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Arr_Rel_Insert_Input>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Inputs_Max_Fields = {
  __typename?: 'app_inputs_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "app_inputs" */
export type App_Inputs_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type App_Inputs_Min_Fields = {
  __typename?: 'app_inputs_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "app_inputs" */
export type App_Inputs_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** response of any mutation on the table "app_inputs" */
export type App_Inputs_Mutation_Response = {
  __typename?: 'app_inputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Inputs>;
};

/** input type for inserting object relation for remote table "app_inputs" */
export type App_Inputs_Obj_Rel_Insert_Input = {
  data: App_Inputs_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<App_Inputs_On_Conflict>;
};

/** on_conflict condition type for table "app_inputs" */
export type App_Inputs_On_Conflict = {
  constraint: App_Inputs_Constraint;
  update_columns?: Array<App_Inputs_Update_Column>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};

/** Ordering options when selecting data from "app_inputs". */
export type App_Inputs_Order_By = {
  app?: Maybe<Apps_Order_By>;
  app_id?: Maybe<Order_By>;
  app_version?: Maybe<App_Versions_Order_By>;
  default_value?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options?: Maybe<Order_By>;
  showroom_apps_inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** primary key columns input for table: app_inputs */
export type App_Inputs_Pk_Columns_Input = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  version_num: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Inputs_Prepend_Input = {
  default_value?: Maybe<Scalars['jsonb']>;
  options?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "app_inputs" */
export enum App_Inputs_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  Description = 'description',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  Type = 'type',
  /** column name */
  VersionNum = 'version_num'
}

/** input type for updating data in table "app_inputs" */
export type App_Inputs_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "app_inputs" */
export type App_Inputs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Inputs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Inputs_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  default_value?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
  version_num?: Maybe<Scalars['String']>;
};

/** update columns of table "app_inputs" */
export enum App_Inputs_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  Description = 'description',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  Type = 'type',
  /** column name */
  VersionNum = 'version_num'
}

export type App_Inputs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<App_Inputs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<App_Inputs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<App_Inputs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<App_Inputs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<App_Inputs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<App_Inputs_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Inputs_Bool_Exp;
};

/** columns and relationships of "app_versions" */
export type App_Versions = {
  __typename?: 'app_versions';
  /** An object relationship */
  app: Apps;
  /** An object relationship */
  appVersionByVersionNumAppId?: Maybe<App_Versions>;
  /** An array relationship */
  app_actions: Array<App_Actions>;
  /** An aggregate relationship */
  app_actions_aggregate: App_Actions_Aggregate;
  /** An array relationship */
  app_events: Array<App_Events>;
  /** An aggregate relationship */
  app_events_aggregate: App_Events_Aggregate;
  app_id: Scalars['uuid'];
  /** An array relationship */
  app_inputs: Array<App_Inputs>;
  /** An aggregate relationship */
  app_inputs_aggregate: App_Inputs_Aggregate;
  created_at: Scalars['timestamptz'];
  grants?: Maybe<Scalars['jsonb']>;
  shasum?: Maybe<Scalars['String']>;
  /** An array relationship */
  showroom_apps: Array<Showroom_Apps>;
  /** An aggregate relationship */
  showroom_apps_aggregate: Showroom_Apps_Aggregate;
  updated_at: Scalars['timestamptz'];
  version_num: Scalars['String'];
};


/** columns and relationships of "app_versions" */
export type App_VersionsApp_ActionsArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsApp_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsApp_EventsArgs = {
  distinct_on?: Maybe<Array<App_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Events_Order_By>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsApp_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Events_Order_By>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsApp_InputsArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsApp_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsGrantsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsShowroom_AppsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


/** columns and relationships of "app_versions" */
export type App_VersionsShowroom_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};

/** aggregated selection of "app_versions" */
export type App_Versions_Aggregate = {
  __typename?: 'app_versions_aggregate';
  aggregate?: Maybe<App_Versions_Aggregate_Fields>;
  nodes: Array<App_Versions>;
};

export type App_Versions_Aggregate_Bool_Exp = {
  count?: Maybe<App_Versions_Aggregate_Bool_Exp_Count>;
};

export type App_Versions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<App_Versions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<App_Versions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "app_versions" */
export type App_Versions_Aggregate_Fields = {
  __typename?: 'app_versions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_Versions_Max_Fields>;
  min?: Maybe<App_Versions_Min_Fields>;
};


/** aggregate fields of "app_versions" */
export type App_Versions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Versions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app_versions" */
export type App_Versions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Versions_Max_Order_By>;
  min?: Maybe<App_Versions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Versions_Append_Input = {
  grants?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "app_versions" */
export type App_Versions_Arr_Rel_Insert_Input = {
  data: Array<App_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<App_Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "app_versions". All fields are combined with a logical 'AND'. */
export type App_Versions_Bool_Exp = {
  _and?: Maybe<Array<App_Versions_Bool_Exp>>;
  _not?: Maybe<App_Versions_Bool_Exp>;
  _or?: Maybe<Array<App_Versions_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  appVersionByVersionNumAppId?: Maybe<App_Versions_Bool_Exp>;
  app_actions?: Maybe<App_Actions_Bool_Exp>;
  app_actions_aggregate?: Maybe<App_Actions_Aggregate_Bool_Exp>;
  app_events?: Maybe<App_Events_Bool_Exp>;
  app_events_aggregate?: Maybe<App_Events_Aggregate_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  app_inputs?: Maybe<App_Inputs_Bool_Exp>;
  app_inputs_aggregate?: Maybe<App_Inputs_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grants?: Maybe<Jsonb_Comparison_Exp>;
  shasum?: Maybe<String_Comparison_Exp>;
  showroom_apps?: Maybe<Showroom_Apps_Bool_Exp>;
  showroom_apps_aggregate?: Maybe<Showroom_Apps_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version_num?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_versions" */
export enum App_Versions_Constraint {
  /** unique or primary key constraint on columns "app_id", "version_num" */
  AppVersionsPkey = 'app_versions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Versions_Delete_At_Path_Input = {
  grants?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Versions_Delete_Elem_Input = {
  grants?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Versions_Delete_Key_Input = {
  grants?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "app_versions" */
export type App_Versions_Insert_Input = {
  app?: Maybe<Apps_Obj_Rel_Insert_Input>;
  appVersionByVersionNumAppId?: Maybe<App_Versions_Obj_Rel_Insert_Input>;
  app_actions?: Maybe<App_Actions_Arr_Rel_Insert_Input>;
  app_events?: Maybe<App_Events_Arr_Rel_Insert_Input>;
  app_id?: Maybe<Scalars['uuid']>;
  app_inputs?: Maybe<App_Inputs_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grants?: Maybe<Scalars['jsonb']>;
  shasum?: Maybe<Scalars['String']>;
  showroom_apps?: Maybe<Showroom_Apps_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Versions_Max_Fields = {
  __typename?: 'app_versions_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  shasum?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "app_versions" */
export type App_Versions_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  shasum?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type App_Versions_Min_Fields = {
  __typename?: 'app_versions_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  shasum?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "app_versions" */
export type App_Versions_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  shasum?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** response of any mutation on the table "app_versions" */
export type App_Versions_Mutation_Response = {
  __typename?: 'app_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Versions>;
};

/** input type for inserting object relation for remote table "app_versions" */
export type App_Versions_Obj_Rel_Insert_Input = {
  data: App_Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<App_Versions_On_Conflict>;
};

/** on_conflict condition type for table "app_versions" */
export type App_Versions_On_Conflict = {
  constraint: App_Versions_Constraint;
  update_columns?: Array<App_Versions_Update_Column>;
  where?: Maybe<App_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "app_versions". */
export type App_Versions_Order_By = {
  app?: Maybe<Apps_Order_By>;
  appVersionByVersionNumAppId?: Maybe<App_Versions_Order_By>;
  app_actions_aggregate?: Maybe<App_Actions_Aggregate_Order_By>;
  app_events_aggregate?: Maybe<App_Events_Aggregate_Order_By>;
  app_id?: Maybe<Order_By>;
  app_inputs_aggregate?: Maybe<App_Inputs_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  grants?: Maybe<Order_By>;
  shasum?: Maybe<Order_By>;
  showroom_apps_aggregate?: Maybe<Showroom_Apps_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** primary key columns input for table: app_versions */
export type App_Versions_Pk_Columns_Input = {
  app_id: Scalars['uuid'];
  version_num: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Versions_Prepend_Input = {
  grants?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "app_versions" */
export enum App_Versions_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Grants = 'grants',
  /** column name */
  Shasum = 'shasum',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionNum = 'version_num'
}

/** input type for updating data in table "app_versions" */
export type App_Versions_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grants?: Maybe<Scalars['jsonb']>;
  shasum?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "app_versions" */
export type App_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Versions_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grants?: Maybe<Scalars['jsonb']>;
  shasum?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** update columns of table "app_versions" */
export enum App_Versions_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Grants = 'grants',
  /** column name */
  Shasum = 'shasum',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionNum = 'version_num'
}

export type App_Versions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<App_Versions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<App_Versions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<App_Versions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<App_Versions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<App_Versions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<App_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Versions_Bool_Exp;
};

/** columns and relationships of "apps" */
export type Apps = {
  __typename?: 'apps';
  /** An array relationship */
  api_clients: Array<Api_Clients>;
  /** An aggregate relationship */
  api_clients_aggregate: Api_Clients_Aggregate;
  /** An array relationship */
  app_actions: Array<App_Actions>;
  /** An aggregate relationship */
  app_actions_aggregate: App_Actions_Aggregate;
  /** An array relationship */
  app_inputs: Array<App_Inputs>;
  /** An aggregate relationship */
  app_inputs_aggregate: App_Inputs_Aggregate;
  /** An array relationship */
  app_versions: Array<App_Versions>;
  /** An aggregate relationship */
  app_versions_aggregate: App_Versions_Aggregate;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  showroom_apps: Array<Showroom_Apps>;
  /** An aggregate relationship */
  showroom_apps_aggregate: Showroom_Apps_Aggregate;
  /** An array relationship */
  showroom_apps_inputs: Array<Showroom_Apps_Inputs>;
  /** An aggregate relationship */
  showroom_apps_inputs_aggregate: Showroom_Apps_Inputs_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "apps" */
export type AppsApi_ClientsArgs = {
  distinct_on?: Maybe<Array<Api_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Clients_Order_By>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApi_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Clients_Order_By>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApp_ActionsArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApp_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApp_InputsArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApp_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApp_VersionsArgs = {
  distinct_on?: Maybe<Array<App_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Versions_Order_By>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsApp_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Versions_Order_By>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsShowroom_AppsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsShowroom_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsShowroom_Apps_InputsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsShowroom_Apps_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};

/** aggregated selection of "apps" */
export type Apps_Aggregate = {
  __typename?: 'apps_aggregate';
  aggregate?: Maybe<Apps_Aggregate_Fields>;
  nodes: Array<Apps>;
};

/** aggregate fields of "apps" */
export type Apps_Aggregate_Fields = {
  __typename?: 'apps_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Apps_Max_Fields>;
  min?: Maybe<Apps_Min_Fields>;
};


/** aggregate fields of "apps" */
export type Apps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Apps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "apps". All fields are combined with a logical 'AND'. */
export type Apps_Bool_Exp = {
  _and?: Maybe<Array<Apps_Bool_Exp>>;
  _not?: Maybe<Apps_Bool_Exp>;
  _or?: Maybe<Array<Apps_Bool_Exp>>;
  api_clients?: Maybe<Api_Clients_Bool_Exp>;
  api_clients_aggregate?: Maybe<Api_Clients_Aggregate_Bool_Exp>;
  app_actions?: Maybe<App_Actions_Bool_Exp>;
  app_actions_aggregate?: Maybe<App_Actions_Aggregate_Bool_Exp>;
  app_inputs?: Maybe<App_Inputs_Bool_Exp>;
  app_inputs_aggregate?: Maybe<App_Inputs_Aggregate_Bool_Exp>;
  app_versions?: Maybe<App_Versions_Bool_Exp>;
  app_versions_aggregate?: Maybe<App_Versions_Aggregate_Bool_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  showroom_apps?: Maybe<Showroom_Apps_Bool_Exp>;
  showroom_apps_aggregate?: Maybe<Showroom_Apps_Aggregate_Bool_Exp>;
  showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
  showroom_apps_inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps" */
export enum Apps_Constraint {
  /** unique or primary key constraint on columns "code" */
  AppsCodeKey = 'apps_code_key',
  /** unique or primary key constraint on columns "id" */
  AppsPkey = 'apps_pkey'
}

/** input type for inserting data into table "apps" */
export type Apps_Insert_Input = {
  api_clients?: Maybe<Api_Clients_Arr_Rel_Insert_Input>;
  app_actions?: Maybe<App_Actions_Arr_Rel_Insert_Input>;
  app_inputs?: Maybe<App_Inputs_Arr_Rel_Insert_Input>;
  app_versions?: Maybe<App_Versions_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showroom_apps?: Maybe<Showroom_Apps_Arr_Rel_Insert_Input>;
  showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Apps_Max_Fields = {
  __typename?: 'apps_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Apps_Min_Fields = {
  __typename?: 'apps_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "apps" */
export type Apps_Mutation_Response = {
  __typename?: 'apps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps>;
};

/** input type for inserting object relation for remote table "apps" */
export type Apps_Obj_Rel_Insert_Input = {
  data: Apps_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Apps_On_Conflict>;
};

/** on_conflict condition type for table "apps" */
export type Apps_On_Conflict = {
  constraint: Apps_Constraint;
  update_columns?: Array<Apps_Update_Column>;
  where?: Maybe<Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "apps". */
export type Apps_Order_By = {
  api_clients_aggregate?: Maybe<Api_Clients_Aggregate_Order_By>;
  app_actions_aggregate?: Maybe<App_Actions_Aggregate_Order_By>;
  app_inputs_aggregate?: Maybe<App_Inputs_Aggregate_Order_By>;
  app_versions_aggregate?: Maybe<App_Versions_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom_apps_aggregate?: Maybe<Showroom_Apps_Aggregate_Order_By>;
  showroom_apps_inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: apps */
export type Apps_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "apps" */
export enum Apps_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "apps" */
export type Apps_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "apps" */
export type Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "apps" */
export enum Apps_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Apps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Bool_Exp;
};

/** columns and relationships of "articles" */
export type Articles = {
  __typename?: 'articles';
  body?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  featured_image?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The estimated reading time for this article, in minutes. */
  reading_time: Scalars['numeric'];
  /** An object relationship */
  showroom?: Maybe<Showrooms>;
  showroom_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  /** The origin of the article. E.g. hubspot */
  source: Scalars['String'];
  tags: Scalars['jsonb'];
  testing?: Maybe<Scalars['Boolean']>;
  /** help | resources */
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "articles" */
export type ArticlesTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "articles" */
export type Articles_Aggregate = {
  __typename?: 'articles_aggregate';
  aggregate?: Maybe<Articles_Aggregate_Fields>;
  nodes: Array<Articles>;
};

export type Articles_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Articles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Articles_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Articles_Aggregate_Bool_Exp_Count>;
};

export type Articles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Articles_Select_Column_Articles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Articles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Articles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Articles_Select_Column_Articles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Articles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Articles_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Articles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Articles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "articles" */
export type Articles_Aggregate_Fields = {
  __typename?: 'articles_aggregate_fields';
  avg?: Maybe<Articles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Articles_Max_Fields>;
  min?: Maybe<Articles_Min_Fields>;
  stddev?: Maybe<Articles_Stddev_Fields>;
  stddev_pop?: Maybe<Articles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Articles_Stddev_Samp_Fields>;
  sum?: Maybe<Articles_Sum_Fields>;
  var_pop?: Maybe<Articles_Var_Pop_Fields>;
  var_samp?: Maybe<Articles_Var_Samp_Fields>;
  variance?: Maybe<Articles_Variance_Fields>;
};


/** aggregate fields of "articles" */
export type Articles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Articles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "articles" */
export type Articles_Aggregate_Order_By = {
  avg?: Maybe<Articles_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Articles_Max_Order_By>;
  min?: Maybe<Articles_Min_Order_By>;
  stddev?: Maybe<Articles_Stddev_Order_By>;
  stddev_pop?: Maybe<Articles_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Articles_Stddev_Samp_Order_By>;
  sum?: Maybe<Articles_Sum_Order_By>;
  var_pop?: Maybe<Articles_Var_Pop_Order_By>;
  var_samp?: Maybe<Articles_Var_Samp_Order_By>;
  variance?: Maybe<Articles_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Articles_Append_Input = {
  tags?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "articles" */
export type Articles_Arr_Rel_Insert_Input = {
  data: Array<Articles_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Articles_On_Conflict>;
};

/** aggregate avg on columns */
export type Articles_Avg_Fields = {
  __typename?: 'articles_avg_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "articles" */
export type Articles_Avg_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "articles". All fields are combined with a logical 'AND'. */
export type Articles_Bool_Exp = {
  _and?: Maybe<Array<Articles_Bool_Exp>>;
  _not?: Maybe<Articles_Bool_Exp>;
  _or?: Maybe<Array<Articles_Bool_Exp>>;
  body?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  featured_image?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meta_description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  reading_time?: Maybe<Numeric_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  testing?: Maybe<Boolean_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "articles" */
export enum Articles_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArticlesPkey = 'articles_pkey',
  /** unique or primary key constraint on columns "slug" */
  ArticlesSlugKey = 'articles_slug_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Articles_Delete_At_Path_Input = {
  tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Articles_Delete_Elem_Input = {
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Articles_Delete_Key_Input = {
  tags?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "articles" */
export type Articles_Inc_Input = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "articles" */
export type Articles_Insert_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['jsonb']>;
  testing?: Maybe<Scalars['Boolean']>;
  /** help | resources */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Articles_Max_Fields = {
  __typename?: 'articles_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Scalars['String']>;
  /** help | resources */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "articles" */
export type Articles_Max_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  featured_image?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Order_By>;
  /** help | resources */
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Articles_Min_Fields = {
  __typename?: 'articles_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Scalars['String']>;
  /** help | resources */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "articles" */
export type Articles_Min_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  featured_image?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Order_By>;
  /** help | resources */
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "articles" */
export type Articles_Mutation_Response = {
  __typename?: 'articles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Articles>;
};

/** on_conflict condition type for table "articles" */
export type Articles_On_Conflict = {
  constraint: Articles_Constraint;
  update_columns?: Array<Articles_Update_Column>;
  where?: Maybe<Articles_Bool_Exp>;
};

/** Ordering options when selecting data from "articles". */
export type Articles_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  featured_image?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reading_time?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  testing?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: articles */
export type Articles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Articles_Prepend_Input = {
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "articles" */
export enum Articles_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FeaturedImage = 'featured_image',
  /** column name */
  Id = 'id',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  Name = 'name',
  /** column name */
  ReadingTime = 'reading_time',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Source = 'source',
  /** column name */
  Tags = 'tags',
  /** column name */
  Testing = 'testing',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "articles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "articles" */
export enum Articles_Select_Column_Articles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Testing = 'testing'
}

/** select "articles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "articles" */
export enum Articles_Select_Column_Articles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Testing = 'testing'
}

/** input type for updating data in table "articles" */
export type Articles_Set_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['jsonb']>;
  testing?: Maybe<Scalars['Boolean']>;
  /** help | resources */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Articles_Stddev_Fields = {
  __typename?: 'articles_stddev_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "articles" */
export type Articles_Stddev_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Articles_Stddev_Pop_Fields = {
  __typename?: 'articles_stddev_pop_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "articles" */
export type Articles_Stddev_Pop_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Articles_Stddev_Samp_Fields = {
  __typename?: 'articles_stddev_samp_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "articles" */
export type Articles_Stddev_Samp_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** Streaming cursor of the table "articles" */
export type Articles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Articles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Articles_Stream_Cursor_Value_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  /** The origin of the article. E.g. hubspot */
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['jsonb']>;
  testing?: Maybe<Scalars['Boolean']>;
  /** help | resources */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Articles_Sum_Fields = {
  __typename?: 'articles_sum_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "articles" */
export type Articles_Sum_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** update columns of table "articles" */
export enum Articles_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FeaturedImage = 'featured_image',
  /** column name */
  Id = 'id',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  Name = 'name',
  /** column name */
  ReadingTime = 'reading_time',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Source = 'source',
  /** column name */
  Tags = 'tags',
  /** column name */
  Testing = 'testing',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Articles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Articles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Articles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Articles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Articles_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Articles_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Articles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Articles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Articles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Articles_Var_Pop_Fields = {
  __typename?: 'articles_var_pop_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "articles" */
export type Articles_Var_Pop_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Articles_Var_Samp_Fields = {
  __typename?: 'articles_var_samp_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "articles" */
export type Articles_Var_Samp_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Articles_Variance_Fields = {
  __typename?: 'articles_variance_fields';
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "articles" */
export type Articles_Variance_Order_By = {
  /** The estimated reading time for this article, in minutes. */
  reading_time?: Maybe<Order_By>;
};

/** columns and relationships of "assets" */
export type Assets = {
  __typename?: 'assets';
  alt?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  custom_vehicle?: Maybe<Custom_Vehicles>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_public: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  offer?: Maybe<Offers>;
  offer_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  page?: Maybe<Pages>;
  page_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  personal_vehicle?: Maybe<Personal_Vehicles>;
  personal_vehicle_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Scalars['String']>;
  src: Scalars['String'];
  /** An object relationship */
  stocked_vehicle?: Maybe<Stocked_Vehicles>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  test_drive?: Maybe<Test_Drives>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "assets" */
export type Assets_Aggregate = {
  __typename?: 'assets_aggregate';
  aggregate?: Maybe<Assets_Aggregate_Fields>;
  nodes: Array<Assets>;
};

export type Assets_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Assets_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Assets_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Assets_Aggregate_Bool_Exp_Count>;
};

export type Assets_Aggregate_Bool_Exp_Bool_And = {
  arguments: Assets_Select_Column_Assets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Assets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assets_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Assets_Select_Column_Assets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Assets_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Assets_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Assets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Assets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assets" */
export type Assets_Aggregate_Fields = {
  __typename?: 'assets_aggregate_fields';
  avg?: Maybe<Assets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assets_Max_Fields>;
  min?: Maybe<Assets_Min_Fields>;
  stddev?: Maybe<Assets_Stddev_Fields>;
  stddev_pop?: Maybe<Assets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assets_Stddev_Samp_Fields>;
  sum?: Maybe<Assets_Sum_Fields>;
  var_pop?: Maybe<Assets_Var_Pop_Fields>;
  var_samp?: Maybe<Assets_Var_Samp_Fields>;
  variance?: Maybe<Assets_Variance_Fields>;
};


/** aggregate fields of "assets" */
export type Assets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assets" */
export type Assets_Aggregate_Order_By = {
  avg?: Maybe<Assets_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Assets_Max_Order_By>;
  min?: Maybe<Assets_Min_Order_By>;
  stddev?: Maybe<Assets_Stddev_Order_By>;
  stddev_pop?: Maybe<Assets_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Assets_Stddev_Samp_Order_By>;
  sum?: Maybe<Assets_Sum_Order_By>;
  var_pop?: Maybe<Assets_Var_Pop_Order_By>;
  var_samp?: Maybe<Assets_Var_Samp_Order_By>;
  variance?: Maybe<Assets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assets" */
export type Assets_Arr_Rel_Insert_Input = {
  data: Array<Assets_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Assets_On_Conflict>;
};

/** aggregate avg on columns */
export type Assets_Avg_Fields = {
  __typename?: 'assets_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assets" */
export type Assets_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assets". All fields are combined with a logical 'AND'. */
export type Assets_Bool_Exp = {
  _and?: Maybe<Array<Assets_Bool_Exp>>;
  _not?: Maybe<Assets_Bool_Exp>;
  _or?: Maybe<Array<Assets_Bool_Exp>>;
  alt?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_vehicle?: Maybe<Custom_Vehicles_Bool_Exp>;
  custom_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Offers_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  page?: Maybe<Pages_Bool_Exp>;
  page_id?: Maybe<Uuid_Comparison_Exp>;
  personal_vehicle?: Maybe<Personal_Vehicles_Bool_Exp>;
  personal_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  placement?: Maybe<String_Comparison_Exp>;
  src?: Maybe<String_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  test_drive?: Maybe<Test_Drives_Bool_Exp>;
  test_drive_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "assets" */
export enum Assets_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssetsPkey = 'assets_pkey'
}

/** input type for incrementing numeric columns in table "assets" */
export type Assets_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assets" */
export type Assets_Insert_Input = {
  alt?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle?: Maybe<Custom_Vehicles_Obj_Rel_Insert_Input>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_public?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Offers_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  page?: Maybe<Pages_Obj_Rel_Insert_Input>;
  page_id?: Maybe<Scalars['uuid']>;
  personal_vehicle?: Maybe<Personal_Vehicles_Obj_Rel_Insert_Input>;
  personal_vehicle_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  test_drive?: Maybe<Test_Drives_Obj_Rel_Insert_Input>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Assets_Max_Fields = {
  __typename?: 'assets_max_fields';
  alt?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  page_id?: Maybe<Scalars['uuid']>;
  personal_vehicle_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "assets" */
export type Assets_Max_Order_By = {
  alt?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_vehicle_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
  personal_vehicle_id?: Maybe<Order_By>;
  placement?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  test_drive_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assets_Min_Fields = {
  __typename?: 'assets_min_fields';
  alt?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  page_id?: Maybe<Scalars['uuid']>;
  personal_vehicle_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "assets" */
export type Assets_Min_Order_By = {
  alt?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_vehicle_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
  personal_vehicle_id?: Maybe<Order_By>;
  placement?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  test_drive_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "assets" */
export type Assets_Mutation_Response = {
  __typename?: 'assets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assets>;
};

/** on_conflict condition type for table "assets" */
export type Assets_On_Conflict = {
  constraint: Assets_Constraint;
  update_columns?: Array<Assets_Update_Column>;
  where?: Maybe<Assets_Bool_Exp>;
};

/** Ordering options when selecting data from "assets". */
export type Assets_Order_By = {
  alt?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_vehicle?: Maybe<Custom_Vehicles_Order_By>;
  custom_vehicle_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  offer?: Maybe<Offers_Order_By>;
  offer_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  page?: Maybe<Pages_Order_By>;
  page_id?: Maybe<Order_By>;
  personal_vehicle?: Maybe<Personal_Vehicles_Order_By>;
  personal_vehicle_id?: Maybe<Order_By>;
  placement?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  test_drive?: Maybe<Test_Drives_Order_By>;
  test_drive_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: assets */
export type Assets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assets" */
export enum Assets_Select_Column {
  /** column name */
  Alt = 'alt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomVehicleId = 'custom_vehicle_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Label = 'label',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  Order = 'order',
  /** column name */
  PageId = 'page_id',
  /** column name */
  PersonalVehicleId = 'personal_vehicle_id',
  /** column name */
  Placement = 'placement',
  /** column name */
  Src = 'src',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  TestDriveId = 'test_drive_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** select "assets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "assets" */
export enum Assets_Select_Column_Assets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublic = 'is_public'
}

/** select "assets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "assets" */
export enum Assets_Select_Column_Assets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublic = 'is_public'
}

/** input type for updating data in table "assets" */
export type Assets_Set_Input = {
  alt?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_public?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  page_id?: Maybe<Scalars['uuid']>;
  personal_vehicle_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Assets_Stddev_Fields = {
  __typename?: 'assets_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assets" */
export type Assets_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assets_Stddev_Pop_Fields = {
  __typename?: 'assets_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assets" */
export type Assets_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assets_Stddev_Samp_Fields = {
  __typename?: 'assets_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assets" */
export type Assets_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "assets" */
export type Assets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assets_Stream_Cursor_Value_Input = {
  alt?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_public?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  page_id?: Maybe<Scalars['uuid']>;
  personal_vehicle_id?: Maybe<Scalars['uuid']>;
  placement?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  test_drive_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Assets_Sum_Fields = {
  __typename?: 'assets_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assets" */
export type Assets_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "assets" */
export enum Assets_Update_Column {
  /** column name */
  Alt = 'alt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomVehicleId = 'custom_vehicle_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Label = 'label',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  Order = 'order',
  /** column name */
  PageId = 'page_id',
  /** column name */
  PersonalVehicleId = 'personal_vehicle_id',
  /** column name */
  Placement = 'placement',
  /** column name */
  Src = 'src',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  TestDriveId = 'test_drive_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Assets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Assets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Assets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assets_Var_Pop_Fields = {
  __typename?: 'assets_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assets" */
export type Assets_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assets_Var_Samp_Fields = {
  __typename?: 'assets_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assets" */
export type Assets_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Assets_Variance_Fields = {
  __typename?: 'assets_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assets" */
export type Assets_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "auction_bids" */
export type Auction_Bids = {
  __typename?: 'auction_bids';
  amount: Scalars['numeric'];
  /** An object relationship */
  auction: Auctions;
  auction_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "auction_bids" */
export type Auction_Bids_Aggregate = {
  __typename?: 'auction_bids_aggregate';
  aggregate?: Maybe<Auction_Bids_Aggregate_Fields>;
  nodes: Array<Auction_Bids>;
};

export type Auction_Bids_Aggregate_Bool_Exp = {
  count?: Maybe<Auction_Bids_Aggregate_Bool_Exp_Count>;
};

export type Auction_Bids_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Auction_Bids_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Auction_Bids_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auction_bids" */
export type Auction_Bids_Aggregate_Fields = {
  __typename?: 'auction_bids_aggregate_fields';
  avg?: Maybe<Auction_Bids_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auction_Bids_Max_Fields>;
  min?: Maybe<Auction_Bids_Min_Fields>;
  stddev?: Maybe<Auction_Bids_Stddev_Fields>;
  stddev_pop?: Maybe<Auction_Bids_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auction_Bids_Stddev_Samp_Fields>;
  sum?: Maybe<Auction_Bids_Sum_Fields>;
  var_pop?: Maybe<Auction_Bids_Var_Pop_Fields>;
  var_samp?: Maybe<Auction_Bids_Var_Samp_Fields>;
  variance?: Maybe<Auction_Bids_Variance_Fields>;
};


/** aggregate fields of "auction_bids" */
export type Auction_Bids_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auction_Bids_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auction_bids" */
export type Auction_Bids_Aggregate_Order_By = {
  avg?: Maybe<Auction_Bids_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Auction_Bids_Max_Order_By>;
  min?: Maybe<Auction_Bids_Min_Order_By>;
  stddev?: Maybe<Auction_Bids_Stddev_Order_By>;
  stddev_pop?: Maybe<Auction_Bids_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Auction_Bids_Stddev_Samp_Order_By>;
  sum?: Maybe<Auction_Bids_Sum_Order_By>;
  var_pop?: Maybe<Auction_Bids_Var_Pop_Order_By>;
  var_samp?: Maybe<Auction_Bids_Var_Samp_Order_By>;
  variance?: Maybe<Auction_Bids_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auction_bids" */
export type Auction_Bids_Arr_Rel_Insert_Input = {
  data: Array<Auction_Bids_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auction_Bids_On_Conflict>;
};

/** aggregate avg on columns */
export type Auction_Bids_Avg_Fields = {
  __typename?: 'auction_bids_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auction_bids" */
export type Auction_Bids_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auction_bids". All fields are combined with a logical 'AND'. */
export type Auction_Bids_Bool_Exp = {
  _and?: Maybe<Array<Auction_Bids_Bool_Exp>>;
  _not?: Maybe<Auction_Bids_Bool_Exp>;
  _or?: Maybe<Array<Auction_Bids_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  auction?: Maybe<Auctions_Bool_Exp>;
  auction_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auction_bids" */
export enum Auction_Bids_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuctionBidsPkey = 'auction_bids_pkey'
}

/** input type for incrementing numeric columns in table "auction_bids" */
export type Auction_Bids_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "auction_bids" */
export type Auction_Bids_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  auction?: Maybe<Auctions_Obj_Rel_Insert_Input>;
  auction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auction_Bids_Max_Fields = {
  __typename?: 'auction_bids_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  auction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auction_bids" */
export type Auction_Bids_Max_Order_By = {
  amount?: Maybe<Order_By>;
  auction_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auction_Bids_Min_Fields = {
  __typename?: 'auction_bids_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  auction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auction_bids" */
export type Auction_Bids_Min_Order_By = {
  amount?: Maybe<Order_By>;
  auction_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "auction_bids" */
export type Auction_Bids_Mutation_Response = {
  __typename?: 'auction_bids_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auction_Bids>;
};

/** on_conflict condition type for table "auction_bids" */
export type Auction_Bids_On_Conflict = {
  constraint: Auction_Bids_Constraint;
  update_columns?: Array<Auction_Bids_Update_Column>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};

/** Ordering options when selecting data from "auction_bids". */
export type Auction_Bids_Order_By = {
  amount?: Maybe<Order_By>;
  auction?: Maybe<Auctions_Order_By>;
  auction_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: auction_bids */
export type Auction_Bids_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auction_bids" */
export enum Auction_Bids_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AuctionId = 'auction_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auction_bids" */
export type Auction_Bids_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  auction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Auction_Bids_Stddev_Fields = {
  __typename?: 'auction_bids_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auction_bids" */
export type Auction_Bids_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auction_Bids_Stddev_Pop_Fields = {
  __typename?: 'auction_bids_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auction_bids" */
export type Auction_Bids_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auction_Bids_Stddev_Samp_Fields = {
  __typename?: 'auction_bids_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auction_bids" */
export type Auction_Bids_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Streaming cursor of the table "auction_bids" */
export type Auction_Bids_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auction_Bids_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auction_Bids_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>;
  auction_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Auction_Bids_Sum_Fields = {
  __typename?: 'auction_bids_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "auction_bids" */
export type Auction_Bids_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "auction_bids" */
export enum Auction_Bids_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AuctionId = 'auction_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Auction_Bids_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Auction_Bids_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Auction_Bids_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auction_Bids_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auction_Bids_Var_Pop_Fields = {
  __typename?: 'auction_bids_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auction_bids" */
export type Auction_Bids_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auction_Bids_Var_Samp_Fields = {
  __typename?: 'auction_bids_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auction_bids" */
export type Auction_Bids_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Auction_Bids_Variance_Fields = {
  __typename?: 'auction_bids_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auction_bids" */
export type Auction_Bids_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "auctions" */
export type Auctions = {
  __typename?: 'auctions';
  /** An array relationship */
  auction_bids: Array<Auction_Bids>;
  /** An aggregate relationship */
  auction_bids_aggregate: Auction_Bids_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  ends_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  min_bid: Scalars['numeric'];
  min_bid_increment: Scalars['numeric'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  starts_at: Scalars['timestamptz'];
  /** An object relationship */
  stocked_vehicle: Stocked_Vehicles;
  stocked_vehicle_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "auctions" */
export type AuctionsAuction_BidsArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


/** columns and relationships of "auctions" */
export type AuctionsAuction_Bids_AggregateArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};

/** aggregated selection of "auctions" */
export type Auctions_Aggregate = {
  __typename?: 'auctions_aggregate';
  aggregate?: Maybe<Auctions_Aggregate_Fields>;
  nodes: Array<Auctions>;
};

export type Auctions_Aggregate_Bool_Exp = {
  count?: Maybe<Auctions_Aggregate_Bool_Exp_Count>;
};

export type Auctions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Auctions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Auctions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auctions" */
export type Auctions_Aggregate_Fields = {
  __typename?: 'auctions_aggregate_fields';
  avg?: Maybe<Auctions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auctions_Max_Fields>;
  min?: Maybe<Auctions_Min_Fields>;
  stddev?: Maybe<Auctions_Stddev_Fields>;
  stddev_pop?: Maybe<Auctions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auctions_Stddev_Samp_Fields>;
  sum?: Maybe<Auctions_Sum_Fields>;
  var_pop?: Maybe<Auctions_Var_Pop_Fields>;
  var_samp?: Maybe<Auctions_Var_Samp_Fields>;
  variance?: Maybe<Auctions_Variance_Fields>;
};


/** aggregate fields of "auctions" */
export type Auctions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auctions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auctions" */
export type Auctions_Aggregate_Order_By = {
  avg?: Maybe<Auctions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Auctions_Max_Order_By>;
  min?: Maybe<Auctions_Min_Order_By>;
  stddev?: Maybe<Auctions_Stddev_Order_By>;
  stddev_pop?: Maybe<Auctions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Auctions_Stddev_Samp_Order_By>;
  sum?: Maybe<Auctions_Sum_Order_By>;
  var_pop?: Maybe<Auctions_Var_Pop_Order_By>;
  var_samp?: Maybe<Auctions_Var_Samp_Order_By>;
  variance?: Maybe<Auctions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auctions" */
export type Auctions_Arr_Rel_Insert_Input = {
  data: Array<Auctions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auctions_On_Conflict>;
};

/** aggregate avg on columns */
export type Auctions_Avg_Fields = {
  __typename?: 'auctions_avg_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auctions" */
export type Auctions_Avg_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auctions". All fields are combined with a logical 'AND'. */
export type Auctions_Bool_Exp = {
  _and?: Maybe<Array<Auctions_Bool_Exp>>;
  _not?: Maybe<Auctions_Bool_Exp>;
  _or?: Maybe<Array<Auctions_Bool_Exp>>;
  auction_bids?: Maybe<Auction_Bids_Bool_Exp>;
  auction_bids_aggregate?: Maybe<Auction_Bids_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  min_bid?: Maybe<Numeric_Comparison_Exp>;
  min_bid_increment?: Maybe<Numeric_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auctions" */
export enum Auctions_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuctionsPkey = 'auctions_pkey'
}

/** input type for incrementing numeric columns in table "auctions" */
export type Auctions_Inc_Input = {
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "auctions" */
export type Auctions_Insert_Input = {
  auction_bids?: Maybe<Auction_Bids_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auctions_Max_Fields = {
  __typename?: 'auctions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auctions" */
export type Auctions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auctions_Min_Fields = {
  __typename?: 'auctions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auctions" */
export type Auctions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "auctions" */
export type Auctions_Mutation_Response = {
  __typename?: 'auctions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auctions>;
};

/** input type for inserting object relation for remote table "auctions" */
export type Auctions_Obj_Rel_Insert_Input = {
  data: Auctions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Auctions_On_Conflict>;
};

/** on_conflict condition type for table "auctions" */
export type Auctions_On_Conflict = {
  constraint: Auctions_Constraint;
  update_columns?: Array<Auctions_Update_Column>;
  where?: Maybe<Auctions_Bool_Exp>;
};

/** Ordering options when selecting data from "auctions". */
export type Auctions_Order_By = {
  auction_bids_aggregate?: Maybe<Auction_Bids_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: auctions */
export type Auctions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auctions" */
export enum Auctions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Id = 'id',
  /** column name */
  MinBid = 'min_bid',
  /** column name */
  MinBidIncrement = 'min_bid_increment',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auctions" */
export type Auctions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auctions_Stddev_Fields = {
  __typename?: 'auctions_stddev_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auctions" */
export type Auctions_Stddev_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auctions_Stddev_Pop_Fields = {
  __typename?: 'auctions_stddev_pop_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auctions" */
export type Auctions_Stddev_Pop_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auctions_Stddev_Samp_Fields = {
  __typename?: 'auctions_stddev_samp_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auctions" */
export type Auctions_Stddev_Samp_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** Streaming cursor of the table "auctions" */
export type Auctions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auctions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auctions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Auctions_Sum_Fields = {
  __typename?: 'auctions_sum_fields';
  min_bid?: Maybe<Scalars['numeric']>;
  min_bid_increment?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "auctions" */
export type Auctions_Sum_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** update columns of table "auctions" */
export enum Auctions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Id = 'id',
  /** column name */
  MinBid = 'min_bid',
  /** column name */
  MinBidIncrement = 'min_bid_increment',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auctions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Auctions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Auctions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auctions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auctions_Var_Pop_Fields = {
  __typename?: 'auctions_var_pop_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auctions" */
export type Auctions_Var_Pop_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auctions_Var_Samp_Fields = {
  __typename?: 'auctions_var_samp_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auctions" */
export type Auctions_Var_Samp_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Auctions_Variance_Fields = {
  __typename?: 'auctions_variance_fields';
  min_bid?: Maybe<Scalars['Float']>;
  min_bid_increment?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auctions" */
export type Auctions_Variance_Order_By = {
  min_bid?: Maybe<Order_By>;
  min_bid_increment?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "calculator_submission" */
export type Calculator_Submission = {
  __typename?: 'calculator_submission';
  allow_updates_until?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  preferred_body_types?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  refereers: Array<Calculator_Submission>;
  /** An aggregate relationship */
  refereers_aggregate: Calculator_Submission_Aggregate;
  /** An object relationship */
  referrer?: Maybe<Calculator_Submission>;
  referrer_id?: Maybe<Scalars['uuid']>;
  registration_lookup_response?: Maybe<Scalars['jsonb']>;
  registration_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom?: Maybe<Showrooms>;
  showroom_id?: Maybe<Scalars['uuid']>;
  state_of_registration?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vehicle?: Maybe<Known_Vehicles>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_identifier_type: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};


/** columns and relationships of "calculator_submission" */
export type Calculator_SubmissionPreferred_Body_TypesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "calculator_submission" */
export type Calculator_SubmissionRefereersArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "calculator_submission" */
export type Calculator_SubmissionRefereers_AggregateArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "calculator_submission" */
export type Calculator_SubmissionRegistration_Lookup_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "calculator_submission" */
export type Calculator_Submission_Aggregate = {
  __typename?: 'calculator_submission_aggregate';
  aggregate?: Maybe<Calculator_Submission_Aggregate_Fields>;
  nodes: Array<Calculator_Submission>;
};

export type Calculator_Submission_Aggregate_Bool_Exp = {
  count?: Maybe<Calculator_Submission_Aggregate_Bool_Exp_Count>;
};

export type Calculator_Submission_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Calculator_Submission_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Calculator_Submission_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calculator_submission" */
export type Calculator_Submission_Aggregate_Fields = {
  __typename?: 'calculator_submission_aggregate_fields';
  avg?: Maybe<Calculator_Submission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Calculator_Submission_Max_Fields>;
  min?: Maybe<Calculator_Submission_Min_Fields>;
  stddev?: Maybe<Calculator_Submission_Stddev_Fields>;
  stddev_pop?: Maybe<Calculator_Submission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calculator_Submission_Stddev_Samp_Fields>;
  sum?: Maybe<Calculator_Submission_Sum_Fields>;
  var_pop?: Maybe<Calculator_Submission_Var_Pop_Fields>;
  var_samp?: Maybe<Calculator_Submission_Var_Samp_Fields>;
  variance?: Maybe<Calculator_Submission_Variance_Fields>;
};


/** aggregate fields of "calculator_submission" */
export type Calculator_Submission_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Calculator_Submission_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calculator_submission" */
export type Calculator_Submission_Aggregate_Order_By = {
  avg?: Maybe<Calculator_Submission_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Calculator_Submission_Max_Order_By>;
  min?: Maybe<Calculator_Submission_Min_Order_By>;
  stddev?: Maybe<Calculator_Submission_Stddev_Order_By>;
  stddev_pop?: Maybe<Calculator_Submission_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Calculator_Submission_Stddev_Samp_Order_By>;
  sum?: Maybe<Calculator_Submission_Sum_Order_By>;
  var_pop?: Maybe<Calculator_Submission_Var_Pop_Order_By>;
  var_samp?: Maybe<Calculator_Submission_Var_Samp_Order_By>;
  variance?: Maybe<Calculator_Submission_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calculator_Submission_Append_Input = {
  preferred_body_types?: Maybe<Scalars['jsonb']>;
  registration_lookup_response?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "calculator_submission" */
export type Calculator_Submission_Arr_Rel_Insert_Input = {
  data: Array<Calculator_Submission_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Calculator_Submission_On_Conflict>;
};

/** aggregate avg on columns */
export type Calculator_Submission_Avg_Fields = {
  __typename?: 'calculator_submission_avg_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "calculator_submission" */
export type Calculator_Submission_Avg_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "calculator_submission". All fields are combined with a logical 'AND'. */
export type Calculator_Submission_Bool_Exp = {
  _and?: Maybe<Array<Calculator_Submission_Bool_Exp>>;
  _not?: Maybe<Calculator_Submission_Bool_Exp>;
  _or?: Maybe<Array<Calculator_Submission_Bool_Exp>>;
  allow_updates_until?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_emissions?: Maybe<Numeric_Comparison_Exp>;
  current_maintenance_costs?: Maybe<Numeric_Comparison_Exp>;
  current_travel_costs?: Maybe<Numeric_Comparison_Exp>;
  driving_distance?: Maybe<Numeric_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  future_emissions?: Maybe<Numeric_Comparison_Exp>;
  future_maintenance_costs?: Maybe<Numeric_Comparison_Exp>;
  future_travel_costs?: Maybe<Numeric_Comparison_Exp>;
  hubspot_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  preferred_body_types?: Maybe<Jsonb_Comparison_Exp>;
  refereers?: Maybe<Calculator_Submission_Bool_Exp>;
  refereers_aggregate?: Maybe<Calculator_Submission_Aggregate_Bool_Exp>;
  referrer?: Maybe<Calculator_Submission_Bool_Exp>;
  referrer_id?: Maybe<Uuid_Comparison_Exp>;
  registration_lookup_response?: Maybe<Jsonb_Comparison_Exp>;
  registration_number?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  state_of_registration?: Maybe<String_Comparison_Exp>;
  touched?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle?: Maybe<Known_Vehicles_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_identifier_type?: Maybe<String_Comparison_Exp>;
  year?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "calculator_submission" */
export enum Calculator_Submission_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalculatorSubmissionPkey = 'calculator_submission_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calculator_Submission_Delete_At_Path_Input = {
  preferred_body_types?: Maybe<Array<Scalars['String']>>;
  registration_lookup_response?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calculator_Submission_Delete_Elem_Input = {
  preferred_body_types?: Maybe<Scalars['Int']>;
  registration_lookup_response?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calculator_Submission_Delete_Key_Input = {
  preferred_body_types?: Maybe<Scalars['String']>;
  registration_lookup_response?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "calculator_submission" */
export type Calculator_Submission_Inc_Input = {
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "calculator_submission" */
export type Calculator_Submission_Insert_Input = {
  allow_updates_until?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  preferred_body_types?: Maybe<Scalars['jsonb']>;
  refereers?: Maybe<Calculator_Submission_Arr_Rel_Insert_Input>;
  referrer?: Maybe<Calculator_Submission_Obj_Rel_Insert_Input>;
  referrer_id?: Maybe<Scalars['uuid']>;
  registration_lookup_response?: Maybe<Scalars['jsonb']>;
  registration_number?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  state_of_registration?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle?: Maybe<Known_Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_identifier_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Calculator_Submission_Max_Fields = {
  __typename?: 'calculator_submission_max_fields';
  allow_updates_until?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  referrer_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  state_of_registration?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_identifier_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "calculator_submission" */
export type Calculator_Submission_Max_Order_By = {
  allow_updates_until?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  hubspot_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  referrer_id?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_identifier_type?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Calculator_Submission_Min_Fields = {
  __typename?: 'calculator_submission_min_fields';
  allow_updates_until?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  referrer_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  state_of_registration?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_identifier_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "calculator_submission" */
export type Calculator_Submission_Min_Order_By = {
  allow_updates_until?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  hubspot_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  referrer_id?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_identifier_type?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "calculator_submission" */
export type Calculator_Submission_Mutation_Response = {
  __typename?: 'calculator_submission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calculator_Submission>;
};

/** input type for inserting object relation for remote table "calculator_submission" */
export type Calculator_Submission_Obj_Rel_Insert_Input = {
  data: Calculator_Submission_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Calculator_Submission_On_Conflict>;
};

/** on_conflict condition type for table "calculator_submission" */
export type Calculator_Submission_On_Conflict = {
  constraint: Calculator_Submission_Constraint;
  update_columns?: Array<Calculator_Submission_Update_Column>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};

/** Ordering options when selecting data from "calculator_submission". */
export type Calculator_Submission_Order_By = {
  allow_updates_until?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  hubspot_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  preferred_body_types?: Maybe<Order_By>;
  refereers_aggregate?: Maybe<Calculator_Submission_Aggregate_Order_By>;
  referrer?: Maybe<Calculator_Submission_Order_By>;
  referrer_id?: Maybe<Order_By>;
  registration_lookup_response?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle?: Maybe<Known_Vehicles_Order_By>;
  vehicle_id?: Maybe<Order_By>;
  vehicle_identifier_type?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: calculator_submission */
export type Calculator_Submission_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calculator_Submission_Prepend_Input = {
  preferred_body_types?: Maybe<Scalars['jsonb']>;
  registration_lookup_response?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "calculator_submission" */
export enum Calculator_Submission_Select_Column {
  /** column name */
  AllowUpdatesUntil = 'allow_updates_until',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentEmissions = 'current_emissions',
  /** column name */
  CurrentMaintenanceCosts = 'current_maintenance_costs',
  /** column name */
  CurrentTravelCosts = 'current_travel_costs',
  /** column name */
  DrivingDistance = 'driving_distance',
  /** column name */
  Email = 'email',
  /** column name */
  FutureEmissions = 'future_emissions',
  /** column name */
  FutureMaintenanceCosts = 'future_maintenance_costs',
  /** column name */
  FutureTravelCosts = 'future_travel_costs',
  /** column name */
  HubspotId = 'hubspot_id',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  PreferredBodyTypes = 'preferred_body_types',
  /** column name */
  ReferrerId = 'referrer_id',
  /** column name */
  RegistrationLookupResponse = 'registration_lookup_response',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  Touched = 'touched',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleIdentifierType = 'vehicle_identifier_type',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "calculator_submission" */
export type Calculator_Submission_Set_Input = {
  allow_updates_until?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  preferred_body_types?: Maybe<Scalars['jsonb']>;
  referrer_id?: Maybe<Scalars['uuid']>;
  registration_lookup_response?: Maybe<Scalars['jsonb']>;
  registration_number?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  state_of_registration?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_identifier_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Calculator_Submission_Stddev_Fields = {
  __typename?: 'calculator_submission_stddev_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "calculator_submission" */
export type Calculator_Submission_Stddev_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calculator_Submission_Stddev_Pop_Fields = {
  __typename?: 'calculator_submission_stddev_pop_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "calculator_submission" */
export type Calculator_Submission_Stddev_Pop_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calculator_Submission_Stddev_Samp_Fields = {
  __typename?: 'calculator_submission_stddev_samp_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "calculator_submission" */
export type Calculator_Submission_Stddev_Samp_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** Streaming cursor of the table "calculator_submission" */
export type Calculator_Submission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calculator_Submission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calculator_Submission_Stream_Cursor_Value_Input = {
  allow_updates_until?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  preferred_body_types?: Maybe<Scalars['jsonb']>;
  referrer_id?: Maybe<Scalars['uuid']>;
  registration_lookup_response?: Maybe<Scalars['jsonb']>;
  registration_number?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  state_of_registration?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  vehicle_identifier_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Calculator_Submission_Sum_Fields = {
  __typename?: 'calculator_submission_sum_fields';
  current_emissions?: Maybe<Scalars['numeric']>;
  current_maintenance_costs?: Maybe<Scalars['numeric']>;
  current_travel_costs?: Maybe<Scalars['numeric']>;
  driving_distance?: Maybe<Scalars['numeric']>;
  future_emissions?: Maybe<Scalars['numeric']>;
  future_maintenance_costs?: Maybe<Scalars['numeric']>;
  future_travel_costs?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "calculator_submission" */
export type Calculator_Submission_Sum_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** update columns of table "calculator_submission" */
export enum Calculator_Submission_Update_Column {
  /** column name */
  AllowUpdatesUntil = 'allow_updates_until',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentEmissions = 'current_emissions',
  /** column name */
  CurrentMaintenanceCosts = 'current_maintenance_costs',
  /** column name */
  CurrentTravelCosts = 'current_travel_costs',
  /** column name */
  DrivingDistance = 'driving_distance',
  /** column name */
  Email = 'email',
  /** column name */
  FutureEmissions = 'future_emissions',
  /** column name */
  FutureMaintenanceCosts = 'future_maintenance_costs',
  /** column name */
  FutureTravelCosts = 'future_travel_costs',
  /** column name */
  HubspotId = 'hubspot_id',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  PreferredBodyTypes = 'preferred_body_types',
  /** column name */
  ReferrerId = 'referrer_id',
  /** column name */
  RegistrationLookupResponse = 'registration_lookup_response',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  Touched = 'touched',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  VehicleIdentifierType = 'vehicle_identifier_type',
  /** column name */
  Year = 'year'
}

export type Calculator_Submission_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Calculator_Submission_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Calculator_Submission_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Calculator_Submission_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Calculator_Submission_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Calculator_Submission_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Calculator_Submission_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Calculator_Submission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calculator_Submission_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calculator_Submission_Var_Pop_Fields = {
  __typename?: 'calculator_submission_var_pop_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "calculator_submission" */
export type Calculator_Submission_Var_Pop_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calculator_Submission_Var_Samp_Fields = {
  __typename?: 'calculator_submission_var_samp_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "calculator_submission" */
export type Calculator_Submission_Var_Samp_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Calculator_Submission_Variance_Fields = {
  __typename?: 'calculator_submission_variance_fields';
  current_emissions?: Maybe<Scalars['Float']>;
  current_maintenance_costs?: Maybe<Scalars['Float']>;
  current_travel_costs?: Maybe<Scalars['Float']>;
  driving_distance?: Maybe<Scalars['Float']>;
  future_emissions?: Maybe<Scalars['Float']>;
  future_maintenance_costs?: Maybe<Scalars['Float']>;
  future_travel_costs?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "calculator_submission" */
export type Calculator_Submission_Variance_Order_By = {
  current_emissions?: Maybe<Order_By>;
  current_maintenance_costs?: Maybe<Order_By>;
  current_travel_costs?: Maybe<Order_By>;
  driving_distance?: Maybe<Order_By>;
  future_emissions?: Maybe<Order_By>;
  future_maintenance_costs?: Maybe<Order_By>;
  future_travel_costs?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['citext']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['citext']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['citext']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['citext']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['citext']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "custom_field_values" */
export type Custom_Field_Values = {
  __typename?: 'custom_field_values';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  custom_field?: Maybe<Custom_Fields>;
  entity: Scalars['String'];
  /** An object relationship */
  form?: Maybe<Forms>;
  form_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "custom_field_values" */
export type Custom_Field_ValuesValueArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "custom_field_values" */
export type Custom_Field_Values_Aggregate = {
  __typename?: 'custom_field_values_aggregate';
  aggregate?: Maybe<Custom_Field_Values_Aggregate_Fields>;
  nodes: Array<Custom_Field_Values>;
};

export type Custom_Field_Values_Aggregate_Bool_Exp = {
  count?: Maybe<Custom_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Custom_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Custom_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "custom_field_values" */
export type Custom_Field_Values_Aggregate_Fields = {
  __typename?: 'custom_field_values_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Custom_Field_Values_Max_Fields>;
  min?: Maybe<Custom_Field_Values_Min_Fields>;
};


/** aggregate fields of "custom_field_values" */
export type Custom_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "custom_field_values" */
export type Custom_Field_Values_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Custom_Field_Values_Max_Order_By>;
  min?: Maybe<Custom_Field_Values_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Custom_Field_Values_Append_Input = {
  value?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "custom_field_values" */
export type Custom_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Custom_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Custom_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "custom_field_values". All fields are combined with a logical 'AND'. */
export type Custom_Field_Values_Bool_Exp = {
  _and?: Maybe<Array<Custom_Field_Values_Bool_Exp>>;
  _not?: Maybe<Custom_Field_Values_Bool_Exp>;
  _or?: Maybe<Array<Custom_Field_Values_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_field?: Maybe<Custom_Fields_Bool_Exp>;
  entity?: Maybe<String_Comparison_Exp>;
  form?: Maybe<Forms_Bool_Exp>;
  form_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  value?: Maybe<Jsonb_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_field_values" */
export enum Custom_Field_Values_Constraint {
  /** unique or primary key constraint on columns "user_id", "entity", "showroom_id", "name" */
  CustomFieldValuesNameShowroomIdEntityUserIdKey = 'custom_field_values_name_showroom_id_entity_user_id_key',
  /** unique or primary key constraint on columns "vehicle_order_id", "entity", "showroom_id", "name" */
  CustomFieldValuesNameShowroomIdEntityVehicleOrderIdKe = 'custom_field_values_name_showroom_id_entity_vehicle_order_id_ke',
  /** unique or primary key constraint on columns "id" */
  CustomFieldValuesPkey = 'custom_field_values_pkey',
  /** unique or primary key constraint on columns "entity", "showroom_id", "location_id", "name" */
  CustomFieldValuesShowroomIdEntityLocationIdNameKey = 'custom_field_values_showroom_id_entity_location_id_name_key',
  /** unique or primary key constraint on columns "entity", "showroom_id", "form_id", "name" */
  CustomFieldValuesShowroomIdFormIdNameEntityKey = 'custom_field_values_showroom_id_form_id_name_entity_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Custom_Field_Values_Delete_At_Path_Input = {
  value?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Custom_Field_Values_Delete_Elem_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Custom_Field_Values_Delete_Key_Input = {
  value?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "custom_field_values" */
export type Custom_Field_Values_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_field?: Maybe<Custom_Fields_Obj_Rel_Insert_Input>;
  entity?: Maybe<Scalars['String']>;
  form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['jsonb']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Custom_Field_Values_Max_Fields = {
  __typename?: 'custom_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "custom_field_values" */
export type Custom_Field_Values_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Custom_Field_Values_Min_Fields = {
  __typename?: 'custom_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "custom_field_values" */
export type Custom_Field_Values_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "custom_field_values" */
export type Custom_Field_Values_Mutation_Response = {
  __typename?: 'custom_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Field_Values>;
};

/** input type for inserting object relation for remote table "custom_field_values" */
export type Custom_Field_Values_Obj_Rel_Insert_Input = {
  data: Custom_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Custom_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "custom_field_values" */
export type Custom_Field_Values_On_Conflict = {
  constraint: Custom_Field_Values_Constraint;
  update_columns?: Array<Custom_Field_Values_Update_Column>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_field_values". */
export type Custom_Field_Values_Order_By = {
  created_at?: Maybe<Order_By>;
  custom_field?: Maybe<Custom_Fields_Order_By>;
  entity?: Maybe<Order_By>;
  form?: Maybe<Forms_Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: custom_field_values */
export type Custom_Field_Values_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Custom_Field_Values_Prepend_Input = {
  value?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "custom_field_values" */
export enum Custom_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Entity = 'entity',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "custom_field_values" */
export type Custom_Field_Values_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  entity?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['jsonb']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "custom_field_values" */
export type Custom_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  entity?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['jsonb']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "custom_field_values" */
export enum Custom_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Entity = 'entity',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Custom_Field_Values_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Custom_Field_Values_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Custom_Field_Values_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Custom_Field_Values_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Custom_Field_Values_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Custom_Field_Values_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Custom_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Field_Values_Bool_Exp;
};

/** columns and relationships of "custom_fields" */
export type Custom_Fields = {
  __typename?: 'custom_fields';
  col?: Maybe<Scalars['String']>;
  /** An object relationship */
  custom_field_value?: Maybe<Custom_Field_Values>;
  default_value?: Maybe<Scalars['jsonb']>;
  entity: Scalars['String'];
  help_text?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  rules?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  type: Scalars['String'];
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsDefault_ValueArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsOptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsRulesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "custom_fields" */
export type Custom_Fields_Aggregate = {
  __typename?: 'custom_fields_aggregate';
  aggregate?: Maybe<Custom_Fields_Aggregate_Fields>;
  nodes: Array<Custom_Fields>;
};

export type Custom_Fields_Aggregate_Bool_Exp = {
  count?: Maybe<Custom_Fields_Aggregate_Bool_Exp_Count>;
};

export type Custom_Fields_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Custom_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Custom_Fields_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "custom_fields" */
export type Custom_Fields_Aggregate_Fields = {
  __typename?: 'custom_fields_aggregate_fields';
  avg?: Maybe<Custom_Fields_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Custom_Fields_Max_Fields>;
  min?: Maybe<Custom_Fields_Min_Fields>;
  stddev?: Maybe<Custom_Fields_Stddev_Fields>;
  stddev_pop?: Maybe<Custom_Fields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Custom_Fields_Stddev_Samp_Fields>;
  sum?: Maybe<Custom_Fields_Sum_Fields>;
  var_pop?: Maybe<Custom_Fields_Var_Pop_Fields>;
  var_samp?: Maybe<Custom_Fields_Var_Samp_Fields>;
  variance?: Maybe<Custom_Fields_Variance_Fields>;
};


/** aggregate fields of "custom_fields" */
export type Custom_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Custom_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "custom_fields" */
export type Custom_Fields_Aggregate_Order_By = {
  avg?: Maybe<Custom_Fields_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Custom_Fields_Max_Order_By>;
  min?: Maybe<Custom_Fields_Min_Order_By>;
  stddev?: Maybe<Custom_Fields_Stddev_Order_By>;
  stddev_pop?: Maybe<Custom_Fields_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Custom_Fields_Stddev_Samp_Order_By>;
  sum?: Maybe<Custom_Fields_Sum_Order_By>;
  var_pop?: Maybe<Custom_Fields_Var_Pop_Order_By>;
  var_samp?: Maybe<Custom_Fields_Var_Samp_Order_By>;
  variance?: Maybe<Custom_Fields_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Custom_Fields_Append_Input = {
  default_value?: Maybe<Scalars['jsonb']>;
  options?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "custom_fields" */
export type Custom_Fields_Arr_Rel_Insert_Input = {
  data: Array<Custom_Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};

/** aggregate avg on columns */
export type Custom_Fields_Avg_Fields = {
  __typename?: 'custom_fields_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "custom_fields" */
export type Custom_Fields_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "custom_fields". All fields are combined with a logical 'AND'. */
export type Custom_Fields_Bool_Exp = {
  _and?: Maybe<Array<Custom_Fields_Bool_Exp>>;
  _not?: Maybe<Custom_Fields_Bool_Exp>;
  _or?: Maybe<Array<Custom_Fields_Bool_Exp>>;
  col?: Maybe<String_Comparison_Exp>;
  custom_field_value?: Maybe<Custom_Field_Values_Bool_Exp>;
  default_value?: Maybe<Jsonb_Comparison_Exp>;
  entity?: Maybe<String_Comparison_Exp>;
  help_text?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  options?: Maybe<Jsonb_Comparison_Exp>;
  options_layout?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  rules?: Maybe<Jsonb_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_fields" */
export enum Custom_Fields_Constraint {
  /** unique or primary key constraint on columns "entity", "showroom_id", "name" */
  CustomFieldsPkey = 'custom_fields_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Custom_Fields_Delete_At_Path_Input = {
  default_value?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<Array<Scalars['String']>>;
  rules?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Custom_Fields_Delete_Elem_Input = {
  default_value?: Maybe<Scalars['Int']>;
  options?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Custom_Fields_Delete_Key_Input = {
  default_value?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "custom_fields" */
export type Custom_Fields_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "custom_fields" */
export type Custom_Fields_Insert_Input = {
  col?: Maybe<Scalars['String']>;
  custom_field_value?: Maybe<Custom_Field_Values_Obj_Rel_Insert_Input>;
  default_value?: Maybe<Scalars['jsonb']>;
  entity?: Maybe<Scalars['String']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['jsonb']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Custom_Fields_Max_Fields = {
  __typename?: 'custom_fields_max_fields';
  col?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "custom_fields" */
export type Custom_Fields_Max_Order_By = {
  col?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  help_text?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options_layout?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Custom_Fields_Min_Fields = {
  __typename?: 'custom_fields_min_fields';
  col?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "custom_fields" */
export type Custom_Fields_Min_Order_By = {
  col?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  help_text?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options_layout?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "custom_fields" */
export type Custom_Fields_Mutation_Response = {
  __typename?: 'custom_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Fields>;
};

/** input type for inserting object relation for remote table "custom_fields" */
export type Custom_Fields_Obj_Rel_Insert_Input = {
  data: Custom_Fields_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};

/** on_conflict condition type for table "custom_fields" */
export type Custom_Fields_On_Conflict = {
  constraint: Custom_Fields_Constraint;
  update_columns?: Array<Custom_Fields_Update_Column>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_fields". */
export type Custom_Fields_Order_By = {
  col?: Maybe<Order_By>;
  custom_field_value?: Maybe<Custom_Field_Values_Order_By>;
  default_value?: Maybe<Order_By>;
  entity?: Maybe<Order_By>;
  help_text?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options?: Maybe<Order_By>;
  options_layout?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  rules?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: custom_fields */
export type Custom_Fields_Pk_Columns_Input = {
  entity: Scalars['String'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Custom_Fields_Prepend_Input = {
  default_value?: Maybe<Scalars['jsonb']>;
  options?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "custom_fields" */
export enum Custom_Fields_Select_Column {
  /** column name */
  Col = 'col',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  Entity = 'entity',
  /** column name */
  HelpText = 'help_text',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  OptionsLayout = 'options_layout',
  /** column name */
  Order = 'order',
  /** column name */
  Rules = 'rules',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "custom_fields" */
export type Custom_Fields_Set_Input = {
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['jsonb']>;
  entity?: Maybe<Scalars['String']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['jsonb']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Custom_Fields_Stddev_Fields = {
  __typename?: 'custom_fields_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "custom_fields" */
export type Custom_Fields_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Custom_Fields_Stddev_Pop_Fields = {
  __typename?: 'custom_fields_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "custom_fields" */
export type Custom_Fields_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Custom_Fields_Stddev_Samp_Fields = {
  __typename?: 'custom_fields_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "custom_fields" */
export type Custom_Fields_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "custom_fields" */
export type Custom_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Fields_Stream_Cursor_Value_Input = {
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['jsonb']>;
  entity?: Maybe<Scalars['String']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['jsonb']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Custom_Fields_Sum_Fields = {
  __typename?: 'custom_fields_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "custom_fields" */
export type Custom_Fields_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "custom_fields" */
export enum Custom_Fields_Update_Column {
  /** column name */
  Col = 'col',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  Entity = 'entity',
  /** column name */
  HelpText = 'help_text',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  OptionsLayout = 'options_layout',
  /** column name */
  Order = 'order',
  /** column name */
  Rules = 'rules',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Type = 'type'
}

export type Custom_Fields_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Custom_Fields_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Custom_Fields_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Custom_Fields_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Custom_Fields_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Custom_Fields_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Custom_Fields_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Custom_Fields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Fields_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Custom_Fields_Var_Pop_Fields = {
  __typename?: 'custom_fields_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "custom_fields" */
export type Custom_Fields_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Custom_Fields_Var_Samp_Fields = {
  __typename?: 'custom_fields_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "custom_fields" */
export type Custom_Fields_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Custom_Fields_Variance_Fields = {
  __typename?: 'custom_fields_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "custom_fields" */
export type Custom_Fields_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** Custom built vehicles produced through the configurator */
export type Custom_Vehicles = {
  __typename?: 'custom_vehicles';
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  configurator: Scalars['jsonb'];
  configurator_code: Scalars['String'];
  configurator_values: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  make: Scalars['String'];
  /** Deprecated. Use custom_vehicles.assets instead. */
  media?: Maybe<Scalars['jsonb']>;
  model: Scalars['String'];
  production_year: Scalars['String'];
  series?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  /** An object relationship */
  vehicle_specification?: Maybe<Vehicle_Specifications>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesConfiguratorArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesConfigurator_ValuesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesMediaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** Custom built vehicles produced through the configurator */
export type Custom_VehiclesVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};

/** aggregated selection of "custom_vehicles" */
export type Custom_Vehicles_Aggregate = {
  __typename?: 'custom_vehicles_aggregate';
  aggregate?: Maybe<Custom_Vehicles_Aggregate_Fields>;
  nodes: Array<Custom_Vehicles>;
};

export type Custom_Vehicles_Aggregate_Bool_Exp = {
  count?: Maybe<Custom_Vehicles_Aggregate_Bool_Exp_Count>;
};

export type Custom_Vehicles_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Custom_Vehicles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "custom_vehicles" */
export type Custom_Vehicles_Aggregate_Fields = {
  __typename?: 'custom_vehicles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Custom_Vehicles_Max_Fields>;
  min?: Maybe<Custom_Vehicles_Min_Fields>;
};


/** aggregate fields of "custom_vehicles" */
export type Custom_Vehicles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "custom_vehicles" */
export type Custom_Vehicles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Custom_Vehicles_Max_Order_By>;
  min?: Maybe<Custom_Vehicles_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Custom_Vehicles_Append_Input = {
  configurator?: Maybe<Scalars['jsonb']>;
  configurator_values?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "custom_vehicles" */
export type Custom_Vehicles_Arr_Rel_Insert_Input = {
  data: Array<Custom_Vehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Custom_Vehicles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "custom_vehicles". All fields are combined with a logical 'AND'. */
export type Custom_Vehicles_Bool_Exp = {
  _and?: Maybe<Array<Custom_Vehicles_Bool_Exp>>;
  _not?: Maybe<Custom_Vehicles_Bool_Exp>;
  _or?: Maybe<Array<Custom_Vehicles_Bool_Exp>>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  badge?: Maybe<String_Comparison_Exp>;
  body_type?: Maybe<String_Comparison_Exp>;
  configurator?: Maybe<Jsonb_Comparison_Exp>;
  configurator_code?: Maybe<String_Comparison_Exp>;
  configurator_values?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  media?: Maybe<Jsonb_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  production_year?: Maybe<String_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_orders?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
  vehicle_specification?: Maybe<Vehicle_Specifications_Bool_Exp>;
  vehicle_specification_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_vehicles" */
export enum Custom_Vehicles_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomVehiclesPkey = 'custom_vehicles_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Custom_Vehicles_Delete_At_Path_Input = {
  configurator?: Maybe<Array<Scalars['String']>>;
  configurator_values?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Custom_Vehicles_Delete_Elem_Input = {
  configurator?: Maybe<Scalars['Int']>;
  configurator_values?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Custom_Vehicles_Delete_Key_Input = {
  configurator?: Maybe<Scalars['String']>;
  configurator_values?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "custom_vehicles" */
export type Custom_Vehicles_Insert_Input = {
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  configurator?: Maybe<Scalars['jsonb']>;
  configurator_code?: Maybe<Scalars['String']>;
  configurator_values?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_orders?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
  vehicle_specification?: Maybe<Vehicle_Specifications_Obj_Rel_Insert_Input>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Custom_Vehicles_Max_Fields = {
  __typename?: 'custom_vehicles_max_fields';
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  configurator_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "custom_vehicles" */
export type Custom_Vehicles_Max_Order_By = {
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  configurator_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_specification_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Custom_Vehicles_Min_Fields = {
  __typename?: 'custom_vehicles_min_fields';
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  configurator_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "custom_vehicles" */
export type Custom_Vehicles_Min_Order_By = {
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  configurator_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_specification_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "custom_vehicles" */
export type Custom_Vehicles_Mutation_Response = {
  __typename?: 'custom_vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Vehicles>;
};

/** input type for inserting object relation for remote table "custom_vehicles" */
export type Custom_Vehicles_Obj_Rel_Insert_Input = {
  data: Custom_Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Custom_Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "custom_vehicles" */
export type Custom_Vehicles_On_Conflict = {
  constraint: Custom_Vehicles_Constraint;
  update_columns?: Array<Custom_Vehicles_Update_Column>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_vehicles". */
export type Custom_Vehicles_Order_By = {
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  configurator?: Maybe<Order_By>;
  configurator_code?: Maybe<Order_By>;
  configurator_values?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  media?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
  vehicle_specification?: Maybe<Vehicle_Specifications_Order_By>;
  vehicle_specification_id?: Maybe<Order_By>;
};

/** primary key columns input for table: custom_vehicles */
export type Custom_Vehicles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Custom_Vehicles_Prepend_Input = {
  configurator?: Maybe<Scalars['jsonb']>;
  configurator_values?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "custom_vehicles" */
export enum Custom_Vehicles_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  Configurator = 'configurator',
  /** column name */
  ConfiguratorCode = 'configurator_code',
  /** column name */
  ConfiguratorValues = 'configurator_values',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Series = 'series',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleSpecificationId = 'vehicle_specification_id'
}

/** input type for updating data in table "custom_vehicles" */
export type Custom_Vehicles_Set_Input = {
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  configurator?: Maybe<Scalars['jsonb']>;
  configurator_code?: Maybe<Scalars['String']>;
  configurator_values?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "custom_vehicles" */
export type Custom_Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Vehicles_Stream_Cursor_Value_Input = {
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  configurator?: Maybe<Scalars['jsonb']>;
  configurator_code?: Maybe<Scalars['String']>;
  configurator_values?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "custom_vehicles" */
export enum Custom_Vehicles_Update_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  Configurator = 'configurator',
  /** column name */
  ConfiguratorCode = 'configurator_code',
  /** column name */
  ConfiguratorValues = 'configurator_values',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Series = 'series',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleSpecificationId = 'vehicle_specification_id'
}

export type Custom_Vehicles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Custom_Vehicles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Custom_Vehicles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Custom_Vehicles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Custom_Vehicles_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Custom_Vehicles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Custom_Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Vehicles_Bool_Exp;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "filter_fields" */
export type Filter_Fields = {
  __typename?: 'filter_fields';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  location: Filter_Locations_Enum;
  name: Filter_Names_Enum;
  options?: Maybe<Scalars['jsonb']>;
  order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle_list: Vehicle_Lists;
  vehicle_list_id: Scalars['uuid'];
};


/** columns and relationships of "filter_fields" */
export type Filter_FieldsOptionsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "filter_fields" */
export type Filter_Fields_Aggregate = {
  __typename?: 'filter_fields_aggregate';
  aggregate?: Maybe<Filter_Fields_Aggregate_Fields>;
  nodes: Array<Filter_Fields>;
};

export type Filter_Fields_Aggregate_Bool_Exp = {
  count?: Maybe<Filter_Fields_Aggregate_Bool_Exp_Count>;
};

export type Filter_Fields_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Filter_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Filter_Fields_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "filter_fields" */
export type Filter_Fields_Aggregate_Fields = {
  __typename?: 'filter_fields_aggregate_fields';
  avg?: Maybe<Filter_Fields_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filter_Fields_Max_Fields>;
  min?: Maybe<Filter_Fields_Min_Fields>;
  stddev?: Maybe<Filter_Fields_Stddev_Fields>;
  stddev_pop?: Maybe<Filter_Fields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filter_Fields_Stddev_Samp_Fields>;
  sum?: Maybe<Filter_Fields_Sum_Fields>;
  var_pop?: Maybe<Filter_Fields_Var_Pop_Fields>;
  var_samp?: Maybe<Filter_Fields_Var_Samp_Fields>;
  variance?: Maybe<Filter_Fields_Variance_Fields>;
};


/** aggregate fields of "filter_fields" */
export type Filter_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "filter_fields" */
export type Filter_Fields_Aggregate_Order_By = {
  avg?: Maybe<Filter_Fields_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Filter_Fields_Max_Order_By>;
  min?: Maybe<Filter_Fields_Min_Order_By>;
  stddev?: Maybe<Filter_Fields_Stddev_Order_By>;
  stddev_pop?: Maybe<Filter_Fields_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Filter_Fields_Stddev_Samp_Order_By>;
  sum?: Maybe<Filter_Fields_Sum_Order_By>;
  var_pop?: Maybe<Filter_Fields_Var_Pop_Order_By>;
  var_samp?: Maybe<Filter_Fields_Var_Samp_Order_By>;
  variance?: Maybe<Filter_Fields_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Filter_Fields_Append_Input = {
  options?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "filter_fields" */
export type Filter_Fields_Arr_Rel_Insert_Input = {
  data: Array<Filter_Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Filter_Fields_On_Conflict>;
};

/** aggregate avg on columns */
export type Filter_Fields_Avg_Fields = {
  __typename?: 'filter_fields_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "filter_fields" */
export type Filter_Fields_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "filter_fields". All fields are combined with a logical 'AND'. */
export type Filter_Fields_Bool_Exp = {
  _and?: Maybe<Array<Filter_Fields_Bool_Exp>>;
  _not?: Maybe<Filter_Fields_Bool_Exp>;
  _or?: Maybe<Array<Filter_Fields_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Filter_Locations_Enum_Comparison_Exp>;
  name?: Maybe<Filter_Names_Enum_Comparison_Exp>;
  options?: Maybe<Jsonb_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_list?: Maybe<Vehicle_Lists_Bool_Exp>;
  vehicle_list_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_fields" */
export enum Filter_Fields_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilterFieldsPkey = 'filter_fields_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Filter_Fields_Delete_At_Path_Input = {
  options?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Filter_Fields_Delete_Elem_Input = {
  options?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Filter_Fields_Delete_Key_Input = {
  options?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "filter_fields" */
export type Filter_Fields_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "filter_fields" */
export type Filter_Fields_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  location?: Maybe<Filter_Locations_Enum>;
  name?: Maybe<Filter_Names_Enum>;
  options?: Maybe<Scalars['jsonb']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_list?: Maybe<Vehicle_Lists_Obj_Rel_Insert_Input>;
  vehicle_list_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Filter_Fields_Max_Fields = {
  __typename?: 'filter_fields_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_list_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "filter_fields" */
export type Filter_Fields_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_list_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Filter_Fields_Min_Fields = {
  __typename?: 'filter_fields_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_list_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "filter_fields" */
export type Filter_Fields_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_list_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "filter_fields" */
export type Filter_Fields_Mutation_Response = {
  __typename?: 'filter_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Fields>;
};

/** on_conflict condition type for table "filter_fields" */
export type Filter_Fields_On_Conflict = {
  constraint: Filter_Fields_Constraint;
  update_columns?: Array<Filter_Fields_Update_Column>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_fields". */
export type Filter_Fields_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_list?: Maybe<Vehicle_Lists_Order_By>;
  vehicle_list_id?: Maybe<Order_By>;
};

/** primary key columns input for table: filter_fields */
export type Filter_Fields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Filter_Fields_Prepend_Input = {
  options?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "filter_fields" */
export enum Filter_Fields_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleListId = 'vehicle_list_id'
}

/** input type for updating data in table "filter_fields" */
export type Filter_Fields_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  location?: Maybe<Filter_Locations_Enum>;
  name?: Maybe<Filter_Names_Enum>;
  options?: Maybe<Scalars['jsonb']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_list_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Filter_Fields_Stddev_Fields = {
  __typename?: 'filter_fields_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "filter_fields" */
export type Filter_Fields_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Filter_Fields_Stddev_Pop_Fields = {
  __typename?: 'filter_fields_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "filter_fields" */
export type Filter_Fields_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Filter_Fields_Stddev_Samp_Fields = {
  __typename?: 'filter_fields_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "filter_fields" */
export type Filter_Fields_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "filter_fields" */
export type Filter_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filter_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filter_Fields_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  location?: Maybe<Filter_Locations_Enum>;
  name?: Maybe<Filter_Names_Enum>;
  options?: Maybe<Scalars['jsonb']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_list_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Filter_Fields_Sum_Fields = {
  __typename?: 'filter_fields_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "filter_fields" */
export type Filter_Fields_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "filter_fields" */
export enum Filter_Fields_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleListId = 'vehicle_list_id'
}

export type Filter_Fields_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Filter_Fields_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Filter_Fields_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Filter_Fields_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Filter_Fields_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Filter_Fields_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Filter_Fields_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Filter_Fields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filter_Fields_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Filter_Fields_Var_Pop_Fields = {
  __typename?: 'filter_fields_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "filter_fields" */
export type Filter_Fields_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Filter_Fields_Var_Samp_Fields = {
  __typename?: 'filter_fields_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "filter_fields" */
export type Filter_Fields_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Filter_Fields_Variance_Fields = {
  __typename?: 'filter_fields_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "filter_fields" */
export type Filter_Fields_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "filter_locations" */
export type Filter_Locations = {
  __typename?: 'filter_locations';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "filter_locations" */
export type Filter_Locations_Aggregate = {
  __typename?: 'filter_locations_aggregate';
  aggregate?: Maybe<Filter_Locations_Aggregate_Fields>;
  nodes: Array<Filter_Locations>;
};

/** aggregate fields of "filter_locations" */
export type Filter_Locations_Aggregate_Fields = {
  __typename?: 'filter_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Filter_Locations_Max_Fields>;
  min?: Maybe<Filter_Locations_Min_Fields>;
};


/** aggregate fields of "filter_locations" */
export type Filter_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "filter_locations". All fields are combined with a logical 'AND'. */
export type Filter_Locations_Bool_Exp = {
  _and?: Maybe<Array<Filter_Locations_Bool_Exp>>;
  _not?: Maybe<Filter_Locations_Bool_Exp>;
  _or?: Maybe<Array<Filter_Locations_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_locations" */
export enum Filter_Locations_Constraint {
  /** unique or primary key constraint on columns "value" */
  FilterLocationsPkey = 'filter_locations_pkey'
}

export enum Filter_Locations_Enum {
  LeftSidebar = 'left_sidebar',
  Modal = 'modal',
  TopLeft = 'top_left',
  TopRight = 'top_right'
}

/** Boolean expression to compare columns of type "filter_locations_enum". All fields are combined with logical 'AND'. */
export type Filter_Locations_Enum_Comparison_Exp = {
  _eq?: Maybe<Filter_Locations_Enum>;
  _in?: Maybe<Array<Filter_Locations_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Filter_Locations_Enum>;
  _nin?: Maybe<Array<Filter_Locations_Enum>>;
};

/** input type for inserting data into table "filter_locations" */
export type Filter_Locations_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Locations_Max_Fields = {
  __typename?: 'filter_locations_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Locations_Min_Fields = {
  __typename?: 'filter_locations_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_locations" */
export type Filter_Locations_Mutation_Response = {
  __typename?: 'filter_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Locations>;
};

/** on_conflict condition type for table "filter_locations" */
export type Filter_Locations_On_Conflict = {
  constraint: Filter_Locations_Constraint;
  update_columns?: Array<Filter_Locations_Update_Column>;
  where?: Maybe<Filter_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_locations". */
export type Filter_Locations_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: filter_locations */
export type Filter_Locations_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "filter_locations" */
export enum Filter_Locations_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "filter_locations" */
export type Filter_Locations_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "filter_locations" */
export type Filter_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filter_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filter_Locations_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "filter_locations" */
export enum Filter_Locations_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Filter_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Filter_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filter_Locations_Bool_Exp;
};

/** columns and relationships of "filter_names" */
export type Filter_Names = {
  __typename?: 'filter_names';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "filter_names" */
export type Filter_Names_Aggregate = {
  __typename?: 'filter_names_aggregate';
  aggregate?: Maybe<Filter_Names_Aggregate_Fields>;
  nodes: Array<Filter_Names>;
};

/** aggregate fields of "filter_names" */
export type Filter_Names_Aggregate_Fields = {
  __typename?: 'filter_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Filter_Names_Max_Fields>;
  min?: Maybe<Filter_Names_Min_Fields>;
};


/** aggregate fields of "filter_names" */
export type Filter_Names_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Names_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "filter_names". All fields are combined with a logical 'AND'. */
export type Filter_Names_Bool_Exp = {
  _and?: Maybe<Array<Filter_Names_Bool_Exp>>;
  _not?: Maybe<Filter_Names_Bool_Exp>;
  _or?: Maybe<Array<Filter_Names_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_names" */
export enum Filter_Names_Constraint {
  /** unique or primary key constraint on columns "value" */
  FilterFieldNamesPkey = 'filter_field_names_pkey'
}

export enum Filter_Names_Enum {
  Custom = '_custom',
  Badge = 'badge',
  /** Combined filter of both max_price and max_repayment */
  Budget = 'budget',
  Colour = 'colour',
  Condition = 'condition',
  Finance = 'finance',
  FuelType = 'fuel_type',
  HasDiscount = 'has_discount',
  Interior = 'interior',
  IsOnWishlist = 'is_on_wishlist',
  Lifestyle = 'lifestyle',
  Location = 'location',
  Make = 'make',
  MaxPrice = 'max_price',
  MaxRepayment = 'max_repayment',
  MinPrice = 'min_price',
  Model = 'model',
  Odometer = 'odometer',
  PackCodes = 'pack_codes',
  Packs = 'packs',
  PersonalOrConsumer = 'personal_or_consumer',
  PricingCode = 'pricing_code',
  ProductionYear = 'production_year',
  Search = 'search',
  Sort = 'sort'
}

/** Boolean expression to compare columns of type "filter_names_enum". All fields are combined with logical 'AND'. */
export type Filter_Names_Enum_Comparison_Exp = {
  _eq?: Maybe<Filter_Names_Enum>;
  _in?: Maybe<Array<Filter_Names_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Filter_Names_Enum>;
  _nin?: Maybe<Array<Filter_Names_Enum>>;
};

/** input type for inserting data into table "filter_names" */
export type Filter_Names_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Names_Max_Fields = {
  __typename?: 'filter_names_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Names_Min_Fields = {
  __typename?: 'filter_names_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_names" */
export type Filter_Names_Mutation_Response = {
  __typename?: 'filter_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Names>;
};

/** on_conflict condition type for table "filter_names" */
export type Filter_Names_On_Conflict = {
  constraint: Filter_Names_Constraint;
  update_columns?: Array<Filter_Names_Update_Column>;
  where?: Maybe<Filter_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_names". */
export type Filter_Names_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: filter_names */
export type Filter_Names_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "filter_names" */
export enum Filter_Names_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "filter_names" */
export type Filter_Names_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "filter_names" */
export type Filter_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Filter_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filter_Names_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "filter_names" */
export enum Filter_Names_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Filter_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Filter_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filter_Names_Bool_Exp;
};

/** columns and relationships of "finance_applications" */
export type Finance_Applications = {
  __typename?: 'finance_applications';
  admin_comments?: Maybe<Scalars['String']>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Scalars['String']>;
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  assets?: Maybe<Scalars['jsonb']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  business_identifier?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deposit?: Maybe<Scalars['numeric']>;
  driva_offer_id?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  employment_type?: Maybe<Scalars['String']>;
  expenses: Scalars['jsonb'];
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Scalars['String']>;
  /** An object relationship */
  finance_product?: Maybe<Finance_Products>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  include_incentives_in_repayment: Scalars['Boolean'];
  /** Other income, aside from employment income. */
  income: Scalars['jsonb'];
  is_commercial: Scalars['Boolean'];
  is_income_likely_to_change_within_12_months: Scalars['Boolean'];
  is_income_likely_to_change_within_12_months_details?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  liabilities?: Maybe<Scalars['jsonb']>;
  living_situation?: Maybe<Scalars['String']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  occupation?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  partner_application_id?: Maybe<Scalars['String']>;
  partner_application_response?: Maybe<Scalars['jsonb']>;
  partner_application_status?: Maybe<Scalars['String']>;
  partner_application_url?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['jsonb']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  previous_employment_type?: Maybe<Scalars['String']>;
  previous_occupation?: Maybe<Scalars['String']>;
  previous_residential_address?: Maybe<Scalars['jsonb']>;
  relationship_status?: Maybe<Scalars['String']>;
  relative_address?: Maybe<Scalars['jsonb']>;
  relative_full_name?: Maybe<Scalars['String']>;
  relative_phone_number?: Maybe<Scalars['String']>;
  relative_relationship_type?: Maybe<Scalars['String']>;
  repayment_frequency?: Maybe<Finance_Frequency_Enum>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  residency?: Maybe<Scalars['String']>;
  residential_address?: Maybe<Scalars['jsonb']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  stage: Scalars['String'];
  submitted_to_partner_at?: Maybe<Scalars['timestamptz']>;
  terms_and_conditions?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  vehicle_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  vehicle_vin?: Maybe<Scalars['String']>;
  visa_code?: Maybe<Scalars['String']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsAssetsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsExpensesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsIncomeArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsLiabilitiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsPartner_Application_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsPostal_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsPrevious_Residential_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsRelative_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsResidential_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsTerms_And_ConditionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "finance_applications" */
export type Finance_ApplicationsVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};

/** aggregated selection of "finance_applications" */
export type Finance_Applications_Aggregate = {
  __typename?: 'finance_applications_aggregate';
  aggregate?: Maybe<Finance_Applications_Aggregate_Fields>;
  nodes: Array<Finance_Applications>;
};

export type Finance_Applications_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Finance_Applications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Finance_Applications_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Finance_Applications_Aggregate_Bool_Exp_Count>;
};

export type Finance_Applications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Finance_Applications_Select_Column_Finance_Applications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Applications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Finance_Applications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Finance_Applications_Select_Column_Finance_Applications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Applications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Finance_Applications_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Finance_Applications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Applications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "finance_applications" */
export type Finance_Applications_Aggregate_Fields = {
  __typename?: 'finance_applications_aggregate_fields';
  avg?: Maybe<Finance_Applications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Applications_Max_Fields>;
  min?: Maybe<Finance_Applications_Min_Fields>;
  stddev?: Maybe<Finance_Applications_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Applications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Applications_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Applications_Sum_Fields>;
  var_pop?: Maybe<Finance_Applications_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Applications_Var_Samp_Fields>;
  variance?: Maybe<Finance_Applications_Variance_Fields>;
};


/** aggregate fields of "finance_applications" */
export type Finance_Applications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Applications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finance_applications" */
export type Finance_Applications_Aggregate_Order_By = {
  avg?: Maybe<Finance_Applications_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Finance_Applications_Max_Order_By>;
  min?: Maybe<Finance_Applications_Min_Order_By>;
  stddev?: Maybe<Finance_Applications_Stddev_Order_By>;
  stddev_pop?: Maybe<Finance_Applications_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Finance_Applications_Stddev_Samp_Order_By>;
  sum?: Maybe<Finance_Applications_Sum_Order_By>;
  var_pop?: Maybe<Finance_Applications_Var_Pop_Order_By>;
  var_samp?: Maybe<Finance_Applications_Var_Samp_Order_By>;
  variance?: Maybe<Finance_Applications_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Finance_Applications_Append_Input = {
  assets?: Maybe<Scalars['jsonb']>;
  expenses?: Maybe<Scalars['jsonb']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['jsonb']>;
  liabilities?: Maybe<Scalars['jsonb']>;
  partner_application_response?: Maybe<Scalars['jsonb']>;
  postal_address?: Maybe<Scalars['jsonb']>;
  previous_residential_address?: Maybe<Scalars['jsonb']>;
  relative_address?: Maybe<Scalars['jsonb']>;
  residential_address?: Maybe<Scalars['jsonb']>;
  terms_and_conditions?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "finance_applications" */
export type Finance_Applications_Arr_Rel_Insert_Input = {
  data: Array<Finance_Applications_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Applications_On_Conflict>;
};

/** aggregate avg on columns */
export type Finance_Applications_Avg_Fields = {
  __typename?: 'finance_applications_avg_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "finance_applications" */
export type Finance_Applications_Avg_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "finance_applications". All fields are combined with a logical 'AND'. */
export type Finance_Applications_Bool_Exp = {
  _and?: Maybe<Array<Finance_Applications_Bool_Exp>>;
  _not?: Maybe<Finance_Applications_Bool_Exp>;
  _or?: Maybe<Array<Finance_Applications_Bool_Exp>>;
  admin_comments?: Maybe<String_Comparison_Exp>;
  age_of_dependents?: Maybe<String_Comparison_Exp>;
  annual_driving_distance?: Maybe<Numeric_Comparison_Exp>;
  annual_net_income?: Maybe<Numeric_Comparison_Exp>;
  assets?: Maybe<Jsonb_Comparison_Exp>;
  awarded_credit_amount?: Maybe<Numeric_Comparison_Exp>;
  awarded_credit_balloon?: Maybe<Numeric_Comparison_Exp>;
  awarded_credit_term?: Maybe<Int_Comparison_Exp>;
  business_duration?: Maybe<Numeric_Comparison_Exp>;
  business_identifier?: Maybe<String_Comparison_Exp>;
  business_name?: Maybe<String_Comparison_Exp>;
  country_of_birth?: Maybe<String_Comparison_Exp>;
  country_of_citizenship?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deposit?: Maybe<Numeric_Comparison_Exp>;
  driva_offer_id?: Maybe<String_Comparison_Exp>;
  employer_name?: Maybe<String_Comparison_Exp>;
  employment_duration?: Maybe<Numeric_Comparison_Exp>;
  employment_type?: Maybe<String_Comparison_Exp>;
  expenses?: Maybe<Jsonb_Comparison_Exp>;
  finance_partner?: Maybe<String_Comparison_Exp>;
  finance_product?: Maybe<Finance_Products_Bool_Exp>;
  finance_product_id?: Maybe<Uuid_Comparison_Exp>;
  home_rent_or_mortgage_repayment?: Maybe<Numeric_Comparison_Exp>;
  home_value?: Maybe<Numeric_Comparison_Exp>;
  hubspot_deal_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  include_incentives_in_repayment?: Maybe<Boolean_Comparison_Exp>;
  income?: Maybe<Jsonb_Comparison_Exp>;
  is_commercial?: Maybe<Boolean_Comparison_Exp>;
  is_income_likely_to_change_within_12_months?: Maybe<Boolean_Comparison_Exp>;
  is_income_likely_to_change_within_12_months_details?: Maybe<String_Comparison_Exp>;
  last_step?: Maybe<String_Comparison_Exp>;
  liabilities?: Maybe<Jsonb_Comparison_Exp>;
  living_situation?: Maybe<String_Comparison_Exp>;
  mortgage_value?: Maybe<Numeric_Comparison_Exp>;
  number_of_dependents?: Maybe<Int_Comparison_Exp>;
  occupation?: Maybe<String_Comparison_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  partner_application_id?: Maybe<String_Comparison_Exp>;
  partner_application_response?: Maybe<Jsonb_Comparison_Exp>;
  partner_application_status?: Maybe<String_Comparison_Exp>;
  partner_application_url?: Maybe<String_Comparison_Exp>;
  postal_address?: Maybe<Jsonb_Comparison_Exp>;
  previous_employer_name?: Maybe<String_Comparison_Exp>;
  previous_employment_duration?: Maybe<Numeric_Comparison_Exp>;
  previous_employment_type?: Maybe<String_Comparison_Exp>;
  previous_occupation?: Maybe<String_Comparison_Exp>;
  previous_residential_address?: Maybe<Jsonb_Comparison_Exp>;
  relationship_status?: Maybe<String_Comparison_Exp>;
  relative_address?: Maybe<Jsonb_Comparison_Exp>;
  relative_full_name?: Maybe<String_Comparison_Exp>;
  relative_phone_number?: Maybe<String_Comparison_Exp>;
  relative_relationship_type?: Maybe<String_Comparison_Exp>;
  repayment_frequency?: Maybe<Finance_Frequency_Enum_Comparison_Exp>;
  requested_credit_amount?: Maybe<Numeric_Comparison_Exp>;
  requested_credit_balloon?: Maybe<Numeric_Comparison_Exp>;
  requested_credit_term?: Maybe<Int_Comparison_Exp>;
  residency?: Maybe<String_Comparison_Exp>;
  residential_address?: Maybe<Jsonb_Comparison_Exp>;
  secondary_country_of_citizenship?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  source_name?: Maybe<String_Comparison_Exp>;
  spouse_annual_net_income?: Maybe<Numeric_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  submitted_to_partner_at?: Maybe<Timestamptz_Comparison_Exp>;
  terms_and_conditions?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_description?: Maybe<String_Comparison_Exp>;
  vehicle_orders?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
  vehicle_purchase_price?: Maybe<Numeric_Comparison_Exp>;
  vehicle_vin?: Maybe<String_Comparison_Exp>;
  visa_code?: Maybe<String_Comparison_Exp>;
  visa_duration?: Maybe<Numeric_Comparison_Exp>;
  years_at_previous_residential_address?: Maybe<Numeric_Comparison_Exp>;
  years_at_residential_address?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_applications" */
export enum Finance_Applications_Constraint {
  /** unique or primary key constraint on columns "partner_application_id" */
  FinanceApplicationsFinancierApplicationIdKey = 'finance_applications_financier_application_id_key',
  /** unique or primary key constraint on columns "id" */
  FinanceApplicationsPkey = 'finance_applications_pkey',
  /** unique or primary key constraint on columns "source_id", "source_name" */
  FinanceApplicationsSourceIdSourceNameKey = 'finance_applications_source_id_source_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Finance_Applications_Delete_At_Path_Input = {
  assets?: Maybe<Array<Scalars['String']>>;
  expenses?: Maybe<Array<Scalars['String']>>;
  /** Other income, aside from employment income. */
  income?: Maybe<Array<Scalars['String']>>;
  liabilities?: Maybe<Array<Scalars['String']>>;
  partner_application_response?: Maybe<Array<Scalars['String']>>;
  postal_address?: Maybe<Array<Scalars['String']>>;
  previous_residential_address?: Maybe<Array<Scalars['String']>>;
  relative_address?: Maybe<Array<Scalars['String']>>;
  residential_address?: Maybe<Array<Scalars['String']>>;
  terms_and_conditions?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Finance_Applications_Delete_Elem_Input = {
  assets?: Maybe<Scalars['Int']>;
  expenses?: Maybe<Scalars['Int']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['Int']>;
  liabilities?: Maybe<Scalars['Int']>;
  partner_application_response?: Maybe<Scalars['Int']>;
  postal_address?: Maybe<Scalars['Int']>;
  previous_residential_address?: Maybe<Scalars['Int']>;
  relative_address?: Maybe<Scalars['Int']>;
  residential_address?: Maybe<Scalars['Int']>;
  terms_and_conditions?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Finance_Applications_Delete_Key_Input = {
  assets?: Maybe<Scalars['String']>;
  expenses?: Maybe<Scalars['String']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['String']>;
  liabilities?: Maybe<Scalars['String']>;
  partner_application_response?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['String']>;
  previous_residential_address?: Maybe<Scalars['String']>;
  relative_address?: Maybe<Scalars['String']>;
  residential_address?: Maybe<Scalars['String']>;
  terms_and_conditions?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "finance_applications" */
export type Finance_Applications_Inc_Input = {
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "finance_applications" */
export type Finance_Applications_Insert_Input = {
  admin_comments?: Maybe<Scalars['String']>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Scalars['String']>;
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  assets?: Maybe<Scalars['jsonb']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  business_identifier?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['numeric']>;
  driva_offer_id?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  employment_type?: Maybe<Scalars['String']>;
  expenses?: Maybe<Scalars['jsonb']>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Scalars['String']>;
  finance_product?: Maybe<Finance_Products_Obj_Rel_Insert_Input>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  include_incentives_in_repayment?: Maybe<Scalars['Boolean']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['jsonb']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  is_income_likely_to_change_within_12_months?: Maybe<Scalars['Boolean']>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  liabilities?: Maybe<Scalars['jsonb']>;
  living_situation?: Maybe<Scalars['String']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  occupation?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  partner_application_id?: Maybe<Scalars['String']>;
  partner_application_response?: Maybe<Scalars['jsonb']>;
  partner_application_status?: Maybe<Scalars['String']>;
  partner_application_url?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['jsonb']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  previous_employment_type?: Maybe<Scalars['String']>;
  previous_occupation?: Maybe<Scalars['String']>;
  previous_residential_address?: Maybe<Scalars['jsonb']>;
  relationship_status?: Maybe<Scalars['String']>;
  relative_address?: Maybe<Scalars['jsonb']>;
  relative_full_name?: Maybe<Scalars['String']>;
  relative_phone_number?: Maybe<Scalars['String']>;
  relative_relationship_type?: Maybe<Scalars['String']>;
  repayment_frequency?: Maybe<Finance_Frequency_Enum>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  residency?: Maybe<Scalars['String']>;
  residential_address?: Maybe<Scalars['jsonb']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  stage?: Maybe<Scalars['String']>;
  submitted_to_partner_at?: Maybe<Scalars['timestamptz']>;
  terms_and_conditions?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_description?: Maybe<Scalars['String']>;
  vehicle_orders?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  vehicle_vin?: Maybe<Scalars['String']>;
  visa_code?: Maybe<Scalars['String']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Finance_Applications_Max_Fields = {
  __typename?: 'finance_applications_max_fields';
  admin_comments?: Maybe<Scalars['String']>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Scalars['String']>;
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  business_identifier?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['numeric']>;
  driva_offer_id?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  employment_type?: Maybe<Scalars['String']>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Scalars['String']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  living_situation?: Maybe<Scalars['String']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  occupation?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  partner_application_id?: Maybe<Scalars['String']>;
  partner_application_status?: Maybe<Scalars['String']>;
  partner_application_url?: Maybe<Scalars['String']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  previous_employment_type?: Maybe<Scalars['String']>;
  previous_occupation?: Maybe<Scalars['String']>;
  relationship_status?: Maybe<Scalars['String']>;
  relative_full_name?: Maybe<Scalars['String']>;
  relative_phone_number?: Maybe<Scalars['String']>;
  relative_relationship_type?: Maybe<Scalars['String']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  residency?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  stage?: Maybe<Scalars['String']>;
  submitted_to_partner_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_description?: Maybe<Scalars['String']>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  vehicle_vin?: Maybe<Scalars['String']>;
  visa_code?: Maybe<Scalars['String']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "finance_applications" */
export type Finance_Applications_Max_Order_By = {
  admin_comments?: Maybe<Order_By>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Order_By>;
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  business_identifier?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Order_By>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  driva_offer_id?: Maybe<Order_By>;
  employer_name?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  employment_type?: Maybe<Order_By>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Order_By>;
  finance_product_id?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  living_situation?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  partner_application_id?: Maybe<Order_By>;
  partner_application_status?: Maybe<Order_By>;
  partner_application_url?: Maybe<Order_By>;
  previous_employer_name?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  previous_employment_type?: Maybe<Order_By>;
  previous_occupation?: Maybe<Order_By>;
  relationship_status?: Maybe<Order_By>;
  relative_full_name?: Maybe<Order_By>;
  relative_phone_number?: Maybe<Order_By>;
  relative_relationship_type?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  residency?: Maybe<Order_By>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  submitted_to_partner_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_description?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  vehicle_vin?: Maybe<Order_By>;
  visa_code?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Finance_Applications_Min_Fields = {
  __typename?: 'finance_applications_min_fields';
  admin_comments?: Maybe<Scalars['String']>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Scalars['String']>;
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  business_identifier?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['numeric']>;
  driva_offer_id?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  employment_type?: Maybe<Scalars['String']>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Scalars['String']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  living_situation?: Maybe<Scalars['String']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  occupation?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  partner_application_id?: Maybe<Scalars['String']>;
  partner_application_status?: Maybe<Scalars['String']>;
  partner_application_url?: Maybe<Scalars['String']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  previous_employment_type?: Maybe<Scalars['String']>;
  previous_occupation?: Maybe<Scalars['String']>;
  relationship_status?: Maybe<Scalars['String']>;
  relative_full_name?: Maybe<Scalars['String']>;
  relative_phone_number?: Maybe<Scalars['String']>;
  relative_relationship_type?: Maybe<Scalars['String']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  residency?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  stage?: Maybe<Scalars['String']>;
  submitted_to_partner_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_description?: Maybe<Scalars['String']>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  vehicle_vin?: Maybe<Scalars['String']>;
  visa_code?: Maybe<Scalars['String']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "finance_applications" */
export type Finance_Applications_Min_Order_By = {
  admin_comments?: Maybe<Order_By>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Order_By>;
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  business_identifier?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Order_By>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  driva_offer_id?: Maybe<Order_By>;
  employer_name?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  employment_type?: Maybe<Order_By>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Order_By>;
  finance_product_id?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  living_situation?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  partner_application_id?: Maybe<Order_By>;
  partner_application_status?: Maybe<Order_By>;
  partner_application_url?: Maybe<Order_By>;
  previous_employer_name?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  previous_employment_type?: Maybe<Order_By>;
  previous_occupation?: Maybe<Order_By>;
  relationship_status?: Maybe<Order_By>;
  relative_full_name?: Maybe<Order_By>;
  relative_phone_number?: Maybe<Order_By>;
  relative_relationship_type?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  residency?: Maybe<Order_By>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  submitted_to_partner_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_description?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  vehicle_vin?: Maybe<Order_By>;
  visa_code?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** response of any mutation on the table "finance_applications" */
export type Finance_Applications_Mutation_Response = {
  __typename?: 'finance_applications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Applications>;
};

/** input type for inserting object relation for remote table "finance_applications" */
export type Finance_Applications_Obj_Rel_Insert_Input = {
  data: Finance_Applications_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Applications_On_Conflict>;
};

/** on_conflict condition type for table "finance_applications" */
export type Finance_Applications_On_Conflict = {
  constraint: Finance_Applications_Constraint;
  update_columns?: Array<Finance_Applications_Update_Column>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_applications". */
export type Finance_Applications_Order_By = {
  admin_comments?: Maybe<Order_By>;
  age_of_dependents?: Maybe<Order_By>;
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  assets?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  business_duration?: Maybe<Order_By>;
  business_identifier?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  country_of_birth?: Maybe<Order_By>;
  country_of_citizenship?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  driva_offer_id?: Maybe<Order_By>;
  employer_name?: Maybe<Order_By>;
  employment_duration?: Maybe<Order_By>;
  employment_type?: Maybe<Order_By>;
  expenses?: Maybe<Order_By>;
  finance_partner?: Maybe<Order_By>;
  finance_product?: Maybe<Finance_Products_Order_By>;
  finance_product_id?: Maybe<Order_By>;
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  include_incentives_in_repayment?: Maybe<Order_By>;
  income?: Maybe<Order_By>;
  is_commercial?: Maybe<Order_By>;
  is_income_likely_to_change_within_12_months?: Maybe<Order_By>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  liabilities?: Maybe<Order_By>;
  living_situation?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  partner_application_id?: Maybe<Order_By>;
  partner_application_response?: Maybe<Order_By>;
  partner_application_status?: Maybe<Order_By>;
  partner_application_url?: Maybe<Order_By>;
  postal_address?: Maybe<Order_By>;
  previous_employer_name?: Maybe<Order_By>;
  previous_employment_duration?: Maybe<Order_By>;
  previous_employment_type?: Maybe<Order_By>;
  previous_occupation?: Maybe<Order_By>;
  previous_residential_address?: Maybe<Order_By>;
  relationship_status?: Maybe<Order_By>;
  relative_address?: Maybe<Order_By>;
  relative_full_name?: Maybe<Order_By>;
  relative_phone_number?: Maybe<Order_By>;
  relative_relationship_type?: Maybe<Order_By>;
  repayment_frequency?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  residency?: Maybe<Order_By>;
  residential_address?: Maybe<Order_By>;
  secondary_country_of_citizenship?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  submitted_to_partner_at?: Maybe<Order_By>;
  terms_and_conditions?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_description?: Maybe<Order_By>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  vehicle_vin?: Maybe<Order_By>;
  visa_code?: Maybe<Order_By>;
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_applications */
export type Finance_Applications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Finance_Applications_Prepend_Input = {
  assets?: Maybe<Scalars['jsonb']>;
  expenses?: Maybe<Scalars['jsonb']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['jsonb']>;
  liabilities?: Maybe<Scalars['jsonb']>;
  partner_application_response?: Maybe<Scalars['jsonb']>;
  postal_address?: Maybe<Scalars['jsonb']>;
  previous_residential_address?: Maybe<Scalars['jsonb']>;
  relative_address?: Maybe<Scalars['jsonb']>;
  residential_address?: Maybe<Scalars['jsonb']>;
  terms_and_conditions?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "finance_applications" */
export enum Finance_Applications_Select_Column {
  /** column name */
  AdminComments = 'admin_comments',
  /** column name */
  AgeOfDependents = 'age_of_dependents',
  /** column name */
  AnnualDrivingDistance = 'annual_driving_distance',
  /** column name */
  AnnualNetIncome = 'annual_net_income',
  /** column name */
  Assets = 'assets',
  /** column name */
  AwardedCreditAmount = 'awarded_credit_amount',
  /** column name */
  AwardedCreditBalloon = 'awarded_credit_balloon',
  /** column name */
  AwardedCreditTerm = 'awarded_credit_term',
  /** column name */
  BusinessDuration = 'business_duration',
  /** column name */
  BusinessIdentifier = 'business_identifier',
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CountryOfBirth = 'country_of_birth',
  /** column name */
  CountryOfCitizenship = 'country_of_citizenship',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  DrivaOfferId = 'driva_offer_id',
  /** column name */
  EmployerName = 'employer_name',
  /** column name */
  EmploymentDuration = 'employment_duration',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  Expenses = 'expenses',
  /** column name */
  FinancePartner = 'finance_partner',
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  HomeRentOrMortgageRepayment = 'home_rent_or_mortgage_repayment',
  /** column name */
  HomeValue = 'home_value',
  /** column name */
  HubspotDealId = 'hubspot_deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  IncludeIncentivesInRepayment = 'include_incentives_in_repayment',
  /** column name */
  Income = 'income',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsIncomeLikelyToChangeWithin_12Months = 'is_income_likely_to_change_within_12_months',
  /** column name */
  IsIncomeLikelyToChangeWithin_12MonthsDetails = 'is_income_likely_to_change_within_12_months_details',
  /** column name */
  LastStep = 'last_step',
  /** column name */
  Liabilities = 'liabilities',
  /** column name */
  LivingSituation = 'living_situation',
  /** column name */
  MortgageValue = 'mortgage_value',
  /** column name */
  NumberOfDependents = 'number_of_dependents',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PartnerApplicationId = 'partner_application_id',
  /** column name */
  PartnerApplicationResponse = 'partner_application_response',
  /** column name */
  PartnerApplicationStatus = 'partner_application_status',
  /** column name */
  PartnerApplicationUrl = 'partner_application_url',
  /** column name */
  PostalAddress = 'postal_address',
  /** column name */
  PreviousEmployerName = 'previous_employer_name',
  /** column name */
  PreviousEmploymentDuration = 'previous_employment_duration',
  /** column name */
  PreviousEmploymentType = 'previous_employment_type',
  /** column name */
  PreviousOccupation = 'previous_occupation',
  /** column name */
  PreviousResidentialAddress = 'previous_residential_address',
  /** column name */
  RelationshipStatus = 'relationship_status',
  /** column name */
  RelativeAddress = 'relative_address',
  /** column name */
  RelativeFullName = 'relative_full_name',
  /** column name */
  RelativePhoneNumber = 'relative_phone_number',
  /** column name */
  RelativeRelationshipType = 'relative_relationship_type',
  /** column name */
  RepaymentFrequency = 'repayment_frequency',
  /** column name */
  RequestedCreditAmount = 'requested_credit_amount',
  /** column name */
  RequestedCreditBalloon = 'requested_credit_balloon',
  /** column name */
  RequestedCreditTerm = 'requested_credit_term',
  /** column name */
  Residency = 'residency',
  /** column name */
  ResidentialAddress = 'residential_address',
  /** column name */
  SecondaryCountryOfCitizenship = 'secondary_country_of_citizenship',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  SpouseAnnualNetIncome = 'spouse_annual_net_income',
  /** column name */
  Stage = 'stage',
  /** column name */
  SubmittedToPartnerAt = 'submitted_to_partner_at',
  /** column name */
  TermsAndConditions = 'terms_and_conditions',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleDescription = 'vehicle_description',
  /** column name */
  VehiclePurchasePrice = 'vehicle_purchase_price',
  /** column name */
  VehicleVin = 'vehicle_vin',
  /** column name */
  VisaCode = 'visa_code',
  /** column name */
  VisaDuration = 'visa_duration',
  /** column name */
  YearsAtPreviousResidentialAddress = 'years_at_previous_residential_address',
  /** column name */
  YearsAtResidentialAddress = 'years_at_residential_address'
}

/** select "finance_applications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "finance_applications" */
export enum Finance_Applications_Select_Column_Finance_Applications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IncludeIncentivesInRepayment = 'include_incentives_in_repayment',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsIncomeLikelyToChangeWithin_12Months = 'is_income_likely_to_change_within_12_months'
}

/** select "finance_applications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "finance_applications" */
export enum Finance_Applications_Select_Column_Finance_Applications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IncludeIncentivesInRepayment = 'include_incentives_in_repayment',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsIncomeLikelyToChangeWithin_12Months = 'is_income_likely_to_change_within_12_months'
}

/** input type for updating data in table "finance_applications" */
export type Finance_Applications_Set_Input = {
  admin_comments?: Maybe<Scalars['String']>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Scalars['String']>;
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  assets?: Maybe<Scalars['jsonb']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  business_identifier?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['numeric']>;
  driva_offer_id?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  employment_type?: Maybe<Scalars['String']>;
  expenses?: Maybe<Scalars['jsonb']>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Scalars['String']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  include_incentives_in_repayment?: Maybe<Scalars['Boolean']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['jsonb']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  is_income_likely_to_change_within_12_months?: Maybe<Scalars['Boolean']>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  liabilities?: Maybe<Scalars['jsonb']>;
  living_situation?: Maybe<Scalars['String']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  occupation?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  partner_application_id?: Maybe<Scalars['String']>;
  partner_application_response?: Maybe<Scalars['jsonb']>;
  partner_application_status?: Maybe<Scalars['String']>;
  partner_application_url?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['jsonb']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  previous_employment_type?: Maybe<Scalars['String']>;
  previous_occupation?: Maybe<Scalars['String']>;
  previous_residential_address?: Maybe<Scalars['jsonb']>;
  relationship_status?: Maybe<Scalars['String']>;
  relative_address?: Maybe<Scalars['jsonb']>;
  relative_full_name?: Maybe<Scalars['String']>;
  relative_phone_number?: Maybe<Scalars['String']>;
  relative_relationship_type?: Maybe<Scalars['String']>;
  repayment_frequency?: Maybe<Finance_Frequency_Enum>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  residency?: Maybe<Scalars['String']>;
  residential_address?: Maybe<Scalars['jsonb']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  stage?: Maybe<Scalars['String']>;
  submitted_to_partner_at?: Maybe<Scalars['timestamptz']>;
  terms_and_conditions?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_description?: Maybe<Scalars['String']>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  vehicle_vin?: Maybe<Scalars['String']>;
  visa_code?: Maybe<Scalars['String']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Finance_Applications_Stddev_Fields = {
  __typename?: 'finance_applications_stddev_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "finance_applications" */
export type Finance_Applications_Stddev_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Finance_Applications_Stddev_Pop_Fields = {
  __typename?: 'finance_applications_stddev_pop_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "finance_applications" */
export type Finance_Applications_Stddev_Pop_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Finance_Applications_Stddev_Samp_Fields = {
  __typename?: 'finance_applications_stddev_samp_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "finance_applications" */
export type Finance_Applications_Stddev_Samp_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** Streaming cursor of the table "finance_applications" */
export type Finance_Applications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Applications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Applications_Stream_Cursor_Value_Input = {
  admin_comments?: Maybe<Scalars['String']>;
  /** The age of the dependents, represented as a comma-delimited string. */
  age_of_dependents?: Maybe<Scalars['String']>;
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  assets?: Maybe<Scalars['jsonb']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  business_identifier?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_birth?: Maybe<Scalars['String']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  country_of_citizenship?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['numeric']>;
  driva_offer_id?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  employment_type?: Maybe<Scalars['String']>;
  expenses?: Maybe<Scalars['jsonb']>;
  /** Deprecated. Use finance_product_id instead */
  finance_partner?: Maybe<Scalars['String']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  include_incentives_in_repayment?: Maybe<Scalars['Boolean']>;
  /** Other income, aside from employment income. */
  income?: Maybe<Scalars['jsonb']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  is_income_likely_to_change_within_12_months?: Maybe<Scalars['Boolean']>;
  is_income_likely_to_change_within_12_months_details?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  liabilities?: Maybe<Scalars['jsonb']>;
  living_situation?: Maybe<Scalars['String']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  occupation?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  partner_application_id?: Maybe<Scalars['String']>;
  partner_application_response?: Maybe<Scalars['jsonb']>;
  partner_application_status?: Maybe<Scalars['String']>;
  partner_application_url?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['jsonb']>;
  previous_employer_name?: Maybe<Scalars['String']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  previous_employment_type?: Maybe<Scalars['String']>;
  previous_occupation?: Maybe<Scalars['String']>;
  previous_residential_address?: Maybe<Scalars['jsonb']>;
  relationship_status?: Maybe<Scalars['String']>;
  relative_address?: Maybe<Scalars['jsonb']>;
  relative_full_name?: Maybe<Scalars['String']>;
  relative_phone_number?: Maybe<Scalars['String']>;
  relative_relationship_type?: Maybe<Scalars['String']>;
  repayment_frequency?: Maybe<Finance_Frequency_Enum>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  residency?: Maybe<Scalars['String']>;
  residential_address?: Maybe<Scalars['jsonb']>;
  /** Uppercase 2-letter ISO 3166 country code. E.g. "AU" = "Australia" */
  secondary_country_of_citizenship?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  stage?: Maybe<Scalars['String']>;
  submitted_to_partner_at?: Maybe<Scalars['timestamptz']>;
  terms_and_conditions?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_description?: Maybe<Scalars['String']>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  vehicle_vin?: Maybe<Scalars['String']>;
  visa_code?: Maybe<Scalars['String']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Finance_Applications_Sum_Fields = {
  __typename?: 'finance_applications_sum_fields';
  annual_driving_distance?: Maybe<Scalars['numeric']>;
  annual_net_income?: Maybe<Scalars['numeric']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['Int']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['numeric']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['numeric']>;
  home_value?: Maybe<Scalars['numeric']>;
  mortgage_value?: Maybe<Scalars['numeric']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['numeric']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['Int']>;
  spouse_annual_net_income?: Maybe<Scalars['numeric']>;
  vehicle_purchase_price?: Maybe<Scalars['numeric']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['numeric']>;
  years_at_previous_residential_address?: Maybe<Scalars['numeric']>;
  years_at_residential_address?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "finance_applications" */
export type Finance_Applications_Sum_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** update columns of table "finance_applications" */
export enum Finance_Applications_Update_Column {
  /** column name */
  AdminComments = 'admin_comments',
  /** column name */
  AgeOfDependents = 'age_of_dependents',
  /** column name */
  AnnualDrivingDistance = 'annual_driving_distance',
  /** column name */
  AnnualNetIncome = 'annual_net_income',
  /** column name */
  Assets = 'assets',
  /** column name */
  AwardedCreditAmount = 'awarded_credit_amount',
  /** column name */
  AwardedCreditBalloon = 'awarded_credit_balloon',
  /** column name */
  AwardedCreditTerm = 'awarded_credit_term',
  /** column name */
  BusinessDuration = 'business_duration',
  /** column name */
  BusinessIdentifier = 'business_identifier',
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CountryOfBirth = 'country_of_birth',
  /** column name */
  CountryOfCitizenship = 'country_of_citizenship',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  DrivaOfferId = 'driva_offer_id',
  /** column name */
  EmployerName = 'employer_name',
  /** column name */
  EmploymentDuration = 'employment_duration',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  Expenses = 'expenses',
  /** column name */
  FinancePartner = 'finance_partner',
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  HomeRentOrMortgageRepayment = 'home_rent_or_mortgage_repayment',
  /** column name */
  HomeValue = 'home_value',
  /** column name */
  HubspotDealId = 'hubspot_deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  IncludeIncentivesInRepayment = 'include_incentives_in_repayment',
  /** column name */
  Income = 'income',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsIncomeLikelyToChangeWithin_12Months = 'is_income_likely_to_change_within_12_months',
  /** column name */
  IsIncomeLikelyToChangeWithin_12MonthsDetails = 'is_income_likely_to_change_within_12_months_details',
  /** column name */
  LastStep = 'last_step',
  /** column name */
  Liabilities = 'liabilities',
  /** column name */
  LivingSituation = 'living_situation',
  /** column name */
  MortgageValue = 'mortgage_value',
  /** column name */
  NumberOfDependents = 'number_of_dependents',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PartnerApplicationId = 'partner_application_id',
  /** column name */
  PartnerApplicationResponse = 'partner_application_response',
  /** column name */
  PartnerApplicationStatus = 'partner_application_status',
  /** column name */
  PartnerApplicationUrl = 'partner_application_url',
  /** column name */
  PostalAddress = 'postal_address',
  /** column name */
  PreviousEmployerName = 'previous_employer_name',
  /** column name */
  PreviousEmploymentDuration = 'previous_employment_duration',
  /** column name */
  PreviousEmploymentType = 'previous_employment_type',
  /** column name */
  PreviousOccupation = 'previous_occupation',
  /** column name */
  PreviousResidentialAddress = 'previous_residential_address',
  /** column name */
  RelationshipStatus = 'relationship_status',
  /** column name */
  RelativeAddress = 'relative_address',
  /** column name */
  RelativeFullName = 'relative_full_name',
  /** column name */
  RelativePhoneNumber = 'relative_phone_number',
  /** column name */
  RelativeRelationshipType = 'relative_relationship_type',
  /** column name */
  RepaymentFrequency = 'repayment_frequency',
  /** column name */
  RequestedCreditAmount = 'requested_credit_amount',
  /** column name */
  RequestedCreditBalloon = 'requested_credit_balloon',
  /** column name */
  RequestedCreditTerm = 'requested_credit_term',
  /** column name */
  Residency = 'residency',
  /** column name */
  ResidentialAddress = 'residential_address',
  /** column name */
  SecondaryCountryOfCitizenship = 'secondary_country_of_citizenship',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  SpouseAnnualNetIncome = 'spouse_annual_net_income',
  /** column name */
  Stage = 'stage',
  /** column name */
  SubmittedToPartnerAt = 'submitted_to_partner_at',
  /** column name */
  TermsAndConditions = 'terms_and_conditions',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleDescription = 'vehicle_description',
  /** column name */
  VehiclePurchasePrice = 'vehicle_purchase_price',
  /** column name */
  VehicleVin = 'vehicle_vin',
  /** column name */
  VisaCode = 'visa_code',
  /** column name */
  VisaDuration = 'visa_duration',
  /** column name */
  YearsAtPreviousResidentialAddress = 'years_at_previous_residential_address',
  /** column name */
  YearsAtResidentialAddress = 'years_at_residential_address'
}

export type Finance_Applications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Finance_Applications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Finance_Applications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Finance_Applications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Finance_Applications_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Applications_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Finance_Applications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Applications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Applications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Applications_Var_Pop_Fields = {
  __typename?: 'finance_applications_var_pop_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "finance_applications" */
export type Finance_Applications_Var_Pop_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Finance_Applications_Var_Samp_Fields = {
  __typename?: 'finance_applications_var_samp_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "finance_applications" */
export type Finance_Applications_Var_Samp_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Finance_Applications_Variance_Fields = {
  __typename?: 'finance_applications_variance_fields';
  annual_driving_distance?: Maybe<Scalars['Float']>;
  annual_net_income?: Maybe<Scalars['Float']>;
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  /** The employment duration in months */
  employment_duration?: Maybe<Scalars['Float']>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Scalars['Float']>;
  home_value?: Maybe<Scalars['Float']>;
  mortgage_value?: Maybe<Scalars['Float']>;
  number_of_dependents?: Maybe<Scalars['Float']>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  spouse_annual_net_income?: Maybe<Scalars['Float']>;
  vehicle_purchase_price?: Maybe<Scalars['Float']>;
  /** The visa duration in months */
  visa_duration?: Maybe<Scalars['Float']>;
  years_at_previous_residential_address?: Maybe<Scalars['Float']>;
  years_at_residential_address?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "finance_applications" */
export type Finance_Applications_Variance_Order_By = {
  annual_driving_distance?: Maybe<Order_By>;
  annual_net_income?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  /** The awarded balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  awarded_credit_balloon?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** How long the business has been in operation in years */
  business_duration?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  /** The employment duration in months */
  employment_duration?: Maybe<Order_By>;
  /** The monthly repayment amount for the applicant's rent or mortgage */
  home_rent_or_mortgage_repayment?: Maybe<Order_By>;
  home_value?: Maybe<Order_By>;
  mortgage_value?: Maybe<Order_By>;
  number_of_dependents?: Maybe<Order_By>;
  /** The previous employment duration in months */
  previous_employment_duration?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  spouse_annual_net_income?: Maybe<Order_By>;
  vehicle_purchase_price?: Maybe<Order_By>;
  /** The visa duration in months */
  visa_duration?: Maybe<Order_By>;
  years_at_previous_residential_address?: Maybe<Order_By>;
  years_at_residential_address?: Maybe<Order_By>;
};

/** columns and relationships of "finance_fees" */
export type Finance_Fees = {
  __typename?: 'finance_fees';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  frequency: Finance_Frequency_Enum;
  id: Scalars['uuid'];
  label: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['Int'];
};

/** aggregated selection of "finance_fees" */
export type Finance_Fees_Aggregate = {
  __typename?: 'finance_fees_aggregate';
  aggregate?: Maybe<Finance_Fees_Aggregate_Fields>;
  nodes: Array<Finance_Fees>;
};

export type Finance_Fees_Aggregate_Bool_Exp = {
  count?: Maybe<Finance_Fees_Aggregate_Bool_Exp_Count>;
};

export type Finance_Fees_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Finance_Fees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Fees_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "finance_fees" */
export type Finance_Fees_Aggregate_Fields = {
  __typename?: 'finance_fees_aggregate_fields';
  avg?: Maybe<Finance_Fees_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Fees_Max_Fields>;
  min?: Maybe<Finance_Fees_Min_Fields>;
  stddev?: Maybe<Finance_Fees_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Fees_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Fees_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Fees_Sum_Fields>;
  var_pop?: Maybe<Finance_Fees_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Fees_Var_Samp_Fields>;
  variance?: Maybe<Finance_Fees_Variance_Fields>;
};


/** aggregate fields of "finance_fees" */
export type Finance_Fees_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Fees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finance_fees" */
export type Finance_Fees_Aggregate_Order_By = {
  avg?: Maybe<Finance_Fees_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Finance_Fees_Max_Order_By>;
  min?: Maybe<Finance_Fees_Min_Order_By>;
  stddev?: Maybe<Finance_Fees_Stddev_Order_By>;
  stddev_pop?: Maybe<Finance_Fees_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Finance_Fees_Stddev_Samp_Order_By>;
  sum?: Maybe<Finance_Fees_Sum_Order_By>;
  var_pop?: Maybe<Finance_Fees_Var_Pop_Order_By>;
  var_samp?: Maybe<Finance_Fees_Var_Samp_Order_By>;
  variance?: Maybe<Finance_Fees_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "finance_fees" */
export type Finance_Fees_Arr_Rel_Insert_Input = {
  data: Array<Finance_Fees_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Fees_On_Conflict>;
};

/** aggregate avg on columns */
export type Finance_Fees_Avg_Fields = {
  __typename?: 'finance_fees_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "finance_fees" */
export type Finance_Fees_Avg_Order_By = {
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "finance_fees". All fields are combined with a logical 'AND'. */
export type Finance_Fees_Bool_Exp = {
  _and?: Maybe<Array<Finance_Fees_Bool_Exp>>;
  _not?: Maybe<Finance_Fees_Bool_Exp>;
  _or?: Maybe<Array<Finance_Fees_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
  finance_fixed_rate_configuration_id?: Maybe<Uuid_Comparison_Exp>;
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
  finance_gfv_configuration_id?: Maybe<Uuid_Comparison_Exp>;
  frequency?: Maybe<Finance_Frequency_Enum_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_fees" */
export enum Finance_Fees_Constraint {
  /** unique or primary key constraint on columns "id" */
  FinanceFeesPkey = 'finance_fees_pkey'
}

/** input type for incrementing numeric columns in table "finance_fees" */
export type Finance_Fees_Inc_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "finance_fees" */
export type Finance_Fees_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations_Obj_Rel_Insert_Input>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations_Obj_Rel_Insert_Input>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  frequency?: Maybe<Finance_Frequency_Enum>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Finance_Fees_Max_Fields = {
  __typename?: 'finance_fees_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "finance_fees" */
export type Finance_Fees_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  finance_fixed_rate_configuration_id?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Finance_Fees_Min_Fields = {
  __typename?: 'finance_fees_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "finance_fees" */
export type Finance_Fees_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  finance_fixed_rate_configuration_id?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "finance_fees" */
export type Finance_Fees_Mutation_Response = {
  __typename?: 'finance_fees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Fees>;
};

/** on_conflict condition type for table "finance_fees" */
export type Finance_Fees_On_Conflict = {
  constraint: Finance_Fees_Constraint;
  update_columns?: Array<Finance_Fees_Update_Column>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_fees". */
export type Finance_Fees_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations_Order_By>;
  finance_fixed_rate_configuration_id?: Maybe<Order_By>;
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations_Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  frequency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_fees */
export type Finance_Fees_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "finance_fees" */
export enum Finance_Fees_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FinanceFixedRateConfigurationId = 'finance_fixed_rate_configuration_id',
  /** column name */
  FinanceGfvConfigurationId = 'finance_gfv_configuration_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "finance_fees" */
export type Finance_Fees_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  frequency?: Maybe<Finance_Frequency_Enum>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Finance_Fees_Stddev_Fields = {
  __typename?: 'finance_fees_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "finance_fees" */
export type Finance_Fees_Stddev_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Finance_Fees_Stddev_Pop_Fields = {
  __typename?: 'finance_fees_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "finance_fees" */
export type Finance_Fees_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Finance_Fees_Stddev_Samp_Fields = {
  __typename?: 'finance_fees_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "finance_fees" */
export type Finance_Fees_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** Streaming cursor of the table "finance_fees" */
export type Finance_Fees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Fees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Fees_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  frequency?: Maybe<Finance_Frequency_Enum>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Finance_Fees_Sum_Fields = {
  __typename?: 'finance_fees_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "finance_fees" */
export type Finance_Fees_Sum_Order_By = {
  value?: Maybe<Order_By>;
};

/** update columns of table "finance_fees" */
export enum Finance_Fees_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FinanceFixedRateConfigurationId = 'finance_fixed_rate_configuration_id',
  /** column name */
  FinanceGfvConfigurationId = 'finance_gfv_configuration_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Finance_Fees_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Fees_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Fees_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Fees_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Fees_Var_Pop_Fields = {
  __typename?: 'finance_fees_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "finance_fees" */
export type Finance_Fees_Var_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Finance_Fees_Var_Samp_Fields = {
  __typename?: 'finance_fees_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "finance_fees" */
export type Finance_Fees_Var_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Finance_Fees_Variance_Fields = {
  __typename?: 'finance_fees_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "finance_fees" */
export type Finance_Fees_Variance_Order_By = {
  value?: Maybe<Order_By>;
};

/** columns and relationships of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations = {
  __typename?: 'finance_fixed_rate_configurations';
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at: Scalars['timestamptz'];
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon: Scalars['numeric'];
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit: Scalars['Int'];
  default_frequency: Finance_Frequency_Enum;
  default_term: Scalars['numeric'];
  /** An array relationship */
  finance_fees: Array<Finance_Fees>;
  /** An aggregate relationship */
  finance_fees_aggregate: Finance_Fees_Aggregate;
  /** An object relationship */
  finance_product: Finance_Products;
  finance_product_id: Scalars['uuid'];
  /** An array relationship */
  finance_terms: Array<Finance_Terms>;
  /** An aggregate relationship */
  finance_terms_aggregate: Finance_Terms_Aggregate;
  id: Scalars['uuid'];
  interest_rate: Scalars['numeric'];
  max_amount?: Maybe<Scalars['Int']>;
  min_amount: Scalars['Int'];
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_ConfigurationsFinance_FeesArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


/** columns and relationships of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_ConfigurationsFinance_Fees_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


/** columns and relationships of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_ConfigurationsFinance_TermsArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


/** columns and relationships of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_ConfigurationsFinance_Terms_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};

/** aggregated selection of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Aggregate = {
  __typename?: 'finance_fixed_rate_configurations_aggregate';
  aggregate?: Maybe<Finance_Fixed_Rate_Configurations_Aggregate_Fields>;
  nodes: Array<Finance_Fixed_Rate_Configurations>;
};

/** aggregate fields of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Aggregate_Fields = {
  __typename?: 'finance_fixed_rate_configurations_aggregate_fields';
  avg?: Maybe<Finance_Fixed_Rate_Configurations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Fixed_Rate_Configurations_Max_Fields>;
  min?: Maybe<Finance_Fixed_Rate_Configurations_Min_Fields>;
  stddev?: Maybe<Finance_Fixed_Rate_Configurations_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Fixed_Rate_Configurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Fixed_Rate_Configurations_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Fixed_Rate_Configurations_Sum_Fields>;
  var_pop?: Maybe<Finance_Fixed_Rate_Configurations_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Fixed_Rate_Configurations_Var_Samp_Fields>;
  variance?: Maybe<Finance_Fixed_Rate_Configurations_Variance_Fields>;
};


/** aggregate fields of "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Fixed_Rate_Configurations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Finance_Fixed_Rate_Configurations_Avg_Fields = {
  __typename?: 'finance_fixed_rate_configurations_avg_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "finance_fixed_rate_configurations". All fields are combined with a logical 'AND'. */
export type Finance_Fixed_Rate_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Finance_Fixed_Rate_Configurations_Bool_Exp>>;
  _not?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Finance_Fixed_Rate_Configurations_Bool_Exp>>;
  comparison_rate?: Maybe<Numeric_Comparison_Exp>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_balloon?: Maybe<Numeric_Comparison_Exp>;
  default_deposit?: Maybe<Int_Comparison_Exp>;
  default_frequency?: Maybe<Finance_Frequency_Enum_Comparison_Exp>;
  default_term?: Maybe<Numeric_Comparison_Exp>;
  finance_fees?: Maybe<Finance_Fees_Bool_Exp>;
  finance_fees_aggregate?: Maybe<Finance_Fees_Aggregate_Bool_Exp>;
  finance_product?: Maybe<Finance_Products_Bool_Exp>;
  finance_product_id?: Maybe<Uuid_Comparison_Exp>;
  finance_terms?: Maybe<Finance_Terms_Bool_Exp>;
  finance_terms_aggregate?: Maybe<Finance_Terms_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interest_rate?: Maybe<Numeric_Comparison_Exp>;
  max_amount?: Maybe<Int_Comparison_Exp>;
  min_amount?: Maybe<Int_Comparison_Exp>;
  representative_rate?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_fixed_rate_configurations" */
export enum Finance_Fixed_Rate_Configurations_Constraint {
  /** unique or primary key constraint on columns "finance_product_id" */
  FinanceFixedRateConfigurationsFinanceProductIdKey = 'finance_fixed_rate_configurations_finance_product_id_key',
  /** unique or primary key constraint on columns "id" */
  FinanceFixedRateConfigurationsPkey = 'finance_fixed_rate_configurations_pkey'
}

/** input type for incrementing numeric columns in table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Inc_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Insert_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_frequency?: Maybe<Finance_Frequency_Enum>;
  default_term?: Maybe<Scalars['numeric']>;
  finance_fees?: Maybe<Finance_Fees_Arr_Rel_Insert_Input>;
  finance_product?: Maybe<Finance_Products_Obj_Rel_Insert_Input>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  finance_terms?: Maybe<Finance_Terms_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Finance_Fixed_Rate_Configurations_Max_Fields = {
  __typename?: 'finance_fixed_rate_configurations_max_fields';
  comparison_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['numeric']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Finance_Fixed_Rate_Configurations_Min_Fields = {
  __typename?: 'finance_fixed_rate_configurations_min_fields';
  comparison_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['numeric']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Mutation_Response = {
  __typename?: 'finance_fixed_rate_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Fixed_Rate_Configurations>;
};

/** input type for inserting object relation for remote table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Obj_Rel_Insert_Input = {
  data: Finance_Fixed_Rate_Configurations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Fixed_Rate_Configurations_On_Conflict>;
};

/** on_conflict condition type for table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_On_Conflict = {
  constraint: Finance_Fixed_Rate_Configurations_Constraint;
  update_columns?: Array<Finance_Fixed_Rate_Configurations_Update_Column>;
  where?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_fixed_rate_configurations". */
export type Finance_Fixed_Rate_Configurations_Order_By = {
  comparison_rate?: Maybe<Order_By>;
  compounding_frequency?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  default_balloon?: Maybe<Order_By>;
  default_deposit?: Maybe<Order_By>;
  default_frequency?: Maybe<Order_By>;
  default_term?: Maybe<Order_By>;
  finance_fees_aggregate?: Maybe<Finance_Fees_Aggregate_Order_By>;
  finance_product?: Maybe<Finance_Products_Order_By>;
  finance_product_id?: Maybe<Order_By>;
  finance_terms_aggregate?: Maybe<Finance_Terms_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  interest_rate?: Maybe<Order_By>;
  max_amount?: Maybe<Order_By>;
  min_amount?: Maybe<Order_By>;
  representative_rate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_fixed_rate_configurations */
export type Finance_Fixed_Rate_Configurations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "finance_fixed_rate_configurations" */
export enum Finance_Fixed_Rate_Configurations_Select_Column {
  /** column name */
  ComparisonRate = 'comparison_rate',
  /** column name */
  CompoundingFrequency = 'compounding_frequency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultBalloon = 'default_balloon',
  /** column name */
  DefaultDeposit = 'default_deposit',
  /** column name */
  DefaultFrequency = 'default_frequency',
  /** column name */
  DefaultTerm = 'default_term',
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  MaxAmount = 'max_amount',
  /** column name */
  MinAmount = 'min_amount',
  /** column name */
  RepresentativeRate = 'representative_rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Set_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_frequency?: Maybe<Finance_Frequency_Enum>;
  default_term?: Maybe<Scalars['numeric']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Finance_Fixed_Rate_Configurations_Stddev_Fields = {
  __typename?: 'finance_fixed_rate_configurations_stddev_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Finance_Fixed_Rate_Configurations_Stddev_Pop_Fields = {
  __typename?: 'finance_fixed_rate_configurations_stddev_pop_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Finance_Fixed_Rate_Configurations_Stddev_Samp_Fields = {
  __typename?: 'finance_fixed_rate_configurations_stddev_samp_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "finance_fixed_rate_configurations" */
export type Finance_Fixed_Rate_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Fixed_Rate_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Fixed_Rate_Configurations_Stream_Cursor_Value_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_frequency?: Maybe<Finance_Frequency_Enum>;
  default_term?: Maybe<Scalars['numeric']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Finance_Fixed_Rate_Configurations_Sum_Fields = {
  __typename?: 'finance_fixed_rate_configurations_sum_fields';
  comparison_rate?: Maybe<Scalars['numeric']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['numeric']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_amount?: Maybe<Scalars['Int']>;
  representative_rate?: Maybe<Scalars['numeric']>;
};

/** update columns of table "finance_fixed_rate_configurations" */
export enum Finance_Fixed_Rate_Configurations_Update_Column {
  /** column name */
  ComparisonRate = 'comparison_rate',
  /** column name */
  CompoundingFrequency = 'compounding_frequency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultBalloon = 'default_balloon',
  /** column name */
  DefaultDeposit = 'default_deposit',
  /** column name */
  DefaultFrequency = 'default_frequency',
  /** column name */
  DefaultTerm = 'default_term',
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  MaxAmount = 'max_amount',
  /** column name */
  MinAmount = 'min_amount',
  /** column name */
  RepresentativeRate = 'representative_rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Finance_Fixed_Rate_Configurations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Fixed_Rate_Configurations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Fixed_Rate_Configurations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Fixed_Rate_Configurations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Fixed_Rate_Configurations_Var_Pop_Fields = {
  __typename?: 'finance_fixed_rate_configurations_var_pop_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Finance_Fixed_Rate_Configurations_Var_Samp_Fields = {
  __typename?: 'finance_fixed_rate_configurations_var_samp_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Finance_Fixed_Rate_Configurations_Variance_Fields = {
  __typename?: 'finance_fixed_rate_configurations_variance_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  /** The balloon represented as a number between 0 and 1 where 1 = 100% balloon. */
  default_balloon?: Maybe<Scalars['Float']>;
  /** The deposit amount in the lowest denomination of the given currency. */
  default_deposit?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "finance_frequency" */
export type Finance_Frequency = {
  __typename?: 'finance_frequency';
  /** An array relationship */
  finance_fees: Array<Finance_Fees>;
  /** An aggregate relationship */
  finance_fees_aggregate: Finance_Fees_Aggregate;
  label: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "finance_frequency" */
export type Finance_FrequencyFinance_FeesArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


/** columns and relationships of "finance_frequency" */
export type Finance_FrequencyFinance_Fees_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};

/** aggregated selection of "finance_frequency" */
export type Finance_Frequency_Aggregate = {
  __typename?: 'finance_frequency_aggregate';
  aggregate?: Maybe<Finance_Frequency_Aggregate_Fields>;
  nodes: Array<Finance_Frequency>;
};

/** aggregate fields of "finance_frequency" */
export type Finance_Frequency_Aggregate_Fields = {
  __typename?: 'finance_frequency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Finance_Frequency_Max_Fields>;
  min?: Maybe<Finance_Frequency_Min_Fields>;
};


/** aggregate fields of "finance_frequency" */
export type Finance_Frequency_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Frequency_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "finance_frequency". All fields are combined with a logical 'AND'. */
export type Finance_Frequency_Bool_Exp = {
  _and?: Maybe<Array<Finance_Frequency_Bool_Exp>>;
  _not?: Maybe<Finance_Frequency_Bool_Exp>;
  _or?: Maybe<Array<Finance_Frequency_Bool_Exp>>;
  finance_fees?: Maybe<Finance_Fees_Bool_Exp>;
  finance_fees_aggregate?: Maybe<Finance_Fees_Aggregate_Bool_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_frequency" */
export enum Finance_Frequency_Constraint {
  /** unique or primary key constraint on columns "value" */
  FinanceFrequencyPkey = 'finance_frequency_pkey'
}

export enum Finance_Frequency_Enum {
  /** Annually */
  Annually = 'annually',
  /** Fortnightly */
  Fortnightly = 'fortnightly',
  /** Monthly */
  Monthly = 'monthly',
  /** One off */
  OneOff = 'one_off',
  /** Weekly */
  Weekly = 'weekly'
}

/** Boolean expression to compare columns of type "finance_frequency_enum". All fields are combined with logical 'AND'. */
export type Finance_Frequency_Enum_Comparison_Exp = {
  _eq?: Maybe<Finance_Frequency_Enum>;
  _in?: Maybe<Array<Finance_Frequency_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Finance_Frequency_Enum>;
  _nin?: Maybe<Array<Finance_Frequency_Enum>>;
};

/** input type for inserting data into table "finance_frequency" */
export type Finance_Frequency_Insert_Input = {
  finance_fees?: Maybe<Finance_Fees_Arr_Rel_Insert_Input>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Finance_Frequency_Max_Fields = {
  __typename?: 'finance_frequency_max_fields';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Finance_Frequency_Min_Fields = {
  __typename?: 'finance_frequency_min_fields';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "finance_frequency" */
export type Finance_Frequency_Mutation_Response = {
  __typename?: 'finance_frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Frequency>;
};

/** on_conflict condition type for table "finance_frequency" */
export type Finance_Frequency_On_Conflict = {
  constraint: Finance_Frequency_Constraint;
  update_columns?: Array<Finance_Frequency_Update_Column>;
  where?: Maybe<Finance_Frequency_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_frequency". */
export type Finance_Frequency_Order_By = {
  finance_fees_aggregate?: Maybe<Finance_Fees_Aggregate_Order_By>;
  label?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_frequency */
export type Finance_Frequency_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "finance_frequency" */
export enum Finance_Frequency_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "finance_frequency" */
export type Finance_Frequency_Set_Input = {
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "finance_frequency" */
export type Finance_Frequency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Frequency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Frequency_Stream_Cursor_Value_Input = {
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "finance_frequency" */
export enum Finance_Frequency_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value'
}

export type Finance_Frequency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Frequency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Frequency_Bool_Exp;
};

/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_Configurations = {
  __typename?: 'finance_gfv_configurations';
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at: Scalars['timestamptz'];
  default_deposit: Scalars['numeric'];
  default_frequency: Finance_Frequency_Enum;
  default_km: Scalars['Int'];
  default_term: Scalars['Int'];
  /** An array relationship */
  finance_fees: Array<Finance_Fees>;
  /** An aggregate relationship */
  finance_fees_aggregate: Finance_Fees_Aggregate;
  /** An object relationship */
  finance_product: Finance_Products;
  finance_product_id: Scalars['uuid'];
  /** An array relationship */
  finance_terms: Array<Finance_Terms>;
  /** An aggregate relationship */
  finance_terms_aggregate: Finance_Terms_Aggregate;
  id: Scalars['uuid'];
  interest_rate: Scalars['numeric'];
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  rates: Array<Finance_Gfv_Residual_Values>;
  /** An aggregate relationship */
  rates_aggregate: Finance_Gfv_Residual_Values_Aggregate;
  representative_rate?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  residual_values: Array<Finance_Gfv_Residual_Values>;
  /** An aggregate relationship */
  residual_values_aggregate: Finance_Gfv_Residual_Values_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsFinance_FeesArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsFinance_Fees_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsFinance_TermsArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsFinance_Terms_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsRatesArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsResidual_ValuesArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


/** columns and relationships of "finance_gfv_configurations" */
export type Finance_Gfv_ConfigurationsResidual_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};

/** aggregated selection of "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Aggregate = {
  __typename?: 'finance_gfv_configurations_aggregate';
  aggregate?: Maybe<Finance_Gfv_Configurations_Aggregate_Fields>;
  nodes: Array<Finance_Gfv_Configurations>;
};

/** aggregate fields of "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Aggregate_Fields = {
  __typename?: 'finance_gfv_configurations_aggregate_fields';
  avg?: Maybe<Finance_Gfv_Configurations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Gfv_Configurations_Max_Fields>;
  min?: Maybe<Finance_Gfv_Configurations_Min_Fields>;
  stddev?: Maybe<Finance_Gfv_Configurations_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Gfv_Configurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Gfv_Configurations_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Gfv_Configurations_Sum_Fields>;
  var_pop?: Maybe<Finance_Gfv_Configurations_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Gfv_Configurations_Var_Samp_Fields>;
  variance?: Maybe<Finance_Gfv_Configurations_Variance_Fields>;
};


/** aggregate fields of "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Gfv_Configurations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Finance_Gfv_Configurations_Avg_Fields = {
  __typename?: 'finance_gfv_configurations_avg_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "finance_gfv_configurations". All fields are combined with a logical 'AND'. */
export type Finance_Gfv_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Finance_Gfv_Configurations_Bool_Exp>>;
  _not?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Finance_Gfv_Configurations_Bool_Exp>>;
  comparison_rate?: Maybe<Numeric_Comparison_Exp>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_deposit?: Maybe<Numeric_Comparison_Exp>;
  default_frequency?: Maybe<Finance_Frequency_Enum_Comparison_Exp>;
  default_km?: Maybe<Int_Comparison_Exp>;
  default_term?: Maybe<Int_Comparison_Exp>;
  finance_fees?: Maybe<Finance_Fees_Bool_Exp>;
  finance_fees_aggregate?: Maybe<Finance_Fees_Aggregate_Bool_Exp>;
  finance_product?: Maybe<Finance_Products_Bool_Exp>;
  finance_product_id?: Maybe<Uuid_Comparison_Exp>;
  finance_terms?: Maybe<Finance_Terms_Bool_Exp>;
  finance_terms_aggregate?: Maybe<Finance_Terms_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interest_rate?: Maybe<Numeric_Comparison_Exp>;
  max_amount?: Maybe<Numeric_Comparison_Exp>;
  min_amount?: Maybe<Numeric_Comparison_Exp>;
  rates?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
  rates_aggregate?: Maybe<Finance_Gfv_Residual_Values_Aggregate_Bool_Exp>;
  representative_rate?: Maybe<Numeric_Comparison_Exp>;
  residual_values?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
  residual_values_aggregate?: Maybe<Finance_Gfv_Residual_Values_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_gfv_configurations" */
export enum Finance_Gfv_Configurations_Constraint {
  /** unique or primary key constraint on columns "finance_product_id" */
  FinanceGfvConfigurationsFinanceProductIdKey = 'finance_gfv_configurations_finance_product_id_key',
  /** unique or primary key constraint on columns "id" */
  FinanceGfvConfigurationsPkey = 'finance_gfv_configurations_pkey'
}

/** input type for incrementing numeric columns in table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Inc_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  representative_rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Insert_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_frequency?: Maybe<Finance_Frequency_Enum>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  finance_fees?: Maybe<Finance_Fees_Arr_Rel_Insert_Input>;
  finance_product?: Maybe<Finance_Products_Obj_Rel_Insert_Input>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  finance_terms?: Maybe<Finance_Terms_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  rates?: Maybe<Finance_Gfv_Residual_Values_Arr_Rel_Insert_Input>;
  representative_rate?: Maybe<Scalars['numeric']>;
  residual_values?: Maybe<Finance_Gfv_Residual_Values_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Finance_Gfv_Configurations_Max_Fields = {
  __typename?: 'finance_gfv_configurations_max_fields';
  comparison_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Finance_Gfv_Configurations_Min_Fields = {
  __typename?: 'finance_gfv_configurations_min_fields';
  comparison_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Mutation_Response = {
  __typename?: 'finance_gfv_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Gfv_Configurations>;
};

/** input type for inserting object relation for remote table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Obj_Rel_Insert_Input = {
  data: Finance_Gfv_Configurations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Gfv_Configurations_On_Conflict>;
};

/** on_conflict condition type for table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_On_Conflict = {
  constraint: Finance_Gfv_Configurations_Constraint;
  update_columns?: Array<Finance_Gfv_Configurations_Update_Column>;
  where?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_gfv_configurations". */
export type Finance_Gfv_Configurations_Order_By = {
  comparison_rate?: Maybe<Order_By>;
  compounding_frequency?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  default_deposit?: Maybe<Order_By>;
  default_frequency?: Maybe<Order_By>;
  default_km?: Maybe<Order_By>;
  default_term?: Maybe<Order_By>;
  finance_fees_aggregate?: Maybe<Finance_Fees_Aggregate_Order_By>;
  finance_product?: Maybe<Finance_Products_Order_By>;
  finance_product_id?: Maybe<Order_By>;
  finance_terms_aggregate?: Maybe<Finance_Terms_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  interest_rate?: Maybe<Order_By>;
  max_amount?: Maybe<Order_By>;
  min_amount?: Maybe<Order_By>;
  rates_aggregate?: Maybe<Finance_Gfv_Residual_Values_Aggregate_Order_By>;
  representative_rate?: Maybe<Order_By>;
  residual_values_aggregate?: Maybe<Finance_Gfv_Residual_Values_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_gfv_configurations */
export type Finance_Gfv_Configurations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "finance_gfv_configurations" */
export enum Finance_Gfv_Configurations_Select_Column {
  /** column name */
  ComparisonRate = 'comparison_rate',
  /** column name */
  CompoundingFrequency = 'compounding_frequency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultDeposit = 'default_deposit',
  /** column name */
  DefaultFrequency = 'default_frequency',
  /** column name */
  DefaultKm = 'default_km',
  /** column name */
  DefaultTerm = 'default_term',
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  MaxAmount = 'max_amount',
  /** column name */
  MinAmount = 'min_amount',
  /** column name */
  RepresentativeRate = 'representative_rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Set_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_frequency?: Maybe<Finance_Frequency_Enum>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Finance_Gfv_Configurations_Stddev_Fields = {
  __typename?: 'finance_gfv_configurations_stddev_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Finance_Gfv_Configurations_Stddev_Pop_Fields = {
  __typename?: 'finance_gfv_configurations_stddev_pop_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Finance_Gfv_Configurations_Stddev_Samp_Fields = {
  __typename?: 'finance_gfv_configurations_stddev_samp_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "finance_gfv_configurations" */
export type Finance_Gfv_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Gfv_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Gfv_Configurations_Stream_Cursor_Value_Input = {
  comparison_rate?: Maybe<Scalars['numeric']>;
  compounding_frequency?: Maybe<Finance_Frequency_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_frequency?: Maybe<Finance_Frequency_Enum>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  representative_rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Finance_Gfv_Configurations_Sum_Fields = {
  __typename?: 'finance_gfv_configurations_sum_fields';
  comparison_rate?: Maybe<Scalars['numeric']>;
  default_deposit?: Maybe<Scalars['numeric']>;
  default_km?: Maybe<Scalars['Int']>;
  default_term?: Maybe<Scalars['Int']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  max_amount?: Maybe<Scalars['numeric']>;
  min_amount?: Maybe<Scalars['numeric']>;
  representative_rate?: Maybe<Scalars['numeric']>;
};

/** update columns of table "finance_gfv_configurations" */
export enum Finance_Gfv_Configurations_Update_Column {
  /** column name */
  ComparisonRate = 'comparison_rate',
  /** column name */
  CompoundingFrequency = 'compounding_frequency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultDeposit = 'default_deposit',
  /** column name */
  DefaultFrequency = 'default_frequency',
  /** column name */
  DefaultKm = 'default_km',
  /** column name */
  DefaultTerm = 'default_term',
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  MaxAmount = 'max_amount',
  /** column name */
  MinAmount = 'min_amount',
  /** column name */
  RepresentativeRate = 'representative_rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Finance_Gfv_Configurations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Gfv_Configurations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Gfv_Configurations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Gfv_Configurations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Gfv_Configurations_Var_Pop_Fields = {
  __typename?: 'finance_gfv_configurations_var_pop_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Finance_Gfv_Configurations_Var_Samp_Fields = {
  __typename?: 'finance_gfv_configurations_var_samp_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Finance_Gfv_Configurations_Variance_Fields = {
  __typename?: 'finance_gfv_configurations_variance_fields';
  comparison_rate?: Maybe<Scalars['Float']>;
  default_deposit?: Maybe<Scalars['Float']>;
  default_km?: Maybe<Scalars['Float']>;
  default_term?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  representative_rate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values = {
  __typename?: 'finance_gfv_residual_values';
  created_at: Scalars['timestamptz'];
  finance_gfv_configuration_id: Scalars['uuid'];
  id: Scalars['uuid'];
  max_km: Scalars['Int'];
  max_term: Scalars['numeric'];
  min_km: Scalars['Int'];
  min_term: Scalars['numeric'];
  residual_value: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Aggregate = {
  __typename?: 'finance_gfv_residual_values_aggregate';
  aggregate?: Maybe<Finance_Gfv_Residual_Values_Aggregate_Fields>;
  nodes: Array<Finance_Gfv_Residual_Values>;
};

export type Finance_Gfv_Residual_Values_Aggregate_Bool_Exp = {
  count?: Maybe<Finance_Gfv_Residual_Values_Aggregate_Bool_Exp_Count>;
};

export type Finance_Gfv_Residual_Values_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Aggregate_Fields = {
  __typename?: 'finance_gfv_residual_values_aggregate_fields';
  avg?: Maybe<Finance_Gfv_Residual_Values_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Gfv_Residual_Values_Max_Fields>;
  min?: Maybe<Finance_Gfv_Residual_Values_Min_Fields>;
  stddev?: Maybe<Finance_Gfv_Residual_Values_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Gfv_Residual_Values_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Gfv_Residual_Values_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Gfv_Residual_Values_Sum_Fields>;
  var_pop?: Maybe<Finance_Gfv_Residual_Values_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Gfv_Residual_Values_Var_Samp_Fields>;
  variance?: Maybe<Finance_Gfv_Residual_Values_Variance_Fields>;
};


/** aggregate fields of "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Aggregate_Order_By = {
  avg?: Maybe<Finance_Gfv_Residual_Values_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Finance_Gfv_Residual_Values_Max_Order_By>;
  min?: Maybe<Finance_Gfv_Residual_Values_Min_Order_By>;
  stddev?: Maybe<Finance_Gfv_Residual_Values_Stddev_Order_By>;
  stddev_pop?: Maybe<Finance_Gfv_Residual_Values_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Finance_Gfv_Residual_Values_Stddev_Samp_Order_By>;
  sum?: Maybe<Finance_Gfv_Residual_Values_Sum_Order_By>;
  var_pop?: Maybe<Finance_Gfv_Residual_Values_Var_Pop_Order_By>;
  var_samp?: Maybe<Finance_Gfv_Residual_Values_Var_Samp_Order_By>;
  variance?: Maybe<Finance_Gfv_Residual_Values_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Arr_Rel_Insert_Input = {
  data: Array<Finance_Gfv_Residual_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Gfv_Residual_Values_On_Conflict>;
};

/** aggregate avg on columns */
export type Finance_Gfv_Residual_Values_Avg_Fields = {
  __typename?: 'finance_gfv_residual_values_avg_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Avg_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "finance_gfv_residual_values". All fields are combined with a logical 'AND'. */
export type Finance_Gfv_Residual_Values_Bool_Exp = {
  _and?: Maybe<Array<Finance_Gfv_Residual_Values_Bool_Exp>>;
  _not?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
  _or?: Maybe<Array<Finance_Gfv_Residual_Values_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  finance_gfv_configuration_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  max_km?: Maybe<Int_Comparison_Exp>;
  max_term?: Maybe<Numeric_Comparison_Exp>;
  min_km?: Maybe<Int_Comparison_Exp>;
  min_term?: Maybe<Numeric_Comparison_Exp>;
  residual_value?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_gfv_residual_values" */
export enum Finance_Gfv_Residual_Values_Constraint {
  /** unique or primary key constraint on columns "id" */
  FinanceGfvRatesPkey = 'finance_gfv_rates_pkey'
}

/** input type for incrementing numeric columns in table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Inc_Input = {
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Finance_Gfv_Residual_Values_Max_Fields = {
  __typename?: 'finance_gfv_residual_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Finance_Gfv_Residual_Values_Min_Fields = {
  __typename?: 'finance_gfv_residual_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Mutation_Response = {
  __typename?: 'finance_gfv_residual_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Gfv_Residual_Values>;
};

/** on_conflict condition type for table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_On_Conflict = {
  constraint: Finance_Gfv_Residual_Values_Constraint;
  update_columns?: Array<Finance_Gfv_Residual_Values_Update_Column>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_gfv_residual_values". */
export type Finance_Gfv_Residual_Values_Order_By = {
  created_at?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_gfv_residual_values */
export type Finance_Gfv_Residual_Values_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "finance_gfv_residual_values" */
export enum Finance_Gfv_Residual_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinanceGfvConfigurationId = 'finance_gfv_configuration_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxKm = 'max_km',
  /** column name */
  MaxTerm = 'max_term',
  /** column name */
  MinKm = 'min_km',
  /** column name */
  MinTerm = 'min_term',
  /** column name */
  ResidualValue = 'residual_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Finance_Gfv_Residual_Values_Stddev_Fields = {
  __typename?: 'finance_gfv_residual_values_stddev_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Stddev_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Finance_Gfv_Residual_Values_Stddev_Pop_Fields = {
  __typename?: 'finance_gfv_residual_values_stddev_pop_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Stddev_Pop_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Finance_Gfv_Residual_Values_Stddev_Samp_Fields = {
  __typename?: 'finance_gfv_residual_values_stddev_samp_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Stddev_Samp_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** Streaming cursor of the table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Gfv_Residual_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Gfv_Residual_Values_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Finance_Gfv_Residual_Values_Sum_Fields = {
  __typename?: 'finance_gfv_residual_values_sum_fields';
  max_km?: Maybe<Scalars['Int']>;
  max_term?: Maybe<Scalars['numeric']>;
  min_km?: Maybe<Scalars['Int']>;
  min_term?: Maybe<Scalars['numeric']>;
  residual_value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Sum_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** update columns of table "finance_gfv_residual_values" */
export enum Finance_Gfv_Residual_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinanceGfvConfigurationId = 'finance_gfv_configuration_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxKm = 'max_km',
  /** column name */
  MaxTerm = 'max_term',
  /** column name */
  MinKm = 'min_km',
  /** column name */
  MinTerm = 'min_term',
  /** column name */
  ResidualValue = 'residual_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Finance_Gfv_Residual_Values_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Gfv_Residual_Values_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Gfv_Residual_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Gfv_Residual_Values_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Gfv_Residual_Values_Var_Pop_Fields = {
  __typename?: 'finance_gfv_residual_values_var_pop_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Var_Pop_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Finance_Gfv_Residual_Values_Var_Samp_Fields = {
  __typename?: 'finance_gfv_residual_values_var_samp_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Var_Samp_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Finance_Gfv_Residual_Values_Variance_Fields = {
  __typename?: 'finance_gfv_residual_values_variance_fields';
  max_km?: Maybe<Scalars['Float']>;
  max_term?: Maybe<Scalars['Float']>;
  min_km?: Maybe<Scalars['Float']>;
  min_term?: Maybe<Scalars['Float']>;
  residual_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "finance_gfv_residual_values" */
export type Finance_Gfv_Residual_Values_Variance_Order_By = {
  max_km?: Maybe<Order_By>;
  max_term?: Maybe<Order_By>;
  min_km?: Maybe<Order_By>;
  min_term?: Maybe<Order_By>;
  residual_value?: Maybe<Order_By>;
};

/** columns and relationships of "finance_product_offers" */
export type Finance_Product_Offers = {
  __typename?: 'finance_product_offers';
  /** An object relationship */
  finance_product: Finance_Products;
  finance_product_id: Scalars['uuid'];
  /** An object relationship */
  offer: Offers;
  offer_id: Scalars['uuid'];
};

/** aggregated selection of "finance_product_offers" */
export type Finance_Product_Offers_Aggregate = {
  __typename?: 'finance_product_offers_aggregate';
  aggregate?: Maybe<Finance_Product_Offers_Aggregate_Fields>;
  nodes: Array<Finance_Product_Offers>;
};

export type Finance_Product_Offers_Aggregate_Bool_Exp = {
  count?: Maybe<Finance_Product_Offers_Aggregate_Bool_Exp_Count>;
};

export type Finance_Product_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Product_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "finance_product_offers" */
export type Finance_Product_Offers_Aggregate_Fields = {
  __typename?: 'finance_product_offers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Finance_Product_Offers_Max_Fields>;
  min?: Maybe<Finance_Product_Offers_Min_Fields>;
};


/** aggregate fields of "finance_product_offers" */
export type Finance_Product_Offers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finance_product_offers" */
export type Finance_Product_Offers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Finance_Product_Offers_Max_Order_By>;
  min?: Maybe<Finance_Product_Offers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "finance_product_offers" */
export type Finance_Product_Offers_Arr_Rel_Insert_Input = {
  data: Array<Finance_Product_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Product_Offers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "finance_product_offers". All fields are combined with a logical 'AND'. */
export type Finance_Product_Offers_Bool_Exp = {
  _and?: Maybe<Array<Finance_Product_Offers_Bool_Exp>>;
  _not?: Maybe<Finance_Product_Offers_Bool_Exp>;
  _or?: Maybe<Array<Finance_Product_Offers_Bool_Exp>>;
  finance_product?: Maybe<Finance_Products_Bool_Exp>;
  finance_product_id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Offers_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_product_offers" */
export enum Finance_Product_Offers_Constraint {
  /** unique or primary key constraint on columns "finance_product_id", "offer_id" */
  FinanceProductOffersPkey = 'finance_product_offers_pkey'
}

/** input type for inserting data into table "finance_product_offers" */
export type Finance_Product_Offers_Insert_Input = {
  finance_product?: Maybe<Finance_Products_Obj_Rel_Insert_Input>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Offers_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Finance_Product_Offers_Max_Fields = {
  __typename?: 'finance_product_offers_max_fields';
  finance_product_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "finance_product_offers" */
export type Finance_Product_Offers_Max_Order_By = {
  finance_product_id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Finance_Product_Offers_Min_Fields = {
  __typename?: 'finance_product_offers_min_fields';
  finance_product_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "finance_product_offers" */
export type Finance_Product_Offers_Min_Order_By = {
  finance_product_id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "finance_product_offers" */
export type Finance_Product_Offers_Mutation_Response = {
  __typename?: 'finance_product_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Product_Offers>;
};

/** on_conflict condition type for table "finance_product_offers" */
export type Finance_Product_Offers_On_Conflict = {
  constraint: Finance_Product_Offers_Constraint;
  update_columns?: Array<Finance_Product_Offers_Update_Column>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_product_offers". */
export type Finance_Product_Offers_Order_By = {
  finance_product?: Maybe<Finance_Products_Order_By>;
  finance_product_id?: Maybe<Order_By>;
  offer?: Maybe<Offers_Order_By>;
  offer_id?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_product_offers */
export type Finance_Product_Offers_Pk_Columns_Input = {
  finance_product_id: Scalars['uuid'];
  offer_id: Scalars['uuid'];
};

/** select columns of table "finance_product_offers" */
export enum Finance_Product_Offers_Select_Column {
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  OfferId = 'offer_id'
}

/** input type for updating data in table "finance_product_offers" */
export type Finance_Product_Offers_Set_Input = {
  finance_product_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "finance_product_offers" */
export type Finance_Product_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Product_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Product_Offers_Stream_Cursor_Value_Input = {
  finance_product_id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "finance_product_offers" */
export enum Finance_Product_Offers_Update_Column {
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  OfferId = 'offer_id'
}

export type Finance_Product_Offers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Product_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Product_Offers_Bool_Exp;
};

/** columns and relationships of "finance_product_types" */
export type Finance_Product_Types = {
  __typename?: 'finance_product_types';
  /** An array relationship */
  finance_products: Array<Finance_Products>;
  /** An aggregate relationship */
  finance_products_aggregate: Finance_Products_Aggregate;
  label: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "finance_product_types" */
export type Finance_Product_TypesFinance_ProductsArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


/** columns and relationships of "finance_product_types" */
export type Finance_Product_TypesFinance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};

/** aggregated selection of "finance_product_types" */
export type Finance_Product_Types_Aggregate = {
  __typename?: 'finance_product_types_aggregate';
  aggregate?: Maybe<Finance_Product_Types_Aggregate_Fields>;
  nodes: Array<Finance_Product_Types>;
};

/** aggregate fields of "finance_product_types" */
export type Finance_Product_Types_Aggregate_Fields = {
  __typename?: 'finance_product_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Finance_Product_Types_Max_Fields>;
  min?: Maybe<Finance_Product_Types_Min_Fields>;
};


/** aggregate fields of "finance_product_types" */
export type Finance_Product_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Product_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "finance_product_types". All fields are combined with a logical 'AND'. */
export type Finance_Product_Types_Bool_Exp = {
  _and?: Maybe<Array<Finance_Product_Types_Bool_Exp>>;
  _not?: Maybe<Finance_Product_Types_Bool_Exp>;
  _or?: Maybe<Array<Finance_Product_Types_Bool_Exp>>;
  finance_products?: Maybe<Finance_Products_Bool_Exp>;
  finance_products_aggregate?: Maybe<Finance_Products_Aggregate_Bool_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_product_types" */
export enum Finance_Product_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  FinanceProductTypesPkey = 'finance_product_types_pkey'
}

export enum Finance_Product_Types_Enum {
  /** Cash */
  Cash = 'cash',
  /** Fixed rate finance */
  FixedRate = 'fixed_rate',
  /** Guaranteed future value */
  Gfv = 'gfv'
}

/** Boolean expression to compare columns of type "finance_product_types_enum". All fields are combined with logical 'AND'. */
export type Finance_Product_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Finance_Product_Types_Enum>;
  _in?: Maybe<Array<Finance_Product_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Finance_Product_Types_Enum>;
  _nin?: Maybe<Array<Finance_Product_Types_Enum>>;
};

/** input type for inserting data into table "finance_product_types" */
export type Finance_Product_Types_Insert_Input = {
  finance_products?: Maybe<Finance_Products_Arr_Rel_Insert_Input>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Finance_Product_Types_Max_Fields = {
  __typename?: 'finance_product_types_max_fields';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Finance_Product_Types_Min_Fields = {
  __typename?: 'finance_product_types_min_fields';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "finance_product_types" */
export type Finance_Product_Types_Mutation_Response = {
  __typename?: 'finance_product_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Product_Types>;
};

/** on_conflict condition type for table "finance_product_types" */
export type Finance_Product_Types_On_Conflict = {
  constraint: Finance_Product_Types_Constraint;
  update_columns?: Array<Finance_Product_Types_Update_Column>;
  where?: Maybe<Finance_Product_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_product_types". */
export type Finance_Product_Types_Order_By = {
  finance_products_aggregate?: Maybe<Finance_Products_Aggregate_Order_By>;
  label?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_product_types */
export type Finance_Product_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "finance_product_types" */
export enum Finance_Product_Types_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "finance_product_types" */
export type Finance_Product_Types_Set_Input = {
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "finance_product_types" */
export type Finance_Product_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Product_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Product_Types_Stream_Cursor_Value_Input = {
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "finance_product_types" */
export enum Finance_Product_Types_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value'
}

export type Finance_Product_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Product_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Product_Types_Bool_Exp;
};

/** columns and relationships of "finance_products" */
export type Finance_Products = {
  __typename?: 'finance_products';
  apply_handoff_message?: Maybe<Scalars['String']>;
  apply_heading?: Maybe<Scalars['String']>;
  apply_label?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  available_from: Scalars['timestamptz'];
  available_to?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  criteria?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  details_template?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  /** An array relationship */
  finance_applications: Array<Finance_Applications>;
  /** An aggregate relationship */
  finance_applications_aggregate: Finance_Applications_Aggregate;
  /** An object relationship */
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations>;
  /** An object relationship */
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations>;
  /** An array relationship */
  finance_product_offers: Array<Finance_Product_Offers>;
  /** An aggregate relationship */
  finance_product_offers_aggregate: Finance_Product_Offers_Aggregate;
  id: Scalars['uuid'];
  info_label?: Maybe<Scalars['String']>;
  info_url?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  /** Whether this finance product is suitable for a commercial customer (i.e. a business). */
  is_commercial: Scalars['Boolean'];
  /** Whether this finance product is suitable for a consumer. */
  is_consumer: Scalars['Boolean'];
  is_published: Scalars['Boolean'];
  is_trashed: Scalars['Boolean'];
  label: Scalars['String'];
  max_amount_message?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  provider_logo_src?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  stocked_vehicle_finance_products: Array<Stocked_Vehicle_Finance_Products>;
  /** An aggregate relationship */
  stocked_vehicle_finance_products_aggregate: Stocked_Vehicle_Finance_Products_Aggregate;
  type: Finance_Product_Types_Enum;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsCriteriaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsFinance_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsFinance_Applications_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsFinance_Product_OffersArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Offers_Order_By>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsFinance_Product_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Offers_Order_By>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsStocked_Vehicle_Finance_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


/** columns and relationships of "finance_products" */
export type Finance_ProductsStocked_Vehicle_Finance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};

/** aggregated selection of "finance_products" */
export type Finance_Products_Aggregate = {
  __typename?: 'finance_products_aggregate';
  aggregate?: Maybe<Finance_Products_Aggregate_Fields>;
  nodes: Array<Finance_Products>;
};

export type Finance_Products_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Finance_Products_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Finance_Products_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Finance_Products_Aggregate_Bool_Exp_Count>;
};

export type Finance_Products_Aggregate_Bool_Exp_Bool_And = {
  arguments: Finance_Products_Select_Column_Finance_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Finance_Products_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Finance_Products_Select_Column_Finance_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Finance_Products_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Finance_Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "finance_products" */
export type Finance_Products_Aggregate_Fields = {
  __typename?: 'finance_products_aggregate_fields';
  avg?: Maybe<Finance_Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Products_Max_Fields>;
  min?: Maybe<Finance_Products_Min_Fields>;
  stddev?: Maybe<Finance_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Products_Sum_Fields>;
  var_pop?: Maybe<Finance_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Products_Var_Samp_Fields>;
  variance?: Maybe<Finance_Products_Variance_Fields>;
};


/** aggregate fields of "finance_products" */
export type Finance_Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finance_products" */
export type Finance_Products_Aggregate_Order_By = {
  avg?: Maybe<Finance_Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Finance_Products_Max_Order_By>;
  min?: Maybe<Finance_Products_Min_Order_By>;
  stddev?: Maybe<Finance_Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Finance_Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Finance_Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Finance_Products_Sum_Order_By>;
  var_pop?: Maybe<Finance_Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Finance_Products_Var_Samp_Order_By>;
  variance?: Maybe<Finance_Products_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Finance_Products_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "finance_products" */
export type Finance_Products_Arr_Rel_Insert_Input = {
  data: Array<Finance_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Finance_Products_Avg_Fields = {
  __typename?: 'finance_products_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "finance_products" */
export type Finance_Products_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "finance_products". All fields are combined with a logical 'AND'. */
export type Finance_Products_Bool_Exp = {
  _and?: Maybe<Array<Finance_Products_Bool_Exp>>;
  _not?: Maybe<Finance_Products_Bool_Exp>;
  _or?: Maybe<Array<Finance_Products_Bool_Exp>>;
  apply_handoff_message?: Maybe<String_Comparison_Exp>;
  apply_heading?: Maybe<String_Comparison_Exp>;
  apply_label?: Maybe<String_Comparison_Exp>;
  apply_url?: Maybe<String_Comparison_Exp>;
  available_from?: Maybe<Timestamptz_Comparison_Exp>;
  available_to?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  criteria?: Maybe<Jsonb_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  details_template?: Maybe<String_Comparison_Exp>;
  disclaimer?: Maybe<String_Comparison_Exp>;
  finance_applications?: Maybe<Finance_Applications_Bool_Exp>;
  finance_applications_aggregate?: Maybe<Finance_Applications_Aggregate_Bool_Exp>;
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
  finance_product_offers?: Maybe<Finance_Product_Offers_Bool_Exp>;
  finance_product_offers_aggregate?: Maybe<Finance_Product_Offers_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  info_label?: Maybe<String_Comparison_Exp>;
  info_url?: Maybe<String_Comparison_Exp>;
  internal_name?: Maybe<String_Comparison_Exp>;
  is_commercial?: Maybe<Boolean_Comparison_Exp>;
  is_consumer?: Maybe<Boolean_Comparison_Exp>;
  is_published?: Maybe<Boolean_Comparison_Exp>;
  is_trashed?: Maybe<Boolean_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  max_amount_message?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  provider_logo_src?: Maybe<String_Comparison_Exp>;
  provider_name?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
  stocked_vehicle_finance_products_aggregate?: Maybe<Stocked_Vehicle_Finance_Products_Aggregate_Bool_Exp>;
  type?: Maybe<Finance_Product_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_products" */
export enum Finance_Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  FinanceProductsPkey = 'finance_products_pkey',
  /** unique or primary key constraint on columns "source", "source_id" */
  FinanceProductsSourceSourceIdKey = 'finance_products_source_source_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Finance_Products_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Finance_Products_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Finance_Products_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "finance_products" */
export type Finance_Products_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "finance_products" */
export type Finance_Products_Insert_Input = {
  apply_handoff_message?: Maybe<Scalars['String']>;
  apply_heading?: Maybe<Scalars['String']>;
  apply_label?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  criteria?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  details_template?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  finance_applications?: Maybe<Finance_Applications_Arr_Rel_Insert_Input>;
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations_Obj_Rel_Insert_Input>;
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations_Obj_Rel_Insert_Input>;
  finance_product_offers?: Maybe<Finance_Product_Offers_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  info_label?: Maybe<Scalars['String']>;
  info_url?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  /** Whether this finance product is suitable for a commercial customer (i.e. a business). */
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** Whether this finance product is suitable for a consumer. */
  is_consumer?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  max_amount_message?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  provider_logo_src?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Arr_Rel_Insert_Input>;
  type?: Maybe<Finance_Product_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Finance_Products_Max_Fields = {
  __typename?: 'finance_products_max_fields';
  apply_handoff_message?: Maybe<Scalars['String']>;
  apply_heading?: Maybe<Scalars['String']>;
  apply_label?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  details_template?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  info_label?: Maybe<Scalars['String']>;
  info_url?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  max_amount_message?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  provider_logo_src?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "finance_products" */
export type Finance_Products_Max_Order_By = {
  apply_handoff_message?: Maybe<Order_By>;
  apply_heading?: Maybe<Order_By>;
  apply_label?: Maybe<Order_By>;
  apply_url?: Maybe<Order_By>;
  available_from?: Maybe<Order_By>;
  available_to?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  details_template?: Maybe<Order_By>;
  disclaimer?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  info_label?: Maybe<Order_By>;
  info_url?: Maybe<Order_By>;
  internal_name?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  max_amount_message?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  provider_logo_src?: Maybe<Order_By>;
  provider_name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Finance_Products_Min_Fields = {
  __typename?: 'finance_products_min_fields';
  apply_handoff_message?: Maybe<Scalars['String']>;
  apply_heading?: Maybe<Scalars['String']>;
  apply_label?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  details_template?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  info_label?: Maybe<Scalars['String']>;
  info_url?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  max_amount_message?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  provider_logo_src?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "finance_products" */
export type Finance_Products_Min_Order_By = {
  apply_handoff_message?: Maybe<Order_By>;
  apply_heading?: Maybe<Order_By>;
  apply_label?: Maybe<Order_By>;
  apply_url?: Maybe<Order_By>;
  available_from?: Maybe<Order_By>;
  available_to?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  details_template?: Maybe<Order_By>;
  disclaimer?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  info_label?: Maybe<Order_By>;
  info_url?: Maybe<Order_By>;
  internal_name?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  max_amount_message?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  provider_logo_src?: Maybe<Order_By>;
  provider_name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "finance_products" */
export type Finance_Products_Mutation_Response = {
  __typename?: 'finance_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Products>;
};

/** input type for inserting object relation for remote table "finance_products" */
export type Finance_Products_Obj_Rel_Insert_Input = {
  data: Finance_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Products_On_Conflict>;
};

/** on_conflict condition type for table "finance_products" */
export type Finance_Products_On_Conflict = {
  constraint: Finance_Products_Constraint;
  update_columns?: Array<Finance_Products_Update_Column>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_products". */
export type Finance_Products_Order_By = {
  apply_handoff_message?: Maybe<Order_By>;
  apply_heading?: Maybe<Order_By>;
  apply_label?: Maybe<Order_By>;
  apply_url?: Maybe<Order_By>;
  available_from?: Maybe<Order_By>;
  available_to?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  criteria?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  details_template?: Maybe<Order_By>;
  disclaimer?: Maybe<Order_By>;
  finance_applications_aggregate?: Maybe<Finance_Applications_Aggregate_Order_By>;
  finance_fixed_rate_configuration?: Maybe<Finance_Fixed_Rate_Configurations_Order_By>;
  finance_gfv_configuration?: Maybe<Finance_Gfv_Configurations_Order_By>;
  finance_product_offers_aggregate?: Maybe<Finance_Product_Offers_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  info_label?: Maybe<Order_By>;
  info_url?: Maybe<Order_By>;
  internal_name?: Maybe<Order_By>;
  is_commercial?: Maybe<Order_By>;
  is_consumer?: Maybe<Order_By>;
  is_published?: Maybe<Order_By>;
  is_trashed?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  max_amount_message?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  provider_logo_src?: Maybe<Order_By>;
  provider_name?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stocked_vehicle_finance_products_aggregate?: Maybe<Stocked_Vehicle_Finance_Products_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_products */
export type Finance_Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Finance_Products_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "finance_products" */
export enum Finance_Products_Select_Column {
  /** column name */
  ApplyHandoffMessage = 'apply_handoff_message',
  /** column name */
  ApplyHeading = 'apply_heading',
  /** column name */
  ApplyLabel = 'apply_label',
  /** column name */
  ApplyUrl = 'apply_url',
  /** column name */
  AvailableFrom = 'available_from',
  /** column name */
  AvailableTo = 'available_to',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Description = 'description',
  /** column name */
  DetailsTemplate = 'details_template',
  /** column name */
  Disclaimer = 'disclaimer',
  /** column name */
  Id = 'id',
  /** column name */
  InfoLabel = 'info_label',
  /** column name */
  InfoUrl = 'info_url',
  /** column name */
  InternalName = 'internal_name',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsConsumer = 'is_consumer',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Label = 'label',
  /** column name */
  MaxAmountMessage = 'max_amount_message',
  /** column name */
  Order = 'order',
  /** column name */
  ProviderLogoSrc = 'provider_logo_src',
  /** column name */
  ProviderName = 'provider_name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "finance_products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "finance_products" */
export enum Finance_Products_Select_Column_Finance_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsConsumer = 'is_consumer',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsTrashed = 'is_trashed'
}

/** select "finance_products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "finance_products" */
export enum Finance_Products_Select_Column_Finance_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsConsumer = 'is_consumer',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsTrashed = 'is_trashed'
}

/** input type for updating data in table "finance_products" */
export type Finance_Products_Set_Input = {
  apply_handoff_message?: Maybe<Scalars['String']>;
  apply_heading?: Maybe<Scalars['String']>;
  apply_label?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  criteria?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  details_template?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  info_label?: Maybe<Scalars['String']>;
  info_url?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  /** Whether this finance product is suitable for a commercial customer (i.e. a business). */
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** Whether this finance product is suitable for a consumer. */
  is_consumer?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  max_amount_message?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  provider_logo_src?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  type?: Maybe<Finance_Product_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Finance_Products_Stddev_Fields = {
  __typename?: 'finance_products_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "finance_products" */
export type Finance_Products_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Finance_Products_Stddev_Pop_Fields = {
  __typename?: 'finance_products_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "finance_products" */
export type Finance_Products_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Finance_Products_Stddev_Samp_Fields = {
  __typename?: 'finance_products_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "finance_products" */
export type Finance_Products_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "finance_products" */
export type Finance_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Products_Stream_Cursor_Value_Input = {
  apply_handoff_message?: Maybe<Scalars['String']>;
  apply_heading?: Maybe<Scalars['String']>;
  apply_label?: Maybe<Scalars['String']>;
  apply_url?: Maybe<Scalars['String']>;
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  criteria?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  details_template?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  info_label?: Maybe<Scalars['String']>;
  info_url?: Maybe<Scalars['String']>;
  internal_name?: Maybe<Scalars['String']>;
  /** Whether this finance product is suitable for a commercial customer (i.e. a business). */
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** Whether this finance product is suitable for a consumer. */
  is_consumer?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  max_amount_message?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  provider_logo_src?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  type?: Maybe<Finance_Product_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Finance_Products_Sum_Fields = {
  __typename?: 'finance_products_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "finance_products" */
export type Finance_Products_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "finance_products" */
export enum Finance_Products_Update_Column {
  /** column name */
  ApplyHandoffMessage = 'apply_handoff_message',
  /** column name */
  ApplyHeading = 'apply_heading',
  /** column name */
  ApplyLabel = 'apply_label',
  /** column name */
  ApplyUrl = 'apply_url',
  /** column name */
  AvailableFrom = 'available_from',
  /** column name */
  AvailableTo = 'available_to',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Description = 'description',
  /** column name */
  DetailsTemplate = 'details_template',
  /** column name */
  Disclaimer = 'disclaimer',
  /** column name */
  Id = 'id',
  /** column name */
  InfoLabel = 'info_label',
  /** column name */
  InfoUrl = 'info_url',
  /** column name */
  InternalName = 'internal_name',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsConsumer = 'is_consumer',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Label = 'label',
  /** column name */
  MaxAmountMessage = 'max_amount_message',
  /** column name */
  Order = 'order',
  /** column name */
  ProviderLogoSrc = 'provider_logo_src',
  /** column name */
  ProviderName = 'provider_name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Finance_Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Finance_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Finance_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Finance_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Finance_Products_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Products_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Finance_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Products_Var_Pop_Fields = {
  __typename?: 'finance_products_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "finance_products" */
export type Finance_Products_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Finance_Products_Var_Samp_Fields = {
  __typename?: 'finance_products_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "finance_products" */
export type Finance_Products_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Finance_Products_Variance_Fields = {
  __typename?: 'finance_products_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "finance_products" */
export type Finance_Products_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "finance_terms" */
export type Finance_Terms = {
  __typename?: 'finance_terms';
  created_at: Scalars['timestamptz'];
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  value: Scalars['numeric'];
};

/** aggregated selection of "finance_terms" */
export type Finance_Terms_Aggregate = {
  __typename?: 'finance_terms_aggregate';
  aggregate?: Maybe<Finance_Terms_Aggregate_Fields>;
  nodes: Array<Finance_Terms>;
};

export type Finance_Terms_Aggregate_Bool_Exp = {
  count?: Maybe<Finance_Terms_Aggregate_Bool_Exp_Count>;
};

export type Finance_Terms_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Finance_Terms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Finance_Terms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "finance_terms" */
export type Finance_Terms_Aggregate_Fields = {
  __typename?: 'finance_terms_aggregate_fields';
  avg?: Maybe<Finance_Terms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Finance_Terms_Max_Fields>;
  min?: Maybe<Finance_Terms_Min_Fields>;
  stddev?: Maybe<Finance_Terms_Stddev_Fields>;
  stddev_pop?: Maybe<Finance_Terms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Finance_Terms_Stddev_Samp_Fields>;
  sum?: Maybe<Finance_Terms_Sum_Fields>;
  var_pop?: Maybe<Finance_Terms_Var_Pop_Fields>;
  var_samp?: Maybe<Finance_Terms_Var_Samp_Fields>;
  variance?: Maybe<Finance_Terms_Variance_Fields>;
};


/** aggregate fields of "finance_terms" */
export type Finance_Terms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Finance_Terms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finance_terms" */
export type Finance_Terms_Aggregate_Order_By = {
  avg?: Maybe<Finance_Terms_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Finance_Terms_Max_Order_By>;
  min?: Maybe<Finance_Terms_Min_Order_By>;
  stddev?: Maybe<Finance_Terms_Stddev_Order_By>;
  stddev_pop?: Maybe<Finance_Terms_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Finance_Terms_Stddev_Samp_Order_By>;
  sum?: Maybe<Finance_Terms_Sum_Order_By>;
  var_pop?: Maybe<Finance_Terms_Var_Pop_Order_By>;
  var_samp?: Maybe<Finance_Terms_Var_Samp_Order_By>;
  variance?: Maybe<Finance_Terms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "finance_terms" */
export type Finance_Terms_Arr_Rel_Insert_Input = {
  data: Array<Finance_Terms_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Finance_Terms_On_Conflict>;
};

/** aggregate avg on columns */
export type Finance_Terms_Avg_Fields = {
  __typename?: 'finance_terms_avg_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "finance_terms" */
export type Finance_Terms_Avg_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "finance_terms". All fields are combined with a logical 'AND'. */
export type Finance_Terms_Bool_Exp = {
  _and?: Maybe<Array<Finance_Terms_Bool_Exp>>;
  _not?: Maybe<Finance_Terms_Bool_Exp>;
  _or?: Maybe<Array<Finance_Terms_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  finance_fixed_rate_configuration_id?: Maybe<Uuid_Comparison_Exp>;
  finance_gfv_configuration_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  max_balloon?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "finance_terms" */
export enum Finance_Terms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FinanceTermsPkey = 'finance_terms_pkey'
}

/** input type for incrementing numeric columns in table "finance_terms" */
export type Finance_Terms_Inc_Input = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "finance_terms" */
export type Finance_Terms_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Finance_Terms_Max_Fields = {
  __typename?: 'finance_terms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "finance_terms" */
export type Finance_Terms_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  finance_fixed_rate_configuration_id?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Finance_Terms_Min_Fields = {
  __typename?: 'finance_terms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "finance_terms" */
export type Finance_Terms_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  finance_fixed_rate_configuration_id?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "finance_terms" */
export type Finance_Terms_Mutation_Response = {
  __typename?: 'finance_terms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Terms>;
};

/** on_conflict condition type for table "finance_terms" */
export type Finance_Terms_On_Conflict = {
  constraint: Finance_Terms_Constraint;
  update_columns?: Array<Finance_Terms_Update_Column>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_terms". */
export type Finance_Terms_Order_By = {
  created_at?: Maybe<Order_By>;
  finance_fixed_rate_configuration_id?: Maybe<Order_By>;
  finance_gfv_configuration_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_balloon?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: finance_terms */
export type Finance_Terms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "finance_terms" */
export enum Finance_Terms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinanceFixedRateConfigurationId = 'finance_fixed_rate_configuration_id',
  /** column name */
  FinanceGfvConfigurationId = 'finance_gfv_configuration_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxBalloon = 'max_balloon',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "finance_terms" */
export type Finance_Terms_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Finance_Terms_Stddev_Fields = {
  __typename?: 'finance_terms_stddev_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "finance_terms" */
export type Finance_Terms_Stddev_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Finance_Terms_Stddev_Pop_Fields = {
  __typename?: 'finance_terms_stddev_pop_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "finance_terms" */
export type Finance_Terms_Stddev_Pop_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Finance_Terms_Stddev_Samp_Fields = {
  __typename?: 'finance_terms_stddev_samp_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "finance_terms" */
export type Finance_Terms_Stddev_Samp_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** Streaming cursor of the table "finance_terms" */
export type Finance_Terms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finance_Terms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Terms_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  finance_fixed_rate_configuration_id?: Maybe<Scalars['uuid']>;
  finance_gfv_configuration_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Finance_Terms_Sum_Fields = {
  __typename?: 'finance_terms_sum_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['numeric']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "finance_terms" */
export type Finance_Terms_Sum_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** update columns of table "finance_terms" */
export enum Finance_Terms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinanceFixedRateConfigurationId = 'finance_fixed_rate_configuration_id',
  /** column name */
  FinanceGfvConfigurationId = 'finance_gfv_configuration_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxBalloon = 'max_balloon',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Finance_Terms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Finance_Terms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Finance_Terms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Terms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Finance_Terms_Var_Pop_Fields = {
  __typename?: 'finance_terms_var_pop_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "finance_terms" */
export type Finance_Terms_Var_Pop_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Finance_Terms_Var_Samp_Fields = {
  __typename?: 'finance_terms_var_samp_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "finance_terms" */
export type Finance_Terms_Var_Samp_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Finance_Terms_Variance_Fields = {
  __typename?: 'finance_terms_variance_fields';
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "finance_terms" */
export type Finance_Terms_Variance_Order_By = {
  /** A decimal value where 1 = 100% and 0 = 0%. */
  max_balloon?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** columns and relationships of "form_fields" */
export type Form_Fields = {
  __typename?: 'form_fields';
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  /** An object relationship */
  form: Forms;
  form_id: Scalars['uuid'];
  help_text?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
};


/** columns and relationships of "form_fields" */
export type Form_FieldsOptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "form_fields" */
export type Form_FieldsRulesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "form_fields" */
export type Form_Fields_Aggregate = {
  __typename?: 'form_fields_aggregate';
  aggregate?: Maybe<Form_Fields_Aggregate_Fields>;
  nodes: Array<Form_Fields>;
};

export type Form_Fields_Aggregate_Bool_Exp = {
  count?: Maybe<Form_Fields_Aggregate_Bool_Exp_Count>;
};

export type Form_Fields_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Form_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Form_Fields_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "form_fields" */
export type Form_Fields_Aggregate_Fields = {
  __typename?: 'form_fields_aggregate_fields';
  avg?: Maybe<Form_Fields_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Form_Fields_Max_Fields>;
  min?: Maybe<Form_Fields_Min_Fields>;
  stddev?: Maybe<Form_Fields_Stddev_Fields>;
  stddev_pop?: Maybe<Form_Fields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Form_Fields_Stddev_Samp_Fields>;
  sum?: Maybe<Form_Fields_Sum_Fields>;
  var_pop?: Maybe<Form_Fields_Var_Pop_Fields>;
  var_samp?: Maybe<Form_Fields_Var_Samp_Fields>;
  variance?: Maybe<Form_Fields_Variance_Fields>;
};


/** aggregate fields of "form_fields" */
export type Form_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Form_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_fields" */
export type Form_Fields_Aggregate_Order_By = {
  avg?: Maybe<Form_Fields_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Form_Fields_Max_Order_By>;
  min?: Maybe<Form_Fields_Min_Order_By>;
  stddev?: Maybe<Form_Fields_Stddev_Order_By>;
  stddev_pop?: Maybe<Form_Fields_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Form_Fields_Stddev_Samp_Order_By>;
  sum?: Maybe<Form_Fields_Sum_Order_By>;
  var_pop?: Maybe<Form_Fields_Var_Pop_Order_By>;
  var_samp?: Maybe<Form_Fields_Var_Samp_Order_By>;
  variance?: Maybe<Form_Fields_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Fields_Append_Input = {
  options?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "form_fields" */
export type Form_Fields_Arr_Rel_Insert_Input = {
  data: Array<Form_Fields_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Form_Fields_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Fields_Avg_Fields = {
  __typename?: 'form_fields_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_fields" */
export type Form_Fields_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_fields". All fields are combined with a logical 'AND'. */
export type Form_Fields_Bool_Exp = {
  _and?: Maybe<Array<Form_Fields_Bool_Exp>>;
  _not?: Maybe<Form_Fields_Bool_Exp>;
  _or?: Maybe<Array<Form_Fields_Bool_Exp>>;
  col?: Maybe<String_Comparison_Exp>;
  default_value?: Maybe<String_Comparison_Exp>;
  form?: Maybe<Forms_Bool_Exp>;
  form_id?: Maybe<Uuid_Comparison_Exp>;
  help_text?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  options?: Maybe<Jsonb_Comparison_Exp>;
  options_layout?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  property?: Maybe<String_Comparison_Exp>;
  rules?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_fields" */
export enum Form_Fields_Constraint {
  /** unique or primary key constraint on columns "form_id", "name" */
  FormFieldsPkey = 'form_fields_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Fields_Delete_At_Path_Input = {
  options?: Maybe<Array<Scalars['String']>>;
  rules?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Fields_Delete_Elem_Input = {
  options?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Fields_Delete_Key_Input = {
  options?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "form_fields" */
export type Form_Fields_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "form_fields" */
export type Form_Fields_Insert_Input = {
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: Maybe<Scalars['uuid']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Fields_Max_Fields = {
  __typename?: 'form_fields_max_fields';
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_fields" */
export type Form_Fields_Max_Order_By = {
  col?: Maybe<Order_By>;
  default_value?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  help_text?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options_layout?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Fields_Min_Fields = {
  __typename?: 'form_fields_min_fields';
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_fields" */
export type Form_Fields_Min_Order_By = {
  col?: Maybe<Order_By>;
  default_value?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  help_text?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options_layout?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_fields" */
export type Form_Fields_Mutation_Response = {
  __typename?: 'form_fields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Fields>;
};

/** on_conflict condition type for table "form_fields" */
export type Form_Fields_On_Conflict = {
  constraint: Form_Fields_Constraint;
  update_columns?: Array<Form_Fields_Update_Column>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};

/** Ordering options when selecting data from "form_fields". */
export type Form_Fields_Order_By = {
  col?: Maybe<Order_By>;
  default_value?: Maybe<Order_By>;
  form?: Maybe<Forms_Order_By>;
  form_id?: Maybe<Order_By>;
  help_text?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  options?: Maybe<Order_By>;
  options_layout?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  property?: Maybe<Order_By>;
  rules?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: form_fields */
export type Form_Fields_Pk_Columns_Input = {
  form_id: Scalars['uuid'];
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Fields_Prepend_Input = {
  options?: Maybe<Scalars['jsonb']>;
  rules?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "form_fields" */
export enum Form_Fields_Select_Column {
  /** column name */
  Col = 'col',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  FormId = 'form_id',
  /** column name */
  HelpText = 'help_text',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  OptionsLayout = 'options_layout',
  /** column name */
  Order = 'order',
  /** column name */
  Property = 'property',
  /** column name */
  Rules = 'rules',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "form_fields" */
export type Form_Fields_Set_Input = {
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Form_Fields_Stddev_Fields = {
  __typename?: 'form_fields_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_fields" */
export type Form_Fields_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Fields_Stddev_Pop_Fields = {
  __typename?: 'form_fields_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_fields" */
export type Form_Fields_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Fields_Stddev_Samp_Fields = {
  __typename?: 'form_fields_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_fields" */
export type Form_Fields_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "form_fields" */
export type Form_Fields_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Fields_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Fields_Stream_Cursor_Value_Input = {
  col?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['uuid']>;
  help_text?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['jsonb']>;
  options_layout?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** The associated user property for this field in object-style dot notation. E.g. user.email */
  property?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Form_Fields_Sum_Fields = {
  __typename?: 'form_fields_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "form_fields" */
export type Form_Fields_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "form_fields" */
export enum Form_Fields_Update_Column {
  /** column name */
  Col = 'col',
  /** column name */
  DefaultValue = 'default_value',
  /** column name */
  FormId = 'form_id',
  /** column name */
  HelpText = 'help_text',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  OptionsLayout = 'options_layout',
  /** column name */
  Order = 'order',
  /** column name */
  Property = 'property',
  /** column name */
  Rules = 'rules',
  /** column name */
  Type = 'type'
}

export type Form_Fields_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Form_Fields_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Form_Fields_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Form_Fields_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Form_Fields_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Form_Fields_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Form_Fields_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Form_Fields_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Fields_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Form_Fields_Var_Pop_Fields = {
  __typename?: 'form_fields_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_fields" */
export type Form_Fields_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Fields_Var_Samp_Fields = {
  __typename?: 'form_fields_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_fields" */
export type Form_Fields_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Fields_Variance_Fields = {
  __typename?: 'form_fields_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_fields" */
export type Form_Fields_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "form_submissions" */
export type Form_Submissions = {
  __typename?: 'form_submissions';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  form: Forms;
  form_id: Scalars['uuid'];
  id: Scalars['uuid'];
  ip?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  values: Scalars['jsonb'];
};


/** columns and relationships of "form_submissions" */
export type Form_SubmissionsValuesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "form_submissions" */
export type Form_Submissions_Aggregate = {
  __typename?: 'form_submissions_aggregate';
  aggregate?: Maybe<Form_Submissions_Aggregate_Fields>;
  nodes: Array<Form_Submissions>;
};

export type Form_Submissions_Aggregate_Bool_Exp = {
  count?: Maybe<Form_Submissions_Aggregate_Bool_Exp_Count>;
};

export type Form_Submissions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Form_Submissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Form_Submissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "form_submissions" */
export type Form_Submissions_Aggregate_Fields = {
  __typename?: 'form_submissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Submissions_Max_Fields>;
  min?: Maybe<Form_Submissions_Min_Fields>;
};


/** aggregate fields of "form_submissions" */
export type Form_Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Form_Submissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_submissions" */
export type Form_Submissions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Form_Submissions_Max_Order_By>;
  min?: Maybe<Form_Submissions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Submissions_Append_Input = {
  values?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "form_submissions" */
export type Form_Submissions_Arr_Rel_Insert_Input = {
  data: Array<Form_Submissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_submissions". All fields are combined with a logical 'AND'. */
export type Form_Submissions_Bool_Exp = {
  _and?: Maybe<Array<Form_Submissions_Bool_Exp>>;
  _not?: Maybe<Form_Submissions_Bool_Exp>;
  _or?: Maybe<Array<Form_Submissions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  form?: Maybe<Forms_Bool_Exp>;
  form_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ip?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  values?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_submissions" */
export enum Form_Submissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormSubmissionsPkey = 'form_submissions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Submissions_Delete_At_Path_Input = {
  values?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Submissions_Delete_Elem_Input = {
  values?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Submissions_Delete_Key_Input = {
  values?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "form_submissions" */
export type Form_Submissions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  form?: Maybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  values?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Form_Submissions_Max_Fields = {
  __typename?: 'form_submissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "form_submissions" */
export type Form_Submissions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Submissions_Min_Fields = {
  __typename?: 'form_submissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "form_submissions" */
export type Form_Submissions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "form_submissions" */
export type Form_Submissions_Mutation_Response = {
  __typename?: 'form_submissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Submissions>;
};

/** input type for inserting object relation for remote table "form_submissions" */
export type Form_Submissions_Obj_Rel_Insert_Input = {
  data: Form_Submissions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};

/** on_conflict condition type for table "form_submissions" */
export type Form_Submissions_On_Conflict = {
  constraint: Form_Submissions_Constraint;
  update_columns?: Array<Form_Submissions_Update_Column>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};

/** Ordering options when selecting data from "form_submissions". */
export type Form_Submissions_Order_By = {
  created_at?: Maybe<Order_By>;
  form?: Maybe<Forms_Order_By>;
  form_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  values?: Maybe<Order_By>;
};

/** primary key columns input for table: form_submissions */
export type Form_Submissions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Submissions_Prepend_Input = {
  values?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "form_submissions" */
export enum Form_Submissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Values = 'values'
}

/** input type for updating data in table "form_submissions" */
export type Form_Submissions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  values?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "form_submissions" */
export type Form_Submissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Submissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Submissions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  form_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  values?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "form_submissions" */
export enum Form_Submissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Values = 'values'
}

export type Form_Submissions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Form_Submissions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Form_Submissions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Form_Submissions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Form_Submissions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Form_Submissions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Form_Submissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Submissions_Bool_Exp;
};

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: 'forms';
  button_text: Scalars['String'];
  /** Create a moment (aka enquiry) when this form is submitted. */
  create_moment_on_submit: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  custom_field_values: Array<Custom_Field_Values>;
  /** An aggregate relationship */
  custom_field_values_aggregate: Custom_Field_Values_Aggregate;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  form_info?: Maybe<Scalars['String']>;
  /** An array relationship */
  form_submissions: Array<Form_Submissions>;
  /** An aggregate relationship */
  form_submissions_aggregate: Form_Submissions_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  on_success_message?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  /** Attempt to create a user account on form submission. */
  signup_user_on_submit: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "forms" */
export type FormsCustom_Field_ValuesArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsCustom_Field_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_FieldsArgs = {
  distinct_on?: Maybe<Array<Form_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Fields_Order_By>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Fields_Order_By>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


/** columns and relationships of "forms" */
export type FormsForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  __typename?: 'forms_aggregate';
  aggregate?: Maybe<Forms_Aggregate_Fields>;
  nodes: Array<Forms>;
};

export type Forms_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Forms_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Forms_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Forms_Aggregate_Bool_Exp_Count>;
};

export type Forms_Aggregate_Bool_Exp_Bool_And = {
  arguments: Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Forms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Forms_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Forms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Forms_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Forms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Forms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  __typename?: 'forms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Forms_Max_Fields>;
  min?: Maybe<Forms_Min_Fields>;
};


/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "forms" */
export type Forms_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Forms_Max_Order_By>;
  min?: Maybe<Forms_Min_Order_By>;
};

/** input type for inserting array relation for remote table "forms" */
export type Forms_Arr_Rel_Insert_Input = {
  data: Array<Forms_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Forms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: Maybe<Array<Forms_Bool_Exp>>;
  _not?: Maybe<Forms_Bool_Exp>;
  _or?: Maybe<Array<Forms_Bool_Exp>>;
  button_text?: Maybe<String_Comparison_Exp>;
  create_moment_on_submit?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_field_values?: Maybe<Custom_Field_Values_Bool_Exp>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Bool_Exp>;
  form_fields?: Maybe<Form_Fields_Bool_Exp>;
  form_fields_aggregate?: Maybe<Form_Fields_Aggregate_Bool_Exp>;
  form_info?: Maybe<String_Comparison_Exp>;
  form_submissions?: Maybe<Form_Submissions_Bool_Exp>;
  form_submissions_aggregate?: Maybe<Form_Submissions_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  on_success_message?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  signup_user_on_submit?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormsPkey = 'forms_pkey'
}

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  button_text?: Maybe<Scalars['String']>;
  /** Create a moment (aka enquiry) when this form is submitted. */
  create_moment_on_submit?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_field_values?: Maybe<Custom_Field_Values_Arr_Rel_Insert_Input>;
  form_fields?: Maybe<Form_Fields_Arr_Rel_Insert_Input>;
  form_info?: Maybe<Scalars['String']>;
  form_submissions?: Maybe<Form_Submissions_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_success_message?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** Attempt to create a user account on form submission. */
  signup_user_on_submit?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Forms_Max_Fields = {
  __typename?: 'forms_max_fields';
  button_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_success_message?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "forms" */
export type Forms_Max_Order_By = {
  button_text?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  form_info?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  on_success_message?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Forms_Min_Fields = {
  __typename?: 'forms_min_fields';
  button_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_success_message?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "forms" */
export type Forms_Min_Order_By = {
  button_text?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  form_info?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  on_success_message?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: 'forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Forms>;
};

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  data: Forms_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Forms_On_Conflict>;
};

/** on_conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint;
  update_columns?: Array<Forms_Update_Column>;
  where?: Maybe<Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  button_text?: Maybe<Order_By>;
  create_moment_on_submit?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Order_By>;
  form_fields_aggregate?: Maybe<Form_Fields_Aggregate_Order_By>;
  form_info?: Maybe<Order_By>;
  form_submissions_aggregate?: Maybe<Form_Submissions_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  on_success_message?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  signup_user_on_submit?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  CreateMomentOnSubmit = 'create_moment_on_submit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormInfo = 'form_info',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnSuccessMessage = 'on_success_message',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SignupUserOnSubmit = 'signup_user_on_submit',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "forms_aggregate_bool_exp_bool_and_arguments_columns" columns of table "forms" */
export enum Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CreateMomentOnSubmit = 'create_moment_on_submit',
  /** column name */
  SignupUserOnSubmit = 'signup_user_on_submit'
}

/** select "forms_aggregate_bool_exp_bool_or_arguments_columns" columns of table "forms" */
export enum Forms_Select_Column_Forms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CreateMomentOnSubmit = 'create_moment_on_submit',
  /** column name */
  SignupUserOnSubmit = 'signup_user_on_submit'
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  button_text?: Maybe<Scalars['String']>;
  /** Create a moment (aka enquiry) when this form is submitted. */
  create_moment_on_submit?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_success_message?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** Attempt to create a user account on form submission. */
  signup_user_on_submit?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "forms" */
export type Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Forms_Stream_Cursor_Value_Input = {
  button_text?: Maybe<Scalars['String']>;
  /** Create a moment (aka enquiry) when this form is submitted. */
  create_moment_on_submit?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  on_success_message?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** Attempt to create a user account on form submission. */
  signup_user_on_submit?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  CreateMomentOnSubmit = 'create_moment_on_submit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormInfo = 'form_info',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnSuccessMessage = 'on_success_message',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SignupUserOnSubmit = 'signup_user_on_submit',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Forms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Forms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Forms_Bool_Exp;
};


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

/** columns and relationships of "insights_click_events" */
export type Insights_Click_Events = {
  __typename?: 'insights_click_events';
  element_classname?: Maybe<Scalars['String']>;
  element_id?: Maybe<Scalars['String']>;
  element_tagname?: Maybe<Scalars['String']>;
  element_text?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "insights_click_events" */
export type Insights_Click_Events_Aggregate = {
  __typename?: 'insights_click_events_aggregate';
  aggregate?: Maybe<Insights_Click_Events_Aggregate_Fields>;
  nodes: Array<Insights_Click_Events>;
};

/** aggregate fields of "insights_click_events" */
export type Insights_Click_Events_Aggregate_Fields = {
  __typename?: 'insights_click_events_aggregate_fields';
  avg?: Maybe<Insights_Click_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Insights_Click_Events_Max_Fields>;
  min?: Maybe<Insights_Click_Events_Min_Fields>;
  stddev?: Maybe<Insights_Click_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Insights_Click_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insights_Click_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Insights_Click_Events_Sum_Fields>;
  var_pop?: Maybe<Insights_Click_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Insights_Click_Events_Var_Samp_Fields>;
  variance?: Maybe<Insights_Click_Events_Variance_Fields>;
};


/** aggregate fields of "insights_click_events" */
export type Insights_Click_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Click_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Insights_Click_Events_Avg_Fields = {
  __typename?: 'insights_click_events_avg_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "insights_click_events". All fields are combined with a logical 'AND'. */
export type Insights_Click_Events_Bool_Exp = {
  _and?: Maybe<Array<Insights_Click_Events_Bool_Exp>>;
  _not?: Maybe<Insights_Click_Events_Bool_Exp>;
  _or?: Maybe<Array<Insights_Click_Events_Bool_Exp>>;
  element_classname?: Maybe<String_Comparison_Exp>;
  element_id?: Maybe<String_Comparison_Exp>;
  element_tagname?: Maybe<String_Comparison_Exp>;
  element_text?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  page_height?: Maybe<Numeric_Comparison_Exp>;
  page_width?: Maybe<Numeric_Comparison_Exp>;
  page_x?: Maybe<Numeric_Comparison_Exp>;
  page_y?: Maybe<Numeric_Comparison_Exp>;
  screen_height?: Maybe<Numeric_Comparison_Exp>;
  screen_width?: Maybe<Numeric_Comparison_Exp>;
  screen_x?: Maybe<Numeric_Comparison_Exp>;
  screen_y?: Maybe<Numeric_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insights_click_events" */
export enum Insights_Click_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsightsClickEventsPkey = 'insights_click_events_pkey'
}

/** input type for incrementing numeric columns in table "insights_click_events" */
export type Insights_Click_Events_Inc_Input = {
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "insights_click_events" */
export type Insights_Click_Events_Insert_Input = {
  element_classname?: Maybe<Scalars['String']>;
  element_id?: Maybe<Scalars['String']>;
  element_tagname?: Maybe<Scalars['String']>;
  element_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insights_Click_Events_Max_Fields = {
  __typename?: 'insights_click_events_max_fields';
  element_classname?: Maybe<Scalars['String']>;
  element_id?: Maybe<Scalars['String']>;
  element_tagname?: Maybe<Scalars['String']>;
  element_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insights_Click_Events_Min_Fields = {
  __typename?: 'insights_click_events_min_fields';
  element_classname?: Maybe<Scalars['String']>;
  element_id?: Maybe<Scalars['String']>;
  element_tagname?: Maybe<Scalars['String']>;
  element_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insights_click_events" */
export type Insights_Click_Events_Mutation_Response = {
  __typename?: 'insights_click_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Click_Events>;
};

/** input type for inserting object relation for remote table "insights_click_events" */
export type Insights_Click_Events_Obj_Rel_Insert_Input = {
  data: Insights_Click_Events_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Insights_Click_Events_On_Conflict>;
};

/** on_conflict condition type for table "insights_click_events" */
export type Insights_Click_Events_On_Conflict = {
  constraint: Insights_Click_Events_Constraint;
  update_columns?: Array<Insights_Click_Events_Update_Column>;
  where?: Maybe<Insights_Click_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_click_events". */
export type Insights_Click_Events_Order_By = {
  element_classname?: Maybe<Order_By>;
  element_id?: Maybe<Order_By>;
  element_tagname?: Maybe<Order_By>;
  element_text?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  page_height?: Maybe<Order_By>;
  page_width?: Maybe<Order_By>;
  page_x?: Maybe<Order_By>;
  page_y?: Maybe<Order_By>;
  screen_height?: Maybe<Order_By>;
  screen_width?: Maybe<Order_By>;
  screen_x?: Maybe<Order_By>;
  screen_y?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: insights_click_events */
export type Insights_Click_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insights_click_events" */
export enum Insights_Click_Events_Select_Column {
  /** column name */
  ElementClassname = 'element_classname',
  /** column name */
  ElementId = 'element_id',
  /** column name */
  ElementTagname = 'element_tagname',
  /** column name */
  ElementText = 'element_text',
  /** column name */
  Id = 'id',
  /** column name */
  PageHeight = 'page_height',
  /** column name */
  PageWidth = 'page_width',
  /** column name */
  PageX = 'page_x',
  /** column name */
  PageY = 'page_y',
  /** column name */
  ScreenHeight = 'screen_height',
  /** column name */
  ScreenWidth = 'screen_width',
  /** column name */
  ScreenX = 'screen_x',
  /** column name */
  ScreenY = 'screen_y',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "insights_click_events" */
export type Insights_Click_Events_Set_Input = {
  element_classname?: Maybe<Scalars['String']>;
  element_id?: Maybe<Scalars['String']>;
  element_tagname?: Maybe<Scalars['String']>;
  element_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Insights_Click_Events_Stddev_Fields = {
  __typename?: 'insights_click_events_stddev_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Insights_Click_Events_Stddev_Pop_Fields = {
  __typename?: 'insights_click_events_stddev_pop_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Insights_Click_Events_Stddev_Samp_Fields = {
  __typename?: 'insights_click_events_stddev_samp_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "insights_click_events" */
export type Insights_Click_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Click_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Click_Events_Stream_Cursor_Value_Input = {
  element_classname?: Maybe<Scalars['String']>;
  element_id?: Maybe<Scalars['String']>;
  element_tagname?: Maybe<Scalars['String']>;
  element_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Insights_Click_Events_Sum_Fields = {
  __typename?: 'insights_click_events_sum_fields';
  page_height?: Maybe<Scalars['numeric']>;
  page_width?: Maybe<Scalars['numeric']>;
  page_x?: Maybe<Scalars['numeric']>;
  page_y?: Maybe<Scalars['numeric']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  screen_x?: Maybe<Scalars['numeric']>;
  screen_y?: Maybe<Scalars['numeric']>;
};

/** update columns of table "insights_click_events" */
export enum Insights_Click_Events_Update_Column {
  /** column name */
  ElementClassname = 'element_classname',
  /** column name */
  ElementId = 'element_id',
  /** column name */
  ElementTagname = 'element_tagname',
  /** column name */
  ElementText = 'element_text',
  /** column name */
  Id = 'id',
  /** column name */
  PageHeight = 'page_height',
  /** column name */
  PageWidth = 'page_width',
  /** column name */
  PageX = 'page_x',
  /** column name */
  PageY = 'page_y',
  /** column name */
  ScreenHeight = 'screen_height',
  /** column name */
  ScreenWidth = 'screen_width',
  /** column name */
  ScreenX = 'screen_x',
  /** column name */
  ScreenY = 'screen_y',
  /** column name */
  Url = 'url'
}

export type Insights_Click_Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Insights_Click_Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Click_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Click_Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Insights_Click_Events_Var_Pop_Fields = {
  __typename?: 'insights_click_events_var_pop_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Insights_Click_Events_Var_Samp_Fields = {
  __typename?: 'insights_click_events_var_samp_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Insights_Click_Events_Variance_Fields = {
  __typename?: 'insights_click_events_variance_fields';
  page_height?: Maybe<Scalars['Float']>;
  page_width?: Maybe<Scalars['Float']>;
  page_x?: Maybe<Scalars['Float']>;
  page_y?: Maybe<Scalars['Float']>;
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
  screen_x?: Maybe<Scalars['Float']>;
  screen_y?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "insights_devices" */
export type Insights_Devices = {
  __typename?: 'insights_devices';
  browser?: Maybe<Scalars['String']>;
  browser_version?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  device_type?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  ip: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_agent?: Maybe<Scalars['String']>;
};

/** aggregated selection of "insights_devices" */
export type Insights_Devices_Aggregate = {
  __typename?: 'insights_devices_aggregate';
  aggregate?: Maybe<Insights_Devices_Aggregate_Fields>;
  nodes: Array<Insights_Devices>;
};

/** aggregate fields of "insights_devices" */
export type Insights_Devices_Aggregate_Fields = {
  __typename?: 'insights_devices_aggregate_fields';
  avg?: Maybe<Insights_Devices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Insights_Devices_Max_Fields>;
  min?: Maybe<Insights_Devices_Min_Fields>;
  stddev?: Maybe<Insights_Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Insights_Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insights_Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Insights_Devices_Sum_Fields>;
  var_pop?: Maybe<Insights_Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Insights_Devices_Var_Samp_Fields>;
  variance?: Maybe<Insights_Devices_Variance_Fields>;
};


/** aggregate fields of "insights_devices" */
export type Insights_Devices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Devices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Insights_Devices_Avg_Fields = {
  __typename?: 'insights_devices_avg_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "insights_devices". All fields are combined with a logical 'AND'. */
export type Insights_Devices_Bool_Exp = {
  _and?: Maybe<Array<Insights_Devices_Bool_Exp>>;
  _not?: Maybe<Insights_Devices_Bool_Exp>;
  _or?: Maybe<Array<Insights_Devices_Bool_Exp>>;
  browser?: Maybe<String_Comparison_Exp>;
  browser_version?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  device_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ip?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  os?: Maybe<String_Comparison_Exp>;
  os_version?: Maybe<String_Comparison_Exp>;
  screen_height?: Maybe<Numeric_Comparison_Exp>;
  screen_width?: Maybe<Numeric_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_agent?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insights_devices" */
export enum Insights_Devices_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsightsDevicePkey = 'insights_device_pkey'
}

/** input type for incrementing numeric columns in table "insights_devices" */
export type Insights_Devices_Inc_Input = {
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "insights_devices" */
export type Insights_Devices_Insert_Input = {
  browser?: Maybe<Scalars['String']>;
  browser_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insights_Devices_Max_Fields = {
  __typename?: 'insights_devices_max_fields';
  browser?: Maybe<Scalars['String']>;
  browser_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insights_Devices_Min_Fields = {
  __typename?: 'insights_devices_min_fields';
  browser?: Maybe<Scalars['String']>;
  browser_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insights_devices" */
export type Insights_Devices_Mutation_Response = {
  __typename?: 'insights_devices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Devices>;
};

/** input type for inserting object relation for remote table "insights_devices" */
export type Insights_Devices_Obj_Rel_Insert_Input = {
  data: Insights_Devices_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Insights_Devices_On_Conflict>;
};

/** on_conflict condition type for table "insights_devices" */
export type Insights_Devices_On_Conflict = {
  constraint: Insights_Devices_Constraint;
  update_columns?: Array<Insights_Devices_Update_Column>;
  where?: Maybe<Insights_Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_devices". */
export type Insights_Devices_Order_By = {
  browser?: Maybe<Order_By>;
  browser_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  os?: Maybe<Order_By>;
  os_version?: Maybe<Order_By>;
  screen_height?: Maybe<Order_By>;
  screen_width?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_agent?: Maybe<Order_By>;
};

/** primary key columns input for table: insights_devices */
export type Insights_Devices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insights_devices" */
export enum Insights_Devices_Select_Column {
  /** column name */
  Browser = 'browser',
  /** column name */
  BrowserVersion = 'browser_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Language = 'language',
  /** column name */
  Os = 'os',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  ScreenHeight = 'screen_height',
  /** column name */
  ScreenWidth = 'screen_width',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent'
}

/** input type for updating data in table "insights_devices" */
export type Insights_Devices_Set_Input = {
  browser?: Maybe<Scalars['String']>;
  browser_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Insights_Devices_Stddev_Fields = {
  __typename?: 'insights_devices_stddev_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Insights_Devices_Stddev_Pop_Fields = {
  __typename?: 'insights_devices_stddev_pop_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Insights_Devices_Stddev_Samp_Fields = {
  __typename?: 'insights_devices_stddev_samp_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "insights_devices" */
export type Insights_Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Devices_Stream_Cursor_Value_Input = {
  browser?: Maybe<Scalars['String']>;
  browser_version?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  os_version?: Maybe<Scalars['String']>;
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_agent?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Insights_Devices_Sum_Fields = {
  __typename?: 'insights_devices_sum_fields';
  screen_height?: Maybe<Scalars['numeric']>;
  screen_width?: Maybe<Scalars['numeric']>;
};

/** update columns of table "insights_devices" */
export enum Insights_Devices_Update_Column {
  /** column name */
  Browser = 'browser',
  /** column name */
  BrowserVersion = 'browser_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Language = 'language',
  /** column name */
  Os = 'os',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  ScreenHeight = 'screen_height',
  /** column name */
  ScreenWidth = 'screen_width',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent'
}

export type Insights_Devices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Insights_Devices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Devices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Insights_Devices_Var_Pop_Fields = {
  __typename?: 'insights_devices_var_pop_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Insights_Devices_Var_Samp_Fields = {
  __typename?: 'insights_devices_var_samp_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Insights_Devices_Variance_Fields = {
  __typename?: 'insights_devices_variance_fields';
  screen_height?: Maybe<Scalars['Float']>;
  screen_width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "insights_event_types" */
export type Insights_Event_Types = {
  __typename?: 'insights_event_types';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  insights_events: Array<Insights_Events>;
  /** An aggregate relationship */
  insights_events_aggregate: Insights_Events_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "insights_event_types" */
export type Insights_Event_TypesInsights_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Events_Order_By>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};


/** columns and relationships of "insights_event_types" */
export type Insights_Event_TypesInsights_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Events_Order_By>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};

/** aggregated selection of "insights_event_types" */
export type Insights_Event_Types_Aggregate = {
  __typename?: 'insights_event_types_aggregate';
  aggregate?: Maybe<Insights_Event_Types_Aggregate_Fields>;
  nodes: Array<Insights_Event_Types>;
};

/** aggregate fields of "insights_event_types" */
export type Insights_Event_Types_Aggregate_Fields = {
  __typename?: 'insights_event_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insights_Event_Types_Max_Fields>;
  min?: Maybe<Insights_Event_Types_Min_Fields>;
};


/** aggregate fields of "insights_event_types" */
export type Insights_Event_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Event_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insights_event_types". All fields are combined with a logical 'AND'. */
export type Insights_Event_Types_Bool_Exp = {
  _and?: Maybe<Array<Insights_Event_Types_Bool_Exp>>;
  _not?: Maybe<Insights_Event_Types_Bool_Exp>;
  _or?: Maybe<Array<Insights_Event_Types_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  insights_events?: Maybe<Insights_Events_Bool_Exp>;
  insights_events_aggregate?: Maybe<Insights_Events_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insights_event_types" */
export enum Insights_Event_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  InsightsEventTypesPkey = 'insights_event_types_pkey'
}

export enum Insights_Event_Types_Enum {
  Click = 'click',
  PageView = 'page_view'
}

/** Boolean expression to compare columns of type "insights_event_types_enum". All fields are combined with logical 'AND'. */
export type Insights_Event_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Insights_Event_Types_Enum>;
  _in?: Maybe<Array<Insights_Event_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Insights_Event_Types_Enum>;
  _nin?: Maybe<Array<Insights_Event_Types_Enum>>;
};

/** input type for inserting data into table "insights_event_types" */
export type Insights_Event_Types_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  insights_events?: Maybe<Insights_Events_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insights_Event_Types_Max_Fields = {
  __typename?: 'insights_event_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insights_Event_Types_Min_Fields = {
  __typename?: 'insights_event_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insights_event_types" */
export type Insights_Event_Types_Mutation_Response = {
  __typename?: 'insights_event_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Event_Types>;
};

/** input type for inserting object relation for remote table "insights_event_types" */
export type Insights_Event_Types_Obj_Rel_Insert_Input = {
  data: Insights_Event_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Insights_Event_Types_On_Conflict>;
};

/** on_conflict condition type for table "insights_event_types" */
export type Insights_Event_Types_On_Conflict = {
  constraint: Insights_Event_Types_Constraint;
  update_columns?: Array<Insights_Event_Types_Update_Column>;
  where?: Maybe<Insights_Event_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_event_types". */
export type Insights_Event_Types_Order_By = {
  description?: Maybe<Order_By>;
  insights_events_aggregate?: Maybe<Insights_Events_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: insights_event_types */
export type Insights_Event_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "insights_event_types" */
export enum Insights_Event_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "insights_event_types" */
export type Insights_Event_Types_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "insights_event_types" */
export type Insights_Event_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Event_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Event_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "insights_event_types" */
export enum Insights_Event_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Insights_Event_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Event_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Event_Types_Bool_Exp;
};

/** columns and relationships of "insights_events" */
export type Insights_Events = {
  __typename?: 'insights_events';
  /** An object relationship */
  click_event?: Maybe<Insights_Click_Events>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  event_type: Insights_Event_Types;
  id: Scalars['uuid'];
  idempotent_key?: Maybe<Scalars['String']>;
  insights_click_event_id?: Maybe<Scalars['uuid']>;
  insights_page_view_event_id?: Maybe<Scalars['uuid']>;
  insights_session_id: Scalars['uuid'];
  /** An object relationship */
  page_view_event?: Maybe<Insights_Page_View_Events>;
  type: Insights_Event_Types_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "insights_events" */
export type Insights_Events_Aggregate = {
  __typename?: 'insights_events_aggregate';
  aggregate?: Maybe<Insights_Events_Aggregate_Fields>;
  nodes: Array<Insights_Events>;
};

export type Insights_Events_Aggregate_Bool_Exp = {
  count?: Maybe<Insights_Events_Aggregate_Bool_Exp_Count>;
};

export type Insights_Events_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Insights_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Insights_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "insights_events" */
export type Insights_Events_Aggregate_Fields = {
  __typename?: 'insights_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insights_Events_Max_Fields>;
  min?: Maybe<Insights_Events_Min_Fields>;
};


/** aggregate fields of "insights_events" */
export type Insights_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insights_events" */
export type Insights_Events_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Insights_Events_Max_Order_By>;
  min?: Maybe<Insights_Events_Min_Order_By>;
};

/** input type for inserting array relation for remote table "insights_events" */
export type Insights_Events_Arr_Rel_Insert_Input = {
  data: Array<Insights_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Insights_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "insights_events". All fields are combined with a logical 'AND'. */
export type Insights_Events_Bool_Exp = {
  _and?: Maybe<Array<Insights_Events_Bool_Exp>>;
  _not?: Maybe<Insights_Events_Bool_Exp>;
  _or?: Maybe<Array<Insights_Events_Bool_Exp>>;
  click_event?: Maybe<Insights_Click_Events_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_type?: Maybe<Insights_Event_Types_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  idempotent_key?: Maybe<String_Comparison_Exp>;
  insights_click_event_id?: Maybe<Uuid_Comparison_Exp>;
  insights_page_view_event_id?: Maybe<Uuid_Comparison_Exp>;
  insights_session_id?: Maybe<Uuid_Comparison_Exp>;
  page_view_event?: Maybe<Insights_Page_View_Events_Bool_Exp>;
  type?: Maybe<Insights_Event_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "insights_events" */
export enum Insights_Events_Constraint {
  /** unique or primary key constraint on columns "idempotent_key" */
  InsightsEventsIdempotentKeyKey = 'insights_events_idempotent_key_key',
  /** unique or primary key constraint on columns "id" */
  InsightsEventsPkey = 'insights_events_pkey'
}

/** input type for inserting data into table "insights_events" */
export type Insights_Events_Insert_Input = {
  click_event?: Maybe<Insights_Click_Events_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Insights_Event_Types_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  idempotent_key?: Maybe<Scalars['String']>;
  insights_click_event_id?: Maybe<Scalars['uuid']>;
  insights_page_view_event_id?: Maybe<Scalars['uuid']>;
  insights_session_id?: Maybe<Scalars['uuid']>;
  page_view_event?: Maybe<Insights_Page_View_Events_Obj_Rel_Insert_Input>;
  type?: Maybe<Insights_Event_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Insights_Events_Max_Fields = {
  __typename?: 'insights_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idempotent_key?: Maybe<Scalars['String']>;
  insights_click_event_id?: Maybe<Scalars['uuid']>;
  insights_page_view_event_id?: Maybe<Scalars['uuid']>;
  insights_session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "insights_events" */
export type Insights_Events_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idempotent_key?: Maybe<Order_By>;
  insights_click_event_id?: Maybe<Order_By>;
  insights_page_view_event_id?: Maybe<Order_By>;
  insights_session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Insights_Events_Min_Fields = {
  __typename?: 'insights_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idempotent_key?: Maybe<Scalars['String']>;
  insights_click_event_id?: Maybe<Scalars['uuid']>;
  insights_page_view_event_id?: Maybe<Scalars['uuid']>;
  insights_session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "insights_events" */
export type Insights_Events_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  idempotent_key?: Maybe<Order_By>;
  insights_click_event_id?: Maybe<Order_By>;
  insights_page_view_event_id?: Maybe<Order_By>;
  insights_session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "insights_events" */
export type Insights_Events_Mutation_Response = {
  __typename?: 'insights_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Events>;
};

/** on_conflict condition type for table "insights_events" */
export type Insights_Events_On_Conflict = {
  constraint: Insights_Events_Constraint;
  update_columns?: Array<Insights_Events_Update_Column>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_events". */
export type Insights_Events_Order_By = {
  click_event?: Maybe<Insights_Click_Events_Order_By>;
  created_at?: Maybe<Order_By>;
  event_type?: Maybe<Insights_Event_Types_Order_By>;
  id?: Maybe<Order_By>;
  idempotent_key?: Maybe<Order_By>;
  insights_click_event_id?: Maybe<Order_By>;
  insights_page_view_event_id?: Maybe<Order_By>;
  insights_session_id?: Maybe<Order_By>;
  page_view_event?: Maybe<Insights_Page_View_Events_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: insights_events */
export type Insights_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insights_events" */
export enum Insights_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdempotentKey = 'idempotent_key',
  /** column name */
  InsightsClickEventId = 'insights_click_event_id',
  /** column name */
  InsightsPageViewEventId = 'insights_page_view_event_id',
  /** column name */
  InsightsSessionId = 'insights_session_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "insights_events" */
export type Insights_Events_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idempotent_key?: Maybe<Scalars['String']>;
  insights_click_event_id?: Maybe<Scalars['uuid']>;
  insights_page_view_event_id?: Maybe<Scalars['uuid']>;
  insights_session_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Insights_Event_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "insights_events" */
export type Insights_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Events_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idempotent_key?: Maybe<Scalars['String']>;
  insights_click_event_id?: Maybe<Scalars['uuid']>;
  insights_page_view_event_id?: Maybe<Scalars['uuid']>;
  insights_session_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Insights_Event_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "insights_events" */
export enum Insights_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdempotentKey = 'idempotent_key',
  /** column name */
  InsightsClickEventId = 'insights_click_event_id',
  /** column name */
  InsightsPageViewEventId = 'insights_page_view_event_id',
  /** column name */
  InsightsSessionId = 'insights_session_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Insights_Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Events_Bool_Exp;
};

/** columns and relationships of "insights_page_view_events" */
export type Insights_Page_View_Events = {
  __typename?: 'insights_page_view_events';
  domain: Scalars['String'];
  id: Scalars['uuid'];
  url: Scalars['String'];
};

/** aggregated selection of "insights_page_view_events" */
export type Insights_Page_View_Events_Aggregate = {
  __typename?: 'insights_page_view_events_aggregate';
  aggregate?: Maybe<Insights_Page_View_Events_Aggregate_Fields>;
  nodes: Array<Insights_Page_View_Events>;
};

/** aggregate fields of "insights_page_view_events" */
export type Insights_Page_View_Events_Aggregate_Fields = {
  __typename?: 'insights_page_view_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insights_Page_View_Events_Max_Fields>;
  min?: Maybe<Insights_Page_View_Events_Min_Fields>;
};


/** aggregate fields of "insights_page_view_events" */
export type Insights_Page_View_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Page_View_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insights_page_view_events". All fields are combined with a logical 'AND'. */
export type Insights_Page_View_Events_Bool_Exp = {
  _and?: Maybe<Array<Insights_Page_View_Events_Bool_Exp>>;
  _not?: Maybe<Insights_Page_View_Events_Bool_Exp>;
  _or?: Maybe<Array<Insights_Page_View_Events_Bool_Exp>>;
  domain?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insights_page_view_events" */
export enum Insights_Page_View_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsightsPageViewEventsPkey = 'insights_page_view_events_pkey'
}

/** input type for inserting data into table "insights_page_view_events" */
export type Insights_Page_View_Events_Insert_Input = {
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insights_Page_View_Events_Max_Fields = {
  __typename?: 'insights_page_view_events_max_fields';
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insights_Page_View_Events_Min_Fields = {
  __typename?: 'insights_page_view_events_min_fields';
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insights_page_view_events" */
export type Insights_Page_View_Events_Mutation_Response = {
  __typename?: 'insights_page_view_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Page_View_Events>;
};

/** input type for inserting object relation for remote table "insights_page_view_events" */
export type Insights_Page_View_Events_Obj_Rel_Insert_Input = {
  data: Insights_Page_View_Events_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Insights_Page_View_Events_On_Conflict>;
};

/** on_conflict condition type for table "insights_page_view_events" */
export type Insights_Page_View_Events_On_Conflict = {
  constraint: Insights_Page_View_Events_Constraint;
  update_columns?: Array<Insights_Page_View_Events_Update_Column>;
  where?: Maybe<Insights_Page_View_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_page_view_events". */
export type Insights_Page_View_Events_Order_By = {
  domain?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: insights_page_view_events */
export type Insights_Page_View_Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insights_page_view_events" */
export enum Insights_Page_View_Events_Select_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "insights_page_view_events" */
export type Insights_Page_View_Events_Set_Input = {
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "insights_page_view_events" */
export type Insights_Page_View_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Page_View_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Page_View_Events_Stream_Cursor_Value_Input = {
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "insights_page_view_events" */
export enum Insights_Page_View_Events_Update_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

export type Insights_Page_View_Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Page_View_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Page_View_Events_Bool_Exp;
};

/** columns and relationships of "insights_sessions" */
export type Insights_Sessions = {
  __typename?: 'insights_sessions';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  device: Insights_Devices;
  id: Scalars['uuid'];
  insights_device_id: Scalars['uuid'];
  insights_visitor_id: Scalars['uuid'];
  /** An object relationship */
  visitor?: Maybe<Insights_Visitors>;
};

/** aggregated selection of "insights_sessions" */
export type Insights_Sessions_Aggregate = {
  __typename?: 'insights_sessions_aggregate';
  aggregate?: Maybe<Insights_Sessions_Aggregate_Fields>;
  nodes: Array<Insights_Sessions>;
};

/** aggregate fields of "insights_sessions" */
export type Insights_Sessions_Aggregate_Fields = {
  __typename?: 'insights_sessions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insights_Sessions_Max_Fields>;
  min?: Maybe<Insights_Sessions_Min_Fields>;
};


/** aggregate fields of "insights_sessions" */
export type Insights_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insights_sessions". All fields are combined with a logical 'AND'. */
export type Insights_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Insights_Sessions_Bool_Exp>>;
  _not?: Maybe<Insights_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Insights_Sessions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  device?: Maybe<Insights_Devices_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insights_device_id?: Maybe<Uuid_Comparison_Exp>;
  insights_visitor_id?: Maybe<Uuid_Comparison_Exp>;
  visitor?: Maybe<Insights_Visitors_Bool_Exp>;
};

/** unique or primary key constraints on table "insights_sessions" */
export enum Insights_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsightsSessionsPkey = 'insights_sessions_pkey'
}

/** input type for inserting data into table "insights_sessions" */
export type Insights_Sessions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  device?: Maybe<Insights_Devices_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  insights_device_id?: Maybe<Scalars['uuid']>;
  insights_visitor_id?: Maybe<Scalars['uuid']>;
  visitor?: Maybe<Insights_Visitors_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Insights_Sessions_Max_Fields = {
  __typename?: 'insights_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  insights_device_id?: Maybe<Scalars['uuid']>;
  insights_visitor_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Insights_Sessions_Min_Fields = {
  __typename?: 'insights_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  insights_device_id?: Maybe<Scalars['uuid']>;
  insights_visitor_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "insights_sessions" */
export type Insights_Sessions_Mutation_Response = {
  __typename?: 'insights_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Sessions>;
};

/** on_conflict condition type for table "insights_sessions" */
export type Insights_Sessions_On_Conflict = {
  constraint: Insights_Sessions_Constraint;
  update_columns?: Array<Insights_Sessions_Update_Column>;
  where?: Maybe<Insights_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_sessions". */
export type Insights_Sessions_Order_By = {
  created_at?: Maybe<Order_By>;
  device?: Maybe<Insights_Devices_Order_By>;
  id?: Maybe<Order_By>;
  insights_device_id?: Maybe<Order_By>;
  insights_visitor_id?: Maybe<Order_By>;
  visitor?: Maybe<Insights_Visitors_Order_By>;
};

/** primary key columns input for table: insights_sessions */
export type Insights_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insights_sessions" */
export enum Insights_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InsightsDeviceId = 'insights_device_id',
  /** column name */
  InsightsVisitorId = 'insights_visitor_id'
}

/** input type for updating data in table "insights_sessions" */
export type Insights_Sessions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  insights_device_id?: Maybe<Scalars['uuid']>;
  insights_visitor_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "insights_sessions" */
export type Insights_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Sessions_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  insights_device_id?: Maybe<Scalars['uuid']>;
  insights_visitor_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "insights_sessions" */
export enum Insights_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InsightsDeviceId = 'insights_device_id',
  /** column name */
  InsightsVisitorId = 'insights_visitor_id'
}

export type Insights_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Sessions_Bool_Exp;
};

/** columns and relationships of "insights_visitors" */
export type Insights_Visitors = {
  __typename?: 'insights_visitors';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  showroom?: Maybe<Showrooms>;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "insights_visitors" */
export type Insights_Visitors_Aggregate = {
  __typename?: 'insights_visitors_aggregate';
  aggregate?: Maybe<Insights_Visitors_Aggregate_Fields>;
  nodes: Array<Insights_Visitors>;
};

/** aggregate fields of "insights_visitors" */
export type Insights_Visitors_Aggregate_Fields = {
  __typename?: 'insights_visitors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insights_Visitors_Max_Fields>;
  min?: Maybe<Insights_Visitors_Min_Fields>;
};


/** aggregate fields of "insights_visitors" */
export type Insights_Visitors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insights_Visitors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insights_visitors". All fields are combined with a logical 'AND'. */
export type Insights_Visitors_Bool_Exp = {
  _and?: Maybe<Array<Insights_Visitors_Bool_Exp>>;
  _not?: Maybe<Insights_Visitors_Bool_Exp>;
  _or?: Maybe<Array<Insights_Visitors_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "insights_visitors" */
export enum Insights_Visitors_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsightsVisitorsPkey = 'insights_visitors_pkey'
}

/** input type for inserting data into table "insights_visitors" */
export type Insights_Visitors_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Insights_Visitors_Max_Fields = {
  __typename?: 'insights_visitors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Insights_Visitors_Min_Fields = {
  __typename?: 'insights_visitors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "insights_visitors" */
export type Insights_Visitors_Mutation_Response = {
  __typename?: 'insights_visitors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insights_Visitors>;
};

/** input type for inserting object relation for remote table "insights_visitors" */
export type Insights_Visitors_Obj_Rel_Insert_Input = {
  data: Insights_Visitors_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Insights_Visitors_On_Conflict>;
};

/** on_conflict condition type for table "insights_visitors" */
export type Insights_Visitors_On_Conflict = {
  constraint: Insights_Visitors_Constraint;
  update_columns?: Array<Insights_Visitors_Update_Column>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};

/** Ordering options when selecting data from "insights_visitors". */
export type Insights_Visitors_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: insights_visitors */
export type Insights_Visitors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insights_visitors" */
export enum Insights_Visitors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "insights_visitors" */
export type Insights_Visitors_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "insights_visitors" */
export type Insights_Visitors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insights_Visitors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insights_Visitors_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "insights_visitors" */
export enum Insights_Visitors_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Insights_Visitors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Insights_Visitors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insights_Visitors_Bool_Exp;
};

/** columns and relationships of "interests" */
export type Interests = {
  __typename?: 'interests';
  code: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom?: Maybe<Showrooms>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  user_interests: Array<User_Interests>;
  /** An aggregate relationship */
  user_interests_aggregate: User_Interests_Aggregate;
};


/** columns and relationships of "interests" */
export type InterestsUser_InterestsArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


/** columns and relationships of "interests" */
export type InterestsUser_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};

/** aggregated selection of "interests" */
export type Interests_Aggregate = {
  __typename?: 'interests_aggregate';
  aggregate?: Maybe<Interests_Aggregate_Fields>;
  nodes: Array<Interests>;
};

export type Interests_Aggregate_Bool_Exp = {
  count?: Maybe<Interests_Aggregate_Bool_Exp_Count>;
};

export type Interests_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Interests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interests" */
export type Interests_Aggregate_Fields = {
  __typename?: 'interests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Interests_Max_Fields>;
  min?: Maybe<Interests_Min_Fields>;
};


/** aggregate fields of "interests" */
export type Interests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interests" */
export type Interests_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Interests_Max_Order_By>;
  min?: Maybe<Interests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "interests" */
export type Interests_Arr_Rel_Insert_Input = {
  data: Array<Interests_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Interests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "interests". All fields are combined with a logical 'AND'. */
export type Interests_Bool_Exp = {
  _and?: Maybe<Array<Interests_Bool_Exp>>;
  _not?: Maybe<Interests_Bool_Exp>;
  _or?: Maybe<Array<Interests_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  group?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  user_interests?: Maybe<User_Interests_Bool_Exp>;
  user_interests_aggregate?: Maybe<User_Interests_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "interests" */
export enum Interests_Constraint {
  /** unique or primary key constraint on columns "code" */
  InterestsPkey = 'interests_pkey'
}

/** input type for inserting data into table "interests" */
export type Interests_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  user_interests?: Maybe<User_Interests_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Interests_Max_Fields = {
  __typename?: 'interests_max_fields';
  code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "interests" */
export type Interests_Max_Order_By = {
  code?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Interests_Min_Fields = {
  __typename?: 'interests_min_fields';
  code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "interests" */
export type Interests_Min_Order_By = {
  code?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "interests" */
export type Interests_Mutation_Response = {
  __typename?: 'interests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interests>;
};

/** input type for inserting object relation for remote table "interests" */
export type Interests_Obj_Rel_Insert_Input = {
  data: Interests_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Interests_On_Conflict>;
};

/** on_conflict condition type for table "interests" */
export type Interests_On_Conflict = {
  constraint: Interests_Constraint;
  update_columns?: Array<Interests_Update_Column>;
  where?: Maybe<Interests_Bool_Exp>;
};

/** Ordering options when selecting data from "interests". */
export type Interests_Order_By = {
  code?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  user_interests_aggregate?: Maybe<User_Interests_Aggregate_Order_By>;
};

/** primary key columns input for table: interests */
export type Interests_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "interests" */
export enum Interests_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Group = 'group',
  /** column name */
  Label = 'label',
  /** column name */
  ShowroomId = 'showroom_id'
}

/** input type for updating data in table "interests" */
export type Interests_Set_Input = {
  code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "interests" */
export type Interests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interests_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "interests" */
export enum Interests_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Group = 'group',
  /** column name */
  Label = 'label',
  /** column name */
  ShowroomId = 'showroom_id'
}

export type Interests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Interests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interests_Bool_Exp;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "known_vehicles" */
export type Known_Vehicles = {
  __typename?: 'known_vehicles';
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  air_pollution_standard?: Maybe<Scalars['String']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  available_in_au_from?: Maybe<Scalars['date']>;
  available_in_au_now: Scalars['Boolean'];
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  /** An array relationship */
  calculator_submissions: Array<Calculator_Submission>;
  /** An aggregate relationship */
  calculator_submissions_aggregate: Calculator_Submission_Aggregate;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  charging_socket_type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country_of_manufacture?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Scalars['String']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  roof_rails: Scalars['Boolean'];
  seats?: Maybe<Scalars['numeric']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  useable_battery?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "known_vehicles" */
export type Known_VehiclesCalculator_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "known_vehicles" */
export type Known_VehiclesCalculator_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};

/** aggregated selection of "known_vehicles" */
export type Known_Vehicles_Aggregate = {
  __typename?: 'known_vehicles_aggregate';
  aggregate?: Maybe<Known_Vehicles_Aggregate_Fields>;
  nodes: Array<Known_Vehicles>;
};

/** aggregate fields of "known_vehicles" */
export type Known_Vehicles_Aggregate_Fields = {
  __typename?: 'known_vehicles_aggregate_fields';
  avg?: Maybe<Known_Vehicles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Known_Vehicles_Max_Fields>;
  min?: Maybe<Known_Vehicles_Min_Fields>;
  stddev?: Maybe<Known_Vehicles_Stddev_Fields>;
  stddev_pop?: Maybe<Known_Vehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Known_Vehicles_Stddev_Samp_Fields>;
  sum?: Maybe<Known_Vehicles_Sum_Fields>;
  var_pop?: Maybe<Known_Vehicles_Var_Pop_Fields>;
  var_samp?: Maybe<Known_Vehicles_Var_Samp_Fields>;
  variance?: Maybe<Known_Vehicles_Variance_Fields>;
};


/** aggregate fields of "known_vehicles" */
export type Known_Vehicles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Known_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Known_Vehicles_Avg_Fields = {
  __typename?: 'known_vehicles_avg_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "known_vehicles". All fields are combined with a logical 'AND'. */
export type Known_Vehicles_Bool_Exp = {
  _and?: Maybe<Array<Known_Vehicles_Bool_Exp>>;
  _not?: Maybe<Known_Vehicles_Bool_Exp>;
  _or?: Maybe<Array<Known_Vehicles_Bool_Exp>>;
  acceleration_0_100?: Maybe<Numeric_Comparison_Exp>;
  air_pollution_standard?: Maybe<String_Comparison_Exp>;
  ancp_safety_rating?: Maybe<Numeric_Comparison_Exp>;
  annual_emissions_co2_tonnes?: Maybe<Numeric_Comparison_Exp>;
  annual_fuel_cost_dollars?: Maybe<Numeric_Comparison_Exp>;
  available_in_au_from?: Maybe<Date_Comparison_Exp>;
  available_in_au_now?: Maybe<Boolean_Comparison_Exp>;
  badge?: Maybe<String_Comparison_Exp>;
  body_type?: Maybe<String_Comparison_Exp>;
  calculator_submissions?: Maybe<Calculator_Submission_Bool_Exp>;
  calculator_submissions_aggregate?: Maybe<Calculator_Submission_Aggregate_Bool_Exp>;
  cargo_capacity?: Maybe<Numeric_Comparison_Exp>;
  charge_port_ac?: Maybe<String_Comparison_Exp>;
  charge_port_dc?: Maybe<String_Comparison_Exp>;
  charge_port_location?: Maybe<String_Comparison_Exp>;
  charge_power_ac?: Maybe<Numeric_Comparison_Exp>;
  charge_power_dc?: Maybe<Numeric_Comparison_Exp>;
  charge_speed_ac?: Maybe<Numeric_Comparison_Exp>;
  charge_speed_dc?: Maybe<Numeric_Comparison_Exp>;
  charge_time_ac?: Maybe<Numeric_Comparison_Exp>;
  charge_time_dc?: Maybe<Numeric_Comparison_Exp>;
  charging_socket_type?: Maybe<String_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  country_of_manufacture?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  doors?: Maybe<Numeric_Comparison_Exp>;
  drive_away_price?: Maybe<Numeric_Comparison_Exp>;
  electric_range_km?: Maybe<Numeric_Comparison_Exp>;
  emissions_co2_grams_per_km?: Maybe<Numeric_Comparison_Exp>;
  energy_consumption_wh_per_100km?: Maybe<Numeric_Comparison_Exp>;
  engine?: Maybe<String_Comparison_Exp>;
  fuel_consumption_litres_per_100km?: Maybe<Numeric_Comparison_Exp>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  production_year?: Maybe<String_Comparison_Exp>;
  range?: Maybe<Numeric_Comparison_Exp>;
  release_year?: Maybe<Numeric_Comparison_Exp>;
  roof_load?: Maybe<Numeric_Comparison_Exp>;
  roof_rails?: Maybe<Boolean_Comparison_Exp>;
  seats?: Maybe<Numeric_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  top_speed?: Maybe<Numeric_Comparison_Exp>;
  tow_weight_braked?: Maybe<Numeric_Comparison_Exp>;
  tow_weight_unbraked?: Maybe<Numeric_Comparison_Exp>;
  transmission?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  useable_battery?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "known_vehicles" */
export enum Known_Vehicles_Constraint {
  /** unique or primary key constraint on columns "code" */
  KnownVehiclesCodeKey = 'known_vehicles_code_key',
  /** unique or primary key constraint on columns "id" */
  VehiclePkey = 'vehicle_pkey',
  /** unique or primary key constraint on columns "source", "source_id" */
  VehicleSourceIdSourceKey = 'vehicle_source_id_source_key'
}

/** input type for incrementing numeric columns in table "known_vehicles" */
export type Known_Vehicles_Inc_Input = {
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "known_vehicles" */
export type Known_Vehicles_Insert_Input = {
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  air_pollution_standard?: Maybe<Scalars['String']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  available_in_au_from?: Maybe<Scalars['date']>;
  available_in_au_now?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculator_submissions?: Maybe<Calculator_Submission_Arr_Rel_Insert_Input>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  charging_socket_type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country_of_manufacture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Scalars['String']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  roof_rails?: Maybe<Scalars['Boolean']>;
  seats?: Maybe<Scalars['numeric']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Known_Vehicles_Max_Fields = {
  __typename?: 'known_vehicles_max_fields';
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  air_pollution_standard?: Maybe<Scalars['String']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  available_in_au_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  charging_socket_type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country_of_manufacture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Scalars['String']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Known_Vehicles_Min_Fields = {
  __typename?: 'known_vehicles_min_fields';
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  air_pollution_standard?: Maybe<Scalars['String']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  available_in_au_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  charging_socket_type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country_of_manufacture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Scalars['String']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "known_vehicles" */
export type Known_Vehicles_Mutation_Response = {
  __typename?: 'known_vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Known_Vehicles>;
};

/** input type for inserting object relation for remote table "known_vehicles" */
export type Known_Vehicles_Obj_Rel_Insert_Input = {
  data: Known_Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Known_Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "known_vehicles" */
export type Known_Vehicles_On_Conflict = {
  constraint: Known_Vehicles_Constraint;
  update_columns?: Array<Known_Vehicles_Update_Column>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "known_vehicles". */
export type Known_Vehicles_Order_By = {
  acceleration_0_100?: Maybe<Order_By>;
  air_pollution_standard?: Maybe<Order_By>;
  ancp_safety_rating?: Maybe<Order_By>;
  annual_emissions_co2_tonnes?: Maybe<Order_By>;
  annual_fuel_cost_dollars?: Maybe<Order_By>;
  available_in_au_from?: Maybe<Order_By>;
  available_in_au_now?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  calculator_submissions_aggregate?: Maybe<Calculator_Submission_Aggregate_Order_By>;
  cargo_capacity?: Maybe<Order_By>;
  charge_port_ac?: Maybe<Order_By>;
  charge_port_dc?: Maybe<Order_By>;
  charge_port_location?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  charge_time_ac?: Maybe<Order_By>;
  charge_time_dc?: Maybe<Order_By>;
  charging_socket_type?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  country_of_manufacture?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  drive_away_price?: Maybe<Order_By>;
  electric_range_km?: Maybe<Order_By>;
  emissions_co2_grams_per_km?: Maybe<Order_By>;
  energy_consumption_wh_per_100km?: Maybe<Order_By>;
  engine?: Maybe<Order_By>;
  fuel_consumption_litres_per_100km?: Maybe<Order_By>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  release_year?: Maybe<Order_By>;
  roof_load?: Maybe<Order_By>;
  roof_rails?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  tow_weight_braked?: Maybe<Order_By>;
  tow_weight_unbraked?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  useable_battery?: Maybe<Order_By>;
};

/** primary key columns input for table: known_vehicles */
export type Known_Vehicles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "known_vehicles" */
export enum Known_Vehicles_Select_Column {
  /** column name */
  Acceleration_0_100 = 'acceleration_0_100',
  /** column name */
  AirPollutionStandard = 'air_pollution_standard',
  /** column name */
  AncpSafetyRating = 'ancp_safety_rating',
  /** column name */
  AnnualEmissionsCo2Tonnes = 'annual_emissions_co2_tonnes',
  /** column name */
  AnnualFuelCostDollars = 'annual_fuel_cost_dollars',
  /** column name */
  AvailableInAuFrom = 'available_in_au_from',
  /** column name */
  AvailableInAuNow = 'available_in_au_now',
  /** column name */
  Badge = 'badge',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  CargoCapacity = 'cargo_capacity',
  /** column name */
  ChargePortAc = 'charge_port_ac',
  /** column name */
  ChargePortDc = 'charge_port_dc',
  /** column name */
  ChargePortLocation = 'charge_port_location',
  /** column name */
  ChargePowerAc = 'charge_power_ac',
  /** column name */
  ChargePowerDc = 'charge_power_dc',
  /** column name */
  ChargeSpeedAc = 'charge_speed_ac',
  /** column name */
  ChargeSpeedDc = 'charge_speed_dc',
  /** column name */
  ChargeTimeAc = 'charge_time_ac',
  /** column name */
  ChargeTimeDc = 'charge_time_dc',
  /** column name */
  ChargingSocketType = 'charging_socket_type',
  /** column name */
  Code = 'code',
  /** column name */
  CountryOfManufacture = 'country_of_manufacture',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Doors = 'doors',
  /** column name */
  DriveAwayPrice = 'drive_away_price',
  /** column name */
  ElectricRangeKm = 'electric_range_km',
  /** column name */
  EmissionsCo2GramsPerKm = 'emissions_co2_grams_per_km',
  /** column name */
  EnergyConsumptionWhPer_100km = 'energy_consumption_wh_per_100km',
  /** column name */
  Engine = 'engine',
  /** column name */
  FuelConsumptionLitresPer_100km = 'fuel_consumption_litres_per_100km',
  /** column name */
  FuelLifecycleCo2GramsPerKm = 'fuel_lifecycle_co2_grams_per_km',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Range = 'range',
  /** column name */
  ReleaseYear = 'release_year',
  /** column name */
  RoofLoad = 'roof_load',
  /** column name */
  RoofRails = 'roof_rails',
  /** column name */
  Seats = 'seats',
  /** column name */
  Series = 'series',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  TopSpeed = 'top_speed',
  /** column name */
  TowWeightBraked = 'tow_weight_braked',
  /** column name */
  TowWeightUnbraked = 'tow_weight_unbraked',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UseableBattery = 'useable_battery'
}

/** input type for updating data in table "known_vehicles" */
export type Known_Vehicles_Set_Input = {
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  air_pollution_standard?: Maybe<Scalars['String']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  available_in_au_from?: Maybe<Scalars['date']>;
  available_in_au_now?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  charging_socket_type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country_of_manufacture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Scalars['String']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  roof_rails?: Maybe<Scalars['Boolean']>;
  seats?: Maybe<Scalars['numeric']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Known_Vehicles_Stddev_Fields = {
  __typename?: 'known_vehicles_stddev_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Known_Vehicles_Stddev_Pop_Fields = {
  __typename?: 'known_vehicles_stddev_pop_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Known_Vehicles_Stddev_Samp_Fields = {
  __typename?: 'known_vehicles_stddev_samp_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "known_vehicles" */
export type Known_Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Known_Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Known_Vehicles_Stream_Cursor_Value_Input = {
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  air_pollution_standard?: Maybe<Scalars['String']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  available_in_au_from?: Maybe<Scalars['date']>;
  available_in_au_now?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  charging_socket_type?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country_of_manufacture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  engine?: Maybe<Scalars['String']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  roof_rails?: Maybe<Scalars['Boolean']>;
  seats?: Maybe<Scalars['numeric']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Known_Vehicles_Sum_Fields = {
  __typename?: 'known_vehicles_sum_fields';
  acceleration_0_100?: Maybe<Scalars['numeric']>;
  ancp_safety_rating?: Maybe<Scalars['numeric']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['numeric']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['numeric']>;
  cargo_capacity?: Maybe<Scalars['numeric']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  charge_time_ac?: Maybe<Scalars['numeric']>;
  charge_time_dc?: Maybe<Scalars['numeric']>;
  doors?: Maybe<Scalars['numeric']>;
  drive_away_price?: Maybe<Scalars['numeric']>;
  electric_range_km?: Maybe<Scalars['numeric']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['numeric']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['numeric']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  release_year?: Maybe<Scalars['numeric']>;
  roof_load?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  top_speed?: Maybe<Scalars['numeric']>;
  tow_weight_braked?: Maybe<Scalars['numeric']>;
  tow_weight_unbraked?: Maybe<Scalars['numeric']>;
  useable_battery?: Maybe<Scalars['numeric']>;
};

/** update columns of table "known_vehicles" */
export enum Known_Vehicles_Update_Column {
  /** column name */
  Acceleration_0_100 = 'acceleration_0_100',
  /** column name */
  AirPollutionStandard = 'air_pollution_standard',
  /** column name */
  AncpSafetyRating = 'ancp_safety_rating',
  /** column name */
  AnnualEmissionsCo2Tonnes = 'annual_emissions_co2_tonnes',
  /** column name */
  AnnualFuelCostDollars = 'annual_fuel_cost_dollars',
  /** column name */
  AvailableInAuFrom = 'available_in_au_from',
  /** column name */
  AvailableInAuNow = 'available_in_au_now',
  /** column name */
  Badge = 'badge',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  CargoCapacity = 'cargo_capacity',
  /** column name */
  ChargePortAc = 'charge_port_ac',
  /** column name */
  ChargePortDc = 'charge_port_dc',
  /** column name */
  ChargePortLocation = 'charge_port_location',
  /** column name */
  ChargePowerAc = 'charge_power_ac',
  /** column name */
  ChargePowerDc = 'charge_power_dc',
  /** column name */
  ChargeSpeedAc = 'charge_speed_ac',
  /** column name */
  ChargeSpeedDc = 'charge_speed_dc',
  /** column name */
  ChargeTimeAc = 'charge_time_ac',
  /** column name */
  ChargeTimeDc = 'charge_time_dc',
  /** column name */
  ChargingSocketType = 'charging_socket_type',
  /** column name */
  Code = 'code',
  /** column name */
  CountryOfManufacture = 'country_of_manufacture',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Doors = 'doors',
  /** column name */
  DriveAwayPrice = 'drive_away_price',
  /** column name */
  ElectricRangeKm = 'electric_range_km',
  /** column name */
  EmissionsCo2GramsPerKm = 'emissions_co2_grams_per_km',
  /** column name */
  EnergyConsumptionWhPer_100km = 'energy_consumption_wh_per_100km',
  /** column name */
  Engine = 'engine',
  /** column name */
  FuelConsumptionLitresPer_100km = 'fuel_consumption_litres_per_100km',
  /** column name */
  FuelLifecycleCo2GramsPerKm = 'fuel_lifecycle_co2_grams_per_km',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Range = 'range',
  /** column name */
  ReleaseYear = 'release_year',
  /** column name */
  RoofLoad = 'roof_load',
  /** column name */
  RoofRails = 'roof_rails',
  /** column name */
  Seats = 'seats',
  /** column name */
  Series = 'series',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  TopSpeed = 'top_speed',
  /** column name */
  TowWeightBraked = 'tow_weight_braked',
  /** column name */
  TowWeightUnbraked = 'tow_weight_unbraked',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UseableBattery = 'useable_battery'
}

export type Known_Vehicles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Known_Vehicles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Known_Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Known_Vehicles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Known_Vehicles_Var_Pop_Fields = {
  __typename?: 'known_vehicles_var_pop_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Known_Vehicles_Var_Samp_Fields = {
  __typename?: 'known_vehicles_var_samp_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Known_Vehicles_Variance_Fields = {
  __typename?: 'known_vehicles_variance_fields';
  acceleration_0_100?: Maybe<Scalars['Float']>;
  ancp_safety_rating?: Maybe<Scalars['Float']>;
  annual_emissions_co2_tonnes?: Maybe<Scalars['Float']>;
  annual_fuel_cost_dollars?: Maybe<Scalars['Float']>;
  cargo_capacity?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  charge_time_ac?: Maybe<Scalars['Float']>;
  charge_time_dc?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  drive_away_price?: Maybe<Scalars['Float']>;
  electric_range_km?: Maybe<Scalars['Float']>;
  emissions_co2_grams_per_km?: Maybe<Scalars['Float']>;
  energy_consumption_wh_per_100km?: Maybe<Scalars['Float']>;
  fuel_consumption_litres_per_100km?: Maybe<Scalars['Float']>;
  fuel_lifecycle_co2_grams_per_km?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  release_year?: Maybe<Scalars['Float']>;
  roof_load?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  tow_weight_braked?: Maybe<Scalars['Float']>;
  tow_weight_unbraked?: Maybe<Scalars['Float']>;
  useable_battery?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "locales" */
export type Locales = {
  __typename?: 'locales';
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code: Scalars['String'];
  /** The name of the locale in the relevant language. */
  local_name: Scalars['String'];
  /** An array relationship */
  showroom_locales: Array<Showroom_Locales>;
  /** An aggregate relationship */
  showroom_locales_aggregate: Showroom_Locales_Aggregate;
  /** An array relationship */
  showrooms: Array<Showrooms>;
  /** An aggregate relationship */
  showrooms_aggregate: Showrooms_Aggregate;
};


/** columns and relationships of "locales" */
export type LocalesShowroom_LocalesArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


/** columns and relationships of "locales" */
export type LocalesShowroom_Locales_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


/** columns and relationships of "locales" */
export type LocalesShowroomsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


/** columns and relationships of "locales" */
export type LocalesShowrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};

/** aggregated selection of "locales" */
export type Locales_Aggregate = {
  __typename?: 'locales_aggregate';
  aggregate?: Maybe<Locales_Aggregate_Fields>;
  nodes: Array<Locales>;
};

/** aggregate fields of "locales" */
export type Locales_Aggregate_Fields = {
  __typename?: 'locales_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Locales_Max_Fields>;
  min?: Maybe<Locales_Min_Fields>;
};


/** aggregate fields of "locales" */
export type Locales_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locales_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "locales". All fields are combined with a logical 'AND'. */
export type Locales_Bool_Exp = {
  _and?: Maybe<Array<Locales_Bool_Exp>>;
  _not?: Maybe<Locales_Bool_Exp>;
  _or?: Maybe<Array<Locales_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  local_name?: Maybe<String_Comparison_Exp>;
  showroom_locales?: Maybe<Showroom_Locales_Bool_Exp>;
  showroom_locales_aggregate?: Maybe<Showroom_Locales_Aggregate_Bool_Exp>;
  showrooms?: Maybe<Showrooms_Bool_Exp>;
  showrooms_aggregate?: Maybe<Showrooms_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "locales" */
export enum Locales_Constraint {
  /** unique or primary key constraint on columns "code" */
  LocalesPkey = 'locales_pkey'
}

/** input type for inserting data into table "locales" */
export type Locales_Insert_Input = {
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code?: Maybe<Scalars['String']>;
  /** The name of the locale in the relevant language. */
  local_name?: Maybe<Scalars['String']>;
  showroom_locales?: Maybe<Showroom_Locales_Arr_Rel_Insert_Input>;
  showrooms?: Maybe<Showrooms_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Locales_Max_Fields = {
  __typename?: 'locales_max_fields';
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code?: Maybe<Scalars['String']>;
  /** The name of the locale in the relevant language. */
  local_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Locales_Min_Fields = {
  __typename?: 'locales_min_fields';
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code?: Maybe<Scalars['String']>;
  /** The name of the locale in the relevant language. */
  local_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locales" */
export type Locales_Mutation_Response = {
  __typename?: 'locales_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locales>;
};

/** input type for inserting object relation for remote table "locales" */
export type Locales_Obj_Rel_Insert_Input = {
  data: Locales_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Locales_On_Conflict>;
};

/** on_conflict condition type for table "locales" */
export type Locales_On_Conflict = {
  constraint: Locales_Constraint;
  update_columns?: Array<Locales_Update_Column>;
  where?: Maybe<Locales_Bool_Exp>;
};

/** Ordering options when selecting data from "locales". */
export type Locales_Order_By = {
  code?: Maybe<Order_By>;
  local_name?: Maybe<Order_By>;
  showroom_locales_aggregate?: Maybe<Showroom_Locales_Aggregate_Order_By>;
  showrooms_aggregate?: Maybe<Showrooms_Aggregate_Order_By>;
};

/** primary key columns input for table: locales */
export type Locales_Pk_Columns_Input = {
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code: Scalars['String'];
};

/** select columns of table "locales" */
export enum Locales_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  LocalName = 'local_name'
}

/** input type for updating data in table "locales" */
export type Locales_Set_Input = {
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code?: Maybe<Scalars['String']>;
  /** The name of the locale in the relevant language. */
  local_name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "locales" */
export type Locales_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locales_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locales_Stream_Cursor_Value_Input = {
  /** the desired locale in the format 'en-US' (ISO 639-1 language code 'en' and ISO 3166-1 alpha-2 country code 'US') */
  code?: Maybe<Scalars['String']>;
  /** The name of the locale in the relevant language. */
  local_name?: Maybe<Scalars['String']>;
};

/** update columns of table "locales" */
export enum Locales_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  LocalName = 'local_name'
}

export type Locales_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Locales_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locales_Bool_Exp;
};

/** columns and relationships of "location_service_types" */
export type Location_Service_Types = {
  __typename?: 'location_service_types';
  is_active: Scalars['Boolean'];
  is_default: Scalars['Boolean'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  service_type: Service_Types;
  service_type_id: Scalars['uuid'];
};

/** aggregated selection of "location_service_types" */
export type Location_Service_Types_Aggregate = {
  __typename?: 'location_service_types_aggregate';
  aggregate?: Maybe<Location_Service_Types_Aggregate_Fields>;
  nodes: Array<Location_Service_Types>;
};

export type Location_Service_Types_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Location_Service_Types_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Location_Service_Types_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Location_Service_Types_Aggregate_Bool_Exp_Count>;
};

export type Location_Service_Types_Aggregate_Bool_Exp_Bool_And = {
  arguments: Location_Service_Types_Select_Column_Location_Service_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Service_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Service_Types_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Location_Service_Types_Select_Column_Location_Service_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Service_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Service_Types_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Location_Service_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Service_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "location_service_types" */
export type Location_Service_Types_Aggregate_Fields = {
  __typename?: 'location_service_types_aggregate_fields';
  avg?: Maybe<Location_Service_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Location_Service_Types_Max_Fields>;
  min?: Maybe<Location_Service_Types_Min_Fields>;
  stddev?: Maybe<Location_Service_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Location_Service_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Location_Service_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Location_Service_Types_Sum_Fields>;
  var_pop?: Maybe<Location_Service_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Location_Service_Types_Var_Samp_Fields>;
  variance?: Maybe<Location_Service_Types_Variance_Fields>;
};


/** aggregate fields of "location_service_types" */
export type Location_Service_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Location_Service_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "location_service_types" */
export type Location_Service_Types_Aggregate_Order_By = {
  avg?: Maybe<Location_Service_Types_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Location_Service_Types_Max_Order_By>;
  min?: Maybe<Location_Service_Types_Min_Order_By>;
  stddev?: Maybe<Location_Service_Types_Stddev_Order_By>;
  stddev_pop?: Maybe<Location_Service_Types_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Location_Service_Types_Stddev_Samp_Order_By>;
  sum?: Maybe<Location_Service_Types_Sum_Order_By>;
  var_pop?: Maybe<Location_Service_Types_Var_Pop_Order_By>;
  var_samp?: Maybe<Location_Service_Types_Var_Samp_Order_By>;
  variance?: Maybe<Location_Service_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "location_service_types" */
export type Location_Service_Types_Arr_Rel_Insert_Input = {
  data: Array<Location_Service_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Location_Service_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Location_Service_Types_Avg_Fields = {
  __typename?: 'location_service_types_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "location_service_types" */
export type Location_Service_Types_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "location_service_types". All fields are combined with a logical 'AND'. */
export type Location_Service_Types_Bool_Exp = {
  _and?: Maybe<Array<Location_Service_Types_Bool_Exp>>;
  _not?: Maybe<Location_Service_Types_Bool_Exp>;
  _or?: Maybe<Array<Location_Service_Types_Bool_Exp>>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  is_default?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  service_type?: Maybe<Service_Types_Bool_Exp>;
  service_type_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "location_service_types" */
export enum Location_Service_Types_Constraint {
  /** unique or primary key constraint on columns "service_type_id", "location_id" */
  LocationServiceTypesPkey = 'location_service_types_pkey'
}

/** input type for incrementing numeric columns in table "location_service_types" */
export type Location_Service_Types_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "location_service_types" */
export type Location_Service_Types_Insert_Input = {
  is_active?: Maybe<Scalars['Boolean']>;
  is_default?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  service_type?: Maybe<Service_Types_Obj_Rel_Insert_Input>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Location_Service_Types_Max_Fields = {
  __typename?: 'location_service_types_max_fields';
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "location_service_types" */
export type Location_Service_Types_Max_Order_By = {
  location_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  service_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Location_Service_Types_Min_Fields = {
  __typename?: 'location_service_types_min_fields';
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "location_service_types" */
export type Location_Service_Types_Min_Order_By = {
  location_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  service_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "location_service_types" */
export type Location_Service_Types_Mutation_Response = {
  __typename?: 'location_service_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location_Service_Types>;
};

/** on_conflict condition type for table "location_service_types" */
export type Location_Service_Types_On_Conflict = {
  constraint: Location_Service_Types_Constraint;
  update_columns?: Array<Location_Service_Types_Update_Column>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "location_service_types". */
export type Location_Service_Types_Order_By = {
  is_active?: Maybe<Order_By>;
  is_default?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  service_type?: Maybe<Service_Types_Order_By>;
  service_type_id?: Maybe<Order_By>;
};

/** primary key columns input for table: location_service_types */
export type Location_Service_Types_Pk_Columns_Input = {
  location_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};

/** select columns of table "location_service_types" */
export enum Location_Service_Types_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Order = 'order',
  /** column name */
  ServiceTypeId = 'service_type_id'
}

/** select "location_service_types_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location_service_types" */
export enum Location_Service_Types_Select_Column_Location_Service_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default'
}

/** select "location_service_types_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location_service_types" */
export enum Location_Service_Types_Select_Column_Location_Service_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default'
}

/** input type for updating data in table "location_service_types" */
export type Location_Service_Types_Set_Input = {
  is_active?: Maybe<Scalars['Boolean']>;
  is_default?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Location_Service_Types_Stddev_Fields = {
  __typename?: 'location_service_types_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "location_service_types" */
export type Location_Service_Types_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Location_Service_Types_Stddev_Pop_Fields = {
  __typename?: 'location_service_types_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "location_service_types" */
export type Location_Service_Types_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Location_Service_Types_Stddev_Samp_Fields = {
  __typename?: 'location_service_types_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "location_service_types" */
export type Location_Service_Types_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "location_service_types" */
export type Location_Service_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Service_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Service_Types_Stream_Cursor_Value_Input = {
  is_active?: Maybe<Scalars['Boolean']>;
  is_default?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Location_Service_Types_Sum_Fields = {
  __typename?: 'location_service_types_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "location_service_types" */
export type Location_Service_Types_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "location_service_types" */
export enum Location_Service_Types_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Order = 'order',
  /** column name */
  ServiceTypeId = 'service_type_id'
}

export type Location_Service_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Location_Service_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Location_Service_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Service_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Service_Types_Var_Pop_Fields = {
  __typename?: 'location_service_types_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "location_service_types" */
export type Location_Service_Types_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Location_Service_Types_Var_Samp_Fields = {
  __typename?: 'location_service_types_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "location_service_types" */
export type Location_Service_Types_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Location_Service_Types_Variance_Fields = {
  __typename?: 'location_service_types_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "location_service_types" */
export type Location_Service_Types_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** Scoping service booking transport types to a locations */
export type Location_Transport_Types = {
  __typename?: 'location_transport_types';
  is_active: Scalars['Boolean'];
  is_default: Scalars['Boolean'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  order: Scalars['Int'];
  /** An object relationship */
  transport_type: Transport_Types;
  transport_type_id: Scalars['uuid'];
};

/** aggregated selection of "location_transport_types" */
export type Location_Transport_Types_Aggregate = {
  __typename?: 'location_transport_types_aggregate';
  aggregate?: Maybe<Location_Transport_Types_Aggregate_Fields>;
  nodes: Array<Location_Transport_Types>;
};

export type Location_Transport_Types_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Location_Transport_Types_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Location_Transport_Types_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Location_Transport_Types_Aggregate_Bool_Exp_Count>;
};

export type Location_Transport_Types_Aggregate_Bool_Exp_Bool_And = {
  arguments: Location_Transport_Types_Select_Column_Location_Transport_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Transport_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Transport_Types_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Location_Transport_Types_Select_Column_Location_Transport_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Transport_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Transport_Types_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Location_Transport_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "location_transport_types" */
export type Location_Transport_Types_Aggregate_Fields = {
  __typename?: 'location_transport_types_aggregate_fields';
  avg?: Maybe<Location_Transport_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Location_Transport_Types_Max_Fields>;
  min?: Maybe<Location_Transport_Types_Min_Fields>;
  stddev?: Maybe<Location_Transport_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Location_Transport_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Location_Transport_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Location_Transport_Types_Sum_Fields>;
  var_pop?: Maybe<Location_Transport_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Location_Transport_Types_Var_Samp_Fields>;
  variance?: Maybe<Location_Transport_Types_Variance_Fields>;
};


/** aggregate fields of "location_transport_types" */
export type Location_Transport_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "location_transport_types" */
export type Location_Transport_Types_Aggregate_Order_By = {
  avg?: Maybe<Location_Transport_Types_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Location_Transport_Types_Max_Order_By>;
  min?: Maybe<Location_Transport_Types_Min_Order_By>;
  stddev?: Maybe<Location_Transport_Types_Stddev_Order_By>;
  stddev_pop?: Maybe<Location_Transport_Types_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Location_Transport_Types_Stddev_Samp_Order_By>;
  sum?: Maybe<Location_Transport_Types_Sum_Order_By>;
  var_pop?: Maybe<Location_Transport_Types_Var_Pop_Order_By>;
  var_samp?: Maybe<Location_Transport_Types_Var_Samp_Order_By>;
  variance?: Maybe<Location_Transport_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "location_transport_types" */
export type Location_Transport_Types_Arr_Rel_Insert_Input = {
  data: Array<Location_Transport_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Location_Transport_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Location_Transport_Types_Avg_Fields = {
  __typename?: 'location_transport_types_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "location_transport_types" */
export type Location_Transport_Types_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "location_transport_types". All fields are combined with a logical 'AND'. */
export type Location_Transport_Types_Bool_Exp = {
  _and?: Maybe<Array<Location_Transport_Types_Bool_Exp>>;
  _not?: Maybe<Location_Transport_Types_Bool_Exp>;
  _or?: Maybe<Array<Location_Transport_Types_Bool_Exp>>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  is_default?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  transport_type?: Maybe<Transport_Types_Bool_Exp>;
  transport_type_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "location_transport_types" */
export enum Location_Transport_Types_Constraint {
  /** unique or primary key constraint on columns "transport_type_id", "location_id" */
  LocationTransportTypesPkey = 'location_transport_types_pkey'
}

/** input type for incrementing numeric columns in table "location_transport_types" */
export type Location_Transport_Types_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "location_transport_types" */
export type Location_Transport_Types_Insert_Input = {
  is_active?: Maybe<Scalars['Boolean']>;
  is_default?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  transport_type?: Maybe<Transport_Types_Obj_Rel_Insert_Input>;
  transport_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Location_Transport_Types_Max_Fields = {
  __typename?: 'location_transport_types_max_fields';
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "location_transport_types" */
export type Location_Transport_Types_Max_Order_By = {
  location_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transport_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Location_Transport_Types_Min_Fields = {
  __typename?: 'location_transport_types_min_fields';
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "location_transport_types" */
export type Location_Transport_Types_Min_Order_By = {
  location_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transport_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "location_transport_types" */
export type Location_Transport_Types_Mutation_Response = {
  __typename?: 'location_transport_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location_Transport_Types>;
};

/** on_conflict condition type for table "location_transport_types" */
export type Location_Transport_Types_On_Conflict = {
  constraint: Location_Transport_Types_Constraint;
  update_columns?: Array<Location_Transport_Types_Update_Column>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "location_transport_types". */
export type Location_Transport_Types_Order_By = {
  is_active?: Maybe<Order_By>;
  is_default?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  transport_type?: Maybe<Transport_Types_Order_By>;
  transport_type_id?: Maybe<Order_By>;
};

/** primary key columns input for table: location_transport_types */
export type Location_Transport_Types_Pk_Columns_Input = {
  location_id: Scalars['uuid'];
  transport_type_id: Scalars['uuid'];
};

/** select columns of table "location_transport_types" */
export enum Location_Transport_Types_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Order = 'order',
  /** column name */
  TransportTypeId = 'transport_type_id'
}

/** select "location_transport_types_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location_transport_types" */
export enum Location_Transport_Types_Select_Column_Location_Transport_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default'
}

/** select "location_transport_types_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location_transport_types" */
export enum Location_Transport_Types_Select_Column_Location_Transport_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default'
}

/** input type for updating data in table "location_transport_types" */
export type Location_Transport_Types_Set_Input = {
  is_active?: Maybe<Scalars['Boolean']>;
  is_default?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Location_Transport_Types_Stddev_Fields = {
  __typename?: 'location_transport_types_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "location_transport_types" */
export type Location_Transport_Types_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Location_Transport_Types_Stddev_Pop_Fields = {
  __typename?: 'location_transport_types_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "location_transport_types" */
export type Location_Transport_Types_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Location_Transport_Types_Stddev_Samp_Fields = {
  __typename?: 'location_transport_types_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "location_transport_types" */
export type Location_Transport_Types_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "location_transport_types" */
export type Location_Transport_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Transport_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Transport_Types_Stream_Cursor_Value_Input = {
  is_active?: Maybe<Scalars['Boolean']>;
  is_default?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Location_Transport_Types_Sum_Fields = {
  __typename?: 'location_transport_types_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "location_transport_types" */
export type Location_Transport_Types_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "location_transport_types" */
export enum Location_Transport_Types_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Order = 'order',
  /** column name */
  TransportTypeId = 'transport_type_id'
}

export type Location_Transport_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Location_Transport_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Location_Transport_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Transport_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Transport_Types_Var_Pop_Fields = {
  __typename?: 'location_transport_types_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "location_transport_types" */
export type Location_Transport_Types_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Location_Transport_Types_Var_Samp_Fields = {
  __typename?: 'location_transport_types_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "location_transport_types" */
export type Location_Transport_Types_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Location_Transport_Types_Variance_Fields = {
  __typename?: 'location_transport_types_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "location_transport_types" */
export type Location_Transport_Types_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  address?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  custom_field_values: Array<Custom_Field_Values>;
  /** An aggregate relationship */
  custom_field_values_aggregate: Custom_Field_Values_Aggregate;
  /** An object relationship */
  default_owner?: Maybe<Users>;
  default_owner_id?: Maybe<Scalars['uuid']>;
  display_order?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  geography?: Maybe<Scalars['geography']>;
  id: Scalars['uuid'];
  /** Indicates whether this location is available for service-bookings */
  is_available_for_services: Scalars['Boolean'];
  is_available_for_test_drives: Scalars['Boolean'];
  is_dealership: Scalars['Boolean'];
  is_handover_enabled: Scalars['Boolean'];
  /** An array relationship */
  location_service_types: Array<Location_Service_Types>;
  /** An aggregate relationship */
  location_service_types_aggregate: Location_Service_Types_Aggregate;
  /** An array relationship */
  location_transport_types: Array<Location_Transport_Types>;
  /** An aggregate relationship */
  location_transport_types_aggregate: Location_Transport_Types_Aggregate;
  name: Scalars['String'];
  opening_hours?: Maybe<Scalars['jsonb']>;
  phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  service_bookings: Array<Service_Bookings>;
  /** An aggregate relationship */
  service_bookings_aggregate: Service_Bookings_Aggregate;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  stocked_vehicles: Array<Stocked_Vehicles>;
  /** An aggregate relationship */
  stocked_vehicles_aggregate: Stocked_Vehicles_Aggregate;
  /** An array relationship */
  test_drives: Array<Test_Drives>;
  /** An aggregate relationship */
  test_drives_aggregate: Test_Drives_Aggregate;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "locations" */
export type LocationsAddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "locations" */
export type LocationsAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsCustom_Field_ValuesArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsCustom_Field_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLocation_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLocation_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLocation_Transport_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLocation_Transport_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsOpening_HoursArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "locations" */
export type LocationsService_BookingsArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsService_Bookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsStocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsStocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsTest_DrivesArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsTest_Drives_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

export type Locations_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Locations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Locations_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Locations_Aggregate_Bool_Exp_Count>;
};

export type Locations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Locations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations" */
export type Locations_Aggregate_Order_By = {
  avg?: Maybe<Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Locations_Max_Order_By>;
  min?: Maybe<Locations_Min_Order_By>;
  stddev?: Maybe<Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Locations_Sum_Order_By>;
  var_pop?: Maybe<Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Locations_Var_Samp_Order_By>;
  variance?: Maybe<Locations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Locations_Append_Input = {
  address?: Maybe<Scalars['jsonb']>;
  opening_hours?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "locations" */
export type Locations_Arr_Rel_Insert_Input = {
  data: Array<Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locations" */
export type Locations_Avg_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  address?: Maybe<Jsonb_Comparison_Exp>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_field_values?: Maybe<Custom_Field_Values_Bool_Exp>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Bool_Exp>;
  default_owner?: Maybe<Users_Bool_Exp>;
  default_owner_id?: Maybe<Uuid_Comparison_Exp>;
  display_order?: Maybe<Numeric_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  geography?: Maybe<Geography_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_available_for_services?: Maybe<Boolean_Comparison_Exp>;
  is_available_for_test_drives?: Maybe<Boolean_Comparison_Exp>;
  is_dealership?: Maybe<Boolean_Comparison_Exp>;
  is_handover_enabled?: Maybe<Boolean_Comparison_Exp>;
  location_service_types?: Maybe<Location_Service_Types_Bool_Exp>;
  location_service_types_aggregate?: Maybe<Location_Service_Types_Aggregate_Bool_Exp>;
  location_transport_types?: Maybe<Location_Transport_Types_Bool_Exp>;
  location_transport_types_aggregate?: Maybe<Location_Transport_Types_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  opening_hours?: Maybe<Jsonb_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  service_bookings?: Maybe<Service_Bookings_Bool_Exp>;
  service_bookings_aggregate?: Maybe<Service_Bookings_Aggregate_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  source_name?: Maybe<String_Comparison_Exp>;
  stocked_vehicles?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicles_aggregate?: Maybe<Stocked_Vehicles_Aggregate_Bool_Exp>;
  test_drives?: Maybe<Test_Drives_Bool_Exp>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Bool_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  users_aggregate?: Maybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = 'locations_pkey',
  /** unique or primary key constraint on columns "source_id", "source_name", "showroom_id" */
  LocationsShowroomIdSourceNameSourceIdKey = 'locations_showroom_id_source_name_source_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Locations_Delete_At_Path_Input = {
  address?: Maybe<Array<Scalars['String']>>;
  opening_hours?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Locations_Delete_Elem_Input = {
  address?: Maybe<Scalars['Int']>;
  opening_hours?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Locations_Delete_Key_Input = {
  address?: Maybe<Scalars['String']>;
  opening_hours?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  display_order?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: Maybe<Scalars['jsonb']>;
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_field_values?: Maybe<Custom_Field_Values_Arr_Rel_Insert_Input>;
  default_owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  default_owner_id?: Maybe<Scalars['uuid']>;
  display_order?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  geography?: Maybe<Scalars['geography']>;
  id?: Maybe<Scalars['uuid']>;
  /** Indicates whether this location is available for service-bookings */
  is_available_for_services?: Maybe<Scalars['Boolean']>;
  is_available_for_test_drives?: Maybe<Scalars['Boolean']>;
  is_dealership?: Maybe<Scalars['Boolean']>;
  is_handover_enabled?: Maybe<Scalars['Boolean']>;
  location_service_types?: Maybe<Location_Service_Types_Arr_Rel_Insert_Input>;
  location_transport_types?: Maybe<Location_Transport_Types_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  opening_hours?: Maybe<Scalars['jsonb']>;
  phone?: Maybe<Scalars['String']>;
  service_bookings?: Maybe<Service_Bookings_Arr_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  stocked_vehicles?: Maybe<Stocked_Vehicles_Arr_Rel_Insert_Input>;
  test_drives?: Maybe<Test_Drives_Arr_Rel_Insert_Input>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_owner_id?: Maybe<Scalars['uuid']>;
  display_order?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locations" */
export type Locations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  default_owner_id?: Maybe<Order_By>;
  display_order?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_owner_id?: Maybe<Scalars['uuid']>;
  display_order?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locations" */
export type Locations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  default_owner_id?: Maybe<Order_By>;
  display_order?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: Maybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: Maybe<Order_By>;
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Order_By>;
  default_owner?: Maybe<Users_Order_By>;
  default_owner_id?: Maybe<Order_By>;
  display_order?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  geography?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_available_for_services?: Maybe<Order_By>;
  is_available_for_test_drives?: Maybe<Order_By>;
  is_dealership?: Maybe<Order_By>;
  is_handover_enabled?: Maybe<Order_By>;
  location_service_types_aggregate?: Maybe<Location_Service_Types_Aggregate_Order_By>;
  location_transport_types_aggregate?: Maybe<Location_Transport_Types_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  opening_hours?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  service_bookings_aggregate?: Maybe<Service_Bookings_Aggregate_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  stocked_vehicles_aggregate?: Maybe<Stocked_Vehicles_Aggregate_Order_By>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Locations_Prepend_Input = {
  address?: Maybe<Scalars['jsonb']>;
  opening_hours?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultOwnerId = 'default_owner_id',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Email = 'email',
  /** column name */
  Geography = 'geography',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableForServices = 'is_available_for_services',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsDealership = 'is_dealership',
  /** column name */
  IsHandoverEnabled = 'is_handover_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  OpeningHours = 'opening_hours',
  /** column name */
  Phone = 'phone',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "locations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "locations" */
export enum Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAvailableForServices = 'is_available_for_services',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsDealership = 'is_dealership',
  /** column name */
  IsHandoverEnabled = 'is_handover_enabled'
}

/** select "locations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "locations" */
export enum Locations_Select_Column_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAvailableForServices = 'is_available_for_services',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsDealership = 'is_dealership',
  /** column name */
  IsHandoverEnabled = 'is_handover_enabled'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_owner_id?: Maybe<Scalars['uuid']>;
  display_order?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  geography?: Maybe<Scalars['geography']>;
  id?: Maybe<Scalars['uuid']>;
  /** Indicates whether this location is available for service-bookings */
  is_available_for_services?: Maybe<Scalars['Boolean']>;
  is_available_for_test_drives?: Maybe<Scalars['Boolean']>;
  is_dealership?: Maybe<Scalars['Boolean']>;
  is_handover_enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  opening_hours?: Maybe<Scalars['jsonb']>;
  phone?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locations" */
export type Locations_Stddev_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locations" */
export type Locations_Stddev_Pop_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locations" */
export type Locations_Stddev_Samp_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_owner_id?: Maybe<Scalars['uuid']>;
  display_order?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  geography?: Maybe<Scalars['geography']>;
  id?: Maybe<Scalars['uuid']>;
  /** Indicates whether this location is available for service-bookings */
  is_available_for_services?: Maybe<Scalars['Boolean']>;
  is_available_for_test_drives?: Maybe<Scalars['Boolean']>;
  is_dealership?: Maybe<Scalars['Boolean']>;
  is_handover_enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  opening_hours?: Maybe<Scalars['jsonb']>;
  phone?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  /** A timezone from the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  display_order?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "locations" */
export type Locations_Sum_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultOwnerId = 'default_owner_id',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Email = 'email',
  /** column name */
  Geography = 'geography',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableForServices = 'is_available_for_services',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsDealership = 'is_dealership',
  /** column name */
  IsHandoverEnabled = 'is_handover_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  OpeningHours = 'opening_hours',
  /** column name */
  Phone = 'phone',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Locations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Locations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Locations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Locations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Locations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Locations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Locations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locations" */
export type Locations_Var_Pop_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locations" */
export type Locations_Var_Samp_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locations" */
export type Locations_Variance_Order_By = {
  display_order?: Maybe<Order_By>;
};

/** columns and relationships of "match_submissions" */
export type Match_Submissions = {
  __typename?: 'match_submissions';
  body_types: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  daily_driving_distance: Scalars['numeric'];
  id: Scalars['uuid'];
  interests: Scalars['jsonb'];
  price_range_lower: Scalars['numeric'];
  price_range_upper: Scalars['numeric'];
  seats: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "match_submissions" */
export type Match_SubmissionsBody_TypesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "match_submissions" */
export type Match_SubmissionsInterestsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "match_submissions" */
export type Match_Submissions_Aggregate = {
  __typename?: 'match_submissions_aggregate';
  aggregate?: Maybe<Match_Submissions_Aggregate_Fields>;
  nodes: Array<Match_Submissions>;
};

/** aggregate fields of "match_submissions" */
export type Match_Submissions_Aggregate_Fields = {
  __typename?: 'match_submissions_aggregate_fields';
  avg?: Maybe<Match_Submissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Match_Submissions_Max_Fields>;
  min?: Maybe<Match_Submissions_Min_Fields>;
  stddev?: Maybe<Match_Submissions_Stddev_Fields>;
  stddev_pop?: Maybe<Match_Submissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Match_Submissions_Stddev_Samp_Fields>;
  sum?: Maybe<Match_Submissions_Sum_Fields>;
  var_pop?: Maybe<Match_Submissions_Var_Pop_Fields>;
  var_samp?: Maybe<Match_Submissions_Var_Samp_Fields>;
  variance?: Maybe<Match_Submissions_Variance_Fields>;
};


/** aggregate fields of "match_submissions" */
export type Match_Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Match_Submissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Match_Submissions_Append_Input = {
  body_types?: Maybe<Scalars['jsonb']>;
  interests?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Match_Submissions_Avg_Fields = {
  __typename?: 'match_submissions_avg_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "match_submissions". All fields are combined with a logical 'AND'. */
export type Match_Submissions_Bool_Exp = {
  _and?: Maybe<Array<Match_Submissions_Bool_Exp>>;
  _not?: Maybe<Match_Submissions_Bool_Exp>;
  _or?: Maybe<Array<Match_Submissions_Bool_Exp>>;
  body_types?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  daily_driving_distance?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interests?: Maybe<Jsonb_Comparison_Exp>;
  price_range_lower?: Maybe<Numeric_Comparison_Exp>;
  price_range_upper?: Maybe<Numeric_Comparison_Exp>;
  seats?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "match_submissions" */
export enum Match_Submissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  MatchSubmissionsPkey = 'match_submissions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Match_Submissions_Delete_At_Path_Input = {
  body_types?: Maybe<Array<Scalars['String']>>;
  interests?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Match_Submissions_Delete_Elem_Input = {
  body_types?: Maybe<Scalars['Int']>;
  interests?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Match_Submissions_Delete_Key_Input = {
  body_types?: Maybe<Scalars['String']>;
  interests?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "match_submissions" */
export type Match_Submissions_Inc_Input = {
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "match_submissions" */
export type Match_Submissions_Insert_Input = {
  body_types?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  interests?: Maybe<Scalars['jsonb']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Match_Submissions_Max_Fields = {
  __typename?: 'match_submissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Match_Submissions_Min_Fields = {
  __typename?: 'match_submissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "match_submissions" */
export type Match_Submissions_Mutation_Response = {
  __typename?: 'match_submissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Match_Submissions>;
};

/** on_conflict condition type for table "match_submissions" */
export type Match_Submissions_On_Conflict = {
  constraint: Match_Submissions_Constraint;
  update_columns?: Array<Match_Submissions_Update_Column>;
  where?: Maybe<Match_Submissions_Bool_Exp>;
};

/** Ordering options when selecting data from "match_submissions". */
export type Match_Submissions_Order_By = {
  body_types?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  daily_driving_distance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interests?: Maybe<Order_By>;
  price_range_lower?: Maybe<Order_By>;
  price_range_upper?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: match_submissions */
export type Match_Submissions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Match_Submissions_Prepend_Input = {
  body_types?: Maybe<Scalars['jsonb']>;
  interests?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "match_submissions" */
export enum Match_Submissions_Select_Column {
  /** column name */
  BodyTypes = 'body_types',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyDrivingDistance = 'daily_driving_distance',
  /** column name */
  Id = 'id',
  /** column name */
  Interests = 'interests',
  /** column name */
  PriceRangeLower = 'price_range_lower',
  /** column name */
  PriceRangeUpper = 'price_range_upper',
  /** column name */
  Seats = 'seats',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "match_submissions" */
export type Match_Submissions_Set_Input = {
  body_types?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  interests?: Maybe<Scalars['jsonb']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Match_Submissions_Stddev_Fields = {
  __typename?: 'match_submissions_stddev_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Match_Submissions_Stddev_Pop_Fields = {
  __typename?: 'match_submissions_stddev_pop_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Match_Submissions_Stddev_Samp_Fields = {
  __typename?: 'match_submissions_stddev_samp_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "match_submissions" */
export type Match_Submissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Match_Submissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Match_Submissions_Stream_Cursor_Value_Input = {
  body_types?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  interests?: Maybe<Scalars['jsonb']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Match_Submissions_Sum_Fields = {
  __typename?: 'match_submissions_sum_fields';
  daily_driving_distance?: Maybe<Scalars['numeric']>;
  price_range_lower?: Maybe<Scalars['numeric']>;
  price_range_upper?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['numeric']>;
};

/** update columns of table "match_submissions" */
export enum Match_Submissions_Update_Column {
  /** column name */
  BodyTypes = 'body_types',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DailyDrivingDistance = 'daily_driving_distance',
  /** column name */
  Id = 'id',
  /** column name */
  Interests = 'interests',
  /** column name */
  PriceRangeLower = 'price_range_lower',
  /** column name */
  PriceRangeUpper = 'price_range_upper',
  /** column name */
  Seats = 'seats',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Match_Submissions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Match_Submissions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Match_Submissions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Match_Submissions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Match_Submissions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Match_Submissions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Match_Submissions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Match_Submissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Match_Submissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Match_Submissions_Var_Pop_Fields = {
  __typename?: 'match_submissions_var_pop_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Match_Submissions_Var_Samp_Fields = {
  __typename?: 'match_submissions_var_samp_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Match_Submissions_Variance_Fields = {
  __typename?: 'match_submissions_variance_fields';
  daily_driving_distance?: Maybe<Scalars['Float']>;
  price_range_lower?: Maybe<Scalars['Float']>;
  price_range_upper?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
};

/** Enquiries and other interactions with customers. */
export type Moments = {
  __typename?: 'moments';
  channel: Scalars['String'];
  created_at: Scalars['timestamptz'];
  enquiry_id?: Maybe<Scalars['String']>;
  enquiry_reason?: Maybe<Scalars['String']>;
  /** An object relationship */
  form_submission?: Maybe<Form_Submissions>;
  form_submission_id?: Maybe<Scalars['uuid']>;
  friendly_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']>;
  priority: Scalars['String'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  stage: Scalars['String'];
  /** An object relationship */
  stocked_vehicle?: Maybe<Stocked_Vehicles>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** Enquiries and other interactions with customers. */
export type MomentsNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** Enquiries and other interactions with customers. */
export type MomentsNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};

/** aggregated selection of "moments" */
export type Moments_Aggregate = {
  __typename?: 'moments_aggregate';
  aggregate?: Maybe<Moments_Aggregate_Fields>;
  nodes: Array<Moments>;
};

export type Moments_Aggregate_Bool_Exp = {
  count?: Maybe<Moments_Aggregate_Bool_Exp_Count>;
};

export type Moments_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Moments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Moments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "moments" */
export type Moments_Aggregate_Fields = {
  __typename?: 'moments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Moments_Max_Fields>;
  min?: Maybe<Moments_Min_Fields>;
};


/** aggregate fields of "moments" */
export type Moments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Moments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "moments" */
export type Moments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Moments_Max_Order_By>;
  min?: Maybe<Moments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "moments" */
export type Moments_Arr_Rel_Insert_Input = {
  data: Array<Moments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Moments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "moments". All fields are combined with a logical 'AND'. */
export type Moments_Bool_Exp = {
  _and?: Maybe<Array<Moments_Bool_Exp>>;
  _not?: Maybe<Moments_Bool_Exp>;
  _or?: Maybe<Array<Moments_Bool_Exp>>;
  channel?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  enquiry_id?: Maybe<String_Comparison_Exp>;
  enquiry_reason?: Maybe<String_Comparison_Exp>;
  form_submission?: Maybe<Form_Submissions_Bool_Exp>;
  form_submission_id?: Maybe<Uuid_Comparison_Exp>;
  friendly_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  notes_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  priority?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "moments" */
export enum Moments_Constraint {
  /** unique or primary key constraint on columns "id" */
  MomentsPkey = 'moments_pkey',
  /** unique or primary key constraint on columns "friendly_id", "showroom_id" */
  MomentsShowroomIdFriendlyIdKey = 'moments_showroom_id_friendly_id_key'
}

/** input type for inserting data into table "moments" */
export type Moments_Insert_Input = {
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enquiry_id?: Maybe<Scalars['String']>;
  enquiry_reason?: Maybe<Scalars['String']>;
  form_submission?: Maybe<Form_Submissions_Obj_Rel_Insert_Input>;
  form_submission_id?: Maybe<Scalars['uuid']>;
  friendly_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Moments_Max_Fields = {
  __typename?: 'moments_max_fields';
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enquiry_id?: Maybe<Scalars['String']>;
  enquiry_reason?: Maybe<Scalars['String']>;
  form_submission_id?: Maybe<Scalars['uuid']>;
  friendly_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "moments" */
export type Moments_Max_Order_By = {
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  enquiry_id?: Maybe<Order_By>;
  enquiry_reason?: Maybe<Order_By>;
  form_submission_id?: Maybe<Order_By>;
  friendly_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Moments_Min_Fields = {
  __typename?: 'moments_min_fields';
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enquiry_id?: Maybe<Scalars['String']>;
  enquiry_reason?: Maybe<Scalars['String']>;
  form_submission_id?: Maybe<Scalars['uuid']>;
  friendly_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "moments" */
export type Moments_Min_Order_By = {
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  enquiry_id?: Maybe<Order_By>;
  enquiry_reason?: Maybe<Order_By>;
  form_submission_id?: Maybe<Order_By>;
  friendly_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "moments" */
export type Moments_Mutation_Response = {
  __typename?: 'moments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Moments>;
};

/** input type for inserting object relation for remote table "moments" */
export type Moments_Obj_Rel_Insert_Input = {
  data: Moments_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Moments_On_Conflict>;
};

/** on_conflict condition type for table "moments" */
export type Moments_On_Conflict = {
  constraint: Moments_Constraint;
  update_columns?: Array<Moments_Update_Column>;
  where?: Maybe<Moments_Bool_Exp>;
};

/** Ordering options when selecting data from "moments". */
export type Moments_Order_By = {
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  enquiry_id?: Maybe<Order_By>;
  enquiry_reason?: Maybe<Order_By>;
  form_submission?: Maybe<Form_Submissions_Order_By>;
  form_submission_id?: Maybe<Order_By>;
  friendly_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: moments */
export type Moments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "moments" */
export enum Moments_Select_Column {
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnquiryId = 'enquiry_id',
  /** column name */
  EnquiryReason = 'enquiry_reason',
  /** column name */
  FormSubmissionId = 'form_submission_id',
  /** column name */
  FriendlyId = 'friendly_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "moments" */
export type Moments_Set_Input = {
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enquiry_id?: Maybe<Scalars['String']>;
  enquiry_reason?: Maybe<Scalars['String']>;
  form_submission_id?: Maybe<Scalars['uuid']>;
  friendly_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "moments" */
export type Moments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Moments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Moments_Stream_Cursor_Value_Input = {
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enquiry_id?: Maybe<Scalars['String']>;
  enquiry_reason?: Maybe<Scalars['String']>;
  form_submission_id?: Maybe<Scalars['uuid']>;
  friendly_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "moments" */
export enum Moments_Update_Column {
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnquiryId = 'enquiry_id',
  /** column name */
  EnquiryReason = 'enquiry_reason',
  /** column name */
  FormSubmissionId = 'form_submission_id',
  /** column name */
  FriendlyId = 'friendly_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Moments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Moments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Moments_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  cancel_vehicle_order?: Maybe<CancelVehicleOrderOutput>;
  /** create_app_author_api_key */
  create_app_author_api_key: ApiKeyOutput;
  /** delete data from the table: "activities" */
  delete_activities?: Maybe<Activities_Mutation_Response>;
  /** delete single row from the table: "activities" */
  delete_activities_by_pk?: Maybe<Activities>;
  /** delete data from the table: "api_clients" */
  delete_api_clients?: Maybe<Api_Clients_Mutation_Response>;
  /** delete single row from the table: "api_clients" */
  delete_api_clients_by_pk?: Maybe<Api_Clients>;
  /** delete data from the table: "app_actions" */
  delete_app_actions?: Maybe<App_Actions_Mutation_Response>;
  /** delete single row from the table: "app_actions" */
  delete_app_actions_by_pk?: Maybe<App_Actions>;
  /** delete data from the table: "app_events" */
  delete_app_events?: Maybe<App_Events_Mutation_Response>;
  /** delete single row from the table: "app_events" */
  delete_app_events_by_pk?: Maybe<App_Events>;
  /** delete data from the table: "app_inputs" */
  delete_app_inputs?: Maybe<App_Inputs_Mutation_Response>;
  /** delete single row from the table: "app_inputs" */
  delete_app_inputs_by_pk?: Maybe<App_Inputs>;
  /** delete data from the table: "app_versions" */
  delete_app_versions?: Maybe<App_Versions_Mutation_Response>;
  /** delete single row from the table: "app_versions" */
  delete_app_versions_by_pk?: Maybe<App_Versions>;
  /** delete data from the table: "apps" */
  delete_apps?: Maybe<Apps_Mutation_Response>;
  /** delete single row from the table: "apps" */
  delete_apps_by_pk?: Maybe<Apps>;
  /** delete data from the table: "articles" */
  delete_articles?: Maybe<Articles_Mutation_Response>;
  /** delete single row from the table: "articles" */
  delete_articles_by_pk?: Maybe<Articles>;
  /** delete data from the table: "assets" */
  delete_assets?: Maybe<Assets_Mutation_Response>;
  /** delete single row from the table: "assets" */
  delete_assets_by_pk?: Maybe<Assets>;
  /** delete data from the table: "auction_bids" */
  delete_auction_bids?: Maybe<Auction_Bids_Mutation_Response>;
  /** delete single row from the table: "auction_bids" */
  delete_auction_bids_by_pk?: Maybe<Auction_Bids>;
  /** delete data from the table: "auctions" */
  delete_auctions?: Maybe<Auctions_Mutation_Response>;
  /** delete single row from the table: "auctions" */
  delete_auctions_by_pk?: Maybe<Auctions>;
  /** delete data from the table: "calculator_submission" */
  delete_calculator_submission?: Maybe<Calculator_Submission_Mutation_Response>;
  /** delete single row from the table: "calculator_submission" */
  delete_calculator_submission_by_pk?: Maybe<Calculator_Submission>;
  /** delete data from the table: "custom_field_values" */
  delete_custom_field_values?: Maybe<Custom_Field_Values_Mutation_Response>;
  /** delete single row from the table: "custom_field_values" */
  delete_custom_field_values_by_pk?: Maybe<Custom_Field_Values>;
  /** delete data from the table: "custom_fields" */
  delete_custom_fields?: Maybe<Custom_Fields_Mutation_Response>;
  /** delete single row from the table: "custom_fields" */
  delete_custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** delete data from the table: "custom_vehicles" */
  delete_custom_vehicles?: Maybe<Custom_Vehicles_Mutation_Response>;
  /** delete single row from the table: "custom_vehicles" */
  delete_custom_vehicles_by_pk?: Maybe<Custom_Vehicles>;
  /** delete data from the table: "filter_fields" */
  delete_filter_fields?: Maybe<Filter_Fields_Mutation_Response>;
  /** delete single row from the table: "filter_fields" */
  delete_filter_fields_by_pk?: Maybe<Filter_Fields>;
  /** delete data from the table: "filter_locations" */
  delete_filter_locations?: Maybe<Filter_Locations_Mutation_Response>;
  /** delete single row from the table: "filter_locations" */
  delete_filter_locations_by_pk?: Maybe<Filter_Locations>;
  /** delete data from the table: "filter_names" */
  delete_filter_names?: Maybe<Filter_Names_Mutation_Response>;
  /** delete single row from the table: "filter_names" */
  delete_filter_names_by_pk?: Maybe<Filter_Names>;
  /** delete data from the table: "finance_applications" */
  delete_finance_applications?: Maybe<Finance_Applications_Mutation_Response>;
  /** delete single row from the table: "finance_applications" */
  delete_finance_applications_by_pk?: Maybe<Finance_Applications>;
  /** delete data from the table: "finance_fees" */
  delete_finance_fees?: Maybe<Finance_Fees_Mutation_Response>;
  /** delete single row from the table: "finance_fees" */
  delete_finance_fees_by_pk?: Maybe<Finance_Fees>;
  /** delete data from the table: "finance_fixed_rate_configurations" */
  delete_finance_fixed_rate_configurations?: Maybe<Finance_Fixed_Rate_Configurations_Mutation_Response>;
  /** delete single row from the table: "finance_fixed_rate_configurations" */
  delete_finance_fixed_rate_configurations_by_pk?: Maybe<Finance_Fixed_Rate_Configurations>;
  /** delete data from the table: "finance_frequency" */
  delete_finance_frequency?: Maybe<Finance_Frequency_Mutation_Response>;
  /** delete single row from the table: "finance_frequency" */
  delete_finance_frequency_by_pk?: Maybe<Finance_Frequency>;
  /** delete data from the table: "finance_gfv_configurations" */
  delete_finance_gfv_configurations?: Maybe<Finance_Gfv_Configurations_Mutation_Response>;
  /** delete single row from the table: "finance_gfv_configurations" */
  delete_finance_gfv_configurations_by_pk?: Maybe<Finance_Gfv_Configurations>;
  /** delete data from the table: "finance_gfv_residual_values" */
  delete_finance_gfv_residual_values?: Maybe<Finance_Gfv_Residual_Values_Mutation_Response>;
  /** delete single row from the table: "finance_gfv_residual_values" */
  delete_finance_gfv_residual_values_by_pk?: Maybe<Finance_Gfv_Residual_Values>;
  /** delete data from the table: "finance_product_offers" */
  delete_finance_product_offers?: Maybe<Finance_Product_Offers_Mutation_Response>;
  /** delete single row from the table: "finance_product_offers" */
  delete_finance_product_offers_by_pk?: Maybe<Finance_Product_Offers>;
  /** delete data from the table: "finance_product_types" */
  delete_finance_product_types?: Maybe<Finance_Product_Types_Mutation_Response>;
  /** delete single row from the table: "finance_product_types" */
  delete_finance_product_types_by_pk?: Maybe<Finance_Product_Types>;
  /** delete data from the table: "finance_products" */
  delete_finance_products?: Maybe<Finance_Products_Mutation_Response>;
  /** delete single row from the table: "finance_products" */
  delete_finance_products_by_pk?: Maybe<Finance_Products>;
  /** delete data from the table: "finance_terms" */
  delete_finance_terms?: Maybe<Finance_Terms_Mutation_Response>;
  /** delete single row from the table: "finance_terms" */
  delete_finance_terms_by_pk?: Maybe<Finance_Terms>;
  /** delete data from the table: "form_fields" */
  delete_form_fields?: Maybe<Form_Fields_Mutation_Response>;
  /** delete single row from the table: "form_fields" */
  delete_form_fields_by_pk?: Maybe<Form_Fields>;
  /** delete data from the table: "form_submissions" */
  delete_form_submissions?: Maybe<Form_Submissions_Mutation_Response>;
  /** delete single row from the table: "form_submissions" */
  delete_form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>;
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>;
  /** delete data from the table: "insights_click_events" */
  delete_insights_click_events?: Maybe<Insights_Click_Events_Mutation_Response>;
  /** delete single row from the table: "insights_click_events" */
  delete_insights_click_events_by_pk?: Maybe<Insights_Click_Events>;
  /** delete data from the table: "insights_devices" */
  delete_insights_devices?: Maybe<Insights_Devices_Mutation_Response>;
  /** delete single row from the table: "insights_devices" */
  delete_insights_devices_by_pk?: Maybe<Insights_Devices>;
  /** delete data from the table: "insights_event_types" */
  delete_insights_event_types?: Maybe<Insights_Event_Types_Mutation_Response>;
  /** delete single row from the table: "insights_event_types" */
  delete_insights_event_types_by_pk?: Maybe<Insights_Event_Types>;
  /** delete data from the table: "insights_events" */
  delete_insights_events?: Maybe<Insights_Events_Mutation_Response>;
  /** delete single row from the table: "insights_events" */
  delete_insights_events_by_pk?: Maybe<Insights_Events>;
  /** delete data from the table: "insights_page_view_events" */
  delete_insights_page_view_events?: Maybe<Insights_Page_View_Events_Mutation_Response>;
  /** delete single row from the table: "insights_page_view_events" */
  delete_insights_page_view_events_by_pk?: Maybe<Insights_Page_View_Events>;
  /** delete data from the table: "insights_sessions" */
  delete_insights_sessions?: Maybe<Insights_Sessions_Mutation_Response>;
  /** delete single row from the table: "insights_sessions" */
  delete_insights_sessions_by_pk?: Maybe<Insights_Sessions>;
  /** delete data from the table: "insights_visitors" */
  delete_insights_visitors?: Maybe<Insights_Visitors_Mutation_Response>;
  /** delete single row from the table: "insights_visitors" */
  delete_insights_visitors_by_pk?: Maybe<Insights_Visitors>;
  /** delete data from the table: "interests" */
  delete_interests?: Maybe<Interests_Mutation_Response>;
  /** delete single row from the table: "interests" */
  delete_interests_by_pk?: Maybe<Interests>;
  /** delete data from the table: "known_vehicles" */
  delete_known_vehicles?: Maybe<Known_Vehicles_Mutation_Response>;
  /** delete single row from the table: "known_vehicles" */
  delete_known_vehicles_by_pk?: Maybe<Known_Vehicles>;
  /** delete data from the table: "locales" */
  delete_locales?: Maybe<Locales_Mutation_Response>;
  /** delete single row from the table: "locales" */
  delete_locales_by_pk?: Maybe<Locales>;
  /** delete data from the table: "location_service_types" */
  delete_location_service_types?: Maybe<Location_Service_Types_Mutation_Response>;
  /** delete single row from the table: "location_service_types" */
  delete_location_service_types_by_pk?: Maybe<Location_Service_Types>;
  /** delete data from the table: "location_transport_types" */
  delete_location_transport_types?: Maybe<Location_Transport_Types_Mutation_Response>;
  /** delete single row from the table: "location_transport_types" */
  delete_location_transport_types_by_pk?: Maybe<Location_Transport_Types>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "match_submissions" */
  delete_match_submissions?: Maybe<Match_Submissions_Mutation_Response>;
  /** delete single row from the table: "match_submissions" */
  delete_match_submissions_by_pk?: Maybe<Match_Submissions>;
  /** delete data from the table: "moments" */
  delete_moments?: Maybe<Moments_Mutation_Response>;
  /** delete single row from the table: "moments" */
  delete_moments_by_pk?: Maybe<Moments>;
  /** delete data from the table: "notes" */
  delete_notes?: Maybe<Notes_Mutation_Response>;
  /** delete single row from the table: "notes" */
  delete_notes_by_pk?: Maybe<Notes>;
  /** delete data from the table: "offer_types" */
  delete_offer_types?: Maybe<Offer_Types_Mutation_Response>;
  /** delete single row from the table: "offer_types" */
  delete_offer_types_by_pk?: Maybe<Offer_Types>;
  /** delete data from the table: "offers" */
  delete_offers?: Maybe<Offers_Mutation_Response>;
  /** delete single row from the table: "offers" */
  delete_offers_by_pk?: Maybe<Offers>;
  /** delete data from the table: "pages" */
  delete_pages?: Maybe<Pages_Mutation_Response>;
  /** delete single row from the table: "pages" */
  delete_pages_by_pk?: Maybe<Pages>;
  /** delete data from the table: "partners" */
  delete_partners?: Maybe<Partners_Mutation_Response>;
  /** delete single row from the table: "partners" */
  delete_partners_by_pk?: Maybe<Partners>;
  /** delete data from the table: "payment_codes" */
  delete_payment_codes?: Maybe<Payment_Codes_Mutation_Response>;
  /** delete single row from the table: "payment_codes" */
  delete_payment_codes_by_pk?: Maybe<Payment_Codes>;
  /** delete data from the table: "payment_method_types" */
  delete_payment_method_types?: Maybe<Payment_Method_Types_Mutation_Response>;
  /** delete single row from the table: "payment_method_types" */
  delete_payment_method_types_by_pk?: Maybe<Payment_Method_Types>;
  /** delete data from the table: "payment_methods" */
  delete_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** delete single row from the table: "payment_methods" */
  delete_payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** delete data from the table: "payments" */
  delete_payments?: Maybe<Payments_Mutation_Response>;
  /** delete single row from the table: "payments" */
  delete_payments_by_pk?: Maybe<Payments>;
  /** delete data from the table: "personal_vehicles" */
  delete_personal_vehicles?: Maybe<Personal_Vehicles_Mutation_Response>;
  /** delete single row from the table: "personal_vehicles" */
  delete_personal_vehicles_by_pk?: Maybe<Personal_Vehicles>;
  /** delete data from the table: "pricing_codes" */
  delete_pricing_codes?: Maybe<Pricing_Codes_Mutation_Response>;
  /** delete single row from the table: "pricing_codes" */
  delete_pricing_codes_by_pk?: Maybe<Pricing_Codes>;
  /** delete data from the table: "product_groups" */
  delete_product_groups?: Maybe<Product_Groups_Mutation_Response>;
  /** delete single row from the table: "product_groups" */
  delete_product_groups_by_pk?: Maybe<Product_Groups>;
  /** delete data from the table: "product_variants" */
  delete_product_variants?: Maybe<Product_Variants_Mutation_Response>;
  /** delete single row from the table: "product_variants" */
  delete_product_variants_by_pk?: Maybe<Product_Variants>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "promotions" */
  delete_promotions?: Maybe<Promotions_Mutation_Response>;
  /** delete single row from the table: "promotions" */
  delete_promotions_by_pk?: Maybe<Promotions>;
  /** delete data from the table: "redirects" */
  delete_redirects?: Maybe<Redirects_Mutation_Response>;
  /** delete single row from the table: "redirects" */
  delete_redirects_by_pk?: Maybe<Redirects>;
  /** delete data from the table: "rental_drivers" */
  delete_rental_drivers?: Maybe<Rental_Drivers_Mutation_Response>;
  /** delete single row from the table: "rental_drivers" */
  delete_rental_drivers_by_pk?: Maybe<Rental_Drivers>;
  /** delete data from the table: "rental_reservation_status" */
  delete_rental_reservation_status?: Maybe<Rental_Reservation_Status_Mutation_Response>;
  /** delete single row from the table: "rental_reservation_status" */
  delete_rental_reservation_status_by_pk?: Maybe<Rental_Reservation_Status>;
  /** delete data from the table: "rental_reservations" */
  delete_rental_reservations?: Maybe<Rental_Reservations_Mutation_Response>;
  /** delete single row from the table: "rental_reservations" */
  delete_rental_reservations_by_pk?: Maybe<Rental_Reservations>;
  /** delete data from the table: "service_booking_service_types" */
  delete_service_booking_service_types?: Maybe<Service_Booking_Service_Types_Mutation_Response>;
  /** delete single row from the table: "service_booking_service_types" */
  delete_service_booking_service_types_by_pk?: Maybe<Service_Booking_Service_Types>;
  /** delete data from the table: "service_bookings" */
  delete_service_bookings?: Maybe<Service_Bookings_Mutation_Response>;
  /** delete single row from the table: "service_bookings" */
  delete_service_bookings_by_pk?: Maybe<Service_Bookings>;
  /** delete data from the table: "service_types" */
  delete_service_types?: Maybe<Service_Types_Mutation_Response>;
  /** delete single row from the table: "service_types" */
  delete_service_types_by_pk?: Maybe<Service_Types>;
  /** delete data from the table: "sessions" */
  delete_sessions?: Maybe<Sessions_Mutation_Response>;
  /** delete single row from the table: "sessions" */
  delete_sessions_by_pk?: Maybe<Sessions>;
  /** delete data from the table: "showroom_apps" */
  delete_showroom_apps?: Maybe<Showroom_Apps_Mutation_Response>;
  /** delete single row from the table: "showroom_apps" */
  delete_showroom_apps_by_pk?: Maybe<Showroom_Apps>;
  /** delete data from the table: "showroom_apps_inputs" */
  delete_showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Mutation_Response>;
  /** delete single row from the table: "showroom_apps_inputs" */
  delete_showroom_apps_inputs_by_pk?: Maybe<Showroom_Apps_Inputs>;
  /** delete data from the table: "showroom_locales" */
  delete_showroom_locales?: Maybe<Showroom_Locales_Mutation_Response>;
  /** delete single row from the table: "showroom_locales" */
  delete_showroom_locales_by_pk?: Maybe<Showroom_Locales>;
  /** delete data from the table: "showroom_pricing_codes" */
  delete_showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Mutation_Response>;
  /** delete single row from the table: "showroom_pricing_codes" */
  delete_showroom_pricing_codes_by_pk?: Maybe<Showroom_Pricing_Codes>;
  /** delete data from the table: "showroom_stocked_vehicles" */
  delete_showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Mutation_Response>;
  /** delete single row from the table: "showroom_stocked_vehicles" */
  delete_showroom_stocked_vehicles_by_pk?: Maybe<Showroom_Stocked_Vehicles>;
  /** delete data from the table: "showroom_users" */
  delete_showroom_users?: Maybe<Showroom_Users_Mutation_Response>;
  /** delete single row from the table: "showroom_users" */
  delete_showroom_users_by_pk?: Maybe<Showroom_Users>;
  /** delete data from the table: "showrooms" */
  delete_showrooms?: Maybe<Showrooms_Mutation_Response>;
  /** delete single row from the table: "showrooms" */
  delete_showrooms_by_pk?: Maybe<Showrooms>;
  /** delete data from the table: "showrooms_partners" */
  delete_showrooms_partners?: Maybe<Showrooms_Partners_Mutation_Response>;
  /** delete single row from the table: "showrooms_partners" */
  delete_showrooms_partners_by_pk?: Maybe<Showrooms_Partners>;
  /** delete data from the table: "showrooms_promotions" */
  delete_showrooms_promotions?: Maybe<Showrooms_Promotions_Mutation_Response>;
  /** delete single row from the table: "showrooms_promotions" */
  delete_showrooms_promotions_by_pk?: Maybe<Showrooms_Promotions>;
  /** delete data from the table: "stocked_vehicle_finance_products" */
  delete_stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Mutation_Response>;
  /** delete single row from the table: "stocked_vehicle_finance_products" */
  delete_stocked_vehicle_finance_products_by_pk?: Maybe<Stocked_Vehicle_Finance_Products>;
  /** delete data from the table: "stocked_vehicle_handover_locations" */
  delete_stocked_vehicle_handover_locations?: Maybe<Stocked_Vehicle_Handover_Locations_Mutation_Response>;
  /** delete single row from the table: "stocked_vehicle_handover_locations" */
  delete_stocked_vehicle_handover_locations_by_pk?: Maybe<Stocked_Vehicle_Handover_Locations>;
  /** delete data from the table: "stocked_vehicle_offers" */
  delete_stocked_vehicle_offers?: Maybe<Stocked_Vehicle_Offers_Mutation_Response>;
  /** delete single row from the table: "stocked_vehicle_offers" */
  delete_stocked_vehicle_offers_by_pk?: Maybe<Stocked_Vehicle_Offers>;
  /** delete data from the table: "stocked_vehicles" */
  delete_stocked_vehicles?: Maybe<Stocked_Vehicles_Mutation_Response>;
  /** delete single row from the table: "stocked_vehicles" */
  delete_stocked_vehicles_by_pk?: Maybe<Stocked_Vehicles>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "test_drives" */
  delete_test_drives?: Maybe<Test_Drives_Mutation_Response>;
  /** delete single row from the table: "test_drives" */
  delete_test_drives_by_pk?: Maybe<Test_Drives>;
  /** delete data from the table: "trade_in_plans" */
  delete_trade_in_plans?: Maybe<Trade_In_Plans_Mutation_Response>;
  /** delete single row from the table: "trade_in_plans" */
  delete_trade_in_plans_by_pk?: Maybe<Trade_In_Plans>;
  /** delete data from the table: "trade_in_valuations" */
  delete_trade_in_valuations?: Maybe<Trade_In_Valuations_Mutation_Response>;
  /** delete single row from the table: "trade_in_valuations" */
  delete_trade_in_valuations_by_pk?: Maybe<Trade_In_Valuations>;
  /** delete data from the table: "transport_types" */
  delete_transport_types?: Maybe<Transport_Types_Mutation_Response>;
  /** delete single row from the table: "transport_types" */
  delete_transport_types_by_pk?: Maybe<Transport_Types>;
  /** delete data from the table: "user_interests" */
  delete_user_interests?: Maybe<User_Interests_Mutation_Response>;
  /** delete single row from the table: "user_interests" */
  delete_user_interests_by_pk?: Maybe<User_Interests>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "valuers" */
  delete_valuers?: Maybe<Valuers_Mutation_Response>;
  /** delete single row from the table: "valuers" */
  delete_valuers_by_pk?: Maybe<Valuers>;
  /** delete data from the table: "vehicle_conditions" */
  delete_vehicle_conditions?: Maybe<Vehicle_Conditions_Mutation_Response>;
  /** delete single row from the table: "vehicle_conditions" */
  delete_vehicle_conditions_by_pk?: Maybe<Vehicle_Conditions>;
  /** delete data from the table: "vehicle_lists" */
  delete_vehicle_lists?: Maybe<Vehicle_Lists_Mutation_Response>;
  /** delete single row from the table: "vehicle_lists" */
  delete_vehicle_lists_by_pk?: Maybe<Vehicle_Lists>;
  /** delete data from the table: "vehicle_order_activity" */
  delete_vehicle_order_activity?: Maybe<Vehicle_Order_Activity_Mutation_Response>;
  /** delete single row from the table: "vehicle_order_activity" */
  delete_vehicle_order_activity_by_pk?: Maybe<Vehicle_Order_Activity>;
  /** delete data from the table: "vehicle_order_product_variants" */
  delete_vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Mutation_Response>;
  /** delete single row from the table: "vehicle_order_product_variants" */
  delete_vehicle_order_product_variants_by_pk?: Maybe<Vehicle_Order_Product_Variants>;
  /** delete data from the table: "vehicle_orders" */
  delete_vehicle_orders?: Maybe<Vehicle_Orders_Mutation_Response>;
  /** delete single row from the table: "vehicle_orders" */
  delete_vehicle_orders_by_pk?: Maybe<Vehicle_Orders>;
  /** delete data from the table: "vehicle_specifications" */
  delete_vehicle_specifications?: Maybe<Vehicle_Specifications_Mutation_Response>;
  /** delete single row from the table: "vehicle_specifications" */
  delete_vehicle_specifications_by_pk?: Maybe<Vehicle_Specifications>;
  /** delete data from the table: "wish_list" */
  delete_wish_list?: Maybe<Wish_List_Mutation_Response>;
  /** delete single row from the table: "wish_list" */
  delete_wish_list_by_pk?: Maybe<Wish_List>;
  get_autograb_valuation: GetAutoGrabValuationOutput;
  get_autograb_vehicle_by_reg_number?: Maybe<GetAutoGrabVehicleByRegNumberOutput>;
  get_holding_deposit_payment_url: PaymentUrlOutput;
  get_loopit_subscription_url: LoopitSubscriptionUrl;
  /** insert data into the table: "activities" */
  insert_activities?: Maybe<Activities_Mutation_Response>;
  /** insert a single row into the table: "activities" */
  insert_activities_one?: Maybe<Activities>;
  /** insert data into the table: "api_clients" */
  insert_api_clients?: Maybe<Api_Clients_Mutation_Response>;
  /** insert a single row into the table: "api_clients" */
  insert_api_clients_one?: Maybe<Api_Clients>;
  /** insert data into the table: "app_actions" */
  insert_app_actions?: Maybe<App_Actions_Mutation_Response>;
  /** insert a single row into the table: "app_actions" */
  insert_app_actions_one?: Maybe<App_Actions>;
  /** insert data into the table: "app_events" */
  insert_app_events?: Maybe<App_Events_Mutation_Response>;
  /** insert a single row into the table: "app_events" */
  insert_app_events_one?: Maybe<App_Events>;
  /** insert data into the table: "app_inputs" */
  insert_app_inputs?: Maybe<App_Inputs_Mutation_Response>;
  /** insert a single row into the table: "app_inputs" */
  insert_app_inputs_one?: Maybe<App_Inputs>;
  /** insert data into the table: "app_versions" */
  insert_app_versions?: Maybe<App_Versions_Mutation_Response>;
  /** insert a single row into the table: "app_versions" */
  insert_app_versions_one?: Maybe<App_Versions>;
  /** insert data into the table: "apps" */
  insert_apps?: Maybe<Apps_Mutation_Response>;
  /** insert a single row into the table: "apps" */
  insert_apps_one?: Maybe<Apps>;
  /** insert data into the table: "articles" */
  insert_articles?: Maybe<Articles_Mutation_Response>;
  /** insert a single row into the table: "articles" */
  insert_articles_one?: Maybe<Articles>;
  /** insert data into the table: "assets" */
  insert_assets?: Maybe<Assets_Mutation_Response>;
  /** insert a single row into the table: "assets" */
  insert_assets_one?: Maybe<Assets>;
  /** insert data into the table: "auction_bids" */
  insert_auction_bids?: Maybe<Auction_Bids_Mutation_Response>;
  /** insert a single row into the table: "auction_bids" */
  insert_auction_bids_one?: Maybe<Auction_Bids>;
  /** insert data into the table: "auctions" */
  insert_auctions?: Maybe<Auctions_Mutation_Response>;
  /** insert a single row into the table: "auctions" */
  insert_auctions_one?: Maybe<Auctions>;
  /** insert data into the table: "calculator_submission" */
  insert_calculator_submission?: Maybe<Calculator_Submission_Mutation_Response>;
  /** insert a single row into the table: "calculator_submission" */
  insert_calculator_submission_one?: Maybe<Calculator_Submission>;
  /** insert data into the table: "custom_field_values" */
  insert_custom_field_values?: Maybe<Custom_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "custom_field_values" */
  insert_custom_field_values_one?: Maybe<Custom_Field_Values>;
  /** insert data into the table: "custom_fields" */
  insert_custom_fields?: Maybe<Custom_Fields_Mutation_Response>;
  /** insert a single row into the table: "custom_fields" */
  insert_custom_fields_one?: Maybe<Custom_Fields>;
  /** insert data into the table: "custom_vehicles" */
  insert_custom_vehicles?: Maybe<Custom_Vehicles_Mutation_Response>;
  /** insert a single row into the table: "custom_vehicles" */
  insert_custom_vehicles_one?: Maybe<Custom_Vehicles>;
  /** insert data into the table: "filter_fields" */
  insert_filter_fields?: Maybe<Filter_Fields_Mutation_Response>;
  /** insert a single row into the table: "filter_fields" */
  insert_filter_fields_one?: Maybe<Filter_Fields>;
  /** insert data into the table: "filter_locations" */
  insert_filter_locations?: Maybe<Filter_Locations_Mutation_Response>;
  /** insert a single row into the table: "filter_locations" */
  insert_filter_locations_one?: Maybe<Filter_Locations>;
  /** insert data into the table: "filter_names" */
  insert_filter_names?: Maybe<Filter_Names_Mutation_Response>;
  /** insert a single row into the table: "filter_names" */
  insert_filter_names_one?: Maybe<Filter_Names>;
  /** insert data into the table: "finance_applications" */
  insert_finance_applications?: Maybe<Finance_Applications_Mutation_Response>;
  /** insert a single row into the table: "finance_applications" */
  insert_finance_applications_one?: Maybe<Finance_Applications>;
  /** insert data into the table: "finance_fees" */
  insert_finance_fees?: Maybe<Finance_Fees_Mutation_Response>;
  /** insert a single row into the table: "finance_fees" */
  insert_finance_fees_one?: Maybe<Finance_Fees>;
  /** insert data into the table: "finance_fixed_rate_configurations" */
  insert_finance_fixed_rate_configurations?: Maybe<Finance_Fixed_Rate_Configurations_Mutation_Response>;
  /** insert a single row into the table: "finance_fixed_rate_configurations" */
  insert_finance_fixed_rate_configurations_one?: Maybe<Finance_Fixed_Rate_Configurations>;
  /** insert data into the table: "finance_frequency" */
  insert_finance_frequency?: Maybe<Finance_Frequency_Mutation_Response>;
  /** insert a single row into the table: "finance_frequency" */
  insert_finance_frequency_one?: Maybe<Finance_Frequency>;
  /** insert data into the table: "finance_gfv_configurations" */
  insert_finance_gfv_configurations?: Maybe<Finance_Gfv_Configurations_Mutation_Response>;
  /** insert a single row into the table: "finance_gfv_configurations" */
  insert_finance_gfv_configurations_one?: Maybe<Finance_Gfv_Configurations>;
  /** insert data into the table: "finance_gfv_residual_values" */
  insert_finance_gfv_residual_values?: Maybe<Finance_Gfv_Residual_Values_Mutation_Response>;
  /** insert a single row into the table: "finance_gfv_residual_values" */
  insert_finance_gfv_residual_values_one?: Maybe<Finance_Gfv_Residual_Values>;
  /** insert data into the table: "finance_product_offers" */
  insert_finance_product_offers?: Maybe<Finance_Product_Offers_Mutation_Response>;
  /** insert a single row into the table: "finance_product_offers" */
  insert_finance_product_offers_one?: Maybe<Finance_Product_Offers>;
  /** insert data into the table: "finance_product_types" */
  insert_finance_product_types?: Maybe<Finance_Product_Types_Mutation_Response>;
  /** insert a single row into the table: "finance_product_types" */
  insert_finance_product_types_one?: Maybe<Finance_Product_Types>;
  /** insert data into the table: "finance_products" */
  insert_finance_products?: Maybe<Finance_Products_Mutation_Response>;
  /** insert a single row into the table: "finance_products" */
  insert_finance_products_one?: Maybe<Finance_Products>;
  /** insert data into the table: "finance_terms" */
  insert_finance_terms?: Maybe<Finance_Terms_Mutation_Response>;
  /** insert a single row into the table: "finance_terms" */
  insert_finance_terms_one?: Maybe<Finance_Terms>;
  /** insert data into the table: "form_fields" */
  insert_form_fields?: Maybe<Form_Fields_Mutation_Response>;
  /** insert a single row into the table: "form_fields" */
  insert_form_fields_one?: Maybe<Form_Fields>;
  /** insert data into the table: "form_submissions" */
  insert_form_submissions?: Maybe<Form_Submissions_Mutation_Response>;
  /** insert a single row into the table: "form_submissions" */
  insert_form_submissions_one?: Maybe<Form_Submissions>;
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>;
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>;
  /** insert data into the table: "insights_click_events" */
  insert_insights_click_events?: Maybe<Insights_Click_Events_Mutation_Response>;
  /** insert a single row into the table: "insights_click_events" */
  insert_insights_click_events_one?: Maybe<Insights_Click_Events>;
  /** insert data into the table: "insights_devices" */
  insert_insights_devices?: Maybe<Insights_Devices_Mutation_Response>;
  /** insert a single row into the table: "insights_devices" */
  insert_insights_devices_one?: Maybe<Insights_Devices>;
  /** insert data into the table: "insights_event_types" */
  insert_insights_event_types?: Maybe<Insights_Event_Types_Mutation_Response>;
  /** insert a single row into the table: "insights_event_types" */
  insert_insights_event_types_one?: Maybe<Insights_Event_Types>;
  /** insert data into the table: "insights_events" */
  insert_insights_events?: Maybe<Insights_Events_Mutation_Response>;
  /** insert a single row into the table: "insights_events" */
  insert_insights_events_one?: Maybe<Insights_Events>;
  /** insert data into the table: "insights_page_view_events" */
  insert_insights_page_view_events?: Maybe<Insights_Page_View_Events_Mutation_Response>;
  /** insert a single row into the table: "insights_page_view_events" */
  insert_insights_page_view_events_one?: Maybe<Insights_Page_View_Events>;
  /** insert data into the table: "insights_sessions" */
  insert_insights_sessions?: Maybe<Insights_Sessions_Mutation_Response>;
  /** insert a single row into the table: "insights_sessions" */
  insert_insights_sessions_one?: Maybe<Insights_Sessions>;
  /** insert data into the table: "insights_visitors" */
  insert_insights_visitors?: Maybe<Insights_Visitors_Mutation_Response>;
  /** insert a single row into the table: "insights_visitors" */
  insert_insights_visitors_one?: Maybe<Insights_Visitors>;
  /** insert data into the table: "interests" */
  insert_interests?: Maybe<Interests_Mutation_Response>;
  /** insert a single row into the table: "interests" */
  insert_interests_one?: Maybe<Interests>;
  /** insert data into the table: "known_vehicles" */
  insert_known_vehicles?: Maybe<Known_Vehicles_Mutation_Response>;
  /** insert a single row into the table: "known_vehicles" */
  insert_known_vehicles_one?: Maybe<Known_Vehicles>;
  /** insert data into the table: "locales" */
  insert_locales?: Maybe<Locales_Mutation_Response>;
  /** insert a single row into the table: "locales" */
  insert_locales_one?: Maybe<Locales>;
  /** insert data into the table: "location_service_types" */
  insert_location_service_types?: Maybe<Location_Service_Types_Mutation_Response>;
  /** insert a single row into the table: "location_service_types" */
  insert_location_service_types_one?: Maybe<Location_Service_Types>;
  /** insert data into the table: "location_transport_types" */
  insert_location_transport_types?: Maybe<Location_Transport_Types_Mutation_Response>;
  /** insert a single row into the table: "location_transport_types" */
  insert_location_transport_types_one?: Maybe<Location_Transport_Types>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "match_submissions" */
  insert_match_submissions?: Maybe<Match_Submissions_Mutation_Response>;
  /** insert a single row into the table: "match_submissions" */
  insert_match_submissions_one?: Maybe<Match_Submissions>;
  /** insert data into the table: "moments" */
  insert_moments?: Maybe<Moments_Mutation_Response>;
  /** insert a single row into the table: "moments" */
  insert_moments_one?: Maybe<Moments>;
  /** insert data into the table: "notes" */
  insert_notes?: Maybe<Notes_Mutation_Response>;
  /** insert a single row into the table: "notes" */
  insert_notes_one?: Maybe<Notes>;
  /** insert data into the table: "offer_types" */
  insert_offer_types?: Maybe<Offer_Types_Mutation_Response>;
  /** insert a single row into the table: "offer_types" */
  insert_offer_types_one?: Maybe<Offer_Types>;
  /** insert data into the table: "offers" */
  insert_offers?: Maybe<Offers_Mutation_Response>;
  /** insert a single row into the table: "offers" */
  insert_offers_one?: Maybe<Offers>;
  /** insert data into the table: "pages" */
  insert_pages?: Maybe<Pages_Mutation_Response>;
  /** insert a single row into the table: "pages" */
  insert_pages_one?: Maybe<Pages>;
  /** insert data into the table: "partners" */
  insert_partners?: Maybe<Partners_Mutation_Response>;
  /** insert a single row into the table: "partners" */
  insert_partners_one?: Maybe<Partners>;
  /** insert data into the table: "payment_codes" */
  insert_payment_codes?: Maybe<Payment_Codes_Mutation_Response>;
  /** insert a single row into the table: "payment_codes" */
  insert_payment_codes_one?: Maybe<Payment_Codes>;
  /** insert data into the table: "payment_method_types" */
  insert_payment_method_types?: Maybe<Payment_Method_Types_Mutation_Response>;
  /** insert a single row into the table: "payment_method_types" */
  insert_payment_method_types_one?: Maybe<Payment_Method_Types>;
  /** insert data into the table: "payment_methods" */
  insert_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** insert a single row into the table: "payment_methods" */
  insert_payment_methods_one?: Maybe<Payment_Methods>;
  /** insert data into the table: "payments" */
  insert_payments?: Maybe<Payments_Mutation_Response>;
  /** insert a single row into the table: "payments" */
  insert_payments_one?: Maybe<Payments>;
  /** insert data into the table: "personal_vehicles" */
  insert_personal_vehicles?: Maybe<Personal_Vehicles_Mutation_Response>;
  /** insert a single row into the table: "personal_vehicles" */
  insert_personal_vehicles_one?: Maybe<Personal_Vehicles>;
  /** insert data into the table: "pricing_codes" */
  insert_pricing_codes?: Maybe<Pricing_Codes_Mutation_Response>;
  /** insert a single row into the table: "pricing_codes" */
  insert_pricing_codes_one?: Maybe<Pricing_Codes>;
  /** insert data into the table: "product_groups" */
  insert_product_groups?: Maybe<Product_Groups_Mutation_Response>;
  /** insert a single row into the table: "product_groups" */
  insert_product_groups_one?: Maybe<Product_Groups>;
  /** insert data into the table: "product_variants" */
  insert_product_variants?: Maybe<Product_Variants_Mutation_Response>;
  /** insert a single row into the table: "product_variants" */
  insert_product_variants_one?: Maybe<Product_Variants>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "promotions" */
  insert_promotions?: Maybe<Promotions_Mutation_Response>;
  /** insert a single row into the table: "promotions" */
  insert_promotions_one?: Maybe<Promotions>;
  /** insert data into the table: "redirects" */
  insert_redirects?: Maybe<Redirects_Mutation_Response>;
  /** insert a single row into the table: "redirects" */
  insert_redirects_one?: Maybe<Redirects>;
  /** insert data into the table: "rental_drivers" */
  insert_rental_drivers?: Maybe<Rental_Drivers_Mutation_Response>;
  /** insert a single row into the table: "rental_drivers" */
  insert_rental_drivers_one?: Maybe<Rental_Drivers>;
  /** insert data into the table: "rental_reservation_status" */
  insert_rental_reservation_status?: Maybe<Rental_Reservation_Status_Mutation_Response>;
  /** insert a single row into the table: "rental_reservation_status" */
  insert_rental_reservation_status_one?: Maybe<Rental_Reservation_Status>;
  /** insert data into the table: "rental_reservations" */
  insert_rental_reservations?: Maybe<Rental_Reservations_Mutation_Response>;
  /** insert a single row into the table: "rental_reservations" */
  insert_rental_reservations_one?: Maybe<Rental_Reservations>;
  /** insert data into the table: "service_booking_service_types" */
  insert_service_booking_service_types?: Maybe<Service_Booking_Service_Types_Mutation_Response>;
  /** insert a single row into the table: "service_booking_service_types" */
  insert_service_booking_service_types_one?: Maybe<Service_Booking_Service_Types>;
  /** insert data into the table: "service_bookings" */
  insert_service_bookings?: Maybe<Service_Bookings_Mutation_Response>;
  /** insert a single row into the table: "service_bookings" */
  insert_service_bookings_one?: Maybe<Service_Bookings>;
  /** insert data into the table: "service_types" */
  insert_service_types?: Maybe<Service_Types_Mutation_Response>;
  /** insert a single row into the table: "service_types" */
  insert_service_types_one?: Maybe<Service_Types>;
  /** insert data into the table: "sessions" */
  insert_sessions?: Maybe<Sessions_Mutation_Response>;
  /** insert a single row into the table: "sessions" */
  insert_sessions_one?: Maybe<Sessions>;
  /** insert data into the table: "showroom_apps" */
  insert_showroom_apps?: Maybe<Showroom_Apps_Mutation_Response>;
  /** insert data into the table: "showroom_apps_inputs" */
  insert_showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Mutation_Response>;
  /** insert a single row into the table: "showroom_apps_inputs" */
  insert_showroom_apps_inputs_one?: Maybe<Showroom_Apps_Inputs>;
  /** insert a single row into the table: "showroom_apps" */
  insert_showroom_apps_one?: Maybe<Showroom_Apps>;
  /** insert data into the table: "showroom_locales" */
  insert_showroom_locales?: Maybe<Showroom_Locales_Mutation_Response>;
  /** insert a single row into the table: "showroom_locales" */
  insert_showroom_locales_one?: Maybe<Showroom_Locales>;
  /** insert data into the table: "showroom_pricing_codes" */
  insert_showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Mutation_Response>;
  /** insert a single row into the table: "showroom_pricing_codes" */
  insert_showroom_pricing_codes_one?: Maybe<Showroom_Pricing_Codes>;
  /** insert data into the table: "showroom_stocked_vehicles" */
  insert_showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Mutation_Response>;
  /** insert a single row into the table: "showroom_stocked_vehicles" */
  insert_showroom_stocked_vehicles_one?: Maybe<Showroom_Stocked_Vehicles>;
  /** insert data into the table: "showroom_users" */
  insert_showroom_users?: Maybe<Showroom_Users_Mutation_Response>;
  /** insert a single row into the table: "showroom_users" */
  insert_showroom_users_one?: Maybe<Showroom_Users>;
  /** insert data into the table: "showrooms" */
  insert_showrooms?: Maybe<Showrooms_Mutation_Response>;
  /** insert a single row into the table: "showrooms" */
  insert_showrooms_one?: Maybe<Showrooms>;
  /** insert data into the table: "showrooms_partners" */
  insert_showrooms_partners?: Maybe<Showrooms_Partners_Mutation_Response>;
  /** insert a single row into the table: "showrooms_partners" */
  insert_showrooms_partners_one?: Maybe<Showrooms_Partners>;
  /** insert data into the table: "showrooms_promotions" */
  insert_showrooms_promotions?: Maybe<Showrooms_Promotions_Mutation_Response>;
  /** insert a single row into the table: "showrooms_promotions" */
  insert_showrooms_promotions_one?: Maybe<Showrooms_Promotions>;
  /** insert data into the table: "stocked_vehicle_finance_products" */
  insert_stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Mutation_Response>;
  /** insert a single row into the table: "stocked_vehicle_finance_products" */
  insert_stocked_vehicle_finance_products_one?: Maybe<Stocked_Vehicle_Finance_Products>;
  /** insert data into the table: "stocked_vehicle_handover_locations" */
  insert_stocked_vehicle_handover_locations?: Maybe<Stocked_Vehicle_Handover_Locations_Mutation_Response>;
  /** insert a single row into the table: "stocked_vehicle_handover_locations" */
  insert_stocked_vehicle_handover_locations_one?: Maybe<Stocked_Vehicle_Handover_Locations>;
  /** insert data into the table: "stocked_vehicle_offers" */
  insert_stocked_vehicle_offers?: Maybe<Stocked_Vehicle_Offers_Mutation_Response>;
  /** insert a single row into the table: "stocked_vehicle_offers" */
  insert_stocked_vehicle_offers_one?: Maybe<Stocked_Vehicle_Offers>;
  /** insert data into the table: "stocked_vehicles" */
  insert_stocked_vehicles?: Maybe<Stocked_Vehicles_Mutation_Response>;
  /** insert a single row into the table: "stocked_vehicles" */
  insert_stocked_vehicles_one?: Maybe<Stocked_Vehicles>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "test_drives" */
  insert_test_drives?: Maybe<Test_Drives_Mutation_Response>;
  /** insert a single row into the table: "test_drives" */
  insert_test_drives_one?: Maybe<Test_Drives>;
  /** insert data into the table: "trade_in_plans" */
  insert_trade_in_plans?: Maybe<Trade_In_Plans_Mutation_Response>;
  /** insert a single row into the table: "trade_in_plans" */
  insert_trade_in_plans_one?: Maybe<Trade_In_Plans>;
  /** insert data into the table: "trade_in_valuations" */
  insert_trade_in_valuations?: Maybe<Trade_In_Valuations_Mutation_Response>;
  /** insert a single row into the table: "trade_in_valuations" */
  insert_trade_in_valuations_one?: Maybe<Trade_In_Valuations>;
  /** insert data into the table: "transport_types" */
  insert_transport_types?: Maybe<Transport_Types_Mutation_Response>;
  /** insert a single row into the table: "transport_types" */
  insert_transport_types_one?: Maybe<Transport_Types>;
  /** insert data into the table: "user_interests" */
  insert_user_interests?: Maybe<User_Interests_Mutation_Response>;
  /** insert a single row into the table: "user_interests" */
  insert_user_interests_one?: Maybe<User_Interests>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "valuers" */
  insert_valuers?: Maybe<Valuers_Mutation_Response>;
  /** insert a single row into the table: "valuers" */
  insert_valuers_one?: Maybe<Valuers>;
  /** insert data into the table: "vehicle_conditions" */
  insert_vehicle_conditions?: Maybe<Vehicle_Conditions_Mutation_Response>;
  /** insert a single row into the table: "vehicle_conditions" */
  insert_vehicle_conditions_one?: Maybe<Vehicle_Conditions>;
  /** insert data into the table: "vehicle_lists" */
  insert_vehicle_lists?: Maybe<Vehicle_Lists_Mutation_Response>;
  /** insert a single row into the table: "vehicle_lists" */
  insert_vehicle_lists_one?: Maybe<Vehicle_Lists>;
  /** insert data into the table: "vehicle_order_activity" */
  insert_vehicle_order_activity?: Maybe<Vehicle_Order_Activity_Mutation_Response>;
  /** insert a single row into the table: "vehicle_order_activity" */
  insert_vehicle_order_activity_one?: Maybe<Vehicle_Order_Activity>;
  /** insert data into the table: "vehicle_order_product_variants" */
  insert_vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Mutation_Response>;
  /** insert a single row into the table: "vehicle_order_product_variants" */
  insert_vehicle_order_product_variants_one?: Maybe<Vehicle_Order_Product_Variants>;
  /** insert data into the table: "vehicle_orders" */
  insert_vehicle_orders?: Maybe<Vehicle_Orders_Mutation_Response>;
  /** insert a single row into the table: "vehicle_orders" */
  insert_vehicle_orders_one?: Maybe<Vehicle_Orders>;
  /** insert data into the table: "vehicle_specifications" */
  insert_vehicle_specifications?: Maybe<Vehicle_Specifications_Mutation_Response>;
  /** insert a single row into the table: "vehicle_specifications" */
  insert_vehicle_specifications_one?: Maybe<Vehicle_Specifications>;
  /** insert data into the table: "wish_list" */
  insert_wish_list?: Maybe<Wish_List_Mutation_Response>;
  /** insert a single row into the table: "wish_list" */
  insert_wish_list_one?: Maybe<Wish_List>;
  media_get_signed_download?: Maybe<MediaGetSignedDownloadOutput>;
  media_get_signed_upload: MediaSignedUploadOutput;
  set_vehicle_by_autograb_vehicle_id?: Maybe<SetVehicleByAutograbVehicleIdOutput>;
  /** update data of the table: "activities" */
  update_activities?: Maybe<Activities_Mutation_Response>;
  /** update single row of the table: "activities" */
  update_activities_by_pk?: Maybe<Activities>;
  /** update multiples rows of table: "activities" */
  update_activities_many?: Maybe<Array<Maybe<Activities_Mutation_Response>>>;
  /** update data of the table: "api_clients" */
  update_api_clients?: Maybe<Api_Clients_Mutation_Response>;
  /** update single row of the table: "api_clients" */
  update_api_clients_by_pk?: Maybe<Api_Clients>;
  /** update multiples rows of table: "api_clients" */
  update_api_clients_many?: Maybe<Array<Maybe<Api_Clients_Mutation_Response>>>;
  /** update data of the table: "app_actions" */
  update_app_actions?: Maybe<App_Actions_Mutation_Response>;
  /** update single row of the table: "app_actions" */
  update_app_actions_by_pk?: Maybe<App_Actions>;
  /** update multiples rows of table: "app_actions" */
  update_app_actions_many?: Maybe<Array<Maybe<App_Actions_Mutation_Response>>>;
  /** update data of the table: "app_events" */
  update_app_events?: Maybe<App_Events_Mutation_Response>;
  /** update single row of the table: "app_events" */
  update_app_events_by_pk?: Maybe<App_Events>;
  /** update multiples rows of table: "app_events" */
  update_app_events_many?: Maybe<Array<Maybe<App_Events_Mutation_Response>>>;
  /** update data of the table: "app_inputs" */
  update_app_inputs?: Maybe<App_Inputs_Mutation_Response>;
  /** update single row of the table: "app_inputs" */
  update_app_inputs_by_pk?: Maybe<App_Inputs>;
  /** update multiples rows of table: "app_inputs" */
  update_app_inputs_many?: Maybe<Array<Maybe<App_Inputs_Mutation_Response>>>;
  /** update data of the table: "app_versions" */
  update_app_versions?: Maybe<App_Versions_Mutation_Response>;
  /** update single row of the table: "app_versions" */
  update_app_versions_by_pk?: Maybe<App_Versions>;
  /** update multiples rows of table: "app_versions" */
  update_app_versions_many?: Maybe<Array<Maybe<App_Versions_Mutation_Response>>>;
  /** update data of the table: "apps" */
  update_apps?: Maybe<Apps_Mutation_Response>;
  /** update single row of the table: "apps" */
  update_apps_by_pk?: Maybe<Apps>;
  /** update multiples rows of table: "apps" */
  update_apps_many?: Maybe<Array<Maybe<Apps_Mutation_Response>>>;
  /** update data of the table: "articles" */
  update_articles?: Maybe<Articles_Mutation_Response>;
  /** update single row of the table: "articles" */
  update_articles_by_pk?: Maybe<Articles>;
  /** update multiples rows of table: "articles" */
  update_articles_many?: Maybe<Array<Maybe<Articles_Mutation_Response>>>;
  /** update data of the table: "assets" */
  update_assets?: Maybe<Assets_Mutation_Response>;
  /** update single row of the table: "assets" */
  update_assets_by_pk?: Maybe<Assets>;
  /** update multiples rows of table: "assets" */
  update_assets_many?: Maybe<Array<Maybe<Assets_Mutation_Response>>>;
  /** update data of the table: "auction_bids" */
  update_auction_bids?: Maybe<Auction_Bids_Mutation_Response>;
  /** update single row of the table: "auction_bids" */
  update_auction_bids_by_pk?: Maybe<Auction_Bids>;
  /** update multiples rows of table: "auction_bids" */
  update_auction_bids_many?: Maybe<Array<Maybe<Auction_Bids_Mutation_Response>>>;
  /** update data of the table: "auctions" */
  update_auctions?: Maybe<Auctions_Mutation_Response>;
  /** update single row of the table: "auctions" */
  update_auctions_by_pk?: Maybe<Auctions>;
  /** update multiples rows of table: "auctions" */
  update_auctions_many?: Maybe<Array<Maybe<Auctions_Mutation_Response>>>;
  /** update data of the table: "calculator_submission" */
  update_calculator_submission?: Maybe<Calculator_Submission_Mutation_Response>;
  /** update single row of the table: "calculator_submission" */
  update_calculator_submission_by_pk?: Maybe<Calculator_Submission>;
  /** update multiples rows of table: "calculator_submission" */
  update_calculator_submission_many?: Maybe<Array<Maybe<Calculator_Submission_Mutation_Response>>>;
  /** update data of the table: "custom_field_values" */
  update_custom_field_values?: Maybe<Custom_Field_Values_Mutation_Response>;
  /** update single row of the table: "custom_field_values" */
  update_custom_field_values_by_pk?: Maybe<Custom_Field_Values>;
  /** update multiples rows of table: "custom_field_values" */
  update_custom_field_values_many?: Maybe<Array<Maybe<Custom_Field_Values_Mutation_Response>>>;
  /** update data of the table: "custom_fields" */
  update_custom_fields?: Maybe<Custom_Fields_Mutation_Response>;
  /** update single row of the table: "custom_fields" */
  update_custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** update multiples rows of table: "custom_fields" */
  update_custom_fields_many?: Maybe<Array<Maybe<Custom_Fields_Mutation_Response>>>;
  /** update data of the table: "custom_vehicles" */
  update_custom_vehicles?: Maybe<Custom_Vehicles_Mutation_Response>;
  /** update single row of the table: "custom_vehicles" */
  update_custom_vehicles_by_pk?: Maybe<Custom_Vehicles>;
  /** update multiples rows of table: "custom_vehicles" */
  update_custom_vehicles_many?: Maybe<Array<Maybe<Custom_Vehicles_Mutation_Response>>>;
  /** update data of the table: "filter_fields" */
  update_filter_fields?: Maybe<Filter_Fields_Mutation_Response>;
  /** update single row of the table: "filter_fields" */
  update_filter_fields_by_pk?: Maybe<Filter_Fields>;
  /** update multiples rows of table: "filter_fields" */
  update_filter_fields_many?: Maybe<Array<Maybe<Filter_Fields_Mutation_Response>>>;
  /** update data of the table: "filter_locations" */
  update_filter_locations?: Maybe<Filter_Locations_Mutation_Response>;
  /** update single row of the table: "filter_locations" */
  update_filter_locations_by_pk?: Maybe<Filter_Locations>;
  /** update multiples rows of table: "filter_locations" */
  update_filter_locations_many?: Maybe<Array<Maybe<Filter_Locations_Mutation_Response>>>;
  /** update data of the table: "filter_names" */
  update_filter_names?: Maybe<Filter_Names_Mutation_Response>;
  /** update single row of the table: "filter_names" */
  update_filter_names_by_pk?: Maybe<Filter_Names>;
  /** update multiples rows of table: "filter_names" */
  update_filter_names_many?: Maybe<Array<Maybe<Filter_Names_Mutation_Response>>>;
  /** update data of the table: "finance_applications" */
  update_finance_applications?: Maybe<Finance_Applications_Mutation_Response>;
  /** update single row of the table: "finance_applications" */
  update_finance_applications_by_pk?: Maybe<Finance_Applications>;
  /** update multiples rows of table: "finance_applications" */
  update_finance_applications_many?: Maybe<Array<Maybe<Finance_Applications_Mutation_Response>>>;
  /** update data of the table: "finance_fees" */
  update_finance_fees?: Maybe<Finance_Fees_Mutation_Response>;
  /** update single row of the table: "finance_fees" */
  update_finance_fees_by_pk?: Maybe<Finance_Fees>;
  /** update multiples rows of table: "finance_fees" */
  update_finance_fees_many?: Maybe<Array<Maybe<Finance_Fees_Mutation_Response>>>;
  /** update data of the table: "finance_fixed_rate_configurations" */
  update_finance_fixed_rate_configurations?: Maybe<Finance_Fixed_Rate_Configurations_Mutation_Response>;
  /** update single row of the table: "finance_fixed_rate_configurations" */
  update_finance_fixed_rate_configurations_by_pk?: Maybe<Finance_Fixed_Rate_Configurations>;
  /** update multiples rows of table: "finance_fixed_rate_configurations" */
  update_finance_fixed_rate_configurations_many?: Maybe<Array<Maybe<Finance_Fixed_Rate_Configurations_Mutation_Response>>>;
  /** update data of the table: "finance_frequency" */
  update_finance_frequency?: Maybe<Finance_Frequency_Mutation_Response>;
  /** update single row of the table: "finance_frequency" */
  update_finance_frequency_by_pk?: Maybe<Finance_Frequency>;
  /** update multiples rows of table: "finance_frequency" */
  update_finance_frequency_many?: Maybe<Array<Maybe<Finance_Frequency_Mutation_Response>>>;
  /** update data of the table: "finance_gfv_configurations" */
  update_finance_gfv_configurations?: Maybe<Finance_Gfv_Configurations_Mutation_Response>;
  /** update single row of the table: "finance_gfv_configurations" */
  update_finance_gfv_configurations_by_pk?: Maybe<Finance_Gfv_Configurations>;
  /** update multiples rows of table: "finance_gfv_configurations" */
  update_finance_gfv_configurations_many?: Maybe<Array<Maybe<Finance_Gfv_Configurations_Mutation_Response>>>;
  /** update data of the table: "finance_gfv_residual_values" */
  update_finance_gfv_residual_values?: Maybe<Finance_Gfv_Residual_Values_Mutation_Response>;
  /** update single row of the table: "finance_gfv_residual_values" */
  update_finance_gfv_residual_values_by_pk?: Maybe<Finance_Gfv_Residual_Values>;
  /** update multiples rows of table: "finance_gfv_residual_values" */
  update_finance_gfv_residual_values_many?: Maybe<Array<Maybe<Finance_Gfv_Residual_Values_Mutation_Response>>>;
  /** update data of the table: "finance_product_offers" */
  update_finance_product_offers?: Maybe<Finance_Product_Offers_Mutation_Response>;
  /** update single row of the table: "finance_product_offers" */
  update_finance_product_offers_by_pk?: Maybe<Finance_Product_Offers>;
  /** update multiples rows of table: "finance_product_offers" */
  update_finance_product_offers_many?: Maybe<Array<Maybe<Finance_Product_Offers_Mutation_Response>>>;
  /** update data of the table: "finance_product_types" */
  update_finance_product_types?: Maybe<Finance_Product_Types_Mutation_Response>;
  /** update single row of the table: "finance_product_types" */
  update_finance_product_types_by_pk?: Maybe<Finance_Product_Types>;
  /** update multiples rows of table: "finance_product_types" */
  update_finance_product_types_many?: Maybe<Array<Maybe<Finance_Product_Types_Mutation_Response>>>;
  /** update data of the table: "finance_products" */
  update_finance_products?: Maybe<Finance_Products_Mutation_Response>;
  /** update single row of the table: "finance_products" */
  update_finance_products_by_pk?: Maybe<Finance_Products>;
  /** update multiples rows of table: "finance_products" */
  update_finance_products_many?: Maybe<Array<Maybe<Finance_Products_Mutation_Response>>>;
  /** update data of the table: "finance_terms" */
  update_finance_terms?: Maybe<Finance_Terms_Mutation_Response>;
  /** update single row of the table: "finance_terms" */
  update_finance_terms_by_pk?: Maybe<Finance_Terms>;
  /** update multiples rows of table: "finance_terms" */
  update_finance_terms_many?: Maybe<Array<Maybe<Finance_Terms_Mutation_Response>>>;
  /** update data of the table: "form_fields" */
  update_form_fields?: Maybe<Form_Fields_Mutation_Response>;
  /** update single row of the table: "form_fields" */
  update_form_fields_by_pk?: Maybe<Form_Fields>;
  /** update multiples rows of table: "form_fields" */
  update_form_fields_many?: Maybe<Array<Maybe<Form_Fields_Mutation_Response>>>;
  /** update data of the table: "form_submissions" */
  update_form_submissions?: Maybe<Form_Submissions_Mutation_Response>;
  /** update single row of the table: "form_submissions" */
  update_form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** update multiples rows of table: "form_submissions" */
  update_form_submissions_many?: Maybe<Array<Maybe<Form_Submissions_Mutation_Response>>>;
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>;
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>;
  /** update multiples rows of table: "forms" */
  update_forms_many?: Maybe<Array<Maybe<Forms_Mutation_Response>>>;
  /** update data of the table: "insights_click_events" */
  update_insights_click_events?: Maybe<Insights_Click_Events_Mutation_Response>;
  /** update single row of the table: "insights_click_events" */
  update_insights_click_events_by_pk?: Maybe<Insights_Click_Events>;
  /** update multiples rows of table: "insights_click_events" */
  update_insights_click_events_many?: Maybe<Array<Maybe<Insights_Click_Events_Mutation_Response>>>;
  /** update data of the table: "insights_devices" */
  update_insights_devices?: Maybe<Insights_Devices_Mutation_Response>;
  /** update single row of the table: "insights_devices" */
  update_insights_devices_by_pk?: Maybe<Insights_Devices>;
  /** update multiples rows of table: "insights_devices" */
  update_insights_devices_many?: Maybe<Array<Maybe<Insights_Devices_Mutation_Response>>>;
  /** update data of the table: "insights_event_types" */
  update_insights_event_types?: Maybe<Insights_Event_Types_Mutation_Response>;
  /** update single row of the table: "insights_event_types" */
  update_insights_event_types_by_pk?: Maybe<Insights_Event_Types>;
  /** update multiples rows of table: "insights_event_types" */
  update_insights_event_types_many?: Maybe<Array<Maybe<Insights_Event_Types_Mutation_Response>>>;
  /** update data of the table: "insights_events" */
  update_insights_events?: Maybe<Insights_Events_Mutation_Response>;
  /** update single row of the table: "insights_events" */
  update_insights_events_by_pk?: Maybe<Insights_Events>;
  /** update multiples rows of table: "insights_events" */
  update_insights_events_many?: Maybe<Array<Maybe<Insights_Events_Mutation_Response>>>;
  /** update data of the table: "insights_page_view_events" */
  update_insights_page_view_events?: Maybe<Insights_Page_View_Events_Mutation_Response>;
  /** update single row of the table: "insights_page_view_events" */
  update_insights_page_view_events_by_pk?: Maybe<Insights_Page_View_Events>;
  /** update multiples rows of table: "insights_page_view_events" */
  update_insights_page_view_events_many?: Maybe<Array<Maybe<Insights_Page_View_Events_Mutation_Response>>>;
  /** update data of the table: "insights_sessions" */
  update_insights_sessions?: Maybe<Insights_Sessions_Mutation_Response>;
  /** update single row of the table: "insights_sessions" */
  update_insights_sessions_by_pk?: Maybe<Insights_Sessions>;
  /** update multiples rows of table: "insights_sessions" */
  update_insights_sessions_many?: Maybe<Array<Maybe<Insights_Sessions_Mutation_Response>>>;
  /** update data of the table: "insights_visitors" */
  update_insights_visitors?: Maybe<Insights_Visitors_Mutation_Response>;
  /** update single row of the table: "insights_visitors" */
  update_insights_visitors_by_pk?: Maybe<Insights_Visitors>;
  /** update multiples rows of table: "insights_visitors" */
  update_insights_visitors_many?: Maybe<Array<Maybe<Insights_Visitors_Mutation_Response>>>;
  /** update data of the table: "interests" */
  update_interests?: Maybe<Interests_Mutation_Response>;
  /** update single row of the table: "interests" */
  update_interests_by_pk?: Maybe<Interests>;
  /** update multiples rows of table: "interests" */
  update_interests_many?: Maybe<Array<Maybe<Interests_Mutation_Response>>>;
  /** update data of the table: "known_vehicles" */
  update_known_vehicles?: Maybe<Known_Vehicles_Mutation_Response>;
  /** update single row of the table: "known_vehicles" */
  update_known_vehicles_by_pk?: Maybe<Known_Vehicles>;
  /** update multiples rows of table: "known_vehicles" */
  update_known_vehicles_many?: Maybe<Array<Maybe<Known_Vehicles_Mutation_Response>>>;
  /** update data of the table: "locales" */
  update_locales?: Maybe<Locales_Mutation_Response>;
  /** update single row of the table: "locales" */
  update_locales_by_pk?: Maybe<Locales>;
  /** update multiples rows of table: "locales" */
  update_locales_many?: Maybe<Array<Maybe<Locales_Mutation_Response>>>;
  /** update data of the table: "location_service_types" */
  update_location_service_types?: Maybe<Location_Service_Types_Mutation_Response>;
  /** update single row of the table: "location_service_types" */
  update_location_service_types_by_pk?: Maybe<Location_Service_Types>;
  /** update multiples rows of table: "location_service_types" */
  update_location_service_types_many?: Maybe<Array<Maybe<Location_Service_Types_Mutation_Response>>>;
  /** update data of the table: "location_transport_types" */
  update_location_transport_types?: Maybe<Location_Transport_Types_Mutation_Response>;
  /** update single row of the table: "location_transport_types" */
  update_location_transport_types_by_pk?: Maybe<Location_Transport_Types>;
  /** update multiples rows of table: "location_transport_types" */
  update_location_transport_types_many?: Maybe<Array<Maybe<Location_Transport_Types_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "match_submissions" */
  update_match_submissions?: Maybe<Match_Submissions_Mutation_Response>;
  /** update single row of the table: "match_submissions" */
  update_match_submissions_by_pk?: Maybe<Match_Submissions>;
  /** update multiples rows of table: "match_submissions" */
  update_match_submissions_many?: Maybe<Array<Maybe<Match_Submissions_Mutation_Response>>>;
  /** update data of the table: "moments" */
  update_moments?: Maybe<Moments_Mutation_Response>;
  /** update single row of the table: "moments" */
  update_moments_by_pk?: Maybe<Moments>;
  /** update multiples rows of table: "moments" */
  update_moments_many?: Maybe<Array<Maybe<Moments_Mutation_Response>>>;
  /** update data of the table: "notes" */
  update_notes?: Maybe<Notes_Mutation_Response>;
  /** update single row of the table: "notes" */
  update_notes_by_pk?: Maybe<Notes>;
  /** update multiples rows of table: "notes" */
  update_notes_many?: Maybe<Array<Maybe<Notes_Mutation_Response>>>;
  /** update data of the table: "offer_types" */
  update_offer_types?: Maybe<Offer_Types_Mutation_Response>;
  /** update single row of the table: "offer_types" */
  update_offer_types_by_pk?: Maybe<Offer_Types>;
  /** update multiples rows of table: "offer_types" */
  update_offer_types_many?: Maybe<Array<Maybe<Offer_Types_Mutation_Response>>>;
  /** update data of the table: "offers" */
  update_offers?: Maybe<Offers_Mutation_Response>;
  /** update single row of the table: "offers" */
  update_offers_by_pk?: Maybe<Offers>;
  /** update multiples rows of table: "offers" */
  update_offers_many?: Maybe<Array<Maybe<Offers_Mutation_Response>>>;
  /** update data of the table: "pages" */
  update_pages?: Maybe<Pages_Mutation_Response>;
  /** update single row of the table: "pages" */
  update_pages_by_pk?: Maybe<Pages>;
  /** update multiples rows of table: "pages" */
  update_pages_many?: Maybe<Array<Maybe<Pages_Mutation_Response>>>;
  /** update data of the table: "partners" */
  update_partners?: Maybe<Partners_Mutation_Response>;
  /** update single row of the table: "partners" */
  update_partners_by_pk?: Maybe<Partners>;
  /** update multiples rows of table: "partners" */
  update_partners_many?: Maybe<Array<Maybe<Partners_Mutation_Response>>>;
  /** update data of the table: "payment_codes" */
  update_payment_codes?: Maybe<Payment_Codes_Mutation_Response>;
  /** update single row of the table: "payment_codes" */
  update_payment_codes_by_pk?: Maybe<Payment_Codes>;
  /** update multiples rows of table: "payment_codes" */
  update_payment_codes_many?: Maybe<Array<Maybe<Payment_Codes_Mutation_Response>>>;
  /** update data of the table: "payment_method_types" */
  update_payment_method_types?: Maybe<Payment_Method_Types_Mutation_Response>;
  /** update single row of the table: "payment_method_types" */
  update_payment_method_types_by_pk?: Maybe<Payment_Method_Types>;
  /** update multiples rows of table: "payment_method_types" */
  update_payment_method_types_many?: Maybe<Array<Maybe<Payment_Method_Types_Mutation_Response>>>;
  /** update data of the table: "payment_methods" */
  update_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** update single row of the table: "payment_methods" */
  update_payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** update multiples rows of table: "payment_methods" */
  update_payment_methods_many?: Maybe<Array<Maybe<Payment_Methods_Mutation_Response>>>;
  /** update data of the table: "payments" */
  update_payments?: Maybe<Payments_Mutation_Response>;
  /** update single row of the table: "payments" */
  update_payments_by_pk?: Maybe<Payments>;
  /** update multiples rows of table: "payments" */
  update_payments_many?: Maybe<Array<Maybe<Payments_Mutation_Response>>>;
  /** update data of the table: "personal_vehicles" */
  update_personal_vehicles?: Maybe<Personal_Vehicles_Mutation_Response>;
  /** update single row of the table: "personal_vehicles" */
  update_personal_vehicles_by_pk?: Maybe<Personal_Vehicles>;
  /** update multiples rows of table: "personal_vehicles" */
  update_personal_vehicles_many?: Maybe<Array<Maybe<Personal_Vehicles_Mutation_Response>>>;
  /** update data of the table: "pricing_codes" */
  update_pricing_codes?: Maybe<Pricing_Codes_Mutation_Response>;
  /** update single row of the table: "pricing_codes" */
  update_pricing_codes_by_pk?: Maybe<Pricing_Codes>;
  /** update multiples rows of table: "pricing_codes" */
  update_pricing_codes_many?: Maybe<Array<Maybe<Pricing_Codes_Mutation_Response>>>;
  /** update data of the table: "product_groups" */
  update_product_groups?: Maybe<Product_Groups_Mutation_Response>;
  /** update single row of the table: "product_groups" */
  update_product_groups_by_pk?: Maybe<Product_Groups>;
  /** update multiples rows of table: "product_groups" */
  update_product_groups_many?: Maybe<Array<Maybe<Product_Groups_Mutation_Response>>>;
  /** update data of the table: "product_variants" */
  update_product_variants?: Maybe<Product_Variants_Mutation_Response>;
  /** update single row of the table: "product_variants" */
  update_product_variants_by_pk?: Maybe<Product_Variants>;
  /** update multiples rows of table: "product_variants" */
  update_product_variants_many?: Maybe<Array<Maybe<Product_Variants_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "promotions" */
  update_promotions?: Maybe<Promotions_Mutation_Response>;
  /** update single row of the table: "promotions" */
  update_promotions_by_pk?: Maybe<Promotions>;
  /** update multiples rows of table: "promotions" */
  update_promotions_many?: Maybe<Array<Maybe<Promotions_Mutation_Response>>>;
  /** update data of the table: "redirects" */
  update_redirects?: Maybe<Redirects_Mutation_Response>;
  /** update single row of the table: "redirects" */
  update_redirects_by_pk?: Maybe<Redirects>;
  /** update multiples rows of table: "redirects" */
  update_redirects_many?: Maybe<Array<Maybe<Redirects_Mutation_Response>>>;
  /** update data of the table: "rental_drivers" */
  update_rental_drivers?: Maybe<Rental_Drivers_Mutation_Response>;
  /** update single row of the table: "rental_drivers" */
  update_rental_drivers_by_pk?: Maybe<Rental_Drivers>;
  /** update multiples rows of table: "rental_drivers" */
  update_rental_drivers_many?: Maybe<Array<Maybe<Rental_Drivers_Mutation_Response>>>;
  /** update data of the table: "rental_reservation_status" */
  update_rental_reservation_status?: Maybe<Rental_Reservation_Status_Mutation_Response>;
  /** update single row of the table: "rental_reservation_status" */
  update_rental_reservation_status_by_pk?: Maybe<Rental_Reservation_Status>;
  /** update multiples rows of table: "rental_reservation_status" */
  update_rental_reservation_status_many?: Maybe<Array<Maybe<Rental_Reservation_Status_Mutation_Response>>>;
  /** update data of the table: "rental_reservations" */
  update_rental_reservations?: Maybe<Rental_Reservations_Mutation_Response>;
  /** update single row of the table: "rental_reservations" */
  update_rental_reservations_by_pk?: Maybe<Rental_Reservations>;
  /** update multiples rows of table: "rental_reservations" */
  update_rental_reservations_many?: Maybe<Array<Maybe<Rental_Reservations_Mutation_Response>>>;
  /** update data of the table: "service_booking_service_types" */
  update_service_booking_service_types?: Maybe<Service_Booking_Service_Types_Mutation_Response>;
  /** update single row of the table: "service_booking_service_types" */
  update_service_booking_service_types_by_pk?: Maybe<Service_Booking_Service_Types>;
  /** update multiples rows of table: "service_booking_service_types" */
  update_service_booking_service_types_many?: Maybe<Array<Maybe<Service_Booking_Service_Types_Mutation_Response>>>;
  /** update data of the table: "service_bookings" */
  update_service_bookings?: Maybe<Service_Bookings_Mutation_Response>;
  /** update single row of the table: "service_bookings" */
  update_service_bookings_by_pk?: Maybe<Service_Bookings>;
  /** update multiples rows of table: "service_bookings" */
  update_service_bookings_many?: Maybe<Array<Maybe<Service_Bookings_Mutation_Response>>>;
  /** update data of the table: "service_types" */
  update_service_types?: Maybe<Service_Types_Mutation_Response>;
  /** update single row of the table: "service_types" */
  update_service_types_by_pk?: Maybe<Service_Types>;
  /** update multiples rows of table: "service_types" */
  update_service_types_many?: Maybe<Array<Maybe<Service_Types_Mutation_Response>>>;
  /** update data of the table: "sessions" */
  update_sessions?: Maybe<Sessions_Mutation_Response>;
  /** update single row of the table: "sessions" */
  update_sessions_by_pk?: Maybe<Sessions>;
  /** update multiples rows of table: "sessions" */
  update_sessions_many?: Maybe<Array<Maybe<Sessions_Mutation_Response>>>;
  /** update data of the table: "showroom_apps" */
  update_showroom_apps?: Maybe<Showroom_Apps_Mutation_Response>;
  /** update single row of the table: "showroom_apps" */
  update_showroom_apps_by_pk?: Maybe<Showroom_Apps>;
  /** update data of the table: "showroom_apps_inputs" */
  update_showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Mutation_Response>;
  /** update single row of the table: "showroom_apps_inputs" */
  update_showroom_apps_inputs_by_pk?: Maybe<Showroom_Apps_Inputs>;
  /** update multiples rows of table: "showroom_apps_inputs" */
  update_showroom_apps_inputs_many?: Maybe<Array<Maybe<Showroom_Apps_Inputs_Mutation_Response>>>;
  /** update multiples rows of table: "showroom_apps" */
  update_showroom_apps_many?: Maybe<Array<Maybe<Showroom_Apps_Mutation_Response>>>;
  /** update data of the table: "showroom_locales" */
  update_showroom_locales?: Maybe<Showroom_Locales_Mutation_Response>;
  /** update single row of the table: "showroom_locales" */
  update_showroom_locales_by_pk?: Maybe<Showroom_Locales>;
  /** update multiples rows of table: "showroom_locales" */
  update_showroom_locales_many?: Maybe<Array<Maybe<Showroom_Locales_Mutation_Response>>>;
  /** update data of the table: "showroom_pricing_codes" */
  update_showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Mutation_Response>;
  /** update single row of the table: "showroom_pricing_codes" */
  update_showroom_pricing_codes_by_pk?: Maybe<Showroom_Pricing_Codes>;
  /** update multiples rows of table: "showroom_pricing_codes" */
  update_showroom_pricing_codes_many?: Maybe<Array<Maybe<Showroom_Pricing_Codes_Mutation_Response>>>;
  /** update data of the table: "showroom_stocked_vehicles" */
  update_showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Mutation_Response>;
  /** update single row of the table: "showroom_stocked_vehicles" */
  update_showroom_stocked_vehicles_by_pk?: Maybe<Showroom_Stocked_Vehicles>;
  /** update multiples rows of table: "showroom_stocked_vehicles" */
  update_showroom_stocked_vehicles_many?: Maybe<Array<Maybe<Showroom_Stocked_Vehicles_Mutation_Response>>>;
  /** update data of the table: "showroom_users" */
  update_showroom_users?: Maybe<Showroom_Users_Mutation_Response>;
  /** update single row of the table: "showroom_users" */
  update_showroom_users_by_pk?: Maybe<Showroom_Users>;
  /** update multiples rows of table: "showroom_users" */
  update_showroom_users_many?: Maybe<Array<Maybe<Showroom_Users_Mutation_Response>>>;
  /** update data of the table: "showrooms" */
  update_showrooms?: Maybe<Showrooms_Mutation_Response>;
  /** update single row of the table: "showrooms" */
  update_showrooms_by_pk?: Maybe<Showrooms>;
  /** update multiples rows of table: "showrooms" */
  update_showrooms_many?: Maybe<Array<Maybe<Showrooms_Mutation_Response>>>;
  /** update data of the table: "showrooms_partners" */
  update_showrooms_partners?: Maybe<Showrooms_Partners_Mutation_Response>;
  /** update single row of the table: "showrooms_partners" */
  update_showrooms_partners_by_pk?: Maybe<Showrooms_Partners>;
  /** update multiples rows of table: "showrooms_partners" */
  update_showrooms_partners_many?: Maybe<Array<Maybe<Showrooms_Partners_Mutation_Response>>>;
  /** update data of the table: "showrooms_promotions" */
  update_showrooms_promotions?: Maybe<Showrooms_Promotions_Mutation_Response>;
  /** update single row of the table: "showrooms_promotions" */
  update_showrooms_promotions_by_pk?: Maybe<Showrooms_Promotions>;
  /** update multiples rows of table: "showrooms_promotions" */
  update_showrooms_promotions_many?: Maybe<Array<Maybe<Showrooms_Promotions_Mutation_Response>>>;
  /** update data of the table: "stocked_vehicle_finance_products" */
  update_stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Mutation_Response>;
  /** update single row of the table: "stocked_vehicle_finance_products" */
  update_stocked_vehicle_finance_products_by_pk?: Maybe<Stocked_Vehicle_Finance_Products>;
  /** update multiples rows of table: "stocked_vehicle_finance_products" */
  update_stocked_vehicle_finance_products_many?: Maybe<Array<Maybe<Stocked_Vehicle_Finance_Products_Mutation_Response>>>;
  /** update data of the table: "stocked_vehicle_handover_locations" */
  update_stocked_vehicle_handover_locations?: Maybe<Stocked_Vehicle_Handover_Locations_Mutation_Response>;
  /** update single row of the table: "stocked_vehicle_handover_locations" */
  update_stocked_vehicle_handover_locations_by_pk?: Maybe<Stocked_Vehicle_Handover_Locations>;
  /** update multiples rows of table: "stocked_vehicle_handover_locations" */
  update_stocked_vehicle_handover_locations_many?: Maybe<Array<Maybe<Stocked_Vehicle_Handover_Locations_Mutation_Response>>>;
  /** update data of the table: "stocked_vehicle_offers" */
  update_stocked_vehicle_offers?: Maybe<Stocked_Vehicle_Offers_Mutation_Response>;
  /** update single row of the table: "stocked_vehicle_offers" */
  update_stocked_vehicle_offers_by_pk?: Maybe<Stocked_Vehicle_Offers>;
  /** update multiples rows of table: "stocked_vehicle_offers" */
  update_stocked_vehicle_offers_many?: Maybe<Array<Maybe<Stocked_Vehicle_Offers_Mutation_Response>>>;
  /** update data of the table: "stocked_vehicles" */
  update_stocked_vehicles?: Maybe<Stocked_Vehicles_Mutation_Response>;
  /** update single row of the table: "stocked_vehicles" */
  update_stocked_vehicles_by_pk?: Maybe<Stocked_Vehicles>;
  /** update multiples rows of table: "stocked_vehicles" */
  update_stocked_vehicles_many?: Maybe<Array<Maybe<Stocked_Vehicles_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "test_drives" */
  update_test_drives?: Maybe<Test_Drives_Mutation_Response>;
  /** update single row of the table: "test_drives" */
  update_test_drives_by_pk?: Maybe<Test_Drives>;
  /** update multiples rows of table: "test_drives" */
  update_test_drives_many?: Maybe<Array<Maybe<Test_Drives_Mutation_Response>>>;
  /** update data of the table: "trade_in_plans" */
  update_trade_in_plans?: Maybe<Trade_In_Plans_Mutation_Response>;
  /** update single row of the table: "trade_in_plans" */
  update_trade_in_plans_by_pk?: Maybe<Trade_In_Plans>;
  /** update multiples rows of table: "trade_in_plans" */
  update_trade_in_plans_many?: Maybe<Array<Maybe<Trade_In_Plans_Mutation_Response>>>;
  /** update data of the table: "trade_in_valuations" */
  update_trade_in_valuations?: Maybe<Trade_In_Valuations_Mutation_Response>;
  /** update single row of the table: "trade_in_valuations" */
  update_trade_in_valuations_by_pk?: Maybe<Trade_In_Valuations>;
  /** update multiples rows of table: "trade_in_valuations" */
  update_trade_in_valuations_many?: Maybe<Array<Maybe<Trade_In_Valuations_Mutation_Response>>>;
  /** update data of the table: "transport_types" */
  update_transport_types?: Maybe<Transport_Types_Mutation_Response>;
  /** update single row of the table: "transport_types" */
  update_transport_types_by_pk?: Maybe<Transport_Types>;
  /** update multiples rows of table: "transport_types" */
  update_transport_types_many?: Maybe<Array<Maybe<Transport_Types_Mutation_Response>>>;
  /** update data of the table: "user_interests" */
  update_user_interests?: Maybe<User_Interests_Mutation_Response>;
  /** update single row of the table: "user_interests" */
  update_user_interests_by_pk?: Maybe<User_Interests>;
  /** update multiples rows of table: "user_interests" */
  update_user_interests_many?: Maybe<Array<Maybe<User_Interests_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "valuers" */
  update_valuers?: Maybe<Valuers_Mutation_Response>;
  /** update single row of the table: "valuers" */
  update_valuers_by_pk?: Maybe<Valuers>;
  /** update multiples rows of table: "valuers" */
  update_valuers_many?: Maybe<Array<Maybe<Valuers_Mutation_Response>>>;
  /** update data of the table: "vehicle_conditions" */
  update_vehicle_conditions?: Maybe<Vehicle_Conditions_Mutation_Response>;
  /** update single row of the table: "vehicle_conditions" */
  update_vehicle_conditions_by_pk?: Maybe<Vehicle_Conditions>;
  /** update multiples rows of table: "vehicle_conditions" */
  update_vehicle_conditions_many?: Maybe<Array<Maybe<Vehicle_Conditions_Mutation_Response>>>;
  /** update data of the table: "vehicle_lists" */
  update_vehicle_lists?: Maybe<Vehicle_Lists_Mutation_Response>;
  /** update single row of the table: "vehicle_lists" */
  update_vehicle_lists_by_pk?: Maybe<Vehicle_Lists>;
  /** update multiples rows of table: "vehicle_lists" */
  update_vehicle_lists_many?: Maybe<Array<Maybe<Vehicle_Lists_Mutation_Response>>>;
  /** update data of the table: "vehicle_order_activity" */
  update_vehicle_order_activity?: Maybe<Vehicle_Order_Activity_Mutation_Response>;
  /** update single row of the table: "vehicle_order_activity" */
  update_vehicle_order_activity_by_pk?: Maybe<Vehicle_Order_Activity>;
  /** update multiples rows of table: "vehicle_order_activity" */
  update_vehicle_order_activity_many?: Maybe<Array<Maybe<Vehicle_Order_Activity_Mutation_Response>>>;
  /** update data of the table: "vehicle_order_product_variants" */
  update_vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Mutation_Response>;
  /** update single row of the table: "vehicle_order_product_variants" */
  update_vehicle_order_product_variants_by_pk?: Maybe<Vehicle_Order_Product_Variants>;
  /** update multiples rows of table: "vehicle_order_product_variants" */
  update_vehicle_order_product_variants_many?: Maybe<Array<Maybe<Vehicle_Order_Product_Variants_Mutation_Response>>>;
  /** update data of the table: "vehicle_orders" */
  update_vehicle_orders?: Maybe<Vehicle_Orders_Mutation_Response>;
  /** update single row of the table: "vehicle_orders" */
  update_vehicle_orders_by_pk?: Maybe<Vehicle_Orders>;
  /** update multiples rows of table: "vehicle_orders" */
  update_vehicle_orders_many?: Maybe<Array<Maybe<Vehicle_Orders_Mutation_Response>>>;
  /** update data of the table: "vehicle_specifications" */
  update_vehicle_specifications?: Maybe<Vehicle_Specifications_Mutation_Response>;
  /** update single row of the table: "vehicle_specifications" */
  update_vehicle_specifications_by_pk?: Maybe<Vehicle_Specifications>;
  /** update multiples rows of table: "vehicle_specifications" */
  update_vehicle_specifications_many?: Maybe<Array<Maybe<Vehicle_Specifications_Mutation_Response>>>;
  /** update data of the table: "wish_list" */
  update_wish_list?: Maybe<Wish_List_Mutation_Response>;
  /** update single row of the table: "wish_list" */
  update_wish_list_by_pk?: Maybe<Wish_List>;
  /** update multiples rows of table: "wish_list" */
  update_wish_list_many?: Maybe<Array<Maybe<Wish_List_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCancel_Vehicle_OrderArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ActivitiesArgs = {
  where: Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Api_ClientsArgs = {
  where: Api_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Api_Clients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_App_ActionsArgs = {
  where: App_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_App_EventsArgs = {
  where: App_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_App_InputsArgs = {
  where: App_Inputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Inputs_By_PkArgs = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  version_num: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_App_VersionsArgs = {
  where: App_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Versions_By_PkArgs = {
  app_id: Scalars['uuid'];
  version_num: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AppsArgs = {
  where: Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ArticlesArgs = {
  where: Articles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Articles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AssetsArgs = {
  where: Assets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assets_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auction_BidsArgs = {
  where: Auction_Bids_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auction_Bids_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AuctionsArgs = {
  where: Auctions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auctions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Calculator_SubmissionArgs = {
  where: Calculator_Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calculator_Submission_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Custom_Field_ValuesArgs = {
  where: Custom_Field_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Custom_Field_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Custom_FieldsArgs = {
  where: Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Custom_Fields_By_PkArgs = {
  entity: Scalars['String'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Custom_VehiclesArgs = {
  where: Custom_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Custom_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Filter_FieldsArgs = {
  where: Filter_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Filter_LocationsArgs = {
  where: Filter_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_Locations_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Filter_NamesArgs = {
  where: Filter_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_Names_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_ApplicationsArgs = {
  where: Finance_Applications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Applications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_FeesArgs = {
  where: Finance_Fees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Fees_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_Fixed_Rate_ConfigurationsArgs = {
  where: Finance_Fixed_Rate_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Fixed_Rate_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_FrequencyArgs = {
  where: Finance_Frequency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Frequency_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_Gfv_ConfigurationsArgs = {
  where: Finance_Gfv_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Gfv_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_Gfv_Residual_ValuesArgs = {
  where: Finance_Gfv_Residual_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Gfv_Residual_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_Product_OffersArgs = {
  where: Finance_Product_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Product_Offers_By_PkArgs = {
  finance_product_id: Scalars['uuid'];
  offer_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_Product_TypesArgs = {
  where: Finance_Product_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Product_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_ProductsArgs = {
  where: Finance_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Finance_TermsArgs = {
  where: Finance_Terms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Finance_Terms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_FieldsArgs = {
  where: Form_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Fields_By_PkArgs = {
  form_id: Scalars['uuid'];
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_SubmissionsArgs = {
  where: Form_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_Click_EventsArgs = {
  where: Insights_Click_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Click_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_DevicesArgs = {
  where: Insights_Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Devices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_Event_TypesArgs = {
  where: Insights_Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Event_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_EventsArgs = {
  where: Insights_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_Page_View_EventsArgs = {
  where: Insights_Page_View_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Page_View_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_SessionsArgs = {
  where: Insights_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insights_VisitorsArgs = {
  where: Insights_Visitors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insights_Visitors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InterestsArgs = {
  where: Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Interests_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Known_VehiclesArgs = {
  where: Known_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Known_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocalesArgs = {
  where: Locales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locales_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Location_Service_TypesArgs = {
  where: Location_Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Location_Service_Types_By_PkArgs = {
  location_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Location_Transport_TypesArgs = {
  where: Location_Transport_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Location_Transport_Types_By_PkArgs = {
  location_id: Scalars['uuid'];
  transport_type_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Match_SubmissionsArgs = {
  where: Match_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Match_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MomentsArgs = {
  where: Moments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Moments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotesArgs = {
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Offer_TypesArgs = {
  where: Offer_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Offer_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_OffersArgs = {
  where: Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Offers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PagesArgs = {
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PartnersArgs = {
  where: Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_CodesArgs = {
  where: Payment_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Codes_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Method_TypesArgs = {
  where: Payment_Method_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Method_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_MethodsArgs = {
  where: Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Methods_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentsArgs = {
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Personal_VehiclesArgs = {
  where: Personal_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Personal_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Pricing_CodesArgs = {
  where: Pricing_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pricing_Codes_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Product_GroupsArgs = {
  where: Product_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Product_VariantsArgs = {
  where: Product_Variants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Variants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PromotionsArgs = {
  where: Promotions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promotions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RedirectsArgs = {
  where: Redirects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Redirects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_DriversArgs = {
  where: Rental_Drivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Drivers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_Reservation_StatusArgs = {
  where: Rental_Reservation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Reservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_ReservationsArgs = {
  where: Rental_Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Service_Booking_Service_TypesArgs = {
  where: Service_Booking_Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Booking_Service_Types_By_PkArgs = {
  service_booking_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Service_BookingsArgs = {
  where: Service_Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Service_TypesArgs = {
  where: Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SessionsArgs = {
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_AppsArgs = {
  where: Showroom_Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Apps_By_PkArgs = {
  app_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Apps_InputsArgs = {
  where: Showroom_Apps_Inputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Apps_Inputs_By_PkArgs = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_LocalesArgs = {
  where: Showroom_Locales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Locales_By_PkArgs = {
  locale_code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Pricing_CodesArgs = {
  where: Showroom_Pricing_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Pricing_Codes_By_PkArgs = {
  code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Stocked_VehiclesArgs = {
  where: Showroom_Stocked_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Stocked_Vehicles_By_PkArgs = {
  showroom_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_UsersArgs = {
  where: Showroom_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Users_By_PkArgs = {
  showroom_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ShowroomsArgs = {
  where: Showrooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showrooms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showrooms_PartnersArgs = {
  where: Showrooms_Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showrooms_Partners_By_PkArgs = {
  partner_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Showrooms_PromotionsArgs = {
  where: Showrooms_Promotions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showrooms_Promotions_By_PkArgs = {
  promotion_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicle_Finance_ProductsArgs = {
  where: Stocked_Vehicle_Finance_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicle_Finance_Products_By_PkArgs = {
  finance_product_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicle_Handover_LocationsArgs = {
  where: Stocked_Vehicle_Handover_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicle_Handover_Locations_By_PkArgs = {
  location_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicle_OffersArgs = {
  where: Stocked_Vehicle_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicle_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stocked_VehiclesArgs = {
  where: Stocked_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocked_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Test_DrivesArgs = {
  where: Test_Drives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Drives_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Trade_In_PlansArgs = {
  where: Trade_In_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trade_In_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Trade_In_ValuationsArgs = {
  where: Trade_In_Valuations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trade_In_Valuations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Transport_TypesArgs = {
  where: Transport_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transport_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InterestsArgs = {
  where: User_Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Interests_By_PkArgs = {
  interest_code: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ValuersArgs = {
  where: Valuers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Valuers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_ConditionsArgs = {
  where: Vehicle_Conditions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Conditions_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_ListsArgs = {
  where: Vehicle_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Lists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Order_ActivityArgs = {
  where: Vehicle_Order_Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Order_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Order_Product_VariantsArgs = {
  where: Vehicle_Order_Product_Variants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Order_Product_Variants_By_PkArgs = {
  product_variant_id: Scalars['uuid'];
  vehicle_order_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_OrdersArgs = {
  where: Vehicle_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_SpecificationsArgs = {
  where: Vehicle_Specifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Specifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wish_ListArgs = {
  where: Wish_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wish_List_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGet_Autograb_ValuationArgs = {
  trade_in_plan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGet_Autograb_Vehicle_By_Reg_NumberArgs = {
  registration_number: Scalars['String'];
  state_of_registration?: Maybe<Scalars['String']>;
  trade_in_plan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGet_Holding_Deposit_Payment_UrlArgs = {
  vehicle_order_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGet_Loopit_Subscription_UrlArgs = {
  vehicleOrderId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_ActivitiesArgs = {
  objects: Array<Activities_Insert_Input>;
  on_conflict?: Maybe<Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activities_OneArgs = {
  object: Activities_Insert_Input;
  on_conflict?: Maybe<Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_ClientsArgs = {
  objects: Array<Api_Clients_Insert_Input>;
  on_conflict?: Maybe<Api_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_Clients_OneArgs = {
  object: Api_Clients_Insert_Input;
  on_conflict?: Maybe<Api_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_ActionsArgs = {
  objects: Array<App_Actions_Insert_Input>;
  on_conflict?: Maybe<App_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Actions_OneArgs = {
  object: App_Actions_Insert_Input;
  on_conflict?: Maybe<App_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_EventsArgs = {
  objects: Array<App_Events_Insert_Input>;
  on_conflict?: Maybe<App_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Events_OneArgs = {
  object: App_Events_Insert_Input;
  on_conflict?: Maybe<App_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_InputsArgs = {
  objects: Array<App_Inputs_Insert_Input>;
  on_conflict?: Maybe<App_Inputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Inputs_OneArgs = {
  object: App_Inputs_Insert_Input;
  on_conflict?: Maybe<App_Inputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_VersionsArgs = {
  objects: Array<App_Versions_Insert_Input>;
  on_conflict?: Maybe<App_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Versions_OneArgs = {
  object: App_Versions_Insert_Input;
  on_conflict?: Maybe<App_Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppsArgs = {
  objects: Array<Apps_Insert_Input>;
  on_conflict?: Maybe<Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_OneArgs = {
  object: Apps_Insert_Input;
  on_conflict?: Maybe<Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ArticlesArgs = {
  objects: Array<Articles_Insert_Input>;
  on_conflict?: Maybe<Articles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Articles_OneArgs = {
  object: Articles_Insert_Input;
  on_conflict?: Maybe<Articles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AssetsArgs = {
  objects: Array<Assets_Insert_Input>;
  on_conflict?: Maybe<Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assets_OneArgs = {
  object: Assets_Insert_Input;
  on_conflict?: Maybe<Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auction_BidsArgs = {
  objects: Array<Auction_Bids_Insert_Input>;
  on_conflict?: Maybe<Auction_Bids_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auction_Bids_OneArgs = {
  object: Auction_Bids_Insert_Input;
  on_conflict?: Maybe<Auction_Bids_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuctionsArgs = {
  objects: Array<Auctions_Insert_Input>;
  on_conflict?: Maybe<Auctions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auctions_OneArgs = {
  object: Auctions_Insert_Input;
  on_conflict?: Maybe<Auctions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calculator_SubmissionArgs = {
  objects: Array<Calculator_Submission_Insert_Input>;
  on_conflict?: Maybe<Calculator_Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calculator_Submission_OneArgs = {
  object: Calculator_Submission_Insert_Input;
  on_conflict?: Maybe<Calculator_Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Field_ValuesArgs = {
  objects: Array<Custom_Field_Values_Insert_Input>;
  on_conflict?: Maybe<Custom_Field_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Field_Values_OneArgs = {
  object: Custom_Field_Values_Insert_Input;
  on_conflict?: Maybe<Custom_Field_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_FieldsArgs = {
  objects: Array<Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Fields_OneArgs = {
  object: Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_VehiclesArgs = {
  objects: Array<Custom_Vehicles_Insert_Input>;
  on_conflict?: Maybe<Custom_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Vehicles_OneArgs = {
  object: Custom_Vehicles_Insert_Input;
  on_conflict?: Maybe<Custom_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_FieldsArgs = {
  objects: Array<Filter_Fields_Insert_Input>;
  on_conflict?: Maybe<Filter_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Fields_OneArgs = {
  object: Filter_Fields_Insert_Input;
  on_conflict?: Maybe<Filter_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_LocationsArgs = {
  objects: Array<Filter_Locations_Insert_Input>;
  on_conflict?: Maybe<Filter_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Locations_OneArgs = {
  object: Filter_Locations_Insert_Input;
  on_conflict?: Maybe<Filter_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_NamesArgs = {
  objects: Array<Filter_Names_Insert_Input>;
  on_conflict?: Maybe<Filter_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Names_OneArgs = {
  object: Filter_Names_Insert_Input;
  on_conflict?: Maybe<Filter_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_ApplicationsArgs = {
  objects: Array<Finance_Applications_Insert_Input>;
  on_conflict?: Maybe<Finance_Applications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Applications_OneArgs = {
  object: Finance_Applications_Insert_Input;
  on_conflict?: Maybe<Finance_Applications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_FeesArgs = {
  objects: Array<Finance_Fees_Insert_Input>;
  on_conflict?: Maybe<Finance_Fees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Fees_OneArgs = {
  object: Finance_Fees_Insert_Input;
  on_conflict?: Maybe<Finance_Fees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Fixed_Rate_ConfigurationsArgs = {
  objects: Array<Finance_Fixed_Rate_Configurations_Insert_Input>;
  on_conflict?: Maybe<Finance_Fixed_Rate_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Fixed_Rate_Configurations_OneArgs = {
  object: Finance_Fixed_Rate_Configurations_Insert_Input;
  on_conflict?: Maybe<Finance_Fixed_Rate_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_FrequencyArgs = {
  objects: Array<Finance_Frequency_Insert_Input>;
  on_conflict?: Maybe<Finance_Frequency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Frequency_OneArgs = {
  object: Finance_Frequency_Insert_Input;
  on_conflict?: Maybe<Finance_Frequency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Gfv_ConfigurationsArgs = {
  objects: Array<Finance_Gfv_Configurations_Insert_Input>;
  on_conflict?: Maybe<Finance_Gfv_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Gfv_Configurations_OneArgs = {
  object: Finance_Gfv_Configurations_Insert_Input;
  on_conflict?: Maybe<Finance_Gfv_Configurations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Gfv_Residual_ValuesArgs = {
  objects: Array<Finance_Gfv_Residual_Values_Insert_Input>;
  on_conflict?: Maybe<Finance_Gfv_Residual_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Gfv_Residual_Values_OneArgs = {
  object: Finance_Gfv_Residual_Values_Insert_Input;
  on_conflict?: Maybe<Finance_Gfv_Residual_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Product_OffersArgs = {
  objects: Array<Finance_Product_Offers_Insert_Input>;
  on_conflict?: Maybe<Finance_Product_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Product_Offers_OneArgs = {
  object: Finance_Product_Offers_Insert_Input;
  on_conflict?: Maybe<Finance_Product_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Product_TypesArgs = {
  objects: Array<Finance_Product_Types_Insert_Input>;
  on_conflict?: Maybe<Finance_Product_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Product_Types_OneArgs = {
  object: Finance_Product_Types_Insert_Input;
  on_conflict?: Maybe<Finance_Product_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_ProductsArgs = {
  objects: Array<Finance_Products_Insert_Input>;
  on_conflict?: Maybe<Finance_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Products_OneArgs = {
  object: Finance_Products_Insert_Input;
  on_conflict?: Maybe<Finance_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_TermsArgs = {
  objects: Array<Finance_Terms_Insert_Input>;
  on_conflict?: Maybe<Finance_Terms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Finance_Terms_OneArgs = {
  object: Finance_Terms_Insert_Input;
  on_conflict?: Maybe<Finance_Terms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_FieldsArgs = {
  objects: Array<Form_Fields_Insert_Input>;
  on_conflict?: Maybe<Form_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Fields_OneArgs = {
  object: Form_Fields_Insert_Input;
  on_conflict?: Maybe<Form_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_SubmissionsArgs = {
  objects: Array<Form_Submissions_Insert_Input>;
  on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Submissions_OneArgs = {
  object: Form_Submissions_Insert_Input;
  on_conflict?: Maybe<Form_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>;
  on_conflict?: Maybe<Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input;
  on_conflict?: Maybe<Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Click_EventsArgs = {
  objects: Array<Insights_Click_Events_Insert_Input>;
  on_conflict?: Maybe<Insights_Click_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Click_Events_OneArgs = {
  object: Insights_Click_Events_Insert_Input;
  on_conflict?: Maybe<Insights_Click_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_DevicesArgs = {
  objects: Array<Insights_Devices_Insert_Input>;
  on_conflict?: Maybe<Insights_Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Devices_OneArgs = {
  object: Insights_Devices_Insert_Input;
  on_conflict?: Maybe<Insights_Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Event_TypesArgs = {
  objects: Array<Insights_Event_Types_Insert_Input>;
  on_conflict?: Maybe<Insights_Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Event_Types_OneArgs = {
  object: Insights_Event_Types_Insert_Input;
  on_conflict?: Maybe<Insights_Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_EventsArgs = {
  objects: Array<Insights_Events_Insert_Input>;
  on_conflict?: Maybe<Insights_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Events_OneArgs = {
  object: Insights_Events_Insert_Input;
  on_conflict?: Maybe<Insights_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Page_View_EventsArgs = {
  objects: Array<Insights_Page_View_Events_Insert_Input>;
  on_conflict?: Maybe<Insights_Page_View_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Page_View_Events_OneArgs = {
  object: Insights_Page_View_Events_Insert_Input;
  on_conflict?: Maybe<Insights_Page_View_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_SessionsArgs = {
  objects: Array<Insights_Sessions_Insert_Input>;
  on_conflict?: Maybe<Insights_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Sessions_OneArgs = {
  object: Insights_Sessions_Insert_Input;
  on_conflict?: Maybe<Insights_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_VisitorsArgs = {
  objects: Array<Insights_Visitors_Insert_Input>;
  on_conflict?: Maybe<Insights_Visitors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insights_Visitors_OneArgs = {
  object: Insights_Visitors_Insert_Input;
  on_conflict?: Maybe<Insights_Visitors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InterestsArgs = {
  objects: Array<Interests_Insert_Input>;
  on_conflict?: Maybe<Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Interests_OneArgs = {
  object: Interests_Insert_Input;
  on_conflict?: Maybe<Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Known_VehiclesArgs = {
  objects: Array<Known_Vehicles_Insert_Input>;
  on_conflict?: Maybe<Known_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Known_Vehicles_OneArgs = {
  object: Known_Vehicles_Insert_Input;
  on_conflict?: Maybe<Known_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocalesArgs = {
  objects: Array<Locales_Insert_Input>;
  on_conflict?: Maybe<Locales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locales_OneArgs = {
  object: Locales_Insert_Input;
  on_conflict?: Maybe<Locales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_Service_TypesArgs = {
  objects: Array<Location_Service_Types_Insert_Input>;
  on_conflict?: Maybe<Location_Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_Service_Types_OneArgs = {
  object: Location_Service_Types_Insert_Input;
  on_conflict?: Maybe<Location_Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_Transport_TypesArgs = {
  objects: Array<Location_Transport_Types_Insert_Input>;
  on_conflict?: Maybe<Location_Transport_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_Transport_Types_OneArgs = {
  object: Location_Transport_Types_Insert_Input;
  on_conflict?: Maybe<Location_Transport_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Match_SubmissionsArgs = {
  objects: Array<Match_Submissions_Insert_Input>;
  on_conflict?: Maybe<Match_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Match_Submissions_OneArgs = {
  object: Match_Submissions_Insert_Input;
  on_conflict?: Maybe<Match_Submissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MomentsArgs = {
  objects: Array<Moments_Insert_Input>;
  on_conflict?: Maybe<Moments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Moments_OneArgs = {
  object: Moments_Insert_Input;
  on_conflict?: Maybe<Moments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotesArgs = {
  objects: Array<Notes_Insert_Input>;
  on_conflict?: Maybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notes_OneArgs = {
  object: Notes_Insert_Input;
  on_conflict?: Maybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Offer_TypesArgs = {
  objects: Array<Offer_Types_Insert_Input>;
  on_conflict?: Maybe<Offer_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Offer_Types_OneArgs = {
  object: Offer_Types_Insert_Input;
  on_conflict?: Maybe<Offer_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OffersArgs = {
  objects: Array<Offers_Insert_Input>;
  on_conflict?: Maybe<Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Offers_OneArgs = {
  object: Offers_Insert_Input;
  on_conflict?: Maybe<Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PagesArgs = {
  objects: Array<Pages_Insert_Input>;
  on_conflict?: Maybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pages_OneArgs = {
  object: Pages_Insert_Input;
  on_conflict?: Maybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PartnersArgs = {
  objects: Array<Partners_Insert_Input>;
  on_conflict?: Maybe<Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partners_OneArgs = {
  object: Partners_Insert_Input;
  on_conflict?: Maybe<Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_CodesArgs = {
  objects: Array<Payment_Codes_Insert_Input>;
  on_conflict?: Maybe<Payment_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Codes_OneArgs = {
  object: Payment_Codes_Insert_Input;
  on_conflict?: Maybe<Payment_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Method_TypesArgs = {
  objects: Array<Payment_Method_Types_Insert_Input>;
  on_conflict?: Maybe<Payment_Method_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Method_Types_OneArgs = {
  object: Payment_Method_Types_Insert_Input;
  on_conflict?: Maybe<Payment_Method_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_MethodsArgs = {
  objects: Array<Payment_Methods_Insert_Input>;
  on_conflict?: Maybe<Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Methods_OneArgs = {
  object: Payment_Methods_Insert_Input;
  on_conflict?: Maybe<Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentsArgs = {
  objects: Array<Payments_Insert_Input>;
  on_conflict?: Maybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payments_OneArgs = {
  object: Payments_Insert_Input;
  on_conflict?: Maybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_VehiclesArgs = {
  objects: Array<Personal_Vehicles_Insert_Input>;
  on_conflict?: Maybe<Personal_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Vehicles_OneArgs = {
  object: Personal_Vehicles_Insert_Input;
  on_conflict?: Maybe<Personal_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pricing_CodesArgs = {
  objects: Array<Pricing_Codes_Insert_Input>;
  on_conflict?: Maybe<Pricing_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pricing_Codes_OneArgs = {
  object: Pricing_Codes_Insert_Input;
  on_conflict?: Maybe<Pricing_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_GroupsArgs = {
  objects: Array<Product_Groups_Insert_Input>;
  on_conflict?: Maybe<Product_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Groups_OneArgs = {
  object: Product_Groups_Insert_Input;
  on_conflict?: Maybe<Product_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_VariantsArgs = {
  objects: Array<Product_Variants_Insert_Input>;
  on_conflict?: Maybe<Product_Variants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Variants_OneArgs = {
  object: Product_Variants_Insert_Input;
  on_conflict?: Maybe<Product_Variants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionsArgs = {
  objects: Array<Promotions_Insert_Input>;
  on_conflict?: Maybe<Promotions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promotions_OneArgs = {
  object: Promotions_Insert_Input;
  on_conflict?: Maybe<Promotions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RedirectsArgs = {
  objects: Array<Redirects_Insert_Input>;
  on_conflict?: Maybe<Redirects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Redirects_OneArgs = {
  object: Redirects_Insert_Input;
  on_conflict?: Maybe<Redirects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_DriversArgs = {
  objects: Array<Rental_Drivers_Insert_Input>;
  on_conflict?: Maybe<Rental_Drivers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Drivers_OneArgs = {
  object: Rental_Drivers_Insert_Input;
  on_conflict?: Maybe<Rental_Drivers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Reservation_StatusArgs = {
  objects: Array<Rental_Reservation_Status_Insert_Input>;
  on_conflict?: Maybe<Rental_Reservation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Reservation_Status_OneArgs = {
  object: Rental_Reservation_Status_Insert_Input;
  on_conflict?: Maybe<Rental_Reservation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_ReservationsArgs = {
  objects: Array<Rental_Reservations_Insert_Input>;
  on_conflict?: Maybe<Rental_Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Reservations_OneArgs = {
  object: Rental_Reservations_Insert_Input;
  on_conflict?: Maybe<Rental_Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Booking_Service_TypesArgs = {
  objects: Array<Service_Booking_Service_Types_Insert_Input>;
  on_conflict?: Maybe<Service_Booking_Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Booking_Service_Types_OneArgs = {
  object: Service_Booking_Service_Types_Insert_Input;
  on_conflict?: Maybe<Service_Booking_Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_BookingsArgs = {
  objects: Array<Service_Bookings_Insert_Input>;
  on_conflict?: Maybe<Service_Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Bookings_OneArgs = {
  object: Service_Bookings_Insert_Input;
  on_conflict?: Maybe<Service_Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_TypesArgs = {
  objects: Array<Service_Types_Insert_Input>;
  on_conflict?: Maybe<Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Types_OneArgs = {
  object: Service_Types_Insert_Input;
  on_conflict?: Maybe<Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionsArgs = {
  objects: Array<Sessions_Insert_Input>;
  on_conflict?: Maybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sessions_OneArgs = {
  object: Sessions_Insert_Input;
  on_conflict?: Maybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_AppsArgs = {
  objects: Array<Showroom_Apps_Insert_Input>;
  on_conflict?: Maybe<Showroom_Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Apps_InputsArgs = {
  objects: Array<Showroom_Apps_Inputs_Insert_Input>;
  on_conflict?: Maybe<Showroom_Apps_Inputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Apps_Inputs_OneArgs = {
  object: Showroom_Apps_Inputs_Insert_Input;
  on_conflict?: Maybe<Showroom_Apps_Inputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Apps_OneArgs = {
  object: Showroom_Apps_Insert_Input;
  on_conflict?: Maybe<Showroom_Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_LocalesArgs = {
  objects: Array<Showroom_Locales_Insert_Input>;
  on_conflict?: Maybe<Showroom_Locales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Locales_OneArgs = {
  object: Showroom_Locales_Insert_Input;
  on_conflict?: Maybe<Showroom_Locales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Pricing_CodesArgs = {
  objects: Array<Showroom_Pricing_Codes_Insert_Input>;
  on_conflict?: Maybe<Showroom_Pricing_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Pricing_Codes_OneArgs = {
  object: Showroom_Pricing_Codes_Insert_Input;
  on_conflict?: Maybe<Showroom_Pricing_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Stocked_VehiclesArgs = {
  objects: Array<Showroom_Stocked_Vehicles_Insert_Input>;
  on_conflict?: Maybe<Showroom_Stocked_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Stocked_Vehicles_OneArgs = {
  object: Showroom_Stocked_Vehicles_Insert_Input;
  on_conflict?: Maybe<Showroom_Stocked_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_UsersArgs = {
  objects: Array<Showroom_Users_Insert_Input>;
  on_conflict?: Maybe<Showroom_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Users_OneArgs = {
  object: Showroom_Users_Insert_Input;
  on_conflict?: Maybe<Showroom_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShowroomsArgs = {
  objects: Array<Showrooms_Insert_Input>;
  on_conflict?: Maybe<Showrooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showrooms_OneArgs = {
  object: Showrooms_Insert_Input;
  on_conflict?: Maybe<Showrooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showrooms_PartnersArgs = {
  objects: Array<Showrooms_Partners_Insert_Input>;
  on_conflict?: Maybe<Showrooms_Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showrooms_Partners_OneArgs = {
  object: Showrooms_Partners_Insert_Input;
  on_conflict?: Maybe<Showrooms_Partners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showrooms_PromotionsArgs = {
  objects: Array<Showrooms_Promotions_Insert_Input>;
  on_conflict?: Maybe<Showrooms_Promotions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showrooms_Promotions_OneArgs = {
  object: Showrooms_Promotions_Insert_Input;
  on_conflict?: Maybe<Showrooms_Promotions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicle_Finance_ProductsArgs = {
  objects: Array<Stocked_Vehicle_Finance_Products_Insert_Input>;
  on_conflict?: Maybe<Stocked_Vehicle_Finance_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicle_Finance_Products_OneArgs = {
  object: Stocked_Vehicle_Finance_Products_Insert_Input;
  on_conflict?: Maybe<Stocked_Vehicle_Finance_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicle_Handover_LocationsArgs = {
  objects: Array<Stocked_Vehicle_Handover_Locations_Insert_Input>;
  on_conflict?: Maybe<Stocked_Vehicle_Handover_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicle_Handover_Locations_OneArgs = {
  object: Stocked_Vehicle_Handover_Locations_Insert_Input;
  on_conflict?: Maybe<Stocked_Vehicle_Handover_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicle_OffersArgs = {
  objects: Array<Stocked_Vehicle_Offers_Insert_Input>;
  on_conflict?: Maybe<Stocked_Vehicle_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicle_Offers_OneArgs = {
  object: Stocked_Vehicle_Offers_Insert_Input;
  on_conflict?: Maybe<Stocked_Vehicle_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_VehiclesArgs = {
  objects: Array<Stocked_Vehicles_Insert_Input>;
  on_conflict?: Maybe<Stocked_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocked_Vehicles_OneArgs = {
  object: Stocked_Vehicles_Insert_Input;
  on_conflict?: Maybe<Stocked_Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: Maybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: Maybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_DrivesArgs = {
  objects: Array<Test_Drives_Insert_Input>;
  on_conflict?: Maybe<Test_Drives_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Drives_OneArgs = {
  object: Test_Drives_Insert_Input;
  on_conflict?: Maybe<Test_Drives_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_In_PlansArgs = {
  objects: Array<Trade_In_Plans_Insert_Input>;
  on_conflict?: Maybe<Trade_In_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_In_Plans_OneArgs = {
  object: Trade_In_Plans_Insert_Input;
  on_conflict?: Maybe<Trade_In_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_In_ValuationsArgs = {
  objects: Array<Trade_In_Valuations_Insert_Input>;
  on_conflict?: Maybe<Trade_In_Valuations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_In_Valuations_OneArgs = {
  object: Trade_In_Valuations_Insert_Input;
  on_conflict?: Maybe<Trade_In_Valuations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transport_TypesArgs = {
  objects: Array<Transport_Types_Insert_Input>;
  on_conflict?: Maybe<Transport_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transport_Types_OneArgs = {
  object: Transport_Types_Insert_Input;
  on_conflict?: Maybe<Transport_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InterestsArgs = {
  objects: Array<User_Interests_Insert_Input>;
  on_conflict?: Maybe<User_Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Interests_OneArgs = {
  object: User_Interests_Insert_Input;
  on_conflict?: Maybe<User_Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ValuersArgs = {
  objects: Array<Valuers_Insert_Input>;
  on_conflict?: Maybe<Valuers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Valuers_OneArgs = {
  object: Valuers_Insert_Input;
  on_conflict?: Maybe<Valuers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_ConditionsArgs = {
  objects: Array<Vehicle_Conditions_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Conditions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Conditions_OneArgs = {
  object: Vehicle_Conditions_Insert_Input;
  on_conflict?: Maybe<Vehicle_Conditions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_ListsArgs = {
  objects: Array<Vehicle_Lists_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Lists_OneArgs = {
  object: Vehicle_Lists_Insert_Input;
  on_conflict?: Maybe<Vehicle_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Order_ActivityArgs = {
  objects: Array<Vehicle_Order_Activity_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Order_Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Order_Activity_OneArgs = {
  object: Vehicle_Order_Activity_Insert_Input;
  on_conflict?: Maybe<Vehicle_Order_Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Order_Product_VariantsArgs = {
  objects: Array<Vehicle_Order_Product_Variants_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Order_Product_Variants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Order_Product_Variants_OneArgs = {
  object: Vehicle_Order_Product_Variants_Insert_Input;
  on_conflict?: Maybe<Vehicle_Order_Product_Variants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_OrdersArgs = {
  objects: Array<Vehicle_Orders_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Orders_OneArgs = {
  object: Vehicle_Orders_Insert_Input;
  on_conflict?: Maybe<Vehicle_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_SpecificationsArgs = {
  objects: Array<Vehicle_Specifications_Insert_Input>;
  on_conflict?: Maybe<Vehicle_Specifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Specifications_OneArgs = {
  object: Vehicle_Specifications_Insert_Input;
  on_conflict?: Maybe<Vehicle_Specifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wish_ListArgs = {
  objects: Array<Wish_List_Insert_Input>;
  on_conflict?: Maybe<Wish_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wish_List_OneArgs = {
  object: Wish_List_Insert_Input;
  on_conflict?: Maybe<Wish_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootMedia_Get_Signed_DownloadArgs = {
  pathname: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMedia_Get_Signed_UploadArgs = {
  is_public?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootSet_Vehicle_By_Autograb_Vehicle_IdArgs = {
  autograb_vehicle_id: Scalars['String'];
  trade_in_plan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdate_ActivitiesArgs = {
  _set?: Maybe<Activities_Set_Input>;
  where: Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activities_By_PkArgs = {
  _set?: Maybe<Activities_Set_Input>;
  pk_columns: Activities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Activities_ManyArgs = {
  updates: Array<Activities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Api_ClientsArgs = {
  _set?: Maybe<Api_Clients_Set_Input>;
  where: Api_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Clients_By_PkArgs = {
  _set?: Maybe<Api_Clients_Set_Input>;
  pk_columns: Api_Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Clients_ManyArgs = {
  updates: Array<Api_Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_ActionsArgs = {
  _append?: Maybe<App_Actions_Append_Input>;
  _delete_at_path?: Maybe<App_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<App_Actions_Delete_Key_Input>;
  _prepend?: Maybe<App_Actions_Prepend_Input>;
  _set?: Maybe<App_Actions_Set_Input>;
  where: App_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Actions_By_PkArgs = {
  _append?: Maybe<App_Actions_Append_Input>;
  _delete_at_path?: Maybe<App_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<App_Actions_Delete_Key_Input>;
  _prepend?: Maybe<App_Actions_Prepend_Input>;
  _set?: Maybe<App_Actions_Set_Input>;
  pk_columns: App_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_Actions_ManyArgs = {
  updates: Array<App_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_EventsArgs = {
  _set?: Maybe<App_Events_Set_Input>;
  where: App_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Events_By_PkArgs = {
  _set?: Maybe<App_Events_Set_Input>;
  pk_columns: App_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_Events_ManyArgs = {
  updates: Array<App_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_InputsArgs = {
  _append?: Maybe<App_Inputs_Append_Input>;
  _delete_at_path?: Maybe<App_Inputs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_Inputs_Delete_Elem_Input>;
  _delete_key?: Maybe<App_Inputs_Delete_Key_Input>;
  _prepend?: Maybe<App_Inputs_Prepend_Input>;
  _set?: Maybe<App_Inputs_Set_Input>;
  where: App_Inputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Inputs_By_PkArgs = {
  _append?: Maybe<App_Inputs_Append_Input>;
  _delete_at_path?: Maybe<App_Inputs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_Inputs_Delete_Elem_Input>;
  _delete_key?: Maybe<App_Inputs_Delete_Key_Input>;
  _prepend?: Maybe<App_Inputs_Prepend_Input>;
  _set?: Maybe<App_Inputs_Set_Input>;
  pk_columns: App_Inputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_Inputs_ManyArgs = {
  updates: Array<App_Inputs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_VersionsArgs = {
  _append?: Maybe<App_Versions_Append_Input>;
  _delete_at_path?: Maybe<App_Versions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_Versions_Delete_Elem_Input>;
  _delete_key?: Maybe<App_Versions_Delete_Key_Input>;
  _prepend?: Maybe<App_Versions_Prepend_Input>;
  _set?: Maybe<App_Versions_Set_Input>;
  where: App_Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Versions_By_PkArgs = {
  _append?: Maybe<App_Versions_Append_Input>;
  _delete_at_path?: Maybe<App_Versions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<App_Versions_Delete_Elem_Input>;
  _delete_key?: Maybe<App_Versions_Delete_Key_Input>;
  _prepend?: Maybe<App_Versions_Prepend_Input>;
  _set?: Maybe<App_Versions_Set_Input>;
  pk_columns: App_Versions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_Versions_ManyArgs = {
  updates: Array<App_Versions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppsArgs = {
  _set?: Maybe<Apps_Set_Input>;
  where: Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_By_PkArgs = {
  _set?: Maybe<Apps_Set_Input>;
  pk_columns: Apps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_ManyArgs = {
  updates: Array<Apps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ArticlesArgs = {
  _append?: Maybe<Articles_Append_Input>;
  _delete_at_path?: Maybe<Articles_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Articles_Delete_Elem_Input>;
  _delete_key?: Maybe<Articles_Delete_Key_Input>;
  _inc?: Maybe<Articles_Inc_Input>;
  _prepend?: Maybe<Articles_Prepend_Input>;
  _set?: Maybe<Articles_Set_Input>;
  where: Articles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Articles_By_PkArgs = {
  _append?: Maybe<Articles_Append_Input>;
  _delete_at_path?: Maybe<Articles_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Articles_Delete_Elem_Input>;
  _delete_key?: Maybe<Articles_Delete_Key_Input>;
  _inc?: Maybe<Articles_Inc_Input>;
  _prepend?: Maybe<Articles_Prepend_Input>;
  _set?: Maybe<Articles_Set_Input>;
  pk_columns: Articles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Articles_ManyArgs = {
  updates: Array<Articles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AssetsArgs = {
  _inc?: Maybe<Assets_Inc_Input>;
  _set?: Maybe<Assets_Set_Input>;
  where: Assets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assets_By_PkArgs = {
  _inc?: Maybe<Assets_Inc_Input>;
  _set?: Maybe<Assets_Set_Input>;
  pk_columns: Assets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assets_ManyArgs = {
  updates: Array<Assets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auction_BidsArgs = {
  _inc?: Maybe<Auction_Bids_Inc_Input>;
  _set?: Maybe<Auction_Bids_Set_Input>;
  where: Auction_Bids_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auction_Bids_By_PkArgs = {
  _inc?: Maybe<Auction_Bids_Inc_Input>;
  _set?: Maybe<Auction_Bids_Set_Input>;
  pk_columns: Auction_Bids_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auction_Bids_ManyArgs = {
  updates: Array<Auction_Bids_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuctionsArgs = {
  _inc?: Maybe<Auctions_Inc_Input>;
  _set?: Maybe<Auctions_Set_Input>;
  where: Auctions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auctions_By_PkArgs = {
  _inc?: Maybe<Auctions_Inc_Input>;
  _set?: Maybe<Auctions_Set_Input>;
  pk_columns: Auctions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auctions_ManyArgs = {
  updates: Array<Auctions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Calculator_SubmissionArgs = {
  _append?: Maybe<Calculator_Submission_Append_Input>;
  _delete_at_path?: Maybe<Calculator_Submission_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Calculator_Submission_Delete_Elem_Input>;
  _delete_key?: Maybe<Calculator_Submission_Delete_Key_Input>;
  _inc?: Maybe<Calculator_Submission_Inc_Input>;
  _prepend?: Maybe<Calculator_Submission_Prepend_Input>;
  _set?: Maybe<Calculator_Submission_Set_Input>;
  where: Calculator_Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calculator_Submission_By_PkArgs = {
  _append?: Maybe<Calculator_Submission_Append_Input>;
  _delete_at_path?: Maybe<Calculator_Submission_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Calculator_Submission_Delete_Elem_Input>;
  _delete_key?: Maybe<Calculator_Submission_Delete_Key_Input>;
  _inc?: Maybe<Calculator_Submission_Inc_Input>;
  _prepend?: Maybe<Calculator_Submission_Prepend_Input>;
  _set?: Maybe<Calculator_Submission_Set_Input>;
  pk_columns: Calculator_Submission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Calculator_Submission_ManyArgs = {
  updates: Array<Calculator_Submission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Field_ValuesArgs = {
  _append?: Maybe<Custom_Field_Values_Append_Input>;
  _delete_at_path?: Maybe<Custom_Field_Values_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Custom_Field_Values_Delete_Elem_Input>;
  _delete_key?: Maybe<Custom_Field_Values_Delete_Key_Input>;
  _prepend?: Maybe<Custom_Field_Values_Prepend_Input>;
  _set?: Maybe<Custom_Field_Values_Set_Input>;
  where: Custom_Field_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Field_Values_By_PkArgs = {
  _append?: Maybe<Custom_Field_Values_Append_Input>;
  _delete_at_path?: Maybe<Custom_Field_Values_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Custom_Field_Values_Delete_Elem_Input>;
  _delete_key?: Maybe<Custom_Field_Values_Delete_Key_Input>;
  _prepend?: Maybe<Custom_Field_Values_Prepend_Input>;
  _set?: Maybe<Custom_Field_Values_Set_Input>;
  pk_columns: Custom_Field_Values_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Field_Values_ManyArgs = {
  updates: Array<Custom_Field_Values_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_FieldsArgs = {
  _append?: Maybe<Custom_Fields_Append_Input>;
  _delete_at_path?: Maybe<Custom_Fields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Custom_Fields_Delete_Elem_Input>;
  _delete_key?: Maybe<Custom_Fields_Delete_Key_Input>;
  _inc?: Maybe<Custom_Fields_Inc_Input>;
  _prepend?: Maybe<Custom_Fields_Prepend_Input>;
  _set?: Maybe<Custom_Fields_Set_Input>;
  where: Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Fields_By_PkArgs = {
  _append?: Maybe<Custom_Fields_Append_Input>;
  _delete_at_path?: Maybe<Custom_Fields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Custom_Fields_Delete_Elem_Input>;
  _delete_key?: Maybe<Custom_Fields_Delete_Key_Input>;
  _inc?: Maybe<Custom_Fields_Inc_Input>;
  _prepend?: Maybe<Custom_Fields_Prepend_Input>;
  _set?: Maybe<Custom_Fields_Set_Input>;
  pk_columns: Custom_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Fields_ManyArgs = {
  updates: Array<Custom_Fields_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_VehiclesArgs = {
  _append?: Maybe<Custom_Vehicles_Append_Input>;
  _delete_at_path?: Maybe<Custom_Vehicles_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Custom_Vehicles_Delete_Elem_Input>;
  _delete_key?: Maybe<Custom_Vehicles_Delete_Key_Input>;
  _prepend?: Maybe<Custom_Vehicles_Prepend_Input>;
  _set?: Maybe<Custom_Vehicles_Set_Input>;
  where: Custom_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Vehicles_By_PkArgs = {
  _append?: Maybe<Custom_Vehicles_Append_Input>;
  _delete_at_path?: Maybe<Custom_Vehicles_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Custom_Vehicles_Delete_Elem_Input>;
  _delete_key?: Maybe<Custom_Vehicles_Delete_Key_Input>;
  _prepend?: Maybe<Custom_Vehicles_Prepend_Input>;
  _set?: Maybe<Custom_Vehicles_Set_Input>;
  pk_columns: Custom_Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Vehicles_ManyArgs = {
  updates: Array<Custom_Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_FieldsArgs = {
  _append?: Maybe<Filter_Fields_Append_Input>;
  _delete_at_path?: Maybe<Filter_Fields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Filter_Fields_Delete_Elem_Input>;
  _delete_key?: Maybe<Filter_Fields_Delete_Key_Input>;
  _inc?: Maybe<Filter_Fields_Inc_Input>;
  _prepend?: Maybe<Filter_Fields_Prepend_Input>;
  _set?: Maybe<Filter_Fields_Set_Input>;
  where: Filter_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Fields_By_PkArgs = {
  _append?: Maybe<Filter_Fields_Append_Input>;
  _delete_at_path?: Maybe<Filter_Fields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Filter_Fields_Delete_Elem_Input>;
  _delete_key?: Maybe<Filter_Fields_Delete_Key_Input>;
  _inc?: Maybe<Filter_Fields_Inc_Input>;
  _prepend?: Maybe<Filter_Fields_Prepend_Input>;
  _set?: Maybe<Filter_Fields_Set_Input>;
  pk_columns: Filter_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Fields_ManyArgs = {
  updates: Array<Filter_Fields_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_LocationsArgs = {
  _set?: Maybe<Filter_Locations_Set_Input>;
  where: Filter_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Locations_By_PkArgs = {
  _set?: Maybe<Filter_Locations_Set_Input>;
  pk_columns: Filter_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Locations_ManyArgs = {
  updates: Array<Filter_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_NamesArgs = {
  _set?: Maybe<Filter_Names_Set_Input>;
  where: Filter_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Names_By_PkArgs = {
  _set?: Maybe<Filter_Names_Set_Input>;
  pk_columns: Filter_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Names_ManyArgs = {
  updates: Array<Filter_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_ApplicationsArgs = {
  _append?: Maybe<Finance_Applications_Append_Input>;
  _delete_at_path?: Maybe<Finance_Applications_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Finance_Applications_Delete_Elem_Input>;
  _delete_key?: Maybe<Finance_Applications_Delete_Key_Input>;
  _inc?: Maybe<Finance_Applications_Inc_Input>;
  _prepend?: Maybe<Finance_Applications_Prepend_Input>;
  _set?: Maybe<Finance_Applications_Set_Input>;
  where: Finance_Applications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Applications_By_PkArgs = {
  _append?: Maybe<Finance_Applications_Append_Input>;
  _delete_at_path?: Maybe<Finance_Applications_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Finance_Applications_Delete_Elem_Input>;
  _delete_key?: Maybe<Finance_Applications_Delete_Key_Input>;
  _inc?: Maybe<Finance_Applications_Inc_Input>;
  _prepend?: Maybe<Finance_Applications_Prepend_Input>;
  _set?: Maybe<Finance_Applications_Set_Input>;
  pk_columns: Finance_Applications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Applications_ManyArgs = {
  updates: Array<Finance_Applications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_FeesArgs = {
  _inc?: Maybe<Finance_Fees_Inc_Input>;
  _set?: Maybe<Finance_Fees_Set_Input>;
  where: Finance_Fees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Fees_By_PkArgs = {
  _inc?: Maybe<Finance_Fees_Inc_Input>;
  _set?: Maybe<Finance_Fees_Set_Input>;
  pk_columns: Finance_Fees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Fees_ManyArgs = {
  updates: Array<Finance_Fees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Fixed_Rate_ConfigurationsArgs = {
  _inc?: Maybe<Finance_Fixed_Rate_Configurations_Inc_Input>;
  _set?: Maybe<Finance_Fixed_Rate_Configurations_Set_Input>;
  where: Finance_Fixed_Rate_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Fixed_Rate_Configurations_By_PkArgs = {
  _inc?: Maybe<Finance_Fixed_Rate_Configurations_Inc_Input>;
  _set?: Maybe<Finance_Fixed_Rate_Configurations_Set_Input>;
  pk_columns: Finance_Fixed_Rate_Configurations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Fixed_Rate_Configurations_ManyArgs = {
  updates: Array<Finance_Fixed_Rate_Configurations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_FrequencyArgs = {
  _set?: Maybe<Finance_Frequency_Set_Input>;
  where: Finance_Frequency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Frequency_By_PkArgs = {
  _set?: Maybe<Finance_Frequency_Set_Input>;
  pk_columns: Finance_Frequency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Frequency_ManyArgs = {
  updates: Array<Finance_Frequency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Gfv_ConfigurationsArgs = {
  _inc?: Maybe<Finance_Gfv_Configurations_Inc_Input>;
  _set?: Maybe<Finance_Gfv_Configurations_Set_Input>;
  where: Finance_Gfv_Configurations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Gfv_Configurations_By_PkArgs = {
  _inc?: Maybe<Finance_Gfv_Configurations_Inc_Input>;
  _set?: Maybe<Finance_Gfv_Configurations_Set_Input>;
  pk_columns: Finance_Gfv_Configurations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Gfv_Configurations_ManyArgs = {
  updates: Array<Finance_Gfv_Configurations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Gfv_Residual_ValuesArgs = {
  _inc?: Maybe<Finance_Gfv_Residual_Values_Inc_Input>;
  _set?: Maybe<Finance_Gfv_Residual_Values_Set_Input>;
  where: Finance_Gfv_Residual_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Gfv_Residual_Values_By_PkArgs = {
  _inc?: Maybe<Finance_Gfv_Residual_Values_Inc_Input>;
  _set?: Maybe<Finance_Gfv_Residual_Values_Set_Input>;
  pk_columns: Finance_Gfv_Residual_Values_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Gfv_Residual_Values_ManyArgs = {
  updates: Array<Finance_Gfv_Residual_Values_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Product_OffersArgs = {
  _set?: Maybe<Finance_Product_Offers_Set_Input>;
  where: Finance_Product_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Product_Offers_By_PkArgs = {
  _set?: Maybe<Finance_Product_Offers_Set_Input>;
  pk_columns: Finance_Product_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Product_Offers_ManyArgs = {
  updates: Array<Finance_Product_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Product_TypesArgs = {
  _set?: Maybe<Finance_Product_Types_Set_Input>;
  where: Finance_Product_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Product_Types_By_PkArgs = {
  _set?: Maybe<Finance_Product_Types_Set_Input>;
  pk_columns: Finance_Product_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Product_Types_ManyArgs = {
  updates: Array<Finance_Product_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_ProductsArgs = {
  _append?: Maybe<Finance_Products_Append_Input>;
  _delete_at_path?: Maybe<Finance_Products_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Finance_Products_Delete_Elem_Input>;
  _delete_key?: Maybe<Finance_Products_Delete_Key_Input>;
  _inc?: Maybe<Finance_Products_Inc_Input>;
  _prepend?: Maybe<Finance_Products_Prepend_Input>;
  _set?: Maybe<Finance_Products_Set_Input>;
  where: Finance_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Products_By_PkArgs = {
  _append?: Maybe<Finance_Products_Append_Input>;
  _delete_at_path?: Maybe<Finance_Products_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Finance_Products_Delete_Elem_Input>;
  _delete_key?: Maybe<Finance_Products_Delete_Key_Input>;
  _inc?: Maybe<Finance_Products_Inc_Input>;
  _prepend?: Maybe<Finance_Products_Prepend_Input>;
  _set?: Maybe<Finance_Products_Set_Input>;
  pk_columns: Finance_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Products_ManyArgs = {
  updates: Array<Finance_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_TermsArgs = {
  _inc?: Maybe<Finance_Terms_Inc_Input>;
  _set?: Maybe<Finance_Terms_Set_Input>;
  where: Finance_Terms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Terms_By_PkArgs = {
  _inc?: Maybe<Finance_Terms_Inc_Input>;
  _set?: Maybe<Finance_Terms_Set_Input>;
  pk_columns: Finance_Terms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Finance_Terms_ManyArgs = {
  updates: Array<Finance_Terms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Form_FieldsArgs = {
  _append?: Maybe<Form_Fields_Append_Input>;
  _delete_at_path?: Maybe<Form_Fields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Form_Fields_Delete_Elem_Input>;
  _delete_key?: Maybe<Form_Fields_Delete_Key_Input>;
  _inc?: Maybe<Form_Fields_Inc_Input>;
  _prepend?: Maybe<Form_Fields_Prepend_Input>;
  _set?: Maybe<Form_Fields_Set_Input>;
  where: Form_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Fields_By_PkArgs = {
  _append?: Maybe<Form_Fields_Append_Input>;
  _delete_at_path?: Maybe<Form_Fields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Form_Fields_Delete_Elem_Input>;
  _delete_key?: Maybe<Form_Fields_Delete_Key_Input>;
  _inc?: Maybe<Form_Fields_Inc_Input>;
  _prepend?: Maybe<Form_Fields_Prepend_Input>;
  _set?: Maybe<Form_Fields_Set_Input>;
  pk_columns: Form_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Fields_ManyArgs = {
  updates: Array<Form_Fields_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Form_SubmissionsArgs = {
  _append?: Maybe<Form_Submissions_Append_Input>;
  _delete_at_path?: Maybe<Form_Submissions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Form_Submissions_Delete_Elem_Input>;
  _delete_key?: Maybe<Form_Submissions_Delete_Key_Input>;
  _prepend?: Maybe<Form_Submissions_Prepend_Input>;
  _set?: Maybe<Form_Submissions_Set_Input>;
  where: Form_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Submissions_By_PkArgs = {
  _append?: Maybe<Form_Submissions_Append_Input>;
  _delete_at_path?: Maybe<Form_Submissions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Form_Submissions_Delete_Elem_Input>;
  _delete_key?: Maybe<Form_Submissions_Delete_Key_Input>;
  _prepend?: Maybe<Form_Submissions_Prepend_Input>;
  _set?: Maybe<Form_Submissions_Set_Input>;
  pk_columns: Form_Submissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Submissions_ManyArgs = {
  updates: Array<Form_Submissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _set?: Maybe<Forms_Set_Input>;
  where: Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _set?: Maybe<Forms_Set_Input>;
  pk_columns: Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Forms_ManyArgs = {
  updates: Array<Forms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Click_EventsArgs = {
  _inc?: Maybe<Insights_Click_Events_Inc_Input>;
  _set?: Maybe<Insights_Click_Events_Set_Input>;
  where: Insights_Click_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Click_Events_By_PkArgs = {
  _inc?: Maybe<Insights_Click_Events_Inc_Input>;
  _set?: Maybe<Insights_Click_Events_Set_Input>;
  pk_columns: Insights_Click_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Click_Events_ManyArgs = {
  updates: Array<Insights_Click_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_DevicesArgs = {
  _inc?: Maybe<Insights_Devices_Inc_Input>;
  _set?: Maybe<Insights_Devices_Set_Input>;
  where: Insights_Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Devices_By_PkArgs = {
  _inc?: Maybe<Insights_Devices_Inc_Input>;
  _set?: Maybe<Insights_Devices_Set_Input>;
  pk_columns: Insights_Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Devices_ManyArgs = {
  updates: Array<Insights_Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Event_TypesArgs = {
  _set?: Maybe<Insights_Event_Types_Set_Input>;
  where: Insights_Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Event_Types_By_PkArgs = {
  _set?: Maybe<Insights_Event_Types_Set_Input>;
  pk_columns: Insights_Event_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Event_Types_ManyArgs = {
  updates: Array<Insights_Event_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_EventsArgs = {
  _set?: Maybe<Insights_Events_Set_Input>;
  where: Insights_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Events_By_PkArgs = {
  _set?: Maybe<Insights_Events_Set_Input>;
  pk_columns: Insights_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Events_ManyArgs = {
  updates: Array<Insights_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Page_View_EventsArgs = {
  _set?: Maybe<Insights_Page_View_Events_Set_Input>;
  where: Insights_Page_View_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Page_View_Events_By_PkArgs = {
  _set?: Maybe<Insights_Page_View_Events_Set_Input>;
  pk_columns: Insights_Page_View_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Page_View_Events_ManyArgs = {
  updates: Array<Insights_Page_View_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_SessionsArgs = {
  _set?: Maybe<Insights_Sessions_Set_Input>;
  where: Insights_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Sessions_By_PkArgs = {
  _set?: Maybe<Insights_Sessions_Set_Input>;
  pk_columns: Insights_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Sessions_ManyArgs = {
  updates: Array<Insights_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_VisitorsArgs = {
  _set?: Maybe<Insights_Visitors_Set_Input>;
  where: Insights_Visitors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Visitors_By_PkArgs = {
  _set?: Maybe<Insights_Visitors_Set_Input>;
  pk_columns: Insights_Visitors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insights_Visitors_ManyArgs = {
  updates: Array<Insights_Visitors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InterestsArgs = {
  _set?: Maybe<Interests_Set_Input>;
  where: Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Interests_By_PkArgs = {
  _set?: Maybe<Interests_Set_Input>;
  pk_columns: Interests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Interests_ManyArgs = {
  updates: Array<Interests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Known_VehiclesArgs = {
  _inc?: Maybe<Known_Vehicles_Inc_Input>;
  _set?: Maybe<Known_Vehicles_Set_Input>;
  where: Known_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Known_Vehicles_By_PkArgs = {
  _inc?: Maybe<Known_Vehicles_Inc_Input>;
  _set?: Maybe<Known_Vehicles_Set_Input>;
  pk_columns: Known_Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Known_Vehicles_ManyArgs = {
  updates: Array<Known_Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocalesArgs = {
  _set?: Maybe<Locales_Set_Input>;
  where: Locales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locales_By_PkArgs = {
  _set?: Maybe<Locales_Set_Input>;
  pk_columns: Locales_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locales_ManyArgs = {
  updates: Array<Locales_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Service_TypesArgs = {
  _inc?: Maybe<Location_Service_Types_Inc_Input>;
  _set?: Maybe<Location_Service_Types_Set_Input>;
  where: Location_Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Service_Types_By_PkArgs = {
  _inc?: Maybe<Location_Service_Types_Inc_Input>;
  _set?: Maybe<Location_Service_Types_Set_Input>;
  pk_columns: Location_Service_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Service_Types_ManyArgs = {
  updates: Array<Location_Service_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Transport_TypesArgs = {
  _inc?: Maybe<Location_Transport_Types_Inc_Input>;
  _set?: Maybe<Location_Transport_Types_Set_Input>;
  where: Location_Transport_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Transport_Types_By_PkArgs = {
  _inc?: Maybe<Location_Transport_Types_Inc_Input>;
  _set?: Maybe<Location_Transport_Types_Set_Input>;
  pk_columns: Location_Transport_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Location_Transport_Types_ManyArgs = {
  updates: Array<Location_Transport_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _append?: Maybe<Locations_Append_Input>;
  _delete_at_path?: Maybe<Locations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Locations_Delete_Elem_Input>;
  _delete_key?: Maybe<Locations_Delete_Key_Input>;
  _inc?: Maybe<Locations_Inc_Input>;
  _prepend?: Maybe<Locations_Prepend_Input>;
  _set?: Maybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _append?: Maybe<Locations_Append_Input>;
  _delete_at_path?: Maybe<Locations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Locations_Delete_Elem_Input>;
  _delete_key?: Maybe<Locations_Delete_Key_Input>;
  _inc?: Maybe<Locations_Inc_Input>;
  _prepend?: Maybe<Locations_Prepend_Input>;
  _set?: Maybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Match_SubmissionsArgs = {
  _append?: Maybe<Match_Submissions_Append_Input>;
  _delete_at_path?: Maybe<Match_Submissions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Match_Submissions_Delete_Elem_Input>;
  _delete_key?: Maybe<Match_Submissions_Delete_Key_Input>;
  _inc?: Maybe<Match_Submissions_Inc_Input>;
  _prepend?: Maybe<Match_Submissions_Prepend_Input>;
  _set?: Maybe<Match_Submissions_Set_Input>;
  where: Match_Submissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Match_Submissions_By_PkArgs = {
  _append?: Maybe<Match_Submissions_Append_Input>;
  _delete_at_path?: Maybe<Match_Submissions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Match_Submissions_Delete_Elem_Input>;
  _delete_key?: Maybe<Match_Submissions_Delete_Key_Input>;
  _inc?: Maybe<Match_Submissions_Inc_Input>;
  _prepend?: Maybe<Match_Submissions_Prepend_Input>;
  _set?: Maybe<Match_Submissions_Set_Input>;
  pk_columns: Match_Submissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Match_Submissions_ManyArgs = {
  updates: Array<Match_Submissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MomentsArgs = {
  _set?: Maybe<Moments_Set_Input>;
  where: Moments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Moments_By_PkArgs = {
  _set?: Maybe<Moments_Set_Input>;
  pk_columns: Moments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Moments_ManyArgs = {
  updates: Array<Moments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotesArgs = {
  _set?: Maybe<Notes_Set_Input>;
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_By_PkArgs = {
  _set?: Maybe<Notes_Set_Input>;
  pk_columns: Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_ManyArgs = {
  updates: Array<Notes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Offer_TypesArgs = {
  _set?: Maybe<Offer_Types_Set_Input>;
  where: Offer_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Offer_Types_By_PkArgs = {
  _set?: Maybe<Offer_Types_Set_Input>;
  pk_columns: Offer_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Offer_Types_ManyArgs = {
  updates: Array<Offer_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OffersArgs = {
  _append?: Maybe<Offers_Append_Input>;
  _delete_at_path?: Maybe<Offers_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Offers_Delete_Elem_Input>;
  _delete_key?: Maybe<Offers_Delete_Key_Input>;
  _prepend?: Maybe<Offers_Prepend_Input>;
  _set?: Maybe<Offers_Set_Input>;
  where: Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Offers_By_PkArgs = {
  _append?: Maybe<Offers_Append_Input>;
  _delete_at_path?: Maybe<Offers_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Offers_Delete_Elem_Input>;
  _delete_key?: Maybe<Offers_Delete_Key_Input>;
  _prepend?: Maybe<Offers_Prepend_Input>;
  _set?: Maybe<Offers_Set_Input>;
  pk_columns: Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Offers_ManyArgs = {
  updates: Array<Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PagesArgs = {
  _append?: Maybe<Pages_Append_Input>;
  _delete_at_path?: Maybe<Pages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pages_Delete_Elem_Input>;
  _delete_key?: Maybe<Pages_Delete_Key_Input>;
  _inc?: Maybe<Pages_Inc_Input>;
  _prepend?: Maybe<Pages_Prepend_Input>;
  _set?: Maybe<Pages_Set_Input>;
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_By_PkArgs = {
  _append?: Maybe<Pages_Append_Input>;
  _delete_at_path?: Maybe<Pages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pages_Delete_Elem_Input>;
  _delete_key?: Maybe<Pages_Delete_Key_Input>;
  _inc?: Maybe<Pages_Inc_Input>;
  _prepend?: Maybe<Pages_Prepend_Input>;
  _set?: Maybe<Pages_Set_Input>;
  pk_columns: Pages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_ManyArgs = {
  updates: Array<Pages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PartnersArgs = {
  _set?: Maybe<Partners_Set_Input>;
  where: Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partners_By_PkArgs = {
  _set?: Maybe<Partners_Set_Input>;
  pk_columns: Partners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partners_ManyArgs = {
  updates: Array<Partners_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_CodesArgs = {
  _set?: Maybe<Payment_Codes_Set_Input>;
  where: Payment_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Codes_By_PkArgs = {
  _set?: Maybe<Payment_Codes_Set_Input>;
  pk_columns: Payment_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Codes_ManyArgs = {
  updates: Array<Payment_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_TypesArgs = {
  _set?: Maybe<Payment_Method_Types_Set_Input>;
  where: Payment_Method_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_Types_By_PkArgs = {
  _set?: Maybe<Payment_Method_Types_Set_Input>;
  pk_columns: Payment_Method_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_Types_ManyArgs = {
  updates: Array<Payment_Method_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_MethodsArgs = {
  _inc?: Maybe<Payment_Methods_Inc_Input>;
  _set?: Maybe<Payment_Methods_Set_Input>;
  where: Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_By_PkArgs = {
  _inc?: Maybe<Payment_Methods_Inc_Input>;
  _set?: Maybe<Payment_Methods_Set_Input>;
  pk_columns: Payment_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_ManyArgs = {
  updates: Array<Payment_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentsArgs = {
  _append?: Maybe<Payments_Append_Input>;
  _delete_at_path?: Maybe<Payments_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Payments_Delete_Elem_Input>;
  _delete_key?: Maybe<Payments_Delete_Key_Input>;
  _inc?: Maybe<Payments_Inc_Input>;
  _prepend?: Maybe<Payments_Prepend_Input>;
  _set?: Maybe<Payments_Set_Input>;
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payments_By_PkArgs = {
  _append?: Maybe<Payments_Append_Input>;
  _delete_at_path?: Maybe<Payments_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Payments_Delete_Elem_Input>;
  _delete_key?: Maybe<Payments_Delete_Key_Input>;
  _inc?: Maybe<Payments_Inc_Input>;
  _prepend?: Maybe<Payments_Prepend_Input>;
  _set?: Maybe<Payments_Set_Input>;
  pk_columns: Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payments_ManyArgs = {
  updates: Array<Payments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_VehiclesArgs = {
  _inc?: Maybe<Personal_Vehicles_Inc_Input>;
  _set?: Maybe<Personal_Vehicles_Set_Input>;
  where: Personal_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Vehicles_By_PkArgs = {
  _inc?: Maybe<Personal_Vehicles_Inc_Input>;
  _set?: Maybe<Personal_Vehicles_Set_Input>;
  pk_columns: Personal_Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Vehicles_ManyArgs = {
  updates: Array<Personal_Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pricing_CodesArgs = {
  _set?: Maybe<Pricing_Codes_Set_Input>;
  where: Pricing_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pricing_Codes_By_PkArgs = {
  _set?: Maybe<Pricing_Codes_Set_Input>;
  pk_columns: Pricing_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pricing_Codes_ManyArgs = {
  updates: Array<Pricing_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_GroupsArgs = {
  _set?: Maybe<Product_Groups_Set_Input>;
  where: Product_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Groups_By_PkArgs = {
  _set?: Maybe<Product_Groups_Set_Input>;
  pk_columns: Product_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Groups_ManyArgs = {
  updates: Array<Product_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_VariantsArgs = {
  _inc?: Maybe<Product_Variants_Inc_Input>;
  _set?: Maybe<Product_Variants_Set_Input>;
  where: Product_Variants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Variants_By_PkArgs = {
  _inc?: Maybe<Product_Variants_Inc_Input>;
  _set?: Maybe<Product_Variants_Set_Input>;
  pk_columns: Product_Variants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Variants_ManyArgs = {
  updates: Array<Product_Variants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _append?: Maybe<Products_Append_Input>;
  _delete_at_path?: Maybe<Products_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Products_Delete_Elem_Input>;
  _delete_key?: Maybe<Products_Delete_Key_Input>;
  _prepend?: Maybe<Products_Prepend_Input>;
  _set?: Maybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _append?: Maybe<Products_Append_Input>;
  _delete_at_path?: Maybe<Products_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Products_Delete_Elem_Input>;
  _delete_key?: Maybe<Products_Delete_Key_Input>;
  _prepend?: Maybe<Products_Prepend_Input>;
  _set?: Maybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionsArgs = {
  _set?: Maybe<Promotions_Set_Input>;
  where: Promotions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promotions_By_PkArgs = {
  _set?: Maybe<Promotions_Set_Input>;
  pk_columns: Promotions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promotions_ManyArgs = {
  updates: Array<Promotions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RedirectsArgs = {
  _set?: Maybe<Redirects_Set_Input>;
  where: Redirects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Redirects_By_PkArgs = {
  _set?: Maybe<Redirects_Set_Input>;
  pk_columns: Redirects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Redirects_ManyArgs = {
  updates: Array<Redirects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_DriversArgs = {
  _inc?: Maybe<Rental_Drivers_Inc_Input>;
  _set?: Maybe<Rental_Drivers_Set_Input>;
  where: Rental_Drivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Drivers_By_PkArgs = {
  _inc?: Maybe<Rental_Drivers_Inc_Input>;
  _set?: Maybe<Rental_Drivers_Set_Input>;
  pk_columns: Rental_Drivers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Drivers_ManyArgs = {
  updates: Array<Rental_Drivers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Reservation_StatusArgs = {
  _set?: Maybe<Rental_Reservation_Status_Set_Input>;
  where: Rental_Reservation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Reservation_Status_By_PkArgs = {
  _set?: Maybe<Rental_Reservation_Status_Set_Input>;
  pk_columns: Rental_Reservation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Reservation_Status_ManyArgs = {
  updates: Array<Rental_Reservation_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_ReservationsArgs = {
  _set?: Maybe<Rental_Reservations_Set_Input>;
  where: Rental_Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Reservations_By_PkArgs = {
  _set?: Maybe<Rental_Reservations_Set_Input>;
  pk_columns: Rental_Reservations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Reservations_ManyArgs = {
  updates: Array<Rental_Reservations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Booking_Service_TypesArgs = {
  _set?: Maybe<Service_Booking_Service_Types_Set_Input>;
  where: Service_Booking_Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Booking_Service_Types_By_PkArgs = {
  _set?: Maybe<Service_Booking_Service_Types_Set_Input>;
  pk_columns: Service_Booking_Service_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Booking_Service_Types_ManyArgs = {
  updates: Array<Service_Booking_Service_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_BookingsArgs = {
  _inc?: Maybe<Service_Bookings_Inc_Input>;
  _set?: Maybe<Service_Bookings_Set_Input>;
  where: Service_Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Bookings_By_PkArgs = {
  _inc?: Maybe<Service_Bookings_Inc_Input>;
  _set?: Maybe<Service_Bookings_Set_Input>;
  pk_columns: Service_Bookings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Bookings_ManyArgs = {
  updates: Array<Service_Bookings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_TypesArgs = {
  _inc?: Maybe<Service_Types_Inc_Input>;
  _set?: Maybe<Service_Types_Set_Input>;
  where: Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Types_By_PkArgs = {
  _inc?: Maybe<Service_Types_Inc_Input>;
  _set?: Maybe<Service_Types_Set_Input>;
  pk_columns: Service_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Types_ManyArgs = {
  updates: Array<Service_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SessionsArgs = {
  _set?: Maybe<Sessions_Set_Input>;
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_By_PkArgs = {
  _set?: Maybe<Sessions_Set_Input>;
  pk_columns: Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_ManyArgs = {
  updates: Array<Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_AppsArgs = {
  _set?: Maybe<Showroom_Apps_Set_Input>;
  where: Showroom_Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Apps_By_PkArgs = {
  _set?: Maybe<Showroom_Apps_Set_Input>;
  pk_columns: Showroom_Apps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Apps_InputsArgs = {
  _append?: Maybe<Showroom_Apps_Inputs_Append_Input>;
  _delete_at_path?: Maybe<Showroom_Apps_Inputs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Showroom_Apps_Inputs_Delete_Elem_Input>;
  _delete_key?: Maybe<Showroom_Apps_Inputs_Delete_Key_Input>;
  _prepend?: Maybe<Showroom_Apps_Inputs_Prepend_Input>;
  _set?: Maybe<Showroom_Apps_Inputs_Set_Input>;
  where: Showroom_Apps_Inputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Apps_Inputs_By_PkArgs = {
  _append?: Maybe<Showroom_Apps_Inputs_Append_Input>;
  _delete_at_path?: Maybe<Showroom_Apps_Inputs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Showroom_Apps_Inputs_Delete_Elem_Input>;
  _delete_key?: Maybe<Showroom_Apps_Inputs_Delete_Key_Input>;
  _prepend?: Maybe<Showroom_Apps_Inputs_Prepend_Input>;
  _set?: Maybe<Showroom_Apps_Inputs_Set_Input>;
  pk_columns: Showroom_Apps_Inputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Apps_Inputs_ManyArgs = {
  updates: Array<Showroom_Apps_Inputs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Apps_ManyArgs = {
  updates: Array<Showroom_Apps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_LocalesArgs = {
  _set?: Maybe<Showroom_Locales_Set_Input>;
  where: Showroom_Locales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Locales_By_PkArgs = {
  _set?: Maybe<Showroom_Locales_Set_Input>;
  pk_columns: Showroom_Locales_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Locales_ManyArgs = {
  updates: Array<Showroom_Locales_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Pricing_CodesArgs = {
  _set?: Maybe<Showroom_Pricing_Codes_Set_Input>;
  where: Showroom_Pricing_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Pricing_Codes_By_PkArgs = {
  _set?: Maybe<Showroom_Pricing_Codes_Set_Input>;
  pk_columns: Showroom_Pricing_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Pricing_Codes_ManyArgs = {
  updates: Array<Showroom_Pricing_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Stocked_VehiclesArgs = {
  _set?: Maybe<Showroom_Stocked_Vehicles_Set_Input>;
  where: Showroom_Stocked_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Stocked_Vehicles_By_PkArgs = {
  _set?: Maybe<Showroom_Stocked_Vehicles_Set_Input>;
  pk_columns: Showroom_Stocked_Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Stocked_Vehicles_ManyArgs = {
  updates: Array<Showroom_Stocked_Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_UsersArgs = {
  _set?: Maybe<Showroom_Users_Set_Input>;
  where: Showroom_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Users_By_PkArgs = {
  _set?: Maybe<Showroom_Users_Set_Input>;
  pk_columns: Showroom_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Users_ManyArgs = {
  updates: Array<Showroom_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ShowroomsArgs = {
  _append?: Maybe<Showrooms_Append_Input>;
  _delete_at_path?: Maybe<Showrooms_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Showrooms_Delete_Elem_Input>;
  _delete_key?: Maybe<Showrooms_Delete_Key_Input>;
  _prepend?: Maybe<Showrooms_Prepend_Input>;
  _set?: Maybe<Showrooms_Set_Input>;
  where: Showrooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_By_PkArgs = {
  _append?: Maybe<Showrooms_Append_Input>;
  _delete_at_path?: Maybe<Showrooms_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Showrooms_Delete_Elem_Input>;
  _delete_key?: Maybe<Showrooms_Delete_Key_Input>;
  _prepend?: Maybe<Showrooms_Prepend_Input>;
  _set?: Maybe<Showrooms_Set_Input>;
  pk_columns: Showrooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_ManyArgs = {
  updates: Array<Showrooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_PartnersArgs = {
  _set?: Maybe<Showrooms_Partners_Set_Input>;
  where: Showrooms_Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_Partners_By_PkArgs = {
  _set?: Maybe<Showrooms_Partners_Set_Input>;
  pk_columns: Showrooms_Partners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_Partners_ManyArgs = {
  updates: Array<Showrooms_Partners_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_PromotionsArgs = {
  _set?: Maybe<Showrooms_Promotions_Set_Input>;
  where: Showrooms_Promotions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_Promotions_By_PkArgs = {
  _set?: Maybe<Showrooms_Promotions_Set_Input>;
  pk_columns: Showrooms_Promotions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showrooms_Promotions_ManyArgs = {
  updates: Array<Showrooms_Promotions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Finance_ProductsArgs = {
  _set?: Maybe<Stocked_Vehicle_Finance_Products_Set_Input>;
  where: Stocked_Vehicle_Finance_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Finance_Products_By_PkArgs = {
  _set?: Maybe<Stocked_Vehicle_Finance_Products_Set_Input>;
  pk_columns: Stocked_Vehicle_Finance_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Finance_Products_ManyArgs = {
  updates: Array<Stocked_Vehicle_Finance_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Handover_LocationsArgs = {
  _set?: Maybe<Stocked_Vehicle_Handover_Locations_Set_Input>;
  where: Stocked_Vehicle_Handover_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Handover_Locations_By_PkArgs = {
  _set?: Maybe<Stocked_Vehicle_Handover_Locations_Set_Input>;
  pk_columns: Stocked_Vehicle_Handover_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Handover_Locations_ManyArgs = {
  updates: Array<Stocked_Vehicle_Handover_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_OffersArgs = {
  _set?: Maybe<Stocked_Vehicle_Offers_Set_Input>;
  where: Stocked_Vehicle_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Offers_By_PkArgs = {
  _set?: Maybe<Stocked_Vehicle_Offers_Set_Input>;
  pk_columns: Stocked_Vehicle_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicle_Offers_ManyArgs = {
  updates: Array<Stocked_Vehicle_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_VehiclesArgs = {
  _append?: Maybe<Stocked_Vehicles_Append_Input>;
  _delete_at_path?: Maybe<Stocked_Vehicles_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Stocked_Vehicles_Delete_Elem_Input>;
  _delete_key?: Maybe<Stocked_Vehicles_Delete_Key_Input>;
  _inc?: Maybe<Stocked_Vehicles_Inc_Input>;
  _prepend?: Maybe<Stocked_Vehicles_Prepend_Input>;
  _set?: Maybe<Stocked_Vehicles_Set_Input>;
  where: Stocked_Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicles_By_PkArgs = {
  _append?: Maybe<Stocked_Vehicles_Append_Input>;
  _delete_at_path?: Maybe<Stocked_Vehicles_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Stocked_Vehicles_Delete_Elem_Input>;
  _delete_key?: Maybe<Stocked_Vehicles_Delete_Key_Input>;
  _inc?: Maybe<Stocked_Vehicles_Inc_Input>;
  _prepend?: Maybe<Stocked_Vehicles_Prepend_Input>;
  _set?: Maybe<Stocked_Vehicles_Set_Input>;
  pk_columns: Stocked_Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stocked_Vehicles_ManyArgs = {
  updates: Array<Stocked_Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _set?: Maybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _set?: Maybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Test_DrivesArgs = {
  _append?: Maybe<Test_Drives_Append_Input>;
  _delete_at_path?: Maybe<Test_Drives_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Test_Drives_Delete_Elem_Input>;
  _delete_key?: Maybe<Test_Drives_Delete_Key_Input>;
  _prepend?: Maybe<Test_Drives_Prepend_Input>;
  _set?: Maybe<Test_Drives_Set_Input>;
  where: Test_Drives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Drives_By_PkArgs = {
  _append?: Maybe<Test_Drives_Append_Input>;
  _delete_at_path?: Maybe<Test_Drives_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Test_Drives_Delete_Elem_Input>;
  _delete_key?: Maybe<Test_Drives_Delete_Key_Input>;
  _prepend?: Maybe<Test_Drives_Prepend_Input>;
  _set?: Maybe<Test_Drives_Set_Input>;
  pk_columns: Test_Drives_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Drives_ManyArgs = {
  updates: Array<Test_Drives_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_In_PlansArgs = {
  _append?: Maybe<Trade_In_Plans_Append_Input>;
  _delete_at_path?: Maybe<Trade_In_Plans_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Trade_In_Plans_Delete_Elem_Input>;
  _delete_key?: Maybe<Trade_In_Plans_Delete_Key_Input>;
  _inc?: Maybe<Trade_In_Plans_Inc_Input>;
  _prepend?: Maybe<Trade_In_Plans_Prepend_Input>;
  _set?: Maybe<Trade_In_Plans_Set_Input>;
  where: Trade_In_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_In_Plans_By_PkArgs = {
  _append?: Maybe<Trade_In_Plans_Append_Input>;
  _delete_at_path?: Maybe<Trade_In_Plans_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Trade_In_Plans_Delete_Elem_Input>;
  _delete_key?: Maybe<Trade_In_Plans_Delete_Key_Input>;
  _inc?: Maybe<Trade_In_Plans_Inc_Input>;
  _prepend?: Maybe<Trade_In_Plans_Prepend_Input>;
  _set?: Maybe<Trade_In_Plans_Set_Input>;
  pk_columns: Trade_In_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_In_Plans_ManyArgs = {
  updates: Array<Trade_In_Plans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_In_ValuationsArgs = {
  _inc?: Maybe<Trade_In_Valuations_Inc_Input>;
  _set?: Maybe<Trade_In_Valuations_Set_Input>;
  where: Trade_In_Valuations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_In_Valuations_By_PkArgs = {
  _inc?: Maybe<Trade_In_Valuations_Inc_Input>;
  _set?: Maybe<Trade_In_Valuations_Set_Input>;
  pk_columns: Trade_In_Valuations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_In_Valuations_ManyArgs = {
  updates: Array<Trade_In_Valuations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Transport_TypesArgs = {
  _set?: Maybe<Transport_Types_Set_Input>;
  where: Transport_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transport_Types_By_PkArgs = {
  _set?: Maybe<Transport_Types_Set_Input>;
  pk_columns: Transport_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transport_Types_ManyArgs = {
  updates: Array<Transport_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InterestsArgs = {
  _set?: Maybe<User_Interests_Set_Input>;
  where: User_Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Interests_By_PkArgs = {
  _set?: Maybe<User_Interests_Set_Input>;
  pk_columns: User_Interests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Interests_ManyArgs = {
  updates: Array<User_Interests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: Maybe<Users_Append_Input>;
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Delete_Key_Input>;
  _inc?: Maybe<Users_Inc_Input>;
  _prepend?: Maybe<Users_Prepend_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: Maybe<Users_Append_Input>;
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Delete_Key_Input>;
  _inc?: Maybe<Users_Inc_Input>;
  _prepend?: Maybe<Users_Prepend_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ValuersArgs = {
  _set?: Maybe<Valuers_Set_Input>;
  where: Valuers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Valuers_By_PkArgs = {
  _set?: Maybe<Valuers_Set_Input>;
  pk_columns: Valuers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Valuers_ManyArgs = {
  updates: Array<Valuers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_ConditionsArgs = {
  _set?: Maybe<Vehicle_Conditions_Set_Input>;
  where: Vehicle_Conditions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Conditions_By_PkArgs = {
  _set?: Maybe<Vehicle_Conditions_Set_Input>;
  pk_columns: Vehicle_Conditions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Conditions_ManyArgs = {
  updates: Array<Vehicle_Conditions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_ListsArgs = {
  _append?: Maybe<Vehicle_Lists_Append_Input>;
  _delete_at_path?: Maybe<Vehicle_Lists_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vehicle_Lists_Delete_Elem_Input>;
  _delete_key?: Maybe<Vehicle_Lists_Delete_Key_Input>;
  _prepend?: Maybe<Vehicle_Lists_Prepend_Input>;
  _set?: Maybe<Vehicle_Lists_Set_Input>;
  where: Vehicle_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Lists_By_PkArgs = {
  _append?: Maybe<Vehicle_Lists_Append_Input>;
  _delete_at_path?: Maybe<Vehicle_Lists_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vehicle_Lists_Delete_Elem_Input>;
  _delete_key?: Maybe<Vehicle_Lists_Delete_Key_Input>;
  _prepend?: Maybe<Vehicle_Lists_Prepend_Input>;
  _set?: Maybe<Vehicle_Lists_Set_Input>;
  pk_columns: Vehicle_Lists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Lists_ManyArgs = {
  updates: Array<Vehicle_Lists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Order_ActivityArgs = {
  _set?: Maybe<Vehicle_Order_Activity_Set_Input>;
  where: Vehicle_Order_Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Order_Activity_By_PkArgs = {
  _set?: Maybe<Vehicle_Order_Activity_Set_Input>;
  pk_columns: Vehicle_Order_Activity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Order_Activity_ManyArgs = {
  updates: Array<Vehicle_Order_Activity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Order_Product_VariantsArgs = {
  _set?: Maybe<Vehicle_Order_Product_Variants_Set_Input>;
  where: Vehicle_Order_Product_Variants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Order_Product_Variants_By_PkArgs = {
  _set?: Maybe<Vehicle_Order_Product_Variants_Set_Input>;
  pk_columns: Vehicle_Order_Product_Variants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Order_Product_Variants_ManyArgs = {
  updates: Array<Vehicle_Order_Product_Variants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_OrdersArgs = {
  _append?: Maybe<Vehicle_Orders_Append_Input>;
  _delete_at_path?: Maybe<Vehicle_Orders_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vehicle_Orders_Delete_Elem_Input>;
  _delete_key?: Maybe<Vehicle_Orders_Delete_Key_Input>;
  _inc?: Maybe<Vehicle_Orders_Inc_Input>;
  _prepend?: Maybe<Vehicle_Orders_Prepend_Input>;
  _set?: Maybe<Vehicle_Orders_Set_Input>;
  where: Vehicle_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Orders_By_PkArgs = {
  _append?: Maybe<Vehicle_Orders_Append_Input>;
  _delete_at_path?: Maybe<Vehicle_Orders_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vehicle_Orders_Delete_Elem_Input>;
  _delete_key?: Maybe<Vehicle_Orders_Delete_Key_Input>;
  _inc?: Maybe<Vehicle_Orders_Inc_Input>;
  _prepend?: Maybe<Vehicle_Orders_Prepend_Input>;
  _set?: Maybe<Vehicle_Orders_Set_Input>;
  pk_columns: Vehicle_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Orders_ManyArgs = {
  updates: Array<Vehicle_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_SpecificationsArgs = {
  _inc?: Maybe<Vehicle_Specifications_Inc_Input>;
  _set?: Maybe<Vehicle_Specifications_Set_Input>;
  where: Vehicle_Specifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Specifications_By_PkArgs = {
  _inc?: Maybe<Vehicle_Specifications_Inc_Input>;
  _set?: Maybe<Vehicle_Specifications_Set_Input>;
  pk_columns: Vehicle_Specifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Specifications_ManyArgs = {
  updates: Array<Vehicle_Specifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wish_ListArgs = {
  _inc?: Maybe<Wish_List_Inc_Input>;
  _set?: Maybe<Wish_List_Set_Input>;
  where: Wish_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wish_List_By_PkArgs = {
  _inc?: Maybe<Wish_List_Inc_Input>;
  _set?: Maybe<Wish_List_Set_Input>;
  pk_columns: Wish_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wish_List_ManyArgs = {
  updates: Array<Wish_List_Updates>;
};

/** columns and relationships of "notes" */
export type Notes = {
  __typename?: 'notes';
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activities_aggregate: Activities_Aggregate;
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<Users>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  moment?: Maybe<Moments>;
  moment_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  service_booking?: Maybe<Service_Bookings>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<Users>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "notes" */
export type NotesActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "notes" */
export type NotesActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};

/** aggregated selection of "notes" */
export type Notes_Aggregate = {
  __typename?: 'notes_aggregate';
  aggregate?: Maybe<Notes_Aggregate_Fields>;
  nodes: Array<Notes>;
};

export type Notes_Aggregate_Bool_Exp = {
  count?: Maybe<Notes_Aggregate_Bool_Exp_Count>;
};

export type Notes_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notes" */
export type Notes_Aggregate_Fields = {
  __typename?: 'notes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notes_Max_Fields>;
  min?: Maybe<Notes_Min_Fields>;
};


/** aggregate fields of "notes" */
export type Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notes" */
export type Notes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Notes_Max_Order_By>;
  min?: Maybe<Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notes" */
export type Notes_Arr_Rel_Insert_Input = {
  data: Array<Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notes". All fields are combined with a logical 'AND'. */
export type Notes_Bool_Exp = {
  _and?: Maybe<Array<Notes_Bool_Exp>>;
  _not?: Maybe<Notes_Bool_Exp>;
  _or?: Maybe<Array<Notes_Bool_Exp>>;
  activities?: Maybe<Activities_Bool_Exp>;
  activities_aggregate?: Maybe<Activities_Aggregate_Bool_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Users_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  moment?: Maybe<Moments_Bool_Exp>;
  moment_id?: Maybe<Uuid_Comparison_Exp>;
  service_booking?: Maybe<Service_Bookings_Bool_Exp>;
  service_booking_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Users_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notes" */
export enum Notes_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotesPkey = 'notes_pkey'
}

/** input type for inserting data into table "notes" */
export type Notes_Insert_Input = {
  activities?: Maybe<Activities_Arr_Rel_Insert_Input>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Users_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moment?: Maybe<Moments_Obj_Rel_Insert_Input>;
  moment_id?: Maybe<Scalars['uuid']>;
  service_booking?: Maybe<Service_Bookings_Obj_Rel_Insert_Input>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Users_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Notes_Max_Fields = {
  __typename?: 'notes_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moment_id?: Maybe<Scalars['uuid']>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "notes" */
export type Notes_Max_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moment_id?: Maybe<Order_By>;
  service_booking_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Notes_Min_Fields = {
  __typename?: 'notes_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moment_id?: Maybe<Scalars['uuid']>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "notes" */
export type Notes_Min_Order_By = {
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moment_id?: Maybe<Order_By>;
  service_booking_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "notes" */
export type Notes_Mutation_Response = {
  __typename?: 'notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notes>;
};

/** input type for inserting object relation for remote table "notes" */
export type Notes_Obj_Rel_Insert_Input = {
  data: Notes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Notes_On_Conflict>;
};

/** on_conflict condition type for table "notes" */
export type Notes_On_Conflict = {
  constraint: Notes_Constraint;
  update_columns?: Array<Notes_Update_Column>;
  where?: Maybe<Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "notes". */
export type Notes_Order_By = {
  activities_aggregate?: Maybe<Activities_Aggregate_Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Users_Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  moment?: Maybe<Moments_Order_By>;
  moment_id?: Maybe<Order_By>;
  service_booking?: Maybe<Service_Bookings_Order_By>;
  service_booking_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Users_Order_By>;
  updated_by_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: notes */
export type Notes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notes" */
export enum Notes_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MomentId = 'moment_id',
  /** column name */
  ServiceBookingId = 'service_booking_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "notes" */
export type Notes_Set_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moment_id?: Maybe<Scalars['uuid']>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "notes" */
export type Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notes_Stream_Cursor_Value_Input = {
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  moment_id?: Maybe<Scalars['uuid']>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "notes" */
export enum Notes_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MomentId = 'moment_id',
  /** column name */
  ServiceBookingId = 'service_booking_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Notes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notes_Bool_Exp;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "offer_types" */
export type Offer_Types = {
  __typename?: 'offer_types';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "offer_types" */
export type Offer_Types_Aggregate = {
  __typename?: 'offer_types_aggregate';
  aggregate?: Maybe<Offer_Types_Aggregate_Fields>;
  nodes: Array<Offer_Types>;
};

/** aggregate fields of "offer_types" */
export type Offer_Types_Aggregate_Fields = {
  __typename?: 'offer_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Offer_Types_Max_Fields>;
  min?: Maybe<Offer_Types_Min_Fields>;
};


/** aggregate fields of "offer_types" */
export type Offer_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Offer_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "offer_types". All fields are combined with a logical 'AND'. */
export type Offer_Types_Bool_Exp = {
  _and?: Maybe<Array<Offer_Types_Bool_Exp>>;
  _not?: Maybe<Offer_Types_Bool_Exp>;
  _or?: Maybe<Array<Offer_Types_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "offer_types" */
export enum Offer_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  OfferTypesPkey = 'offer_types_pkey'
}

export enum Offer_Types_Enum {
  /** An offer related to a financial product. */
  Finance = 'finance',
  /** A general offer provided as part of a vehicle order. */
  Order = 'order'
}

/** Boolean expression to compare columns of type "offer_types_enum". All fields are combined with logical 'AND'. */
export type Offer_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Offer_Types_Enum>;
  _in?: Maybe<Array<Offer_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Offer_Types_Enum>;
  _nin?: Maybe<Array<Offer_Types_Enum>>;
};

/** input type for inserting data into table "offer_types" */
export type Offer_Types_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Offer_Types_Max_Fields = {
  __typename?: 'offer_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Offer_Types_Min_Fields = {
  __typename?: 'offer_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "offer_types" */
export type Offer_Types_Mutation_Response = {
  __typename?: 'offer_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Offer_Types>;
};

/** on_conflict condition type for table "offer_types" */
export type Offer_Types_On_Conflict = {
  constraint: Offer_Types_Constraint;
  update_columns?: Array<Offer_Types_Update_Column>;
  where?: Maybe<Offer_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "offer_types". */
export type Offer_Types_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: offer_types */
export type Offer_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "offer_types" */
export enum Offer_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "offer_types" */
export type Offer_Types_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "offer_types" */
export type Offer_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Offer_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Offer_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "offer_types" */
export enum Offer_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Offer_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Offer_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Offer_Types_Bool_Exp;
};

/** columns and relationships of "offers" */
export type Offers = {
  __typename?: 'offers';
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  available_from: Scalars['timestamptz'];
  available_to?: Maybe<Scalars['timestamptz']>;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['jsonb']>;
  cta_href?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_button_label?: Maybe<Scalars['String']>;
  disclaimer_href?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_published: Scalars['Boolean'];
  is_trashed: Scalars['Boolean'];
  label: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  tags: Scalars['jsonb'];
  type?: Maybe<Offer_Types_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "offers" */
export type OffersAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "offers" */
export type OffersAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "offers" */
export type OffersCriteriaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "offers" */
export type OffersTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "offers" */
export type Offers_Aggregate = {
  __typename?: 'offers_aggregate';
  aggregate?: Maybe<Offers_Aggregate_Fields>;
  nodes: Array<Offers>;
};

/** aggregate fields of "offers" */
export type Offers_Aggregate_Fields = {
  __typename?: 'offers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Offers_Max_Fields>;
  min?: Maybe<Offers_Min_Fields>;
};


/** aggregate fields of "offers" */
export type Offers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Offers_Append_Input = {
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "offers". All fields are combined with a logical 'AND'. */
export type Offers_Bool_Exp = {
  _and?: Maybe<Array<Offers_Bool_Exp>>;
  _not?: Maybe<Offers_Bool_Exp>;
  _or?: Maybe<Array<Offers_Bool_Exp>>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  available_from?: Maybe<Timestamptz_Comparison_Exp>;
  available_to?: Maybe<Timestamptz_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  criteria?: Maybe<Jsonb_Comparison_Exp>;
  cta_href?: Maybe<String_Comparison_Exp>;
  cta_label?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  disclaimer?: Maybe<String_Comparison_Exp>;
  disclaimer_button_label?: Maybe<String_Comparison_Exp>;
  disclaimer_href?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_published?: Maybe<Boolean_Comparison_Exp>;
  is_trashed?: Maybe<Boolean_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<Offer_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "offers" */
export enum Offers_Constraint {
  /** unique or primary key constraint on columns "code" */
  OffersCodeKey = 'offers_code_key',
  /** unique or primary key constraint on columns "id" */
  OffersPkey = 'offers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Offers_Delete_At_Path_Input = {
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Offers_Delete_Elem_Input = {
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Offers_Delete_Key_Input = {
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "offers" */
export type Offers_Insert_Input = {
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['jsonb']>;
  cta_href?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_button_label?: Maybe<Scalars['String']>;
  disclaimer_href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Offer_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Offers_Max_Fields = {
  __typename?: 'offers_max_fields';
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cta_href?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_button_label?: Maybe<Scalars['String']>;
  disclaimer_href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Offers_Min_Fields = {
  __typename?: 'offers_min_fields';
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cta_href?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_button_label?: Maybe<Scalars['String']>;
  disclaimer_href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "offers" */
export type Offers_Mutation_Response = {
  __typename?: 'offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Offers>;
};

/** input type for inserting object relation for remote table "offers" */
export type Offers_Obj_Rel_Insert_Input = {
  data: Offers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Offers_On_Conflict>;
};

/** on_conflict condition type for table "offers" */
export type Offers_On_Conflict = {
  constraint: Offers_Constraint;
  update_columns?: Array<Offers_Update_Column>;
  where?: Maybe<Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "offers". */
export type Offers_Order_By = {
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  available_from?: Maybe<Order_By>;
  available_to?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  criteria?: Maybe<Order_By>;
  cta_href?: Maybe<Order_By>;
  cta_label?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  disclaimer?: Maybe<Order_By>;
  disclaimer_button_label?: Maybe<Order_By>;
  disclaimer_href?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_published?: Maybe<Order_By>;
  is_trashed?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: offers */
export type Offers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Offers_Prepend_Input = {
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['jsonb']>;
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "offers" */
export enum Offers_Select_Column {
  /** column name */
  AvailableFrom = 'available_from',
  /** column name */
  AvailableTo = 'available_to',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  CtaHref = 'cta_href',
  /** column name */
  CtaLabel = 'cta_label',
  /** column name */
  Description = 'description',
  /** column name */
  Disclaimer = 'disclaimer',
  /** column name */
  DisclaimerButtonLabel = 'disclaimer_button_label',
  /** column name */
  DisclaimerHref = 'disclaimer_href',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "offers" */
export type Offers_Set_Input = {
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['jsonb']>;
  cta_href?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_button_label?: Maybe<Scalars['String']>;
  disclaimer_href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Offer_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "offers" */
export type Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Offers_Stream_Cursor_Value_Input = {
  available_from?: Maybe<Scalars['timestamptz']>;
  available_to?: Maybe<Scalars['timestamptz']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** A Stocked_Vehicles_Bool_Exp object. Note, this will be null when type=finance. */
  criteria?: Maybe<Scalars['jsonb']>;
  cta_href?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  disclaimer_button_label?: Maybe<Scalars['String']>;
  disclaimer_href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Offer_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "offers" */
export enum Offers_Update_Column {
  /** column name */
  AvailableFrom = 'available_from',
  /** column name */
  AvailableTo = 'available_to',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  CtaHref = 'cta_href',
  /** column name */
  CtaLabel = 'cta_label',
  /** column name */
  Description = 'description',
  /** column name */
  Disclaimer = 'disclaimer',
  /** column name */
  DisclaimerButtonLabel = 'disclaimer_button_label',
  /** column name */
  DisclaimerHref = 'disclaimer_href',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Offers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Offers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Offers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Offers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Offers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Offers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Offers_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "pages" */
export type Pages = {
  __typename?: 'pages';
  created_at: Scalars['timestamptz'];
  design?: Maybe<Scalars['jsonb']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_published: Scalars['Boolean'];
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  sections?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  version: Scalars['Int'];
};


/** columns and relationships of "pages" */
export type PagesDesignArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pages" */
export type PagesSectionsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pages" */
export type Pages_Aggregate = {
  __typename?: 'pages_aggregate';
  aggregate?: Maybe<Pages_Aggregate_Fields>;
  nodes: Array<Pages>;
};

export type Pages_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Pages_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Pages_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Pages_Aggregate_Bool_Exp_Count>;
};

export type Pages_Aggregate_Bool_Exp_Bool_And = {
  arguments: Pages_Select_Column_Pages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Pages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Pages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Pages_Select_Column_Pages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Pages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Pages_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Pages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Pages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pages" */
export type Pages_Aggregate_Fields = {
  __typename?: 'pages_aggregate_fields';
  avg?: Maybe<Pages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pages_Max_Fields>;
  min?: Maybe<Pages_Min_Fields>;
  stddev?: Maybe<Pages_Stddev_Fields>;
  stddev_pop?: Maybe<Pages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pages_Stddev_Samp_Fields>;
  sum?: Maybe<Pages_Sum_Fields>;
  var_pop?: Maybe<Pages_Var_Pop_Fields>;
  var_samp?: Maybe<Pages_Var_Samp_Fields>;
  variance?: Maybe<Pages_Variance_Fields>;
};


/** aggregate fields of "pages" */
export type Pages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pages" */
export type Pages_Aggregate_Order_By = {
  avg?: Maybe<Pages_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pages_Max_Order_By>;
  min?: Maybe<Pages_Min_Order_By>;
  stddev?: Maybe<Pages_Stddev_Order_By>;
  stddev_pop?: Maybe<Pages_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pages_Stddev_Samp_Order_By>;
  sum?: Maybe<Pages_Sum_Order_By>;
  var_pop?: Maybe<Pages_Var_Pop_Order_By>;
  var_samp?: Maybe<Pages_Var_Samp_Order_By>;
  variance?: Maybe<Pages_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pages_Append_Input = {
  design?: Maybe<Scalars['jsonb']>;
  sections?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pages" */
export type Pages_Arr_Rel_Insert_Input = {
  data: Array<Pages_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Pages_On_Conflict>;
};

/** aggregate avg on columns */
export type Pages_Avg_Fields = {
  __typename?: 'pages_avg_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pages" */
export type Pages_Avg_Order_By = {
  version?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pages". All fields are combined with a logical 'AND'. */
export type Pages_Bool_Exp = {
  _and?: Maybe<Array<Pages_Bool_Exp>>;
  _not?: Maybe<Pages_Bool_Exp>;
  _or?: Maybe<Array<Pages_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  design?: Maybe<Jsonb_Comparison_Exp>;
  html?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_published?: Maybe<Boolean_Comparison_Exp>;
  meta_description?: Maybe<String_Comparison_Exp>;
  meta_title?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  sections?: Maybe<Jsonb_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "pages" */
export enum Pages_Constraint {
  /** unique or primary key constraint on columns "showroom_id", "path" */
  PagesPathShowroomIdKey = 'pages_path_showroom_id_key',
  /** unique or primary key constraint on columns "id" */
  PagesPkey = 'pages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pages_Delete_At_Path_Input = {
  design?: Maybe<Array<Scalars['String']>>;
  sections?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pages_Delete_Elem_Input = {
  design?: Maybe<Scalars['Int']>;
  sections?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pages_Delete_Key_Input = {
  design?: Maybe<Scalars['String']>;
  sections?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "pages" */
export type Pages_Inc_Input = {
  version?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pages" */
export type Pages_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  design?: Maybe<Scalars['jsonb']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sections?: Maybe<Scalars['jsonb']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Pages_Max_Fields = {
  __typename?: 'pages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pages" */
export type Pages_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  meta_title?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pages_Min_Fields = {
  __typename?: 'pages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pages" */
export type Pages_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  meta_title?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "pages" */
export type Pages_Mutation_Response = {
  __typename?: 'pages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pages>;
};

/** input type for inserting object relation for remote table "pages" */
export type Pages_Obj_Rel_Insert_Input = {
  data: Pages_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Pages_On_Conflict>;
};

/** on_conflict condition type for table "pages" */
export type Pages_On_Conflict = {
  constraint: Pages_Constraint;
  update_columns?: Array<Pages_Update_Column>;
  where?: Maybe<Pages_Bool_Exp>;
};

/** Ordering options when selecting data from "pages". */
export type Pages_Order_By = {
  created_at?: Maybe<Order_By>;
  design?: Maybe<Order_By>;
  html?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_published?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  meta_title?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  sections?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: pages */
export type Pages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pages_Prepend_Input = {
  design?: Maybe<Scalars['jsonb']>;
  sections?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pages" */
export enum Pages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Design = 'design',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  Sections = 'sections',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** select "pages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pages" */
export enum Pages_Select_Column_Pages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublished = 'is_published'
}

/** select "pages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pages" */
export enum Pages_Select_Column_Pages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublished = 'is_published'
}

/** input type for updating data in table "pages" */
export type Pages_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  design?: Maybe<Scalars['jsonb']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sections?: Maybe<Scalars['jsonb']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Pages_Stddev_Fields = {
  __typename?: 'pages_stddev_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pages" */
export type Pages_Stddev_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pages_Stddev_Pop_Fields = {
  __typename?: 'pages_stddev_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pages" */
export type Pages_Stddev_Pop_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pages_Stddev_Samp_Fields = {
  __typename?: 'pages_stddev_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pages" */
export type Pages_Stddev_Samp_Order_By = {
  version?: Maybe<Order_By>;
};

/** Streaming cursor of the table "pages" */
export type Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pages_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  design?: Maybe<Scalars['jsonb']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sections?: Maybe<Scalars['jsonb']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Pages_Sum_Fields = {
  __typename?: 'pages_sum_fields';
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pages" */
export type Pages_Sum_Order_By = {
  version?: Maybe<Order_By>;
};

/** update columns of table "pages" */
export enum Pages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Design = 'design',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  Sections = 'sections',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

export type Pages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Pages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Pages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Pages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Pages_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Pages_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Pages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Pages_Var_Pop_Fields = {
  __typename?: 'pages_var_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pages" */
export type Pages_Var_Pop_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pages_Var_Samp_Fields = {
  __typename?: 'pages_var_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pages" */
export type Pages_Var_Samp_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pages_Variance_Fields = {
  __typename?: 'pages_variance_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pages" */
export type Pages_Variance_Order_By = {
  version?: Maybe<Order_By>;
};

/** columns and relationships of "partners" */
export type Partners = {
  __typename?: 'partners';
  id: Scalars['uuid'];
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  partner_showroom: Array<Showrooms_Partners>;
  /** An aggregate relationship */
  partner_showroom_aggregate: Showrooms_Partners_Aggregate;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "partners" */
export type PartnersPartner_ShowroomArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


/** columns and relationships of "partners" */
export type PartnersPartner_Showroom_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};

/** aggregated selection of "partners" */
export type Partners_Aggregate = {
  __typename?: 'partners_aggregate';
  aggregate?: Maybe<Partners_Aggregate_Fields>;
  nodes: Array<Partners>;
};

/** aggregate fields of "partners" */
export type Partners_Aggregate_Fields = {
  __typename?: 'partners_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Partners_Max_Fields>;
  min?: Maybe<Partners_Min_Fields>;
};


/** aggregate fields of "partners" */
export type Partners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Partners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "partners". All fields are combined with a logical 'AND'. */
export type Partners_Bool_Exp = {
  _and?: Maybe<Array<Partners_Bool_Exp>>;
  _not?: Maybe<Partners_Bool_Exp>;
  _or?: Maybe<Array<Partners_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  intro?: Maybe<String_Comparison_Exp>;
  logo?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  partner_showroom?: Maybe<Showrooms_Partners_Bool_Exp>;
  partner_showroom_aggregate?: Maybe<Showrooms_Partners_Aggregate_Bool_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "partners" */
export enum Partners_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartnersPkey = 'partners_pkey'
}

/** input type for inserting data into table "partners" */
export type Partners_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partner_showroom?: Maybe<Showrooms_Partners_Arr_Rel_Insert_Input>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Partners_Max_Fields = {
  __typename?: 'partners_max_fields';
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Partners_Min_Fields = {
  __typename?: 'partners_min_fields';
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "partners" */
export type Partners_Mutation_Response = {
  __typename?: 'partners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partners>;
};

/** input type for inserting object relation for remote table "partners" */
export type Partners_Obj_Rel_Insert_Input = {
  data: Partners_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Partners_On_Conflict>;
};

/** on_conflict condition type for table "partners" */
export type Partners_On_Conflict = {
  constraint: Partners_Constraint;
  update_columns?: Array<Partners_Update_Column>;
  where?: Maybe<Partners_Bool_Exp>;
};

/** Ordering options when selecting data from "partners". */
export type Partners_Order_By = {
  id?: Maybe<Order_By>;
  intro?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  partner_showroom_aggregate?: Maybe<Showrooms_Partners_Aggregate_Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: partners */
export type Partners_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "partners" */
export enum Partners_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "partners" */
export type Partners_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "partners" */
export type Partners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partners_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** update columns of table "partners" */
export enum Partners_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Website = 'website'
}

export type Partners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Partners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partners_Bool_Exp;
};

/** columns and relationships of "payment_codes" */
export type Payment_Codes = {
  __typename?: 'payment_codes';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "payment_codes" */
export type Payment_CodesPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "payment_codes" */
export type Payment_CodesPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** aggregated selection of "payment_codes" */
export type Payment_Codes_Aggregate = {
  __typename?: 'payment_codes_aggregate';
  aggregate?: Maybe<Payment_Codes_Aggregate_Fields>;
  nodes: Array<Payment_Codes>;
};

/** aggregate fields of "payment_codes" */
export type Payment_Codes_Aggregate_Fields = {
  __typename?: 'payment_codes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Codes_Max_Fields>;
  min?: Maybe<Payment_Codes_Min_Fields>;
};


/** aggregate fields of "payment_codes" */
export type Payment_Codes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Codes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_codes". All fields are combined with a logical 'AND'. */
export type Payment_Codes_Bool_Exp = {
  _and?: Maybe<Array<Payment_Codes_Bool_Exp>>;
  _not?: Maybe<Payment_Codes_Bool_Exp>;
  _or?: Maybe<Array<Payment_Codes_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  payments_aggregate?: Maybe<Payments_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_codes" */
export enum Payment_Codes_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentCodesPkey = 'payment_codes_pkey'
}

export enum Payment_Codes_Enum {
  /** Vehicle order holding deposit */
  Refdep = 'REFDEP',
  /** Rental reservation */
  Rental = 'RENTAL',
  /** A refundable rental bond (deposit). */
  Rentalbond = 'RENTALBOND',
  /** Vehicle order settlement */
  Settle = 'SETTLE'
}

/** Boolean expression to compare columns of type "payment_codes_enum". All fields are combined with logical 'AND'. */
export type Payment_Codes_Enum_Comparison_Exp = {
  _eq?: Maybe<Payment_Codes_Enum>;
  _in?: Maybe<Array<Payment_Codes_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Payment_Codes_Enum>;
  _nin?: Maybe<Array<Payment_Codes_Enum>>;
};

/** input type for inserting data into table "payment_codes" */
export type Payment_Codes_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  payments?: Maybe<Payments_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Codes_Max_Fields = {
  __typename?: 'payment_codes_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Codes_Min_Fields = {
  __typename?: 'payment_codes_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_codes" */
export type Payment_Codes_Mutation_Response = {
  __typename?: 'payment_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Codes>;
};

/** input type for inserting object relation for remote table "payment_codes" */
export type Payment_Codes_Obj_Rel_Insert_Input = {
  data: Payment_Codes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Payment_Codes_On_Conflict>;
};

/** on_conflict condition type for table "payment_codes" */
export type Payment_Codes_On_Conflict = {
  constraint: Payment_Codes_Constraint;
  update_columns?: Array<Payment_Codes_Update_Column>;
  where?: Maybe<Payment_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_codes". */
export type Payment_Codes_Order_By = {
  description?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: payment_codes */
export type Payment_Codes_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "payment_codes" */
export enum Payment_Codes_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_codes" */
export type Payment_Codes_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_codes" */
export type Payment_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Codes_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "payment_codes" */
export enum Payment_Codes_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Payment_Codes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Codes_Bool_Exp;
};

/** columns and relationships of "payment_method_types" */
export type Payment_Method_Types = {
  __typename?: 'payment_method_types';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  payment_methods: Array<Payment_Methods>;
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "payment_method_types" */
export type Payment_Method_TypesPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


/** columns and relationships of "payment_method_types" */
export type Payment_Method_TypesPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


/** columns and relationships of "payment_method_types" */
export type Payment_Method_TypesPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "payment_method_types" */
export type Payment_Method_TypesPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** aggregated selection of "payment_method_types" */
export type Payment_Method_Types_Aggregate = {
  __typename?: 'payment_method_types_aggregate';
  aggregate?: Maybe<Payment_Method_Types_Aggregate_Fields>;
  nodes: Array<Payment_Method_Types>;
};

/** aggregate fields of "payment_method_types" */
export type Payment_Method_Types_Aggregate_Fields = {
  __typename?: 'payment_method_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Method_Types_Max_Fields>;
  min?: Maybe<Payment_Method_Types_Min_Fields>;
};


/** aggregate fields of "payment_method_types" */
export type Payment_Method_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Method_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_method_types". All fields are combined with a logical 'AND'. */
export type Payment_Method_Types_Bool_Exp = {
  _and?: Maybe<Array<Payment_Method_Types_Bool_Exp>>;
  _not?: Maybe<Payment_Method_Types_Bool_Exp>;
  _or?: Maybe<Array<Payment_Method_Types_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  payment_methods?: Maybe<Payment_Methods_Bool_Exp>;
  payment_methods_aggregate?: Maybe<Payment_Methods_Aggregate_Bool_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  payments_aggregate?: Maybe<Payments_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_method_types" */
export enum Payment_Method_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentMethodsPkey = 'payment_methods_pkey'
}

export enum Payment_Method_Types_Enum {
  /** Electronic funds transfer to a bank account. */
  BankTransfer = 'BANK_TRANSFER',
  /** Online or in person card payment. */
  CreditCard = 'CREDIT_CARD'
}

/** Boolean expression to compare columns of type "payment_method_types_enum". All fields are combined with logical 'AND'. */
export type Payment_Method_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Payment_Method_Types_Enum>;
  _in?: Maybe<Array<Payment_Method_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Payment_Method_Types_Enum>;
  _nin?: Maybe<Array<Payment_Method_Types_Enum>>;
};

/** input type for inserting data into table "payment_method_types" */
export type Payment_Method_Types_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  payment_methods?: Maybe<Payment_Methods_Arr_Rel_Insert_Input>;
  payments?: Maybe<Payments_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Method_Types_Max_Fields = {
  __typename?: 'payment_method_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Method_Types_Min_Fields = {
  __typename?: 'payment_method_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_method_types" */
export type Payment_Method_Types_Mutation_Response = {
  __typename?: 'payment_method_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method_Types>;
};

/** input type for inserting object relation for remote table "payment_method_types" */
export type Payment_Method_Types_Obj_Rel_Insert_Input = {
  data: Payment_Method_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Payment_Method_Types_On_Conflict>;
};

/** on_conflict condition type for table "payment_method_types" */
export type Payment_Method_Types_On_Conflict = {
  constraint: Payment_Method_Types_Constraint;
  update_columns?: Array<Payment_Method_Types_Update_Column>;
  where?: Maybe<Payment_Method_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_method_types". */
export type Payment_Method_Types_Order_By = {
  description?: Maybe<Order_By>;
  payment_methods_aggregate?: Maybe<Payment_Methods_Aggregate_Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: payment_method_types */
export type Payment_Method_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "payment_method_types" */
export enum Payment_Method_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_method_types" */
export type Payment_Method_Types_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_method_types" */
export type Payment_Method_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Method_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Types_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "payment_method_types" */
export enum Payment_Method_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Payment_Method_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_Method_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Method_Types_Bool_Exp;
};

/** columns and relationships of "payment_methods" */
export type Payment_Methods = {
  __typename?: 'payment_methods';
  card_brand?: Maybe<Scalars['String']>;
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
  card_last_4?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_setup: Scalars['Boolean'];
  /** An object relationship */
  payment_method_type: Payment_Method_Types;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Scalars['String']>;
  type: Payment_Method_Types_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "payment_methods" */
export type Payment_MethodsPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "payment_methods" */
export type Payment_MethodsPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** aggregated selection of "payment_methods" */
export type Payment_Methods_Aggregate = {
  __typename?: 'payment_methods_aggregate';
  aggregate?: Maybe<Payment_Methods_Aggregate_Fields>;
  nodes: Array<Payment_Methods>;
};

export type Payment_Methods_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Payment_Methods_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Payment_Methods_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Payment_Methods_Aggregate_Bool_Exp_Count>;
};

export type Payment_Methods_Aggregate_Bool_Exp_Bool_And = {
  arguments: Payment_Methods_Select_Column_Payment_Methods_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Methods_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Methods_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Payment_Methods_Select_Column_Payment_Methods_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Methods_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Methods_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Payment_Methods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Methods_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_Fields = {
  __typename?: 'payment_methods_aggregate_fields';
  avg?: Maybe<Payment_Methods_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Methods_Max_Fields>;
  min?: Maybe<Payment_Methods_Min_Fields>;
  stddev?: Maybe<Payment_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Methods_Sum_Fields>;
  var_pop?: Maybe<Payment_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Methods_Var_Samp_Fields>;
  variance?: Maybe<Payment_Methods_Variance_Fields>;
};


/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Methods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_methods" */
export type Payment_Methods_Aggregate_Order_By = {
  avg?: Maybe<Payment_Methods_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Methods_Max_Order_By>;
  min?: Maybe<Payment_Methods_Min_Order_By>;
  stddev?: Maybe<Payment_Methods_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Methods_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Methods_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Methods_Sum_Order_By>;
  var_pop?: Maybe<Payment_Methods_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Methods_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Methods_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_methods" */
export type Payment_Methods_Arr_Rel_Insert_Input = {
  data: Array<Payment_Methods_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Payment_Methods_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Methods_Avg_Fields = {
  __typename?: 'payment_methods_avg_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_methods" */
export type Payment_Methods_Avg_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_methods". All fields are combined with a logical 'AND'. */
export type Payment_Methods_Bool_Exp = {
  _and?: Maybe<Array<Payment_Methods_Bool_Exp>>;
  _not?: Maybe<Payment_Methods_Bool_Exp>;
  _or?: Maybe<Array<Payment_Methods_Bool_Exp>>;
  card_brand?: Maybe<String_Comparison_Exp>;
  card_expires_month?: Maybe<Int_Comparison_Exp>;
  card_expires_year?: Maybe<Int_Comparison_Exp>;
  card_last_4?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_setup?: Maybe<Boolean_Comparison_Exp>;
  payment_method_type?: Maybe<Payment_Method_Types_Bool_Exp>;
  payment_provider_name?: Maybe<String_Comparison_Exp>;
  payment_provider_reference?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  payments_aggregate?: Maybe<Payments_Aggregate_Bool_Exp>;
  setup_url?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Payment_Method_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_methods" */
export enum Payment_Methods_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentMethodsPkey1 = 'payment_methods_pkey1'
}

/** input type for incrementing numeric columns in table "payment_methods" */
export type Payment_Methods_Inc_Input = {
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_methods" */
export type Payment_Methods_Insert_Input = {
  card_brand?: Maybe<Scalars['String']>;
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
  card_last_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_setup?: Maybe<Scalars['Boolean']>;
  payment_method_type?: Maybe<Payment_Method_Types_Obj_Rel_Insert_Input>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  payments?: Maybe<Payments_Arr_Rel_Insert_Input>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Scalars['String']>;
  type?: Maybe<Payment_Method_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payment_Methods_Max_Fields = {
  __typename?: 'payment_methods_max_fields';
  card_brand?: Maybe<Scalars['String']>;
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
  card_last_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payment_methods" */
export type Payment_Methods_Max_Order_By = {
  card_brand?: Maybe<Order_By>;
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
  card_last_4?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Methods_Min_Fields = {
  __typename?: 'payment_methods_min_fields';
  card_brand?: Maybe<Scalars['String']>;
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
  card_last_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payment_methods" */
export type Payment_Methods_Min_Order_By = {
  card_brand?: Maybe<Order_By>;
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
  card_last_4?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment_methods" */
export type Payment_Methods_Mutation_Response = {
  __typename?: 'payment_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Methods>;
};

/** input type for inserting object relation for remote table "payment_methods" */
export type Payment_Methods_Obj_Rel_Insert_Input = {
  data: Payment_Methods_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Payment_Methods_On_Conflict>;
};

/** on_conflict condition type for table "payment_methods" */
export type Payment_Methods_On_Conflict = {
  constraint: Payment_Methods_Constraint;
  update_columns?: Array<Payment_Methods_Update_Column>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_methods". */
export type Payment_Methods_Order_By = {
  card_brand?: Maybe<Order_By>;
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
  card_last_4?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_setup?: Maybe<Order_By>;
  payment_method_type?: Maybe<Payment_Method_Types_Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  setup_url?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: payment_methods */
export type Payment_Methods_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payment_methods" */
export enum Payment_Methods_Select_Column {
  /** column name */
  CardBrand = 'card_brand',
  /** column name */
  CardExpiresMonth = 'card_expires_month',
  /** column name */
  CardExpiresYear = 'card_expires_year',
  /** column name */
  CardLast_4 = 'card_last_4',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsSetup = 'is_setup',
  /** column name */
  PaymentProviderName = 'payment_provider_name',
  /** column name */
  PaymentProviderReference = 'payment_provider_reference',
  /** column name */
  SetupUrl = 'setup_url',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "payment_methods_aggregate_bool_exp_bool_and_arguments_columns" columns of table "payment_methods" */
export enum Payment_Methods_Select_Column_Payment_Methods_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsSetup = 'is_setup'
}

/** select "payment_methods_aggregate_bool_exp_bool_or_arguments_columns" columns of table "payment_methods" */
export enum Payment_Methods_Select_Column_Payment_Methods_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsSetup = 'is_setup'
}

/** input type for updating data in table "payment_methods" */
export type Payment_Methods_Set_Input = {
  card_brand?: Maybe<Scalars['String']>;
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
  card_last_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_setup?: Maybe<Scalars['Boolean']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Scalars['String']>;
  type?: Maybe<Payment_Method_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payment_Methods_Stddev_Fields = {
  __typename?: 'payment_methods_stddev_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_methods" */
export type Payment_Methods_Stddev_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Methods_Stddev_Pop_Fields = {
  __typename?: 'payment_methods_stddev_pop_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_methods" */
export type Payment_Methods_Stddev_Pop_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Methods_Stddev_Samp_Fields = {
  __typename?: 'payment_methods_stddev_samp_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_methods" */
export type Payment_Methods_Stddev_Samp_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** Streaming cursor of the table "payment_methods" */
export type Payment_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Methods_Stream_Cursor_Value_Input = {
  card_brand?: Maybe<Scalars['String']>;
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
  card_last_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_setup?: Maybe<Scalars['Boolean']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** An absolute URL to setup the payment method. */
  setup_url?: Maybe<Scalars['String']>;
  type?: Maybe<Payment_Method_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Payment_Methods_Sum_Fields = {
  __typename?: 'payment_methods_sum_fields';
  card_expires_month?: Maybe<Scalars['Int']>;
  card_expires_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment_methods" */
export type Payment_Methods_Sum_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** update columns of table "payment_methods" */
export enum Payment_Methods_Update_Column {
  /** column name */
  CardBrand = 'card_brand',
  /** column name */
  CardExpiresMonth = 'card_expires_month',
  /** column name */
  CardExpiresYear = 'card_expires_year',
  /** column name */
  CardLast_4 = 'card_last_4',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsSetup = 'is_setup',
  /** column name */
  PaymentProviderName = 'payment_provider_name',
  /** column name */
  PaymentProviderReference = 'payment_provider_reference',
  /** column name */
  SetupUrl = 'setup_url',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Payment_Methods_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Payment_Methods_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Methods_Var_Pop_Fields = {
  __typename?: 'payment_methods_var_pop_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_methods" */
export type Payment_Methods_Var_Pop_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Methods_Var_Samp_Fields = {
  __typename?: 'payment_methods_var_samp_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_methods" */
export type Payment_Methods_Var_Samp_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Methods_Variance_Fields = {
  __typename?: 'payment_methods_variance_fields';
  card_expires_month?: Maybe<Scalars['Float']>;
  card_expires_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_methods" */
export type Payment_Methods_Variance_Order_By = {
  card_expires_month?: Maybe<Order_By>;
  card_expires_year?: Maybe<Order_By>;
};

/** columns and relationships of "payments" */
export type Payments = {
  __typename?: 'payments';
  authorised_amount?: Maybe<Scalars['numeric']>;
  authorised_at?: Maybe<Scalars['timestamptz']>;
  cancel_url?: Maybe<Scalars['String']>;
  code: Payment_Codes_Enum;
  contact_email?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  due_date: Scalars['date'];
  gross_amount: Scalars['numeric'];
  id: Scalars['uuid'];
  media: Scalars['jsonb'];
  metadata: Scalars['jsonb'];
  /** The payment method type */
  method: Payment_Method_Types_Enum;
  name?: Maybe<Scalars['String']>;
  net_amount: Scalars['numeric'];
  /** An object relationship */
  payment_code: Payment_Codes;
  /** An object relationship */
  payment_method?: Maybe<Payment_Methods>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  payment_method_type: Payment_Method_Types;
  payment_provider_name?: Maybe<Scalars['String']>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Scalars['String']>;
  received_amount: Scalars['numeric'];
  received_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rental_reservation?: Maybe<Rental_Reservations>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  showroom?: Maybe<Showrooms>;
  showroom_id: Scalars['uuid'];
  success_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "payments" */
export type PaymentsMediaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "payments" */
export type PaymentsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payments" */
export type Payments_Aggregate = {
  __typename?: 'payments_aggregate';
  aggregate?: Maybe<Payments_Aggregate_Fields>;
  nodes: Array<Payments>;
};

export type Payments_Aggregate_Bool_Exp = {
  count?: Maybe<Payments_Aggregate_Bool_Exp_Count>;
};

export type Payments_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payments" */
export type Payments_Aggregate_Fields = {
  __typename?: 'payments_aggregate_fields';
  avg?: Maybe<Payments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payments_Max_Fields>;
  min?: Maybe<Payments_Min_Fields>;
  stddev?: Maybe<Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Payments_Sum_Fields>;
  var_pop?: Maybe<Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Payments_Var_Samp_Fields>;
  variance?: Maybe<Payments_Variance_Fields>;
};


/** aggregate fields of "payments" */
export type Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payments" */
export type Payments_Aggregate_Order_By = {
  avg?: Maybe<Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payments_Max_Order_By>;
  min?: Maybe<Payments_Min_Order_By>;
  stddev?: Maybe<Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Payments_Sum_Order_By>;
  var_pop?: Maybe<Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Payments_Var_Samp_Order_By>;
  variance?: Maybe<Payments_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Payments_Append_Input = {
  media?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "payments" */
export type Payments_Arr_Rel_Insert_Input = {
  data: Array<Payments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Payments_Avg_Fields = {
  __typename?: 'payments_avg_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payments" */
export type Payments_Avg_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type Payments_Bool_Exp = {
  _and?: Maybe<Array<Payments_Bool_Exp>>;
  _not?: Maybe<Payments_Bool_Exp>;
  _or?: Maybe<Array<Payments_Bool_Exp>>;
  authorised_amount?: Maybe<Numeric_Comparison_Exp>;
  authorised_at?: Maybe<Timestamptz_Comparison_Exp>;
  cancel_url?: Maybe<String_Comparison_Exp>;
  code?: Maybe<Payment_Codes_Enum_Comparison_Exp>;
  contact_email?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  due_date?: Maybe<Date_Comparison_Exp>;
  gross_amount?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  media?: Maybe<Jsonb_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  method?: Maybe<Payment_Method_Types_Enum_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  net_amount?: Maybe<Numeric_Comparison_Exp>;
  payment_code?: Maybe<Payment_Codes_Bool_Exp>;
  payment_method?: Maybe<Payment_Methods_Bool_Exp>;
  payment_method_id?: Maybe<Uuid_Comparison_Exp>;
  payment_method_type?: Maybe<Payment_Method_Types_Bool_Exp>;
  payment_provider_name?: Maybe<String_Comparison_Exp>;
  payment_provider_reference?: Maybe<String_Comparison_Exp>;
  payment_url?: Maybe<String_Comparison_Exp>;
  received_amount?: Maybe<Numeric_Comparison_Exp>;
  received_at?: Maybe<Timestamptz_Comparison_Exp>;
  refunded_at?: Maybe<Timestamptz_Comparison_Exp>;
  rental_reservation_id?: Maybe<Uuid_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  success_url?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payments" */
export enum Payments_Constraint {
  /** unique or primary key constraint on columns "rental_reservation_id", "code" */
  PaymentsCodeRentalReservationIdKey = 'payments_code_rental_reservation_id_key',
  /** unique or primary key constraint on columns "id" */
  PaymentsPkey1 = 'payments_pkey1',
  /** unique or primary key constraint on columns "payment_provider_reference" */
  PaymentsStripeCheckoutSessionIdKey = 'payments_stripe_checkout_session_id_key',
  /** unique or primary key constraint on columns "vehicle_order_id", "code" */
  PaymentsVehicleOrderIdCodeKey = 'payments_vehicle_order_id_code_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Payments_Delete_At_Path_Input = {
  media?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Payments_Delete_Elem_Input = {
  media?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Payments_Delete_Key_Input = {
  media?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "payments" */
export type Payments_Inc_Input = {
  authorised_amount?: Maybe<Scalars['numeric']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  net_amount?: Maybe<Scalars['numeric']>;
  received_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "payments" */
export type Payments_Insert_Input = {
  authorised_amount?: Maybe<Scalars['numeric']>;
  authorised_at?: Maybe<Scalars['timestamptz']>;
  cancel_url?: Maybe<Scalars['String']>;
  code?: Maybe<Payment_Codes_Enum>;
  contact_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  media?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** The payment method type */
  method?: Maybe<Payment_Method_Types_Enum>;
  name?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['numeric']>;
  payment_code?: Maybe<Payment_Codes_Obj_Rel_Insert_Input>;
  payment_method?: Maybe<Payment_Methods_Obj_Rel_Insert_Input>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_method_type?: Maybe<Payment_Method_Types_Obj_Rel_Insert_Input>;
  payment_provider_name?: Maybe<Scalars['String']>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Scalars['String']>;
  received_amount?: Maybe<Scalars['numeric']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payments_Max_Fields = {
  __typename?: 'payments_max_fields';
  authorised_amount?: Maybe<Scalars['numeric']>;
  authorised_at?: Maybe<Scalars['timestamptz']>;
  cancel_url?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['numeric']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Scalars['String']>;
  received_amount?: Maybe<Scalars['numeric']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payments" */
export type Payments_Max_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  authorised_at?: Maybe<Order_By>;
  cancel_url?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Order_By>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  success_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payments_Min_Fields = {
  __typename?: 'payments_min_fields';
  authorised_amount?: Maybe<Scalars['numeric']>;
  authorised_at?: Maybe<Scalars['timestamptz']>;
  cancel_url?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['numeric']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Scalars['String']>;
  received_amount?: Maybe<Scalars['numeric']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payments" */
export type Payments_Min_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  authorised_at?: Maybe<Order_By>;
  cancel_url?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  payment_method_id?: Maybe<Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Order_By>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  success_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "payments" */
export type Payments_Mutation_Response = {
  __typename?: 'payments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payments>;
};

/** on_conflict condition type for table "payments" */
export type Payments_On_Conflict = {
  constraint: Payments_Constraint;
  update_columns?: Array<Payments_Update_Column>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** Ordering options when selecting data from "payments". */
export type Payments_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  authorised_at?: Maybe<Order_By>;
  cancel_url?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  contact_email?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  media?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  payment_code?: Maybe<Payment_Codes_Order_By>;
  payment_method?: Maybe<Payment_Methods_Order_By>;
  payment_method_id?: Maybe<Order_By>;
  payment_method_type?: Maybe<Payment_Method_Types_Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  payment_url?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  refunded_at?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  success_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: payments */
export type Payments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Payments_Prepend_Input = {
  media?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "payments" */
export enum Payments_Select_Column {
  /** column name */
  AuthorisedAmount = 'authorised_amount',
  /** column name */
  AuthorisedAt = 'authorised_at',
  /** column name */
  CancelUrl = 'cancel_url',
  /** column name */
  Code = 'code',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  GrossAmount = 'gross_amount',
  /** column name */
  Id = 'id',
  /** column name */
  Media = 'media',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  NetAmount = 'net_amount',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentProviderName = 'payment_provider_name',
  /** column name */
  PaymentProviderReference = 'payment_provider_reference',
  /** column name */
  PaymentUrl = 'payment_url',
  /** column name */
  ReceivedAmount = 'received_amount',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  RentalReservationId = 'rental_reservation_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "payments" */
export type Payments_Set_Input = {
  authorised_amount?: Maybe<Scalars['numeric']>;
  authorised_at?: Maybe<Scalars['timestamptz']>;
  cancel_url?: Maybe<Scalars['String']>;
  code?: Maybe<Payment_Codes_Enum>;
  contact_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  media?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** The payment method type */
  method?: Maybe<Payment_Method_Types_Enum>;
  name?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['numeric']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Scalars['String']>;
  received_amount?: Maybe<Scalars['numeric']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payments_Stddev_Fields = {
  __typename?: 'payments_stddev_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payments" */
export type Payments_Stddev_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payments_Stddev_Pop_Fields = {
  __typename?: 'payments_stddev_pop_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payments" */
export type Payments_Stddev_Pop_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payments_Stddev_Samp_Fields = {
  __typename?: 'payments_stddev_samp_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payments" */
export type Payments_Stddev_Samp_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** Streaming cursor of the table "payments" */
export type Payments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payments_Stream_Cursor_Value_Input = {
  authorised_amount?: Maybe<Scalars['numeric']>;
  authorised_at?: Maybe<Scalars['timestamptz']>;
  cancel_url?: Maybe<Scalars['String']>;
  code?: Maybe<Payment_Codes_Enum>;
  contact_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter ISO 4217 currency code in UPPERCASE. E.g. AUD, USD */
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  media?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** The payment method type */
  method?: Maybe<Payment_Method_Types_Enum>;
  name?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['numeric']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  /** the payment provider's reference to this payment */
  payment_provider_reference?: Maybe<Scalars['String']>;
  /** the url to the payment provider's payment page */
  payment_url?: Maybe<Scalars['String']>;
  received_amount?: Maybe<Scalars['numeric']>;
  received_at?: Maybe<Scalars['timestamptz']>;
  refunded_at?: Maybe<Scalars['timestamptz']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Payments_Sum_Fields = {
  __typename?: 'payments_sum_fields';
  authorised_amount?: Maybe<Scalars['numeric']>;
  gross_amount?: Maybe<Scalars['numeric']>;
  net_amount?: Maybe<Scalars['numeric']>;
  received_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "payments" */
export type Payments_Sum_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** update columns of table "payments" */
export enum Payments_Update_Column {
  /** column name */
  AuthorisedAmount = 'authorised_amount',
  /** column name */
  AuthorisedAt = 'authorised_at',
  /** column name */
  CancelUrl = 'cancel_url',
  /** column name */
  Code = 'code',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  GrossAmount = 'gross_amount',
  /** column name */
  Id = 'id',
  /** column name */
  Media = 'media',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  NetAmount = 'net_amount',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentProviderName = 'payment_provider_name',
  /** column name */
  PaymentProviderReference = 'payment_provider_reference',
  /** column name */
  PaymentUrl = 'payment_url',
  /** column name */
  ReceivedAmount = 'received_amount',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  RentalReservationId = 'rental_reservation_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Payments_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Payments_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Payments_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Payments_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Payments_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Payments_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Payments_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payments_Var_Pop_Fields = {
  __typename?: 'payments_var_pop_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payments" */
export type Payments_Var_Pop_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payments_Var_Samp_Fields = {
  __typename?: 'payments_var_samp_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payments" */
export type Payments_Var_Samp_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payments_Variance_Fields = {
  __typename?: 'payments_variance_fields';
  authorised_amount?: Maybe<Scalars['Float']>;
  gross_amount?: Maybe<Scalars['Float']>;
  net_amount?: Maybe<Scalars['Float']>;
  received_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payments" */
export type Payments_Variance_Order_By = {
  authorised_amount?: Maybe<Order_By>;
  gross_amount?: Maybe<Order_By>;
  net_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** columns and relationships of "personal_vehicles" */
export type Personal_Vehicles = {
  __typename?: 'personal_vehicles';
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  badge?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  disposal_price?: Maybe<Scalars['numeric']>;
  disposed_at?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  is_trashed: Scalars['Boolean'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  purchased_at?: Maybe<Scalars['date']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "personal_vehicles" */
export type Personal_VehiclesAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "personal_vehicles" */
export type Personal_VehiclesAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};

/** aggregated selection of "personal_vehicles" */
export type Personal_Vehicles_Aggregate = {
  __typename?: 'personal_vehicles_aggregate';
  aggregate?: Maybe<Personal_Vehicles_Aggregate_Fields>;
  nodes: Array<Personal_Vehicles>;
};

/** aggregate fields of "personal_vehicles" */
export type Personal_Vehicles_Aggregate_Fields = {
  __typename?: 'personal_vehicles_aggregate_fields';
  avg?: Maybe<Personal_Vehicles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Personal_Vehicles_Max_Fields>;
  min?: Maybe<Personal_Vehicles_Min_Fields>;
  stddev?: Maybe<Personal_Vehicles_Stddev_Fields>;
  stddev_pop?: Maybe<Personal_Vehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Personal_Vehicles_Stddev_Samp_Fields>;
  sum?: Maybe<Personal_Vehicles_Sum_Fields>;
  var_pop?: Maybe<Personal_Vehicles_Var_Pop_Fields>;
  var_samp?: Maybe<Personal_Vehicles_Var_Samp_Fields>;
  variance?: Maybe<Personal_Vehicles_Variance_Fields>;
};


/** aggregate fields of "personal_vehicles" */
export type Personal_Vehicles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Personal_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Personal_Vehicles_Avg_Fields = {
  __typename?: 'personal_vehicles_avg_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "personal_vehicles". All fields are combined with a logical 'AND'. */
export type Personal_Vehicles_Bool_Exp = {
  _and?: Maybe<Array<Personal_Vehicles_Bool_Exp>>;
  _not?: Maybe<Personal_Vehicles_Bool_Exp>;
  _or?: Maybe<Array<Personal_Vehicles_Bool_Exp>>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  badge?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disposal_price?: Maybe<Numeric_Comparison_Exp>;
  disposed_at?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_trashed?: Maybe<Boolean_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  production_year?: Maybe<String_Comparison_Exp>;
  purchase_price?: Maybe<Numeric_Comparison_Exp>;
  purchased_at?: Maybe<Date_Comparison_Exp>;
  registration_expiry_date?: Maybe<Date_Comparison_Exp>;
  registration_number?: Maybe<String_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  state_of_registration?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_vehicles" */
export enum Personal_Vehicles_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonalVehiclesPkey = 'personal_vehicles_pkey',
  /** unique or primary key constraint on columns "source", "source_id" */
  PersonalVehiclesSourceIdSourceKey = 'personal_vehicles_source_id_source_key'
}

/** input type for incrementing numeric columns in table "personal_vehicles" */
export type Personal_Vehicles_Inc_Input = {
  disposal_price?: Maybe<Scalars['numeric']>;
  purchase_price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "personal_vehicles" */
export type Personal_Vehicles_Insert_Input = {
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disposal_price?: Maybe<Scalars['numeric']>;
  disposed_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  purchased_at?: Maybe<Scalars['date']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Personal_Vehicles_Max_Fields = {
  __typename?: 'personal_vehicles_max_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disposal_price?: Maybe<Scalars['numeric']>;
  disposed_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  purchased_at?: Maybe<Scalars['date']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Personal_Vehicles_Min_Fields = {
  __typename?: 'personal_vehicles_min_fields';
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disposal_price?: Maybe<Scalars['numeric']>;
  disposed_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  purchased_at?: Maybe<Scalars['date']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "personal_vehicles" */
export type Personal_Vehicles_Mutation_Response = {
  __typename?: 'personal_vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Personal_Vehicles>;
};

/** input type for inserting object relation for remote table "personal_vehicles" */
export type Personal_Vehicles_Obj_Rel_Insert_Input = {
  data: Personal_Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Personal_Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "personal_vehicles" */
export type Personal_Vehicles_On_Conflict = {
  constraint: Personal_Vehicles_Constraint;
  update_columns?: Array<Personal_Vehicles_Update_Column>;
  where?: Maybe<Personal_Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_vehicles". */
export type Personal_Vehicles_Order_By = {
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  badge?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  disposal_price?: Maybe<Order_By>;
  disposed_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_trashed?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  purchase_price?: Maybe<Order_By>;
  purchased_at?: Maybe<Order_By>;
  registration_expiry_date?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: personal_vehicles */
export type Personal_Vehicles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "personal_vehicles" */
export enum Personal_Vehicles_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisposalPrice = 'disposal_price',
  /** column name */
  DisposedAt = 'disposed_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  PurchasePrice = 'purchase_price',
  /** column name */
  PurchasedAt = 'purchased_at',
  /** column name */
  RegistrationExpiryDate = 'registration_expiry_date',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  Series = 'series',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "personal_vehicles" */
export type Personal_Vehicles_Set_Input = {
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disposal_price?: Maybe<Scalars['numeric']>;
  disposed_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  purchased_at?: Maybe<Scalars['date']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Personal_Vehicles_Stddev_Fields = {
  __typename?: 'personal_vehicles_stddev_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Personal_Vehicles_Stddev_Pop_Fields = {
  __typename?: 'personal_vehicles_stddev_pop_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Personal_Vehicles_Stddev_Samp_Fields = {
  __typename?: 'personal_vehicles_stddev_samp_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "personal_vehicles" */
export type Personal_Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personal_Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personal_Vehicles_Stream_Cursor_Value_Input = {
  badge?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disposal_price?: Maybe<Scalars['numeric']>;
  disposed_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  purchased_at?: Maybe<Scalars['date']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Personal_Vehicles_Sum_Fields = {
  __typename?: 'personal_vehicles_sum_fields';
  disposal_price?: Maybe<Scalars['numeric']>;
  purchase_price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "personal_vehicles" */
export enum Personal_Vehicles_Update_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisposalPrice = 'disposal_price',
  /** column name */
  DisposedAt = 'disposed_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  PurchasePrice = 'purchase_price',
  /** column name */
  PurchasedAt = 'purchased_at',
  /** column name */
  RegistrationExpiryDate = 'registration_expiry_date',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  Series = 'series',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Personal_Vehicles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Personal_Vehicles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Personal_Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personal_Vehicles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Personal_Vehicles_Var_Pop_Fields = {
  __typename?: 'personal_vehicles_var_pop_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Personal_Vehicles_Var_Samp_Fields = {
  __typename?: 'personal_vehicles_var_samp_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Personal_Vehicles_Variance_Fields = {
  __typename?: 'personal_vehicles_variance_fields';
  disposal_price?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pricing_codes" */
export type Pricing_Codes = {
  __typename?: 'pricing_codes';
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code: Scalars['String'];
  /** Two-letter country code in UPPERCASE (ISO 3166-1 alpha-2). See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** Three-letter currency code in UPPERCASE (ISO_4217). See: https://en.wikipedia.org/wiki/ISO_4217 */
  currency: Scalars['String'];
  /** Up to three-letter country/region code in UPPERCASE (ISO_3166-2). In Australia, we'd refer to this as a "State" or "Territory". See: https://en.wikipedia.org/wiki/ISO_3166-2 */
  region?: Maybe<Scalars['String']>;
  /** A human-readable name for the pricing code. Typically the name of the Region, or something else that makes localised sense. */
  short_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  showroom_pricing_codes: Array<Showroom_Pricing_Codes>;
  /** An aggregate relationship */
  showroom_pricing_codes_aggregate: Showroom_Pricing_Codes_Aggregate;
  /** An array relationship */
  showrooms: Array<Showrooms>;
  /** An aggregate relationship */
  showrooms_aggregate: Showrooms_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
};


/** columns and relationships of "pricing_codes" */
export type Pricing_CodesShowroom_Pricing_CodesArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


/** columns and relationships of "pricing_codes" */
export type Pricing_CodesShowroom_Pricing_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


/** columns and relationships of "pricing_codes" */
export type Pricing_CodesShowroomsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


/** columns and relationships of "pricing_codes" */
export type Pricing_CodesShowrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


/** columns and relationships of "pricing_codes" */
export type Pricing_CodesVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "pricing_codes" */
export type Pricing_CodesVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};

/** aggregated selection of "pricing_codes" */
export type Pricing_Codes_Aggregate = {
  __typename?: 'pricing_codes_aggregate';
  aggregate?: Maybe<Pricing_Codes_Aggregate_Fields>;
  nodes: Array<Pricing_Codes>;
};

/** aggregate fields of "pricing_codes" */
export type Pricing_Codes_Aggregate_Fields = {
  __typename?: 'pricing_codes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pricing_Codes_Max_Fields>;
  min?: Maybe<Pricing_Codes_Min_Fields>;
};


/** aggregate fields of "pricing_codes" */
export type Pricing_Codes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pricing_Codes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "pricing_codes". All fields are combined with a logical 'AND'. */
export type Pricing_Codes_Bool_Exp = {
  _and?: Maybe<Array<Pricing_Codes_Bool_Exp>>;
  _not?: Maybe<Pricing_Codes_Bool_Exp>;
  _or?: Maybe<Array<Pricing_Codes_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  short_name?: Maybe<String_Comparison_Exp>;
  showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
  showroom_pricing_codes_aggregate?: Maybe<Showroom_Pricing_Codes_Aggregate_Bool_Exp>;
  showrooms?: Maybe<Showrooms_Bool_Exp>;
  showrooms_aggregate?: Maybe<Showrooms_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_orders?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "pricing_codes" */
export enum Pricing_Codes_Constraint {
  /** unique or primary key constraint on columns "code" */
  PricingCodesPkey = 'pricing_codes_pkey'
}

/** input type for inserting data into table "pricing_codes" */
export type Pricing_Codes_Insert_Input = {
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code?: Maybe<Scalars['String']>;
  /** Two-letter country code in UPPERCASE (ISO 3166-1 alpha-2). See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter currency code in UPPERCASE (ISO_4217). See: https://en.wikipedia.org/wiki/ISO_4217 */
  currency?: Maybe<Scalars['String']>;
  /** Up to three-letter country/region code in UPPERCASE (ISO_3166-2). In Australia, we'd refer to this as a "State" or "Territory". See: https://en.wikipedia.org/wiki/ISO_3166-2 */
  region?: Maybe<Scalars['String']>;
  /** A human-readable name for the pricing code. Typically the name of the Region, or something else that makes localised sense. */
  short_name?: Maybe<Scalars['String']>;
  showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Arr_Rel_Insert_Input>;
  showrooms?: Maybe<Showrooms_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_orders?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Pricing_Codes_Max_Fields = {
  __typename?: 'pricing_codes_max_fields';
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code?: Maybe<Scalars['String']>;
  /** Two-letter country code in UPPERCASE (ISO 3166-1 alpha-2). See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter currency code in UPPERCASE (ISO_4217). See: https://en.wikipedia.org/wiki/ISO_4217 */
  currency?: Maybe<Scalars['String']>;
  /** Up to three-letter country/region code in UPPERCASE (ISO_3166-2). In Australia, we'd refer to this as a "State" or "Territory". See: https://en.wikipedia.org/wiki/ISO_3166-2 */
  region?: Maybe<Scalars['String']>;
  /** A human-readable name for the pricing code. Typically the name of the Region, or something else that makes localised sense. */
  short_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Pricing_Codes_Min_Fields = {
  __typename?: 'pricing_codes_min_fields';
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code?: Maybe<Scalars['String']>;
  /** Two-letter country code in UPPERCASE (ISO 3166-1 alpha-2). See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter currency code in UPPERCASE (ISO_4217). See: https://en.wikipedia.org/wiki/ISO_4217 */
  currency?: Maybe<Scalars['String']>;
  /** Up to three-letter country/region code in UPPERCASE (ISO_3166-2). In Australia, we'd refer to this as a "State" or "Territory". See: https://en.wikipedia.org/wiki/ISO_3166-2 */
  region?: Maybe<Scalars['String']>;
  /** A human-readable name for the pricing code. Typically the name of the Region, or something else that makes localised sense. */
  short_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "pricing_codes" */
export type Pricing_Codes_Mutation_Response = {
  __typename?: 'pricing_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pricing_Codes>;
};

/** input type for inserting object relation for remote table "pricing_codes" */
export type Pricing_Codes_Obj_Rel_Insert_Input = {
  data: Pricing_Codes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Pricing_Codes_On_Conflict>;
};

/** on_conflict condition type for table "pricing_codes" */
export type Pricing_Codes_On_Conflict = {
  constraint: Pricing_Codes_Constraint;
  update_columns?: Array<Pricing_Codes_Update_Column>;
  where?: Maybe<Pricing_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "pricing_codes". */
export type Pricing_Codes_Order_By = {
  code?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  short_name?: Maybe<Order_By>;
  showroom_pricing_codes_aggregate?: Maybe<Showroom_Pricing_Codes_Aggregate_Order_By>;
  showrooms_aggregate?: Maybe<Showrooms_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
};

/** primary key columns input for table: pricing_codes */
export type Pricing_Codes_Pk_Columns_Input = {
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code: Scalars['String'];
};

/** select columns of table "pricing_codes" */
export enum Pricing_Codes_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Region = 'region',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pricing_codes" */
export type Pricing_Codes_Set_Input = {
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code?: Maybe<Scalars['String']>;
  /** Two-letter country code in UPPERCASE (ISO 3166-1 alpha-2). See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter currency code in UPPERCASE (ISO_4217). See: https://en.wikipedia.org/wiki/ISO_4217 */
  currency?: Maybe<Scalars['String']>;
  /** Up to three-letter country/region code in UPPERCASE (ISO_3166-2). In Australia, we'd refer to this as a "State" or "Territory". See: https://en.wikipedia.org/wiki/ISO_3166-2 */
  region?: Maybe<Scalars['String']>;
  /** A human-readable name for the pricing code. Typically the name of the Region, or something else that makes localised sense. */
  short_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "pricing_codes" */
export type Pricing_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pricing_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pricing_Codes_Stream_Cursor_Value_Input = {
  /** A unique code representing the pricing group in UPPERCASE and typically starting with a Two-letter Country code, following by an optional Region code. E.g. AU_NSW */
  code?: Maybe<Scalars['String']>;
  /** Two-letter country code in UPPERCASE (ISO 3166-1 alpha-2). See: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Three-letter currency code in UPPERCASE (ISO_4217). See: https://en.wikipedia.org/wiki/ISO_4217 */
  currency?: Maybe<Scalars['String']>;
  /** Up to three-letter country/region code in UPPERCASE (ISO_3166-2). In Australia, we'd refer to this as a "State" or "Territory". See: https://en.wikipedia.org/wiki/ISO_3166-2 */
  region?: Maybe<Scalars['String']>;
  /** A human-readable name for the pricing code. Typically the name of the Region, or something else that makes localised sense. */
  short_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "pricing_codes" */
export enum Pricing_Codes_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Region = 'region',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Pricing_Codes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pricing_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pricing_Codes_Bool_Exp;
};

/** columns and relationships of "product_groups" */
export type Product_Groups = {
  __typename?: 'product_groups';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  parent_product_group_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  product_group?: Maybe<Product_Groups>;
  /** An array relationship */
  product_groups: Array<Product_Groups>;
  /** An aggregate relationship */
  product_groups_aggregate: Product_Groups_Aggregate;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProduct_GroupsArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProduct_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "product_groups" */
export type Product_GroupsProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

/** aggregated selection of "product_groups" */
export type Product_Groups_Aggregate = {
  __typename?: 'product_groups_aggregate';
  aggregate?: Maybe<Product_Groups_Aggregate_Fields>;
  nodes: Array<Product_Groups>;
};

export type Product_Groups_Aggregate_Bool_Exp = {
  count?: Maybe<Product_Groups_Aggregate_Bool_Exp_Count>;
};

export type Product_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Product_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_groups" */
export type Product_Groups_Aggregate_Fields = {
  __typename?: 'product_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Groups_Max_Fields>;
  min?: Maybe<Product_Groups_Min_Fields>;
};


/** aggregate fields of "product_groups" */
export type Product_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_groups" */
export type Product_Groups_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Groups_Max_Order_By>;
  min?: Maybe<Product_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_groups" */
export type Product_Groups_Arr_Rel_Insert_Input = {
  data: Array<Product_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_groups". All fields are combined with a logical 'AND'. */
export type Product_Groups_Bool_Exp = {
  _and?: Maybe<Array<Product_Groups_Bool_Exp>>;
  _not?: Maybe<Product_Groups_Bool_Exp>;
  _or?: Maybe<Array<Product_Groups_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parent_product_group_id?: Maybe<Uuid_Comparison_Exp>;
  product_group?: Maybe<Product_Groups_Bool_Exp>;
  product_groups?: Maybe<Product_Groups_Bool_Exp>;
  product_groups_aggregate?: Maybe<Product_Groups_Aggregate_Bool_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_groups" */
export enum Product_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductGroupsPkey = 'product_groups_pkey',
  /** unique or primary key constraint on columns "showroom_id", "code" */
  ProductGroupsShowroomIdCodeKey = 'product_groups_showroom_id_code_key'
}

/** input type for inserting data into table "product_groups" */
export type Product_Groups_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_product_group_id?: Maybe<Scalars['uuid']>;
  product_group?: Maybe<Product_Groups_Obj_Rel_Insert_Input>;
  product_groups?: Maybe<Product_Groups_Arr_Rel_Insert_Input>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Groups_Max_Fields = {
  __typename?: 'product_groups_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_groups" */
export type Product_Groups_Max_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_product_group_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Groups_Min_Fields = {
  __typename?: 'product_groups_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_groups" */
export type Product_Groups_Min_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_product_group_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_groups" */
export type Product_Groups_Mutation_Response = {
  __typename?: 'product_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Groups>;
};

/** input type for inserting object relation for remote table "product_groups" */
export type Product_Groups_Obj_Rel_Insert_Input = {
  data: Product_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Groups_On_Conflict>;
};

/** on_conflict condition type for table "product_groups" */
export type Product_Groups_On_Conflict = {
  constraint: Product_Groups_Constraint;
  update_columns?: Array<Product_Groups_Update_Column>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "product_groups". */
export type Product_Groups_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_product_group_id?: Maybe<Order_By>;
  product_group?: Maybe<Product_Groups_Order_By>;
  product_groups_aggregate?: Maybe<Product_Groups_Aggregate_Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: product_groups */
export type Product_Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "product_groups" */
export enum Product_Groups_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentProductGroupId = 'parent_product_group_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_groups" */
export type Product_Groups_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "product_groups" */
export type Product_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Groups_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "product_groups" */
export enum Product_Groups_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentProductGroupId = 'parent_product_group_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Product_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Groups_Bool_Exp;
};

/** columns and relationships of "product_variants" */
export type Product_Variants = {
  __typename?: 'product_variants';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  gross_price: Scalars['numeric'];
  id: Scalars['uuid'];
  manufacturer_product_code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  net_price: Scalars['numeric'];
  order: Scalars['Int'];
  /** An object relationship */
  product: Products;
  product_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  vehicle_order_product_variants: Array<Vehicle_Order_Product_Variants>;
  /** An aggregate relationship */
  vehicle_order_product_variants_aggregate: Vehicle_Order_Product_Variants_Aggregate;
};


/** columns and relationships of "product_variants" */
export type Product_VariantsVehicle_Order_Product_VariantsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


/** columns and relationships of "product_variants" */
export type Product_VariantsVehicle_Order_Product_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};

/** aggregated selection of "product_variants" */
export type Product_Variants_Aggregate = {
  __typename?: 'product_variants_aggregate';
  aggregate?: Maybe<Product_Variants_Aggregate_Fields>;
  nodes: Array<Product_Variants>;
};

export type Product_Variants_Aggregate_Bool_Exp = {
  count?: Maybe<Product_Variants_Aggregate_Bool_Exp_Count>;
};

export type Product_Variants_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Variants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Product_Variants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_variants" */
export type Product_Variants_Aggregate_Fields = {
  __typename?: 'product_variants_aggregate_fields';
  avg?: Maybe<Product_Variants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Variants_Max_Fields>;
  min?: Maybe<Product_Variants_Min_Fields>;
  stddev?: Maybe<Product_Variants_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Variants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Variants_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Variants_Sum_Fields>;
  var_pop?: Maybe<Product_Variants_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Variants_Var_Samp_Fields>;
  variance?: Maybe<Product_Variants_Variance_Fields>;
};


/** aggregate fields of "product_variants" */
export type Product_Variants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Variants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_variants" */
export type Product_Variants_Aggregate_Order_By = {
  avg?: Maybe<Product_Variants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Variants_Max_Order_By>;
  min?: Maybe<Product_Variants_Min_Order_By>;
  stddev?: Maybe<Product_Variants_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Variants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Variants_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Variants_Sum_Order_By>;
  var_pop?: Maybe<Product_Variants_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Variants_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_variants" */
export type Product_Variants_Arr_Rel_Insert_Input = {
  data: Array<Product_Variants_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Variants_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Variants_Avg_Fields = {
  __typename?: 'product_variants_avg_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_variants" */
export type Product_Variants_Avg_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_variants". All fields are combined with a logical 'AND'. */
export type Product_Variants_Bool_Exp = {
  _and?: Maybe<Array<Product_Variants_Bool_Exp>>;
  _not?: Maybe<Product_Variants_Bool_Exp>;
  _or?: Maybe<Array<Product_Variants_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  gross_price?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  manufacturer_product_code?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  net_price?: Maybe<Numeric_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  product_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
  vehicle_order_product_variants_aggregate?: Maybe<Vehicle_Order_Product_Variants_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "product_variants" */
export enum Product_Variants_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductVariantPkey = 'product_variant_pkey'
}

/** input type for incrementing numeric columns in table "product_variants" */
export type Product_Variants_Inc_Input = {
  gross_price?: Maybe<Scalars['numeric']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "product_variants" */
export type Product_Variants_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gross_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manufacturer_product_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Variants_Max_Fields = {
  __typename?: 'product_variants_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gross_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manufacturer_product_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_variants" */
export type Product_Variants_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  gross_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  manufacturer_product_code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Variants_Min_Fields = {
  __typename?: 'product_variants_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gross_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manufacturer_product_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_variants" */
export type Product_Variants_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  gross_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  manufacturer_product_code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_variants" */
export type Product_Variants_Mutation_Response = {
  __typename?: 'product_variants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Variants>;
};

/** input type for inserting object relation for remote table "product_variants" */
export type Product_Variants_Obj_Rel_Insert_Input = {
  data: Product_Variants_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Variants_On_Conflict>;
};

/** on_conflict condition type for table "product_variants" */
export type Product_Variants_On_Conflict = {
  constraint: Product_Variants_Constraint;
  update_columns?: Array<Product_Variants_Update_Column>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};

/** Ordering options when selecting data from "product_variants". */
export type Product_Variants_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  gross_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  manufacturer_product_code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order_product_variants_aggregate?: Maybe<Vehicle_Order_Product_Variants_Aggregate_Order_By>;
};

/** primary key columns input for table: product_variants */
export type Product_Variants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "product_variants" */
export enum Product_Variants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  GrossPrice = 'gross_price',
  /** column name */
  Id = 'id',
  /** column name */
  ManufacturerProductCode = 'manufacturer_product_code',
  /** column name */
  Name = 'name',
  /** column name */
  NetPrice = 'net_price',
  /** column name */
  Order = 'order',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_variants" */
export type Product_Variants_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gross_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manufacturer_product_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Variants_Stddev_Fields = {
  __typename?: 'product_variants_stddev_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_variants" */
export type Product_Variants_Stddev_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Variants_Stddev_Pop_Fields = {
  __typename?: 'product_variants_stddev_pop_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_variants" */
export type Product_Variants_Stddev_Pop_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Variants_Stddev_Samp_Fields = {
  __typename?: 'product_variants_stddev_samp_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_variants" */
export type Product_Variants_Stddev_Samp_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product_variants" */
export type Product_Variants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Variants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Variants_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gross_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  manufacturer_product_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Product_Variants_Sum_Fields = {
  __typename?: 'product_variants_sum_fields';
  gross_price?: Maybe<Scalars['numeric']>;
  net_price?: Maybe<Scalars['numeric']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product_variants" */
export type Product_Variants_Sum_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "product_variants" */
export enum Product_Variants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  GrossPrice = 'gross_price',
  /** column name */
  Id = 'id',
  /** column name */
  ManufacturerProductCode = 'manufacturer_product_code',
  /** column name */
  Name = 'name',
  /** column name */
  NetPrice = 'net_price',
  /** column name */
  Order = 'order',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Product_Variants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Variants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Variants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Variants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Variants_Var_Pop_Fields = {
  __typename?: 'product_variants_var_pop_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_variants" */
export type Product_Variants_Var_Pop_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Variants_Var_Samp_Fields = {
  __typename?: 'product_variants_var_samp_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_variants" */
export type Product_Variants_Var_Samp_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variants_Variance_Fields = {
  __typename?: 'product_variants_variance_fields';
  gross_price?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_variants" */
export type Product_Variants_Variance_Order_By = {
  gross_price?: Maybe<Order_By>;
  net_price?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  body?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  is_listed: Scalars['Boolean'];
  is_published: Scalars['Boolean'];
  media: Scalars['jsonb'];
  name: Scalars['String'];
  /** A computed field, executes function "product_path_v2" */
  path?: Maybe<Scalars['String']>;
  /** An object relationship */
  product_group?: Maybe<Product_Groups>;
  product_group_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  product_variants: Array<Product_Variants>;
  /** An aggregate relationship */
  product_variants_aggregate: Product_Variants_Aggregate;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  variant_label?: Maybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type ProductsMediaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type ProductsProduct_VariantsArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProduct_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Products_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Products_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Products_Aggregate_Bool_Exp_Count>;
};

export type Products_Aggregate_Bool_Exp_Bool_And = {
  arguments: Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Products_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Products_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Products_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Products_Append_Input = {
  media?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "products" */
export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Products_On_Conflict>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: Maybe<Array<Products_Bool_Exp>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Products_Bool_Exp>>;
  body?: Maybe<String_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_listed?: Maybe<Boolean_Comparison_Exp>;
  is_published?: Maybe<Boolean_Comparison_Exp>;
  media?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  product_group?: Maybe<Product_Groups_Bool_Exp>;
  product_group_id?: Maybe<Uuid_Comparison_Exp>;
  product_variants?: Maybe<Product_Variants_Bool_Exp>;
  product_variants_aggregate?: Maybe<Product_Variants_Aggregate_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  variant_label?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Products_Delete_At_Path_Input = {
  media?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Products_Delete_Elem_Input = {
  media?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Products_Delete_Key_Input = {
  media?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  body?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_listed?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  product_group?: Maybe<Product_Groups_Obj_Rel_Insert_Input>;
  product_group_id?: Maybe<Scalars['uuid']>;
  product_variants?: Maybe<Product_Variants_Arr_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_label?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  body?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "product_path_v2" */
  path?: Maybe<Scalars['String']>;
  product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_label?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  body?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_group_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant_label?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  body?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "product_path_v2" */
  path?: Maybe<Scalars['String']>;
  product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_label?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  body?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  product_group_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant_label?: Maybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: Maybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  body?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_listed?: Maybe<Order_By>;
  is_published?: Maybe<Order_By>;
  media?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  product_group?: Maybe<Product_Groups_Order_By>;
  product_group_id?: Maybe<Order_By>;
  product_variants_aggregate?: Maybe<Product_Variants_Aggregate_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variant_label?: Maybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Products_Prepend_Input = {
  media?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  Media = 'media',
  /** column name */
  Name = 'name',
  /** column name */
  ProductGroupId = 'product_group_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VariantLabel = 'variant_label'
}

/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
export enum Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published'
}

/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
export enum Products_Select_Column_Products_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  body?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_listed?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_label?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  body?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_listed?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  product_group_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  variant_label?: Maybe<Scalars['String']>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  Media = 'media',
  /** column name */
  Name = 'name',
  /** column name */
  ProductGroupId = 'product_group_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VariantLabel = 'variant_label'
}

export type Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Products_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

/** columns and relationships of "promotions" */
export type Promotions = {
  __typename?: 'promotions';
  background_image?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  showrooms_promotions: Array<Showrooms_Promotions>;
  /** An aggregate relationship */
  showrooms_promotions_aggregate: Showrooms_Promotions_Aggregate;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "promotions" */
export type PromotionsShowrooms_PromotionsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


/** columns and relationships of "promotions" */
export type PromotionsShowrooms_Promotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};

/** aggregated selection of "promotions" */
export type Promotions_Aggregate = {
  __typename?: 'promotions_aggregate';
  aggregate?: Maybe<Promotions_Aggregate_Fields>;
  nodes: Array<Promotions>;
};

/** aggregate fields of "promotions" */
export type Promotions_Aggregate_Fields = {
  __typename?: 'promotions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Promotions_Max_Fields>;
  min?: Maybe<Promotions_Min_Fields>;
};


/** aggregate fields of "promotions" */
export type Promotions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Promotions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "promotions". All fields are combined with a logical 'AND'. */
export type Promotions_Bool_Exp = {
  _and?: Maybe<Array<Promotions_Bool_Exp>>;
  _not?: Maybe<Promotions_Bool_Exp>;
  _or?: Maybe<Array<Promotions_Bool_Exp>>;
  background_image?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  intro?: Maybe<String_Comparison_Exp>;
  logo?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  showrooms_promotions?: Maybe<Showrooms_Promotions_Bool_Exp>;
  showrooms_promotions_aggregate?: Maybe<Showrooms_Promotions_Aggregate_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "promotions" */
export enum Promotions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromotionsPkey = 'promotions_pkey'
}

/** input type for inserting data into table "promotions" */
export type Promotions_Insert_Input = {
  background_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showrooms_promotions?: Maybe<Showrooms_Promotions_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Promotions_Max_Fields = {
  __typename?: 'promotions_max_fields';
  background_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Promotions_Min_Fields = {
  __typename?: 'promotions_min_fields';
  background_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "promotions" */
export type Promotions_Mutation_Response = {
  __typename?: 'promotions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promotions>;
};

/** input type for inserting object relation for remote table "promotions" */
export type Promotions_Obj_Rel_Insert_Input = {
  data: Promotions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Promotions_On_Conflict>;
};

/** on_conflict condition type for table "promotions" */
export type Promotions_On_Conflict = {
  constraint: Promotions_Constraint;
  update_columns?: Array<Promotions_Update_Column>;
  where?: Maybe<Promotions_Bool_Exp>;
};

/** Ordering options when selecting data from "promotions". */
export type Promotions_Order_By = {
  background_image?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intro?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showrooms_promotions_aggregate?: Maybe<Showrooms_Promotions_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: promotions */
export type Promotions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "promotions" */
export enum Promotions_Select_Column {
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "promotions" */
export type Promotions_Set_Input = {
  background_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "promotions" */
export type Promotions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promotions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promotions_Stream_Cursor_Value_Input = {
  background_image?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** update columns of table "promotions" */
export enum Promotions_Update_Column {
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
  /** column name */
  Website = 'website'
}

export type Promotions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Promotions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promotions_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activities_aggregate: Activities_Aggregate;
  /** fetch data from the table: "activities" using primary key columns */
  activities_by_pk?: Maybe<Activities>;
  /** An array relationship */
  api_clients: Array<Api_Clients>;
  /** An aggregate relationship */
  api_clients_aggregate: Api_Clients_Aggregate;
  /** fetch data from the table: "api_clients" using primary key columns */
  api_clients_by_pk?: Maybe<Api_Clients>;
  /** An array relationship */
  app_actions: Array<App_Actions>;
  /** An aggregate relationship */
  app_actions_aggregate: App_Actions_Aggregate;
  /** fetch data from the table: "app_actions" using primary key columns */
  app_actions_by_pk?: Maybe<App_Actions>;
  /** An array relationship */
  app_events: Array<App_Events>;
  /** An aggregate relationship */
  app_events_aggregate: App_Events_Aggregate;
  /** fetch data from the table: "app_events" using primary key columns */
  app_events_by_pk?: Maybe<App_Events>;
  /** An array relationship */
  app_inputs: Array<App_Inputs>;
  /** An aggregate relationship */
  app_inputs_aggregate: App_Inputs_Aggregate;
  /** fetch data from the table: "app_inputs" using primary key columns */
  app_inputs_by_pk?: Maybe<App_Inputs>;
  /** An array relationship */
  app_versions: Array<App_Versions>;
  /** An aggregate relationship */
  app_versions_aggregate: App_Versions_Aggregate;
  /** fetch data from the table: "app_versions" using primary key columns */
  app_versions_by_pk?: Maybe<App_Versions>;
  /** fetch data from the table: "apps" */
  apps: Array<Apps>;
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: Apps_Aggregate;
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>;
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articles_aggregate: Articles_Aggregate;
  /** fetch data from the table: "articles" using primary key columns */
  articles_by_pk?: Maybe<Articles>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  /** fetch data from the table: "assets" using primary key columns */
  assets_by_pk?: Maybe<Assets>;
  /** An array relationship */
  auction_bids: Array<Auction_Bids>;
  /** An aggregate relationship */
  auction_bids_aggregate: Auction_Bids_Aggregate;
  /** fetch data from the table: "auction_bids" using primary key columns */
  auction_bids_by_pk?: Maybe<Auction_Bids>;
  /** An array relationship */
  auctions: Array<Auctions>;
  /** An aggregate relationship */
  auctions_aggregate: Auctions_Aggregate;
  /** fetch data from the table: "auctions" using primary key columns */
  auctions_by_pk?: Maybe<Auctions>;
  /** fetch data from the table: "calculator_submission" */
  calculator_submission: Array<Calculator_Submission>;
  /** fetch aggregated fields from the table: "calculator_submission" */
  calculator_submission_aggregate: Calculator_Submission_Aggregate;
  /** fetch data from the table: "calculator_submission" using primary key columns */
  calculator_submission_by_pk?: Maybe<Calculator_Submission>;
  /** An array relationship */
  custom_field_values: Array<Custom_Field_Values>;
  /** An aggregate relationship */
  custom_field_values_aggregate: Custom_Field_Values_Aggregate;
  /** fetch data from the table: "custom_field_values" using primary key columns */
  custom_field_values_by_pk?: Maybe<Custom_Field_Values>;
  /** An array relationship */
  custom_fields: Array<Custom_Fields>;
  /** An aggregate relationship */
  custom_fields_aggregate: Custom_Fields_Aggregate;
  /** fetch data from the table: "custom_fields" using primary key columns */
  custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** An array relationship */
  custom_vehicles: Array<Custom_Vehicles>;
  /** An aggregate relationship */
  custom_vehicles_aggregate: Custom_Vehicles_Aggregate;
  /** fetch data from the table: "custom_vehicles" using primary key columns */
  custom_vehicles_by_pk?: Maybe<Custom_Vehicles>;
  /** An array relationship */
  filter_fields: Array<Filter_Fields>;
  /** An aggregate relationship */
  filter_fields_aggregate: Filter_Fields_Aggregate;
  /** fetch data from the table: "filter_fields" using primary key columns */
  filter_fields_by_pk?: Maybe<Filter_Fields>;
  /** fetch data from the table: "filter_locations" */
  filter_locations: Array<Filter_Locations>;
  /** fetch aggregated fields from the table: "filter_locations" */
  filter_locations_aggregate: Filter_Locations_Aggregate;
  /** fetch data from the table: "filter_locations" using primary key columns */
  filter_locations_by_pk?: Maybe<Filter_Locations>;
  /** fetch data from the table: "filter_names" */
  filter_names: Array<Filter_Names>;
  /** fetch aggregated fields from the table: "filter_names" */
  filter_names_aggregate: Filter_Names_Aggregate;
  /** fetch data from the table: "filter_names" using primary key columns */
  filter_names_by_pk?: Maybe<Filter_Names>;
  /** An array relationship */
  finance_applications: Array<Finance_Applications>;
  /** An aggregate relationship */
  finance_applications_aggregate: Finance_Applications_Aggregate;
  /** fetch data from the table: "finance_applications" using primary key columns */
  finance_applications_by_pk?: Maybe<Finance_Applications>;
  /** An array relationship */
  finance_fees: Array<Finance_Fees>;
  /** An aggregate relationship */
  finance_fees_aggregate: Finance_Fees_Aggregate;
  /** fetch data from the table: "finance_fees" using primary key columns */
  finance_fees_by_pk?: Maybe<Finance_Fees>;
  /** fetch data from the table: "finance_fixed_rate_configurations" */
  finance_fixed_rate_configurations: Array<Finance_Fixed_Rate_Configurations>;
  /** fetch aggregated fields from the table: "finance_fixed_rate_configurations" */
  finance_fixed_rate_configurations_aggregate: Finance_Fixed_Rate_Configurations_Aggregate;
  /** fetch data from the table: "finance_fixed_rate_configurations" using primary key columns */
  finance_fixed_rate_configurations_by_pk?: Maybe<Finance_Fixed_Rate_Configurations>;
  /** fetch data from the table: "finance_frequency" */
  finance_frequency: Array<Finance_Frequency>;
  /** fetch aggregated fields from the table: "finance_frequency" */
  finance_frequency_aggregate: Finance_Frequency_Aggregate;
  /** fetch data from the table: "finance_frequency" using primary key columns */
  finance_frequency_by_pk?: Maybe<Finance_Frequency>;
  /** fetch data from the table: "finance_gfv_configurations" */
  finance_gfv_configurations: Array<Finance_Gfv_Configurations>;
  /** fetch aggregated fields from the table: "finance_gfv_configurations" */
  finance_gfv_configurations_aggregate: Finance_Gfv_Configurations_Aggregate;
  /** fetch data from the table: "finance_gfv_configurations" using primary key columns */
  finance_gfv_configurations_by_pk?: Maybe<Finance_Gfv_Configurations>;
  /** fetch data from the table: "finance_gfv_residual_values" */
  finance_gfv_residual_values: Array<Finance_Gfv_Residual_Values>;
  /** fetch aggregated fields from the table: "finance_gfv_residual_values" */
  finance_gfv_residual_values_aggregate: Finance_Gfv_Residual_Values_Aggregate;
  /** fetch data from the table: "finance_gfv_residual_values" using primary key columns */
  finance_gfv_residual_values_by_pk?: Maybe<Finance_Gfv_Residual_Values>;
  /** An array relationship */
  finance_product_offers: Array<Finance_Product_Offers>;
  /** An aggregate relationship */
  finance_product_offers_aggregate: Finance_Product_Offers_Aggregate;
  /** fetch data from the table: "finance_product_offers" using primary key columns */
  finance_product_offers_by_pk?: Maybe<Finance_Product_Offers>;
  /** fetch data from the table: "finance_product_types" */
  finance_product_types: Array<Finance_Product_Types>;
  /** fetch aggregated fields from the table: "finance_product_types" */
  finance_product_types_aggregate: Finance_Product_Types_Aggregate;
  /** fetch data from the table: "finance_product_types" using primary key columns */
  finance_product_types_by_pk?: Maybe<Finance_Product_Types>;
  /** An array relationship */
  finance_products: Array<Finance_Products>;
  /** An aggregate relationship */
  finance_products_aggregate: Finance_Products_Aggregate;
  /** fetch data from the table: "finance_products" using primary key columns */
  finance_products_by_pk?: Maybe<Finance_Products>;
  /** An array relationship */
  finance_terms: Array<Finance_Terms>;
  /** An aggregate relationship */
  finance_terms_aggregate: Finance_Terms_Aggregate;
  /** fetch data from the table: "finance_terms" using primary key columns */
  finance_terms_by_pk?: Maybe<Finance_Terms>;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  /** fetch data from the table: "form_fields" using primary key columns */
  form_fields_by_pk?: Maybe<Form_Fields>;
  /** An array relationship */
  form_submissions: Array<Form_Submissions>;
  /** An aggregate relationship */
  form_submissions_aggregate: Form_Submissions_Aggregate;
  /** fetch data from the table: "form_submissions" using primary key columns */
  form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  get_autograb_vehicles_by_search: Array<GetAutoGrabVehiclesBySearchOutput>;
  /** fetch data from the table: "insights_click_events" */
  insights_click_events: Array<Insights_Click_Events>;
  /** fetch aggregated fields from the table: "insights_click_events" */
  insights_click_events_aggregate: Insights_Click_Events_Aggregate;
  /** fetch data from the table: "insights_click_events" using primary key columns */
  insights_click_events_by_pk?: Maybe<Insights_Click_Events>;
  /** fetch data from the table: "insights_devices" */
  insights_devices: Array<Insights_Devices>;
  /** fetch aggregated fields from the table: "insights_devices" */
  insights_devices_aggregate: Insights_Devices_Aggregate;
  /** fetch data from the table: "insights_devices" using primary key columns */
  insights_devices_by_pk?: Maybe<Insights_Devices>;
  /** fetch data from the table: "insights_event_types" */
  insights_event_types: Array<Insights_Event_Types>;
  /** fetch aggregated fields from the table: "insights_event_types" */
  insights_event_types_aggregate: Insights_Event_Types_Aggregate;
  /** fetch data from the table: "insights_event_types" using primary key columns */
  insights_event_types_by_pk?: Maybe<Insights_Event_Types>;
  /** An array relationship */
  insights_events: Array<Insights_Events>;
  /** An aggregate relationship */
  insights_events_aggregate: Insights_Events_Aggregate;
  /** fetch data from the table: "insights_events" using primary key columns */
  insights_events_by_pk?: Maybe<Insights_Events>;
  /** fetch data from the table: "insights_page_view_events" */
  insights_page_view_events: Array<Insights_Page_View_Events>;
  /** fetch aggregated fields from the table: "insights_page_view_events" */
  insights_page_view_events_aggregate: Insights_Page_View_Events_Aggregate;
  /** fetch data from the table: "insights_page_view_events" using primary key columns */
  insights_page_view_events_by_pk?: Maybe<Insights_Page_View_Events>;
  /** fetch data from the table: "insights_sessions" */
  insights_sessions: Array<Insights_Sessions>;
  /** fetch aggregated fields from the table: "insights_sessions" */
  insights_sessions_aggregate: Insights_Sessions_Aggregate;
  /** fetch data from the table: "insights_sessions" using primary key columns */
  insights_sessions_by_pk?: Maybe<Insights_Sessions>;
  insights_visitors: Array<Insights_Visitors>;
  insights_visitors_aggregate: Insights_Visitors_Aggregate;
  /** fetch data from the table: "insights_visitors" using primary key columns */
  insights_visitors_by_pk?: Maybe<Insights_Visitors>;
  /** An array relationship */
  interests: Array<Interests>;
  /** An aggregate relationship */
  interests_aggregate: Interests_Aggregate;
  /** fetch data from the table: "interests" using primary key columns */
  interests_by_pk?: Maybe<Interests>;
  /** fetch data from the table: "known_vehicles" */
  known_vehicles: Array<Known_Vehicles>;
  /** fetch aggregated fields from the table: "known_vehicles" */
  known_vehicles_aggregate: Known_Vehicles_Aggregate;
  /** fetch data from the table: "known_vehicles" using primary key columns */
  known_vehicles_by_pk?: Maybe<Known_Vehicles>;
  /** fetch data from the table: "locales" */
  locales: Array<Locales>;
  /** fetch aggregated fields from the table: "locales" */
  locales_aggregate: Locales_Aggregate;
  /** fetch data from the table: "locales" using primary key columns */
  locales_by_pk?: Maybe<Locales>;
  /** An array relationship */
  location_service_types: Array<Location_Service_Types>;
  /** An aggregate relationship */
  location_service_types_aggregate: Location_Service_Types_Aggregate;
  /** fetch data from the table: "location_service_types" using primary key columns */
  location_service_types_by_pk?: Maybe<Location_Service_Types>;
  /** An array relationship */
  location_transport_types: Array<Location_Transport_Types>;
  /** An aggregate relationship */
  location_transport_types_aggregate: Location_Transport_Types_Aggregate;
  /** fetch data from the table: "location_transport_types" using primary key columns */
  location_transport_types_by_pk?: Maybe<Location_Transport_Types>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "match_submissions" */
  match_submissions: Array<Match_Submissions>;
  /** fetch aggregated fields from the table: "match_submissions" */
  match_submissions_aggregate: Match_Submissions_Aggregate;
  /** fetch data from the table: "match_submissions" using primary key columns */
  match_submissions_by_pk?: Maybe<Match_Submissions>;
  /** An array relationship */
  moments: Array<Moments>;
  /** An aggregate relationship */
  moments_aggregate: Moments_Aggregate;
  /** fetch data from the table: "moments" using primary key columns */
  moments_by_pk?: Maybe<Moments>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** fetch data from the table: "offer_types" */
  offer_types: Array<Offer_Types>;
  /** fetch aggregated fields from the table: "offer_types" */
  offer_types_aggregate: Offer_Types_Aggregate;
  /** fetch data from the table: "offer_types" using primary key columns */
  offer_types_by_pk?: Maybe<Offer_Types>;
  /** fetch data from the table: "offers" */
  offers: Array<Offers>;
  /** fetch aggregated fields from the table: "offers" */
  offers_aggregate: Offers_Aggregate;
  /** fetch data from the table: "offers" using primary key columns */
  offers_by_pk?: Maybe<Offers>;
  /** An array relationship */
  pages: Array<Pages>;
  /** An aggregate relationship */
  pages_aggregate: Pages_Aggregate;
  /** fetch data from the table: "pages" using primary key columns */
  pages_by_pk?: Maybe<Pages>;
  /** fetch data from the table: "partners" */
  partners: Array<Partners>;
  /** fetch aggregated fields from the table: "partners" */
  partners_aggregate: Partners_Aggregate;
  /** fetch data from the table: "partners" using primary key columns */
  partners_by_pk?: Maybe<Partners>;
  /** fetch data from the table: "payment_codes" */
  payment_codes: Array<Payment_Codes>;
  /** fetch aggregated fields from the table: "payment_codes" */
  payment_codes_aggregate: Payment_Codes_Aggregate;
  /** fetch data from the table: "payment_codes" using primary key columns */
  payment_codes_by_pk?: Maybe<Payment_Codes>;
  /** fetch data from the table: "payment_method_types" */
  payment_method_types: Array<Payment_Method_Types>;
  /** fetch aggregated fields from the table: "payment_method_types" */
  payment_method_types_aggregate: Payment_Method_Types_Aggregate;
  /** fetch data from the table: "payment_method_types" using primary key columns */
  payment_method_types_by_pk?: Maybe<Payment_Method_Types>;
  /** An array relationship */
  payment_methods: Array<Payment_Methods>;
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table: "personal_vehicles" */
  personal_vehicles: Array<Personal_Vehicles>;
  /** fetch aggregated fields from the table: "personal_vehicles" */
  personal_vehicles_aggregate: Personal_Vehicles_Aggregate;
  /** fetch data from the table: "personal_vehicles" using primary key columns */
  personal_vehicles_by_pk?: Maybe<Personal_Vehicles>;
  /** fetch data from the table: "pricing_codes" */
  pricing_codes: Array<Pricing_Codes>;
  /** fetch aggregated fields from the table: "pricing_codes" */
  pricing_codes_aggregate: Pricing_Codes_Aggregate;
  /** fetch data from the table: "pricing_codes" using primary key columns */
  pricing_codes_by_pk?: Maybe<Pricing_Codes>;
  /** An array relationship */
  product_groups: Array<Product_Groups>;
  /** An aggregate relationship */
  product_groups_aggregate: Product_Groups_Aggregate;
  /** fetch data from the table: "product_groups" using primary key columns */
  product_groups_by_pk?: Maybe<Product_Groups>;
  /** An array relationship */
  product_variants: Array<Product_Variants>;
  /** An aggregate relationship */
  product_variants_aggregate: Product_Variants_Aggregate;
  /** fetch data from the table: "product_variants" using primary key columns */
  product_variants_by_pk?: Maybe<Product_Variants>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "promotions" */
  promotions: Array<Promotions>;
  /** fetch aggregated fields from the table: "promotions" */
  promotions_aggregate: Promotions_Aggregate;
  /** fetch data from the table: "promotions" using primary key columns */
  promotions_by_pk?: Maybe<Promotions>;
  /** fetch data from the table: "redirects" */
  redirects: Array<Redirects>;
  /** fetch aggregated fields from the table: "redirects" */
  redirects_aggregate: Redirects_Aggregate;
  /** fetch data from the table: "redirects" using primary key columns */
  redirects_by_pk?: Maybe<Redirects>;
  /** An array relationship */
  rental_drivers: Array<Rental_Drivers>;
  /** An aggregate relationship */
  rental_drivers_aggregate: Rental_Drivers_Aggregate;
  /** fetch data from the table: "rental_drivers" using primary key columns */
  rental_drivers_by_pk?: Maybe<Rental_Drivers>;
  /** fetch data from the table: "rental_reservation_status" */
  rental_reservation_status: Array<Rental_Reservation_Status>;
  /** fetch aggregated fields from the table: "rental_reservation_status" */
  rental_reservation_status_aggregate: Rental_Reservation_Status_Aggregate;
  /** fetch data from the table: "rental_reservation_status" using primary key columns */
  rental_reservation_status_by_pk?: Maybe<Rental_Reservation_Status>;
  /** An array relationship */
  rental_reservations: Array<Rental_Reservations>;
  /** An aggregate relationship */
  rental_reservations_aggregate: Rental_Reservations_Aggregate;
  /** fetch data from the table: "rental_reservations" using primary key columns */
  rental_reservations_by_pk?: Maybe<Rental_Reservations>;
  /** execute function "search_articles" which returns "articles" */
  search_articles: Array<Articles>;
  /** execute function "search_articles" and query aggregates on result of table type "articles" */
  search_articles_aggregate: Articles_Aggregate;
  /** execute function "search_known_vehicles" which returns "known_vehicles" */
  search_known_vehicles: Array<Known_Vehicles>;
  /** execute function "search_known_vehicles" and query aggregates on result of table type "known_vehicles" */
  search_known_vehicles_aggregate: Known_Vehicles_Aggregate;
  /** execute function "search_rental_reservations" which returns "rental_reservations" */
  search_rental_reservations: Array<Rental_Reservations>;
  /** execute function "search_rental_reservations" and query aggregates on result of table type "rental_reservations" */
  search_rental_reservations_aggregate: Rental_Reservations_Aggregate;
  /** execute function "search_stocked_vehicles" which returns "stocked_vehicles" */
  search_stocked_vehicles: Array<Stocked_Vehicles>;
  /** execute function "search_stocked_vehicles" and query aggregates on result of table type "stocked_vehicles" */
  search_stocked_vehicles_aggregate: Stocked_Vehicles_Aggregate;
  /** An array relationship */
  service_booking_service_types: Array<Service_Booking_Service_Types>;
  /** An aggregate relationship */
  service_booking_service_types_aggregate: Service_Booking_Service_Types_Aggregate;
  /** fetch data from the table: "service_booking_service_types" using primary key columns */
  service_booking_service_types_by_pk?: Maybe<Service_Booking_Service_Types>;
  /** An array relationship */
  service_bookings: Array<Service_Bookings>;
  /** An aggregate relationship */
  service_bookings_aggregate: Service_Bookings_Aggregate;
  /** fetch data from the table: "service_bookings" using primary key columns */
  service_bookings_by_pk?: Maybe<Service_Bookings>;
  /** fetch data from the table: "service_types" */
  service_types: Array<Service_Types>;
  /** fetch aggregated fields from the table: "service_types" */
  service_types_aggregate: Service_Types_Aggregate;
  /** fetch data from the table: "service_types" using primary key columns */
  service_types_by_pk?: Maybe<Service_Types>;
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** An array relationship */
  showroom_apps: Array<Showroom_Apps>;
  /** An aggregate relationship */
  showroom_apps_aggregate: Showroom_Apps_Aggregate;
  /** fetch data from the table: "showroom_apps" using primary key columns */
  showroom_apps_by_pk?: Maybe<Showroom_Apps>;
  /** An array relationship */
  showroom_apps_inputs: Array<Showroom_Apps_Inputs>;
  /** An aggregate relationship */
  showroom_apps_inputs_aggregate: Showroom_Apps_Inputs_Aggregate;
  /** fetch data from the table: "showroom_apps_inputs" using primary key columns */
  showroom_apps_inputs_by_pk?: Maybe<Showroom_Apps_Inputs>;
  /** An array relationship */
  showroom_locales: Array<Showroom_Locales>;
  /** An aggregate relationship */
  showroom_locales_aggregate: Showroom_Locales_Aggregate;
  /** fetch data from the table: "showroom_locales" using primary key columns */
  showroom_locales_by_pk?: Maybe<Showroom_Locales>;
  /** An array relationship */
  showroom_pricing_codes: Array<Showroom_Pricing_Codes>;
  /** An aggregate relationship */
  showroom_pricing_codes_aggregate: Showroom_Pricing_Codes_Aggregate;
  /** fetch data from the table: "showroom_pricing_codes" using primary key columns */
  showroom_pricing_codes_by_pk?: Maybe<Showroom_Pricing_Codes>;
  /** An array relationship */
  showroom_stocked_vehicles: Array<Showroom_Stocked_Vehicles>;
  /** An aggregate relationship */
  showroom_stocked_vehicles_aggregate: Showroom_Stocked_Vehicles_Aggregate;
  /** fetch data from the table: "showroom_stocked_vehicles" using primary key columns */
  showroom_stocked_vehicles_by_pk?: Maybe<Showroom_Stocked_Vehicles>;
  /** An array relationship */
  showroom_users: Array<Showroom_Users>;
  /** An aggregate relationship */
  showroom_users_aggregate: Showroom_Users_Aggregate;
  /** fetch data from the table: "showroom_users" using primary key columns */
  showroom_users_by_pk?: Maybe<Showroom_Users>;
  /** An array relationship */
  showrooms: Array<Showrooms>;
  /** An aggregate relationship */
  showrooms_aggregate: Showrooms_Aggregate;
  /** fetch data from the table: "showrooms" using primary key columns */
  showrooms_by_pk?: Maybe<Showrooms>;
  /** fetch data from the table: "showrooms_partners" */
  showrooms_partners: Array<Showrooms_Partners>;
  /** fetch aggregated fields from the table: "showrooms_partners" */
  showrooms_partners_aggregate: Showrooms_Partners_Aggregate;
  /** fetch data from the table: "showrooms_partners" using primary key columns */
  showrooms_partners_by_pk?: Maybe<Showrooms_Partners>;
  /** An array relationship */
  showrooms_promotions: Array<Showrooms_Promotions>;
  /** An aggregate relationship */
  showrooms_promotions_aggregate: Showrooms_Promotions_Aggregate;
  /** fetch data from the table: "showrooms_promotions" using primary key columns */
  showrooms_promotions_by_pk?: Maybe<Showrooms_Promotions>;
  /** An array relationship */
  stocked_vehicle_finance_products: Array<Stocked_Vehicle_Finance_Products>;
  /** An aggregate relationship */
  stocked_vehicle_finance_products_aggregate: Stocked_Vehicle_Finance_Products_Aggregate;
  /** fetch data from the table: "stocked_vehicle_finance_products" using primary key columns */
  stocked_vehicle_finance_products_by_pk?: Maybe<Stocked_Vehicle_Finance_Products>;
  /** An array relationship */
  stocked_vehicle_handover_locations: Array<Stocked_Vehicle_Handover_Locations>;
  /** An aggregate relationship */
  stocked_vehicle_handover_locations_aggregate: Stocked_Vehicle_Handover_Locations_Aggregate;
  /** fetch data from the table: "stocked_vehicle_handover_locations" using primary key columns */
  stocked_vehicle_handover_locations_by_pk?: Maybe<Stocked_Vehicle_Handover_Locations>;
  /** An array relationship */
  stocked_vehicle_offers: Array<Stocked_Vehicle_Offers>;
  /** An aggregate relationship */
  stocked_vehicle_offers_aggregate: Stocked_Vehicle_Offers_Aggregate;
  /** fetch data from the table: "stocked_vehicle_offers" using primary key columns */
  stocked_vehicle_offers_by_pk?: Maybe<Stocked_Vehicle_Offers>;
  /** An array relationship */
  stocked_vehicles: Array<Stocked_Vehicles>;
  /** An aggregate relationship */
  stocked_vehicles_aggregate: Stocked_Vehicles_Aggregate;
  /** fetch data from the table: "stocked_vehicles" using primary key columns */
  stocked_vehicles_by_pk?: Maybe<Stocked_Vehicles>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  test_drives: Array<Test_Drives>;
  /** An aggregate relationship */
  test_drives_aggregate: Test_Drives_Aggregate;
  /** fetch data from the table: "test_drives" using primary key columns */
  test_drives_by_pk?: Maybe<Test_Drives>;
  /** An array relationship */
  trade_in_plans: Array<Trade_In_Plans>;
  /** An aggregate relationship */
  trade_in_plans_aggregate: Trade_In_Plans_Aggregate;
  /** fetch data from the table: "trade_in_plans" using primary key columns */
  trade_in_plans_by_pk?: Maybe<Trade_In_Plans>;
  /** An array relationship */
  trade_in_valuations: Array<Trade_In_Valuations>;
  /** An aggregate relationship */
  trade_in_valuations_aggregate: Trade_In_Valuations_Aggregate;
  /** fetch data from the table: "trade_in_valuations" using primary key columns */
  trade_in_valuations_by_pk?: Maybe<Trade_In_Valuations>;
  /** fetch data from the table: "transport_types" */
  transport_types: Array<Transport_Types>;
  /** fetch aggregated fields from the table: "transport_types" */
  transport_types_aggregate: Transport_Types_Aggregate;
  /** fetch data from the table: "transport_types" using primary key columns */
  transport_types_by_pk?: Maybe<Transport_Types>;
  /** An array relationship */
  user_interests: Array<User_Interests>;
  /** An aggregate relationship */
  user_interests_aggregate: User_Interests_Aggregate;
  /** fetch data from the table: "user_interests" using primary key columns */
  user_interests_by_pk?: Maybe<User_Interests>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "valuers" */
  valuers: Array<Valuers>;
  /** fetch aggregated fields from the table: "valuers" */
  valuers_aggregate: Valuers_Aggregate;
  /** fetch data from the table: "valuers" using primary key columns */
  valuers_by_pk?: Maybe<Valuers>;
  /** fetch data from the table: "vehicle_conditions" */
  vehicle_conditions: Array<Vehicle_Conditions>;
  /** fetch aggregated fields from the table: "vehicle_conditions" */
  vehicle_conditions_aggregate: Vehicle_Conditions_Aggregate;
  /** fetch data from the table: "vehicle_conditions" using primary key columns */
  vehicle_conditions_by_pk?: Maybe<Vehicle_Conditions>;
  /** An array relationship */
  vehicle_lists: Array<Vehicle_Lists>;
  /** An aggregate relationship */
  vehicle_lists_aggregate: Vehicle_Lists_Aggregate;
  /** fetch data from the table: "vehicle_lists" using primary key columns */
  vehicle_lists_by_pk?: Maybe<Vehicle_Lists>;
  /** fetch data from the table: "vehicle_order_activity" */
  vehicle_order_activity: Array<Vehicle_Order_Activity>;
  /** fetch aggregated fields from the table: "vehicle_order_activity" */
  vehicle_order_activity_aggregate: Vehicle_Order_Activity_Aggregate;
  /** fetch data from the table: "vehicle_order_activity" using primary key columns */
  vehicle_order_activity_by_pk?: Maybe<Vehicle_Order_Activity>;
  /** An array relationship */
  vehicle_order_product_variants: Array<Vehicle_Order_Product_Variants>;
  /** An aggregate relationship */
  vehicle_order_product_variants_aggregate: Vehicle_Order_Product_Variants_Aggregate;
  /** fetch data from the table: "vehicle_order_product_variants" using primary key columns */
  vehicle_order_product_variants_by_pk?: Maybe<Vehicle_Order_Product_Variants>;
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  /** fetch data from the table: "vehicle_orders" using primary key columns */
  vehicle_orders_by_pk?: Maybe<Vehicle_Orders>;
  /** fetch data from the table: "vehicle_specifications" */
  vehicle_specifications: Array<Vehicle_Specifications>;
  /** fetch aggregated fields from the table: "vehicle_specifications" */
  vehicle_specifications_aggregate: Vehicle_Specifications_Aggregate;
  /** fetch data from the table: "vehicle_specifications" using primary key columns */
  vehicle_specifications_by_pk?: Maybe<Vehicle_Specifications>;
  /** fetch data from the table: "wish_list" */
  wish_list: Array<Wish_List>;
  /** fetch aggregated fields from the table: "wish_list" */
  wish_list_aggregate: Wish_List_Aggregate;
  /** fetch data from the table: "wish_list" using primary key columns */
  wish_list_by_pk?: Maybe<Wish_List>;
};


export type Query_RootActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Query_RootActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Query_RootActivities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApi_ClientsArgs = {
  distinct_on?: Maybe<Array<Api_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Clients_Order_By>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


export type Query_RootApi_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Clients_Order_By>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


export type Query_RootApi_Clients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApp_ActionsArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


export type Query_RootApp_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


export type Query_RootApp_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApp_EventsArgs = {
  distinct_on?: Maybe<Array<App_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Events_Order_By>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


export type Query_RootApp_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Events_Order_By>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


export type Query_RootApp_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApp_InputsArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


export type Query_RootApp_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


export type Query_RootApp_Inputs_By_PkArgs = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  version_num: Scalars['String'];
};


export type Query_RootApp_VersionsArgs = {
  distinct_on?: Maybe<Array<App_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Versions_Order_By>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


export type Query_RootApp_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Versions_Order_By>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


export type Query_RootApp_Versions_By_PkArgs = {
  app_id: Scalars['uuid'];
  version_num: Scalars['String'];
};


export type Query_RootAppsArgs = {
  distinct_on?: Maybe<Array<Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apps_Order_By>>;
  where?: Maybe<Apps_Bool_Exp>;
};


export type Query_RootApps_AggregateArgs = {
  distinct_on?: Maybe<Array<Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apps_Order_By>>;
  where?: Maybe<Apps_Bool_Exp>;
};


export type Query_RootApps_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootArticlesArgs = {
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Query_RootArticles_AggregateArgs = {
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Query_RootArticles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


export type Query_RootAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


export type Query_RootAssets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuction_BidsArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


export type Query_RootAuction_Bids_AggregateArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


export type Query_RootAuction_Bids_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuctionsArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


export type Query_RootAuctions_AggregateArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


export type Query_RootAuctions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCalculator_SubmissionArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


export type Query_RootCalculator_Submission_AggregateArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


export type Query_RootCalculator_Submission_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustom_Field_ValuesArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


export type Query_RootCustom_Field_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


export type Query_RootCustom_Field_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustom_FieldsArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


export type Query_RootCustom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


export type Query_RootCustom_Fields_By_PkArgs = {
  entity: Scalars['String'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootCustom_VehiclesArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


export type Query_RootCustom_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


export type Query_RootCustom_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFilter_FieldsArgs = {
  distinct_on?: Maybe<Array<Filter_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Fields_Order_By>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


export type Query_RootFilter_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Fields_Order_By>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


export type Query_RootFilter_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFilter_LocationsArgs = {
  distinct_on?: Maybe<Array<Filter_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Locations_Order_By>>;
  where?: Maybe<Filter_Locations_Bool_Exp>;
};


export type Query_RootFilter_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Locations_Order_By>>;
  where?: Maybe<Filter_Locations_Bool_Exp>;
};


export type Query_RootFilter_Locations_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFilter_NamesArgs = {
  distinct_on?: Maybe<Array<Filter_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Names_Order_By>>;
  where?: Maybe<Filter_Names_Bool_Exp>;
};


export type Query_RootFilter_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Names_Order_By>>;
  where?: Maybe<Filter_Names_Bool_Exp>;
};


export type Query_RootFilter_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFinance_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


export type Query_RootFinance_Applications_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


export type Query_RootFinance_Applications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFinance_FeesArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


export type Query_RootFinance_Fees_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


export type Query_RootFinance_Fees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFinance_Fixed_Rate_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Finance_Fixed_Rate_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fixed_Rate_Configurations_Order_By>>;
  where?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
};


export type Query_RootFinance_Fixed_Rate_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fixed_Rate_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fixed_Rate_Configurations_Order_By>>;
  where?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
};


export type Query_RootFinance_Fixed_Rate_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFinance_FrequencyArgs = {
  distinct_on?: Maybe<Array<Finance_Frequency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Frequency_Order_By>>;
  where?: Maybe<Finance_Frequency_Bool_Exp>;
};


export type Query_RootFinance_Frequency_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Frequency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Frequency_Order_By>>;
  where?: Maybe<Finance_Frequency_Bool_Exp>;
};


export type Query_RootFinance_Frequency_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFinance_Gfv_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Configurations_Order_By>>;
  where?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
};


export type Query_RootFinance_Gfv_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Configurations_Order_By>>;
  where?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
};


export type Query_RootFinance_Gfv_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFinance_Gfv_Residual_ValuesArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


export type Query_RootFinance_Gfv_Residual_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


export type Query_RootFinance_Gfv_Residual_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFinance_Product_OffersArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Offers_Order_By>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


export type Query_RootFinance_Product_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Offers_Order_By>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


export type Query_RootFinance_Product_Offers_By_PkArgs = {
  finance_product_id: Scalars['uuid'];
  offer_id: Scalars['uuid'];
};


export type Query_RootFinance_Product_TypesArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Types_Order_By>>;
  where?: Maybe<Finance_Product_Types_Bool_Exp>;
};


export type Query_RootFinance_Product_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Types_Order_By>>;
  where?: Maybe<Finance_Product_Types_Bool_Exp>;
};


export type Query_RootFinance_Product_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFinance_ProductsArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


export type Query_RootFinance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


export type Query_RootFinance_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFinance_TermsArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


export type Query_RootFinance_Terms_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


export type Query_RootFinance_Terms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_FieldsArgs = {
  distinct_on?: Maybe<Array<Form_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Fields_Order_By>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


export type Query_RootForm_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Fields_Order_By>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


export type Query_RootForm_Fields_By_PkArgs = {
  form_id: Scalars['uuid'];
  name: Scalars['String'];
};


export type Query_RootForm_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Query_RootForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Query_RootForm_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFormsArgs = {
  distinct_on?: Maybe<Array<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Query_RootForms_AggregateArgs = {
  distinct_on?: Maybe<Array<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Query_RootForms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGet_Autograb_Vehicles_By_SearchArgs = {
  search: Scalars['String'];
};


export type Query_RootInsights_Click_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Click_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Click_Events_Order_By>>;
  where?: Maybe<Insights_Click_Events_Bool_Exp>;
};


export type Query_RootInsights_Click_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Click_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Click_Events_Order_By>>;
  where?: Maybe<Insights_Click_Events_Bool_Exp>;
};


export type Query_RootInsights_Click_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInsights_DevicesArgs = {
  distinct_on?: Maybe<Array<Insights_Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Devices_Order_By>>;
  where?: Maybe<Insights_Devices_Bool_Exp>;
};


export type Query_RootInsights_Devices_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Devices_Order_By>>;
  where?: Maybe<Insights_Devices_Bool_Exp>;
};


export type Query_RootInsights_Devices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInsights_Event_TypesArgs = {
  distinct_on?: Maybe<Array<Insights_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Event_Types_Order_By>>;
  where?: Maybe<Insights_Event_Types_Bool_Exp>;
};


export type Query_RootInsights_Event_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Event_Types_Order_By>>;
  where?: Maybe<Insights_Event_Types_Bool_Exp>;
};


export type Query_RootInsights_Event_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootInsights_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Events_Order_By>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};


export type Query_RootInsights_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Events_Order_By>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};


export type Query_RootInsights_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInsights_Page_View_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Page_View_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Page_View_Events_Order_By>>;
  where?: Maybe<Insights_Page_View_Events_Bool_Exp>;
};


export type Query_RootInsights_Page_View_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Page_View_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Page_View_Events_Order_By>>;
  where?: Maybe<Insights_Page_View_Events_Bool_Exp>;
};


export type Query_RootInsights_Page_View_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInsights_SessionsArgs = {
  distinct_on?: Maybe<Array<Insights_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Sessions_Order_By>>;
  where?: Maybe<Insights_Sessions_Bool_Exp>;
};


export type Query_RootInsights_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Sessions_Order_By>>;
  where?: Maybe<Insights_Sessions_Bool_Exp>;
};


export type Query_RootInsights_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInsights_VisitorsArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


export type Query_RootInsights_Visitors_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


export type Query_RootInsights_Visitors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInterestsArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Query_RootInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Query_RootInterests_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootKnown_VehiclesArgs = {
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Query_RootKnown_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Query_RootKnown_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocalesArgs = {
  distinct_on?: Maybe<Array<Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locales_Order_By>>;
  where?: Maybe<Locales_Bool_Exp>;
};


export type Query_RootLocales_AggregateArgs = {
  distinct_on?: Maybe<Array<Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locales_Order_By>>;
  where?: Maybe<Locales_Bool_Exp>;
};


export type Query_RootLocales_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootLocation_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


export type Query_RootLocation_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


export type Query_RootLocation_Service_Types_By_PkArgs = {
  location_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};


export type Query_RootLocation_Transport_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


export type Query_RootLocation_Transport_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


export type Query_RootLocation_Transport_Types_By_PkArgs = {
  location_id: Scalars['uuid'];
  transport_type_id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMatch_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Match_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Submissions_Order_By>>;
  where?: Maybe<Match_Submissions_Bool_Exp>;
};


export type Query_RootMatch_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Match_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Submissions_Order_By>>;
  where?: Maybe<Match_Submissions_Bool_Exp>;
};


export type Query_RootMatch_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMomentsArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


export type Query_RootMoments_AggregateArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


export type Query_RootMoments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOffer_TypesArgs = {
  distinct_on?: Maybe<Array<Offer_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offer_Types_Order_By>>;
  where?: Maybe<Offer_Types_Bool_Exp>;
};


export type Query_RootOffer_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Offer_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offer_Types_Order_By>>;
  where?: Maybe<Offer_Types_Bool_Exp>;
};


export type Query_RootOffer_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Query_RootOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Query_RootOffers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPagesArgs = {
  distinct_on?: Maybe<Array<Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pages_Order_By>>;
  where?: Maybe<Pages_Bool_Exp>;
};


export type Query_RootPages_AggregateArgs = {
  distinct_on?: Maybe<Array<Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pages_Order_By>>;
  where?: Maybe<Pages_Bool_Exp>;
};


export type Query_RootPages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPartnersArgs = {
  distinct_on?: Maybe<Array<Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partners_Order_By>>;
  where?: Maybe<Partners_Bool_Exp>;
};


export type Query_RootPartners_AggregateArgs = {
  distinct_on?: Maybe<Array<Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partners_Order_By>>;
  where?: Maybe<Partners_Bool_Exp>;
};


export type Query_RootPartners_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayment_CodesArgs = {
  distinct_on?: Maybe<Array<Payment_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Codes_Order_By>>;
  where?: Maybe<Payment_Codes_Bool_Exp>;
};


export type Query_RootPayment_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Codes_Order_By>>;
  where?: Maybe<Payment_Codes_Bool_Exp>;
};


export type Query_RootPayment_Codes_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPayment_Method_TypesArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Types_Order_By>>;
  where?: Maybe<Payment_Method_Types_Bool_Exp>;
};


export type Query_RootPayment_Method_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Types_Order_By>>;
  where?: Maybe<Payment_Method_Types_Bool_Exp>;
};


export type Query_RootPayment_Method_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


export type Query_RootPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


export type Query_RootPayment_Methods_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPersonal_VehiclesArgs = {
  distinct_on?: Maybe<Array<Personal_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Personal_Vehicles_Order_By>>;
  where?: Maybe<Personal_Vehicles_Bool_Exp>;
};


export type Query_RootPersonal_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Personal_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Personal_Vehicles_Order_By>>;
  where?: Maybe<Personal_Vehicles_Bool_Exp>;
};


export type Query_RootPersonal_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPricing_CodesArgs = {
  distinct_on?: Maybe<Array<Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricing_Codes_Order_By>>;
  where?: Maybe<Pricing_Codes_Bool_Exp>;
};


export type Query_RootPricing_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricing_Codes_Order_By>>;
  where?: Maybe<Pricing_Codes_Bool_Exp>;
};


export type Query_RootPricing_Codes_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootProduct_GroupsArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


export type Query_RootProduct_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


export type Query_RootProduct_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProduct_VariantsArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Query_RootProduct_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Query_RootProduct_Variants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPromotionsArgs = {
  distinct_on?: Maybe<Array<Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotions_Order_By>>;
  where?: Maybe<Promotions_Bool_Exp>;
};


export type Query_RootPromotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotions_Order_By>>;
  where?: Maybe<Promotions_Bool_Exp>;
};


export type Query_RootPromotions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRedirectsArgs = {
  distinct_on?: Maybe<Array<Redirects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redirects_Order_By>>;
  where?: Maybe<Redirects_Bool_Exp>;
};


export type Query_RootRedirects_AggregateArgs = {
  distinct_on?: Maybe<Array<Redirects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redirects_Order_By>>;
  where?: Maybe<Redirects_Bool_Exp>;
};


export type Query_RootRedirects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRental_DriversArgs = {
  distinct_on?: Maybe<Array<Rental_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Drivers_Order_By>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};


export type Query_RootRental_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Drivers_Order_By>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};


export type Query_RootRental_Drivers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRental_Reservation_StatusArgs = {
  distinct_on?: Maybe<Array<Rental_Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservation_Status_Order_By>>;
  where?: Maybe<Rental_Reservation_Status_Bool_Exp>;
};


export type Query_RootRental_Reservation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservation_Status_Order_By>>;
  where?: Maybe<Rental_Reservation_Status_Bool_Exp>;
};


export type Query_RootRental_Reservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRental_ReservationsArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Query_RootRental_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Query_RootRental_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSearch_ArticlesArgs = {
  args: Search_Articles_Args;
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Query_RootSearch_Articles_AggregateArgs = {
  args: Search_Articles_Args;
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Query_RootSearch_Known_VehiclesArgs = {
  args: Search_Known_Vehicles_Args;
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Query_RootSearch_Known_Vehicles_AggregateArgs = {
  args: Search_Known_Vehicles_Args;
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Query_RootSearch_Rental_ReservationsArgs = {
  args: Search_Rental_Reservations_Args;
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Query_RootSearch_Rental_Reservations_AggregateArgs = {
  args: Search_Rental_Reservations_Args;
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Query_RootSearch_Stocked_VehiclesArgs = {
  args: Search_Stocked_Vehicles_Args;
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Query_RootSearch_Stocked_Vehicles_AggregateArgs = {
  args: Search_Stocked_Vehicles_Args;
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Query_RootService_Booking_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


export type Query_RootService_Booking_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


export type Query_RootService_Booking_Service_Types_By_PkArgs = {
  service_booking_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};


export type Query_RootService_BookingsArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


export type Query_RootService_Bookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


export type Query_RootService_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootService_TypesArgs = {
  distinct_on?: Maybe<Array<Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Types_Order_By>>;
  where?: Maybe<Service_Types_Bool_Exp>;
};


export type Query_RootService_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Types_Order_By>>;
  where?: Maybe<Service_Types_Bool_Exp>;
};


export type Query_RootService_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSessionsArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootShowroom_AppsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


export type Query_RootShowroom_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


export type Query_RootShowroom_Apps_By_PkArgs = {
  app_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootShowroom_Apps_InputsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


export type Query_RootShowroom_Apps_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


export type Query_RootShowroom_Apps_Inputs_By_PkArgs = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootShowroom_LocalesArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


export type Query_RootShowroom_Locales_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


export type Query_RootShowroom_Locales_By_PkArgs = {
  locale_code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootShowroom_Pricing_CodesArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


export type Query_RootShowroom_Pricing_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


export type Query_RootShowroom_Pricing_Codes_By_PkArgs = {
  code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootShowroom_Stocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


export type Query_RootShowroom_Stocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


export type Query_RootShowroom_Stocked_Vehicles_By_PkArgs = {
  showroom_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Query_RootShowroom_UsersArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


export type Query_RootShowroom_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


export type Query_RootShowroom_Users_By_PkArgs = {
  showroom_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootShowroomsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


export type Query_RootShowrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


export type Query_RootShowrooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootShowrooms_PartnersArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


export type Query_RootShowrooms_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


export type Query_RootShowrooms_Partners_By_PkArgs = {
  partner_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootShowrooms_PromotionsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


export type Query_RootShowrooms_Promotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


export type Query_RootShowrooms_Promotions_By_PkArgs = {
  promotion_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


export type Query_RootStocked_Vehicle_Finance_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


export type Query_RootStocked_Vehicle_Finance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


export type Query_RootStocked_Vehicle_Finance_Products_By_PkArgs = {
  finance_product_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Query_RootStocked_Vehicle_Handover_LocationsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


export type Query_RootStocked_Vehicle_Handover_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


export type Query_RootStocked_Vehicle_Handover_Locations_By_PkArgs = {
  location_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Query_RootStocked_Vehicle_OffersArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Offers_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


export type Query_RootStocked_Vehicle_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Offers_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


export type Query_RootStocked_Vehicle_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Query_RootStocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Query_RootStocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Query_RootStocked_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTest_DrivesArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


export type Query_RootTest_Drives_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


export type Query_RootTest_Drives_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTrade_In_PlansArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


export type Query_RootTrade_In_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


export type Query_RootTrade_In_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTrade_In_ValuationsArgs = {
  distinct_on?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Valuations_Order_By>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};


export type Query_RootTrade_In_Valuations_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Valuations_Order_By>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};


export type Query_RootTrade_In_Valuations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTransport_TypesArgs = {
  distinct_on?: Maybe<Array<Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transport_Types_Order_By>>;
  where?: Maybe<Transport_Types_Bool_Exp>;
};


export type Query_RootTransport_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transport_Types_Order_By>>;
  where?: Maybe<Transport_Types_Bool_Exp>;
};


export type Query_RootTransport_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_InterestsArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


export type Query_RootUser_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


export type Query_RootUser_Interests_By_PkArgs = {
  interest_code: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootValuersArgs = {
  distinct_on?: Maybe<Array<Valuers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valuers_Order_By>>;
  where?: Maybe<Valuers_Bool_Exp>;
};


export type Query_RootValuers_AggregateArgs = {
  distinct_on?: Maybe<Array<Valuers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valuers_Order_By>>;
  where?: Maybe<Valuers_Bool_Exp>;
};


export type Query_RootValuers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVehicle_ConditionsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Conditions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Conditions_Order_By>>;
  where?: Maybe<Vehicle_Conditions_Bool_Exp>;
};


export type Query_RootVehicle_Conditions_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Conditions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Conditions_Order_By>>;
  where?: Maybe<Vehicle_Conditions_Bool_Exp>;
};


export type Query_RootVehicle_Conditions_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootVehicle_ListsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Lists_Order_By>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


export type Query_RootVehicle_Lists_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Lists_Order_By>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


export type Query_RootVehicle_Lists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVehicle_Order_ActivityArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Activity_Order_By>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


export type Query_RootVehicle_Order_Activity_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Activity_Order_By>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


export type Query_RootVehicle_Order_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVehicle_Order_Product_VariantsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


export type Query_RootVehicle_Order_Product_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


export type Query_RootVehicle_Order_Product_Variants_By_PkArgs = {
  product_variant_id: Scalars['uuid'];
  vehicle_order_id: Scalars['uuid'];
};


export type Query_RootVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


export type Query_RootVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


export type Query_RootVehicle_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVehicle_SpecificationsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Specifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Specifications_Order_By>>;
  where?: Maybe<Vehicle_Specifications_Bool_Exp>;
};


export type Query_RootVehicle_Specifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Specifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Specifications_Order_By>>;
  where?: Maybe<Vehicle_Specifications_Bool_Exp>;
};


export type Query_RootVehicle_Specifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWish_ListArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};


export type Query_RootWish_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};


export type Query_RootWish_List_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "redirects" */
export type Redirects = {
  __typename?: 'redirects';
  created_at: Scalars['timestamptz'];
  from: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  to: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "redirects" */
export type Redirects_Aggregate = {
  __typename?: 'redirects_aggregate';
  aggregate?: Maybe<Redirects_Aggregate_Fields>;
  nodes: Array<Redirects>;
};

/** aggregate fields of "redirects" */
export type Redirects_Aggregate_Fields = {
  __typename?: 'redirects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Redirects_Max_Fields>;
  min?: Maybe<Redirects_Min_Fields>;
};


/** aggregate fields of "redirects" */
export type Redirects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Redirects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "redirects". All fields are combined with a logical 'AND'. */
export type Redirects_Bool_Exp = {
  _and?: Maybe<Array<Redirects_Bool_Exp>>;
  _not?: Maybe<Redirects_Bool_Exp>;
  _or?: Maybe<Array<Redirects_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  to?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "redirects" */
export enum Redirects_Constraint {
  /** unique or primary key constraint on columns "from", "showroom_id" */
  RedirectsFromShowroomIdKey = 'redirects_from_showroom_id_key',
  /** unique or primary key constraint on columns "id" */
  RedirectsPkey = 'redirects_pkey'
}

/** input type for inserting data into table "redirects" */
export type Redirects_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Redirects_Max_Fields = {
  __typename?: 'redirects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Redirects_Min_Fields = {
  __typename?: 'redirects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "redirects" */
export type Redirects_Mutation_Response = {
  __typename?: 'redirects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Redirects>;
};

/** on_conflict condition type for table "redirects" */
export type Redirects_On_Conflict = {
  constraint: Redirects_Constraint;
  update_columns?: Array<Redirects_Update_Column>;
  where?: Maybe<Redirects_Bool_Exp>;
};

/** Ordering options when selecting data from "redirects". */
export type Redirects_Order_By = {
  created_at?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: redirects */
export type Redirects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "redirects" */
export enum Redirects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "redirects" */
export type Redirects_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "redirects" */
export type Redirects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Redirects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Redirects_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "redirects" */
export enum Redirects_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Redirects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Redirects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Redirects_Bool_Exp;
};

/** columns and relationships of "rental_drivers" */
export type Rental_Drivers = {
  __typename?: 'rental_drivers';
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rental_reservation_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "rental_drivers" */
export type Rental_Drivers_Aggregate = {
  __typename?: 'rental_drivers_aggregate';
  aggregate?: Maybe<Rental_Drivers_Aggregate_Fields>;
  nodes: Array<Rental_Drivers>;
};

export type Rental_Drivers_Aggregate_Bool_Exp = {
  count?: Maybe<Rental_Drivers_Aggregate_Bool_Exp_Count>;
};

export type Rental_Drivers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Rental_Drivers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Rental_Drivers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_drivers" */
export type Rental_Drivers_Aggregate_Fields = {
  __typename?: 'rental_drivers_aggregate_fields';
  avg?: Maybe<Rental_Drivers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rental_Drivers_Max_Fields>;
  min?: Maybe<Rental_Drivers_Min_Fields>;
  stddev?: Maybe<Rental_Drivers_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Drivers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Drivers_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Drivers_Sum_Fields>;
  var_pop?: Maybe<Rental_Drivers_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Drivers_Var_Samp_Fields>;
  variance?: Maybe<Rental_Drivers_Variance_Fields>;
};


/** aggregate fields of "rental_drivers" */
export type Rental_Drivers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rental_Drivers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rental_drivers" */
export type Rental_Drivers_Aggregate_Order_By = {
  avg?: Maybe<Rental_Drivers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rental_Drivers_Max_Order_By>;
  min?: Maybe<Rental_Drivers_Min_Order_By>;
  stddev?: Maybe<Rental_Drivers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rental_Drivers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rental_Drivers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rental_Drivers_Sum_Order_By>;
  var_pop?: Maybe<Rental_Drivers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rental_Drivers_Var_Samp_Order_By>;
  variance?: Maybe<Rental_Drivers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental_drivers" */
export type Rental_Drivers_Arr_Rel_Insert_Input = {
  data: Array<Rental_Drivers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rental_Drivers_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Drivers_Avg_Fields = {
  __typename?: 'rental_drivers_avg_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rental_drivers" */
export type Rental_Drivers_Avg_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental_drivers". All fields are combined with a logical 'AND'. */
export type Rental_Drivers_Bool_Exp = {
  _and?: Maybe<Array<Rental_Drivers_Bool_Exp>>;
  _not?: Maybe<Rental_Drivers_Bool_Exp>;
  _or?: Maybe<Array<Rental_Drivers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  index?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  rental_reservation_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_drivers" */
export enum Rental_Drivers_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalDriversPkey = 'rental_drivers_pkey',
  /** unique or primary key constraint on columns "index", "rental_reservation_id" */
  RentalDriversRentalReservationIdIndexKey = 'rental_drivers_rental_reservation_id_index_key'
}

/** input type for incrementing numeric columns in table "rental_drivers" */
export type Rental_Drivers_Inc_Input = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "rental_drivers" */
export type Rental_Drivers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rental_Drivers_Max_Fields = {
  __typename?: 'rental_drivers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "rental_drivers" */
export type Rental_Drivers_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Drivers_Min_Fields = {
  __typename?: 'rental_drivers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "rental_drivers" */
export type Rental_Drivers_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "rental_drivers" */
export type Rental_Drivers_Mutation_Response = {
  __typename?: 'rental_drivers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Drivers>;
};

/** on_conflict condition type for table "rental_drivers" */
export type Rental_Drivers_On_Conflict = {
  constraint: Rental_Drivers_Constraint;
  update_columns?: Array<Rental_Drivers_Update_Column>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_drivers". */
export type Rental_Drivers_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  index?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rental_reservation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: rental_drivers */
export type Rental_Drivers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rental_drivers" */
export enum Rental_Drivers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  RentalReservationId = 'rental_reservation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "rental_drivers" */
export type Rental_Drivers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Rental_Drivers_Stddev_Fields = {
  __typename?: 'rental_drivers_stddev_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rental_drivers" */
export type Rental_Drivers_Stddev_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Drivers_Stddev_Pop_Fields = {
  __typename?: 'rental_drivers_stddev_pop_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rental_drivers" */
export type Rental_Drivers_Stddev_Pop_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Drivers_Stddev_Samp_Fields = {
  __typename?: 'rental_drivers_stddev_samp_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rental_drivers" */
export type Rental_Drivers_Stddev_Samp_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** Streaming cursor of the table "rental_drivers" */
export type Rental_Drivers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Drivers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Drivers_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rental_reservation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Rental_Drivers_Sum_Fields = {
  __typename?: 'rental_drivers_sum_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "rental_drivers" */
export type Rental_Drivers_Sum_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** update columns of table "rental_drivers" */
export enum Rental_Drivers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  RentalReservationId = 'rental_reservation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Rental_Drivers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Rental_Drivers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rental_Drivers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Drivers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rental_Drivers_Var_Pop_Fields = {
  __typename?: 'rental_drivers_var_pop_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rental_drivers" */
export type Rental_Drivers_Var_Pop_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Drivers_Var_Samp_Fields = {
  __typename?: 'rental_drivers_var_samp_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rental_drivers" */
export type Rental_Drivers_Var_Samp_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Drivers_Variance_Fields = {
  __typename?: 'rental_drivers_variance_fields';
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rental_drivers" */
export type Rental_Drivers_Variance_Order_By = {
  /** The order of the driver on the reservation. 0 = primary / first. */
  index?: Maybe<Order_By>;
};

/** columns and relationships of "rental_reservation_status" */
export type Rental_Reservation_Status = {
  __typename?: 'rental_reservation_status';
  description: Scalars['String'];
  /** An array relationship */
  rental_reservations: Array<Rental_Reservations>;
  /** An aggregate relationship */
  rental_reservations_aggregate: Rental_Reservations_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "rental_reservation_status" */
export type Rental_Reservation_StatusRental_ReservationsArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


/** columns and relationships of "rental_reservation_status" */
export type Rental_Reservation_StatusRental_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};

/** aggregated selection of "rental_reservation_status" */
export type Rental_Reservation_Status_Aggregate = {
  __typename?: 'rental_reservation_status_aggregate';
  aggregate?: Maybe<Rental_Reservation_Status_Aggregate_Fields>;
  nodes: Array<Rental_Reservation_Status>;
};

/** aggregate fields of "rental_reservation_status" */
export type Rental_Reservation_Status_Aggregate_Fields = {
  __typename?: 'rental_reservation_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rental_Reservation_Status_Max_Fields>;
  min?: Maybe<Rental_Reservation_Status_Min_Fields>;
};


/** aggregate fields of "rental_reservation_status" */
export type Rental_Reservation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rental_Reservation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rental_reservation_status". All fields are combined with a logical 'AND'. */
export type Rental_Reservation_Status_Bool_Exp = {
  _and?: Maybe<Array<Rental_Reservation_Status_Bool_Exp>>;
  _not?: Maybe<Rental_Reservation_Status_Bool_Exp>;
  _or?: Maybe<Array<Rental_Reservation_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  rental_reservations?: Maybe<Rental_Reservations_Bool_Exp>;
  rental_reservations_aggregate?: Maybe<Rental_Reservations_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_reservation_status" */
export enum Rental_Reservation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  RentalReservationStatusPkey = 'rental_reservation_status_pkey'
}

export enum Rental_Reservation_Status_Enum {
  /** Booking cancelled. */
  Cancelled = 'cancelled',
  /** Booking complete. */
  Closed = 'closed',
  /** Booking has begun. */
  Open = 'open',
  /** Booking is in progress and has not been finalised yet.  */
  Pending = 'pending',
  /** Booking confirmed but yet to start.  */
  Reserved = 'reserved'
}

/** Boolean expression to compare columns of type "rental_reservation_status_enum". All fields are combined with logical 'AND'. */
export type Rental_Reservation_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Rental_Reservation_Status_Enum>;
  _in?: Maybe<Array<Rental_Reservation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Rental_Reservation_Status_Enum>;
  _nin?: Maybe<Array<Rental_Reservation_Status_Enum>>;
};

/** input type for inserting data into table "rental_reservation_status" */
export type Rental_Reservation_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  rental_reservations?: Maybe<Rental_Reservations_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rental_Reservation_Status_Max_Fields = {
  __typename?: 'rental_reservation_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rental_Reservation_Status_Min_Fields = {
  __typename?: 'rental_reservation_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rental_reservation_status" */
export type Rental_Reservation_Status_Mutation_Response = {
  __typename?: 'rental_reservation_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Reservation_Status>;
};

/** input type for inserting object relation for remote table "rental_reservation_status" */
export type Rental_Reservation_Status_Obj_Rel_Insert_Input = {
  data: Rental_Reservation_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rental_Reservation_Status_On_Conflict>;
};

/** on_conflict condition type for table "rental_reservation_status" */
export type Rental_Reservation_Status_On_Conflict = {
  constraint: Rental_Reservation_Status_Constraint;
  update_columns?: Array<Rental_Reservation_Status_Update_Column>;
  where?: Maybe<Rental_Reservation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_reservation_status". */
export type Rental_Reservation_Status_Order_By = {
  description?: Maybe<Order_By>;
  rental_reservations_aggregate?: Maybe<Rental_Reservations_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: rental_reservation_status */
export type Rental_Reservation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "rental_reservation_status" */
export enum Rental_Reservation_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rental_reservation_status" */
export type Rental_Reservation_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "rental_reservation_status" */
export type Rental_Reservation_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Reservation_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Reservation_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "rental_reservation_status" */
export enum Rental_Reservation_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Rental_Reservation_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rental_Reservation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Reservation_Status_Bool_Exp;
};

/** columns and relationships of "rental_reservations" */
export type Rental_Reservations = {
  __typename?: 'rental_reservations';
  acriss_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  pickup_actual?: Maybe<Scalars['timestamptz']>;
  pickup_location?: Maybe<Locations>;
  pickup_location_id?: Maybe<Scalars['uuid']>;
  pickup_requested: Scalars['timestamptz'];
  /** An array relationship */
  rental_drivers: Array<Rental_Drivers>;
  /** An aggregate relationship */
  rental_drivers_aggregate: Rental_Drivers_Aggregate;
  /** An object relationship */
  rental_reservation_status?: Maybe<Rental_Reservation_Status>;
  return_actual?: Maybe<Scalars['timestamptz']>;
  return_location?: Maybe<Locations>;
  return_location_id?: Maybe<Scalars['uuid']>;
  return_requested: Scalars['timestamptz'];
  showroom?: Maybe<Showrooms>;
  showroom_id: Scalars['uuid'];
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Rental_Reservation_Status_Enum>;
  updated_at: Scalars['timestamptz'];
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "rental_reservations" */
export type Rental_ReservationsPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "rental_reservations" */
export type Rental_ReservationsPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "rental_reservations" */
export type Rental_ReservationsRental_DriversArgs = {
  distinct_on?: Maybe<Array<Rental_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Drivers_Order_By>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};


/** columns and relationships of "rental_reservations" */
export type Rental_ReservationsRental_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Drivers_Order_By>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};

/** aggregated selection of "rental_reservations" */
export type Rental_Reservations_Aggregate = {
  __typename?: 'rental_reservations_aggregate';
  aggregate?: Maybe<Rental_Reservations_Aggregate_Fields>;
  nodes: Array<Rental_Reservations>;
};

export type Rental_Reservations_Aggregate_Bool_Exp = {
  count?: Maybe<Rental_Reservations_Aggregate_Bool_Exp_Count>;
};

export type Rental_Reservations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Rental_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Rental_Reservations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_reservations" */
export type Rental_Reservations_Aggregate_Fields = {
  __typename?: 'rental_reservations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rental_Reservations_Max_Fields>;
  min?: Maybe<Rental_Reservations_Min_Fields>;
};


/** aggregate fields of "rental_reservations" */
export type Rental_Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rental_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rental_reservations" */
export type Rental_Reservations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rental_Reservations_Max_Order_By>;
  min?: Maybe<Rental_Reservations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rental_reservations" */
export type Rental_Reservations_Arr_Rel_Insert_Input = {
  data: Array<Rental_Reservations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rental_Reservations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rental_reservations". All fields are combined with a logical 'AND'. */
export type Rental_Reservations_Bool_Exp = {
  _and?: Maybe<Array<Rental_Reservations_Bool_Exp>>;
  _not?: Maybe<Rental_Reservations_Bool_Exp>;
  _or?: Maybe<Array<Rental_Reservations_Bool_Exp>>;
  acriss_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pickup_actual?: Maybe<Timestamptz_Comparison_Exp>;
  pickup_location_id?: Maybe<Uuid_Comparison_Exp>;
  pickup_requested?: Maybe<Timestamptz_Comparison_Exp>;
  rental_drivers?: Maybe<Rental_Drivers_Bool_Exp>;
  rental_drivers_aggregate?: Maybe<Rental_Drivers_Aggregate_Bool_Exp>;
  rental_reservation_status?: Maybe<Rental_Reservation_Status_Bool_Exp>;
  return_actual?: Maybe<Timestamptz_Comparison_Exp>;
  return_location_id?: Maybe<Uuid_Comparison_Exp>;
  return_requested?: Maybe<Timestamptz_Comparison_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Rental_Reservation_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_reservations" */
export enum Rental_Reservations_Constraint {
  /** unique or primary key constraint on columns "source", "source_id", "showroom_id" */
  RentalReservationsSourceSourceIdShowroomIdKey = 'rental_reservations_source_source_id_showroom_id_key',
  /** unique or primary key constraint on columns "id" */
  ReservationsPkey = 'reservations_pkey'
}

/** input type for inserting data into table "rental_reservations" */
export type Rental_Reservations_Insert_Input = {
  acriss_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pickup_actual?: Maybe<Scalars['timestamptz']>;
  pickup_location_id?: Maybe<Scalars['uuid']>;
  pickup_requested?: Maybe<Scalars['timestamptz']>;
  rental_drivers?: Maybe<Rental_Drivers_Arr_Rel_Insert_Input>;
  rental_reservation_status?: Maybe<Rental_Reservation_Status_Obj_Rel_Insert_Input>;
  return_actual?: Maybe<Scalars['timestamptz']>;
  return_location_id?: Maybe<Scalars['uuid']>;
  return_requested?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Rental_Reservation_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Rental_Reservations_Max_Fields = {
  __typename?: 'rental_reservations_max_fields';
  acriss_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pickup_actual?: Maybe<Scalars['timestamptz']>;
  pickup_location_id?: Maybe<Scalars['uuid']>;
  pickup_requested?: Maybe<Scalars['timestamptz']>;
  return_actual?: Maybe<Scalars['timestamptz']>;
  return_location_id?: Maybe<Scalars['uuid']>;
  return_requested?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rental_reservations" */
export type Rental_Reservations_Max_Order_By = {
  acriss_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pickup_actual?: Maybe<Order_By>;
  pickup_location_id?: Maybe<Order_By>;
  pickup_requested?: Maybe<Order_By>;
  return_actual?: Maybe<Order_By>;
  return_location_id?: Maybe<Order_By>;
  return_requested?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Reservations_Min_Fields = {
  __typename?: 'rental_reservations_min_fields';
  acriss_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pickup_actual?: Maybe<Scalars['timestamptz']>;
  pickup_location_id?: Maybe<Scalars['uuid']>;
  pickup_requested?: Maybe<Scalars['timestamptz']>;
  return_actual?: Maybe<Scalars['timestamptz']>;
  return_location_id?: Maybe<Scalars['uuid']>;
  return_requested?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rental_reservations" */
export type Rental_Reservations_Min_Order_By = {
  acriss_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pickup_actual?: Maybe<Order_By>;
  pickup_location_id?: Maybe<Order_By>;
  pickup_requested?: Maybe<Order_By>;
  return_actual?: Maybe<Order_By>;
  return_location_id?: Maybe<Order_By>;
  return_requested?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "rental_reservations" */
export type Rental_Reservations_Mutation_Response = {
  __typename?: 'rental_reservations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Reservations>;
};

/** on_conflict condition type for table "rental_reservations" */
export type Rental_Reservations_On_Conflict = {
  constraint: Rental_Reservations_Constraint;
  update_columns?: Array<Rental_Reservations_Update_Column>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_reservations". */
export type Rental_Reservations_Order_By = {
  acriss_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pickup_actual?: Maybe<Order_By>;
  pickup_location_id?: Maybe<Order_By>;
  pickup_requested?: Maybe<Order_By>;
  rental_drivers_aggregate?: Maybe<Rental_Drivers_Aggregate_Order_By>;
  rental_reservation_status?: Maybe<Rental_Reservation_Status_Order_By>;
  return_actual?: Maybe<Order_By>;
  return_location_id?: Maybe<Order_By>;
  return_requested?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: rental_reservations */
export type Rental_Reservations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rental_reservations" */
export enum Rental_Reservations_Select_Column {
  /** column name */
  AcrissCode = 'acriss_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PickupActual = 'pickup_actual',
  /** column name */
  PickupLocationId = 'pickup_location_id',
  /** column name */
  PickupRequested = 'pickup_requested',
  /** column name */
  ReturnActual = 'return_actual',
  /** column name */
  ReturnLocationId = 'return_location_id',
  /** column name */
  ReturnRequested = 'return_requested',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "rental_reservations" */
export type Rental_Reservations_Set_Input = {
  acriss_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pickup_actual?: Maybe<Scalars['timestamptz']>;
  pickup_location_id?: Maybe<Scalars['uuid']>;
  pickup_requested?: Maybe<Scalars['timestamptz']>;
  return_actual?: Maybe<Scalars['timestamptz']>;
  return_location_id?: Maybe<Scalars['uuid']>;
  return_requested?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Rental_Reservation_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rental_reservations" */
export type Rental_Reservations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Reservations_Stream_Cursor_Value_Input = {
  acriss_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pickup_actual?: Maybe<Scalars['timestamptz']>;
  pickup_location_id?: Maybe<Scalars['uuid']>;
  pickup_requested?: Maybe<Scalars['timestamptz']>;
  return_actual?: Maybe<Scalars['timestamptz']>;
  return_location_id?: Maybe<Scalars['uuid']>;
  return_requested?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  status?: Maybe<Rental_Reservation_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "rental_reservations" */
export enum Rental_Reservations_Update_Column {
  /** column name */
  AcrissCode = 'acriss_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PickupActual = 'pickup_actual',
  /** column name */
  PickupLocationId = 'pickup_location_id',
  /** column name */
  PickupRequested = 'pickup_requested',
  /** column name */
  ReturnActual = 'return_actual',
  /** column name */
  ReturnLocationId = 'return_location_id',
  /** column name */
  ReturnRequested = 'return_requested',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Rental_Reservations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rental_Reservations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Reservations_Bool_Exp;
};

export type Search_Articles_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Known_Vehicles_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Rental_Reservations_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Stocked_Vehicles_Args = {
  just_published?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

/** columns and relationships of "service_booking_service_types" */
export type Service_Booking_Service_Types = {
  __typename?: 'service_booking_service_types';
  /** An object relationship */
  service_booking: Service_Bookings;
  service_booking_id: Scalars['uuid'];
  /** An object relationship */
  service_type: Service_Types;
  service_type_id: Scalars['uuid'];
};

/** aggregated selection of "service_booking_service_types" */
export type Service_Booking_Service_Types_Aggregate = {
  __typename?: 'service_booking_service_types_aggregate';
  aggregate?: Maybe<Service_Booking_Service_Types_Aggregate_Fields>;
  nodes: Array<Service_Booking_Service_Types>;
};

export type Service_Booking_Service_Types_Aggregate_Bool_Exp = {
  count?: Maybe<Service_Booking_Service_Types_Aggregate_Bool_Exp_Count>;
};

export type Service_Booking_Service_Types_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_booking_service_types" */
export type Service_Booking_Service_Types_Aggregate_Fields = {
  __typename?: 'service_booking_service_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Service_Booking_Service_Types_Max_Fields>;
  min?: Maybe<Service_Booking_Service_Types_Min_Fields>;
};


/** aggregate fields of "service_booking_service_types" */
export type Service_Booking_Service_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service_booking_service_types" */
export type Service_Booking_Service_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Service_Booking_Service_Types_Max_Order_By>;
  min?: Maybe<Service_Booking_Service_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "service_booking_service_types" */
export type Service_Booking_Service_Types_Arr_Rel_Insert_Input = {
  data: Array<Service_Booking_Service_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Service_Booking_Service_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "service_booking_service_types". All fields are combined with a logical 'AND'. */
export type Service_Booking_Service_Types_Bool_Exp = {
  _and?: Maybe<Array<Service_Booking_Service_Types_Bool_Exp>>;
  _not?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
  _or?: Maybe<Array<Service_Booking_Service_Types_Bool_Exp>>;
  service_booking?: Maybe<Service_Bookings_Bool_Exp>;
  service_booking_id?: Maybe<Uuid_Comparison_Exp>;
  service_type?: Maybe<Service_Types_Bool_Exp>;
  service_type_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_booking_service_types" */
export enum Service_Booking_Service_Types_Constraint {
  /** unique or primary key constraint on columns "service_booking_id", "service_type_id" */
  ServiceBookingServiceTypesPkey = 'service_booking_service_types_pkey'
}

/** input type for inserting data into table "service_booking_service_types" */
export type Service_Booking_Service_Types_Insert_Input = {
  service_booking?: Maybe<Service_Bookings_Obj_Rel_Insert_Input>;
  service_booking_id?: Maybe<Scalars['uuid']>;
  service_type?: Maybe<Service_Types_Obj_Rel_Insert_Input>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Service_Booking_Service_Types_Max_Fields = {
  __typename?: 'service_booking_service_types_max_fields';
  service_booking_id?: Maybe<Scalars['uuid']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "service_booking_service_types" */
export type Service_Booking_Service_Types_Max_Order_By = {
  service_booking_id?: Maybe<Order_By>;
  service_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Booking_Service_Types_Min_Fields = {
  __typename?: 'service_booking_service_types_min_fields';
  service_booking_id?: Maybe<Scalars['uuid']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "service_booking_service_types" */
export type Service_Booking_Service_Types_Min_Order_By = {
  service_booking_id?: Maybe<Order_By>;
  service_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "service_booking_service_types" */
export type Service_Booking_Service_Types_Mutation_Response = {
  __typename?: 'service_booking_service_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Booking_Service_Types>;
};

/** input type for inserting object relation for remote table "service_booking_service_types" */
export type Service_Booking_Service_Types_Obj_Rel_Insert_Input = {
  data: Service_Booking_Service_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Service_Booking_Service_Types_On_Conflict>;
};

/** on_conflict condition type for table "service_booking_service_types" */
export type Service_Booking_Service_Types_On_Conflict = {
  constraint: Service_Booking_Service_Types_Constraint;
  update_columns?: Array<Service_Booking_Service_Types_Update_Column>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "service_booking_service_types". */
export type Service_Booking_Service_Types_Order_By = {
  service_booking?: Maybe<Service_Bookings_Order_By>;
  service_booking_id?: Maybe<Order_By>;
  service_type?: Maybe<Service_Types_Order_By>;
  service_type_id?: Maybe<Order_By>;
};

/** primary key columns input for table: service_booking_service_types */
export type Service_Booking_Service_Types_Pk_Columns_Input = {
  service_booking_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};

/** select columns of table "service_booking_service_types" */
export enum Service_Booking_Service_Types_Select_Column {
  /** column name */
  ServiceBookingId = 'service_booking_id',
  /** column name */
  ServiceTypeId = 'service_type_id'
}

/** input type for updating data in table "service_booking_service_types" */
export type Service_Booking_Service_Types_Set_Input = {
  service_booking_id?: Maybe<Scalars['uuid']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "service_booking_service_types" */
export type Service_Booking_Service_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Booking_Service_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Booking_Service_Types_Stream_Cursor_Value_Input = {
  service_booking_id?: Maybe<Scalars['uuid']>;
  service_type_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "service_booking_service_types" */
export enum Service_Booking_Service_Types_Update_Column {
  /** column name */
  ServiceBookingId = 'service_booking_id',
  /** column name */
  ServiceTypeId = 'service_type_id'
}

export type Service_Booking_Service_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Booking_Service_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Booking_Service_Types_Bool_Exp;
};

/** columns and relationships of "service_bookings" */
export type Service_Bookings = {
  __typename?: 'service_bookings';
  comments?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  odometer?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Scalars['String']>;
  scheduled_at: Scalars['timestamptz'];
  /** An object relationship */
  service_booking_service_type?: Maybe<Service_Booking_Service_Types>;
  /** An array relationship */
  service_booking_service_types: Array<Service_Booking_Service_Types>;
  /** An aggregate relationship */
  service_booking_service_types_aggregate: Service_Booking_Service_Types_Aggregate;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  /** An object relationship */
  transport_type?: Maybe<Transport_Types>;
  transport_type_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "service_bookings" */
export type Service_BookingsNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "service_bookings" */
export type Service_BookingsNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "service_bookings" */
export type Service_BookingsService_Booking_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


/** columns and relationships of "service_bookings" */
export type Service_BookingsService_Booking_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};

/** aggregated selection of "service_bookings" */
export type Service_Bookings_Aggregate = {
  __typename?: 'service_bookings_aggregate';
  aggregate?: Maybe<Service_Bookings_Aggregate_Fields>;
  nodes: Array<Service_Bookings>;
};

export type Service_Bookings_Aggregate_Bool_Exp = {
  count?: Maybe<Service_Bookings_Aggregate_Bool_Exp_Count>;
};

export type Service_Bookings_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Service_Bookings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Service_Bookings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_bookings" */
export type Service_Bookings_Aggregate_Fields = {
  __typename?: 'service_bookings_aggregate_fields';
  avg?: Maybe<Service_Bookings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Bookings_Max_Fields>;
  min?: Maybe<Service_Bookings_Min_Fields>;
  stddev?: Maybe<Service_Bookings_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Bookings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Bookings_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Bookings_Sum_Fields>;
  var_pop?: Maybe<Service_Bookings_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Bookings_Var_Samp_Fields>;
  variance?: Maybe<Service_Bookings_Variance_Fields>;
};


/** aggregate fields of "service_bookings" */
export type Service_Bookings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Bookings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service_bookings" */
export type Service_Bookings_Aggregate_Order_By = {
  avg?: Maybe<Service_Bookings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Service_Bookings_Max_Order_By>;
  min?: Maybe<Service_Bookings_Min_Order_By>;
  stddev?: Maybe<Service_Bookings_Stddev_Order_By>;
  stddev_pop?: Maybe<Service_Bookings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Service_Bookings_Stddev_Samp_Order_By>;
  sum?: Maybe<Service_Bookings_Sum_Order_By>;
  var_pop?: Maybe<Service_Bookings_Var_Pop_Order_By>;
  var_samp?: Maybe<Service_Bookings_Var_Samp_Order_By>;
  variance?: Maybe<Service_Bookings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_bookings" */
export type Service_Bookings_Arr_Rel_Insert_Input = {
  data: Array<Service_Bookings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Service_Bookings_On_Conflict>;
};

/** aggregate avg on columns */
export type Service_Bookings_Avg_Fields = {
  __typename?: 'service_bookings_avg_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "service_bookings" */
export type Service_Bookings_Avg_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_bookings". All fields are combined with a logical 'AND'. */
export type Service_Bookings_Bool_Exp = {
  _and?: Maybe<Array<Service_Bookings_Bool_Exp>>;
  _not?: Maybe<Service_Bookings_Bool_Exp>;
  _or?: Maybe<Array<Service_Bookings_Bool_Exp>>;
  comments?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  notes_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  odometer?: Maybe<Numeric_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  registration_number?: Maybe<String_Comparison_Exp>;
  registration_state?: Maybe<String_Comparison_Exp>;
  scheduled_at?: Maybe<Timestamptz_Comparison_Exp>;
  service_booking_service_type?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
  service_booking_service_types?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
  service_booking_service_types_aggregate?: Maybe<Service_Booking_Service_Types_Aggregate_Bool_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  transport_type?: Maybe<Transport_Types_Bool_Exp>;
  transport_type_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_bookings" */
export enum Service_Bookings_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceBookingsPkey = 'service_bookings_pkey'
}

/** input type for incrementing numeric columns in table "service_bookings" */
export type Service_Bookings_Inc_Input = {
  odometer?: Maybe<Scalars['numeric']>;
  year?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "service_bookings" */
export type Service_Bookings_Insert_Input = {
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  odometer?: Maybe<Scalars['numeric']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  service_booking_service_type?: Maybe<Service_Booking_Service_Types_Obj_Rel_Insert_Input>;
  service_booking_service_types?: Maybe<Service_Booking_Service_Types_Arr_Rel_Insert_Input>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  transport_type?: Maybe<Transport_Types_Obj_Rel_Insert_Input>;
  transport_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Service_Bookings_Max_Fields = {
  __typename?: 'service_bookings_max_fields';
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  owner_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "service_bookings" */
export type Service_Bookings_Max_Order_By = {
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  transport_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Bookings_Min_Fields = {
  __typename?: 'service_bookings_min_fields';
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  owner_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "service_bookings" */
export type Service_Bookings_Min_Order_By = {
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  transport_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "service_bookings" */
export type Service_Bookings_Mutation_Response = {
  __typename?: 'service_bookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Bookings>;
};

/** input type for inserting object relation for remote table "service_bookings" */
export type Service_Bookings_Obj_Rel_Insert_Input = {
  data: Service_Bookings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Service_Bookings_On_Conflict>;
};

/** on_conflict condition type for table "service_bookings" */
export type Service_Bookings_On_Conflict = {
  constraint: Service_Bookings_Constraint;
  update_columns?: Array<Service_Bookings_Update_Column>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};

/** Ordering options when selecting data from "service_bookings". */
export type Service_Bookings_Order_By = {
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  odometer?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  registration_state?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  service_booking_service_type?: Maybe<Service_Booking_Service_Types_Order_By>;
  service_booking_service_types_aggregate?: Maybe<Service_Booking_Service_Types_Aggregate_Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  transport_type?: Maybe<Transport_Types_Order_By>;
  transport_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: service_bookings */
export type Service_Bookings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "service_bookings" */
export enum Service_Bookings_Select_Column {
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  TransportTypeId = 'transport_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Vin = 'vin',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "service_bookings" */
export type Service_Bookings_Set_Input = {
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  owner_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Service_Bookings_Stddev_Fields = {
  __typename?: 'service_bookings_stddev_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "service_bookings" */
export type Service_Bookings_Stddev_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Service_Bookings_Stddev_Pop_Fields = {
  __typename?: 'service_bookings_stddev_pop_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "service_bookings" */
export type Service_Bookings_Stddev_Pop_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Service_Bookings_Stddev_Samp_Fields = {
  __typename?: 'service_bookings_stddev_samp_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "service_bookings" */
export type Service_Bookings_Stddev_Samp_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Streaming cursor of the table "service_bookings" */
export type Service_Bookings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Bookings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Bookings_Stream_Cursor_Value_Input = {
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  owner_id?: Maybe<Scalars['uuid']>;
  registration_number?: Maybe<Scalars['String']>;
  registration_state?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  transport_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Service_Bookings_Sum_Fields = {
  __typename?: 'service_bookings_sum_fields';
  odometer?: Maybe<Scalars['numeric']>;
  year?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "service_bookings" */
export type Service_Bookings_Sum_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "service_bookings" */
export enum Service_Bookings_Update_Column {
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  RegistrationState = 'registration_state',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  TransportTypeId = 'transport_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Vin = 'vin',
  /** column name */
  Year = 'year'
}

export type Service_Bookings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Service_Bookings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Bookings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Bookings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Bookings_Var_Pop_Fields = {
  __typename?: 'service_bookings_var_pop_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "service_bookings" */
export type Service_Bookings_Var_Pop_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Service_Bookings_Var_Samp_Fields = {
  __typename?: 'service_bookings_var_samp_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "service_bookings" */
export type Service_Bookings_Var_Samp_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Service_Bookings_Variance_Fields = {
  __typename?: 'service_bookings_variance_fields';
  odometer?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "service_bookings" */
export type Service_Bookings_Variance_Order_By = {
  odometer?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "service_types" */
export type Service_Types = {
  __typename?: 'service_types';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  is_notes_mandatory: Scalars['Boolean'];
  /** An array relationship */
  location_service_types: Array<Location_Service_Types>;
  /** An aggregate relationship */
  location_service_types_aggregate: Location_Service_Types_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  service_booking_service_type?: Maybe<Service_Booking_Service_Types>;
  /** An array relationship */
  service_booking_service_types: Array<Service_Booking_Service_Types>;
  /** An aggregate relationship */
  service_booking_service_types_aggregate: Service_Booking_Service_Types_Aggregate;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "service_types" */
export type Service_TypesLocation_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


/** columns and relationships of "service_types" */
export type Service_TypesLocation_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


/** columns and relationships of "service_types" */
export type Service_TypesService_Booking_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


/** columns and relationships of "service_types" */
export type Service_TypesService_Booking_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};

/** aggregated selection of "service_types" */
export type Service_Types_Aggregate = {
  __typename?: 'service_types_aggregate';
  aggregate?: Maybe<Service_Types_Aggregate_Fields>;
  nodes: Array<Service_Types>;
};

/** aggregate fields of "service_types" */
export type Service_Types_Aggregate_Fields = {
  __typename?: 'service_types_aggregate_fields';
  avg?: Maybe<Service_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Types_Max_Fields>;
  min?: Maybe<Service_Types_Min_Fields>;
  stddev?: Maybe<Service_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Types_Sum_Fields>;
  var_pop?: Maybe<Service_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Types_Var_Samp_Fields>;
  variance?: Maybe<Service_Types_Variance_Fields>;
};


/** aggregate fields of "service_types" */
export type Service_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Types_Avg_Fields = {
  __typename?: 'service_types_avg_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "service_types". All fields are combined with a logical 'AND'. */
export type Service_Types_Bool_Exp = {
  _and?: Maybe<Array<Service_Types_Bool_Exp>>;
  _not?: Maybe<Service_Types_Bool_Exp>;
  _or?: Maybe<Array<Service_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  estimated_duration?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_notes_mandatory?: Maybe<Boolean_Comparison_Exp>;
  location_service_types?: Maybe<Location_Service_Types_Bool_Exp>;
  location_service_types_aggregate?: Maybe<Location_Service_Types_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  service_booking_service_type?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
  service_booking_service_types?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
  service_booking_service_types_aggregate?: Maybe<Service_Booking_Service_Types_Aggregate_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_types" */
export enum Service_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTypesPkey = 'service_types_pkey'
}

/** input type for incrementing numeric columns in table "service_types" */
export type Service_Types_Inc_Input = {
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "service_types" */
export type Service_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  is_notes_mandatory?: Maybe<Scalars['Boolean']>;
  location_service_types?: Maybe<Location_Service_Types_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  service_booking_service_type?: Maybe<Service_Booking_Service_Types_Obj_Rel_Insert_Input>;
  service_booking_service_types?: Maybe<Service_Booking_Service_Types_Arr_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Service_Types_Max_Fields = {
  __typename?: 'service_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Service_Types_Min_Fields = {
  __typename?: 'service_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "service_types" */
export type Service_Types_Mutation_Response = {
  __typename?: 'service_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Types>;
};

/** input type for inserting object relation for remote table "service_types" */
export type Service_Types_Obj_Rel_Insert_Input = {
  data: Service_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Service_Types_On_Conflict>;
};

/** on_conflict condition type for table "service_types" */
export type Service_Types_On_Conflict = {
  constraint: Service_Types_Constraint;
  update_columns?: Array<Service_Types_Update_Column>;
  where?: Maybe<Service_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "service_types". */
export type Service_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimated_duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_notes_mandatory?: Maybe<Order_By>;
  location_service_types_aggregate?: Maybe<Location_Service_Types_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  service_booking_service_type?: Maybe<Service_Booking_Service_Types_Order_By>;
  service_booking_service_types_aggregate?: Maybe<Service_Booking_Service_Types_Aggregate_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: service_types */
export type Service_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "service_types" */
export enum Service_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedDuration = 'estimated_duration',
  /** column name */
  Id = 'id',
  /** column name */
  IsNotesMandatory = 'is_notes_mandatory',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_types" */
export type Service_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  is_notes_mandatory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Service_Types_Stddev_Fields = {
  __typename?: 'service_types_stddev_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Types_Stddev_Pop_Fields = {
  __typename?: 'service_types_stddev_pop_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Types_Stddev_Samp_Fields = {
  __typename?: 'service_types_stddev_samp_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "service_types" */
export type Service_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Types_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  is_notes_mandatory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Service_Types_Sum_Fields = {
  __typename?: 'service_types_sum_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Int']>;
};

/** update columns of table "service_types" */
export enum Service_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedDuration = 'estimated_duration',
  /** column name */
  Id = 'id',
  /** column name */
  IsNotesMandatory = 'is_notes_mandatory',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Service_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Service_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Types_Var_Pop_Fields = {
  __typename?: 'service_types_var_pop_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Types_Var_Samp_Fields = {
  __typename?: 'service_types_var_samp_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Types_Variance_Fields = {
  __typename?: 'service_types_variance_fields';
  /** Estimated duration in minutes */
  estimated_duration?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sessions" */
export type Sessions = {
  __typename?: 'sessions';
  /** An object relationship */
  api_client?: Maybe<Api_Clients>;
  api_client_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  children: Array<Sessions>;
  /** An aggregate relationship */
  children_aggregate: Sessions_Aggregate;
  code?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  ip_address: Scalars['String'];
  last_refreshed_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  parent?: Maybe<Sessions>;
  parent_id?: Maybe<Scalars['uuid']>;
  requested_at: Scalars['timestamptz'];
  revoked_at?: Maybe<Scalars['timestamptz']>;
  /** user | api_client */
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  verify_before: Scalars['timestamptz'];
};


/** columns and relationships of "sessions" */
export type SessionsChildrenArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};

/** aggregated selection of "sessions" */
export type Sessions_Aggregate = {
  __typename?: 'sessions_aggregate';
  aggregate?: Maybe<Sessions_Aggregate_Fields>;
  nodes: Array<Sessions>;
};

export type Sessions_Aggregate_Bool_Exp = {
  count?: Maybe<Sessions_Aggregate_Bool_Exp_Count>;
};

export type Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sessions" */
export type Sessions_Aggregate_Fields = {
  __typename?: 'sessions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sessions_Max_Fields>;
  min?: Maybe<Sessions_Min_Fields>;
};


/** aggregate fields of "sessions" */
export type Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sessions" */
export type Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sessions_Max_Order_By>;
  min?: Maybe<Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sessions" */
export type Sessions_Arr_Rel_Insert_Input = {
  data: Array<Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sessions". All fields are combined with a logical 'AND'. */
export type Sessions_Bool_Exp = {
  _and?: Maybe<Array<Sessions_Bool_Exp>>;
  _not?: Maybe<Sessions_Bool_Exp>;
  _or?: Maybe<Array<Sessions_Bool_Exp>>;
  api_client?: Maybe<Api_Clients_Bool_Exp>;
  api_client_id?: Maybe<Uuid_Comparison_Exp>;
  children?: Maybe<Sessions_Bool_Exp>;
  children_aggregate?: Maybe<Sessions_Aggregate_Bool_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ip_address?: Maybe<String_Comparison_Exp>;
  last_refreshed_at?: Maybe<Timestamptz_Comparison_Exp>;
  parent?: Maybe<Sessions_Bool_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  requested_at?: Maybe<Timestamptz_Comparison_Exp>;
  revoked_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  verify_before?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sessions" */
export enum Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionsPkey = 'sessions_pkey'
}

/** input type for inserting data into table "sessions" */
export type Sessions_Insert_Input = {
  api_client?: Maybe<Api_Clients_Obj_Rel_Insert_Input>;
  api_client_id?: Maybe<Scalars['uuid']>;
  children?: Maybe<Sessions_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
  last_refreshed_at?: Maybe<Scalars['timestamptz']>;
  parent?: Maybe<Sessions_Obj_Rel_Insert_Input>;
  parent_id?: Maybe<Scalars['uuid']>;
  requested_at?: Maybe<Scalars['timestamptz']>;
  revoked_at?: Maybe<Scalars['timestamptz']>;
  /** user | api_client */
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  verify_before?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sessions_Max_Fields = {
  __typename?: 'sessions_max_fields';
  api_client_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
  last_refreshed_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  requested_at?: Maybe<Scalars['timestamptz']>;
  revoked_at?: Maybe<Scalars['timestamptz']>;
  /** user | api_client */
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  verify_before?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sessions" */
export type Sessions_Max_Order_By = {
  api_client_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip_address?: Maybe<Order_By>;
  last_refreshed_at?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  requested_at?: Maybe<Order_By>;
  revoked_at?: Maybe<Order_By>;
  /** user | api_client */
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  verified_at?: Maybe<Order_By>;
  verify_before?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sessions_Min_Fields = {
  __typename?: 'sessions_min_fields';
  api_client_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
  last_refreshed_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  requested_at?: Maybe<Scalars['timestamptz']>;
  revoked_at?: Maybe<Scalars['timestamptz']>;
  /** user | api_client */
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  verify_before?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sessions" */
export type Sessions_Min_Order_By = {
  api_client_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip_address?: Maybe<Order_By>;
  last_refreshed_at?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  requested_at?: Maybe<Order_By>;
  revoked_at?: Maybe<Order_By>;
  /** user | api_client */
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  verified_at?: Maybe<Order_By>;
  verify_before?: Maybe<Order_By>;
};

/** response of any mutation on the table "sessions" */
export type Sessions_Mutation_Response = {
  __typename?: 'sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sessions>;
};

/** input type for inserting object relation for remote table "sessions" */
export type Sessions_Obj_Rel_Insert_Input = {
  data: Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Sessions_On_Conflict>;
};

/** on_conflict condition type for table "sessions" */
export type Sessions_On_Conflict = {
  constraint: Sessions_Constraint;
  update_columns?: Array<Sessions_Update_Column>;
  where?: Maybe<Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "sessions". */
export type Sessions_Order_By = {
  api_client?: Maybe<Api_Clients_Order_By>;
  api_client_id?: Maybe<Order_By>;
  children_aggregate?: Maybe<Sessions_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ip_address?: Maybe<Order_By>;
  last_refreshed_at?: Maybe<Order_By>;
  parent?: Maybe<Sessions_Order_By>;
  parent_id?: Maybe<Order_By>;
  requested_at?: Maybe<Order_By>;
  revoked_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  verified_at?: Maybe<Order_By>;
  verify_before?: Maybe<Order_By>;
};

/** primary key columns input for table: sessions */
export type Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sessions" */
export enum Sessions_Select_Column {
  /** column name */
  ApiClientId = 'api_client_id',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  LastRefreshedAt = 'last_refreshed_at',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  RequestedAt = 'requested_at',
  /** column name */
  RevokedAt = 'revoked_at',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifyBefore = 'verify_before'
}

/** input type for updating data in table "sessions" */
export type Sessions_Set_Input = {
  api_client_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
  last_refreshed_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  requested_at?: Maybe<Scalars['timestamptz']>;
  revoked_at?: Maybe<Scalars['timestamptz']>;
  /** user | api_client */
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  verify_before?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "sessions" */
export type Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sessions_Stream_Cursor_Value_Input = {
  api_client_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
  last_refreshed_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  requested_at?: Maybe<Scalars['timestamptz']>;
  revoked_at?: Maybe<Scalars['timestamptz']>;
  /** user | api_client */
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
  verify_before?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "sessions" */
export enum Sessions_Update_Column {
  /** column name */
  ApiClientId = 'api_client_id',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  LastRefreshedAt = 'last_refreshed_at',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  RequestedAt = 'requested_at',
  /** column name */
  RevokedAt = 'revoked_at',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifyBefore = 'verify_before'
}

export type Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sessions_Bool_Exp;
};

/** columns and relationships of "showroom_apps" */
export type Showroom_Apps = {
  __typename?: 'showroom_apps';
  /** An object relationship */
  app: Apps;
  app_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  inputs: Array<Showroom_Apps_Inputs>;
  /** An aggregate relationship */
  inputs_aggregate: Showroom_Apps_Inputs_Aggregate;
  /** An object relationship */
  installed_version?: Maybe<App_Versions>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  version_num: Scalars['String'];
};


/** columns and relationships of "showroom_apps" */
export type Showroom_AppsInputsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


/** columns and relationships of "showroom_apps" */
export type Showroom_AppsInputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};

/** aggregated selection of "showroom_apps" */
export type Showroom_Apps_Aggregate = {
  __typename?: 'showroom_apps_aggregate';
  aggregate?: Maybe<Showroom_Apps_Aggregate_Fields>;
  nodes: Array<Showroom_Apps>;
};

export type Showroom_Apps_Aggregate_Bool_Exp = {
  count?: Maybe<Showroom_Apps_Aggregate_Bool_Exp_Count>;
};

export type Showroom_Apps_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showroom_Apps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showroom_Apps_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showroom_apps" */
export type Showroom_Apps_Aggregate_Fields = {
  __typename?: 'showroom_apps_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showroom_Apps_Max_Fields>;
  min?: Maybe<Showroom_Apps_Min_Fields>;
};


/** aggregate fields of "showroom_apps" */
export type Showroom_Apps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Apps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showroom_apps" */
export type Showroom_Apps_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showroom_Apps_Max_Order_By>;
  min?: Maybe<Showroom_Apps_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showroom_apps" */
export type Showroom_Apps_Arr_Rel_Insert_Input = {
  data: Array<Showroom_Apps_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Apps_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showroom_apps". All fields are combined with a logical 'AND'. */
export type Showroom_Apps_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Apps_Bool_Exp>>;
  _not?: Maybe<Showroom_Apps_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Apps_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  inputs?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
  inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Bool_Exp>;
  installed_version?: Maybe<App_Versions_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version_num?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "showroom_apps" */
export enum Showroom_Apps_Constraint {
  /** unique or primary key constraint on columns "app_id", "showroom_id" */
  ShowroomAppsPkey = 'showroom_apps_pkey'
}

/** columns and relationships of "showroom_apps_inputs" */
export type Showroom_Apps_Inputs = {
  __typename?: 'showroom_apps_inputs';
  /** An object relationship */
  app: Apps;
  app_id: Scalars['uuid'];
  /** An object relationship */
  app_input?: Maybe<App_Inputs>;
  created_at: Scalars['timestamptz'];
  name: Scalars['String'];
  /** An object relationship */
  showroom: Showrooms;
  /** An object relationship */
  showroom_app?: Maybe<Showroom_Apps>;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['jsonb']>;
  version_num: Scalars['String'];
};


/** columns and relationships of "showroom_apps_inputs" */
export type Showroom_Apps_InputsValueArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Aggregate = {
  __typename?: 'showroom_apps_inputs_aggregate';
  aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Fields>;
  nodes: Array<Showroom_Apps_Inputs>;
};

export type Showroom_Apps_Inputs_Aggregate_Bool_Exp = {
  count?: Maybe<Showroom_Apps_Inputs_Aggregate_Bool_Exp_Count>;
};

export type Showroom_Apps_Inputs_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Aggregate_Fields = {
  __typename?: 'showroom_apps_inputs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showroom_Apps_Inputs_Max_Fields>;
  min?: Maybe<Showroom_Apps_Inputs_Min_Fields>;
};


/** aggregate fields of "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showroom_Apps_Inputs_Max_Order_By>;
  min?: Maybe<Showroom_Apps_Inputs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Showroom_Apps_Inputs_Append_Input = {
  value?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Arr_Rel_Insert_Input = {
  data: Array<Showroom_Apps_Inputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Apps_Inputs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showroom_apps_inputs". All fields are combined with a logical 'AND'. */
export type Showroom_Apps_Inputs_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Apps_Inputs_Bool_Exp>>;
  _not?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Apps_Inputs_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  app_input?: Maybe<App_Inputs_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_app?: Maybe<Showroom_Apps_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Jsonb_Comparison_Exp>;
  version_num?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "showroom_apps_inputs" */
export enum Showroom_Apps_Inputs_Constraint {
  /** unique or primary key constraint on columns "app_id", "showroom_id", "name" */
  ShowroomAppsInputsPkey = 'showroom_apps_inputs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Showroom_Apps_Inputs_Delete_At_Path_Input = {
  value?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Showroom_Apps_Inputs_Delete_Elem_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Showroom_Apps_Inputs_Delete_Key_Input = {
  value?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Insert_Input = {
  app?: Maybe<Apps_Obj_Rel_Insert_Input>;
  app_id?: Maybe<Scalars['uuid']>;
  app_input?: Maybe<App_Inputs_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_app?: Maybe<Showroom_Apps_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['jsonb']>;
  version_num?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Showroom_Apps_Inputs_Max_Fields = {
  __typename?: 'showroom_apps_inputs_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showroom_Apps_Inputs_Min_Fields = {
  __typename?: 'showroom_apps_inputs_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** response of any mutation on the table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Mutation_Response = {
  __typename?: 'showroom_apps_inputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Apps_Inputs>;
};

/** on_conflict condition type for table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_On_Conflict = {
  constraint: Showroom_Apps_Inputs_Constraint;
  update_columns?: Array<Showroom_Apps_Inputs_Update_Column>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};

/** Ordering options when selecting data from "showroom_apps_inputs". */
export type Showroom_Apps_Inputs_Order_By = {
  app?: Maybe<Apps_Order_By>;
  app_id?: Maybe<Order_By>;
  app_input?: Maybe<App_Inputs_Order_By>;
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_app?: Maybe<Showroom_Apps_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** primary key columns input for table: showroom_apps_inputs */
export type Showroom_Apps_Inputs_Pk_Columns_Input = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Showroom_Apps_Inputs_Prepend_Input = {
  value?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "showroom_apps_inputs" */
export enum Showroom_Apps_Inputs_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VersionNum = 'version_num'
}

/** input type for updating data in table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['jsonb']>;
  version_num?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "showroom_apps_inputs" */
export type Showroom_Apps_Inputs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showroom_Apps_Inputs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showroom_Apps_Inputs_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['jsonb']>;
  version_num?: Maybe<Scalars['String']>;
};

/** update columns of table "showroom_apps_inputs" */
export enum Showroom_Apps_Inputs_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VersionNum = 'version_num'
}

export type Showroom_Apps_Inputs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Showroom_Apps_Inputs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Showroom_Apps_Inputs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Showroom_Apps_Inputs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Showroom_Apps_Inputs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Showroom_Apps_Inputs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showroom_Apps_Inputs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showroom_Apps_Inputs_Bool_Exp;
};

/** input type for inserting data into table "showroom_apps" */
export type Showroom_Apps_Insert_Input = {
  app?: Maybe<Apps_Obj_Rel_Insert_Input>;
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  inputs?: Maybe<Showroom_Apps_Inputs_Arr_Rel_Insert_Input>;
  installed_version?: Maybe<App_Versions_Obj_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Showroom_Apps_Max_Fields = {
  __typename?: 'showroom_apps_max_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "showroom_apps" */
export type Showroom_Apps_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showroom_Apps_Min_Fields = {
  __typename?: 'showroom_apps_min_fields';
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "showroom_apps" */
export type Showroom_Apps_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** response of any mutation on the table "showroom_apps" */
export type Showroom_Apps_Mutation_Response = {
  __typename?: 'showroom_apps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Apps>;
};

/** input type for inserting object relation for remote table "showroom_apps" */
export type Showroom_Apps_Obj_Rel_Insert_Input = {
  data: Showroom_Apps_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Apps_On_Conflict>;
};

/** on_conflict condition type for table "showroom_apps" */
export type Showroom_Apps_On_Conflict = {
  constraint: Showroom_Apps_Constraint;
  update_columns?: Array<Showroom_Apps_Update_Column>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "showroom_apps". */
export type Showroom_Apps_Order_By = {
  app?: Maybe<Apps_Order_By>;
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Order_By>;
  installed_version?: Maybe<App_Versions_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version_num?: Maybe<Order_By>;
};

/** primary key columns input for table: showroom_apps */
export type Showroom_Apps_Pk_Columns_Input = {
  app_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};

/** select columns of table "showroom_apps" */
export enum Showroom_Apps_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionNum = 'version_num'
}

/** input type for updating data in table "showroom_apps" */
export type Showroom_Apps_Set_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "showroom_apps" */
export type Showroom_Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showroom_Apps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showroom_Apps_Stream_Cursor_Value_Input = {
  app_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version_num?: Maybe<Scalars['String']>;
};

/** update columns of table "showroom_apps" */
export enum Showroom_Apps_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionNum = 'version_num'
}

export type Showroom_Apps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showroom_Apps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showroom_Apps_Bool_Exp;
};

/** columns and relationships of "showroom_locales" */
export type Showroom_Locales = {
  __typename?: 'showroom_locales';
  /** An object relationship */
  locale: Locales;
  locale_code: Scalars['String'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
};

/** aggregated selection of "showroom_locales" */
export type Showroom_Locales_Aggregate = {
  __typename?: 'showroom_locales_aggregate';
  aggregate?: Maybe<Showroom_Locales_Aggregate_Fields>;
  nodes: Array<Showroom_Locales>;
};

export type Showroom_Locales_Aggregate_Bool_Exp = {
  count?: Maybe<Showroom_Locales_Aggregate_Bool_Exp_Count>;
};

export type Showroom_Locales_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showroom_Locales_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showroom_Locales_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showroom_locales" */
export type Showroom_Locales_Aggregate_Fields = {
  __typename?: 'showroom_locales_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showroom_Locales_Max_Fields>;
  min?: Maybe<Showroom_Locales_Min_Fields>;
};


/** aggregate fields of "showroom_locales" */
export type Showroom_Locales_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Locales_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showroom_locales" */
export type Showroom_Locales_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showroom_Locales_Max_Order_By>;
  min?: Maybe<Showroom_Locales_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showroom_locales" */
export type Showroom_Locales_Arr_Rel_Insert_Input = {
  data: Array<Showroom_Locales_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Locales_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showroom_locales". All fields are combined with a logical 'AND'. */
export type Showroom_Locales_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Locales_Bool_Exp>>;
  _not?: Maybe<Showroom_Locales_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Locales_Bool_Exp>>;
  locale?: Maybe<Locales_Bool_Exp>;
  locale_code?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "showroom_locales" */
export enum Showroom_Locales_Constraint {
  /** unique or primary key constraint on columns "locale_code", "showroom_id" */
  ShowroomLocalesPkey = 'showroom_locales_pkey'
}

/** input type for inserting data into table "showroom_locales" */
export type Showroom_Locales_Insert_Input = {
  locale?: Maybe<Locales_Obj_Rel_Insert_Input>;
  locale_code?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Showroom_Locales_Max_Fields = {
  __typename?: 'showroom_locales_max_fields';
  locale_code?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "showroom_locales" */
export type Showroom_Locales_Max_Order_By = {
  locale_code?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showroom_Locales_Min_Fields = {
  __typename?: 'showroom_locales_min_fields';
  locale_code?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "showroom_locales" */
export type Showroom_Locales_Min_Order_By = {
  locale_code?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "showroom_locales" */
export type Showroom_Locales_Mutation_Response = {
  __typename?: 'showroom_locales_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Locales>;
};

/** on_conflict condition type for table "showroom_locales" */
export type Showroom_Locales_On_Conflict = {
  constraint: Showroom_Locales_Constraint;
  update_columns?: Array<Showroom_Locales_Update_Column>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};

/** Ordering options when selecting data from "showroom_locales". */
export type Showroom_Locales_Order_By = {
  locale?: Maybe<Locales_Order_By>;
  locale_code?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** primary key columns input for table: showroom_locales */
export type Showroom_Locales_Pk_Columns_Input = {
  locale_code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};

/** select columns of table "showroom_locales" */
export enum Showroom_Locales_Select_Column {
  /** column name */
  LocaleCode = 'locale_code',
  /** column name */
  ShowroomId = 'showroom_id'
}

/** input type for updating data in table "showroom_locales" */
export type Showroom_Locales_Set_Input = {
  locale_code?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "showroom_locales" */
export type Showroom_Locales_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showroom_Locales_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showroom_Locales_Stream_Cursor_Value_Input = {
  locale_code?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "showroom_locales" */
export enum Showroom_Locales_Update_Column {
  /** column name */
  LocaleCode = 'locale_code',
  /** column name */
  ShowroomId = 'showroom_id'
}

export type Showroom_Locales_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showroom_Locales_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showroom_Locales_Bool_Exp;
};

/** columns and relationships of "showroom_pricing_codes" */
export type Showroom_Pricing_Codes = {
  __typename?: 'showroom_pricing_codes';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  pricing_code: Pricing_Codes;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Aggregate = {
  __typename?: 'showroom_pricing_codes_aggregate';
  aggregate?: Maybe<Showroom_Pricing_Codes_Aggregate_Fields>;
  nodes: Array<Showroom_Pricing_Codes>;
};

export type Showroom_Pricing_Codes_Aggregate_Bool_Exp = {
  count?: Maybe<Showroom_Pricing_Codes_Aggregate_Bool_Exp_Count>;
};

export type Showroom_Pricing_Codes_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Aggregate_Fields = {
  __typename?: 'showroom_pricing_codes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showroom_Pricing_Codes_Max_Fields>;
  min?: Maybe<Showroom_Pricing_Codes_Min_Fields>;
};


/** aggregate fields of "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showroom_Pricing_Codes_Max_Order_By>;
  min?: Maybe<Showroom_Pricing_Codes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Arr_Rel_Insert_Input = {
  data: Array<Showroom_Pricing_Codes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Pricing_Codes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showroom_pricing_codes". All fields are combined with a logical 'AND'. */
export type Showroom_Pricing_Codes_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Pricing_Codes_Bool_Exp>>;
  _not?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Pricing_Codes_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  pricing_code?: Maybe<Pricing_Codes_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "showroom_pricing_codes" */
export enum Showroom_Pricing_Codes_Constraint {
  /** unique or primary key constraint on columns "showroom_id", "code" */
  ShowroomPricingCodesPkey = 'showroom_pricing_codes_pkey'
}

/** input type for inserting data into table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  pricing_code?: Maybe<Pricing_Codes_Obj_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Showroom_Pricing_Codes_Max_Fields = {
  __typename?: 'showroom_pricing_codes_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Max_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showroom_Pricing_Codes_Min_Fields = {
  __typename?: 'showroom_pricing_codes_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Min_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Mutation_Response = {
  __typename?: 'showroom_pricing_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Pricing_Codes>;
};

/** on_conflict condition type for table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_On_Conflict = {
  constraint: Showroom_Pricing_Codes_Constraint;
  update_columns?: Array<Showroom_Pricing_Codes_Update_Column>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "showroom_pricing_codes". */
export type Showroom_Pricing_Codes_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  pricing_code?: Maybe<Pricing_Codes_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: showroom_pricing_codes */
export type Showroom_Pricing_Codes_Pk_Columns_Input = {
  code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};

/** select columns of table "showroom_pricing_codes" */
export enum Showroom_Pricing_Codes_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "showroom_pricing_codes" */
export type Showroom_Pricing_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showroom_Pricing_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showroom_Pricing_Codes_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "showroom_pricing_codes" */
export enum Showroom_Pricing_Codes_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Showroom_Pricing_Codes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showroom_Pricing_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showroom_Pricing_Codes_Bool_Exp;
};

/** columns and relationships of "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles = {
  __typename?: 'showroom_stocked_vehicles';
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  /** An object relationship */
  stocked_vehicle: Stocked_Vehicles;
  stocked_vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Aggregate = {
  __typename?: 'showroom_stocked_vehicles_aggregate';
  aggregate?: Maybe<Showroom_Stocked_Vehicles_Aggregate_Fields>;
  nodes: Array<Showroom_Stocked_Vehicles>;
};

export type Showroom_Stocked_Vehicles_Aggregate_Bool_Exp = {
  count?: Maybe<Showroom_Stocked_Vehicles_Aggregate_Bool_Exp_Count>;
};

export type Showroom_Stocked_Vehicles_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Aggregate_Fields = {
  __typename?: 'showroom_stocked_vehicles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showroom_Stocked_Vehicles_Max_Fields>;
  min?: Maybe<Showroom_Stocked_Vehicles_Min_Fields>;
};


/** aggregate fields of "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showroom_Stocked_Vehicles_Max_Order_By>;
  min?: Maybe<Showroom_Stocked_Vehicles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Arr_Rel_Insert_Input = {
  data: Array<Showroom_Stocked_Vehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Stocked_Vehicles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showroom_stocked_vehicles". All fields are combined with a logical 'AND'. */
export type Showroom_Stocked_Vehicles_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Stocked_Vehicles_Bool_Exp>>;
  _not?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Stocked_Vehicles_Bool_Exp>>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "showroom_stocked_vehicles" */
export enum Showroom_Stocked_Vehicles_Constraint {
  /** unique or primary key constraint on columns "stocked_vehicle_id", "showroom_id" */
  ShowroomStockedVehiclesPkey = 'showroom_stocked_vehicles_pkey'
}

/** input type for inserting data into table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Insert_Input = {
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Showroom_Stocked_Vehicles_Max_Fields = {
  __typename?: 'showroom_stocked_vehicles_max_fields';
  showroom_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Max_Order_By = {
  showroom_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showroom_Stocked_Vehicles_Min_Fields = {
  __typename?: 'showroom_stocked_vehicles_min_fields';
  showroom_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Min_Order_By = {
  showroom_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Mutation_Response = {
  __typename?: 'showroom_stocked_vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Stocked_Vehicles>;
};

/** on_conflict condition type for table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_On_Conflict = {
  constraint: Showroom_Stocked_Vehicles_Constraint;
  update_columns?: Array<Showroom_Stocked_Vehicles_Update_Column>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "showroom_stocked_vehicles". */
export type Showroom_Stocked_Vehicles_Order_By = {
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: showroom_stocked_vehicles */
export type Showroom_Stocked_Vehicles_Pk_Columns_Input = {
  showroom_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};

/** select columns of table "showroom_stocked_vehicles" */
export enum Showroom_Stocked_Vehicles_Select_Column {
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id'
}

/** input type for updating data in table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Set_Input = {
  showroom_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "showroom_stocked_vehicles" */
export type Showroom_Stocked_Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showroom_Stocked_Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showroom_Stocked_Vehicles_Stream_Cursor_Value_Input = {
  showroom_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "showroom_stocked_vehicles" */
export enum Showroom_Stocked_Vehicles_Update_Column {
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id'
}

export type Showroom_Stocked_Vehicles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showroom_Stocked_Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showroom_Stocked_Vehicles_Bool_Exp;
};

/** columns and relationships of "showroom_users" */
export type Showroom_Users = {
  __typename?: 'showroom_users';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "showroom_users" */
export type Showroom_Users_Aggregate = {
  __typename?: 'showroom_users_aggregate';
  aggregate?: Maybe<Showroom_Users_Aggregate_Fields>;
  nodes: Array<Showroom_Users>;
};

export type Showroom_Users_Aggregate_Bool_Exp = {
  count?: Maybe<Showroom_Users_Aggregate_Bool_Exp_Count>;
};

export type Showroom_Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showroom_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showroom_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showroom_users" */
export type Showroom_Users_Aggregate_Fields = {
  __typename?: 'showroom_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showroom_Users_Max_Fields>;
  min?: Maybe<Showroom_Users_Min_Fields>;
};


/** aggregate fields of "showroom_users" */
export type Showroom_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showroom_users" */
export type Showroom_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showroom_Users_Max_Order_By>;
  min?: Maybe<Showroom_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showroom_users" */
export type Showroom_Users_Arr_Rel_Insert_Input = {
  data: Array<Showroom_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showroom_users". All fields are combined with a logical 'AND'. */
export type Showroom_Users_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Users_Bool_Exp>>;
  _not?: Maybe<Showroom_Users_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Users_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "showroom_users" */
export enum Showroom_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "showroom_id" */
  ShowroomUsersPkey = 'showroom_users_pkey'
}

/** input type for inserting data into table "showroom_users" */
export type Showroom_Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Showroom_Users_Max_Fields = {
  __typename?: 'showroom_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "showroom_users" */
export type Showroom_Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showroom_Users_Min_Fields = {
  __typename?: 'showroom_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "showroom_users" */
export type Showroom_Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "showroom_users" */
export type Showroom_Users_Mutation_Response = {
  __typename?: 'showroom_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Users>;
};

/** on_conflict condition type for table "showroom_users" */
export type Showroom_Users_On_Conflict = {
  constraint: Showroom_Users_Constraint;
  update_columns?: Array<Showroom_Users_Update_Column>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "showroom_users". */
export type Showroom_Users_Order_By = {
  created_at?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: showroom_users */
export type Showroom_Users_Pk_Columns_Input = {
  showroom_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "showroom_users" */
export enum Showroom_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "showroom_users" */
export type Showroom_Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "showroom_users" */
export type Showroom_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showroom_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showroom_Users_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "showroom_users" */
export enum Showroom_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Showroom_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showroom_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showroom_Users_Bool_Exp;
};

/** columns and relationships of "showrooms" */
export type Showrooms = {
  __typename?: 'showrooms';
  address?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articles_aggregate: Articles_Aggregate;
  /** An array relationship */
  auctions: Array<Auctions>;
  /** An aggregate relationship */
  auctions_aggregate: Auctions_Aggregate;
  /** An array relationship */
  calculator_submissions: Array<Calculator_Submission>;
  /** An aggregate relationship */
  calculator_submissions_aggregate: Calculator_Submission_Aggregate;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  custom_fields: Array<Custom_Fields>;
  /** An aggregate relationship */
  custom_fields_aggregate: Custom_Fields_Aggregate;
  /** An array relationship */
  custom_vehicles: Array<Custom_Vehicles>;
  /** An aggregate relationship */
  custom_vehicles_aggregate: Custom_Vehicles_Aggregate;
  default_locale?: Maybe<Scalars['String']>;
  default_pricing_code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  finance_applications: Array<Finance_Applications>;
  /** An aggregate relationship */
  finance_applications_aggregate: Finance_Applications_Aggregate;
  /** An array relationship */
  finance_products: Array<Finance_Products>;
  /** An aggregate relationship */
  finance_products_aggregate: Finance_Products_Aggregate;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  https_enabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  insights_visitors: Array<Insights_Visitors>;
  insights_visitors_aggregate: Insights_Visitors_Aggregate;
  /** An array relationship */
  interests: Array<Interests>;
  /** An aggregate relationship */
  interests_aggregate: Interests_Aggregate;
  /** An object relationship */
  locale?: Maybe<Locales>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  login_methods: Scalars['jsonb'];
  name: Scalars['String'];
  /** An array relationship */
  pages: Array<Pages>;
  /** An aggregate relationship */
  pages_aggregate: Pages_Aggregate;
  phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  pricing_code?: Maybe<Pricing_Codes>;
  private_settings: Scalars['jsonb'];
  /** An array relationship */
  product_groups: Array<Product_Groups>;
  /** An aggregate relationship */
  product_groups_aggregate: Product_Groups_Aggregate;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  public_settings: Scalars['jsonb'];
  /** An array relationship */
  rental_reservations: Array<Rental_Reservations>;
  /** An aggregate relationship */
  rental_reservations_aggregate: Rental_Reservations_Aggregate;
  /** An array relationship */
  showroom_apps: Array<Showroom_Apps>;
  /** An aggregate relationship */
  showroom_apps_aggregate: Showroom_Apps_Aggregate;
  /** An array relationship */
  showroom_apps_inputs: Array<Showroom_Apps_Inputs>;
  /** An aggregate relationship */
  showroom_apps_inputs_aggregate: Showroom_Apps_Inputs_Aggregate;
  /** An array relationship */
  showroom_locales: Array<Showroom_Locales>;
  /** An aggregate relationship */
  showroom_locales_aggregate: Showroom_Locales_Aggregate;
  /** An array relationship */
  showroom_partner: Array<Showrooms_Partners>;
  /** An aggregate relationship */
  showroom_partner_aggregate: Showrooms_Partners_Aggregate;
  /** An array relationship */
  showroom_pricing_codes: Array<Showroom_Pricing_Codes>;
  /** An aggregate relationship */
  showroom_pricing_codes_aggregate: Showroom_Pricing_Codes_Aggregate;
  /** An array relationship */
  showroom_promotion: Array<Showrooms_Promotions>;
  /** An aggregate relationship */
  showroom_promotion_aggregate: Showrooms_Promotions_Aggregate;
  /** An array relationship */
  showroom_stocked_vehicles: Array<Showroom_Stocked_Vehicles>;
  /** An aggregate relationship */
  showroom_stocked_vehicles_aggregate: Showroom_Stocked_Vehicles_Aggregate;
  /** An array relationship */
  showroom_users: Array<Showroom_Users>;
  /** An aggregate relationship */
  showroom_users_aggregate: Showroom_Users_Aggregate;
  /** An array relationship */
  test_drives: Array<Test_Drives>;
  /** An aggregate relationship */
  test_drives_aggregate: Test_Drives_Aggregate;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone: Scalars['String'];
  /** An array relationship */
  trade_in_plans: Array<Trade_In_Plans>;
  /** An aggregate relationship */
  trade_in_plans_aggregate: Trade_In_Plans_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  vehicle_lists: Array<Vehicle_Lists>;
  /** An aggregate relationship */
  vehicle_lists_aggregate: Vehicle_Lists_Aggregate;
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  verified_sending_email?: Maybe<Scalars['String']>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsAddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsArticlesArgs = {
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsArticles_AggregateArgs = {
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsAuctionsArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsAuctions_AggregateArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsCalculator_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsCalculator_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsCustom_FieldsArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsCustom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsCustom_VehiclesArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsCustom_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsFinance_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsFinance_Applications_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsFinance_ProductsArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsFinance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsFormsArgs = {
  distinct_on?: Maybe<Array<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsForms_AggregateArgs = {
  distinct_on?: Maybe<Array<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsInsights_VisitorsArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsInsights_Visitors_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsInterestsArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsLogin_MethodsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsPagesArgs = {
  distinct_on?: Maybe<Array<Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pages_Order_By>>;
  where?: Maybe<Pages_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsPages_AggregateArgs = {
  distinct_on?: Maybe<Array<Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pages_Order_By>>;
  where?: Maybe<Pages_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsPrivate_SettingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsProduct_GroupsArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsProduct_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsPublic_SettingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsRental_ReservationsArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsRental_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_AppsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Apps_InputsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Apps_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_LocalesArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Locales_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_PartnerArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Partner_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Pricing_CodesArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Pricing_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_PromotionArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Promotion_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Stocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Stocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_UsersArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsShowroom_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsTest_DrivesArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsTest_Drives_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsTrade_In_PlansArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsTrade_In_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsVehicle_ListsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Lists_Order_By>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsVehicle_Lists_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Lists_Order_By>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "showrooms" */
export type ShowroomsVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};

/** aggregated selection of "showrooms" */
export type Showrooms_Aggregate = {
  __typename?: 'showrooms_aggregate';
  aggregate?: Maybe<Showrooms_Aggregate_Fields>;
  nodes: Array<Showrooms>;
};

export type Showrooms_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Showrooms_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Showrooms_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Showrooms_Aggregate_Bool_Exp_Count>;
};

export type Showrooms_Aggregate_Bool_Exp_Bool_And = {
  arguments: Showrooms_Select_Column_Showrooms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showrooms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Showrooms_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Showrooms_Select_Column_Showrooms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showrooms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Showrooms_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showrooms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showrooms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showrooms" */
export type Showrooms_Aggregate_Fields = {
  __typename?: 'showrooms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showrooms_Max_Fields>;
  min?: Maybe<Showrooms_Min_Fields>;
};


/** aggregate fields of "showrooms" */
export type Showrooms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showrooms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showrooms" */
export type Showrooms_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showrooms_Max_Order_By>;
  min?: Maybe<Showrooms_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Showrooms_Append_Input = {
  address?: Maybe<Scalars['jsonb']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  private_settings?: Maybe<Scalars['jsonb']>;
  public_settings?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "showrooms" */
export type Showrooms_Arr_Rel_Insert_Input = {
  data: Array<Showrooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showrooms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showrooms". All fields are combined with a logical 'AND'. */
export type Showrooms_Bool_Exp = {
  _and?: Maybe<Array<Showrooms_Bool_Exp>>;
  _not?: Maybe<Showrooms_Bool_Exp>;
  _or?: Maybe<Array<Showrooms_Bool_Exp>>;
  address?: Maybe<Jsonb_Comparison_Exp>;
  articles?: Maybe<Articles_Bool_Exp>;
  articles_aggregate?: Maybe<Articles_Aggregate_Bool_Exp>;
  auctions?: Maybe<Auctions_Bool_Exp>;
  auctions_aggregate?: Maybe<Auctions_Aggregate_Bool_Exp>;
  calculator_submissions?: Maybe<Calculator_Submission_Bool_Exp>;
  calculator_submissions_aggregate?: Maybe<Calculator_Submission_Aggregate_Bool_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_fields?: Maybe<Custom_Fields_Bool_Exp>;
  custom_fields_aggregate?: Maybe<Custom_Fields_Aggregate_Bool_Exp>;
  custom_vehicles?: Maybe<Custom_Vehicles_Bool_Exp>;
  custom_vehicles_aggregate?: Maybe<Custom_Vehicles_Aggregate_Bool_Exp>;
  default_locale?: Maybe<String_Comparison_Exp>;
  default_pricing_code?: Maybe<String_Comparison_Exp>;
  domain?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  finance_applications?: Maybe<Finance_Applications_Bool_Exp>;
  finance_applications_aggregate?: Maybe<Finance_Applications_Aggregate_Bool_Exp>;
  finance_products?: Maybe<Finance_Products_Bool_Exp>;
  finance_products_aggregate?: Maybe<Finance_Products_Aggregate_Bool_Exp>;
  forms?: Maybe<Forms_Bool_Exp>;
  forms_aggregate?: Maybe<Forms_Aggregate_Bool_Exp>;
  https_enabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interests?: Maybe<Interests_Bool_Exp>;
  interests_aggregate?: Maybe<Interests_Aggregate_Bool_Exp>;
  locale?: Maybe<Locales_Bool_Exp>;
  locations?: Maybe<Locations_Bool_Exp>;
  locations_aggregate?: Maybe<Locations_Aggregate_Bool_Exp>;
  login_methods?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pages?: Maybe<Pages_Bool_Exp>;
  pages_aggregate?: Maybe<Pages_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  pricing_code?: Maybe<Pricing_Codes_Bool_Exp>;
  private_settings?: Maybe<Jsonb_Comparison_Exp>;
  product_groups?: Maybe<Product_Groups_Bool_Exp>;
  product_groups_aggregate?: Maybe<Product_Groups_Aggregate_Bool_Exp>;
  products?: Maybe<Products_Bool_Exp>;
  products_aggregate?: Maybe<Products_Aggregate_Bool_Exp>;
  public_settings?: Maybe<Jsonb_Comparison_Exp>;
  showroom_apps?: Maybe<Showroom_Apps_Bool_Exp>;
  showroom_apps_aggregate?: Maybe<Showroom_Apps_Aggregate_Bool_Exp>;
  showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
  showroom_apps_inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Bool_Exp>;
  showroom_locales?: Maybe<Showroom_Locales_Bool_Exp>;
  showroom_locales_aggregate?: Maybe<Showroom_Locales_Aggregate_Bool_Exp>;
  showroom_partner?: Maybe<Showrooms_Partners_Bool_Exp>;
  showroom_partner_aggregate?: Maybe<Showrooms_Partners_Aggregate_Bool_Exp>;
  showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
  showroom_pricing_codes_aggregate?: Maybe<Showroom_Pricing_Codes_Aggregate_Bool_Exp>;
  showroom_promotion?: Maybe<Showrooms_Promotions_Bool_Exp>;
  showroom_promotion_aggregate?: Maybe<Showrooms_Promotions_Aggregate_Bool_Exp>;
  showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
  showroom_stocked_vehicles_aggregate?: Maybe<Showroom_Stocked_Vehicles_Aggregate_Bool_Exp>;
  showroom_users?: Maybe<Showroom_Users_Bool_Exp>;
  showroom_users_aggregate?: Maybe<Showroom_Users_Aggregate_Bool_Exp>;
  test_drives?: Maybe<Test_Drives_Bool_Exp>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Bool_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  trade_in_plans?: Maybe<Trade_In_Plans_Bool_Exp>;
  trade_in_plans_aggregate?: Maybe<Trade_In_Plans_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_lists?: Maybe<Vehicle_Lists_Bool_Exp>;
  vehicle_lists_aggregate?: Maybe<Vehicle_Lists_Aggregate_Bool_Exp>;
  vehicle_orders?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
  verified_sending_email?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "showrooms" */
export enum Showrooms_Constraint {
  /** unique or primary key constraint on columns "code" */
  ShowroomsCodeKey = 'showrooms_code_key',
  /** unique or primary key constraint on columns "domain" */
  ShowroomsDomainKey = 'showrooms_domain_key',
  /** unique or primary key constraint on columns "id" */
  ShowroomsPkey = 'showrooms_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Showrooms_Delete_At_Path_Input = {
  address?: Maybe<Array<Scalars['String']>>;
  login_methods?: Maybe<Array<Scalars['String']>>;
  private_settings?: Maybe<Array<Scalars['String']>>;
  public_settings?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Showrooms_Delete_Elem_Input = {
  address?: Maybe<Scalars['Int']>;
  login_methods?: Maybe<Scalars['Int']>;
  private_settings?: Maybe<Scalars['Int']>;
  public_settings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Showrooms_Delete_Key_Input = {
  address?: Maybe<Scalars['String']>;
  login_methods?: Maybe<Scalars['String']>;
  private_settings?: Maybe<Scalars['String']>;
  public_settings?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "showrooms" */
export type Showrooms_Insert_Input = {
  address?: Maybe<Scalars['jsonb']>;
  articles?: Maybe<Articles_Arr_Rel_Insert_Input>;
  auctions?: Maybe<Auctions_Arr_Rel_Insert_Input>;
  calculator_submissions?: Maybe<Calculator_Submission_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_fields?: Maybe<Custom_Fields_Arr_Rel_Insert_Input>;
  custom_vehicles?: Maybe<Custom_Vehicles_Arr_Rel_Insert_Input>;
  default_locale?: Maybe<Scalars['String']>;
  default_pricing_code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  finance_applications?: Maybe<Finance_Applications_Arr_Rel_Insert_Input>;
  finance_products?: Maybe<Finance_Products_Arr_Rel_Insert_Input>;
  forms?: Maybe<Forms_Arr_Rel_Insert_Input>;
  https_enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  interests?: Maybe<Interests_Arr_Rel_Insert_Input>;
  locale?: Maybe<Locales_Obj_Rel_Insert_Input>;
  locations?: Maybe<Locations_Arr_Rel_Insert_Input>;
  login_methods?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  pages?: Maybe<Pages_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  pricing_code?: Maybe<Pricing_Codes_Obj_Rel_Insert_Input>;
  private_settings?: Maybe<Scalars['jsonb']>;
  product_groups?: Maybe<Product_Groups_Arr_Rel_Insert_Input>;
  products?: Maybe<Products_Arr_Rel_Insert_Input>;
  public_settings?: Maybe<Scalars['jsonb']>;
  showroom_apps?: Maybe<Showroom_Apps_Arr_Rel_Insert_Input>;
  showroom_apps_inputs?: Maybe<Showroom_Apps_Inputs_Arr_Rel_Insert_Input>;
  showroom_locales?: Maybe<Showroom_Locales_Arr_Rel_Insert_Input>;
  showroom_partner?: Maybe<Showrooms_Partners_Arr_Rel_Insert_Input>;
  showroom_pricing_codes?: Maybe<Showroom_Pricing_Codes_Arr_Rel_Insert_Input>;
  showroom_promotion?: Maybe<Showrooms_Promotions_Arr_Rel_Insert_Input>;
  showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Arr_Rel_Insert_Input>;
  showroom_users?: Maybe<Showroom_Users_Arr_Rel_Insert_Input>;
  test_drives?: Maybe<Test_Drives_Arr_Rel_Insert_Input>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  trade_in_plans?: Maybe<Trade_In_Plans_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_lists?: Maybe<Vehicle_Lists_Arr_Rel_Insert_Input>;
  vehicle_orders?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
  verified_sending_email?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Showrooms_Max_Fields = {
  __typename?: 'showrooms_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_locale?: Maybe<Scalars['String']>;
  default_pricing_code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified_sending_email?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "showrooms" */
export type Showrooms_Max_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  default_locale?: Maybe<Order_By>;
  default_pricing_code?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verified_sending_email?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showrooms_Min_Fields = {
  __typename?: 'showrooms_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_locale?: Maybe<Scalars['String']>;
  default_pricing_code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified_sending_email?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "showrooms" */
export type Showrooms_Min_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  default_locale?: Maybe<Order_By>;
  default_pricing_code?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verified_sending_email?: Maybe<Order_By>;
};

/** response of any mutation on the table "showrooms" */
export type Showrooms_Mutation_Response = {
  __typename?: 'showrooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showrooms>;
};

/** input type for inserting object relation for remote table "showrooms" */
export type Showrooms_Obj_Rel_Insert_Input = {
  data: Showrooms_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Showrooms_On_Conflict>;
};

/** on_conflict condition type for table "showrooms" */
export type Showrooms_On_Conflict = {
  constraint: Showrooms_Constraint;
  update_columns?: Array<Showrooms_Update_Column>;
  where?: Maybe<Showrooms_Bool_Exp>;
};

/** Ordering options when selecting data from "showrooms". */
export type Showrooms_Order_By = {
  address?: Maybe<Order_By>;
  articles_aggregate?: Maybe<Articles_Aggregate_Order_By>;
  auctions_aggregate?: Maybe<Auctions_Aggregate_Order_By>;
  calculator_submissions_aggregate?: Maybe<Calculator_Submission_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_fields_aggregate?: Maybe<Custom_Fields_Aggregate_Order_By>;
  custom_vehicles_aggregate?: Maybe<Custom_Vehicles_Aggregate_Order_By>;
  default_locale?: Maybe<Order_By>;
  default_pricing_code?: Maybe<Order_By>;
  domain?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  finance_applications_aggregate?: Maybe<Finance_Applications_Aggregate_Order_By>;
  finance_products_aggregate?: Maybe<Finance_Products_Aggregate_Order_By>;
  forms_aggregate?: Maybe<Forms_Aggregate_Order_By>;
  https_enabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interests_aggregate?: Maybe<Interests_Aggregate_Order_By>;
  locale?: Maybe<Locales_Order_By>;
  locations_aggregate?: Maybe<Locations_Aggregate_Order_By>;
  login_methods?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pages_aggregate?: Maybe<Pages_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  pricing_code?: Maybe<Pricing_Codes_Order_By>;
  private_settings?: Maybe<Order_By>;
  product_groups_aggregate?: Maybe<Product_Groups_Aggregate_Order_By>;
  products_aggregate?: Maybe<Products_Aggregate_Order_By>;
  public_settings?: Maybe<Order_By>;
  showroom_apps_aggregate?: Maybe<Showroom_Apps_Aggregate_Order_By>;
  showroom_apps_inputs_aggregate?: Maybe<Showroom_Apps_Inputs_Aggregate_Order_By>;
  showroom_locales_aggregate?: Maybe<Showroom_Locales_Aggregate_Order_By>;
  showroom_partner_aggregate?: Maybe<Showrooms_Partners_Aggregate_Order_By>;
  showroom_pricing_codes_aggregate?: Maybe<Showroom_Pricing_Codes_Aggregate_Order_By>;
  showroom_promotion_aggregate?: Maybe<Showrooms_Promotions_Aggregate_Order_By>;
  showroom_stocked_vehicles_aggregate?: Maybe<Showroom_Stocked_Vehicles_Aggregate_Order_By>;
  showroom_users_aggregate?: Maybe<Showroom_Users_Aggregate_Order_By>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Order_By>;
  timezone?: Maybe<Order_By>;
  trade_in_plans_aggregate?: Maybe<Trade_In_Plans_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_lists_aggregate?: Maybe<Vehicle_Lists_Aggregate_Order_By>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
  verified_sending_email?: Maybe<Order_By>;
};

/** columns and relationships of "showrooms_partners" */
export type Showrooms_Partners = {
  __typename?: 'showrooms_partners';
  /** An object relationship */
  partner: Partners;
  partner_id: Scalars['uuid'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
};

/** aggregated selection of "showrooms_partners" */
export type Showrooms_Partners_Aggregate = {
  __typename?: 'showrooms_partners_aggregate';
  aggregate?: Maybe<Showrooms_Partners_Aggregate_Fields>;
  nodes: Array<Showrooms_Partners>;
};

export type Showrooms_Partners_Aggregate_Bool_Exp = {
  count?: Maybe<Showrooms_Partners_Aggregate_Bool_Exp_Count>;
};

export type Showrooms_Partners_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showrooms_Partners_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showrooms_partners" */
export type Showrooms_Partners_Aggregate_Fields = {
  __typename?: 'showrooms_partners_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showrooms_Partners_Max_Fields>;
  min?: Maybe<Showrooms_Partners_Min_Fields>;
};


/** aggregate fields of "showrooms_partners" */
export type Showrooms_Partners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showrooms_partners" */
export type Showrooms_Partners_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showrooms_Partners_Max_Order_By>;
  min?: Maybe<Showrooms_Partners_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showrooms_partners" */
export type Showrooms_Partners_Arr_Rel_Insert_Input = {
  data: Array<Showrooms_Partners_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showrooms_Partners_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showrooms_partners". All fields are combined with a logical 'AND'. */
export type Showrooms_Partners_Bool_Exp = {
  _and?: Maybe<Array<Showrooms_Partners_Bool_Exp>>;
  _not?: Maybe<Showrooms_Partners_Bool_Exp>;
  _or?: Maybe<Array<Showrooms_Partners_Bool_Exp>>;
  partner?: Maybe<Partners_Bool_Exp>;
  partner_id?: Maybe<Uuid_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "showrooms_partners" */
export enum Showrooms_Partners_Constraint {
  /** unique or primary key constraint on columns "partner_id", "showroom_id" */
  ShowroomsPartnersPkey = 'showrooms_partners_pkey'
}

/** input type for inserting data into table "showrooms_partners" */
export type Showrooms_Partners_Insert_Input = {
  partner?: Maybe<Partners_Obj_Rel_Insert_Input>;
  partner_id?: Maybe<Scalars['uuid']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Showrooms_Partners_Max_Fields = {
  __typename?: 'showrooms_partners_max_fields';
  partner_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "showrooms_partners" */
export type Showrooms_Partners_Max_Order_By = {
  partner_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showrooms_Partners_Min_Fields = {
  __typename?: 'showrooms_partners_min_fields';
  partner_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "showrooms_partners" */
export type Showrooms_Partners_Min_Order_By = {
  partner_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "showrooms_partners" */
export type Showrooms_Partners_Mutation_Response = {
  __typename?: 'showrooms_partners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showrooms_Partners>;
};

/** on_conflict condition type for table "showrooms_partners" */
export type Showrooms_Partners_On_Conflict = {
  constraint: Showrooms_Partners_Constraint;
  update_columns?: Array<Showrooms_Partners_Update_Column>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};

/** Ordering options when selecting data from "showrooms_partners". */
export type Showrooms_Partners_Order_By = {
  partner?: Maybe<Partners_Order_By>;
  partner_id?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
};

/** primary key columns input for table: showrooms_partners */
export type Showrooms_Partners_Pk_Columns_Input = {
  partner_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};

/** select columns of table "showrooms_partners" */
export enum Showrooms_Partners_Select_Column {
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  ShowroomId = 'showroom_id'
}

/** input type for updating data in table "showrooms_partners" */
export type Showrooms_Partners_Set_Input = {
  partner_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "showrooms_partners" */
export type Showrooms_Partners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showrooms_Partners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showrooms_Partners_Stream_Cursor_Value_Input = {
  partner_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "showrooms_partners" */
export enum Showrooms_Partners_Update_Column {
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  ShowroomId = 'showroom_id'
}

export type Showrooms_Partners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showrooms_Partners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showrooms_Partners_Bool_Exp;
};

/** primary key columns input for table: showrooms */
export type Showrooms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Showrooms_Prepend_Input = {
  address?: Maybe<Scalars['jsonb']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  private_settings?: Maybe<Scalars['jsonb']>;
  public_settings?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "showrooms_promotions" */
export type Showrooms_Promotions = {
  __typename?: 'showrooms_promotions';
  end_date: Scalars['timestamptz'];
  /** An object relationship */
  promotion: Promotions;
  promotion_id: Scalars['uuid'];
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  start_date: Scalars['timestamptz'];
};

/** aggregated selection of "showrooms_promotions" */
export type Showrooms_Promotions_Aggregate = {
  __typename?: 'showrooms_promotions_aggregate';
  aggregate?: Maybe<Showrooms_Promotions_Aggregate_Fields>;
  nodes: Array<Showrooms_Promotions>;
};

export type Showrooms_Promotions_Aggregate_Bool_Exp = {
  count?: Maybe<Showrooms_Promotions_Aggregate_Bool_Exp_Count>;
};

export type Showrooms_Promotions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Showrooms_Promotions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showrooms_promotions" */
export type Showrooms_Promotions_Aggregate_Fields = {
  __typename?: 'showrooms_promotions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Showrooms_Promotions_Max_Fields>;
  min?: Maybe<Showrooms_Promotions_Min_Fields>;
};


/** aggregate fields of "showrooms_promotions" */
export type Showrooms_Promotions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showrooms_promotions" */
export type Showrooms_Promotions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Showrooms_Promotions_Max_Order_By>;
  min?: Maybe<Showrooms_Promotions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "showrooms_promotions" */
export type Showrooms_Promotions_Arr_Rel_Insert_Input = {
  data: Array<Showrooms_Promotions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Showrooms_Promotions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "showrooms_promotions". All fields are combined with a logical 'AND'. */
export type Showrooms_Promotions_Bool_Exp = {
  _and?: Maybe<Array<Showrooms_Promotions_Bool_Exp>>;
  _not?: Maybe<Showrooms_Promotions_Bool_Exp>;
  _or?: Maybe<Array<Showrooms_Promotions_Bool_Exp>>;
  end_date?: Maybe<Timestamptz_Comparison_Exp>;
  promotion?: Maybe<Promotions_Bool_Exp>;
  promotion_id?: Maybe<Uuid_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  start_date?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "showrooms_promotions" */
export enum Showrooms_Promotions_Constraint {
  /** unique or primary key constraint on columns "promotion_id", "showroom_id" */
  ShowroomsPromotionsPkey = 'showrooms_promotions_pkey'
}

/** input type for inserting data into table "showrooms_promotions" */
export type Showrooms_Promotions_Insert_Input = {
  end_date?: Maybe<Scalars['timestamptz']>;
  promotion?: Maybe<Promotions_Obj_Rel_Insert_Input>;
  promotion_id?: Maybe<Scalars['uuid']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Showrooms_Promotions_Max_Fields = {
  __typename?: 'showrooms_promotions_max_fields';
  end_date?: Maybe<Scalars['timestamptz']>;
  promotion_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "showrooms_promotions" */
export type Showrooms_Promotions_Max_Order_By = {
  end_date?: Maybe<Order_By>;
  promotion_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Showrooms_Promotions_Min_Fields = {
  __typename?: 'showrooms_promotions_min_fields';
  end_date?: Maybe<Scalars['timestamptz']>;
  promotion_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "showrooms_promotions" */
export type Showrooms_Promotions_Min_Order_By = {
  end_date?: Maybe<Order_By>;
  promotion_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
};

/** response of any mutation on the table "showrooms_promotions" */
export type Showrooms_Promotions_Mutation_Response = {
  __typename?: 'showrooms_promotions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showrooms_Promotions>;
};

/** on_conflict condition type for table "showrooms_promotions" */
export type Showrooms_Promotions_On_Conflict = {
  constraint: Showrooms_Promotions_Constraint;
  update_columns?: Array<Showrooms_Promotions_Update_Column>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};

/** Ordering options when selecting data from "showrooms_promotions". */
export type Showrooms_Promotions_Order_By = {
  end_date?: Maybe<Order_By>;
  promotion?: Maybe<Promotions_Order_By>;
  promotion_id?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
};

/** primary key columns input for table: showrooms_promotions */
export type Showrooms_Promotions_Pk_Columns_Input = {
  promotion_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};

/** select columns of table "showrooms_promotions" */
export enum Showrooms_Promotions_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  PromotionId = 'promotion_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "showrooms_promotions" */
export type Showrooms_Promotions_Set_Input = {
  end_date?: Maybe<Scalars['timestamptz']>;
  promotion_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "showrooms_promotions" */
export type Showrooms_Promotions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showrooms_Promotions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showrooms_Promotions_Stream_Cursor_Value_Input = {
  end_date?: Maybe<Scalars['timestamptz']>;
  promotion_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "showrooms_promotions" */
export enum Showrooms_Promotions_Update_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  PromotionId = 'promotion_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  StartDate = 'start_date'
}

export type Showrooms_Promotions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showrooms_Promotions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showrooms_Promotions_Bool_Exp;
};

/** select columns of table "showrooms" */
export enum Showrooms_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultLocale = 'default_locale',
  /** column name */
  DefaultPricingCode = 'default_pricing_code',
  /** column name */
  Domain = 'domain',
  /** column name */
  Email = 'email',
  /** column name */
  HttpsEnabled = 'https_enabled',
  /** column name */
  Id = 'id',
  /** column name */
  LoginMethods = 'login_methods',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  PrivateSettings = 'private_settings',
  /** column name */
  PublicSettings = 'public_settings',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedSendingEmail = 'verified_sending_email'
}

/** select "showrooms_aggregate_bool_exp_bool_and_arguments_columns" columns of table "showrooms" */
export enum Showrooms_Select_Column_Showrooms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HttpsEnabled = 'https_enabled'
}

/** select "showrooms_aggregate_bool_exp_bool_or_arguments_columns" columns of table "showrooms" */
export enum Showrooms_Select_Column_Showrooms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HttpsEnabled = 'https_enabled'
}

/** input type for updating data in table "showrooms" */
export type Showrooms_Set_Input = {
  address?: Maybe<Scalars['jsonb']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_locale?: Maybe<Scalars['String']>;
  default_pricing_code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  https_enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  private_settings?: Maybe<Scalars['jsonb']>;
  public_settings?: Maybe<Scalars['jsonb']>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified_sending_email?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "showrooms" */
export type Showrooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Showrooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showrooms_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['jsonb']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_locale?: Maybe<Scalars['String']>;
  default_pricing_code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  https_enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  private_settings?: Maybe<Scalars['jsonb']>;
  public_settings?: Maybe<Scalars['jsonb']>;
  /** A timezone name form the IANA Database. See: https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified_sending_email?: Maybe<Scalars['String']>;
};

/** update columns of table "showrooms" */
export enum Showrooms_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultLocale = 'default_locale',
  /** column name */
  DefaultPricingCode = 'default_pricing_code',
  /** column name */
  Domain = 'domain',
  /** column name */
  Email = 'email',
  /** column name */
  HttpsEnabled = 'https_enabled',
  /** column name */
  Id = 'id',
  /** column name */
  LoginMethods = 'login_methods',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  PrivateSettings = 'private_settings',
  /** column name */
  PublicSettings = 'public_settings',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedSendingEmail = 'verified_sending_email'
}

export type Showrooms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Showrooms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Showrooms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Showrooms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Showrooms_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Showrooms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Showrooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Showrooms_Bool_Exp;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** columns and relationships of "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products = {
  __typename?: 'stocked_vehicle_finance_products';
  /** An object relationship */
  finance_product: Finance_Products;
  finance_product_id: Scalars['uuid'];
  /** An object relationship */
  stocked_vehicle: Stocked_Vehicles;
  stocked_vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Aggregate = {
  __typename?: 'stocked_vehicle_finance_products_aggregate';
  aggregate?: Maybe<Stocked_Vehicle_Finance_Products_Aggregate_Fields>;
  nodes: Array<Stocked_Vehicle_Finance_Products>;
};

export type Stocked_Vehicle_Finance_Products_Aggregate_Bool_Exp = {
  count?: Maybe<Stocked_Vehicle_Finance_Products_Aggregate_Bool_Exp_Count>;
};

export type Stocked_Vehicle_Finance_Products_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Aggregate_Fields = {
  __typename?: 'stocked_vehicle_finance_products_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stocked_Vehicle_Finance_Products_Max_Fields>;
  min?: Maybe<Stocked_Vehicle_Finance_Products_Min_Fields>;
};


/** aggregate fields of "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Stocked_Vehicle_Finance_Products_Max_Order_By>;
  min?: Maybe<Stocked_Vehicle_Finance_Products_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Arr_Rel_Insert_Input = {
  data: Array<Stocked_Vehicle_Finance_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stocked_Vehicle_Finance_Products_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stocked_vehicle_finance_products". All fields are combined with a logical 'AND'. */
export type Stocked_Vehicle_Finance_Products_Bool_Exp = {
  _and?: Maybe<Array<Stocked_Vehicle_Finance_Products_Bool_Exp>>;
  _not?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
  _or?: Maybe<Array<Stocked_Vehicle_Finance_Products_Bool_Exp>>;
  finance_product?: Maybe<Finance_Products_Bool_Exp>;
  finance_product_id?: Maybe<Uuid_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "stocked_vehicle_finance_products" */
export enum Stocked_Vehicle_Finance_Products_Constraint {
  /** unique or primary key constraint on columns "stocked_vehicle_id", "finance_product_id" */
  StockedVehicleFinanceProductsPkey = 'stocked_vehicle_finance_products_pkey'
}

/** input type for inserting data into table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Insert_Input = {
  finance_product?: Maybe<Finance_Products_Obj_Rel_Insert_Input>;
  finance_product_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Stocked_Vehicle_Finance_Products_Max_Fields = {
  __typename?: 'stocked_vehicle_finance_products_max_fields';
  finance_product_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Max_Order_By = {
  finance_product_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stocked_Vehicle_Finance_Products_Min_Fields = {
  __typename?: 'stocked_vehicle_finance_products_min_fields';
  finance_product_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Min_Order_By = {
  finance_product_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Mutation_Response = {
  __typename?: 'stocked_vehicle_finance_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocked_Vehicle_Finance_Products>;
};

/** on_conflict condition type for table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_On_Conflict = {
  constraint: Stocked_Vehicle_Finance_Products_Constraint;
  update_columns?: Array<Stocked_Vehicle_Finance_Products_Update_Column>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "stocked_vehicle_finance_products". */
export type Stocked_Vehicle_Finance_Products_Order_By = {
  finance_product?: Maybe<Finance_Products_Order_By>;
  finance_product_id?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stocked_vehicle_finance_products */
export type Stocked_Vehicle_Finance_Products_Pk_Columns_Input = {
  finance_product_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};

/** select columns of table "stocked_vehicle_finance_products" */
export enum Stocked_Vehicle_Finance_Products_Select_Column {
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id'
}

/** input type for updating data in table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Set_Input = {
  finance_product_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "stocked_vehicle_finance_products" */
export type Stocked_Vehicle_Finance_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stocked_Vehicle_Finance_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stocked_Vehicle_Finance_Products_Stream_Cursor_Value_Input = {
  finance_product_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "stocked_vehicle_finance_products" */
export enum Stocked_Vehicle_Finance_Products_Update_Column {
  /** column name */
  FinanceProductId = 'finance_product_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id'
}

export type Stocked_Vehicle_Finance_Products_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stocked_Vehicle_Finance_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stocked_Vehicle_Finance_Products_Bool_Exp;
};

/** columns and relationships of "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations = {
  __typename?: 'stocked_vehicle_handover_locations';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  /** An object relationship */
  stocked_vehicle?: Maybe<Stocked_Vehicles>;
  stocked_vehicle_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Aggregate = {
  __typename?: 'stocked_vehicle_handover_locations_aggregate';
  aggregate?: Maybe<Stocked_Vehicle_Handover_Locations_Aggregate_Fields>;
  nodes: Array<Stocked_Vehicle_Handover_Locations>;
};

export type Stocked_Vehicle_Handover_Locations_Aggregate_Bool_Exp = {
  count?: Maybe<Stocked_Vehicle_Handover_Locations_Aggregate_Bool_Exp_Count>;
};

export type Stocked_Vehicle_Handover_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Aggregate_Fields = {
  __typename?: 'stocked_vehicle_handover_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stocked_Vehicle_Handover_Locations_Max_Fields>;
  min?: Maybe<Stocked_Vehicle_Handover_Locations_Min_Fields>;
};


/** aggregate fields of "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Stocked_Vehicle_Handover_Locations_Max_Order_By>;
  min?: Maybe<Stocked_Vehicle_Handover_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Arr_Rel_Insert_Input = {
  data: Array<Stocked_Vehicle_Handover_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stocked_Vehicle_Handover_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stocked_vehicle_handover_locations". All fields are combined with a logical 'AND'. */
export type Stocked_Vehicle_Handover_Locations_Bool_Exp = {
  _and?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Bool_Exp>>;
  _not?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
  _or?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stocked_vehicle_handover_locations" */
export enum Stocked_Vehicle_Handover_Locations_Constraint {
  /** unique or primary key constraint on columns "stocked_vehicle_id", "location_id" */
  StockedVehicleHandoverLocationsPkey = 'stocked_vehicle_handover_locations_pkey'
}

/** input type for inserting data into table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stocked_Vehicle_Handover_Locations_Max_Fields = {
  __typename?: 'stocked_vehicle_handover_locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stocked_Vehicle_Handover_Locations_Min_Fields = {
  __typename?: 'stocked_vehicle_handover_locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Mutation_Response = {
  __typename?: 'stocked_vehicle_handover_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocked_Vehicle_Handover_Locations>;
};

/** on_conflict condition type for table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_On_Conflict = {
  constraint: Stocked_Vehicle_Handover_Locations_Constraint;
  update_columns?: Array<Stocked_Vehicle_Handover_Locations_Update_Column>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "stocked_vehicle_handover_locations". */
export type Stocked_Vehicle_Handover_Locations_Order_By = {
  created_at?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: stocked_vehicle_handover_locations */
export type Stocked_Vehicle_Handover_Locations_Pk_Columns_Input = {
  location_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};

/** select columns of table "stocked_vehicle_handover_locations" */
export enum Stocked_Vehicle_Handover_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "stocked_vehicle_handover_locations" */
export type Stocked_Vehicle_Handover_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stocked_Vehicle_Handover_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stocked_Vehicle_Handover_Locations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "stocked_vehicle_handover_locations" */
export enum Stocked_Vehicle_Handover_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Stocked_Vehicle_Handover_Locations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stocked_Vehicle_Handover_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stocked_Vehicle_Handover_Locations_Bool_Exp;
};

/** columns and relationships of "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers = {
  __typename?: 'stocked_vehicle_offers';
  /** An object relationship */
  offer?: Maybe<Offers>;
  offer_id: Scalars['uuid'];
  /** An object relationship */
  stocked_vehicle: Stocked_Vehicles;
  stocked_vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Aggregate = {
  __typename?: 'stocked_vehicle_offers_aggregate';
  aggregate?: Maybe<Stocked_Vehicle_Offers_Aggregate_Fields>;
  nodes: Array<Stocked_Vehicle_Offers>;
};

export type Stocked_Vehicle_Offers_Aggregate_Bool_Exp = {
  count?: Maybe<Stocked_Vehicle_Offers_Aggregate_Bool_Exp_Count>;
};

export type Stocked_Vehicle_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Aggregate_Fields = {
  __typename?: 'stocked_vehicle_offers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stocked_Vehicle_Offers_Max_Fields>;
  min?: Maybe<Stocked_Vehicle_Offers_Min_Fields>;
};


/** aggregate fields of "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Stocked_Vehicle_Offers_Max_Order_By>;
  min?: Maybe<Stocked_Vehicle_Offers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Arr_Rel_Insert_Input = {
  data: Array<Stocked_Vehicle_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stocked_Vehicle_Offers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stocked_vehicle_offers". All fields are combined with a logical 'AND'. */
export type Stocked_Vehicle_Offers_Bool_Exp = {
  _and?: Maybe<Array<Stocked_Vehicle_Offers_Bool_Exp>>;
  _not?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
  _or?: Maybe<Array<Stocked_Vehicle_Offers_Bool_Exp>>;
  offer?: Maybe<Offers_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "stocked_vehicle_offers" */
export enum Stocked_Vehicle_Offers_Constraint {
  /** unique or primary key constraint on columns "stocked_vehicle_id", "offer_id" */
  StockedVehicleOffersPkey = 'stocked_vehicle_offers_pkey'
}

/** input type for inserting data into table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Insert_Input = {
  offer?: Maybe<Offers_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Stocked_Vehicle_Offers_Max_Fields = {
  __typename?: 'stocked_vehicle_offers_max_fields';
  offer_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Max_Order_By = {
  offer_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stocked_Vehicle_Offers_Min_Fields = {
  __typename?: 'stocked_vehicle_offers_min_fields';
  offer_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Min_Order_By = {
  offer_id?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Mutation_Response = {
  __typename?: 'stocked_vehicle_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocked_Vehicle_Offers>;
};

/** on_conflict condition type for table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_On_Conflict = {
  constraint: Stocked_Vehicle_Offers_Constraint;
  update_columns?: Array<Stocked_Vehicle_Offers_Update_Column>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "stocked_vehicle_offers". */
export type Stocked_Vehicle_Offers_Order_By = {
  offer?: Maybe<Offers_Order_By>;
  offer_id?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stocked_vehicle_offers */
export type Stocked_Vehicle_Offers_Pk_Columns_Input = {
  offer_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};

/** select columns of table "stocked_vehicle_offers" */
export enum Stocked_Vehicle_Offers_Select_Column {
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id'
}

/** input type for updating data in table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Set_Input = {
  offer_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "stocked_vehicle_offers" */
export type Stocked_Vehicle_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stocked_Vehicle_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stocked_Vehicle_Offers_Stream_Cursor_Value_Input = {
  offer_id?: Maybe<Scalars['uuid']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "stocked_vehicle_offers" */
export enum Stocked_Vehicle_Offers_Update_Column {
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id'
}

export type Stocked_Vehicle_Offers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stocked_Vehicle_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stocked_Vehicle_Offers_Bool_Exp;
};

/** columns and relationships of "stocked_vehicles" */
export type Stocked_Vehicles = {
  __typename?: 'stocked_vehicles';
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  /** An array relationship */
  auctions: Array<Auctions>;
  /** An aggregate relationship */
  auctions_aggregate: Auctions_Aggregate;
  available_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  badge_code?: Maybe<Scalars['String']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_type?: Maybe<Scalars['String']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  body_type?: Maybe<Scalars['String']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  build_date?: Maybe<Scalars['date']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  code: Scalars['String'];
  colour?: Maybe<Scalars['String']>;
  colour_code?: Maybe<Scalars['String']>;
  compliance_date?: Maybe<Scalars['date']>;
  /** new | used | demo */
  condition: Vehicle_Conditions_Enum;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  cost_of_goods_paid_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  deposit_amount_paid_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  drive_type?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['numeric']>;
  engine_details?: Maybe<Scalars['String']>;
  engine_number?: Maybe<Scalars['String']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  exclude_auto_refresh_assets: Scalars['Boolean'];
  external_cta_description?: Maybe<Scalars['String']>;
  external_cta_label?: Maybe<Scalars['String']>;
  external_cta_url?: Maybe<Scalars['String']>;
  factory_order_no?: Maybe<Scalars['String']>;
  featured_order?: Maybe<Scalars['Int']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features: Scalars['jsonb'];
  features_version: Scalars['String'];
  /** Deprecated. Use stocked_vehicles.assets instead. */
  files?: Maybe<Scalars['jsonb']>;
  fuel_type?: Maybe<Scalars['String']>;
  generated_stock_id?: Maybe<Scalars['String']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  /** Whether this vehicle has a discount on at least one of its pricing codes. */
  has_discount?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  included_home_trickle_charger: Scalars['Boolean'];
  interior?: Maybe<Scalars['String']>;
  interior_code?: Maybe<Scalars['String']>;
  is_available_for_test_drives: Scalars['Boolean'];
  is_basic_pricing: Scalars['Boolean'];
  is_employee_car: Scalars['Boolean'];
  /** Indicates that the media assigned to the vehicle is "for illustrative purposes only" and does not accurately represent the vehicle. */
  is_illustrative_media: Scalars['Boolean'];
  is_listed: Scalars['Boolean'];
  is_published: Scalars['Boolean'];
  /** A computed field, executes function "stocked_vehicle_is_reserved" */
  is_reserved?: Maybe<Scalars['Boolean']>;
  is_reserved_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  is_reserved_by_user?: Maybe<Users>;
  is_reserved_until?: Maybe<Scalars['timestamptz']>;
  is_sold: Scalars['Boolean'];
  is_template: Scalars['Boolean'];
  is_trashed: Scalars['Boolean'];
  length?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['uuid']>;
  make: Scalars['String'];
  make_logo_src?: Maybe<Scalars['String']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** Deprecated. Used stocked_vehicles.assets instead. */
  media?: Maybe<Scalars['jsonb']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  model: Scalars['String'];
  model_code?: Maybe<Scalars['String']>;
  motor_size?: Maybe<Scalars['numeric']>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer: Scalars['numeric'];
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes: Scalars['jsonb'];
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes?: Maybe<Scalars['jsonb']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  packs?: Maybe<Scalars['String']>;
  powered_by_description?: Maybe<Scalars['String']>;
  powered_by_logo_src?: Maybe<Scalars['String']>;
  pricing_config: Scalars['jsonb'];
  production_year: Scalars['numeric'];
  range?: Maybe<Scalars['numeric']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  safety_rating_authority?: Maybe<Scalars['String']>;
  sales_priority: Scalars['numeric'];
  seats?: Maybe<Scalars['Int']>;
  series?: Maybe<Scalars['String']>;
  /** An array relationship */
  showroom_stocked_vehicles: Array<Showroom_Stocked_Vehicles>;
  /** An aggregate relationship */
  showroom_stocked_vehicles_aggregate: Showroom_Stocked_Vehicles_Aggregate;
  source_id?: Maybe<Scalars['String']>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  stock_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  stocked_vehicle_finance_products: Array<Stocked_Vehicle_Finance_Products>;
  /** An aggregate relationship */
  stocked_vehicle_finance_products_aggregate: Stocked_Vehicle_Finance_Products_Aggregate;
  /** An array relationship */
  stocked_vehicle_handover_locations: Array<Stocked_Vehicle_Handover_Locations>;
  /** An aggregate relationship */
  stocked_vehicle_handover_locations_aggregate: Stocked_Vehicle_Handover_Locations_Aggregate;
  /** An array relationship */
  stocked_vehicle_offers: Array<Stocked_Vehicle_Offers>;
  /** An aggregate relationship */
  stocked_vehicle_offers_aggregate: Stocked_Vehicle_Offers_Aggregate;
  supplier_reference?: Maybe<Scalars['String']>;
  synced_from_source_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  test_drives: Array<Test_Drives>;
  /** An aggregate relationship */
  test_drives_aggregate: Test_Drives_Aggregate;
  /** A computed field, executes function "stocked_vehicle_title" */
  title?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  unmapped_option_codes: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  /** An object relationship */
  vehicle_specification?: Maybe<Vehicle_Specifications>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  wheel_code?: Maybe<Scalars['String']>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Scalars['String']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  wish_lists: Array<Wish_List>;
  /** An aggregate relationship */
  wish_lists_aggregate: Wish_List_Aggregate;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesAdditional_FeaturesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesAuctionsArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesAuctions_AggregateArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesFeaturesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesFilesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesMediaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesOption_CodesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesPack_CodesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesPricing_ConfigArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesShowroom_Stocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesShowroom_Stocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesStocked_Vehicle_Finance_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesStocked_Vehicle_Finance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesStocked_Vehicle_Handover_LocationsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesStocked_Vehicle_Handover_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesStocked_Vehicle_OffersArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Offers_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesStocked_Vehicle_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Offers_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesTest_DrivesArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesTest_Drives_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesUnmapped_Option_CodesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesWish_ListsArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};


/** columns and relationships of "stocked_vehicles" */
export type Stocked_VehiclesWish_Lists_AggregateArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};

/** aggregated selection of "stocked_vehicles" */
export type Stocked_Vehicles_Aggregate = {
  __typename?: 'stocked_vehicles_aggregate';
  aggregate?: Maybe<Stocked_Vehicles_Aggregate_Fields>;
  nodes: Array<Stocked_Vehicles>;
};

export type Stocked_Vehicles_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Stocked_Vehicles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Stocked_Vehicles_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Stocked_Vehicles_Aggregate_Bool_Exp_Count>;
};

export type Stocked_Vehicles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Stocked_Vehicles_Select_Column_Stocked_Vehicles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stocked_Vehicles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stocked_Vehicles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Stocked_Vehicles_Select_Column_Stocked_Vehicles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stocked_Vehicles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stocked_Vehicles_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stocked_Vehicles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stocked_vehicles" */
export type Stocked_Vehicles_Aggregate_Fields = {
  __typename?: 'stocked_vehicles_aggregate_fields';
  avg?: Maybe<Stocked_Vehicles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stocked_Vehicles_Max_Fields>;
  min?: Maybe<Stocked_Vehicles_Min_Fields>;
  stddev?: Maybe<Stocked_Vehicles_Stddev_Fields>;
  stddev_pop?: Maybe<Stocked_Vehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stocked_Vehicles_Stddev_Samp_Fields>;
  sum?: Maybe<Stocked_Vehicles_Sum_Fields>;
  var_pop?: Maybe<Stocked_Vehicles_Var_Pop_Fields>;
  var_samp?: Maybe<Stocked_Vehicles_Var_Samp_Fields>;
  variance?: Maybe<Stocked_Vehicles_Variance_Fields>;
};


/** aggregate fields of "stocked_vehicles" */
export type Stocked_Vehicles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stocked_vehicles" */
export type Stocked_Vehicles_Aggregate_Order_By = {
  avg?: Maybe<Stocked_Vehicles_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stocked_Vehicles_Max_Order_By>;
  min?: Maybe<Stocked_Vehicles_Min_Order_By>;
  stddev?: Maybe<Stocked_Vehicles_Stddev_Order_By>;
  stddev_pop?: Maybe<Stocked_Vehicles_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stocked_Vehicles_Stddev_Samp_Order_By>;
  sum?: Maybe<Stocked_Vehicles_Sum_Order_By>;
  var_pop?: Maybe<Stocked_Vehicles_Var_Pop_Order_By>;
  var_samp?: Maybe<Stocked_Vehicles_Var_Samp_Order_By>;
  variance?: Maybe<Stocked_Vehicles_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stocked_Vehicles_Append_Input = {
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['jsonb']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['jsonb']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['jsonb']>;
  pack_codes?: Maybe<Scalars['jsonb']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  unmapped_option_codes?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "stocked_vehicles" */
export type Stocked_Vehicles_Arr_Rel_Insert_Input = {
  data: Array<Stocked_Vehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stocked_Vehicles_On_Conflict>;
};

/** aggregate avg on columns */
export type Stocked_Vehicles_Avg_Fields = {
  __typename?: 'stocked_vehicles_avg_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Avg_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stocked_vehicles". All fields are combined with a logical 'AND'. */
export type Stocked_Vehicles_Bool_Exp = {
  _and?: Maybe<Array<Stocked_Vehicles_Bool_Exp>>;
  _not?: Maybe<Stocked_Vehicles_Bool_Exp>;
  _or?: Maybe<Array<Stocked_Vehicles_Bool_Exp>>;
  acceleration_0_100_km?: Maybe<Numeric_Comparison_Exp>;
  accessories_warranty_distance?: Maybe<Numeric_Comparison_Exp>;
  accessories_warranty_years?: Maybe<Numeric_Comparison_Exp>;
  additional_features?: Maybe<Jsonb_Comparison_Exp>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  auctions?: Maybe<Auctions_Bool_Exp>;
  auctions_aggregate?: Maybe<Auctions_Aggregate_Bool_Exp>;
  available_from?: Maybe<Date_Comparison_Exp>;
  badge?: Maybe<String_Comparison_Exp>;
  badge_code?: Maybe<String_Comparison_Exp>;
  battery_health?: Maybe<Numeric_Comparison_Exp>;
  battery_type?: Maybe<String_Comparison_Exp>;
  battery_warranty_distance?: Maybe<Numeric_Comparison_Exp>;
  battery_warranty_years?: Maybe<Numeric_Comparison_Exp>;
  body_type?: Maybe<String_Comparison_Exp>;
  boot_capacity_seats_down?: Maybe<Numeric_Comparison_Exp>;
  boot_capacity_seats_up?: Maybe<Numeric_Comparison_Exp>;
  build_date?: Maybe<Date_Comparison_Exp>;
  charge_port_ac?: Maybe<String_Comparison_Exp>;
  charge_port_dc?: Maybe<String_Comparison_Exp>;
  charge_port_location?: Maybe<String_Comparison_Exp>;
  charge_power_ac?: Maybe<Numeric_Comparison_Exp>;
  charge_power_dc?: Maybe<Numeric_Comparison_Exp>;
  charge_speed_ac?: Maybe<Numeric_Comparison_Exp>;
  charge_speed_dc?: Maybe<Numeric_Comparison_Exp>;
  charge_time_ac?: Maybe<Numeric_Comparison_Exp>;
  charge_time_dc?: Maybe<Numeric_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  colour?: Maybe<String_Comparison_Exp>;
  colour_code?: Maybe<String_Comparison_Exp>;
  compliance_date?: Maybe<Date_Comparison_Exp>;
  condition?: Maybe<Vehicle_Conditions_Enum_Comparison_Exp>;
  cost_of_goods?: Maybe<Numeric_Comparison_Exp>;
  cost_of_goods_paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deposit_amount?: Maybe<Numeric_Comparison_Exp>;
  deposit_amount_paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  doors?: Maybe<Int_Comparison_Exp>;
  drive_type?: Maybe<String_Comparison_Exp>;
  efficiency?: Maybe<Numeric_Comparison_Exp>;
  engine_details?: Maybe<String_Comparison_Exp>;
  engine_number?: Maybe<String_Comparison_Exp>;
  estimated_delivery_delay?: Maybe<Int_Comparison_Exp>;
  exclude_auto_refresh_assets?: Maybe<Boolean_Comparison_Exp>;
  external_cta_description?: Maybe<String_Comparison_Exp>;
  external_cta_label?: Maybe<String_Comparison_Exp>;
  external_cta_url?: Maybe<String_Comparison_Exp>;
  factory_order_no?: Maybe<String_Comparison_Exp>;
  featured_order?: Maybe<Int_Comparison_Exp>;
  features?: Maybe<Jsonb_Comparison_Exp>;
  features_version?: Maybe<String_Comparison_Exp>;
  files?: Maybe<Jsonb_Comparison_Exp>;
  fuel_type?: Maybe<String_Comparison_Exp>;
  generated_stock_id?: Maybe<String_Comparison_Exp>;
  gross_weight?: Maybe<Numeric_Comparison_Exp>;
  has_discount?: Maybe<Boolean_Comparison_Exp>;
  height?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  included_home_trickle_charger?: Maybe<Boolean_Comparison_Exp>;
  interior?: Maybe<String_Comparison_Exp>;
  interior_code?: Maybe<String_Comparison_Exp>;
  is_available_for_test_drives?: Maybe<Boolean_Comparison_Exp>;
  is_basic_pricing?: Maybe<Boolean_Comparison_Exp>;
  is_employee_car?: Maybe<Boolean_Comparison_Exp>;
  is_illustrative_media?: Maybe<Boolean_Comparison_Exp>;
  is_listed?: Maybe<Boolean_Comparison_Exp>;
  is_published?: Maybe<Boolean_Comparison_Exp>;
  is_reserved?: Maybe<Boolean_Comparison_Exp>;
  is_reserved_by?: Maybe<Uuid_Comparison_Exp>;
  is_reserved_by_user?: Maybe<Users_Bool_Exp>;
  is_reserved_until?: Maybe<Timestamptz_Comparison_Exp>;
  is_sold?: Maybe<Boolean_Comparison_Exp>;
  is_template?: Maybe<Boolean_Comparison_Exp>;
  is_trashed?: Maybe<Boolean_Comparison_Exp>;
  length?: Maybe<Numeric_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  make_logo_src?: Maybe<String_Comparison_Exp>;
  max_price?: Maybe<Numeric_Comparison_Exp>;
  mechanical_warranty_distance?: Maybe<Numeric_Comparison_Exp>;
  mechanical_warranty_years?: Maybe<Numeric_Comparison_Exp>;
  media?: Maybe<Jsonb_Comparison_Exp>;
  min_price?: Maybe<Numeric_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  model_code?: Maybe<String_Comparison_Exp>;
  motor_size?: Maybe<Numeric_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  number_of_airbags?: Maybe<Numeric_Comparison_Exp>;
  odometer?: Maybe<Numeric_Comparison_Exp>;
  option_codes?: Maybe<Jsonb_Comparison_Exp>;
  option_codes_count?: Maybe<Int_Comparison_Exp>;
  pack_codes?: Maybe<Jsonb_Comparison_Exp>;
  pack_codes_count?: Maybe<Int_Comparison_Exp>;
  packs?: Maybe<String_Comparison_Exp>;
  powered_by_description?: Maybe<String_Comparison_Exp>;
  powered_by_logo_src?: Maybe<String_Comparison_Exp>;
  pricing_config?: Maybe<Jsonb_Comparison_Exp>;
  production_year?: Maybe<Numeric_Comparison_Exp>;
  range?: Maybe<Numeric_Comparison_Exp>;
  registration_expiry_date?: Maybe<Date_Comparison_Exp>;
  registration_number?: Maybe<String_Comparison_Exp>;
  safety_rating?: Maybe<Numeric_Comparison_Exp>;
  safety_rating_authority?: Maybe<String_Comparison_Exp>;
  sales_priority?: Maybe<Numeric_Comparison_Exp>;
  seats?: Maybe<Int_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
  showroom_stocked_vehicles_aggregate?: Maybe<Showroom_Stocked_Vehicles_Aggregate_Bool_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  source_name?: Maybe<String_Comparison_Exp>;
  state_of_registration?: Maybe<String_Comparison_Exp>;
  stock_id?: Maybe<String_Comparison_Exp>;
  stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
  stocked_vehicle_finance_products_aggregate?: Maybe<Stocked_Vehicle_Finance_Products_Aggregate_Bool_Exp>;
  stocked_vehicle_handover_locations?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
  stocked_vehicle_handover_locations_aggregate?: Maybe<Stocked_Vehicle_Handover_Locations_Aggregate_Bool_Exp>;
  stocked_vehicle_offers?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
  stocked_vehicle_offers_aggregate?: Maybe<Stocked_Vehicle_Offers_Aggregate_Bool_Exp>;
  supplier_reference?: Maybe<String_Comparison_Exp>;
  synced_from_source_at?: Maybe<Timestamptz_Comparison_Exp>;
  test_drives?: Maybe<Test_Drives_Bool_Exp>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  top_speed?: Maybe<Numeric_Comparison_Exp>;
  torque?: Maybe<Numeric_Comparison_Exp>;
  touched?: Maybe<Int_Comparison_Exp>;
  towing_capacity_braked?: Maybe<Numeric_Comparison_Exp>;
  towing_capacity_unbraked?: Maybe<Numeric_Comparison_Exp>;
  transmission?: Maybe<String_Comparison_Exp>;
  unmapped_option_codes?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  useable_battery?: Maybe<Numeric_Comparison_Exp>;
  vehicle_orders?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
  vehicle_specification?: Maybe<Vehicle_Specifications_Bool_Exp>;
  vehicle_specification_id?: Maybe<Uuid_Comparison_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  wheel_code?: Maybe<String_Comparison_Exp>;
  wheel_name?: Maybe<String_Comparison_Exp>;
  wheel_size?: Maybe<Numeric_Comparison_Exp>;
  width?: Maybe<Numeric_Comparison_Exp>;
  wish_lists?: Maybe<Wish_List_Bool_Exp>;
  wish_lists_aggregate?: Maybe<Wish_List_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stocked_vehicles" */
export enum Stocked_Vehicles_Constraint {
  /** unique or primary key constraint on columns "code" */
  StockedVehiclesCodeKey = 'stocked_vehicles_code_key',
  /** unique or primary key constraint on columns "generated_stock_id" */
  StockedVehiclesGeneratedStockIdKey = 'stocked_vehicles_generated_stock_id_key',
  /** unique or primary key constraint on columns "id" */
  StockedVehiclesPkey = 'stocked_vehicles_pkey',
  /** unique or primary key constraint on columns "source_id", "source_name" */
  StockedVehiclesSourceNameSourceIdKey = 'stocked_vehicles_source_name_source_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stocked_Vehicles_Delete_At_Path_Input = {
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Array<Scalars['String']>>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Array<Scalars['String']>>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Array<Scalars['String']>>;
  pack_codes?: Maybe<Array<Scalars['String']>>;
  pricing_config?: Maybe<Array<Scalars['String']>>;
  unmapped_option_codes?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stocked_Vehicles_Delete_Elem_Input = {
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['Int']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['Int']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['Int']>;
  pack_codes?: Maybe<Scalars['Int']>;
  pricing_config?: Maybe<Scalars['Int']>;
  unmapped_option_codes?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stocked_Vehicles_Delete_Key_Input = {
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['String']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['String']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['String']>;
  pack_codes?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['String']>;
  unmapped_option_codes?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "stocked_vehicles" */
export type Stocked_Vehicles_Inc_Input = {
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  doors?: Maybe<Scalars['Int']>;
  efficiency?: Maybe<Scalars['numeric']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  featured_order?: Maybe<Scalars['Int']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  height?: Maybe<Scalars['numeric']>;
  length?: Maybe<Scalars['numeric']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  motor_size?: Maybe<Scalars['numeric']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "stocked_vehicles" */
export type Stocked_Vehicles_Insert_Input = {
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['jsonb']>;
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  auctions?: Maybe<Auctions_Arr_Rel_Insert_Input>;
  available_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  badge_code?: Maybe<Scalars['String']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_type?: Maybe<Scalars['String']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  body_type?: Maybe<Scalars['String']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  build_date?: Maybe<Scalars['date']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  colour_code?: Maybe<Scalars['String']>;
  compliance_date?: Maybe<Scalars['date']>;
  /** new | used | demo */
  condition?: Maybe<Vehicle_Conditions_Enum>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  cost_of_goods_paid_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  deposit_amount_paid_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  drive_type?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['numeric']>;
  engine_details?: Maybe<Scalars['String']>;
  engine_number?: Maybe<Scalars['String']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  exclude_auto_refresh_assets?: Maybe<Scalars['Boolean']>;
  external_cta_description?: Maybe<Scalars['String']>;
  external_cta_label?: Maybe<Scalars['String']>;
  external_cta_url?: Maybe<Scalars['String']>;
  factory_order_no?: Maybe<Scalars['String']>;
  featured_order?: Maybe<Scalars['Int']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['jsonb']>;
  features_version?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  generated_stock_id?: Maybe<Scalars['String']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  /** Whether this vehicle has a discount on at least one of its pricing codes. */
  has_discount?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  included_home_trickle_charger?: Maybe<Scalars['Boolean']>;
  interior?: Maybe<Scalars['String']>;
  interior_code?: Maybe<Scalars['String']>;
  is_available_for_test_drives?: Maybe<Scalars['Boolean']>;
  is_basic_pricing?: Maybe<Scalars['Boolean']>;
  is_employee_car?: Maybe<Scalars['Boolean']>;
  /** Indicates that the media assigned to the vehicle is "for illustrative purposes only" and does not accurately represent the vehicle. */
  is_illustrative_media?: Maybe<Scalars['Boolean']>;
  is_listed?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_reserved_by?: Maybe<Scalars['uuid']>;
  is_reserved_by_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  is_reserved_until?: Maybe<Scalars['timestamptz']>;
  is_sold?: Maybe<Scalars['Boolean']>;
  is_template?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['numeric']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  make_logo_src?: Maybe<Scalars['String']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  model_code?: Maybe<Scalars['String']>;
  motor_size?: Maybe<Scalars['numeric']>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['jsonb']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes?: Maybe<Scalars['jsonb']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  packs?: Maybe<Scalars['String']>;
  powered_by_description?: Maybe<Scalars['String']>;
  powered_by_logo_src?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  safety_rating_authority?: Maybe<Scalars['String']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  series?: Maybe<Scalars['String']>;
  showroom_stocked_vehicles?: Maybe<Showroom_Stocked_Vehicles_Arr_Rel_Insert_Input>;
  source_id?: Maybe<Scalars['String']>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  stock_id?: Maybe<Scalars['String']>;
  stocked_vehicle_finance_products?: Maybe<Stocked_Vehicle_Finance_Products_Arr_Rel_Insert_Input>;
  stocked_vehicle_handover_locations?: Maybe<Stocked_Vehicle_Handover_Locations_Arr_Rel_Insert_Input>;
  stocked_vehicle_offers?: Maybe<Stocked_Vehicle_Offers_Arr_Rel_Insert_Input>;
  supplier_reference?: Maybe<Scalars['String']>;
  synced_from_source_at?: Maybe<Scalars['timestamptz']>;
  test_drives?: Maybe<Test_Drives_Arr_Rel_Insert_Input>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  unmapped_option_codes?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  vehicle_orders?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
  vehicle_specification?: Maybe<Vehicle_Specifications_Obj_Rel_Insert_Input>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  wheel_code?: Maybe<Scalars['String']>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Scalars['String']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
  wish_lists?: Maybe<Wish_List_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stocked_Vehicles_Max_Fields = {
  __typename?: 'stocked_vehicles_max_fields';
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  available_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  badge_code?: Maybe<Scalars['String']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_type?: Maybe<Scalars['String']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  body_type?: Maybe<Scalars['String']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  build_date?: Maybe<Scalars['date']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  colour_code?: Maybe<Scalars['String']>;
  compliance_date?: Maybe<Scalars['date']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  cost_of_goods_paid_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  deposit_amount_paid_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  drive_type?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['numeric']>;
  engine_details?: Maybe<Scalars['String']>;
  engine_number?: Maybe<Scalars['String']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  external_cta_description?: Maybe<Scalars['String']>;
  external_cta_label?: Maybe<Scalars['String']>;
  external_cta_url?: Maybe<Scalars['String']>;
  factory_order_no?: Maybe<Scalars['String']>;
  featured_order?: Maybe<Scalars['Int']>;
  features_version?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  generated_stock_id?: Maybe<Scalars['String']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  interior?: Maybe<Scalars['String']>;
  interior_code?: Maybe<Scalars['String']>;
  is_reserved_by?: Maybe<Scalars['uuid']>;
  is_reserved_until?: Maybe<Scalars['timestamptz']>;
  length?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  make_logo_src?: Maybe<Scalars['String']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  model_code?: Maybe<Scalars['String']>;
  motor_size?: Maybe<Scalars['numeric']>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  packs?: Maybe<Scalars['String']>;
  powered_by_description?: Maybe<Scalars['String']>;
  powered_by_logo_src?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  safety_rating_authority?: Maybe<Scalars['String']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  series?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  stock_id?: Maybe<Scalars['String']>;
  supplier_reference?: Maybe<Scalars['String']>;
  synced_from_source_at?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "stocked_vehicle_title" */
  title?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  wheel_code?: Maybe<Scalars['String']>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Scalars['String']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Max_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  available_from?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  badge_code?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_type?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  build_date?: Maybe<Order_By>;
  charge_port_ac?: Maybe<Order_By>;
  charge_port_dc?: Maybe<Order_By>;
  charge_port_location?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  colour?: Maybe<Order_By>;
  colour_code?: Maybe<Order_By>;
  compliance_date?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  cost_of_goods_paid_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  deposit_amount_paid_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  drive_type?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  engine_details?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  external_cta_description?: Maybe<Order_By>;
  external_cta_label?: Maybe<Order_By>;
  external_cta_url?: Maybe<Order_By>;
  factory_order_no?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  features_version?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  generated_stock_id?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interior?: Maybe<Order_By>;
  interior_code?: Maybe<Order_By>;
  is_reserved_by?: Maybe<Order_By>;
  is_reserved_until?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  make_logo_src?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  model_code?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  packs?: Maybe<Order_By>;
  powered_by_description?: Maybe<Order_By>;
  powered_by_logo_src?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  registration_expiry_date?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  safety_rating_authority?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  stock_id?: Maybe<Order_By>;
  supplier_reference?: Maybe<Order_By>;
  synced_from_source_at?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  vehicle_specification_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  wheel_code?: Maybe<Order_By>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stocked_Vehicles_Min_Fields = {
  __typename?: 'stocked_vehicles_min_fields';
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  available_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  badge_code?: Maybe<Scalars['String']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_type?: Maybe<Scalars['String']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  body_type?: Maybe<Scalars['String']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  build_date?: Maybe<Scalars['date']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  colour_code?: Maybe<Scalars['String']>;
  compliance_date?: Maybe<Scalars['date']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  cost_of_goods_paid_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  deposit_amount_paid_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  drive_type?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['numeric']>;
  engine_details?: Maybe<Scalars['String']>;
  engine_number?: Maybe<Scalars['String']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  external_cta_description?: Maybe<Scalars['String']>;
  external_cta_label?: Maybe<Scalars['String']>;
  external_cta_url?: Maybe<Scalars['String']>;
  factory_order_no?: Maybe<Scalars['String']>;
  featured_order?: Maybe<Scalars['Int']>;
  features_version?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  generated_stock_id?: Maybe<Scalars['String']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  interior?: Maybe<Scalars['String']>;
  interior_code?: Maybe<Scalars['String']>;
  is_reserved_by?: Maybe<Scalars['uuid']>;
  is_reserved_until?: Maybe<Scalars['timestamptz']>;
  length?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  make_logo_src?: Maybe<Scalars['String']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  model_code?: Maybe<Scalars['String']>;
  motor_size?: Maybe<Scalars['numeric']>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  packs?: Maybe<Scalars['String']>;
  powered_by_description?: Maybe<Scalars['String']>;
  powered_by_logo_src?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  safety_rating_authority?: Maybe<Scalars['String']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  series?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  stock_id?: Maybe<Scalars['String']>;
  supplier_reference?: Maybe<Scalars['String']>;
  synced_from_source_at?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "stocked_vehicle_title" */
  title?: Maybe<Scalars['String']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  wheel_code?: Maybe<Scalars['String']>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Scalars['String']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Min_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  available_from?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  badge_code?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_type?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  build_date?: Maybe<Order_By>;
  charge_port_ac?: Maybe<Order_By>;
  charge_port_dc?: Maybe<Order_By>;
  charge_port_location?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  colour?: Maybe<Order_By>;
  colour_code?: Maybe<Order_By>;
  compliance_date?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  cost_of_goods_paid_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  deposit_amount_paid_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  drive_type?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  engine_details?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  external_cta_description?: Maybe<Order_By>;
  external_cta_label?: Maybe<Order_By>;
  external_cta_url?: Maybe<Order_By>;
  factory_order_no?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  features_version?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  generated_stock_id?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interior?: Maybe<Order_By>;
  interior_code?: Maybe<Order_By>;
  is_reserved_by?: Maybe<Order_By>;
  is_reserved_until?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  make_logo_src?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  model_code?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  packs?: Maybe<Order_By>;
  powered_by_description?: Maybe<Order_By>;
  powered_by_logo_src?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  registration_expiry_date?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  safety_rating_authority?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  stock_id?: Maybe<Order_By>;
  supplier_reference?: Maybe<Order_By>;
  synced_from_source_at?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  vehicle_specification_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  wheel_code?: Maybe<Order_By>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** response of any mutation on the table "stocked_vehicles" */
export type Stocked_Vehicles_Mutation_Response = {
  __typename?: 'stocked_vehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocked_Vehicles>;
};

/** input type for inserting object relation for remote table "stocked_vehicles" */
export type Stocked_Vehicles_Obj_Rel_Insert_Input = {
  data: Stocked_Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stocked_Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "stocked_vehicles" */
export type Stocked_Vehicles_On_Conflict = {
  constraint: Stocked_Vehicles_Constraint;
  update_columns?: Array<Stocked_Vehicles_Update_Column>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "stocked_vehicles". */
export type Stocked_Vehicles_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  additional_features?: Maybe<Order_By>;
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  auctions_aggregate?: Maybe<Auctions_Aggregate_Order_By>;
  available_from?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  badge_code?: Maybe<Order_By>;
  battery_health?: Maybe<Order_By>;
  battery_type?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  build_date?: Maybe<Order_By>;
  charge_port_ac?: Maybe<Order_By>;
  charge_port_dc?: Maybe<Order_By>;
  charge_port_location?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  charge_time_ac?: Maybe<Order_By>;
  charge_time_dc?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  colour?: Maybe<Order_By>;
  colour_code?: Maybe<Order_By>;
  compliance_date?: Maybe<Order_By>;
  condition?: Maybe<Order_By>;
  cost_of_goods?: Maybe<Order_By>;
  cost_of_goods_paid_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deposit_amount?: Maybe<Order_By>;
  deposit_amount_paid_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  drive_type?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  engine_details?: Maybe<Order_By>;
  engine_number?: Maybe<Order_By>;
  estimated_delivery_delay?: Maybe<Order_By>;
  exclude_auto_refresh_assets?: Maybe<Order_By>;
  external_cta_description?: Maybe<Order_By>;
  external_cta_label?: Maybe<Order_By>;
  external_cta_url?: Maybe<Order_By>;
  factory_order_no?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  features?: Maybe<Order_By>;
  features_version?: Maybe<Order_By>;
  files?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  generated_stock_id?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  has_discount?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  included_home_trickle_charger?: Maybe<Order_By>;
  interior?: Maybe<Order_By>;
  interior_code?: Maybe<Order_By>;
  is_available_for_test_drives?: Maybe<Order_By>;
  is_basic_pricing?: Maybe<Order_By>;
  is_employee_car?: Maybe<Order_By>;
  is_illustrative_media?: Maybe<Order_By>;
  is_listed?: Maybe<Order_By>;
  is_published?: Maybe<Order_By>;
  is_reserved?: Maybe<Order_By>;
  is_reserved_by?: Maybe<Order_By>;
  is_reserved_by_user?: Maybe<Users_Order_By>;
  is_reserved_until?: Maybe<Order_By>;
  is_sold?: Maybe<Order_By>;
  is_template?: Maybe<Order_By>;
  is_trashed?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  make_logo_src?: Maybe<Order_By>;
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  media?: Maybe<Order_By>;
  min_price?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  model_code?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  packs?: Maybe<Order_By>;
  powered_by_description?: Maybe<Order_By>;
  powered_by_logo_src?: Maybe<Order_By>;
  pricing_config?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  registration_expiry_date?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  safety_rating_authority?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  showroom_stocked_vehicles_aggregate?: Maybe<Showroom_Stocked_Vehicles_Aggregate_Order_By>;
  source_id?: Maybe<Order_By>;
  source_name?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  stock_id?: Maybe<Order_By>;
  stocked_vehicle_finance_products_aggregate?: Maybe<Stocked_Vehicle_Finance_Products_Aggregate_Order_By>;
  stocked_vehicle_handover_locations_aggregate?: Maybe<Stocked_Vehicle_Handover_Locations_Aggregate_Order_By>;
  stocked_vehicle_offers_aggregate?: Maybe<Stocked_Vehicle_Offers_Aggregate_Order_By>;
  supplier_reference?: Maybe<Order_By>;
  synced_from_source_at?: Maybe<Order_By>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  unmapped_option_codes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  useable_battery?: Maybe<Order_By>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
  vehicle_specification?: Maybe<Vehicle_Specifications_Order_By>;
  vehicle_specification_id?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  wheel_code?: Maybe<Order_By>;
  wheel_name?: Maybe<Order_By>;
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
  wish_lists_aggregate?: Maybe<Wish_List_Aggregate_Order_By>;
};

/** primary key columns input for table: stocked_vehicles */
export type Stocked_Vehicles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stocked_Vehicles_Prepend_Input = {
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['jsonb']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['jsonb']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['jsonb']>;
  pack_codes?: Maybe<Scalars['jsonb']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  unmapped_option_codes?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "stocked_vehicles" */
export enum Stocked_Vehicles_Select_Column {
  /** column name */
  Acceleration_0_100Km = 'acceleration_0_100_km',
  /** column name */
  AccessoriesWarrantyDistance = 'accessories_warranty_distance',
  /** column name */
  AccessoriesWarrantyYears = 'accessories_warranty_years',
  /** column name */
  AdditionalFeatures = 'additional_features',
  /** column name */
  AvailableFrom = 'available_from',
  /** column name */
  Badge = 'badge',
  /** column name */
  BadgeCode = 'badge_code',
  /** column name */
  BatteryHealth = 'battery_health',
  /** column name */
  BatteryType = 'battery_type',
  /** column name */
  BatteryWarrantyDistance = 'battery_warranty_distance',
  /** column name */
  BatteryWarrantyYears = 'battery_warranty_years',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  BootCapacitySeatsDown = 'boot_capacity_seats_down',
  /** column name */
  BootCapacitySeatsUp = 'boot_capacity_seats_up',
  /** column name */
  BuildDate = 'build_date',
  /** column name */
  ChargePortAc = 'charge_port_ac',
  /** column name */
  ChargePortDc = 'charge_port_dc',
  /** column name */
  ChargePortLocation = 'charge_port_location',
  /** column name */
  ChargePowerAc = 'charge_power_ac',
  /** column name */
  ChargePowerDc = 'charge_power_dc',
  /** column name */
  ChargeSpeedAc = 'charge_speed_ac',
  /** column name */
  ChargeSpeedDc = 'charge_speed_dc',
  /** column name */
  ChargeTimeAc = 'charge_time_ac',
  /** column name */
  ChargeTimeDc = 'charge_time_dc',
  /** column name */
  Code = 'code',
  /** column name */
  Colour = 'colour',
  /** column name */
  ColourCode = 'colour_code',
  /** column name */
  ComplianceDate = 'compliance_date',
  /** column name */
  Condition = 'condition',
  /** column name */
  CostOfGoods = 'cost_of_goods',
  /** column name */
  CostOfGoodsPaidAt = 'cost_of_goods_paid_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  DepositAmountPaidAt = 'deposit_amount_paid_at',
  /** column name */
  Description = 'description',
  /** column name */
  Doors = 'doors',
  /** column name */
  DriveType = 'drive_type',
  /** column name */
  Efficiency = 'efficiency',
  /** column name */
  EngineDetails = 'engine_details',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  EstimatedDeliveryDelay = 'estimated_delivery_delay',
  /** column name */
  ExcludeAutoRefreshAssets = 'exclude_auto_refresh_assets',
  /** column name */
  ExternalCtaDescription = 'external_cta_description',
  /** column name */
  ExternalCtaLabel = 'external_cta_label',
  /** column name */
  ExternalCtaUrl = 'external_cta_url',
  /** column name */
  FactoryOrderNo = 'factory_order_no',
  /** column name */
  FeaturedOrder = 'featured_order',
  /** column name */
  Features = 'features',
  /** column name */
  FeaturesVersion = 'features_version',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  GeneratedStockId = 'generated_stock_id',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  HasDiscount = 'has_discount',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IncludedHomeTrickleCharger = 'included_home_trickle_charger',
  /** column name */
  Interior = 'interior',
  /** column name */
  InteriorCode = 'interior_code',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsBasicPricing = 'is_basic_pricing',
  /** column name */
  IsEmployeeCar = 'is_employee_car',
  /** column name */
  IsIllustrativeMedia = 'is_illustrative_media',
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsReservedBy = 'is_reserved_by',
  /** column name */
  IsReservedUntil = 'is_reserved_until',
  /** column name */
  IsSold = 'is_sold',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Length = 'length',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Make = 'make',
  /** column name */
  MakeLogoSrc = 'make_logo_src',
  /** column name */
  MaxPrice = 'max_price',
  /** column name */
  MechanicalWarrantyDistance = 'mechanical_warranty_distance',
  /** column name */
  MechanicalWarrantyYears = 'mechanical_warranty_years',
  /** column name */
  MinPrice = 'min_price',
  /** column name */
  Model = 'model',
  /** column name */
  ModelCode = 'model_code',
  /** column name */
  MotorSize = 'motor_size',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  NumberOfAirbags = 'number_of_airbags',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OptionCodes = 'option_codes',
  /** column name */
  OptionCodesCount = 'option_codes_count',
  /** column name */
  PackCodes = 'pack_codes',
  /** column name */
  PackCodesCount = 'pack_codes_count',
  /** column name */
  Packs = 'packs',
  /** column name */
  PoweredByDescription = 'powered_by_description',
  /** column name */
  PoweredByLogoSrc = 'powered_by_logo_src',
  /** column name */
  PricingConfig = 'pricing_config',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Range = 'range',
  /** column name */
  RegistrationExpiryDate = 'registration_expiry_date',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  SafetyRating = 'safety_rating',
  /** column name */
  SafetyRatingAuthority = 'safety_rating_authority',
  /** column name */
  SalesPriority = 'sales_priority',
  /** column name */
  Seats = 'seats',
  /** column name */
  Series = 'series',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  StockId = 'stock_id',
  /** column name */
  SupplierReference = 'supplier_reference',
  /** column name */
  SyncedFromSourceAt = 'synced_from_source_at',
  /** column name */
  TopSpeed = 'top_speed',
  /** column name */
  Torque = 'torque',
  /** column name */
  Touched = 'touched',
  /** column name */
  TowingCapacityBraked = 'towing_capacity_braked',
  /** column name */
  TowingCapacityUnbraked = 'towing_capacity_unbraked',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UnmappedOptionCodes = 'unmapped_option_codes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UseableBattery = 'useable_battery',
  /** column name */
  VehicleSpecificationId = 'vehicle_specification_id',
  /** column name */
  Vin = 'vin',
  /** column name */
  WheelCode = 'wheel_code',
  /** column name */
  WheelName = 'wheel_name',
  /** column name */
  WheelSize = 'wheel_size',
  /** column name */
  Width = 'width'
}

/** select "stocked_vehicles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "stocked_vehicles" */
export enum Stocked_Vehicles_Select_Column_Stocked_Vehicles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ExcludeAutoRefreshAssets = 'exclude_auto_refresh_assets',
  /** column name */
  HasDiscount = 'has_discount',
  /** column name */
  IncludedHomeTrickleCharger = 'included_home_trickle_charger',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsBasicPricing = 'is_basic_pricing',
  /** column name */
  IsEmployeeCar = 'is_employee_car',
  /** column name */
  IsIllustrativeMedia = 'is_illustrative_media',
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsSold = 'is_sold',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  IsTrashed = 'is_trashed'
}

/** select "stocked_vehicles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "stocked_vehicles" */
export enum Stocked_Vehicles_Select_Column_Stocked_Vehicles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ExcludeAutoRefreshAssets = 'exclude_auto_refresh_assets',
  /** column name */
  HasDiscount = 'has_discount',
  /** column name */
  IncludedHomeTrickleCharger = 'included_home_trickle_charger',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsBasicPricing = 'is_basic_pricing',
  /** column name */
  IsEmployeeCar = 'is_employee_car',
  /** column name */
  IsIllustrativeMedia = 'is_illustrative_media',
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsSold = 'is_sold',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  IsTrashed = 'is_trashed'
}

/** input type for updating data in table "stocked_vehicles" */
export type Stocked_Vehicles_Set_Input = {
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['jsonb']>;
  available_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  badge_code?: Maybe<Scalars['String']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_type?: Maybe<Scalars['String']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  body_type?: Maybe<Scalars['String']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  build_date?: Maybe<Scalars['date']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  colour_code?: Maybe<Scalars['String']>;
  compliance_date?: Maybe<Scalars['date']>;
  /** new | used | demo */
  condition?: Maybe<Vehicle_Conditions_Enum>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  cost_of_goods_paid_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  deposit_amount_paid_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  drive_type?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['numeric']>;
  engine_details?: Maybe<Scalars['String']>;
  engine_number?: Maybe<Scalars['String']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  exclude_auto_refresh_assets?: Maybe<Scalars['Boolean']>;
  external_cta_description?: Maybe<Scalars['String']>;
  external_cta_label?: Maybe<Scalars['String']>;
  external_cta_url?: Maybe<Scalars['String']>;
  factory_order_no?: Maybe<Scalars['String']>;
  featured_order?: Maybe<Scalars['Int']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['jsonb']>;
  features_version?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  generated_stock_id?: Maybe<Scalars['String']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  /** Whether this vehicle has a discount on at least one of its pricing codes. */
  has_discount?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  included_home_trickle_charger?: Maybe<Scalars['Boolean']>;
  interior?: Maybe<Scalars['String']>;
  interior_code?: Maybe<Scalars['String']>;
  is_available_for_test_drives?: Maybe<Scalars['Boolean']>;
  is_basic_pricing?: Maybe<Scalars['Boolean']>;
  is_employee_car?: Maybe<Scalars['Boolean']>;
  /** Indicates that the media assigned to the vehicle is "for illustrative purposes only" and does not accurately represent the vehicle. */
  is_illustrative_media?: Maybe<Scalars['Boolean']>;
  is_listed?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_reserved_by?: Maybe<Scalars['uuid']>;
  is_reserved_until?: Maybe<Scalars['timestamptz']>;
  is_sold?: Maybe<Scalars['Boolean']>;
  is_template?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  make_logo_src?: Maybe<Scalars['String']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  model_code?: Maybe<Scalars['String']>;
  motor_size?: Maybe<Scalars['numeric']>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['jsonb']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes?: Maybe<Scalars['jsonb']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  packs?: Maybe<Scalars['String']>;
  powered_by_description?: Maybe<Scalars['String']>;
  powered_by_logo_src?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  safety_rating_authority?: Maybe<Scalars['String']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  series?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  stock_id?: Maybe<Scalars['String']>;
  supplier_reference?: Maybe<Scalars['String']>;
  synced_from_source_at?: Maybe<Scalars['timestamptz']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  unmapped_option_codes?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  wheel_code?: Maybe<Scalars['String']>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Scalars['String']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Stocked_Vehicles_Stddev_Fields = {
  __typename?: 'stocked_vehicles_stddev_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Stddev_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stocked_Vehicles_Stddev_Pop_Fields = {
  __typename?: 'stocked_vehicles_stddev_pop_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Stddev_Pop_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stocked_Vehicles_Stddev_Samp_Fields = {
  __typename?: 'stocked_vehicles_stddev_samp_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Stddev_Samp_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stocked_vehicles" */
export type Stocked_Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stocked_Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stocked_Vehicles_Stream_Cursor_Value_Input = {
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  /** An array of features. E.g. [{ name: String, description: String }] */
  additional_features?: Maybe<Scalars['jsonb']>;
  available_from?: Maybe<Scalars['date']>;
  badge?: Maybe<Scalars['String']>;
  badge_code?: Maybe<Scalars['String']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_type?: Maybe<Scalars['String']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  body_type?: Maybe<Scalars['String']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  build_date?: Maybe<Scalars['date']>;
  charge_port_ac?: Maybe<Scalars['String']>;
  charge_port_dc?: Maybe<Scalars['String']>;
  charge_port_location?: Maybe<Scalars['String']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  colour_code?: Maybe<Scalars['String']>;
  compliance_date?: Maybe<Scalars['date']>;
  /** new | used | demo */
  condition?: Maybe<Vehicle_Conditions_Enum>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  cost_of_goods_paid_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  deposit_amount_paid_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  doors?: Maybe<Scalars['Int']>;
  drive_type?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['numeric']>;
  engine_details?: Maybe<Scalars['String']>;
  engine_number?: Maybe<Scalars['String']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  exclude_auto_refresh_assets?: Maybe<Scalars['Boolean']>;
  external_cta_description?: Maybe<Scalars['String']>;
  external_cta_label?: Maybe<Scalars['String']>;
  external_cta_url?: Maybe<Scalars['String']>;
  factory_order_no?: Maybe<Scalars['String']>;
  featured_order?: Maybe<Scalars['Int']>;
  /** Deprecated if the vehicle is marked as "features_version=v2" (or above). Instead, use the structured features which are at the top level of the vehicle. E.g. range, safety_rating. */
  features?: Maybe<Scalars['jsonb']>;
  features_version?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  generated_stock_id?: Maybe<Scalars['String']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  /** Whether this vehicle has a discount on at least one of its pricing codes. */
  has_discount?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  included_home_trickle_charger?: Maybe<Scalars['Boolean']>;
  interior?: Maybe<Scalars['String']>;
  interior_code?: Maybe<Scalars['String']>;
  is_available_for_test_drives?: Maybe<Scalars['Boolean']>;
  is_basic_pricing?: Maybe<Scalars['Boolean']>;
  is_employee_car?: Maybe<Scalars['Boolean']>;
  /** Indicates that the media assigned to the vehicle is "for illustrative purposes only" and does not accurately represent the vehicle. */
  is_illustrative_media?: Maybe<Scalars['Boolean']>;
  is_listed?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_reserved_by?: Maybe<Scalars['uuid']>;
  is_reserved_until?: Maybe<Scalars['timestamptz']>;
  is_sold?: Maybe<Scalars['Boolean']>;
  is_template?: Maybe<Scalars['Boolean']>;
  is_trashed?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['numeric']>;
  location_id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  make_logo_src?: Maybe<Scalars['String']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  model_code?: Maybe<Scalars['String']>;
  motor_size?: Maybe<Scalars['numeric']>;
  /** a commonly used name for the vehicle */
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  /** An alphabetically sorted array of factory option code strings in ascending order. */
  option_codes?: Maybe<Scalars['jsonb']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes?: Maybe<Scalars['jsonb']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  packs?: Maybe<Scalars['String']>;
  powered_by_description?: Maybe<Scalars['String']>;
  powered_by_logo_src?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  registration_expiry_date?: Maybe<Scalars['date']>;
  registration_number?: Maybe<Scalars['String']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  safety_rating_authority?: Maybe<Scalars['String']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  series?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** a foreign reference to the source dataset */
  source_name?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  stock_id?: Maybe<Scalars['String']>;
  supplier_reference?: Maybe<Scalars['String']>;
  synced_from_source_at?: Maybe<Scalars['timestamptz']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  unmapped_option_codes?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  vehicle_specification_id?: Maybe<Scalars['uuid']>;
  vin?: Maybe<Scalars['String']>;
  wheel_code?: Maybe<Scalars['String']>;
  /** a short name describing the wheels fitted to the vehicle */
  wheel_name?: Maybe<Scalars['String']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Stocked_Vehicles_Sum_Fields = {
  __typename?: 'stocked_vehicles_sum_fields';
  acceleration_0_100_km?: Maybe<Scalars['numeric']>;
  accessories_warranty_distance?: Maybe<Scalars['numeric']>;
  accessories_warranty_years?: Maybe<Scalars['numeric']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['numeric']>;
  battery_warranty_distance?: Maybe<Scalars['numeric']>;
  battery_warranty_years?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_down?: Maybe<Scalars['numeric']>;
  boot_capacity_seats_up?: Maybe<Scalars['numeric']>;
  charge_power_ac?: Maybe<Scalars['numeric']>;
  charge_power_dc?: Maybe<Scalars['numeric']>;
  charge_speed_ac?: Maybe<Scalars['numeric']>;
  charge_speed_dc?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['numeric']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['numeric']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['numeric']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  doors?: Maybe<Scalars['Int']>;
  efficiency?: Maybe<Scalars['numeric']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Int']>;
  featured_order?: Maybe<Scalars['Int']>;
  gross_weight?: Maybe<Scalars['numeric']>;
  height?: Maybe<Scalars['numeric']>;
  length?: Maybe<Scalars['numeric']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['numeric']>;
  mechanical_warranty_distance?: Maybe<Scalars['numeric']>;
  mechanical_warranty_years?: Maybe<Scalars['numeric']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['numeric']>;
  motor_size?: Maybe<Scalars['numeric']>;
  number_of_airbags?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  option_codes_count?: Maybe<Scalars['Int']>;
  pack_codes_count?: Maybe<Scalars['Int']>;
  production_year?: Maybe<Scalars['numeric']>;
  range?: Maybe<Scalars['numeric']>;
  safety_rating?: Maybe<Scalars['numeric']>;
  sales_priority?: Maybe<Scalars['numeric']>;
  seats?: Maybe<Scalars['Int']>;
  top_speed?: Maybe<Scalars['numeric']>;
  torque?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['numeric']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Sum_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** update columns of table "stocked_vehicles" */
export enum Stocked_Vehicles_Update_Column {
  /** column name */
  Acceleration_0_100Km = 'acceleration_0_100_km',
  /** column name */
  AccessoriesWarrantyDistance = 'accessories_warranty_distance',
  /** column name */
  AccessoriesWarrantyYears = 'accessories_warranty_years',
  /** column name */
  AdditionalFeatures = 'additional_features',
  /** column name */
  AvailableFrom = 'available_from',
  /** column name */
  Badge = 'badge',
  /** column name */
  BadgeCode = 'badge_code',
  /** column name */
  BatteryHealth = 'battery_health',
  /** column name */
  BatteryType = 'battery_type',
  /** column name */
  BatteryWarrantyDistance = 'battery_warranty_distance',
  /** column name */
  BatteryWarrantyYears = 'battery_warranty_years',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  BootCapacitySeatsDown = 'boot_capacity_seats_down',
  /** column name */
  BootCapacitySeatsUp = 'boot_capacity_seats_up',
  /** column name */
  BuildDate = 'build_date',
  /** column name */
  ChargePortAc = 'charge_port_ac',
  /** column name */
  ChargePortDc = 'charge_port_dc',
  /** column name */
  ChargePortLocation = 'charge_port_location',
  /** column name */
  ChargePowerAc = 'charge_power_ac',
  /** column name */
  ChargePowerDc = 'charge_power_dc',
  /** column name */
  ChargeSpeedAc = 'charge_speed_ac',
  /** column name */
  ChargeSpeedDc = 'charge_speed_dc',
  /** column name */
  ChargeTimeAc = 'charge_time_ac',
  /** column name */
  ChargeTimeDc = 'charge_time_dc',
  /** column name */
  Code = 'code',
  /** column name */
  Colour = 'colour',
  /** column name */
  ColourCode = 'colour_code',
  /** column name */
  ComplianceDate = 'compliance_date',
  /** column name */
  Condition = 'condition',
  /** column name */
  CostOfGoods = 'cost_of_goods',
  /** column name */
  CostOfGoodsPaidAt = 'cost_of_goods_paid_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  DepositAmountPaidAt = 'deposit_amount_paid_at',
  /** column name */
  Description = 'description',
  /** column name */
  Doors = 'doors',
  /** column name */
  DriveType = 'drive_type',
  /** column name */
  Efficiency = 'efficiency',
  /** column name */
  EngineDetails = 'engine_details',
  /** column name */
  EngineNumber = 'engine_number',
  /** column name */
  EstimatedDeliveryDelay = 'estimated_delivery_delay',
  /** column name */
  ExcludeAutoRefreshAssets = 'exclude_auto_refresh_assets',
  /** column name */
  ExternalCtaDescription = 'external_cta_description',
  /** column name */
  ExternalCtaLabel = 'external_cta_label',
  /** column name */
  ExternalCtaUrl = 'external_cta_url',
  /** column name */
  FactoryOrderNo = 'factory_order_no',
  /** column name */
  FeaturedOrder = 'featured_order',
  /** column name */
  Features = 'features',
  /** column name */
  FeaturesVersion = 'features_version',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  GeneratedStockId = 'generated_stock_id',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  HasDiscount = 'has_discount',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IncludedHomeTrickleCharger = 'included_home_trickle_charger',
  /** column name */
  Interior = 'interior',
  /** column name */
  InteriorCode = 'interior_code',
  /** column name */
  IsAvailableForTestDrives = 'is_available_for_test_drives',
  /** column name */
  IsBasicPricing = 'is_basic_pricing',
  /** column name */
  IsEmployeeCar = 'is_employee_car',
  /** column name */
  IsIllustrativeMedia = 'is_illustrative_media',
  /** column name */
  IsListed = 'is_listed',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  IsReservedBy = 'is_reserved_by',
  /** column name */
  IsReservedUntil = 'is_reserved_until',
  /** column name */
  IsSold = 'is_sold',
  /** column name */
  IsTemplate = 'is_template',
  /** column name */
  IsTrashed = 'is_trashed',
  /** column name */
  Length = 'length',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Make = 'make',
  /** column name */
  MakeLogoSrc = 'make_logo_src',
  /** column name */
  MaxPrice = 'max_price',
  /** column name */
  MechanicalWarrantyDistance = 'mechanical_warranty_distance',
  /** column name */
  MechanicalWarrantyYears = 'mechanical_warranty_years',
  /** column name */
  MinPrice = 'min_price',
  /** column name */
  Model = 'model',
  /** column name */
  ModelCode = 'model_code',
  /** column name */
  MotorSize = 'motor_size',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  NumberOfAirbags = 'number_of_airbags',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OptionCodes = 'option_codes',
  /** column name */
  OptionCodesCount = 'option_codes_count',
  /** column name */
  PackCodes = 'pack_codes',
  /** column name */
  PackCodesCount = 'pack_codes_count',
  /** column name */
  Packs = 'packs',
  /** column name */
  PoweredByDescription = 'powered_by_description',
  /** column name */
  PoweredByLogoSrc = 'powered_by_logo_src',
  /** column name */
  PricingConfig = 'pricing_config',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Range = 'range',
  /** column name */
  RegistrationExpiryDate = 'registration_expiry_date',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  SafetyRating = 'safety_rating',
  /** column name */
  SafetyRatingAuthority = 'safety_rating_authority',
  /** column name */
  SalesPriority = 'sales_priority',
  /** column name */
  Seats = 'seats',
  /** column name */
  Series = 'series',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceName = 'source_name',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  StockId = 'stock_id',
  /** column name */
  SupplierReference = 'supplier_reference',
  /** column name */
  SyncedFromSourceAt = 'synced_from_source_at',
  /** column name */
  TopSpeed = 'top_speed',
  /** column name */
  Torque = 'torque',
  /** column name */
  Touched = 'touched',
  /** column name */
  TowingCapacityBraked = 'towing_capacity_braked',
  /** column name */
  TowingCapacityUnbraked = 'towing_capacity_unbraked',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UnmappedOptionCodes = 'unmapped_option_codes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UseableBattery = 'useable_battery',
  /** column name */
  VehicleSpecificationId = 'vehicle_specification_id',
  /** column name */
  Vin = 'vin',
  /** column name */
  WheelCode = 'wheel_code',
  /** column name */
  WheelName = 'wheel_name',
  /** column name */
  WheelSize = 'wheel_size',
  /** column name */
  Width = 'width'
}

export type Stocked_Vehicles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Stocked_Vehicles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Stocked_Vehicles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Stocked_Vehicles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Stocked_Vehicles_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stocked_Vehicles_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Stocked_Vehicles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stocked_Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stocked_Vehicles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stocked_Vehicles_Var_Pop_Fields = {
  __typename?: 'stocked_vehicles_var_pop_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Var_Pop_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stocked_Vehicles_Var_Samp_Fields = {
  __typename?: 'stocked_vehicles_var_samp_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Var_Samp_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stocked_Vehicles_Variance_Fields = {
  __typename?: 'stocked_vehicles_variance_fields';
  acceleration_0_100_km?: Maybe<Scalars['Float']>;
  accessories_warranty_distance?: Maybe<Scalars['Float']>;
  accessories_warranty_years?: Maybe<Scalars['Float']>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Scalars['Float']>;
  battery_warranty_distance?: Maybe<Scalars['Float']>;
  battery_warranty_years?: Maybe<Scalars['Float']>;
  boot_capacity_seats_down?: Maybe<Scalars['Float']>;
  boot_capacity_seats_up?: Maybe<Scalars['Float']>;
  charge_power_ac?: Maybe<Scalars['Float']>;
  charge_power_dc?: Maybe<Scalars['Float']>;
  charge_speed_ac?: Maybe<Scalars['Float']>;
  charge_speed_dc?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_ac?: Maybe<Scalars['Float']>;
  /** In minutes */
  charge_time_dc?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Scalars['Float']>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Scalars['Float']>;
  doors?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Scalars['Float']>;
  featured_order?: Maybe<Scalars['Float']>;
  gross_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Scalars['Float']>;
  mechanical_warranty_distance?: Maybe<Scalars['Float']>;
  mechanical_warranty_years?: Maybe<Scalars['Float']>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Scalars['Float']>;
  motor_size?: Maybe<Scalars['Float']>;
  number_of_airbags?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  option_codes_count?: Maybe<Scalars['Float']>;
  pack_codes_count?: Maybe<Scalars['Float']>;
  production_year?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['Float']>;
  safety_rating?: Maybe<Scalars['Float']>;
  sales_priority?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  top_speed?: Maybe<Scalars['Float']>;
  torque?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Scalars['Float']>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stocked_vehicles" */
export type Stocked_Vehicles_Variance_Order_By = {
  acceleration_0_100_km?: Maybe<Order_By>;
  accessories_warranty_distance?: Maybe<Order_By>;
  accessories_warranty_years?: Maybe<Order_By>;
  /** a percentage indicating the battery's state of health. 100 = new */
  battery_health?: Maybe<Order_By>;
  battery_warranty_distance?: Maybe<Order_By>;
  battery_warranty_years?: Maybe<Order_By>;
  boot_capacity_seats_down?: Maybe<Order_By>;
  boot_capacity_seats_up?: Maybe<Order_By>;
  charge_power_ac?: Maybe<Order_By>;
  charge_power_dc?: Maybe<Order_By>;
  charge_speed_ac?: Maybe<Order_By>;
  charge_speed_dc?: Maybe<Order_By>;
  /** In minutes */
  charge_time_ac?: Maybe<Order_By>;
  /** In minutes */
  charge_time_dc?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when purchasing this vehicle. */
  cost_of_goods?: Maybe<Order_By>;
  /** The total price Vyro has / will pay when placing a deposit on this vehicle. */
  deposit_amount?: Maybe<Order_By>;
  doors?: Maybe<Order_By>;
  efficiency?: Maybe<Order_By>;
  /** the number of days between today and the vehicle arriving */
  estimated_delivery_delay?: Maybe<Order_By>;
  featured_order?: Maybe<Order_By>;
  gross_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  /** The maximum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  max_price?: Maybe<Order_By>;
  mechanical_warranty_distance?: Maybe<Order_By>;
  mechanical_warranty_years?: Maybe<Order_By>;
  /** The minimum drive away price for this vehicle across all of the vehicle's associated pricing codes. */
  min_price?: Maybe<Order_By>;
  motor_size?: Maybe<Order_By>;
  number_of_airbags?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  option_codes_count?: Maybe<Order_By>;
  pack_codes_count?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  range?: Maybe<Order_By>;
  safety_rating?: Maybe<Order_By>;
  sales_priority?: Maybe<Order_By>;
  seats?: Maybe<Order_By>;
  top_speed?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  /** The 'Battery capacity' shows the actual size of the battery. NOTE: You can never use 100% of a battery's capacity, so OEMs will always list 'Battery capacity' and 'Useable battery', even for brand new vehicles. */
  useable_battery?: Maybe<Order_By>;
  /** the size of the wheels in inches */
  wheel_size?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activities_aggregate: Activities_Aggregate;
  /** fetch data from the table: "activities" using primary key columns */
  activities_by_pk?: Maybe<Activities>;
  /** fetch data from the table in a streaming manner: "activities" */
  activities_stream: Array<Activities>;
  /** An array relationship */
  api_clients: Array<Api_Clients>;
  /** An aggregate relationship */
  api_clients_aggregate: Api_Clients_Aggregate;
  /** fetch data from the table: "api_clients" using primary key columns */
  api_clients_by_pk?: Maybe<Api_Clients>;
  /** fetch data from the table in a streaming manner: "api_clients" */
  api_clients_stream: Array<Api_Clients>;
  /** An array relationship */
  app_actions: Array<App_Actions>;
  /** An aggregate relationship */
  app_actions_aggregate: App_Actions_Aggregate;
  /** fetch data from the table: "app_actions" using primary key columns */
  app_actions_by_pk?: Maybe<App_Actions>;
  /** fetch data from the table in a streaming manner: "app_actions" */
  app_actions_stream: Array<App_Actions>;
  /** An array relationship */
  app_events: Array<App_Events>;
  /** An aggregate relationship */
  app_events_aggregate: App_Events_Aggregate;
  /** fetch data from the table: "app_events" using primary key columns */
  app_events_by_pk?: Maybe<App_Events>;
  /** fetch data from the table in a streaming manner: "app_events" */
  app_events_stream: Array<App_Events>;
  /** An array relationship */
  app_inputs: Array<App_Inputs>;
  /** An aggregate relationship */
  app_inputs_aggregate: App_Inputs_Aggregate;
  /** fetch data from the table: "app_inputs" using primary key columns */
  app_inputs_by_pk?: Maybe<App_Inputs>;
  /** fetch data from the table in a streaming manner: "app_inputs" */
  app_inputs_stream: Array<App_Inputs>;
  /** An array relationship */
  app_versions: Array<App_Versions>;
  /** An aggregate relationship */
  app_versions_aggregate: App_Versions_Aggregate;
  /** fetch data from the table: "app_versions" using primary key columns */
  app_versions_by_pk?: Maybe<App_Versions>;
  /** fetch data from the table in a streaming manner: "app_versions" */
  app_versions_stream: Array<App_Versions>;
  /** fetch data from the table: "apps" */
  apps: Array<Apps>;
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: Apps_Aggregate;
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>;
  /** fetch data from the table in a streaming manner: "apps" */
  apps_stream: Array<Apps>;
  /** An array relationship */
  articles: Array<Articles>;
  /** An aggregate relationship */
  articles_aggregate: Articles_Aggregate;
  /** fetch data from the table: "articles" using primary key columns */
  articles_by_pk?: Maybe<Articles>;
  /** fetch data from the table in a streaming manner: "articles" */
  articles_stream: Array<Articles>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  /** fetch data from the table: "assets" using primary key columns */
  assets_by_pk?: Maybe<Assets>;
  /** fetch data from the table in a streaming manner: "assets" */
  assets_stream: Array<Assets>;
  /** An array relationship */
  auction_bids: Array<Auction_Bids>;
  /** An aggregate relationship */
  auction_bids_aggregate: Auction_Bids_Aggregate;
  /** fetch data from the table: "auction_bids" using primary key columns */
  auction_bids_by_pk?: Maybe<Auction_Bids>;
  /** fetch data from the table in a streaming manner: "auction_bids" */
  auction_bids_stream: Array<Auction_Bids>;
  /** An array relationship */
  auctions: Array<Auctions>;
  /** An aggregate relationship */
  auctions_aggregate: Auctions_Aggregate;
  /** fetch data from the table: "auctions" using primary key columns */
  auctions_by_pk?: Maybe<Auctions>;
  /** fetch data from the table in a streaming manner: "auctions" */
  auctions_stream: Array<Auctions>;
  /** fetch data from the table: "calculator_submission" */
  calculator_submission: Array<Calculator_Submission>;
  /** fetch aggregated fields from the table: "calculator_submission" */
  calculator_submission_aggregate: Calculator_Submission_Aggregate;
  /** fetch data from the table: "calculator_submission" using primary key columns */
  calculator_submission_by_pk?: Maybe<Calculator_Submission>;
  /** fetch data from the table in a streaming manner: "calculator_submission" */
  calculator_submission_stream: Array<Calculator_Submission>;
  /** An array relationship */
  custom_field_values: Array<Custom_Field_Values>;
  /** An aggregate relationship */
  custom_field_values_aggregate: Custom_Field_Values_Aggregate;
  /** fetch data from the table: "custom_field_values" using primary key columns */
  custom_field_values_by_pk?: Maybe<Custom_Field_Values>;
  /** fetch data from the table in a streaming manner: "custom_field_values" */
  custom_field_values_stream: Array<Custom_Field_Values>;
  /** An array relationship */
  custom_fields: Array<Custom_Fields>;
  /** An aggregate relationship */
  custom_fields_aggregate: Custom_Fields_Aggregate;
  /** fetch data from the table: "custom_fields" using primary key columns */
  custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** fetch data from the table in a streaming manner: "custom_fields" */
  custom_fields_stream: Array<Custom_Fields>;
  /** An array relationship */
  custom_vehicles: Array<Custom_Vehicles>;
  /** An aggregate relationship */
  custom_vehicles_aggregate: Custom_Vehicles_Aggregate;
  /** fetch data from the table: "custom_vehicles" using primary key columns */
  custom_vehicles_by_pk?: Maybe<Custom_Vehicles>;
  /** fetch data from the table in a streaming manner: "custom_vehicles" */
  custom_vehicles_stream: Array<Custom_Vehicles>;
  /** An array relationship */
  filter_fields: Array<Filter_Fields>;
  /** An aggregate relationship */
  filter_fields_aggregate: Filter_Fields_Aggregate;
  /** fetch data from the table: "filter_fields" using primary key columns */
  filter_fields_by_pk?: Maybe<Filter_Fields>;
  /** fetch data from the table in a streaming manner: "filter_fields" */
  filter_fields_stream: Array<Filter_Fields>;
  /** fetch data from the table: "filter_locations" */
  filter_locations: Array<Filter_Locations>;
  /** fetch aggregated fields from the table: "filter_locations" */
  filter_locations_aggregate: Filter_Locations_Aggregate;
  /** fetch data from the table: "filter_locations" using primary key columns */
  filter_locations_by_pk?: Maybe<Filter_Locations>;
  /** fetch data from the table in a streaming manner: "filter_locations" */
  filter_locations_stream: Array<Filter_Locations>;
  /** fetch data from the table: "filter_names" */
  filter_names: Array<Filter_Names>;
  /** fetch aggregated fields from the table: "filter_names" */
  filter_names_aggregate: Filter_Names_Aggregate;
  /** fetch data from the table: "filter_names" using primary key columns */
  filter_names_by_pk?: Maybe<Filter_Names>;
  /** fetch data from the table in a streaming manner: "filter_names" */
  filter_names_stream: Array<Filter_Names>;
  /** An array relationship */
  finance_applications: Array<Finance_Applications>;
  /** An aggregate relationship */
  finance_applications_aggregate: Finance_Applications_Aggregate;
  /** fetch data from the table: "finance_applications" using primary key columns */
  finance_applications_by_pk?: Maybe<Finance_Applications>;
  /** fetch data from the table in a streaming manner: "finance_applications" */
  finance_applications_stream: Array<Finance_Applications>;
  /** An array relationship */
  finance_fees: Array<Finance_Fees>;
  /** An aggregate relationship */
  finance_fees_aggregate: Finance_Fees_Aggregate;
  /** fetch data from the table: "finance_fees" using primary key columns */
  finance_fees_by_pk?: Maybe<Finance_Fees>;
  /** fetch data from the table in a streaming manner: "finance_fees" */
  finance_fees_stream: Array<Finance_Fees>;
  /** fetch data from the table: "finance_fixed_rate_configurations" */
  finance_fixed_rate_configurations: Array<Finance_Fixed_Rate_Configurations>;
  /** fetch aggregated fields from the table: "finance_fixed_rate_configurations" */
  finance_fixed_rate_configurations_aggregate: Finance_Fixed_Rate_Configurations_Aggregate;
  /** fetch data from the table: "finance_fixed_rate_configurations" using primary key columns */
  finance_fixed_rate_configurations_by_pk?: Maybe<Finance_Fixed_Rate_Configurations>;
  /** fetch data from the table in a streaming manner: "finance_fixed_rate_configurations" */
  finance_fixed_rate_configurations_stream: Array<Finance_Fixed_Rate_Configurations>;
  /** fetch data from the table: "finance_frequency" */
  finance_frequency: Array<Finance_Frequency>;
  /** fetch aggregated fields from the table: "finance_frequency" */
  finance_frequency_aggregate: Finance_Frequency_Aggregate;
  /** fetch data from the table: "finance_frequency" using primary key columns */
  finance_frequency_by_pk?: Maybe<Finance_Frequency>;
  /** fetch data from the table in a streaming manner: "finance_frequency" */
  finance_frequency_stream: Array<Finance_Frequency>;
  /** fetch data from the table: "finance_gfv_configurations" */
  finance_gfv_configurations: Array<Finance_Gfv_Configurations>;
  /** fetch aggregated fields from the table: "finance_gfv_configurations" */
  finance_gfv_configurations_aggregate: Finance_Gfv_Configurations_Aggregate;
  /** fetch data from the table: "finance_gfv_configurations" using primary key columns */
  finance_gfv_configurations_by_pk?: Maybe<Finance_Gfv_Configurations>;
  /** fetch data from the table in a streaming manner: "finance_gfv_configurations" */
  finance_gfv_configurations_stream: Array<Finance_Gfv_Configurations>;
  /** fetch data from the table: "finance_gfv_residual_values" */
  finance_gfv_residual_values: Array<Finance_Gfv_Residual_Values>;
  /** fetch aggregated fields from the table: "finance_gfv_residual_values" */
  finance_gfv_residual_values_aggregate: Finance_Gfv_Residual_Values_Aggregate;
  /** fetch data from the table: "finance_gfv_residual_values" using primary key columns */
  finance_gfv_residual_values_by_pk?: Maybe<Finance_Gfv_Residual_Values>;
  /** fetch data from the table in a streaming manner: "finance_gfv_residual_values" */
  finance_gfv_residual_values_stream: Array<Finance_Gfv_Residual_Values>;
  /** An array relationship */
  finance_product_offers: Array<Finance_Product_Offers>;
  /** An aggregate relationship */
  finance_product_offers_aggregate: Finance_Product_Offers_Aggregate;
  /** fetch data from the table: "finance_product_offers" using primary key columns */
  finance_product_offers_by_pk?: Maybe<Finance_Product_Offers>;
  /** fetch data from the table in a streaming manner: "finance_product_offers" */
  finance_product_offers_stream: Array<Finance_Product_Offers>;
  /** fetch data from the table: "finance_product_types" */
  finance_product_types: Array<Finance_Product_Types>;
  /** fetch aggregated fields from the table: "finance_product_types" */
  finance_product_types_aggregate: Finance_Product_Types_Aggregate;
  /** fetch data from the table: "finance_product_types" using primary key columns */
  finance_product_types_by_pk?: Maybe<Finance_Product_Types>;
  /** fetch data from the table in a streaming manner: "finance_product_types" */
  finance_product_types_stream: Array<Finance_Product_Types>;
  /** An array relationship */
  finance_products: Array<Finance_Products>;
  /** An aggregate relationship */
  finance_products_aggregate: Finance_Products_Aggregate;
  /** fetch data from the table: "finance_products" using primary key columns */
  finance_products_by_pk?: Maybe<Finance_Products>;
  /** fetch data from the table in a streaming manner: "finance_products" */
  finance_products_stream: Array<Finance_Products>;
  /** An array relationship */
  finance_terms: Array<Finance_Terms>;
  /** An aggregate relationship */
  finance_terms_aggregate: Finance_Terms_Aggregate;
  /** fetch data from the table: "finance_terms" using primary key columns */
  finance_terms_by_pk?: Maybe<Finance_Terms>;
  /** fetch data from the table in a streaming manner: "finance_terms" */
  finance_terms_stream: Array<Finance_Terms>;
  /** An array relationship */
  form_fields: Array<Form_Fields>;
  /** An aggregate relationship */
  form_fields_aggregate: Form_Fields_Aggregate;
  /** fetch data from the table: "form_fields" using primary key columns */
  form_fields_by_pk?: Maybe<Form_Fields>;
  /** fetch data from the table in a streaming manner: "form_fields" */
  form_fields_stream: Array<Form_Fields>;
  /** An array relationship */
  form_submissions: Array<Form_Submissions>;
  /** An aggregate relationship */
  form_submissions_aggregate: Form_Submissions_Aggregate;
  /** fetch data from the table: "form_submissions" using primary key columns */
  form_submissions_by_pk?: Maybe<Form_Submissions>;
  /** fetch data from the table in a streaming manner: "form_submissions" */
  form_submissions_stream: Array<Form_Submissions>;
  /** An array relationship */
  forms: Array<Forms>;
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  /** fetch data from the table in a streaming manner: "forms" */
  forms_stream: Array<Forms>;
  /** fetch data from the table: "insights_click_events" */
  insights_click_events: Array<Insights_Click_Events>;
  /** fetch aggregated fields from the table: "insights_click_events" */
  insights_click_events_aggregate: Insights_Click_Events_Aggregate;
  /** fetch data from the table: "insights_click_events" using primary key columns */
  insights_click_events_by_pk?: Maybe<Insights_Click_Events>;
  /** fetch data from the table in a streaming manner: "insights_click_events" */
  insights_click_events_stream: Array<Insights_Click_Events>;
  /** fetch data from the table: "insights_devices" */
  insights_devices: Array<Insights_Devices>;
  /** fetch aggregated fields from the table: "insights_devices" */
  insights_devices_aggregate: Insights_Devices_Aggregate;
  /** fetch data from the table: "insights_devices" using primary key columns */
  insights_devices_by_pk?: Maybe<Insights_Devices>;
  /** fetch data from the table in a streaming manner: "insights_devices" */
  insights_devices_stream: Array<Insights_Devices>;
  /** fetch data from the table: "insights_event_types" */
  insights_event_types: Array<Insights_Event_Types>;
  /** fetch aggregated fields from the table: "insights_event_types" */
  insights_event_types_aggregate: Insights_Event_Types_Aggregate;
  /** fetch data from the table: "insights_event_types" using primary key columns */
  insights_event_types_by_pk?: Maybe<Insights_Event_Types>;
  /** fetch data from the table in a streaming manner: "insights_event_types" */
  insights_event_types_stream: Array<Insights_Event_Types>;
  /** An array relationship */
  insights_events: Array<Insights_Events>;
  /** An aggregate relationship */
  insights_events_aggregate: Insights_Events_Aggregate;
  /** fetch data from the table: "insights_events" using primary key columns */
  insights_events_by_pk?: Maybe<Insights_Events>;
  /** fetch data from the table in a streaming manner: "insights_events" */
  insights_events_stream: Array<Insights_Events>;
  /** fetch data from the table: "insights_page_view_events" */
  insights_page_view_events: Array<Insights_Page_View_Events>;
  /** fetch aggregated fields from the table: "insights_page_view_events" */
  insights_page_view_events_aggregate: Insights_Page_View_Events_Aggregate;
  /** fetch data from the table: "insights_page_view_events" using primary key columns */
  insights_page_view_events_by_pk?: Maybe<Insights_Page_View_Events>;
  /** fetch data from the table in a streaming manner: "insights_page_view_events" */
  insights_page_view_events_stream: Array<Insights_Page_View_Events>;
  /** fetch data from the table: "insights_sessions" */
  insights_sessions: Array<Insights_Sessions>;
  /** fetch aggregated fields from the table: "insights_sessions" */
  insights_sessions_aggregate: Insights_Sessions_Aggregate;
  /** fetch data from the table: "insights_sessions" using primary key columns */
  insights_sessions_by_pk?: Maybe<Insights_Sessions>;
  /** fetch data from the table in a streaming manner: "insights_sessions" */
  insights_sessions_stream: Array<Insights_Sessions>;
  insights_visitors: Array<Insights_Visitors>;
  insights_visitors_aggregate: Insights_Visitors_Aggregate;
  /** fetch data from the table: "insights_visitors" using primary key columns */
  insights_visitors_by_pk?: Maybe<Insights_Visitors>;
  /** fetch data from the table in a streaming manner: "insights_visitors" */
  insights_visitors_stream: Array<Insights_Visitors>;
  /** An array relationship */
  interests: Array<Interests>;
  /** An aggregate relationship */
  interests_aggregate: Interests_Aggregate;
  /** fetch data from the table: "interests" using primary key columns */
  interests_by_pk?: Maybe<Interests>;
  /** fetch data from the table in a streaming manner: "interests" */
  interests_stream: Array<Interests>;
  /** fetch data from the table: "known_vehicles" */
  known_vehicles: Array<Known_Vehicles>;
  /** fetch aggregated fields from the table: "known_vehicles" */
  known_vehicles_aggregate: Known_Vehicles_Aggregate;
  /** fetch data from the table: "known_vehicles" using primary key columns */
  known_vehicles_by_pk?: Maybe<Known_Vehicles>;
  /** fetch data from the table in a streaming manner: "known_vehicles" */
  known_vehicles_stream: Array<Known_Vehicles>;
  /** fetch data from the table: "locales" */
  locales: Array<Locales>;
  /** fetch aggregated fields from the table: "locales" */
  locales_aggregate: Locales_Aggregate;
  /** fetch data from the table: "locales" using primary key columns */
  locales_by_pk?: Maybe<Locales>;
  /** fetch data from the table in a streaming manner: "locales" */
  locales_stream: Array<Locales>;
  /** An array relationship */
  location_service_types: Array<Location_Service_Types>;
  /** An aggregate relationship */
  location_service_types_aggregate: Location_Service_Types_Aggregate;
  /** fetch data from the table: "location_service_types" using primary key columns */
  location_service_types_by_pk?: Maybe<Location_Service_Types>;
  /** fetch data from the table in a streaming manner: "location_service_types" */
  location_service_types_stream: Array<Location_Service_Types>;
  /** An array relationship */
  location_transport_types: Array<Location_Transport_Types>;
  /** An aggregate relationship */
  location_transport_types_aggregate: Location_Transport_Types_Aggregate;
  /** fetch data from the table: "location_transport_types" using primary key columns */
  location_transport_types_by_pk?: Maybe<Location_Transport_Types>;
  /** fetch data from the table in a streaming manner: "location_transport_types" */
  location_transport_types_stream: Array<Location_Transport_Types>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** fetch data from the table: "match_submissions" */
  match_submissions: Array<Match_Submissions>;
  /** fetch aggregated fields from the table: "match_submissions" */
  match_submissions_aggregate: Match_Submissions_Aggregate;
  /** fetch data from the table: "match_submissions" using primary key columns */
  match_submissions_by_pk?: Maybe<Match_Submissions>;
  /** fetch data from the table in a streaming manner: "match_submissions" */
  match_submissions_stream: Array<Match_Submissions>;
  /** An array relationship */
  moments: Array<Moments>;
  /** An aggregate relationship */
  moments_aggregate: Moments_Aggregate;
  /** fetch data from the table: "moments" using primary key columns */
  moments_by_pk?: Maybe<Moments>;
  /** fetch data from the table in a streaming manner: "moments" */
  moments_stream: Array<Moments>;
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** fetch data from the table in a streaming manner: "notes" */
  notes_stream: Array<Notes>;
  /** fetch data from the table: "offer_types" */
  offer_types: Array<Offer_Types>;
  /** fetch aggregated fields from the table: "offer_types" */
  offer_types_aggregate: Offer_Types_Aggregate;
  /** fetch data from the table: "offer_types" using primary key columns */
  offer_types_by_pk?: Maybe<Offer_Types>;
  /** fetch data from the table in a streaming manner: "offer_types" */
  offer_types_stream: Array<Offer_Types>;
  /** fetch data from the table: "offers" */
  offers: Array<Offers>;
  /** fetch aggregated fields from the table: "offers" */
  offers_aggregate: Offers_Aggregate;
  /** fetch data from the table: "offers" using primary key columns */
  offers_by_pk?: Maybe<Offers>;
  /** fetch data from the table in a streaming manner: "offers" */
  offers_stream: Array<Offers>;
  /** An array relationship */
  pages: Array<Pages>;
  /** An aggregate relationship */
  pages_aggregate: Pages_Aggregate;
  /** fetch data from the table: "pages" using primary key columns */
  pages_by_pk?: Maybe<Pages>;
  /** fetch data from the table in a streaming manner: "pages" */
  pages_stream: Array<Pages>;
  /** fetch data from the table: "partners" */
  partners: Array<Partners>;
  /** fetch aggregated fields from the table: "partners" */
  partners_aggregate: Partners_Aggregate;
  /** fetch data from the table: "partners" using primary key columns */
  partners_by_pk?: Maybe<Partners>;
  /** fetch data from the table in a streaming manner: "partners" */
  partners_stream: Array<Partners>;
  /** fetch data from the table: "payment_codes" */
  payment_codes: Array<Payment_Codes>;
  /** fetch aggregated fields from the table: "payment_codes" */
  payment_codes_aggregate: Payment_Codes_Aggregate;
  /** fetch data from the table: "payment_codes" using primary key columns */
  payment_codes_by_pk?: Maybe<Payment_Codes>;
  /** fetch data from the table in a streaming manner: "payment_codes" */
  payment_codes_stream: Array<Payment_Codes>;
  /** fetch data from the table: "payment_method_types" */
  payment_method_types: Array<Payment_Method_Types>;
  /** fetch aggregated fields from the table: "payment_method_types" */
  payment_method_types_aggregate: Payment_Method_Types_Aggregate;
  /** fetch data from the table: "payment_method_types" using primary key columns */
  payment_method_types_by_pk?: Maybe<Payment_Method_Types>;
  /** fetch data from the table in a streaming manner: "payment_method_types" */
  payment_method_types_stream: Array<Payment_Method_Types>;
  /** An array relationship */
  payment_methods: Array<Payment_Methods>;
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** fetch data from the table in a streaming manner: "payment_methods" */
  payment_methods_stream: Array<Payment_Methods>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table in a streaming manner: "payments" */
  payments_stream: Array<Payments>;
  /** fetch data from the table: "personal_vehicles" */
  personal_vehicles: Array<Personal_Vehicles>;
  /** fetch aggregated fields from the table: "personal_vehicles" */
  personal_vehicles_aggregate: Personal_Vehicles_Aggregate;
  /** fetch data from the table: "personal_vehicles" using primary key columns */
  personal_vehicles_by_pk?: Maybe<Personal_Vehicles>;
  /** fetch data from the table in a streaming manner: "personal_vehicles" */
  personal_vehicles_stream: Array<Personal_Vehicles>;
  /** fetch data from the table: "pricing_codes" */
  pricing_codes: Array<Pricing_Codes>;
  /** fetch aggregated fields from the table: "pricing_codes" */
  pricing_codes_aggregate: Pricing_Codes_Aggregate;
  /** fetch data from the table: "pricing_codes" using primary key columns */
  pricing_codes_by_pk?: Maybe<Pricing_Codes>;
  /** fetch data from the table in a streaming manner: "pricing_codes" */
  pricing_codes_stream: Array<Pricing_Codes>;
  /** An array relationship */
  product_groups: Array<Product_Groups>;
  /** An aggregate relationship */
  product_groups_aggregate: Product_Groups_Aggregate;
  /** fetch data from the table: "product_groups" using primary key columns */
  product_groups_by_pk?: Maybe<Product_Groups>;
  /** fetch data from the table in a streaming manner: "product_groups" */
  product_groups_stream: Array<Product_Groups>;
  /** An array relationship */
  product_variants: Array<Product_Variants>;
  /** An aggregate relationship */
  product_variants_aggregate: Product_Variants_Aggregate;
  /** fetch data from the table: "product_variants" using primary key columns */
  product_variants_by_pk?: Maybe<Product_Variants>;
  /** fetch data from the table in a streaming manner: "product_variants" */
  product_variants_stream: Array<Product_Variants>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "promotions" */
  promotions: Array<Promotions>;
  /** fetch aggregated fields from the table: "promotions" */
  promotions_aggregate: Promotions_Aggregate;
  /** fetch data from the table: "promotions" using primary key columns */
  promotions_by_pk?: Maybe<Promotions>;
  /** fetch data from the table in a streaming manner: "promotions" */
  promotions_stream: Array<Promotions>;
  /** fetch data from the table: "redirects" */
  redirects: Array<Redirects>;
  /** fetch aggregated fields from the table: "redirects" */
  redirects_aggregate: Redirects_Aggregate;
  /** fetch data from the table: "redirects" using primary key columns */
  redirects_by_pk?: Maybe<Redirects>;
  /** fetch data from the table in a streaming manner: "redirects" */
  redirects_stream: Array<Redirects>;
  /** An array relationship */
  rental_drivers: Array<Rental_Drivers>;
  /** An aggregate relationship */
  rental_drivers_aggregate: Rental_Drivers_Aggregate;
  /** fetch data from the table: "rental_drivers" using primary key columns */
  rental_drivers_by_pk?: Maybe<Rental_Drivers>;
  /** fetch data from the table in a streaming manner: "rental_drivers" */
  rental_drivers_stream: Array<Rental_Drivers>;
  /** fetch data from the table: "rental_reservation_status" */
  rental_reservation_status: Array<Rental_Reservation_Status>;
  /** fetch aggregated fields from the table: "rental_reservation_status" */
  rental_reservation_status_aggregate: Rental_Reservation_Status_Aggregate;
  /** fetch data from the table: "rental_reservation_status" using primary key columns */
  rental_reservation_status_by_pk?: Maybe<Rental_Reservation_Status>;
  /** fetch data from the table in a streaming manner: "rental_reservation_status" */
  rental_reservation_status_stream: Array<Rental_Reservation_Status>;
  /** An array relationship */
  rental_reservations: Array<Rental_Reservations>;
  /** An aggregate relationship */
  rental_reservations_aggregate: Rental_Reservations_Aggregate;
  /** fetch data from the table: "rental_reservations" using primary key columns */
  rental_reservations_by_pk?: Maybe<Rental_Reservations>;
  /** fetch data from the table in a streaming manner: "rental_reservations" */
  rental_reservations_stream: Array<Rental_Reservations>;
  /** execute function "search_articles" which returns "articles" */
  search_articles: Array<Articles>;
  /** execute function "search_articles" and query aggregates on result of table type "articles" */
  search_articles_aggregate: Articles_Aggregate;
  /** execute function "search_known_vehicles" which returns "known_vehicles" */
  search_known_vehicles: Array<Known_Vehicles>;
  /** execute function "search_known_vehicles" and query aggregates on result of table type "known_vehicles" */
  search_known_vehicles_aggregate: Known_Vehicles_Aggregate;
  /** execute function "search_rental_reservations" which returns "rental_reservations" */
  search_rental_reservations: Array<Rental_Reservations>;
  /** execute function "search_rental_reservations" and query aggregates on result of table type "rental_reservations" */
  search_rental_reservations_aggregate: Rental_Reservations_Aggregate;
  /** execute function "search_stocked_vehicles" which returns "stocked_vehicles" */
  search_stocked_vehicles: Array<Stocked_Vehicles>;
  /** execute function "search_stocked_vehicles" and query aggregates on result of table type "stocked_vehicles" */
  search_stocked_vehicles_aggregate: Stocked_Vehicles_Aggregate;
  /** An array relationship */
  service_booking_service_types: Array<Service_Booking_Service_Types>;
  /** An aggregate relationship */
  service_booking_service_types_aggregate: Service_Booking_Service_Types_Aggregate;
  /** fetch data from the table: "service_booking_service_types" using primary key columns */
  service_booking_service_types_by_pk?: Maybe<Service_Booking_Service_Types>;
  /** fetch data from the table in a streaming manner: "service_booking_service_types" */
  service_booking_service_types_stream: Array<Service_Booking_Service_Types>;
  /** An array relationship */
  service_bookings: Array<Service_Bookings>;
  /** An aggregate relationship */
  service_bookings_aggregate: Service_Bookings_Aggregate;
  /** fetch data from the table: "service_bookings" using primary key columns */
  service_bookings_by_pk?: Maybe<Service_Bookings>;
  /** fetch data from the table in a streaming manner: "service_bookings" */
  service_bookings_stream: Array<Service_Bookings>;
  /** fetch data from the table: "service_types" */
  service_types: Array<Service_Types>;
  /** fetch aggregated fields from the table: "service_types" */
  service_types_aggregate: Service_Types_Aggregate;
  /** fetch data from the table: "service_types" using primary key columns */
  service_types_by_pk?: Maybe<Service_Types>;
  /** fetch data from the table in a streaming manner: "service_types" */
  service_types_stream: Array<Service_Types>;
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** fetch data from the table in a streaming manner: "sessions" */
  sessions_stream: Array<Sessions>;
  /** An array relationship */
  showroom_apps: Array<Showroom_Apps>;
  /** An aggregate relationship */
  showroom_apps_aggregate: Showroom_Apps_Aggregate;
  /** fetch data from the table: "showroom_apps" using primary key columns */
  showroom_apps_by_pk?: Maybe<Showroom_Apps>;
  /** An array relationship */
  showroom_apps_inputs: Array<Showroom_Apps_Inputs>;
  /** An aggregate relationship */
  showroom_apps_inputs_aggregate: Showroom_Apps_Inputs_Aggregate;
  /** fetch data from the table: "showroom_apps_inputs" using primary key columns */
  showroom_apps_inputs_by_pk?: Maybe<Showroom_Apps_Inputs>;
  /** fetch data from the table in a streaming manner: "showroom_apps_inputs" */
  showroom_apps_inputs_stream: Array<Showroom_Apps_Inputs>;
  /** fetch data from the table in a streaming manner: "showroom_apps" */
  showroom_apps_stream: Array<Showroom_Apps>;
  /** An array relationship */
  showroom_locales: Array<Showroom_Locales>;
  /** An aggregate relationship */
  showroom_locales_aggregate: Showroom_Locales_Aggregate;
  /** fetch data from the table: "showroom_locales" using primary key columns */
  showroom_locales_by_pk?: Maybe<Showroom_Locales>;
  /** fetch data from the table in a streaming manner: "showroom_locales" */
  showroom_locales_stream: Array<Showroom_Locales>;
  /** An array relationship */
  showroom_pricing_codes: Array<Showroom_Pricing_Codes>;
  /** An aggregate relationship */
  showroom_pricing_codes_aggregate: Showroom_Pricing_Codes_Aggregate;
  /** fetch data from the table: "showroom_pricing_codes" using primary key columns */
  showroom_pricing_codes_by_pk?: Maybe<Showroom_Pricing_Codes>;
  /** fetch data from the table in a streaming manner: "showroom_pricing_codes" */
  showroom_pricing_codes_stream: Array<Showroom_Pricing_Codes>;
  /** An array relationship */
  showroom_stocked_vehicles: Array<Showroom_Stocked_Vehicles>;
  /** An aggregate relationship */
  showroom_stocked_vehicles_aggregate: Showroom_Stocked_Vehicles_Aggregate;
  /** fetch data from the table: "showroom_stocked_vehicles" using primary key columns */
  showroom_stocked_vehicles_by_pk?: Maybe<Showroom_Stocked_Vehicles>;
  /** fetch data from the table in a streaming manner: "showroom_stocked_vehicles" */
  showroom_stocked_vehicles_stream: Array<Showroom_Stocked_Vehicles>;
  /** An array relationship */
  showroom_users: Array<Showroom_Users>;
  /** An aggregate relationship */
  showroom_users_aggregate: Showroom_Users_Aggregate;
  /** fetch data from the table: "showroom_users" using primary key columns */
  showroom_users_by_pk?: Maybe<Showroom_Users>;
  /** fetch data from the table in a streaming manner: "showroom_users" */
  showroom_users_stream: Array<Showroom_Users>;
  /** An array relationship */
  showrooms: Array<Showrooms>;
  /** An aggregate relationship */
  showrooms_aggregate: Showrooms_Aggregate;
  /** fetch data from the table: "showrooms" using primary key columns */
  showrooms_by_pk?: Maybe<Showrooms>;
  /** fetch data from the table: "showrooms_partners" */
  showrooms_partners: Array<Showrooms_Partners>;
  /** fetch aggregated fields from the table: "showrooms_partners" */
  showrooms_partners_aggregate: Showrooms_Partners_Aggregate;
  /** fetch data from the table: "showrooms_partners" using primary key columns */
  showrooms_partners_by_pk?: Maybe<Showrooms_Partners>;
  /** fetch data from the table in a streaming manner: "showrooms_partners" */
  showrooms_partners_stream: Array<Showrooms_Partners>;
  /** An array relationship */
  showrooms_promotions: Array<Showrooms_Promotions>;
  /** An aggregate relationship */
  showrooms_promotions_aggregate: Showrooms_Promotions_Aggregate;
  /** fetch data from the table: "showrooms_promotions" using primary key columns */
  showrooms_promotions_by_pk?: Maybe<Showrooms_Promotions>;
  /** fetch data from the table in a streaming manner: "showrooms_promotions" */
  showrooms_promotions_stream: Array<Showrooms_Promotions>;
  /** fetch data from the table in a streaming manner: "showrooms" */
  showrooms_stream: Array<Showrooms>;
  /** An array relationship */
  stocked_vehicle_finance_products: Array<Stocked_Vehicle_Finance_Products>;
  /** An aggregate relationship */
  stocked_vehicle_finance_products_aggregate: Stocked_Vehicle_Finance_Products_Aggregate;
  /** fetch data from the table: "stocked_vehicle_finance_products" using primary key columns */
  stocked_vehicle_finance_products_by_pk?: Maybe<Stocked_Vehicle_Finance_Products>;
  /** fetch data from the table in a streaming manner: "stocked_vehicle_finance_products" */
  stocked_vehicle_finance_products_stream: Array<Stocked_Vehicle_Finance_Products>;
  /** An array relationship */
  stocked_vehicle_handover_locations: Array<Stocked_Vehicle_Handover_Locations>;
  /** An aggregate relationship */
  stocked_vehicle_handover_locations_aggregate: Stocked_Vehicle_Handover_Locations_Aggregate;
  /** fetch data from the table: "stocked_vehicle_handover_locations" using primary key columns */
  stocked_vehicle_handover_locations_by_pk?: Maybe<Stocked_Vehicle_Handover_Locations>;
  /** fetch data from the table in a streaming manner: "stocked_vehicle_handover_locations" */
  stocked_vehicle_handover_locations_stream: Array<Stocked_Vehicle_Handover_Locations>;
  /** An array relationship */
  stocked_vehicle_offers: Array<Stocked_Vehicle_Offers>;
  /** An aggregate relationship */
  stocked_vehicle_offers_aggregate: Stocked_Vehicle_Offers_Aggregate;
  /** fetch data from the table: "stocked_vehicle_offers" using primary key columns */
  stocked_vehicle_offers_by_pk?: Maybe<Stocked_Vehicle_Offers>;
  /** fetch data from the table in a streaming manner: "stocked_vehicle_offers" */
  stocked_vehicle_offers_stream: Array<Stocked_Vehicle_Offers>;
  /** An array relationship */
  stocked_vehicles: Array<Stocked_Vehicles>;
  /** An aggregate relationship */
  stocked_vehicles_aggregate: Stocked_Vehicles_Aggregate;
  /** fetch data from the table: "stocked_vehicles" using primary key columns */
  stocked_vehicles_by_pk?: Maybe<Stocked_Vehicles>;
  /** fetch data from the table in a streaming manner: "stocked_vehicles" */
  stocked_vehicles_stream: Array<Stocked_Vehicles>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasks_stream: Array<Tasks>;
  /** An array relationship */
  test_drives: Array<Test_Drives>;
  /** An aggregate relationship */
  test_drives_aggregate: Test_Drives_Aggregate;
  /** fetch data from the table: "test_drives" using primary key columns */
  test_drives_by_pk?: Maybe<Test_Drives>;
  /** fetch data from the table in a streaming manner: "test_drives" */
  test_drives_stream: Array<Test_Drives>;
  /** An array relationship */
  trade_in_plans: Array<Trade_In_Plans>;
  /** An aggregate relationship */
  trade_in_plans_aggregate: Trade_In_Plans_Aggregate;
  /** fetch data from the table: "trade_in_plans" using primary key columns */
  trade_in_plans_by_pk?: Maybe<Trade_In_Plans>;
  /** fetch data from the table in a streaming manner: "trade_in_plans" */
  trade_in_plans_stream: Array<Trade_In_Plans>;
  /** An array relationship */
  trade_in_valuations: Array<Trade_In_Valuations>;
  /** An aggregate relationship */
  trade_in_valuations_aggregate: Trade_In_Valuations_Aggregate;
  /** fetch data from the table: "trade_in_valuations" using primary key columns */
  trade_in_valuations_by_pk?: Maybe<Trade_In_Valuations>;
  /** fetch data from the table in a streaming manner: "trade_in_valuations" */
  trade_in_valuations_stream: Array<Trade_In_Valuations>;
  /** fetch data from the table: "transport_types" */
  transport_types: Array<Transport_Types>;
  /** fetch aggregated fields from the table: "transport_types" */
  transport_types_aggregate: Transport_Types_Aggregate;
  /** fetch data from the table: "transport_types" using primary key columns */
  transport_types_by_pk?: Maybe<Transport_Types>;
  /** fetch data from the table in a streaming manner: "transport_types" */
  transport_types_stream: Array<Transport_Types>;
  /** An array relationship */
  user_interests: Array<User_Interests>;
  /** An aggregate relationship */
  user_interests_aggregate: User_Interests_Aggregate;
  /** fetch data from the table: "user_interests" using primary key columns */
  user_interests_by_pk?: Maybe<User_Interests>;
  /** fetch data from the table in a streaming manner: "user_interests" */
  user_interests_stream: Array<User_Interests>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "valuers" */
  valuers: Array<Valuers>;
  /** fetch aggregated fields from the table: "valuers" */
  valuers_aggregate: Valuers_Aggregate;
  /** fetch data from the table: "valuers" using primary key columns */
  valuers_by_pk?: Maybe<Valuers>;
  /** fetch data from the table in a streaming manner: "valuers" */
  valuers_stream: Array<Valuers>;
  /** fetch data from the table: "vehicle_conditions" */
  vehicle_conditions: Array<Vehicle_Conditions>;
  /** fetch aggregated fields from the table: "vehicle_conditions" */
  vehicle_conditions_aggregate: Vehicle_Conditions_Aggregate;
  /** fetch data from the table: "vehicle_conditions" using primary key columns */
  vehicle_conditions_by_pk?: Maybe<Vehicle_Conditions>;
  /** fetch data from the table in a streaming manner: "vehicle_conditions" */
  vehicle_conditions_stream: Array<Vehicle_Conditions>;
  /** An array relationship */
  vehicle_lists: Array<Vehicle_Lists>;
  /** An aggregate relationship */
  vehicle_lists_aggregate: Vehicle_Lists_Aggregate;
  /** fetch data from the table: "vehicle_lists" using primary key columns */
  vehicle_lists_by_pk?: Maybe<Vehicle_Lists>;
  /** fetch data from the table in a streaming manner: "vehicle_lists" */
  vehicle_lists_stream: Array<Vehicle_Lists>;
  /** fetch data from the table: "vehicle_order_activity" */
  vehicle_order_activity: Array<Vehicle_Order_Activity>;
  /** fetch aggregated fields from the table: "vehicle_order_activity" */
  vehicle_order_activity_aggregate: Vehicle_Order_Activity_Aggregate;
  /** fetch data from the table: "vehicle_order_activity" using primary key columns */
  vehicle_order_activity_by_pk?: Maybe<Vehicle_Order_Activity>;
  /** fetch data from the table in a streaming manner: "vehicle_order_activity" */
  vehicle_order_activity_stream: Array<Vehicle_Order_Activity>;
  /** An array relationship */
  vehicle_order_product_variants: Array<Vehicle_Order_Product_Variants>;
  /** An aggregate relationship */
  vehicle_order_product_variants_aggregate: Vehicle_Order_Product_Variants_Aggregate;
  /** fetch data from the table: "vehicle_order_product_variants" using primary key columns */
  vehicle_order_product_variants_by_pk?: Maybe<Vehicle_Order_Product_Variants>;
  /** fetch data from the table in a streaming manner: "vehicle_order_product_variants" */
  vehicle_order_product_variants_stream: Array<Vehicle_Order_Product_Variants>;
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  /** fetch data from the table: "vehicle_orders" using primary key columns */
  vehicle_orders_by_pk?: Maybe<Vehicle_Orders>;
  /** fetch data from the table in a streaming manner: "vehicle_orders" */
  vehicle_orders_stream: Array<Vehicle_Orders>;
  /** fetch data from the table: "vehicle_specifications" */
  vehicle_specifications: Array<Vehicle_Specifications>;
  /** fetch aggregated fields from the table: "vehicle_specifications" */
  vehicle_specifications_aggregate: Vehicle_Specifications_Aggregate;
  /** fetch data from the table: "vehicle_specifications" using primary key columns */
  vehicle_specifications_by_pk?: Maybe<Vehicle_Specifications>;
  /** fetch data from the table in a streaming manner: "vehicle_specifications" */
  vehicle_specifications_stream: Array<Vehicle_Specifications>;
  /** fetch data from the table: "wish_list" */
  wish_list: Array<Wish_List>;
  /** fetch aggregated fields from the table: "wish_list" */
  wish_list_aggregate: Wish_List_Aggregate;
  /** fetch data from the table: "wish_list" using primary key columns */
  wish_list_by_pk?: Maybe<Wish_List>;
  /** fetch data from the table in a streaming manner: "wish_list" */
  wish_list_stream: Array<Wish_List>;
};


export type Subscription_RootActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Subscription_RootActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Subscription_RootActivities_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootActivities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Activities_Stream_Cursor_Input>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Subscription_RootApi_ClientsArgs = {
  distinct_on?: Maybe<Array<Api_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Clients_Order_By>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


export type Subscription_RootApi_Clients_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Clients_Order_By>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


export type Subscription_RootApi_Clients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApi_Clients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Api_Clients_Stream_Cursor_Input>>;
  where?: Maybe<Api_Clients_Bool_Exp>;
};


export type Subscription_RootApp_ActionsArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


export type Subscription_RootApp_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Actions_Order_By>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


export type Subscription_RootApp_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApp_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<App_Actions_Stream_Cursor_Input>>;
  where?: Maybe<App_Actions_Bool_Exp>;
};


export type Subscription_RootApp_EventsArgs = {
  distinct_on?: Maybe<Array<App_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Events_Order_By>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


export type Subscription_RootApp_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Events_Order_By>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


export type Subscription_RootApp_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApp_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<App_Events_Stream_Cursor_Input>>;
  where?: Maybe<App_Events_Bool_Exp>;
};


export type Subscription_RootApp_InputsArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


export type Subscription_RootApp_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Inputs_Order_By>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


export type Subscription_RootApp_Inputs_By_PkArgs = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  version_num: Scalars['String'];
};


export type Subscription_RootApp_Inputs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<App_Inputs_Stream_Cursor_Input>>;
  where?: Maybe<App_Inputs_Bool_Exp>;
};


export type Subscription_RootApp_VersionsArgs = {
  distinct_on?: Maybe<Array<App_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Versions_Order_By>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


export type Subscription_RootApp_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Versions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Versions_Order_By>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


export type Subscription_RootApp_Versions_By_PkArgs = {
  app_id: Scalars['uuid'];
  version_num: Scalars['String'];
};


export type Subscription_RootApp_Versions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<App_Versions_Stream_Cursor_Input>>;
  where?: Maybe<App_Versions_Bool_Exp>;
};


export type Subscription_RootAppsArgs = {
  distinct_on?: Maybe<Array<Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apps_Order_By>>;
  where?: Maybe<Apps_Bool_Exp>;
};


export type Subscription_RootApps_AggregateArgs = {
  distinct_on?: Maybe<Array<Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apps_Order_By>>;
  where?: Maybe<Apps_Bool_Exp>;
};


export type Subscription_RootApps_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Apps_Stream_Cursor_Input>>;
  where?: Maybe<Apps_Bool_Exp>;
};


export type Subscription_RootArticlesArgs = {
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Subscription_RootArticles_AggregateArgs = {
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Subscription_RootArticles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootArticles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Articles_Stream_Cursor_Input>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Subscription_RootAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


export type Subscription_RootAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


export type Subscription_RootAssets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAssets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Assets_Stream_Cursor_Input>>;
  where?: Maybe<Assets_Bool_Exp>;
};


export type Subscription_RootAuction_BidsArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


export type Subscription_RootAuction_Bids_AggregateArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


export type Subscription_RootAuction_Bids_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuction_Bids_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Auction_Bids_Stream_Cursor_Input>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


export type Subscription_RootAuctionsArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


export type Subscription_RootAuctions_AggregateArgs = {
  distinct_on?: Maybe<Array<Auctions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auctions_Order_By>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


export type Subscription_RootAuctions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuctions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Auctions_Stream_Cursor_Input>>;
  where?: Maybe<Auctions_Bool_Exp>;
};


export type Subscription_RootCalculator_SubmissionArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


export type Subscription_RootCalculator_Submission_AggregateArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


export type Subscription_RootCalculator_Submission_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCalculator_Submission_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Calculator_Submission_Stream_Cursor_Input>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


export type Subscription_RootCustom_Field_ValuesArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


export type Subscription_RootCustom_Field_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


export type Subscription_RootCustom_Field_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustom_Field_Values_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Custom_Field_Values_Stream_Cursor_Input>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


export type Subscription_RootCustom_FieldsArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


export type Subscription_RootCustom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


export type Subscription_RootCustom_Fields_By_PkArgs = {
  entity: Scalars['String'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootCustom_Fields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Custom_Fields_Stream_Cursor_Input>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


export type Subscription_RootCustom_VehiclesArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


export type Subscription_RootCustom_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


export type Subscription_RootCustom_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustom_Vehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Custom_Vehicles_Stream_Cursor_Input>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


export type Subscription_RootFilter_FieldsArgs = {
  distinct_on?: Maybe<Array<Filter_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Fields_Order_By>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


export type Subscription_RootFilter_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Fields_Order_By>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


export type Subscription_RootFilter_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFilter_Fields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Filter_Fields_Stream_Cursor_Input>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


export type Subscription_RootFilter_LocationsArgs = {
  distinct_on?: Maybe<Array<Filter_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Locations_Order_By>>;
  where?: Maybe<Filter_Locations_Bool_Exp>;
};


export type Subscription_RootFilter_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Locations_Order_By>>;
  where?: Maybe<Filter_Locations_Bool_Exp>;
};


export type Subscription_RootFilter_Locations_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFilter_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Filter_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Filter_Locations_Bool_Exp>;
};


export type Subscription_RootFilter_NamesArgs = {
  distinct_on?: Maybe<Array<Filter_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Names_Order_By>>;
  where?: Maybe<Filter_Names_Bool_Exp>;
};


export type Subscription_RootFilter_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Names_Order_By>>;
  where?: Maybe<Filter_Names_Bool_Exp>;
};


export type Subscription_RootFilter_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFilter_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Filter_Names_Stream_Cursor_Input>>;
  where?: Maybe<Filter_Names_Bool_Exp>;
};


export type Subscription_RootFinance_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


export type Subscription_RootFinance_Applications_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


export type Subscription_RootFinance_Applications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Applications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Applications_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


export type Subscription_RootFinance_FeesArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


export type Subscription_RootFinance_Fees_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fees_Order_By>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


export type Subscription_RootFinance_Fees_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Fees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Fees_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Fees_Bool_Exp>;
};


export type Subscription_RootFinance_Fixed_Rate_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Finance_Fixed_Rate_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fixed_Rate_Configurations_Order_By>>;
  where?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
};


export type Subscription_RootFinance_Fixed_Rate_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Fixed_Rate_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Fixed_Rate_Configurations_Order_By>>;
  where?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
};


export type Subscription_RootFinance_Fixed_Rate_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Fixed_Rate_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Fixed_Rate_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Fixed_Rate_Configurations_Bool_Exp>;
};


export type Subscription_RootFinance_FrequencyArgs = {
  distinct_on?: Maybe<Array<Finance_Frequency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Frequency_Order_By>>;
  where?: Maybe<Finance_Frequency_Bool_Exp>;
};


export type Subscription_RootFinance_Frequency_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Frequency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Frequency_Order_By>>;
  where?: Maybe<Finance_Frequency_Bool_Exp>;
};


export type Subscription_RootFinance_Frequency_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFinance_Frequency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Frequency_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Frequency_Bool_Exp>;
};


export type Subscription_RootFinance_Gfv_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Configurations_Order_By>>;
  where?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
};


export type Subscription_RootFinance_Gfv_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Configurations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Configurations_Order_By>>;
  where?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
};


export type Subscription_RootFinance_Gfv_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Gfv_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Gfv_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Gfv_Configurations_Bool_Exp>;
};


export type Subscription_RootFinance_Gfv_Residual_ValuesArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


export type Subscription_RootFinance_Gfv_Residual_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Gfv_Residual_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Gfv_Residual_Values_Order_By>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


export type Subscription_RootFinance_Gfv_Residual_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Gfv_Residual_Values_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Gfv_Residual_Values_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Gfv_Residual_Values_Bool_Exp>;
};


export type Subscription_RootFinance_Product_OffersArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Offers_Order_By>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


export type Subscription_RootFinance_Product_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Offers_Order_By>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


export type Subscription_RootFinance_Product_Offers_By_PkArgs = {
  finance_product_id: Scalars['uuid'];
  offer_id: Scalars['uuid'];
};


export type Subscription_RootFinance_Product_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Product_Offers_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Product_Offers_Bool_Exp>;
};


export type Subscription_RootFinance_Product_TypesArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Types_Order_By>>;
  where?: Maybe<Finance_Product_Types_Bool_Exp>;
};


export type Subscription_RootFinance_Product_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Product_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Product_Types_Order_By>>;
  where?: Maybe<Finance_Product_Types_Bool_Exp>;
};


export type Subscription_RootFinance_Product_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFinance_Product_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Product_Types_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Product_Types_Bool_Exp>;
};


export type Subscription_RootFinance_ProductsArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


export type Subscription_RootFinance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Products_Order_By>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


export type Subscription_RootFinance_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Products_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Products_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Products_Bool_Exp>;
};


export type Subscription_RootFinance_TermsArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


export type Subscription_RootFinance_Terms_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Terms_Order_By>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


export type Subscription_RootFinance_Terms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinance_Terms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Finance_Terms_Stream_Cursor_Input>>;
  where?: Maybe<Finance_Terms_Bool_Exp>;
};


export type Subscription_RootForm_FieldsArgs = {
  distinct_on?: Maybe<Array<Form_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Fields_Order_By>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


export type Subscription_RootForm_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Fields_Order_By>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


export type Subscription_RootForm_Fields_By_PkArgs = {
  form_id: Scalars['uuid'];
  name: Scalars['String'];
};


export type Subscription_RootForm_Fields_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Form_Fields_Stream_Cursor_Input>>;
  where?: Maybe<Form_Fields_Bool_Exp>;
};


export type Subscription_RootForm_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Subscription_RootForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Subscription_RootForm_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_Submissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Form_Submissions_Stream_Cursor_Input>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


export type Subscription_RootFormsArgs = {
  distinct_on?: Maybe<Array<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: Maybe<Array<Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forms_Order_By>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Subscription_RootForms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Forms_Stream_Cursor_Input>>;
  where?: Maybe<Forms_Bool_Exp>;
};


export type Subscription_RootInsights_Click_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Click_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Click_Events_Order_By>>;
  where?: Maybe<Insights_Click_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Click_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Click_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Click_Events_Order_By>>;
  where?: Maybe<Insights_Click_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Click_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsights_Click_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Click_Events_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Click_Events_Bool_Exp>;
};


export type Subscription_RootInsights_DevicesArgs = {
  distinct_on?: Maybe<Array<Insights_Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Devices_Order_By>>;
  where?: Maybe<Insights_Devices_Bool_Exp>;
};


export type Subscription_RootInsights_Devices_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Devices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Devices_Order_By>>;
  where?: Maybe<Insights_Devices_Bool_Exp>;
};


export type Subscription_RootInsights_Devices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsights_Devices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Devices_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Devices_Bool_Exp>;
};


export type Subscription_RootInsights_Event_TypesArgs = {
  distinct_on?: Maybe<Array<Insights_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Event_Types_Order_By>>;
  where?: Maybe<Insights_Event_Types_Bool_Exp>;
};


export type Subscription_RootInsights_Event_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Event_Types_Order_By>>;
  where?: Maybe<Insights_Event_Types_Bool_Exp>;
};


export type Subscription_RootInsights_Event_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootInsights_Event_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Event_Types_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Event_Types_Bool_Exp>;
};


export type Subscription_RootInsights_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Events_Order_By>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Events_Order_By>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsights_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Events_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Page_View_EventsArgs = {
  distinct_on?: Maybe<Array<Insights_Page_View_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Page_View_Events_Order_By>>;
  where?: Maybe<Insights_Page_View_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Page_View_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Page_View_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Page_View_Events_Order_By>>;
  where?: Maybe<Insights_Page_View_Events_Bool_Exp>;
};


export type Subscription_RootInsights_Page_View_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsights_Page_View_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Page_View_Events_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Page_View_Events_Bool_Exp>;
};


export type Subscription_RootInsights_SessionsArgs = {
  distinct_on?: Maybe<Array<Insights_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Sessions_Order_By>>;
  where?: Maybe<Insights_Sessions_Bool_Exp>;
};


export type Subscription_RootInsights_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Sessions_Order_By>>;
  where?: Maybe<Insights_Sessions_Bool_Exp>;
};


export type Subscription_RootInsights_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsights_Sessions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Sessions_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Sessions_Bool_Exp>;
};


export type Subscription_RootInsights_VisitorsArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


export type Subscription_RootInsights_Visitors_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


export type Subscription_RootInsights_Visitors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsights_Visitors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Insights_Visitors_Stream_Cursor_Input>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


export type Subscription_RootInterestsArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Subscription_RootInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Subscription_RootInterests_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootInterests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Interests_Stream_Cursor_Input>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Subscription_RootKnown_VehiclesArgs = {
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Subscription_RootKnown_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Subscription_RootKnown_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKnown_Vehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Known_Vehicles_Stream_Cursor_Input>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Subscription_RootLocalesArgs = {
  distinct_on?: Maybe<Array<Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locales_Order_By>>;
  where?: Maybe<Locales_Bool_Exp>;
};


export type Subscription_RootLocales_AggregateArgs = {
  distinct_on?: Maybe<Array<Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locales_Order_By>>;
  where?: Maybe<Locales_Bool_Exp>;
};


export type Subscription_RootLocales_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootLocales_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Locales_Stream_Cursor_Input>>;
  where?: Maybe<Locales_Bool_Exp>;
};


export type Subscription_RootLocation_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


export type Subscription_RootLocation_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Service_Types_Order_By>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


export type Subscription_RootLocation_Service_Types_By_PkArgs = {
  location_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};


export type Subscription_RootLocation_Service_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Location_Service_Types_Stream_Cursor_Input>>;
  where?: Maybe<Location_Service_Types_Bool_Exp>;
};


export type Subscription_RootLocation_Transport_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


export type Subscription_RootLocation_Transport_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


export type Subscription_RootLocation_Transport_Types_By_PkArgs = {
  location_id: Scalars['uuid'];
  transport_type_id: Scalars['uuid'];
};


export type Subscription_RootLocation_Transport_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Location_Transport_Types_Stream_Cursor_Input>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Locations_Stream_Cursor_Input>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootMatch_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Match_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Submissions_Order_By>>;
  where?: Maybe<Match_Submissions_Bool_Exp>;
};


export type Subscription_RootMatch_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Match_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Match_Submissions_Order_By>>;
  where?: Maybe<Match_Submissions_Bool_Exp>;
};


export type Subscription_RootMatch_Submissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMatch_Submissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Match_Submissions_Stream_Cursor_Input>>;
  where?: Maybe<Match_Submissions_Bool_Exp>;
};


export type Subscription_RootMomentsArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


export type Subscription_RootMoments_AggregateArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


export type Subscription_RootMoments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMoments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Moments_Stream_Cursor_Input>>;
  where?: Maybe<Moments_Bool_Exp>;
};


export type Subscription_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Notes_Stream_Cursor_Input>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootOffer_TypesArgs = {
  distinct_on?: Maybe<Array<Offer_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offer_Types_Order_By>>;
  where?: Maybe<Offer_Types_Bool_Exp>;
};


export type Subscription_RootOffer_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Offer_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offer_Types_Order_By>>;
  where?: Maybe<Offer_Types_Bool_Exp>;
};


export type Subscription_RootOffer_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootOffer_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Offer_Types_Stream_Cursor_Input>>;
  where?: Maybe<Offer_Types_Bool_Exp>;
};


export type Subscription_RootOffersArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Subscription_RootOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Offers_Order_By>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Subscription_RootOffers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOffers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Offers_Stream_Cursor_Input>>;
  where?: Maybe<Offers_Bool_Exp>;
};


export type Subscription_RootPagesArgs = {
  distinct_on?: Maybe<Array<Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pages_Order_By>>;
  where?: Maybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_AggregateArgs = {
  distinct_on?: Maybe<Array<Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pages_Order_By>>;
  where?: Maybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pages_Stream_Cursor_Input>>;
  where?: Maybe<Pages_Bool_Exp>;
};


export type Subscription_RootPartnersArgs = {
  distinct_on?: Maybe<Array<Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partners_Order_By>>;
  where?: Maybe<Partners_Bool_Exp>;
};


export type Subscription_RootPartners_AggregateArgs = {
  distinct_on?: Maybe<Array<Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partners_Order_By>>;
  where?: Maybe<Partners_Bool_Exp>;
};


export type Subscription_RootPartners_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPartners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Partners_Stream_Cursor_Input>>;
  where?: Maybe<Partners_Bool_Exp>;
};


export type Subscription_RootPayment_CodesArgs = {
  distinct_on?: Maybe<Array<Payment_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Codes_Order_By>>;
  where?: Maybe<Payment_Codes_Bool_Exp>;
};


export type Subscription_RootPayment_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Codes_Order_By>>;
  where?: Maybe<Payment_Codes_Bool_Exp>;
};


export type Subscription_RootPayment_Codes_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPayment_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_Codes_Stream_Cursor_Input>>;
  where?: Maybe<Payment_Codes_Bool_Exp>;
};


export type Subscription_RootPayment_Method_TypesArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Types_Order_By>>;
  where?: Maybe<Payment_Method_Types_Bool_Exp>;
};


export type Subscription_RootPayment_Method_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Types_Order_By>>;
  where?: Maybe<Payment_Method_Types_Bool_Exp>;
};


export type Subscription_RootPayment_Method_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPayment_Method_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_Method_Types_Stream_Cursor_Input>>;
  where?: Maybe<Payment_Method_Types_Bool_Exp>;
};


export type Subscription_RootPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayment_Methods_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_Methods_Stream_Cursor_Input>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payments_Stream_Cursor_Input>>;
  where?: Maybe<Payments_Bool_Exp>;
};


export type Subscription_RootPersonal_VehiclesArgs = {
  distinct_on?: Maybe<Array<Personal_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Personal_Vehicles_Order_By>>;
  where?: Maybe<Personal_Vehicles_Bool_Exp>;
};


export type Subscription_RootPersonal_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Personal_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Personal_Vehicles_Order_By>>;
  where?: Maybe<Personal_Vehicles_Bool_Exp>;
};


export type Subscription_RootPersonal_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPersonal_Vehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Personal_Vehicles_Stream_Cursor_Input>>;
  where?: Maybe<Personal_Vehicles_Bool_Exp>;
};


export type Subscription_RootPricing_CodesArgs = {
  distinct_on?: Maybe<Array<Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricing_Codes_Order_By>>;
  where?: Maybe<Pricing_Codes_Bool_Exp>;
};


export type Subscription_RootPricing_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricing_Codes_Order_By>>;
  where?: Maybe<Pricing_Codes_Bool_Exp>;
};


export type Subscription_RootPricing_Codes_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootPricing_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pricing_Codes_Stream_Cursor_Input>>;
  where?: Maybe<Pricing_Codes_Bool_Exp>;
};


export type Subscription_RootProduct_GroupsArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootProduct_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Groups_Order_By>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootProduct_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProduct_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Product_Groups_Stream_Cursor_Input>>;
  where?: Maybe<Product_Groups_Bool_Exp>;
};


export type Subscription_RootProduct_VariantsArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Subscription_RootProduct_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Variants_Order_By>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Subscription_RootProduct_Variants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProduct_Variants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Product_Variants_Stream_Cursor_Input>>;
  where?: Maybe<Product_Variants_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Products_Stream_Cursor_Input>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootPromotionsArgs = {
  distinct_on?: Maybe<Array<Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotions_Order_By>>;
  where?: Maybe<Promotions_Bool_Exp>;
};


export type Subscription_RootPromotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Promotions_Order_By>>;
  where?: Maybe<Promotions_Bool_Exp>;
};


export type Subscription_RootPromotions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPromotions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Promotions_Stream_Cursor_Input>>;
  where?: Maybe<Promotions_Bool_Exp>;
};


export type Subscription_RootRedirectsArgs = {
  distinct_on?: Maybe<Array<Redirects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redirects_Order_By>>;
  where?: Maybe<Redirects_Bool_Exp>;
};


export type Subscription_RootRedirects_AggregateArgs = {
  distinct_on?: Maybe<Array<Redirects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redirects_Order_By>>;
  where?: Maybe<Redirects_Bool_Exp>;
};


export type Subscription_RootRedirects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRedirects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Redirects_Stream_Cursor_Input>>;
  where?: Maybe<Redirects_Bool_Exp>;
};


export type Subscription_RootRental_DriversArgs = {
  distinct_on?: Maybe<Array<Rental_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Drivers_Order_By>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};


export type Subscription_RootRental_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Drivers_Order_By>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};


export type Subscription_RootRental_Drivers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRental_Drivers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rental_Drivers_Stream_Cursor_Input>>;
  where?: Maybe<Rental_Drivers_Bool_Exp>;
};


export type Subscription_RootRental_Reservation_StatusArgs = {
  distinct_on?: Maybe<Array<Rental_Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservation_Status_Order_By>>;
  where?: Maybe<Rental_Reservation_Status_Bool_Exp>;
};


export type Subscription_RootRental_Reservation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservation_Status_Order_By>>;
  where?: Maybe<Rental_Reservation_Status_Bool_Exp>;
};


export type Subscription_RootRental_Reservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRental_Reservation_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rental_Reservation_Status_Stream_Cursor_Input>>;
  where?: Maybe<Rental_Reservation_Status_Bool_Exp>;
};


export type Subscription_RootRental_ReservationsArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Subscription_RootRental_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Subscription_RootRental_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRental_Reservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Rental_Reservations_Stream_Cursor_Input>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Subscription_RootSearch_ArticlesArgs = {
  args: Search_Articles_Args;
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Subscription_RootSearch_Articles_AggregateArgs = {
  args: Search_Articles_Args;
  distinct_on?: Maybe<Array<Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Articles_Order_By>>;
  where?: Maybe<Articles_Bool_Exp>;
};


export type Subscription_RootSearch_Known_VehiclesArgs = {
  args: Search_Known_Vehicles_Args;
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Subscription_RootSearch_Known_Vehicles_AggregateArgs = {
  args: Search_Known_Vehicles_Args;
  distinct_on?: Maybe<Array<Known_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Known_Vehicles_Order_By>>;
  where?: Maybe<Known_Vehicles_Bool_Exp>;
};


export type Subscription_RootSearch_Rental_ReservationsArgs = {
  args: Search_Rental_Reservations_Args;
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Subscription_RootSearch_Rental_Reservations_AggregateArgs = {
  args: Search_Rental_Reservations_Args;
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


export type Subscription_RootSearch_Stocked_VehiclesArgs = {
  args: Search_Stocked_Vehicles_Args;
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootSearch_Stocked_Vehicles_AggregateArgs = {
  args: Search_Stocked_Vehicles_Args;
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootService_Booking_Service_TypesArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


export type Subscription_RootService_Booking_Service_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Booking_Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Booking_Service_Types_Order_By>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


export type Subscription_RootService_Booking_Service_Types_By_PkArgs = {
  service_booking_id: Scalars['uuid'];
  service_type_id: Scalars['uuid'];
};


export type Subscription_RootService_Booking_Service_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Booking_Service_Types_Stream_Cursor_Input>>;
  where?: Maybe<Service_Booking_Service_Types_Bool_Exp>;
};


export type Subscription_RootService_BookingsArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


export type Subscription_RootService_Bookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


export type Subscription_RootService_Bookings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootService_Bookings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Bookings_Stream_Cursor_Input>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


export type Subscription_RootService_TypesArgs = {
  distinct_on?: Maybe<Array<Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Types_Order_By>>;
  where?: Maybe<Service_Types_Bool_Exp>;
};


export type Subscription_RootService_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Types_Order_By>>;
  where?: Maybe<Service_Types_Bool_Exp>;
};


export type Subscription_RootService_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootService_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Types_Stream_Cursor_Input>>;
  where?: Maybe<Service_Types_Bool_Exp>;
};


export type Subscription_RootSessionsArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSessions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sessions_Stream_Cursor_Input>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


export type Subscription_RootShowroom_AppsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


export type Subscription_RootShowroom_Apps_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Order_By>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


export type Subscription_RootShowroom_Apps_By_PkArgs = {
  app_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootShowroom_Apps_InputsArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


export type Subscription_RootShowroom_Apps_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Apps_Inputs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Apps_Inputs_Order_By>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


export type Subscription_RootShowroom_Apps_Inputs_By_PkArgs = {
  app_id: Scalars['uuid'];
  name: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootShowroom_Apps_Inputs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showroom_Apps_Inputs_Stream_Cursor_Input>>;
  where?: Maybe<Showroom_Apps_Inputs_Bool_Exp>;
};


export type Subscription_RootShowroom_Apps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showroom_Apps_Stream_Cursor_Input>>;
  where?: Maybe<Showroom_Apps_Bool_Exp>;
};


export type Subscription_RootShowroom_LocalesArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


export type Subscription_RootShowroom_Locales_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Locales_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Locales_Order_By>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


export type Subscription_RootShowroom_Locales_By_PkArgs = {
  locale_code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootShowroom_Locales_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showroom_Locales_Stream_Cursor_Input>>;
  where?: Maybe<Showroom_Locales_Bool_Exp>;
};


export type Subscription_RootShowroom_Pricing_CodesArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


export type Subscription_RootShowroom_Pricing_Codes_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Pricing_Codes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Pricing_Codes_Order_By>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


export type Subscription_RootShowroom_Pricing_Codes_By_PkArgs = {
  code: Scalars['String'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootShowroom_Pricing_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showroom_Pricing_Codes_Stream_Cursor_Input>>;
  where?: Maybe<Showroom_Pricing_Codes_Bool_Exp>;
};


export type Subscription_RootShowroom_Stocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootShowroom_Stocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Stocked_Vehicles_Order_By>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootShowroom_Stocked_Vehicles_By_PkArgs = {
  showroom_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Subscription_RootShowroom_Stocked_Vehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showroom_Stocked_Vehicles_Stream_Cursor_Input>>;
  where?: Maybe<Showroom_Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootShowroom_UsersArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


export type Subscription_RootShowroom_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


export type Subscription_RootShowroom_Users_By_PkArgs = {
  showroom_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootShowroom_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showroom_Users_Stream_Cursor_Input>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


export type Subscription_RootShowroomsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


export type Subscription_RootShowrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Order_By>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


export type Subscription_RootShowrooms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootShowrooms_PartnersArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


export type Subscription_RootShowrooms_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Partners_Order_By>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


export type Subscription_RootShowrooms_Partners_By_PkArgs = {
  partner_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootShowrooms_Partners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showrooms_Partners_Stream_Cursor_Input>>;
  where?: Maybe<Showrooms_Partners_Bool_Exp>;
};


export type Subscription_RootShowrooms_PromotionsArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


export type Subscription_RootShowrooms_Promotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Showrooms_Promotions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showrooms_Promotions_Order_By>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


export type Subscription_RootShowrooms_Promotions_By_PkArgs = {
  promotion_id: Scalars['uuid'];
  showroom_id: Scalars['uuid'];
};


export type Subscription_RootShowrooms_Promotions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showrooms_Promotions_Stream_Cursor_Input>>;
  where?: Maybe<Showrooms_Promotions_Bool_Exp>;
};


export type Subscription_RootShowrooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Showrooms_Stream_Cursor_Input>>;
  where?: Maybe<Showrooms_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Finance_ProductsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Finance_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Finance_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Finance_Products_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Finance_Products_By_PkArgs = {
  finance_product_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Subscription_RootStocked_Vehicle_Finance_Products_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stocked_Vehicle_Finance_Products_Stream_Cursor_Input>>;
  where?: Maybe<Stocked_Vehicle_Finance_Products_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Handover_LocationsArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Handover_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Handover_Locations_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Handover_Locations_By_PkArgs = {
  location_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Subscription_RootStocked_Vehicle_Handover_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stocked_Vehicle_Handover_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Stocked_Vehicle_Handover_Locations_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_OffersArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Offers_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Offers_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicle_Offers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicle_Offers_Order_By>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicle_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
  stocked_vehicle_id: Scalars['uuid'];
};


export type Subscription_RootStocked_Vehicle_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stocked_Vehicle_Offers_Stream_Cursor_Input>>;
  where?: Maybe<Stocked_Vehicle_Offers_Bool_Exp>;
};


export type Subscription_RootStocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootStocked_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStocked_Vehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stocked_Vehicles_Stream_Cursor_Input>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


export type Subscription_RootTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTest_DrivesArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


export type Subscription_RootTest_Drives_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


export type Subscription_RootTest_Drives_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTest_Drives_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Test_Drives_Stream_Cursor_Input>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


export type Subscription_RootTrade_In_PlansArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


export type Subscription_RootTrade_In_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


export type Subscription_RootTrade_In_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTrade_In_Plans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Trade_In_Plans_Stream_Cursor_Input>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


export type Subscription_RootTrade_In_ValuationsArgs = {
  distinct_on?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Valuations_Order_By>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};


export type Subscription_RootTrade_In_Valuations_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Valuations_Order_By>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};


export type Subscription_RootTrade_In_Valuations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTrade_In_Valuations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Trade_In_Valuations_Stream_Cursor_Input>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};


export type Subscription_RootTransport_TypesArgs = {
  distinct_on?: Maybe<Array<Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transport_Types_Order_By>>;
  where?: Maybe<Transport_Types_Bool_Exp>;
};


export type Subscription_RootTransport_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transport_Types_Order_By>>;
  where?: Maybe<Transport_Types_Bool_Exp>;
};


export type Subscription_RootTransport_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTransport_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Transport_Types_Stream_Cursor_Input>>;
  where?: Maybe<Transport_Types_Bool_Exp>;
};


export type Subscription_RootUser_InterestsArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


export type Subscription_RootUser_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


export type Subscription_RootUser_Interests_By_PkArgs = {
  interest_code: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Interests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Interests_Stream_Cursor_Input>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Users_Stream_Cursor_Input>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootValuersArgs = {
  distinct_on?: Maybe<Array<Valuers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valuers_Order_By>>;
  where?: Maybe<Valuers_Bool_Exp>;
};


export type Subscription_RootValuers_AggregateArgs = {
  distinct_on?: Maybe<Array<Valuers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valuers_Order_By>>;
  where?: Maybe<Valuers_Bool_Exp>;
};


export type Subscription_RootValuers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootValuers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Valuers_Stream_Cursor_Input>>;
  where?: Maybe<Valuers_Bool_Exp>;
};


export type Subscription_RootVehicle_ConditionsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Conditions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Conditions_Order_By>>;
  where?: Maybe<Vehicle_Conditions_Bool_Exp>;
};


export type Subscription_RootVehicle_Conditions_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Conditions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Conditions_Order_By>>;
  where?: Maybe<Vehicle_Conditions_Bool_Exp>;
};


export type Subscription_RootVehicle_Conditions_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVehicle_Conditions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Conditions_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Conditions_Bool_Exp>;
};


export type Subscription_RootVehicle_ListsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Lists_Order_By>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


export type Subscription_RootVehicle_Lists_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Lists_Order_By>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


export type Subscription_RootVehicle_Lists_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVehicle_Lists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Lists_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};


export type Subscription_RootVehicle_Order_ActivityArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Activity_Order_By>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


export type Subscription_RootVehicle_Order_Activity_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Activity_Order_By>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


export type Subscription_RootVehicle_Order_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVehicle_Order_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Order_Activity_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


export type Subscription_RootVehicle_Order_Product_VariantsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


export type Subscription_RootVehicle_Order_Product_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


export type Subscription_RootVehicle_Order_Product_Variants_By_PkArgs = {
  product_variant_id: Scalars['uuid'];
  vehicle_order_id: Scalars['uuid'];
};


export type Subscription_RootVehicle_Order_Product_Variants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Order_Product_Variants_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


export type Subscription_RootVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


export type Subscription_RootVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


export type Subscription_RootVehicle_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVehicle_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Orders_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


export type Subscription_RootVehicle_SpecificationsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Specifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Specifications_Order_By>>;
  where?: Maybe<Vehicle_Specifications_Bool_Exp>;
};


export type Subscription_RootVehicle_Specifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Specifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Specifications_Order_By>>;
  where?: Maybe<Vehicle_Specifications_Bool_Exp>;
};


export type Subscription_RootVehicle_Specifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVehicle_Specifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicle_Specifications_Stream_Cursor_Input>>;
  where?: Maybe<Vehicle_Specifications_Bool_Exp>;
};


export type Subscription_RootWish_ListArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};


export type Subscription_RootWish_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};


export type Subscription_RootWish_List_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWish_List_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Wish_List_Stream_Cursor_Input>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'tasks';
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  due_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_complete: Scalars['Boolean'];
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['uuid'];
  remind_owner: Scalars['Boolean'];
  /** An object relationship */
  showroom?: Maybe<Showrooms>;
  showroom_id: Scalars['uuid'];
  subject: Scalars['String'];
  type: Scalars['citext'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Tasks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Tasks_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Tasks_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
};


/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tasks_Max_Order_By>;
  min?: Maybe<Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: Maybe<Array<Tasks_Bool_Exp>>;
  _not?: Maybe<Tasks_Bool_Exp>;
  _or?: Maybe<Array<Tasks_Bool_Exp>>;
  body?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_complete?: Maybe<Boolean_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  remind_owner?: Maybe<Boolean_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Citext_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  TasksPkey = 'tasks_pkey'
}

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  remind_owner?: Maybe<Scalars['Boolean']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: Maybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  body?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_complete?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  remind_owner?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  RemindOwner = 'remind_owner',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Subject = 'subject',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "tasks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  RemindOwner = 'remind_owner'
}

/** select "tasks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  RemindOwner = 'remind_owner'
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['uuid']>;
  remind_owner?: Maybe<Scalars['Boolean']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['uuid']>;
  remind_owner?: Maybe<Scalars['Boolean']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsComplete = 'is_complete',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  RemindOwner = 'remind_owner',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Subject = 'subject',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** columns and relationships of "test_drives" */
export type Test_Drives = {
  __typename?: 'test_drives';
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_licence_verified: Scalars['Boolean'];
  /** An object relationship */
  location?: Maybe<Locations>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']>;
  primary_driver: Scalars['jsonb'];
  scheduled_at: Scalars['timestamptz'];
  secondary_driver?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  stage: Scalars['String'];
  /** An object relationship */
  stocked_vehicle?: Maybe<Stocked_Vehicles>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "test_drives" */
export type Test_DrivesAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "test_drives" */
export type Test_DrivesAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "test_drives" */
export type Test_DrivesPrimary_DriverArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "test_drives" */
export type Test_DrivesSecondary_DriverArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "test_drives" */
export type Test_Drives_Aggregate = {
  __typename?: 'test_drives_aggregate';
  aggregate?: Maybe<Test_Drives_Aggregate_Fields>;
  nodes: Array<Test_Drives>;
};

export type Test_Drives_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Test_Drives_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Test_Drives_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Test_Drives_Aggregate_Bool_Exp_Count>;
};

export type Test_Drives_Aggregate_Bool_Exp_Bool_And = {
  arguments: Test_Drives_Select_Column_Test_Drives_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Test_Drives_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Test_Drives_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Test_Drives_Select_Column_Test_Drives_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Test_Drives_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Test_Drives_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Test_Drives_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Test_Drives_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "test_drives" */
export type Test_Drives_Aggregate_Fields = {
  __typename?: 'test_drives_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Test_Drives_Max_Fields>;
  min?: Maybe<Test_Drives_Min_Fields>;
};


/** aggregate fields of "test_drives" */
export type Test_Drives_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Test_Drives_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "test_drives" */
export type Test_Drives_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Test_Drives_Max_Order_By>;
  min?: Maybe<Test_Drives_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Test_Drives_Append_Input = {
  primary_driver?: Maybe<Scalars['jsonb']>;
  secondary_driver?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "test_drives" */
export type Test_Drives_Arr_Rel_Insert_Input = {
  data: Array<Test_Drives_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Test_Drives_On_Conflict>;
};

/** Boolean expression to filter rows from the table "test_drives". All fields are combined with a logical 'AND'. */
export type Test_Drives_Bool_Exp = {
  _and?: Maybe<Array<Test_Drives_Bool_Exp>>;
  _not?: Maybe<Test_Drives_Bool_Exp>;
  _or?: Maybe<Array<Test_Drives_Bool_Exp>>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_licence_verified?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  primary_driver?: Maybe<Jsonb_Comparison_Exp>;
  scheduled_at?: Maybe<Timestamptz_Comparison_Exp>;
  secondary_driver?: Maybe<Jsonb_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "test_drives" */
export enum Test_Drives_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestDrivesPkey = 'test_drives_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Test_Drives_Delete_At_Path_Input = {
  primary_driver?: Maybe<Array<Scalars['String']>>;
  secondary_driver?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Test_Drives_Delete_Elem_Input = {
  primary_driver?: Maybe<Scalars['Int']>;
  secondary_driver?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Test_Drives_Delete_Key_Input = {
  primary_driver?: Maybe<Scalars['String']>;
  secondary_driver?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "test_drives" */
export type Test_Drives_Insert_Input = {
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_licence_verified?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  primary_driver?: Maybe<Scalars['jsonb']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  secondary_driver?: Maybe<Scalars['jsonb']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Test_Drives_Max_Fields = {
  __typename?: 'test_drives_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "test_drives" */
export type Test_Drives_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Test_Drives_Min_Fields = {
  __typename?: 'test_drives_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "test_drives" */
export type Test_Drives_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "test_drives" */
export type Test_Drives_Mutation_Response = {
  __typename?: 'test_drives_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Drives>;
};

/** input type for inserting object relation for remote table "test_drives" */
export type Test_Drives_Obj_Rel_Insert_Input = {
  data: Test_Drives_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Test_Drives_On_Conflict>;
};

/** on_conflict condition type for table "test_drives" */
export type Test_Drives_On_Conflict = {
  constraint: Test_Drives_Constraint;
  update_columns?: Array<Test_Drives_Update_Column>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};

/** Ordering options when selecting data from "test_drives". */
export type Test_Drives_Order_By = {
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_licence_verified?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  primary_driver?: Maybe<Order_By>;
  scheduled_at?: Maybe<Order_By>;
  secondary_driver?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: test_drives */
export type Test_Drives_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Test_Drives_Prepend_Input = {
  primary_driver?: Maybe<Scalars['jsonb']>;
  secondary_driver?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "test_drives" */
export enum Test_Drives_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLicenceVerified = 'is_licence_verified',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PrimaryDriver = 'primary_driver',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SecondaryDriver = 'secondary_driver',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "test_drives_aggregate_bool_exp_bool_and_arguments_columns" columns of table "test_drives" */
export enum Test_Drives_Select_Column_Test_Drives_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLicenceVerified = 'is_licence_verified'
}

/** select "test_drives_aggregate_bool_exp_bool_or_arguments_columns" columns of table "test_drives" */
export enum Test_Drives_Select_Column_Test_Drives_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLicenceVerified = 'is_licence_verified'
}

/** input type for updating data in table "test_drives" */
export type Test_Drives_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_licence_verified?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  primary_driver?: Maybe<Scalars['jsonb']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  secondary_driver?: Maybe<Scalars['jsonb']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "test_drives" */
export type Test_Drives_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Drives_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Drives_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_licence_verified?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  primary_driver?: Maybe<Scalars['jsonb']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  secondary_driver?: Maybe<Scalars['jsonb']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "test_drives" */
export enum Test_Drives_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLicenceVerified = 'is_licence_verified',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PrimaryDriver = 'primary_driver',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SecondaryDriver = 'secondary_driver',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Test_Drives_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Test_Drives_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Test_Drives_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Test_Drives_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Test_Drives_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Test_Drives_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Test_Drives_Set_Input>;
  /** filter the rows which have to be updated */
  where: Test_Drives_Bool_Exp;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "trade_in_plans" */
export type Trade_In_Plans = {
  __typename?: 'trade_in_plans';
  additional_valuations_requested_at?: Maybe<Scalars['timestamptz']>;
  additional_valuations_updated_at?: Maybe<Scalars['timestamptz']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
  autograb_valuation_response?: Maybe<Scalars['jsonb']>;
  autograb_valuation_success?: Maybe<Scalars['Boolean']>;
  autograb_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  autograb_vehicle_id?: Maybe<Scalars['String']>;
  autograb_vehicle_response?: Maybe<Scalars['jsonb']>;
  autograb_vehicle_success?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculated_value?: Maybe<Scalars['numeric']>;
  carbuyers_inspection_id?: Maybe<Scalars['String']>;
  carbuyers_inspection_response?: Maybe<Scalars['jsonb']>;
  city?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  customer_comment?: Maybe<Scalars['String']>;
  drive?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "has_instant_trade_in_option" */
  has_instant_trade_in_option?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "has_summon_trade_in_option" */
  has_summon_trade_in_option?: Maybe<Scalars['Boolean']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  instant_valuation?: Maybe<Scalars['numeric']>;
  instant_valuation_accepted_at?: Maybe<Scalars['timestamptz']>;
  last_step: Scalars['String'];
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  on_valuation_result_url?: Maybe<Scalars['String']>;
  on_valuation_result_vehicle_order_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  /** pending | processing | declined */
  stage: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  summon_call_requested_at?: Maybe<Scalars['timestamptz']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_qualified?: Maybe<Scalars['Boolean']>;
  summon_referral_id?: Maybe<Scalars['String']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  summon_valuation_response?: Maybe<Scalars['jsonb']>;
  summon_valuation_success?: Maybe<Scalars['Boolean']>;
  summon_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  trade_in_valuations: Array<Trade_In_Valuations>;
  /** An aggregate relationship */
  trade_in_valuations_aggregate: Trade_In_Valuations_Aggregate;
  transmission?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  valuers_notified_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  vehicle_order?: Maybe<Vehicle_Orders>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "trade_in_plans" */
export type Trade_In_PlansAutograb_Valuation_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "trade_in_plans" */
export type Trade_In_PlansAutograb_Vehicle_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "trade_in_plans" */
export type Trade_In_PlansCarbuyers_Inspection_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "trade_in_plans" */
export type Trade_In_PlansSummon_Valuation_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "trade_in_plans" */
export type Trade_In_PlansTrade_In_ValuationsArgs = {
  distinct_on?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Valuations_Order_By>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};


/** columns and relationships of "trade_in_plans" */
export type Trade_In_PlansTrade_In_Valuations_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Valuations_Order_By>>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};

/** aggregated selection of "trade_in_plans" */
export type Trade_In_Plans_Aggregate = {
  __typename?: 'trade_in_plans_aggregate';
  aggregate?: Maybe<Trade_In_Plans_Aggregate_Fields>;
  nodes: Array<Trade_In_Plans>;
};

export type Trade_In_Plans_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Trade_In_Plans_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Trade_In_Plans_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Trade_In_Plans_Aggregate_Bool_Exp_Count>;
};

export type Trade_In_Plans_Aggregate_Bool_Exp_Bool_And = {
  arguments: Trade_In_Plans_Select_Column_Trade_In_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Trade_In_Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Trade_In_Plans_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Trade_In_Plans_Select_Column_Trade_In_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Trade_In_Plans_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Trade_In_Plans_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Trade_In_Plans_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trade_in_plans" */
export type Trade_In_Plans_Aggregate_Fields = {
  __typename?: 'trade_in_plans_aggregate_fields';
  avg?: Maybe<Trade_In_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Trade_In_Plans_Max_Fields>;
  min?: Maybe<Trade_In_Plans_Min_Fields>;
  stddev?: Maybe<Trade_In_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Trade_In_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trade_In_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Trade_In_Plans_Sum_Fields>;
  var_pop?: Maybe<Trade_In_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Trade_In_Plans_Var_Samp_Fields>;
  variance?: Maybe<Trade_In_Plans_Variance_Fields>;
};


/** aggregate fields of "trade_in_plans" */
export type Trade_In_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_in_plans" */
export type Trade_In_Plans_Aggregate_Order_By = {
  avg?: Maybe<Trade_In_Plans_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Trade_In_Plans_Max_Order_By>;
  min?: Maybe<Trade_In_Plans_Min_Order_By>;
  stddev?: Maybe<Trade_In_Plans_Stddev_Order_By>;
  stddev_pop?: Maybe<Trade_In_Plans_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Trade_In_Plans_Stddev_Samp_Order_By>;
  sum?: Maybe<Trade_In_Plans_Sum_Order_By>;
  var_pop?: Maybe<Trade_In_Plans_Var_Pop_Order_By>;
  var_samp?: Maybe<Trade_In_Plans_Var_Samp_Order_By>;
  variance?: Maybe<Trade_In_Plans_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Trade_In_Plans_Append_Input = {
  autograb_valuation_response?: Maybe<Scalars['jsonb']>;
  autograb_vehicle_response?: Maybe<Scalars['jsonb']>;
  carbuyers_inspection_response?: Maybe<Scalars['jsonb']>;
  summon_valuation_response?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "trade_in_plans" */
export type Trade_In_Plans_Arr_Rel_Insert_Input = {
  data: Array<Trade_In_Plans_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Trade_In_Plans_On_Conflict>;
};

/** aggregate avg on columns */
export type Trade_In_Plans_Avg_Fields = {
  __typename?: 'trade_in_plans_avg_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Avg_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trade_in_plans". All fields are combined with a logical 'AND'. */
export type Trade_In_Plans_Bool_Exp = {
  _and?: Maybe<Array<Trade_In_Plans_Bool_Exp>>;
  _not?: Maybe<Trade_In_Plans_Bool_Exp>;
  _or?: Maybe<Array<Trade_In_Plans_Bool_Exp>>;
  additional_valuations_requested_at?: Maybe<Timestamptz_Comparison_Exp>;
  additional_valuations_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  address_line_3?: Maybe<String_Comparison_Exp>;
  admin_comment?: Maybe<String_Comparison_Exp>;
  autograb_valuation_response?: Maybe<Jsonb_Comparison_Exp>;
  autograb_valuation_success?: Maybe<Boolean_Comparison_Exp>;
  autograb_valuation_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  autograb_vehicle_id?: Maybe<String_Comparison_Exp>;
  autograb_vehicle_response?: Maybe<Jsonb_Comparison_Exp>;
  autograb_vehicle_success?: Maybe<Boolean_Comparison_Exp>;
  badge?: Maybe<String_Comparison_Exp>;
  body_type?: Maybe<String_Comparison_Exp>;
  calculated_value?: Maybe<Numeric_Comparison_Exp>;
  carbuyers_inspection_id?: Maybe<String_Comparison_Exp>;
  carbuyers_inspection_response?: Maybe<Jsonb_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  colour?: Maybe<String_Comparison_Exp>;
  condition?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customer_comment?: Maybe<String_Comparison_Exp>;
  drive?: Maybe<String_Comparison_Exp>;
  engine?: Maybe<String_Comparison_Exp>;
  formatted_address?: Maybe<String_Comparison_Exp>;
  fuel?: Maybe<String_Comparison_Exp>;
  google_place_id?: Maybe<String_Comparison_Exp>;
  has_instant_trade_in_option?: Maybe<Boolean_Comparison_Exp>;
  has_summon_trade_in_option?: Maybe<Boolean_Comparison_Exp>;
  hubspot_deal_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instant_valuation?: Maybe<Numeric_Comparison_Exp>;
  instant_valuation_accepted_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_step?: Maybe<String_Comparison_Exp>;
  latitude?: Maybe<Numeric_Comparison_Exp>;
  longitude?: Maybe<Numeric_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  odometer?: Maybe<Numeric_Comparison_Exp>;
  on_valuation_result_url?: Maybe<String_Comparison_Exp>;
  on_valuation_result_vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  registration_number?: Maybe<String_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  service?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  state_of_registration?: Maybe<String_Comparison_Exp>;
  summon_call_requested_at?: Maybe<Timestamptz_Comparison_Exp>;
  summon_lower_valuation?: Maybe<Numeric_Comparison_Exp>;
  summon_qualified?: Maybe<Boolean_Comparison_Exp>;
  summon_referral_id?: Maybe<String_Comparison_Exp>;
  summon_upper_valuation?: Maybe<Numeric_Comparison_Exp>;
  summon_valuation_response?: Maybe<Jsonb_Comparison_Exp>;
  summon_valuation_success?: Maybe<Boolean_Comparison_Exp>;
  summon_valuation_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  touched?: Maybe<Numeric_Comparison_Exp>;
  trade_in_valuations?: Maybe<Trade_In_Valuations_Bool_Exp>;
  trade_in_valuations_aggregate?: Maybe<Trade_In_Valuations_Aggregate_Bool_Exp>;
  transmission?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  valuers_notified_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
  wheelbase?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "trade_in_plans" */
export enum Trade_In_Plans_Constraint {
  /** unique or primary key constraint on columns "id" */
  TradeInPlansPkey = 'trade_in_plans_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Trade_In_Plans_Delete_At_Path_Input = {
  autograb_valuation_response?: Maybe<Array<Scalars['String']>>;
  autograb_vehicle_response?: Maybe<Array<Scalars['String']>>;
  carbuyers_inspection_response?: Maybe<Array<Scalars['String']>>;
  summon_valuation_response?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Trade_In_Plans_Delete_Elem_Input = {
  autograb_valuation_response?: Maybe<Scalars['Int']>;
  autograb_vehicle_response?: Maybe<Scalars['Int']>;
  carbuyers_inspection_response?: Maybe<Scalars['Int']>;
  summon_valuation_response?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Trade_In_Plans_Delete_Key_Input = {
  autograb_valuation_response?: Maybe<Scalars['String']>;
  autograb_vehicle_response?: Maybe<Scalars['String']>;
  carbuyers_inspection_response?: Maybe<Scalars['String']>;
  summon_valuation_response?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "trade_in_plans" */
export type Trade_In_Plans_Inc_Input = {
  calculated_value?: Maybe<Scalars['numeric']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['numeric']>;
  year?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "trade_in_plans" */
export type Trade_In_Plans_Insert_Input = {
  additional_valuations_requested_at?: Maybe<Scalars['timestamptz']>;
  additional_valuations_updated_at?: Maybe<Scalars['timestamptz']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
  autograb_valuation_response?: Maybe<Scalars['jsonb']>;
  autograb_valuation_success?: Maybe<Scalars['Boolean']>;
  autograb_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  autograb_vehicle_id?: Maybe<Scalars['String']>;
  autograb_vehicle_response?: Maybe<Scalars['jsonb']>;
  autograb_vehicle_success?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculated_value?: Maybe<Scalars['numeric']>;
  carbuyers_inspection_id?: Maybe<Scalars['String']>;
  carbuyers_inspection_response?: Maybe<Scalars['jsonb']>;
  city?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_comment?: Maybe<Scalars['String']>;
  drive?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  instant_valuation_accepted_at?: Maybe<Scalars['timestamptz']>;
  last_step?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  on_valuation_result_url?: Maybe<Scalars['String']>;
  on_valuation_result_vehicle_order_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** pending | processing | declined */
  stage?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  summon_call_requested_at?: Maybe<Scalars['timestamptz']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_qualified?: Maybe<Scalars['Boolean']>;
  summon_referral_id?: Maybe<Scalars['String']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  summon_valuation_response?: Maybe<Scalars['jsonb']>;
  summon_valuation_success?: Maybe<Scalars['Boolean']>;
  summon_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['numeric']>;
  trade_in_valuations?: Maybe<Trade_In_Valuations_Arr_Rel_Insert_Input>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  valuers_notified_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Trade_In_Plans_Max_Fields = {
  __typename?: 'trade_in_plans_max_fields';
  additional_valuations_requested_at?: Maybe<Scalars['timestamptz']>;
  additional_valuations_updated_at?: Maybe<Scalars['timestamptz']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
  autograb_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  autograb_vehicle_id?: Maybe<Scalars['String']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculated_value?: Maybe<Scalars['numeric']>;
  carbuyers_inspection_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_comment?: Maybe<Scalars['String']>;
  drive?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  instant_valuation_accepted_at?: Maybe<Scalars['timestamptz']>;
  last_step?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  on_valuation_result_url?: Maybe<Scalars['String']>;
  on_valuation_result_vehicle_order_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** pending | processing | declined */
  stage?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  summon_call_requested_at?: Maybe<Scalars['timestamptz']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_referral_id?: Maybe<Scalars['String']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  summon_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valuers_notified_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Max_Order_By = {
  additional_valuations_requested_at?: Maybe<Order_By>;
  additional_valuations_updated_at?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  admin_comment?: Maybe<Order_By>;
  autograb_valuation_updated_at?: Maybe<Order_By>;
  autograb_vehicle_id?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  calculated_value?: Maybe<Order_By>;
  carbuyers_inspection_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  colour?: Maybe<Order_By>;
  condition?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_comment?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  engine?: Maybe<Order_By>;
  formatted_address?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  google_place_id?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  instant_valuation_accepted_at?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  on_valuation_result_url?: Maybe<Order_By>;
  on_valuation_result_vehicle_order_id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  service?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  /** pending | processing | declined */
  stage?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  summon_call_requested_at?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_referral_id?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  summon_valuation_updated_at?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  valuers_notified_at?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
  wheelbase?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Trade_In_Plans_Min_Fields = {
  __typename?: 'trade_in_plans_min_fields';
  additional_valuations_requested_at?: Maybe<Scalars['timestamptz']>;
  additional_valuations_updated_at?: Maybe<Scalars['timestamptz']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
  autograb_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  autograb_vehicle_id?: Maybe<Scalars['String']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculated_value?: Maybe<Scalars['numeric']>;
  carbuyers_inspection_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_comment?: Maybe<Scalars['String']>;
  drive?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  instant_valuation_accepted_at?: Maybe<Scalars['timestamptz']>;
  last_step?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  on_valuation_result_url?: Maybe<Scalars['String']>;
  on_valuation_result_vehicle_order_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** pending | processing | declined */
  stage?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  summon_call_requested_at?: Maybe<Scalars['timestamptz']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_referral_id?: Maybe<Scalars['String']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  summon_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valuers_notified_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Min_Order_By = {
  additional_valuations_requested_at?: Maybe<Order_By>;
  additional_valuations_updated_at?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  admin_comment?: Maybe<Order_By>;
  autograb_valuation_updated_at?: Maybe<Order_By>;
  autograb_vehicle_id?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  calculated_value?: Maybe<Order_By>;
  carbuyers_inspection_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  colour?: Maybe<Order_By>;
  condition?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_comment?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  engine?: Maybe<Order_By>;
  formatted_address?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  google_place_id?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  instant_valuation_accepted_at?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  on_valuation_result_url?: Maybe<Order_By>;
  on_valuation_result_vehicle_order_id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  service?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  /** pending | processing | declined */
  stage?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  summon_call_requested_at?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_referral_id?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  summon_valuation_updated_at?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  valuers_notified_at?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
  wheelbase?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "trade_in_plans" */
export type Trade_In_Plans_Mutation_Response = {
  __typename?: 'trade_in_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade_In_Plans>;
};

/** input type for inserting object relation for remote table "trade_in_plans" */
export type Trade_In_Plans_Obj_Rel_Insert_Input = {
  data: Trade_In_Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Trade_In_Plans_On_Conflict>;
};

/** on_conflict condition type for table "trade_in_plans" */
export type Trade_In_Plans_On_Conflict = {
  constraint: Trade_In_Plans_Constraint;
  update_columns?: Array<Trade_In_Plans_Update_Column>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "trade_in_plans". */
export type Trade_In_Plans_Order_By = {
  additional_valuations_requested_at?: Maybe<Order_By>;
  additional_valuations_updated_at?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  admin_comment?: Maybe<Order_By>;
  autograb_valuation_response?: Maybe<Order_By>;
  autograb_valuation_success?: Maybe<Order_By>;
  autograb_valuation_updated_at?: Maybe<Order_By>;
  autograb_vehicle_id?: Maybe<Order_By>;
  autograb_vehicle_response?: Maybe<Order_By>;
  autograb_vehicle_success?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  body_type?: Maybe<Order_By>;
  calculated_value?: Maybe<Order_By>;
  carbuyers_inspection_id?: Maybe<Order_By>;
  carbuyers_inspection_response?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  colour?: Maybe<Order_By>;
  condition?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer_comment?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  engine?: Maybe<Order_By>;
  formatted_address?: Maybe<Order_By>;
  fuel?: Maybe<Order_By>;
  google_place_id?: Maybe<Order_By>;
  has_instant_trade_in_option?: Maybe<Order_By>;
  has_summon_trade_in_option?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  instant_valuation_accepted_at?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  on_valuation_result_url?: Maybe<Order_By>;
  on_valuation_result_vehicle_order_id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  registration_number?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  service?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_of_registration?: Maybe<Order_By>;
  summon_call_requested_at?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_qualified?: Maybe<Order_By>;
  summon_referral_id?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  summon_valuation_response?: Maybe<Order_By>;
  summon_valuation_success?: Maybe<Order_By>;
  summon_valuation_updated_at?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  trade_in_valuations_aggregate?: Maybe<Trade_In_Valuations_Aggregate_Order_By>;
  transmission?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  valuers_notified_at?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
  wheelbase?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: trade_in_plans */
export type Trade_In_Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Trade_In_Plans_Prepend_Input = {
  autograb_valuation_response?: Maybe<Scalars['jsonb']>;
  autograb_vehicle_response?: Maybe<Scalars['jsonb']>;
  carbuyers_inspection_response?: Maybe<Scalars['jsonb']>;
  summon_valuation_response?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "trade_in_plans" */
export enum Trade_In_Plans_Select_Column {
  /** column name */
  AdditionalValuationsRequestedAt = 'additional_valuations_requested_at',
  /** column name */
  AdditionalValuationsUpdatedAt = 'additional_valuations_updated_at',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AddressLine_3 = 'address_line_3',
  /** column name */
  AdminComment = 'admin_comment',
  /** column name */
  AutograbValuationResponse = 'autograb_valuation_response',
  /** column name */
  AutograbValuationSuccess = 'autograb_valuation_success',
  /** column name */
  AutograbValuationUpdatedAt = 'autograb_valuation_updated_at',
  /** column name */
  AutograbVehicleId = 'autograb_vehicle_id',
  /** column name */
  AutograbVehicleResponse = 'autograb_vehicle_response',
  /** column name */
  AutograbVehicleSuccess = 'autograb_vehicle_success',
  /** column name */
  Badge = 'badge',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  CalculatedValue = 'calculated_value',
  /** column name */
  CarbuyersInspectionId = 'carbuyers_inspection_id',
  /** column name */
  CarbuyersInspectionResponse = 'carbuyers_inspection_response',
  /** column name */
  City = 'city',
  /** column name */
  Colour = 'colour',
  /** column name */
  Condition = 'condition',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerComment = 'customer_comment',
  /** column name */
  Drive = 'drive',
  /** column name */
  Engine = 'engine',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  GooglePlaceId = 'google_place_id',
  /** column name */
  HubspotDealId = 'hubspot_deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstantValuation = 'instant_valuation',
  /** column name */
  InstantValuationAcceptedAt = 'instant_valuation_accepted_at',
  /** column name */
  LastStep = 'last_step',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OnValuationResultUrl = 'on_valuation_result_url',
  /** column name */
  OnValuationResultVehicleOrderId = 'on_valuation_result_vehicle_order_id',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  Series = 'series',
  /** column name */
  Service = 'service',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  State = 'state',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  SummonCallRequestedAt = 'summon_call_requested_at',
  /** column name */
  SummonLowerValuation = 'summon_lower_valuation',
  /** column name */
  SummonQualified = 'summon_qualified',
  /** column name */
  SummonReferralId = 'summon_referral_id',
  /** column name */
  SummonUpperValuation = 'summon_upper_valuation',
  /** column name */
  SummonValuationResponse = 'summon_valuation_response',
  /** column name */
  SummonValuationSuccess = 'summon_valuation_success',
  /** column name */
  SummonValuationUpdatedAt = 'summon_valuation_updated_at',
  /** column name */
  Title = 'title',
  /** column name */
  Touched = 'touched',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValuersNotifiedAt = 'valuers_notified_at',
  /** column name */
  VehicleOrderId = 'vehicle_order_id',
  /** column name */
  Wheelbase = 'wheelbase',
  /** column name */
  Year = 'year'
}

/** select "trade_in_plans_aggregate_bool_exp_bool_and_arguments_columns" columns of table "trade_in_plans" */
export enum Trade_In_Plans_Select_Column_Trade_In_Plans_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AutograbValuationSuccess = 'autograb_valuation_success',
  /** column name */
  AutograbVehicleSuccess = 'autograb_vehicle_success',
  /** column name */
  SummonQualified = 'summon_qualified',
  /** column name */
  SummonValuationSuccess = 'summon_valuation_success'
}

/** select "trade_in_plans_aggregate_bool_exp_bool_or_arguments_columns" columns of table "trade_in_plans" */
export enum Trade_In_Plans_Select_Column_Trade_In_Plans_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AutograbValuationSuccess = 'autograb_valuation_success',
  /** column name */
  AutograbVehicleSuccess = 'autograb_vehicle_success',
  /** column name */
  SummonQualified = 'summon_qualified',
  /** column name */
  SummonValuationSuccess = 'summon_valuation_success'
}

/** input type for updating data in table "trade_in_plans" */
export type Trade_In_Plans_Set_Input = {
  additional_valuations_requested_at?: Maybe<Scalars['timestamptz']>;
  additional_valuations_updated_at?: Maybe<Scalars['timestamptz']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
  autograb_valuation_response?: Maybe<Scalars['jsonb']>;
  autograb_valuation_success?: Maybe<Scalars['Boolean']>;
  autograb_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  autograb_vehicle_id?: Maybe<Scalars['String']>;
  autograb_vehicle_response?: Maybe<Scalars['jsonb']>;
  autograb_vehicle_success?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculated_value?: Maybe<Scalars['numeric']>;
  carbuyers_inspection_id?: Maybe<Scalars['String']>;
  carbuyers_inspection_response?: Maybe<Scalars['jsonb']>;
  city?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_comment?: Maybe<Scalars['String']>;
  drive?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  instant_valuation_accepted_at?: Maybe<Scalars['timestamptz']>;
  last_step?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  on_valuation_result_url?: Maybe<Scalars['String']>;
  on_valuation_result_vehicle_order_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** pending | processing | declined */
  stage?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  summon_call_requested_at?: Maybe<Scalars['timestamptz']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_qualified?: Maybe<Scalars['Boolean']>;
  summon_referral_id?: Maybe<Scalars['String']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  summon_valuation_response?: Maybe<Scalars['jsonb']>;
  summon_valuation_success?: Maybe<Scalars['Boolean']>;
  summon_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valuers_notified_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Trade_In_Plans_Stddev_Fields = {
  __typename?: 'trade_in_plans_stddev_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Stddev_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trade_In_Plans_Stddev_Pop_Fields = {
  __typename?: 'trade_in_plans_stddev_pop_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Stddev_Pop_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trade_In_Plans_Stddev_Samp_Fields = {
  __typename?: 'trade_in_plans_stddev_samp_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Stddev_Samp_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Streaming cursor of the table "trade_in_plans" */
export type Trade_In_Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trade_In_Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trade_In_Plans_Stream_Cursor_Value_Input = {
  additional_valuations_requested_at?: Maybe<Scalars['timestamptz']>;
  additional_valuations_updated_at?: Maybe<Scalars['timestamptz']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
  autograb_valuation_response?: Maybe<Scalars['jsonb']>;
  autograb_valuation_success?: Maybe<Scalars['Boolean']>;
  autograb_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  autograb_vehicle_id?: Maybe<Scalars['String']>;
  autograb_vehicle_response?: Maybe<Scalars['jsonb']>;
  autograb_vehicle_success?: Maybe<Scalars['Boolean']>;
  badge?: Maybe<Scalars['String']>;
  body_type?: Maybe<Scalars['String']>;
  calculated_value?: Maybe<Scalars['numeric']>;
  carbuyers_inspection_id?: Maybe<Scalars['String']>;
  carbuyers_inspection_response?: Maybe<Scalars['jsonb']>;
  city?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_comment?: Maybe<Scalars['String']>;
  drive?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  fuel?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  instant_valuation_accepted_at?: Maybe<Scalars['timestamptz']>;
  last_step?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  odometer?: Maybe<Scalars['numeric']>;
  on_valuation_result_url?: Maybe<Scalars['String']>;
  on_valuation_result_vehicle_order_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  registration_number?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  /** pending | processing | declined */
  stage?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_of_registration?: Maybe<Scalars['String']>;
  summon_call_requested_at?: Maybe<Scalars['timestamptz']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_qualified?: Maybe<Scalars['Boolean']>;
  summon_referral_id?: Maybe<Scalars['String']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  summon_valuation_response?: Maybe<Scalars['jsonb']>;
  summon_valuation_success?: Maybe<Scalars['Boolean']>;
  summon_valuation_updated_at?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['numeric']>;
  transmission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valuers_notified_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
  wheelbase?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Trade_In_Plans_Sum_Fields = {
  __typename?: 'trade_in_plans_sum_fields';
  calculated_value?: Maybe<Scalars['numeric']>;
  instant_valuation?: Maybe<Scalars['numeric']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  odometer?: Maybe<Scalars['numeric']>;
  summon_lower_valuation?: Maybe<Scalars['numeric']>;
  summon_upper_valuation?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['numeric']>;
  year?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Sum_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "trade_in_plans" */
export enum Trade_In_Plans_Update_Column {
  /** column name */
  AdditionalValuationsRequestedAt = 'additional_valuations_requested_at',
  /** column name */
  AdditionalValuationsUpdatedAt = 'additional_valuations_updated_at',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AddressLine_3 = 'address_line_3',
  /** column name */
  AdminComment = 'admin_comment',
  /** column name */
  AutograbValuationResponse = 'autograb_valuation_response',
  /** column name */
  AutograbValuationSuccess = 'autograb_valuation_success',
  /** column name */
  AutograbValuationUpdatedAt = 'autograb_valuation_updated_at',
  /** column name */
  AutograbVehicleId = 'autograb_vehicle_id',
  /** column name */
  AutograbVehicleResponse = 'autograb_vehicle_response',
  /** column name */
  AutograbVehicleSuccess = 'autograb_vehicle_success',
  /** column name */
  Badge = 'badge',
  /** column name */
  BodyType = 'body_type',
  /** column name */
  CalculatedValue = 'calculated_value',
  /** column name */
  CarbuyersInspectionId = 'carbuyers_inspection_id',
  /** column name */
  CarbuyersInspectionResponse = 'carbuyers_inspection_response',
  /** column name */
  City = 'city',
  /** column name */
  Colour = 'colour',
  /** column name */
  Condition = 'condition',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerComment = 'customer_comment',
  /** column name */
  Drive = 'drive',
  /** column name */
  Engine = 'engine',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  GooglePlaceId = 'google_place_id',
  /** column name */
  HubspotDealId = 'hubspot_deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstantValuation = 'instant_valuation',
  /** column name */
  InstantValuationAcceptedAt = 'instant_valuation_accepted_at',
  /** column name */
  LastStep = 'last_step',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OnValuationResultUrl = 'on_valuation_result_url',
  /** column name */
  OnValuationResultVehicleOrderId = 'on_valuation_result_vehicle_order_id',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  RegistrationNumber = 'registration_number',
  /** column name */
  Series = 'series',
  /** column name */
  Service = 'service',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  State = 'state',
  /** column name */
  StateOfRegistration = 'state_of_registration',
  /** column name */
  SummonCallRequestedAt = 'summon_call_requested_at',
  /** column name */
  SummonLowerValuation = 'summon_lower_valuation',
  /** column name */
  SummonQualified = 'summon_qualified',
  /** column name */
  SummonReferralId = 'summon_referral_id',
  /** column name */
  SummonUpperValuation = 'summon_upper_valuation',
  /** column name */
  SummonValuationResponse = 'summon_valuation_response',
  /** column name */
  SummonValuationSuccess = 'summon_valuation_success',
  /** column name */
  SummonValuationUpdatedAt = 'summon_valuation_updated_at',
  /** column name */
  Title = 'title',
  /** column name */
  Touched = 'touched',
  /** column name */
  Transmission = 'transmission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValuersNotifiedAt = 'valuers_notified_at',
  /** column name */
  VehicleOrderId = 'vehicle_order_id',
  /** column name */
  Wheelbase = 'wheelbase',
  /** column name */
  Year = 'year'
}

export type Trade_In_Plans_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Trade_In_Plans_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Trade_In_Plans_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Trade_In_Plans_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Trade_In_Plans_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Trade_In_Plans_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Trade_In_Plans_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Trade_In_Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trade_In_Plans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Trade_In_Plans_Var_Pop_Fields = {
  __typename?: 'trade_in_plans_var_pop_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Var_Pop_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trade_In_Plans_Var_Samp_Fields = {
  __typename?: 'trade_in_plans_var_samp_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Var_Samp_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Trade_In_Plans_Variance_Fields = {
  __typename?: 'trade_in_plans_variance_fields';
  calculated_value?: Maybe<Scalars['Float']>;
  instant_valuation?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
  summon_lower_valuation?: Maybe<Scalars['Float']>;
  summon_upper_valuation?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_in_plans" */
export type Trade_In_Plans_Variance_Order_By = {
  calculated_value?: Maybe<Order_By>;
  instant_valuation?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  odometer?: Maybe<Order_By>;
  summon_lower_valuation?: Maybe<Order_By>;
  summon_upper_valuation?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "trade_in_valuations" */
export type Trade_In_Valuations = {
  __typename?: 'trade_in_valuations';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  trade_in_plan: Trade_In_Plans;
  trade_in_plan_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['Int'];
  valuer_code: Scalars['String'];
  valuer_comments?: Maybe<Scalars['String']>;
};

/** aggregated selection of "trade_in_valuations" */
export type Trade_In_Valuations_Aggregate = {
  __typename?: 'trade_in_valuations_aggregate';
  aggregate?: Maybe<Trade_In_Valuations_Aggregate_Fields>;
  nodes: Array<Trade_In_Valuations>;
};

export type Trade_In_Valuations_Aggregate_Bool_Exp = {
  count?: Maybe<Trade_In_Valuations_Aggregate_Bool_Exp_Count>;
};

export type Trade_In_Valuations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Trade_In_Valuations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trade_in_valuations" */
export type Trade_In_Valuations_Aggregate_Fields = {
  __typename?: 'trade_in_valuations_aggregate_fields';
  avg?: Maybe<Trade_In_Valuations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Trade_In_Valuations_Max_Fields>;
  min?: Maybe<Trade_In_Valuations_Min_Fields>;
  stddev?: Maybe<Trade_In_Valuations_Stddev_Fields>;
  stddev_pop?: Maybe<Trade_In_Valuations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trade_In_Valuations_Stddev_Samp_Fields>;
  sum?: Maybe<Trade_In_Valuations_Sum_Fields>;
  var_pop?: Maybe<Trade_In_Valuations_Var_Pop_Fields>;
  var_samp?: Maybe<Trade_In_Valuations_Var_Samp_Fields>;
  variance?: Maybe<Trade_In_Valuations_Variance_Fields>;
};


/** aggregate fields of "trade_in_valuations" */
export type Trade_In_Valuations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Trade_In_Valuations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_in_valuations" */
export type Trade_In_Valuations_Aggregate_Order_By = {
  avg?: Maybe<Trade_In_Valuations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Trade_In_Valuations_Max_Order_By>;
  min?: Maybe<Trade_In_Valuations_Min_Order_By>;
  stddev?: Maybe<Trade_In_Valuations_Stddev_Order_By>;
  stddev_pop?: Maybe<Trade_In_Valuations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Trade_In_Valuations_Stddev_Samp_Order_By>;
  sum?: Maybe<Trade_In_Valuations_Sum_Order_By>;
  var_pop?: Maybe<Trade_In_Valuations_Var_Pop_Order_By>;
  var_samp?: Maybe<Trade_In_Valuations_Var_Samp_Order_By>;
  variance?: Maybe<Trade_In_Valuations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trade_in_valuations" */
export type Trade_In_Valuations_Arr_Rel_Insert_Input = {
  data: Array<Trade_In_Valuations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Trade_In_Valuations_On_Conflict>;
};

/** aggregate avg on columns */
export type Trade_In_Valuations_Avg_Fields = {
  __typename?: 'trade_in_valuations_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Avg_Order_By = {
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trade_in_valuations". All fields are combined with a logical 'AND'. */
export type Trade_In_Valuations_Bool_Exp = {
  _and?: Maybe<Array<Trade_In_Valuations_Bool_Exp>>;
  _not?: Maybe<Trade_In_Valuations_Bool_Exp>;
  _or?: Maybe<Array<Trade_In_Valuations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  trade_in_plan?: Maybe<Trade_In_Plans_Bool_Exp>;
  trade_in_plan_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Int_Comparison_Exp>;
  valuer_code?: Maybe<String_Comparison_Exp>;
  valuer_comments?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trade_in_valuations" */
export enum Trade_In_Valuations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TradeInValuationsPkey = 'trade_in_valuations_pkey',
  /** unique or primary key constraint on columns "valuer_code", "trade_in_plan_id" */
  TradeInValuationsTradeInPlanIdValuerCodeKey = 'trade_in_valuations_trade_in_plan_id_valuer_code_key'
}

/** input type for incrementing numeric columns in table "trade_in_valuations" */
export type Trade_In_Valuations_Inc_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_in_valuations" */
export type Trade_In_Valuations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  trade_in_plan?: Maybe<Trade_In_Plans_Obj_Rel_Insert_Input>;
  trade_in_plan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
  valuer_code?: Maybe<Scalars['String']>;
  valuer_comments?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Trade_In_Valuations_Max_Fields = {
  __typename?: 'trade_in_valuations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  trade_in_plan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
  valuer_code?: Maybe<Scalars['String']>;
  valuer_comments?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trade_in_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  valuer_code?: Maybe<Order_By>;
  valuer_comments?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Trade_In_Valuations_Min_Fields = {
  __typename?: 'trade_in_valuations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  trade_in_plan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
  valuer_code?: Maybe<Scalars['String']>;
  valuer_comments?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trade_in_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  valuer_code?: Maybe<Order_By>;
  valuer_comments?: Maybe<Order_By>;
};

/** response of any mutation on the table "trade_in_valuations" */
export type Trade_In_Valuations_Mutation_Response = {
  __typename?: 'trade_in_valuations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade_In_Valuations>;
};

/** on_conflict condition type for table "trade_in_valuations" */
export type Trade_In_Valuations_On_Conflict = {
  constraint: Trade_In_Valuations_Constraint;
  update_columns?: Array<Trade_In_Valuations_Update_Column>;
  where?: Maybe<Trade_In_Valuations_Bool_Exp>;
};

/** Ordering options when selecting data from "trade_in_valuations". */
export type Trade_In_Valuations_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trade_in_plan?: Maybe<Trade_In_Plans_Order_By>;
  trade_in_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  valuer_code?: Maybe<Order_By>;
  valuer_comments?: Maybe<Order_By>;
};

/** primary key columns input for table: trade_in_valuations */
export type Trade_In_Valuations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "trade_in_valuations" */
export enum Trade_In_Valuations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TradeInPlanId = 'trade_in_plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  ValuerCode = 'valuer_code',
  /** column name */
  ValuerComments = 'valuer_comments'
}

/** input type for updating data in table "trade_in_valuations" */
export type Trade_In_Valuations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  trade_in_plan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
  valuer_code?: Maybe<Scalars['String']>;
  valuer_comments?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Trade_In_Valuations_Stddev_Fields = {
  __typename?: 'trade_in_valuations_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Stddev_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trade_In_Valuations_Stddev_Pop_Fields = {
  __typename?: 'trade_in_valuations_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trade_In_Valuations_Stddev_Samp_Fields = {
  __typename?: 'trade_in_valuations_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** Streaming cursor of the table "trade_in_valuations" */
export type Trade_In_Valuations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trade_In_Valuations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trade_In_Valuations_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  trade_in_plan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
  valuer_code?: Maybe<Scalars['String']>;
  valuer_comments?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Trade_In_Valuations_Sum_Fields = {
  __typename?: 'trade_in_valuations_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Sum_Order_By = {
  value?: Maybe<Order_By>;
};

/** update columns of table "trade_in_valuations" */
export enum Trade_In_Valuations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TradeInPlanId = 'trade_in_plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  ValuerCode = 'valuer_code',
  /** column name */
  ValuerComments = 'valuer_comments'
}

export type Trade_In_Valuations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Trade_In_Valuations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Trade_In_Valuations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trade_In_Valuations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Trade_In_Valuations_Var_Pop_Fields = {
  __typename?: 'trade_in_valuations_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Var_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trade_In_Valuations_Var_Samp_Fields = {
  __typename?: 'trade_in_valuations_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Var_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Trade_In_Valuations_Variance_Fields = {
  __typename?: 'trade_in_valuations_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_in_valuations" */
export type Trade_In_Valuations_Variance_Order_By = {
  value?: Maybe<Order_By>;
};

/** columns and relationships of "transport_types" */
export type Transport_Types = {
  __typename?: 'transport_types';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_car_share: Scalars['Boolean'];
  is_loan_car: Scalars['Boolean'];
  /** An array relationship */
  location_transport_types: Array<Location_Transport_Types>;
  /** An aggregate relationship */
  location_transport_types_aggregate: Location_Transport_Types_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  service_bookings: Array<Service_Bookings>;
  /** An aggregate relationship */
  service_bookings_aggregate: Service_Bookings_Aggregate;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "transport_types" */
export type Transport_TypesLocation_Transport_TypesArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


/** columns and relationships of "transport_types" */
export type Transport_TypesLocation_Transport_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Transport_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Transport_Types_Order_By>>;
  where?: Maybe<Location_Transport_Types_Bool_Exp>;
};


/** columns and relationships of "transport_types" */
export type Transport_TypesService_BookingsArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


/** columns and relationships of "transport_types" */
export type Transport_TypesService_Bookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};

/** aggregated selection of "transport_types" */
export type Transport_Types_Aggregate = {
  __typename?: 'transport_types_aggregate';
  aggregate?: Maybe<Transport_Types_Aggregate_Fields>;
  nodes: Array<Transport_Types>;
};

/** aggregate fields of "transport_types" */
export type Transport_Types_Aggregate_Fields = {
  __typename?: 'transport_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Transport_Types_Max_Fields>;
  min?: Maybe<Transport_Types_Min_Fields>;
};


/** aggregate fields of "transport_types" */
export type Transport_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transport_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "transport_types". All fields are combined with a logical 'AND'. */
export type Transport_Types_Bool_Exp = {
  _and?: Maybe<Array<Transport_Types_Bool_Exp>>;
  _not?: Maybe<Transport_Types_Bool_Exp>;
  _or?: Maybe<Array<Transport_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_car_share?: Maybe<Boolean_Comparison_Exp>;
  is_loan_car?: Maybe<Boolean_Comparison_Exp>;
  location_transport_types?: Maybe<Location_Transport_Types_Bool_Exp>;
  location_transport_types_aggregate?: Maybe<Location_Transport_Types_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  service_bookings?: Maybe<Service_Bookings_Bool_Exp>;
  service_bookings_aggregate?: Maybe<Service_Bookings_Aggregate_Bool_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transport_types" */
export enum Transport_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransportTypesPkey = 'transport_types_pkey'
}

/** input type for inserting data into table "transport_types" */
export type Transport_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_car_share?: Maybe<Scalars['Boolean']>;
  is_loan_car?: Maybe<Scalars['Boolean']>;
  location_transport_types?: Maybe<Location_Transport_Types_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  service_bookings?: Maybe<Service_Bookings_Arr_Rel_Insert_Input>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transport_Types_Max_Fields = {
  __typename?: 'transport_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Transport_Types_Min_Fields = {
  __typename?: 'transport_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transport_types" */
export type Transport_Types_Mutation_Response = {
  __typename?: 'transport_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transport_Types>;
};

/** input type for inserting object relation for remote table "transport_types" */
export type Transport_Types_Obj_Rel_Insert_Input = {
  data: Transport_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Transport_Types_On_Conflict>;
};

/** on_conflict condition type for table "transport_types" */
export type Transport_Types_On_Conflict = {
  constraint: Transport_Types_Constraint;
  update_columns?: Array<Transport_Types_Update_Column>;
  where?: Maybe<Transport_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "transport_types". */
export type Transport_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_car_share?: Maybe<Order_By>;
  is_loan_car?: Maybe<Order_By>;
  location_transport_types_aggregate?: Maybe<Location_Transport_Types_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  service_bookings_aggregate?: Maybe<Service_Bookings_Aggregate_Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: transport_types */
export type Transport_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "transport_types" */
export enum Transport_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsCarShare = 'is_car_share',
  /** column name */
  IsLoanCar = 'is_loan_car',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transport_types" */
export type Transport_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_car_share?: Maybe<Scalars['Boolean']>;
  is_loan_car?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "transport_types" */
export type Transport_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transport_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transport_Types_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_car_share?: Maybe<Scalars['Boolean']>;
  is_loan_car?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "transport_types" */
export enum Transport_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsCarShare = 'is_car_share',
  /** column name */
  IsLoanCar = 'is_loan_car',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Transport_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Transport_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transport_Types_Bool_Exp;
};

/** columns and relationships of "user_interests" */
export type User_Interests = {
  __typename?: 'user_interests';
  /** An object relationship */
  interest: Interests;
  interest_code: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_interests" */
export type User_Interests_Aggregate = {
  __typename?: 'user_interests_aggregate';
  aggregate?: Maybe<User_Interests_Aggregate_Fields>;
  nodes: Array<User_Interests>;
};

export type User_Interests_Aggregate_Bool_Exp = {
  count?: Maybe<User_Interests_Aggregate_Bool_Exp_Count>;
};

export type User_Interests_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<User_Interests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_interests" */
export type User_Interests_Aggregate_Fields = {
  __typename?: 'user_interests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Interests_Max_Fields>;
  min?: Maybe<User_Interests_Min_Fields>;
};


/** aggregate fields of "user_interests" */
export type User_Interests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_interests" */
export type User_Interests_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Interests_Max_Order_By>;
  min?: Maybe<User_Interests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_interests" */
export type User_Interests_Arr_Rel_Insert_Input = {
  data: Array<User_Interests_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<User_Interests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_interests". All fields are combined with a logical 'AND'. */
export type User_Interests_Bool_Exp = {
  _and?: Maybe<Array<User_Interests_Bool_Exp>>;
  _not?: Maybe<User_Interests_Bool_Exp>;
  _or?: Maybe<Array<User_Interests_Bool_Exp>>;
  interest?: Maybe<Interests_Bool_Exp>;
  interest_code?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_interests" */
export enum User_Interests_Constraint {
  /** unique or primary key constraint on columns "interest_code", "user_id" */
  UserInterestsPkey = 'user_interests_pkey'
}

/** input type for inserting data into table "user_interests" */
export type User_Interests_Insert_Input = {
  interest?: Maybe<Interests_Obj_Rel_Insert_Input>;
  interest_code?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Interests_Max_Fields = {
  __typename?: 'user_interests_max_fields';
  interest_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_interests" */
export type User_Interests_Max_Order_By = {
  interest_code?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Interests_Min_Fields = {
  __typename?: 'user_interests_min_fields';
  interest_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_interests" */
export type User_Interests_Min_Order_By = {
  interest_code?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_interests" */
export type User_Interests_Mutation_Response = {
  __typename?: 'user_interests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Interests>;
};

/** on_conflict condition type for table "user_interests" */
export type User_Interests_On_Conflict = {
  constraint: User_Interests_Constraint;
  update_columns?: Array<User_Interests_Update_Column>;
  where?: Maybe<User_Interests_Bool_Exp>;
};

/** Ordering options when selecting data from "user_interests". */
export type User_Interests_Order_By = {
  interest?: Maybe<Interests_Order_By>;
  interest_code?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: user_interests */
export type User_Interests_Pk_Columns_Input = {
  interest_code: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_interests" */
export enum User_Interests_Select_Column {
  /** column name */
  InterestCode = 'interest_code',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_interests" */
export type User_Interests_Set_Input = {
  interest_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_interests" */
export type User_Interests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Interests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Interests_Stream_Cursor_Value_Input = {
  interest_code?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "user_interests" */
export enum User_Interests_Update_Column {
  /** column name */
  InterestCode = 'interest_code',
  /** column name */
  UserId = 'user_id'
}

export type User_Interests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Interests_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Interests_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array of access region codes that the user is authorised to access. */
  access_regions: Scalars['jsonb'];
  /** An array relationship */
  activities: Array<Activities>;
  /** An array relationship */
  activitiesByUserId: Array<Activities>;
  /** An aggregate relationship */
  activitiesByUserId_aggregate: Activities_Aggregate;
  /** An aggregate relationship */
  activities_aggregate: Activities_Aggregate;
  address?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  /** An array relationship */
  auction_bids: Array<Auction_Bids>;
  /** An aggregate relationship */
  auction_bids_aggregate: Auction_Bids_Aggregate;
  /** An array relationship */
  authored_notes: Array<Notes>;
  /** An aggregate relationship */
  authored_notes_aggregate: Notes_Aggregate;
  basiq_user_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  calculator_submissions: Array<Calculator_Submission>;
  /** An aggregate relationship */
  calculator_submissions_aggregate: Calculator_Submission_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  custom_field_values: Array<Custom_Field_Values>;
  /** An aggregate relationship */
  custom_field_values_aggregate: Custom_Field_Values_Aggregate;
  /** An array relationship */
  custom_vehicles: Array<Custom_Vehicles>;
  /** An aggregate relationship */
  custom_vehicles_aggregate: Custom_Vehicles_Aggregate;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driving_licence?: Maybe<Scalars['String']>;
  driving_licence_expiry?: Maybe<Scalars['date']>;
  driving_licence_number?: Maybe<Scalars['String']>;
  driving_licence_state?: Maybe<Scalars['String']>;
  driving_licence_type?: Maybe<Scalars['String']>;
  driving_licence_version?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** An array relationship */
  finance_applications: Array<Finance_Applications>;
  /** An aggregate relationship */
  finance_applications_aggregate: Finance_Applications_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  form_submissions: Array<Form_Submissions>;
  /** An aggregate relationship */
  form_submissions_aggregate: Form_Submissions_Aggregate;
  full_name?: Maybe<Scalars['String']>;
  /** male | female | other */
  gender?: Maybe<Scalars['String']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insights_visitors: Array<Insights_Visitors>;
  insights_visitors_aggregate: Insights_Visitors_Aggregate;
  /** Whether the user is permitted to publish apps. */
  is_app_author: Scalars['Boolean'];
  is_home_owner?: Maybe<Scalars['Boolean']>;
  is_legal_adult_age?: Maybe<Scalars['Boolean']>;
  is_resident?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  last_enquiry_location?: Maybe<Locations>;
  last_enquiry_location_id?: Maybe<Scalars['uuid']>;
  last_enquiry_notes?: Maybe<Scalars['String']>;
  last_enquiry_reason?: Maybe<Scalars['String']>;
  last_logged_in: Scalars['timestamptz'];
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  login_methods: Scalars['jsonb'];
  middle_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  moments: Array<Moments>;
  /** An array relationship */
  momentsByOwnerId: Array<Moments>;
  /** An aggregate relationship */
  momentsByOwnerId_aggregate: Moments_Aggregate;
  /** An aggregate relationship */
  moments_aggregate: Moments_Aggregate;
  /** An array relationship */
  notes: Array<Notes>;
  /** An array relationship */
  notesByCreatedById: Array<Notes>;
  /** An aggregate relationship */
  notesByCreatedById_aggregate: Notes_Aggregate;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  payment_methods: Array<Payment_Methods>;
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** email | phone | sms */
  preferred_contact_method: Scalars['String'];
  pronoun?: Maybe<Scalars['String']>;
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id: Scalars['Int'];
  referred_by?: Maybe<Scalars['Int']>;
  /** An array relationship */
  rental_reservations: Array<Rental_Reservations>;
  /** An aggregate relationship */
  rental_reservations_aggregate: Rental_Reservations_Aggregate;
  /** admin | staff | customer | public */
  role: Scalars['String'];
  /** An array relationship */
  service_bookings: Array<Service_Bookings>;
  /** An aggregate relationship */
  service_bookings_aggregate: Service_Bookings_Aggregate;
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  /** An array relationship */
  showroom_users: Array<Showroom_Users>;
  /** An aggregate relationship */
  showroom_users_aggregate: Showroom_Users_Aggregate;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  tasks_involving_user: Array<Tasks>;
  /** An aggregate relationship */
  tasks_involving_user_aggregate: Tasks_Aggregate;
  /** An array relationship */
  testDrivesByUserId: Array<Test_Drives>;
  /** An aggregate relationship */
  testDrivesByUserId_aggregate: Test_Drives_Aggregate;
  /** An array relationship */
  test_drives: Array<Test_Drives>;
  /** An aggregate relationship */
  test_drives_aggregate: Test_Drives_Aggregate;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  /** An array relationship */
  trade_in_plans: Array<Trade_In_Plans>;
  /** An aggregate relationship */
  trade_in_plans_aggregate: Trade_In_Plans_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_interests: Array<User_Interests>;
  /** An aggregate relationship */
  user_interests_aggregate: User_Interests_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  utm?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  vehicleOrdersByOwnerId: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicleOrdersByOwnerId_aggregate: Vehicle_Orders_Aggregate;
  /** An array relationship */
  vehicle_orders: Array<Vehicle_Orders>;
  /** An aggregate relationship */
  vehicle_orders_aggregate: Vehicle_Orders_Aggregate;
  /** An array relationship */
  wish_lists: Array<Wish_List>;
  /** An aggregate relationship */
  wish_lists_aggregate: Wish_List_Aggregate;
};


/** columns and relationships of "users" */
export type UsersAccess_RegionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersActivitiesByUserIdArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersActivitiesByUserId_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAuction_BidsArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAuction_Bids_AggregateArgs = {
  distinct_on?: Maybe<Array<Auction_Bids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auction_Bids_Order_By>>;
  where?: Maybe<Auction_Bids_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAuthored_NotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAuthored_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCalculator_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCalculator_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Calculator_Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calculator_Submission_Order_By>>;
  where?: Maybe<Calculator_Submission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustom_Field_ValuesArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustom_Field_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustom_VehiclesArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCustom_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFinance_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFinance_Applications_AggregateArgs = {
  distinct_on?: Maybe<Array<Finance_Applications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Finance_Applications_Order_By>>;
  where?: Maybe<Finance_Applications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersForm_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersForm_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Form_Submissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Form_Submissions_Order_By>>;
  where?: Maybe<Form_Submissions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInsights_VisitorsArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInsights_Visitors_AggregateArgs = {
  distinct_on?: Maybe<Array<Insights_Visitors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insights_Visitors_Order_By>>;
  where?: Maybe<Insights_Visitors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLogin_MethodsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersMomentsArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMomentsByOwnerIdArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMomentsByOwnerId_AggregateArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMoments_AggregateArgs = {
  distinct_on?: Maybe<Array<Moments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Moments_Order_By>>;
  where?: Maybe<Moments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotesByCreatedByIdArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotesByCreatedById_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Methods_Order_By>>;
  where?: Maybe<Payment_Methods_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRental_ReservationsArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRental_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Reservations_Order_By>>;
  where?: Maybe<Rental_Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersService_BookingsArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersService_Bookings_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Bookings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Bookings_Order_By>>;
  where?: Maybe<Service_Bookings_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSessionsArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sessions_Order_By>>;
  where?: Maybe<Sessions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersShowroom_UsersArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersShowroom_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Users_Order_By>>;
  where?: Maybe<Showroom_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTasks_Involving_UserArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTasks_Involving_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTestDrivesByUserIdArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTestDrivesByUserId_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTest_DrivesArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTest_Drives_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Drives_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Drives_Order_By>>;
  where?: Maybe<Test_Drives_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTrade_In_PlansArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTrade_In_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_InterestsArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Interests_Order_By>>;
  where?: Maybe<User_Interests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUtmArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersVehicleOrdersByOwnerIdArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVehicleOrdersByOwnerId_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVehicle_OrdersArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVehicle_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Orders_Order_By>>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersWish_ListsArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersWish_Lists_AggregateArgs = {
  distinct_on?: Maybe<Array<Wish_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wish_List_Order_By>>;
  where?: Maybe<Wish_List_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['jsonb']>;
  address?: Maybe<Scalars['jsonb']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  utm?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  access_regions?: Maybe<Jsonb_Comparison_Exp>;
  activities?: Maybe<Activities_Bool_Exp>;
  activitiesByUserId?: Maybe<Activities_Bool_Exp>;
  activitiesByUserId_aggregate?: Maybe<Activities_Aggregate_Bool_Exp>;
  activities_aggregate?: Maybe<Activities_Aggregate_Bool_Exp>;
  address?: Maybe<Jsonb_Comparison_Exp>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  auction_bids?: Maybe<Auction_Bids_Bool_Exp>;
  auction_bids_aggregate?: Maybe<Auction_Bids_Aggregate_Bool_Exp>;
  authored_notes?: Maybe<Notes_Bool_Exp>;
  authored_notes_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  basiq_user_id?: Maybe<String_Comparison_Exp>;
  calculator_submissions?: Maybe<Calculator_Submission_Bool_Exp>;
  calculator_submissions_aggregate?: Maybe<Calculator_Submission_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_field_values?: Maybe<Custom_Field_Values_Bool_Exp>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Bool_Exp>;
  custom_vehicles?: Maybe<Custom_Vehicles_Bool_Exp>;
  custom_vehicles_aggregate?: Maybe<Custom_Vehicles_Aggregate_Bool_Exp>;
  date_of_birth?: Maybe<Date_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  driving_licence?: Maybe<String_Comparison_Exp>;
  driving_licence_expiry?: Maybe<Date_Comparison_Exp>;
  driving_licence_number?: Maybe<String_Comparison_Exp>;
  driving_licence_state?: Maybe<String_Comparison_Exp>;
  driving_licence_type?: Maybe<String_Comparison_Exp>;
  driving_licence_version?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  finance_applications?: Maybe<Finance_Applications_Bool_Exp>;
  finance_applications_aggregate?: Maybe<Finance_Applications_Aggregate_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  form_submissions?: Maybe<Form_Submissions_Bool_Exp>;
  form_submissions_aggregate?: Maybe<Form_Submissions_Aggregate_Bool_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  hubspot_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_app_author?: Maybe<Boolean_Comparison_Exp>;
  is_home_owner?: Maybe<Boolean_Comparison_Exp>;
  is_legal_adult_age?: Maybe<Boolean_Comparison_Exp>;
  is_resident?: Maybe<Boolean_Comparison_Exp>;
  last_enquiry_location?: Maybe<Locations_Bool_Exp>;
  last_enquiry_location_id?: Maybe<Uuid_Comparison_Exp>;
  last_enquiry_notes?: Maybe<String_Comparison_Exp>;
  last_enquiry_reason?: Maybe<String_Comparison_Exp>;
  last_logged_in?: Maybe<Timestamptz_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  locations?: Maybe<Locations_Bool_Exp>;
  locations_aggregate?: Maybe<Locations_Aggregate_Bool_Exp>;
  login_methods?: Maybe<Jsonb_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  moments?: Maybe<Moments_Bool_Exp>;
  momentsByOwnerId?: Maybe<Moments_Bool_Exp>;
  momentsByOwnerId_aggregate?: Maybe<Moments_Aggregate_Bool_Exp>;
  moments_aggregate?: Maybe<Moments_Aggregate_Bool_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  notesByCreatedById?: Maybe<Notes_Bool_Exp>;
  notesByCreatedById_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  notes_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  payment_methods?: Maybe<Payment_Methods_Bool_Exp>;
  payment_methods_aggregate?: Maybe<Payment_Methods_Aggregate_Bool_Exp>;
  payment_provider_name?: Maybe<String_Comparison_Exp>;
  payment_provider_reference?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  preferred_contact_method?: Maybe<String_Comparison_Exp>;
  pronoun?: Maybe<String_Comparison_Exp>;
  referral_count?: Maybe<Bigint_Comparison_Exp>;
  referral_id?: Maybe<Int_Comparison_Exp>;
  referred_by?: Maybe<Int_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  service_bookings?: Maybe<Service_Bookings_Bool_Exp>;
  service_bookings_aggregate?: Maybe<Service_Bookings_Aggregate_Bool_Exp>;
  sessions?: Maybe<Sessions_Bool_Exp>;
  sessions_aggregate?: Maybe<Sessions_Aggregate_Bool_Exp>;
  showroom_users?: Maybe<Showroom_Users_Bool_Exp>;
  showroom_users_aggregate?: Maybe<Showroom_Users_Aggregate_Bool_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  tasks?: Maybe<Tasks_Bool_Exp>;
  tasks_aggregate?: Maybe<Tasks_Aggregate_Bool_Exp>;
  tasks_involving_user?: Maybe<Tasks_Bool_Exp>;
  tasks_involving_user_aggregate?: Maybe<Tasks_Aggregate_Bool_Exp>;
  testDrivesByUserId?: Maybe<Test_Drives_Bool_Exp>;
  testDrivesByUserId_aggregate?: Maybe<Test_Drives_Aggregate_Bool_Exp>;
  test_drives?: Maybe<Test_Drives_Bool_Exp>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  touched?: Maybe<Int_Comparison_Exp>;
  trade_in_plans?: Maybe<Trade_In_Plans_Bool_Exp>;
  trade_in_plans_aggregate?: Maybe<Trade_In_Plans_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_interests?: Maybe<User_Interests_Bool_Exp>;
  user_interests_aggregate?: Maybe<User_Interests_Aggregate_Bool_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  users_aggregate?: Maybe<Users_Aggregate_Bool_Exp>;
  utm?: Maybe<Jsonb_Comparison_Exp>;
  vehicleOrdersByOwnerId?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicleOrdersByOwnerId_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
  vehicle_orders?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp>;
  wish_lists?: Maybe<Wish_List_Bool_Exp>;
  wish_lists_aggregate?: Maybe<Wish_List_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "referral_id" */
  UsersReferralIdKey = 'users_referral_id_key',
  /** unique or primary key constraint on columns "source", "source_id" */
  UsersSourceSourceIdKey = 'users_source_source_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Array<Scalars['String']>>;
  address?: Maybe<Array<Scalars['String']>>;
  login_methods?: Maybe<Array<Scalars['String']>>;
  utm?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['Int']>;
  login_methods?: Maybe<Scalars['Int']>;
  utm?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  login_methods?: Maybe<Scalars['String']>;
  utm?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  touched?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['jsonb']>;
  activities?: Maybe<Activities_Arr_Rel_Insert_Input>;
  activitiesByUserId?: Maybe<Activities_Arr_Rel_Insert_Input>;
  address?: Maybe<Scalars['jsonb']>;
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  auction_bids?: Maybe<Auction_Bids_Arr_Rel_Insert_Input>;
  authored_notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  basiq_user_id?: Maybe<Scalars['String']>;
  calculator_submissions?: Maybe<Calculator_Submission_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_field_values?: Maybe<Custom_Field_Values_Arr_Rel_Insert_Input>;
  custom_vehicles?: Maybe<Custom_Vehicles_Arr_Rel_Insert_Input>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driving_licence?: Maybe<Scalars['String']>;
  driving_licence_expiry?: Maybe<Scalars['date']>;
  driving_licence_number?: Maybe<Scalars['String']>;
  driving_licence_state?: Maybe<Scalars['String']>;
  driving_licence_type?: Maybe<Scalars['String']>;
  driving_licence_version?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  finance_applications?: Maybe<Finance_Applications_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  form_submissions?: Maybe<Form_Submissions_Arr_Rel_Insert_Input>;
  full_name?: Maybe<Scalars['String']>;
  /** male | female | other */
  gender?: Maybe<Scalars['String']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Whether the user is permitted to publish apps. */
  is_app_author?: Maybe<Scalars['Boolean']>;
  is_home_owner?: Maybe<Scalars['Boolean']>;
  is_legal_adult_age?: Maybe<Scalars['Boolean']>;
  is_resident?: Maybe<Scalars['Boolean']>;
  last_enquiry_location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  last_enquiry_location_id?: Maybe<Scalars['uuid']>;
  last_enquiry_notes?: Maybe<Scalars['String']>;
  last_enquiry_reason?: Maybe<Scalars['String']>;
  last_logged_in?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  locations?: Maybe<Locations_Arr_Rel_Insert_Input>;
  login_methods?: Maybe<Scalars['jsonb']>;
  middle_name?: Maybe<Scalars['String']>;
  moments?: Maybe<Moments_Arr_Rel_Insert_Input>;
  momentsByOwnerId?: Maybe<Moments_Arr_Rel_Insert_Input>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  notesByCreatedById?: Maybe<Notes_Arr_Rel_Insert_Input>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  payment_methods?: Maybe<Payment_Methods_Arr_Rel_Insert_Input>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Scalars['String']>;
  pronoun?: Maybe<Scalars['String']>;
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  /** admin | staff | customer | public */
  role?: Maybe<Scalars['String']>;
  service_bookings?: Maybe<Service_Bookings_Arr_Rel_Insert_Input>;
  sessions?: Maybe<Sessions_Arr_Rel_Insert_Input>;
  showroom_users?: Maybe<Showroom_Users_Arr_Rel_Insert_Input>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  tasks?: Maybe<Tasks_Arr_Rel_Insert_Input>;
  tasks_involving_user?: Maybe<Tasks_Arr_Rel_Insert_Input>;
  testDrivesByUserId?: Maybe<Test_Drives_Arr_Rel_Insert_Input>;
  test_drives?: Maybe<Test_Drives_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  trade_in_plans?: Maybe<Trade_In_Plans_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_interests?: Maybe<User_Interests_Arr_Rel_Insert_Input>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  utm?: Maybe<Scalars['jsonb']>;
  vehicleOrdersByOwnerId?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
  vehicle_orders?: Maybe<Vehicle_Orders_Arr_Rel_Insert_Input>;
  wish_lists?: Maybe<Wish_List_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  basiq_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driving_licence?: Maybe<Scalars['String']>;
  driving_licence_expiry?: Maybe<Scalars['date']>;
  driving_licence_number?: Maybe<Scalars['String']>;
  driving_licence_state?: Maybe<Scalars['String']>;
  driving_licence_type?: Maybe<Scalars['String']>;
  driving_licence_version?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  /** male | female | other */
  gender?: Maybe<Scalars['String']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_enquiry_location_id?: Maybe<Scalars['uuid']>;
  last_enquiry_notes?: Maybe<Scalars['String']>;
  last_enquiry_reason?: Maybe<Scalars['String']>;
  last_logged_in?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Scalars['String']>;
  pronoun?: Maybe<Scalars['String']>;
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  /** admin | staff | customer | public */
  role?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  basiq_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driving_licence?: Maybe<Order_By>;
  driving_licence_expiry?: Maybe<Order_By>;
  driving_licence_number?: Maybe<Order_By>;
  driving_licence_state?: Maybe<Order_By>;
  driving_licence_type?: Maybe<Order_By>;
  driving_licence_version?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  /** male | female | other */
  gender?: Maybe<Order_By>;
  hubspot_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_enquiry_location_id?: Maybe<Order_By>;
  last_enquiry_notes?: Maybe<Order_By>;
  last_enquiry_reason?: Maybe<Order_By>;
  last_logged_in?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Order_By>;
  pronoun?: Maybe<Order_By>;
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  /** admin | staff | customer | public */
  role?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  basiq_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driving_licence?: Maybe<Scalars['String']>;
  driving_licence_expiry?: Maybe<Scalars['date']>;
  driving_licence_number?: Maybe<Scalars['String']>;
  driving_licence_state?: Maybe<Scalars['String']>;
  driving_licence_type?: Maybe<Scalars['String']>;
  driving_licence_version?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  /** male | female | other */
  gender?: Maybe<Scalars['String']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_enquiry_location_id?: Maybe<Scalars['uuid']>;
  last_enquiry_notes?: Maybe<Scalars['String']>;
  last_enquiry_reason?: Maybe<Scalars['String']>;
  last_logged_in?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Scalars['String']>;
  pronoun?: Maybe<Scalars['String']>;
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  /** admin | staff | customer | public */
  role?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  basiq_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driving_licence?: Maybe<Order_By>;
  driving_licence_expiry?: Maybe<Order_By>;
  driving_licence_number?: Maybe<Order_By>;
  driving_licence_state?: Maybe<Order_By>;
  driving_licence_type?: Maybe<Order_By>;
  driving_licence_version?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  /** male | female | other */
  gender?: Maybe<Order_By>;
  hubspot_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_enquiry_location_id?: Maybe<Order_By>;
  last_enquiry_notes?: Maybe<Order_By>;
  last_enquiry_reason?: Maybe<Order_By>;
  last_logged_in?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Order_By>;
  pronoun?: Maybe<Order_By>;
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  /** admin | staff | customer | public */
  role?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  access_regions?: Maybe<Order_By>;
  activitiesByUserId_aggregate?: Maybe<Activities_Aggregate_Order_By>;
  activities_aggregate?: Maybe<Activities_Aggregate_Order_By>;
  address?: Maybe<Order_By>;
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  auction_bids_aggregate?: Maybe<Auction_Bids_Aggregate_Order_By>;
  authored_notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  basiq_user_id?: Maybe<Order_By>;
  calculator_submissions_aggregate?: Maybe<Calculator_Submission_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Order_By>;
  custom_vehicles_aggregate?: Maybe<Custom_Vehicles_Aggregate_Order_By>;
  date_of_birth?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  driving_licence?: Maybe<Order_By>;
  driving_licence_expiry?: Maybe<Order_By>;
  driving_licence_number?: Maybe<Order_By>;
  driving_licence_state?: Maybe<Order_By>;
  driving_licence_type?: Maybe<Order_By>;
  driving_licence_version?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  finance_applications_aggregate?: Maybe<Finance_Applications_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  form_submissions_aggregate?: Maybe<Form_Submissions_Aggregate_Order_By>;
  full_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  hubspot_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_app_author?: Maybe<Order_By>;
  is_home_owner?: Maybe<Order_By>;
  is_legal_adult_age?: Maybe<Order_By>;
  is_resident?: Maybe<Order_By>;
  last_enquiry_location?: Maybe<Locations_Order_By>;
  last_enquiry_location_id?: Maybe<Order_By>;
  last_enquiry_notes?: Maybe<Order_By>;
  last_enquiry_reason?: Maybe<Order_By>;
  last_logged_in?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Locations_Aggregate_Order_By>;
  login_methods?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  momentsByOwnerId_aggregate?: Maybe<Moments_Aggregate_Order_By>;
  moments_aggregate?: Maybe<Moments_Aggregate_Order_By>;
  notesByCreatedById_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  payment_methods_aggregate?: Maybe<Payment_Methods_Aggregate_Order_By>;
  payment_provider_name?: Maybe<Order_By>;
  payment_provider_reference?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  preferred_contact_method?: Maybe<Order_By>;
  pronoun?: Maybe<Order_By>;
  referral_count?: Maybe<Order_By>;
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  service_bookings_aggregate?: Maybe<Service_Bookings_Aggregate_Order_By>;
  sessions_aggregate?: Maybe<Sessions_Aggregate_Order_By>;
  showroom_users_aggregate?: Maybe<Showroom_Users_Aggregate_Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Tasks_Aggregate_Order_By>;
  tasks_involving_user_aggregate?: Maybe<Tasks_Aggregate_Order_By>;
  testDrivesByUserId_aggregate?: Maybe<Test_Drives_Aggregate_Order_By>;
  test_drives_aggregate?: Maybe<Test_Drives_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  trade_in_plans_aggregate?: Maybe<Trade_In_Plans_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_interests_aggregate?: Maybe<User_Interests_Aggregate_Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  utm?: Maybe<Order_By>;
  vehicleOrdersByOwnerId_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
  vehicle_orders_aggregate?: Maybe<Vehicle_Orders_Aggregate_Order_By>;
  wish_lists_aggregate?: Maybe<Wish_List_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['jsonb']>;
  address?: Maybe<Scalars['jsonb']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  utm?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AccessRegions = 'access_regions',
  /** column name */
  Address = 'address',
  /** column name */
  BasiqUserId = 'basiq_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrivingLicence = 'driving_licence',
  /** column name */
  DrivingLicenceExpiry = 'driving_licence_expiry',
  /** column name */
  DrivingLicenceNumber = 'driving_licence_number',
  /** column name */
  DrivingLicenceState = 'driving_licence_state',
  /** column name */
  DrivingLicenceType = 'driving_licence_type',
  /** column name */
  DrivingLicenceVersion = 'driving_licence_version',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  HubspotId = 'hubspot_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAppAuthor = 'is_app_author',
  /** column name */
  IsHomeOwner = 'is_home_owner',
  /** column name */
  IsLegalAdultAge = 'is_legal_adult_age',
  /** column name */
  IsResident = 'is_resident',
  /** column name */
  LastEnquiryLocationId = 'last_enquiry_location_id',
  /** column name */
  LastEnquiryNotes = 'last_enquiry_notes',
  /** column name */
  LastEnquiryReason = 'last_enquiry_reason',
  /** column name */
  LastLoggedIn = 'last_logged_in',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LoginMethods = 'login_methods',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PaymentProviderName = 'payment_provider_name',
  /** column name */
  PaymentProviderReference = 'payment_provider_reference',
  /** column name */
  Phone = 'phone',
  /** column name */
  PreferredContactMethod = 'preferred_contact_method',
  /** column name */
  Pronoun = 'pronoun',
  /** column name */
  ReferralId = 'referral_id',
  /** column name */
  ReferredBy = 'referred_by',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  Title = 'title',
  /** column name */
  Touched = 'touched',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Utm = 'utm'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAppAuthor = 'is_app_author',
  /** column name */
  IsHomeOwner = 'is_home_owner',
  /** column name */
  IsLegalAdultAge = 'is_legal_adult_age',
  /** column name */
  IsResident = 'is_resident'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAppAuthor = 'is_app_author',
  /** column name */
  IsHomeOwner = 'is_home_owner',
  /** column name */
  IsLegalAdultAge = 'is_legal_adult_age',
  /** column name */
  IsResident = 'is_resident'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['jsonb']>;
  address?: Maybe<Scalars['jsonb']>;
  basiq_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driving_licence?: Maybe<Scalars['String']>;
  driving_licence_expiry?: Maybe<Scalars['date']>;
  driving_licence_number?: Maybe<Scalars['String']>;
  driving_licence_state?: Maybe<Scalars['String']>;
  driving_licence_type?: Maybe<Scalars['String']>;
  driving_licence_version?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  /** male | female | other */
  gender?: Maybe<Scalars['String']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Whether the user is permitted to publish apps. */
  is_app_author?: Maybe<Scalars['Boolean']>;
  is_home_owner?: Maybe<Scalars['Boolean']>;
  is_legal_adult_age?: Maybe<Scalars['Boolean']>;
  is_resident?: Maybe<Scalars['Boolean']>;
  last_enquiry_location_id?: Maybe<Scalars['uuid']>;
  last_enquiry_notes?: Maybe<Scalars['String']>;
  last_enquiry_reason?: Maybe<Scalars['String']>;
  last_logged_in?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  middle_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Scalars['String']>;
  pronoun?: Maybe<Scalars['String']>;
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  /** admin | staff | customer | public */
  role?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utm?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  /** An array of access region codes that the user is authorised to access. */
  access_regions?: Maybe<Scalars['jsonb']>;
  address?: Maybe<Scalars['jsonb']>;
  basiq_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  driving_licence?: Maybe<Scalars['String']>;
  driving_licence_expiry?: Maybe<Scalars['date']>;
  driving_licence_number?: Maybe<Scalars['String']>;
  driving_licence_state?: Maybe<Scalars['String']>;
  driving_licence_type?: Maybe<Scalars['String']>;
  driving_licence_version?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  /** male | female | other */
  gender?: Maybe<Scalars['String']>;
  hubspot_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Whether the user is permitted to publish apps. */
  is_app_author?: Maybe<Scalars['Boolean']>;
  is_home_owner?: Maybe<Scalars['Boolean']>;
  is_legal_adult_age?: Maybe<Scalars['Boolean']>;
  is_resident?: Maybe<Scalars['Boolean']>;
  last_enquiry_location_id?: Maybe<Scalars['uuid']>;
  last_enquiry_notes?: Maybe<Scalars['String']>;
  last_enquiry_reason?: Maybe<Scalars['String']>;
  last_logged_in?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  login_methods?: Maybe<Scalars['jsonb']>;
  middle_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  payment_provider_name?: Maybe<Scalars['String']>;
  payment_provider_reference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** email | phone | sms */
  preferred_contact_method?: Maybe<Scalars['String']>;
  pronoun?: Maybe<Scalars['String']>;
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  /** admin | staff | customer | public */
  role?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  touched?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  utm?: Maybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Int']>;
  referred_by?: Maybe<Scalars['Int']>;
  touched?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AccessRegions = 'access_regions',
  /** column name */
  Address = 'address',
  /** column name */
  BasiqUserId = 'basiq_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrivingLicence = 'driving_licence',
  /** column name */
  DrivingLicenceExpiry = 'driving_licence_expiry',
  /** column name */
  DrivingLicenceNumber = 'driving_licence_number',
  /** column name */
  DrivingLicenceState = 'driving_licence_state',
  /** column name */
  DrivingLicenceType = 'driving_licence_type',
  /** column name */
  DrivingLicenceVersion = 'driving_licence_version',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  HubspotId = 'hubspot_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAppAuthor = 'is_app_author',
  /** column name */
  IsHomeOwner = 'is_home_owner',
  /** column name */
  IsLegalAdultAge = 'is_legal_adult_age',
  /** column name */
  IsResident = 'is_resident',
  /** column name */
  LastEnquiryLocationId = 'last_enquiry_location_id',
  /** column name */
  LastEnquiryNotes = 'last_enquiry_notes',
  /** column name */
  LastEnquiryReason = 'last_enquiry_reason',
  /** column name */
  LastLoggedIn = 'last_logged_in',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LoginMethods = 'login_methods',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PaymentProviderName = 'payment_provider_name',
  /** column name */
  PaymentProviderReference = 'payment_provider_reference',
  /** column name */
  Phone = 'phone',
  /** column name */
  PreferredContactMethod = 'preferred_contact_method',
  /** column name */
  Pronoun = 'pronoun',
  /** column name */
  ReferralId = 'referral_id',
  /** column name */
  ReferredBy = 'referred_by',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  Title = 'title',
  /** column name */
  Touched = 'touched',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Utm = 'utm'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  /** A computed field, executes function "users_referral_count" */
  referral_count?: Maybe<Scalars['bigint']>;
  referral_id?: Maybe<Scalars['Float']>;
  referred_by?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  referral_id?: Maybe<Order_By>;
  referred_by?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** Trade in valuers */
export type Valuers = {
  __typename?: 'valuers';
  code: Scalars['String'];
  /** email | phone | slack */
  contact_method: Scalars['String'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  phone?: Maybe<Scalars['String']>;
  /** A Slack Webhook URL */
  slack?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "valuers" */
export type Valuers_Aggregate = {
  __typename?: 'valuers_aggregate';
  aggregate?: Maybe<Valuers_Aggregate_Fields>;
  nodes: Array<Valuers>;
};

/** aggregate fields of "valuers" */
export type Valuers_Aggregate_Fields = {
  __typename?: 'valuers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Valuers_Max_Fields>;
  min?: Maybe<Valuers_Min_Fields>;
};


/** aggregate fields of "valuers" */
export type Valuers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Valuers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "valuers". All fields are combined with a logical 'AND'. */
export type Valuers_Bool_Exp = {
  _and?: Maybe<Array<Valuers_Bool_Exp>>;
  _not?: Maybe<Valuers_Bool_Exp>;
  _or?: Maybe<Array<Valuers_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  contact_method?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  slack?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "valuers" */
export enum Valuers_Constraint {
  /** unique or primary key constraint on columns "code" */
  ValuersCodeKey = 'valuers_code_key',
  /** unique or primary key constraint on columns "id" */
  ValuersPkey = 'valuers_pkey'
}

/** input type for inserting data into table "valuers" */
export type Valuers_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  /** email | phone | slack */
  contact_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** A Slack Webhook URL */
  slack?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Valuers_Max_Fields = {
  __typename?: 'valuers_max_fields';
  code?: Maybe<Scalars['String']>;
  /** email | phone | slack */
  contact_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** A Slack Webhook URL */
  slack?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Valuers_Min_Fields = {
  __typename?: 'valuers_min_fields';
  code?: Maybe<Scalars['String']>;
  /** email | phone | slack */
  contact_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** A Slack Webhook URL */
  slack?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "valuers" */
export type Valuers_Mutation_Response = {
  __typename?: 'valuers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Valuers>;
};

/** on_conflict condition type for table "valuers" */
export type Valuers_On_Conflict = {
  constraint: Valuers_Constraint;
  update_columns?: Array<Valuers_Update_Column>;
  where?: Maybe<Valuers_Bool_Exp>;
};

/** Ordering options when selecting data from "valuers". */
export type Valuers_Order_By = {
  code?: Maybe<Order_By>;
  contact_method?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  slack?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: valuers */
export type Valuers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "valuers" */
export enum Valuers_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  ContactMethod = 'contact_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Slack = 'slack',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "valuers" */
export type Valuers_Set_Input = {
  code?: Maybe<Scalars['String']>;
  /** email | phone | slack */
  contact_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** A Slack Webhook URL */
  slack?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "valuers" */
export type Valuers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Valuers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Valuers_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  /** email | phone | slack */
  contact_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** A Slack Webhook URL */
  slack?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "valuers" */
export enum Valuers_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  ContactMethod = 'contact_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Slack = 'slack',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Valuers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Valuers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Valuers_Bool_Exp;
};

/** columns and relationships of "vehicle_conditions" */
export type Vehicle_Conditions = {
  __typename?: 'vehicle_conditions';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "vehicle_conditions" */
export type Vehicle_Conditions_Aggregate = {
  __typename?: 'vehicle_conditions_aggregate';
  aggregate?: Maybe<Vehicle_Conditions_Aggregate_Fields>;
  nodes: Array<Vehicle_Conditions>;
};

/** aggregate fields of "vehicle_conditions" */
export type Vehicle_Conditions_Aggregate_Fields = {
  __typename?: 'vehicle_conditions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Conditions_Max_Fields>;
  min?: Maybe<Vehicle_Conditions_Min_Fields>;
};


/** aggregate fields of "vehicle_conditions" */
export type Vehicle_Conditions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Conditions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vehicle_conditions". All fields are combined with a logical 'AND'. */
export type Vehicle_Conditions_Bool_Exp = {
  _and?: Maybe<Array<Vehicle_Conditions_Bool_Exp>>;
  _not?: Maybe<Vehicle_Conditions_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Conditions_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_conditions" */
export enum Vehicle_Conditions_Constraint {
  /** unique or primary key constraint on columns "value" */
  VehicleConditionsPkey = 'vehicle_conditions_pkey'
}

export enum Vehicle_Conditions_Enum {
  /** Demo Vehicle */
  Demo = 'demo',
  /** New Vehicle  */
  New = 'new',
  /** Used Vehicle  */
  Used = 'used'
}

/** Boolean expression to compare columns of type "vehicle_conditions_enum". All fields are combined with logical 'AND'. */
export type Vehicle_Conditions_Enum_Comparison_Exp = {
  _eq?: Maybe<Vehicle_Conditions_Enum>;
  _in?: Maybe<Array<Vehicle_Conditions_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Vehicle_Conditions_Enum>;
  _nin?: Maybe<Array<Vehicle_Conditions_Enum>>;
};

/** input type for inserting data into table "vehicle_conditions" */
export type Vehicle_Conditions_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vehicle_Conditions_Max_Fields = {
  __typename?: 'vehicle_conditions_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vehicle_Conditions_Min_Fields = {
  __typename?: 'vehicle_conditions_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "vehicle_conditions" */
export type Vehicle_Conditions_Mutation_Response = {
  __typename?: 'vehicle_conditions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Conditions>;
};

/** on_conflict condition type for table "vehicle_conditions" */
export type Vehicle_Conditions_On_Conflict = {
  constraint: Vehicle_Conditions_Constraint;
  update_columns?: Array<Vehicle_Conditions_Update_Column>;
  where?: Maybe<Vehicle_Conditions_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_conditions". */
export type Vehicle_Conditions_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: vehicle_conditions */
export type Vehicle_Conditions_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "vehicle_conditions" */
export enum Vehicle_Conditions_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "vehicle_conditions" */
export type Vehicle_Conditions_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "vehicle_conditions" */
export type Vehicle_Conditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Conditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Conditions_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "vehicle_conditions" */
export enum Vehicle_Conditions_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Vehicle_Conditions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Conditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Conditions_Bool_Exp;
};

/** columns and relationships of "vehicle_lists" */
export type Vehicle_Lists = {
  __typename?: 'vehicle_lists';
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Scalars['String']>;
  default_settlement_method: Scalars['citext'];
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drop_in_ads: Scalars['jsonb'];
  /** An array relationship */
  filter_fields: Array<Filter_Fields>;
  /** An aggregate relationship */
  filter_fields_aggregate: Filter_Fields_Aggregate;
  footer_partnership_type?: Maybe<Scalars['String']>;
  header_image_url?: Maybe<Scalars['String']>;
  header_logo_url?: Maybe<Scalars['String']>;
  /** small | medium | large */
  header_size?: Maybe<Scalars['String']>;
  header_text_color?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_default: Scalars['Boolean'];
  meta_description: Scalars['String'];
  meta_title?: Maybe<Scalars['String']>;
  /** A root-relative path to the listing page */
  path?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom: Showrooms;
  showroom_id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause: Scalars['jsonb'];
};


/** columns and relationships of "vehicle_lists" */
export type Vehicle_ListsDrop_In_AdsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vehicle_lists" */
export type Vehicle_ListsFilter_FieldsArgs = {
  distinct_on?: Maybe<Array<Filter_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Fields_Order_By>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


/** columns and relationships of "vehicle_lists" */
export type Vehicle_ListsFilter_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Fields_Order_By>>;
  where?: Maybe<Filter_Fields_Bool_Exp>;
};


/** columns and relationships of "vehicle_lists" */
export type Vehicle_ListsWhere_ClauseArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vehicle_lists" */
export type Vehicle_Lists_Aggregate = {
  __typename?: 'vehicle_lists_aggregate';
  aggregate?: Maybe<Vehicle_Lists_Aggregate_Fields>;
  nodes: Array<Vehicle_Lists>;
};

export type Vehicle_Lists_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Vehicle_Lists_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Vehicle_Lists_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Vehicle_Lists_Aggregate_Bool_Exp_Count>;
};

export type Vehicle_Lists_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vehicle_Lists_Select_Column_Vehicle_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicle_Lists_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vehicle_Lists_Select_Column_Vehicle_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicle_Lists_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Lists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vehicle_lists" */
export type Vehicle_Lists_Aggregate_Fields = {
  __typename?: 'vehicle_lists_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Lists_Max_Fields>;
  min?: Maybe<Vehicle_Lists_Min_Fields>;
};


/** aggregate fields of "vehicle_lists" */
export type Vehicle_Lists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Lists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_lists" */
export type Vehicle_Lists_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Lists_Max_Order_By>;
  min?: Maybe<Vehicle_Lists_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vehicle_Lists_Append_Input = {
  drop_in_ads?: Maybe<Scalars['jsonb']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "vehicle_lists" */
export type Vehicle_Lists_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Lists_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Lists_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vehicle_lists". All fields are combined with a logical 'AND'. */
export type Vehicle_Lists_Bool_Exp = {
  _and?: Maybe<Array<Vehicle_Lists_Bool_Exp>>;
  _not?: Maybe<Vehicle_Lists_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Lists_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  default_settlement_method?: Maybe<Citext_Comparison_Exp>;
  default_sort?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  drop_in_ads?: Maybe<Jsonb_Comparison_Exp>;
  filter_fields?: Maybe<Filter_Fields_Bool_Exp>;
  filter_fields_aggregate?: Maybe<Filter_Fields_Aggregate_Bool_Exp>;
  footer_partnership_type?: Maybe<String_Comparison_Exp>;
  header_image_url?: Maybe<String_Comparison_Exp>;
  header_logo_url?: Maybe<String_Comparison_Exp>;
  header_size?: Maybe<String_Comparison_Exp>;
  header_text_color?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_default?: Maybe<Boolean_Comparison_Exp>;
  meta_description?: Maybe<String_Comparison_Exp>;
  meta_title?: Maybe<String_Comparison_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  where_clause?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_lists" */
export enum Vehicle_Lists_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleListsPkey = 'vehicle_lists_pkey',
  /** unique or primary key constraint on columns "showroom_id", "code" */
  VehicleListsShowroomIdCodeKey = 'vehicle_lists_showroom_id_code_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vehicle_Lists_Delete_At_Path_Input = {
  drop_in_ads?: Maybe<Array<Scalars['String']>>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vehicle_Lists_Delete_Elem_Input = {
  drop_in_ads?: Maybe<Scalars['Int']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vehicle_Lists_Delete_Key_Input = {
  drop_in_ads?: Maybe<Scalars['String']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "vehicle_lists" */
export type Vehicle_Lists_Insert_Input = {
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Scalars['String']>;
  default_settlement_method?: Maybe<Scalars['citext']>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drop_in_ads?: Maybe<Scalars['jsonb']>;
  filter_fields?: Maybe<Filter_Fields_Arr_Rel_Insert_Input>;
  footer_partnership_type?: Maybe<Scalars['String']>;
  header_image_url?: Maybe<Scalars['String']>;
  header_logo_url?: Maybe<Scalars['String']>;
  /** small | medium | large */
  header_size?: Maybe<Scalars['String']>;
  header_text_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_default?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Vehicle_Lists_Max_Fields = {
  __typename?: 'vehicle_lists_max_fields';
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Scalars['String']>;
  default_settlement_method?: Maybe<Scalars['citext']>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  footer_partnership_type?: Maybe<Scalars['String']>;
  header_image_url?: Maybe<Scalars['String']>;
  header_logo_url?: Maybe<Scalars['String']>;
  /** small | medium | large */
  header_size?: Maybe<Scalars['String']>;
  header_text_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** A root-relative path to the listing page */
  path?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicle_lists" */
export type Vehicle_Lists_Max_Order_By = {
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Order_By>;
  default_settlement_method?: Maybe<Order_By>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  footer_partnership_type?: Maybe<Order_By>;
  header_image_url?: Maybe<Order_By>;
  header_logo_url?: Maybe<Order_By>;
  /** small | medium | large */
  header_size?: Maybe<Order_By>;
  header_text_color?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  meta_title?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Lists_Min_Fields = {
  __typename?: 'vehicle_lists_min_fields';
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Scalars['String']>;
  default_settlement_method?: Maybe<Scalars['citext']>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  footer_partnership_type?: Maybe<Scalars['String']>;
  header_image_url?: Maybe<Scalars['String']>;
  header_logo_url?: Maybe<Scalars['String']>;
  /** small | medium | large */
  header_size?: Maybe<Scalars['String']>;
  header_text_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** A root-relative path to the listing page */
  path?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicle_lists" */
export type Vehicle_Lists_Min_Order_By = {
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Order_By>;
  default_settlement_method?: Maybe<Order_By>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  footer_partnership_type?: Maybe<Order_By>;
  header_image_url?: Maybe<Order_By>;
  header_logo_url?: Maybe<Order_By>;
  /** small | medium | large */
  header_size?: Maybe<Order_By>;
  header_text_color?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  meta_title?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicle_lists" */
export type Vehicle_Lists_Mutation_Response = {
  __typename?: 'vehicle_lists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Lists>;
};

/** input type for inserting object relation for remote table "vehicle_lists" */
export type Vehicle_Lists_Obj_Rel_Insert_Input = {
  data: Vehicle_Lists_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Lists_On_Conflict>;
};

/** on_conflict condition type for table "vehicle_lists" */
export type Vehicle_Lists_On_Conflict = {
  constraint: Vehicle_Lists_Constraint;
  update_columns?: Array<Vehicle_Lists_Update_Column>;
  where?: Maybe<Vehicle_Lists_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_lists". */
export type Vehicle_Lists_Order_By = {
  code?: Maybe<Order_By>;
  default_settlement_method?: Maybe<Order_By>;
  default_sort?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drop_in_ads?: Maybe<Order_By>;
  filter_fields_aggregate?: Maybe<Filter_Fields_Aggregate_Order_By>;
  footer_partnership_type?: Maybe<Order_By>;
  header_image_url?: Maybe<Order_By>;
  header_logo_url?: Maybe<Order_By>;
  header_size?: Maybe<Order_By>;
  header_text_color?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_default?: Maybe<Order_By>;
  meta_description?: Maybe<Order_By>;
  meta_title?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  where_clause?: Maybe<Order_By>;
};

/** primary key columns input for table: vehicle_lists */
export type Vehicle_Lists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vehicle_Lists_Prepend_Input = {
  drop_in_ads?: Maybe<Scalars['jsonb']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "vehicle_lists" */
export enum Vehicle_Lists_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  DefaultSettlementMethod = 'default_settlement_method',
  /** column name */
  DefaultSort = 'default_sort',
  /** column name */
  Description = 'description',
  /** column name */
  DropInAds = 'drop_in_ads',
  /** column name */
  FooterPartnershipType = 'footer_partnership_type',
  /** column name */
  HeaderImageUrl = 'header_image_url',
  /** column name */
  HeaderLogoUrl = 'header_logo_url',
  /** column name */
  HeaderSize = 'header_size',
  /** column name */
  HeaderTextColor = 'header_text_color',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Title = 'title',
  /** column name */
  WhereClause = 'where_clause'
}

/** select "vehicle_lists_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vehicle_lists" */
export enum Vehicle_Lists_Select_Column_Vehicle_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDefault = 'is_default'
}

/** select "vehicle_lists_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vehicle_lists" */
export enum Vehicle_Lists_Select_Column_Vehicle_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDefault = 'is_default'
}

/** input type for updating data in table "vehicle_lists" */
export type Vehicle_Lists_Set_Input = {
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Scalars['String']>;
  default_settlement_method?: Maybe<Scalars['citext']>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drop_in_ads?: Maybe<Scalars['jsonb']>;
  footer_partnership_type?: Maybe<Scalars['String']>;
  header_image_url?: Maybe<Scalars['String']>;
  header_logo_url?: Maybe<Scalars['String']>;
  /** small | medium | large */
  header_size?: Maybe<Scalars['String']>;
  header_text_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_default?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "vehicle_lists" */
export type Vehicle_Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Lists_Stream_Cursor_Value_Input = {
  /** A URL-friendly code which will be used to form the URL of the page */
  code?: Maybe<Scalars['String']>;
  default_settlement_method?: Maybe<Scalars['citext']>;
  /** mostViewed deliveryDateAscending deliveryDateDescending priceAscending priceDescending */
  default_sort?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drop_in_ads?: Maybe<Scalars['jsonb']>;
  footer_partnership_type?: Maybe<Scalars['String']>;
  header_image_url?: Maybe<Scalars['String']>;
  header_logo_url?: Maybe<Scalars['String']>;
  /** small | medium | large */
  header_size?: Maybe<Scalars['String']>;
  header_text_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_default?: Maybe<Scalars['Boolean']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  /** The Hasura 'where' clause for this list which will determine which vehicles it should query within the showroom. */
  where_clause?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "vehicle_lists" */
export enum Vehicle_Lists_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  DefaultSettlementMethod = 'default_settlement_method',
  /** column name */
  DefaultSort = 'default_sort',
  /** column name */
  Description = 'description',
  /** column name */
  DropInAds = 'drop_in_ads',
  /** column name */
  FooterPartnershipType = 'footer_partnership_type',
  /** column name */
  HeaderImageUrl = 'header_image_url',
  /** column name */
  HeaderLogoUrl = 'header_logo_url',
  /** column name */
  HeaderSize = 'header_size',
  /** column name */
  HeaderTextColor = 'header_text_color',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  MetaTitle = 'meta_title',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Title = 'title',
  /** column name */
  WhereClause = 'where_clause'
}

export type Vehicle_Lists_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Vehicle_Lists_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Vehicle_Lists_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Vehicle_Lists_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Vehicle_Lists_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Vehicle_Lists_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Lists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Lists_Bool_Exp;
};

/** columns and relationships of "vehicle_order_activity" */
export type Vehicle_Order_Activity = {
  __typename?: 'vehicle_order_activity';
  code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** The time the activity eventuated */
  timestamp: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle_order: Vehicle_Orders;
  vehicle_order_id: Scalars['uuid'];
};

/** aggregated selection of "vehicle_order_activity" */
export type Vehicle_Order_Activity_Aggregate = {
  __typename?: 'vehicle_order_activity_aggregate';
  aggregate?: Maybe<Vehicle_Order_Activity_Aggregate_Fields>;
  nodes: Array<Vehicle_Order_Activity>;
};

export type Vehicle_Order_Activity_Aggregate_Bool_Exp = {
  count?: Maybe<Vehicle_Order_Activity_Aggregate_Bool_Exp_Count>;
};

export type Vehicle_Order_Activity_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vehicle_order_activity" */
export type Vehicle_Order_Activity_Aggregate_Fields = {
  __typename?: 'vehicle_order_activity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Order_Activity_Max_Fields>;
  min?: Maybe<Vehicle_Order_Activity_Min_Fields>;
};


/** aggregate fields of "vehicle_order_activity" */
export type Vehicle_Order_Activity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Order_Activity_Max_Order_By>;
  min?: Maybe<Vehicle_Order_Activity_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Order_Activity_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Order_Activity_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vehicle_order_activity". All fields are combined with a logical 'AND'. */
export type Vehicle_Order_Activity_Bool_Exp = {
  _and?: Maybe<Array<Vehicle_Order_Activity_Bool_Exp>>;
  _not?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Order_Activity_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_order_activity" */
export enum Vehicle_Order_Activity_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleOrderActivityPkey = 'vehicle_order_activity_pkey'
}

/** input type for inserting data into table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Vehicle_Order_Activity_Max_Fields = {
  __typename?: 'vehicle_order_activity_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Max_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Order_Activity_Min_Fields = {
  __typename?: 'vehicle_order_activity_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Min_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Mutation_Response = {
  __typename?: 'vehicle_order_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Order_Activity>;
};

/** on_conflict condition type for table "vehicle_order_activity" */
export type Vehicle_Order_Activity_On_Conflict = {
  constraint: Vehicle_Order_Activity_Constraint;
  update_columns?: Array<Vehicle_Order_Activity_Update_Column>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_order_activity". */
export type Vehicle_Order_Activity_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: vehicle_order_activity */
export type Vehicle_Order_Activity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicle_order_activity" */
export enum Vehicle_Order_Activity_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "vehicle_order_activity" */
export type Vehicle_Order_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Order_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Order_Activity_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The time the activity eventuated */
  timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "vehicle_order_activity" */
export enum Vehicle_Order_Activity_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Vehicle_Order_Activity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Order_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Order_Activity_Bool_Exp;
};

/** columns and relationships of "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants = {
  __typename?: 'vehicle_order_product_variants';
  /** An object relationship */
  product_variant: Product_Variants;
  product_variant_id: Scalars['uuid'];
  /** An object relationship */
  vehicle_order: Vehicle_Orders;
  vehicle_order_id: Scalars['uuid'];
};

/** aggregated selection of "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Aggregate = {
  __typename?: 'vehicle_order_product_variants_aggregate';
  aggregate?: Maybe<Vehicle_Order_Product_Variants_Aggregate_Fields>;
  nodes: Array<Vehicle_Order_Product_Variants>;
};

export type Vehicle_Order_Product_Variants_Aggregate_Bool_Exp = {
  count?: Maybe<Vehicle_Order_Product_Variants_Aggregate_Bool_Exp_Count>;
};

export type Vehicle_Order_Product_Variants_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Aggregate_Fields = {
  __typename?: 'vehicle_order_product_variants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Order_Product_Variants_Max_Fields>;
  min?: Maybe<Vehicle_Order_Product_Variants_Min_Fields>;
};


/** aggregate fields of "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Order_Product_Variants_Max_Order_By>;
  min?: Maybe<Vehicle_Order_Product_Variants_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Order_Product_Variants_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Order_Product_Variants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vehicle_order_product_variants". All fields are combined with a logical 'AND'. */
export type Vehicle_Order_Product_Variants_Bool_Exp = {
  _and?: Maybe<Array<Vehicle_Order_Product_Variants_Bool_Exp>>;
  _not?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Order_Product_Variants_Bool_Exp>>;
  product_variant?: Maybe<Product_Variants_Bool_Exp>;
  product_variant_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_order?: Maybe<Vehicle_Orders_Bool_Exp>;
  vehicle_order_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_order_product_variants" */
export enum Vehicle_Order_Product_Variants_Constraint {
  /** unique or primary key constraint on columns "vehicle_order_id", "product_variant_id" */
  VehicleOrderProductsPkey = 'vehicle_order_products_pkey'
}

/** input type for inserting data into table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Insert_Input = {
  product_variant?: Maybe<Product_Variants_Obj_Rel_Insert_Input>;
  product_variant_id?: Maybe<Scalars['uuid']>;
  vehicle_order?: Maybe<Vehicle_Orders_Obj_Rel_Insert_Input>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Vehicle_Order_Product_Variants_Max_Fields = {
  __typename?: 'vehicle_order_product_variants_max_fields';
  product_variant_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Max_Order_By = {
  product_variant_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Order_Product_Variants_Min_Fields = {
  __typename?: 'vehicle_order_product_variants_min_fields';
  product_variant_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Min_Order_By = {
  product_variant_id?: Maybe<Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Mutation_Response = {
  __typename?: 'vehicle_order_product_variants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Order_Product_Variants>;
};

/** on_conflict condition type for table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_On_Conflict = {
  constraint: Vehicle_Order_Product_Variants_Constraint;
  update_columns?: Array<Vehicle_Order_Product_Variants_Update_Column>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_order_product_variants". */
export type Vehicle_Order_Product_Variants_Order_By = {
  product_variant?: Maybe<Product_Variants_Order_By>;
  product_variant_id?: Maybe<Order_By>;
  vehicle_order?: Maybe<Vehicle_Orders_Order_By>;
  vehicle_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: vehicle_order_product_variants */
export type Vehicle_Order_Product_Variants_Pk_Columns_Input = {
  product_variant_id: Scalars['uuid'];
  vehicle_order_id: Scalars['uuid'];
};

/** select columns of table "vehicle_order_product_variants" */
export enum Vehicle_Order_Product_Variants_Select_Column {
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

/** input type for updating data in table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Set_Input = {
  product_variant_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "vehicle_order_product_variants" */
export type Vehicle_Order_Product_Variants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Order_Product_Variants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Order_Product_Variants_Stream_Cursor_Value_Input = {
  product_variant_id?: Maybe<Scalars['uuid']>;
  vehicle_order_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "vehicle_order_product_variants" */
export enum Vehicle_Order_Product_Variants_Update_Column {
  /** column name */
  ProductVariantId = 'product_variant_id',
  /** column name */
  VehicleOrderId = 'vehicle_order_id'
}

export type Vehicle_Order_Product_Variants_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Order_Product_Variants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Order_Product_Variants_Bool_Exp;
};

/** columns and relationships of "vehicle_orders" */
export type Vehicle_Orders = {
  __typename?: 'vehicle_orders';
  actewagl_promotion_selected_benefit?: Maybe<Scalars['String']>;
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activities_aggregate: Activities_Aggregate;
  /** An array relationship */
  activity: Array<Vehicle_Order_Activity>;
  /** An aggregate relationship */
  activity_aggregate: Vehicle_Order_Activity_Aggregate;
  actual_delivery_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: Assets_Aggregate;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** deprecated */
  block_automated_comms: Scalars['Boolean'];
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Scalars['String']>;
  /** The legal name of the business */
  business_name?: Maybe<Scalars['String']>;
  call_requested_at?: Maybe<Scalars['timestamptz']>;
  contract_reissued_at?: Maybe<Scalars['timestamptz']>;
  contract_sent_at?: Maybe<Scalars['timestamptz']>;
  contract_signed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  custom_field_values: Array<Custom_Field_Values>;
  /** An aggregate relationship */
  custom_field_values_aggregate: Custom_Field_Values_Aggregate;
  /** An object relationship */
  custom_vehicle?: Maybe<Custom_Vehicles>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_address?: Maybe<Scalars['jsonb']>;
  delivery_method?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount: Scalars['numeric'];
  estimated_delivery_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  finance_application?: Maybe<Finance_Applications>;
  finance_application_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  handover_location?: Maybe<Locations>;
  handover_location_id?: Maybe<Scalars['uuid']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  invoice_resent_at?: Maybe<Scalars['timestamptz']>;
  invoice_sent_at?: Maybe<Scalars['timestamptz']>;
  invoicing_id?: Maybe<Scalars['String']>;
  is_actewagl_charger_add_on_enquiry: Scalars['Boolean'];
  is_actewagl_energy_add_on_enquiry: Scalars['Boolean'];
  is_commercial: Scalars['Boolean'];
  /** Whether the user has silenced (hidden) the continue order banner for the order. */
  is_silenced: Scalars['Boolean'];
  is_temporary_hold: Scalars['Boolean'];
  last_step: Scalars['String'];
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  order_notes: Array<Notes>;
  /** An aggregate relationship */
  order_notes_aggregate: Notes_Aggregate;
  /** An object relationship */
  owner?: Maybe<Users>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  phone?: Maybe<Scalars['String']>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code: Scalars['String'];
  /** An object relationship */
  pricing_code_record: Pricing_Codes;
  pricing_config?: Maybe<Scalars['jsonb']>;
  received_nrma_blue_offer_at?: Maybe<Scalars['timestamptz']>;
  registered_owner?: Maybe<Scalars['jsonb']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom?: Maybe<Showrooms>;
  showroom_id?: Maybe<Scalars['uuid']>;
  showroom_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage: Scalars['String'];
  /** An object relationship */
  stocked_vehicle?: Maybe<Stocked_Vehicles>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_liability_protection?: Maybe<Scalars['Boolean']>;
  subscription_plan_id?: Maybe<Scalars['String']>;
  subscription_provider?: Maybe<Scalars['String']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value: Scalars['numeric'];
  touched?: Maybe<Scalars['Int']>;
  /** An array relationship */
  trade_in_plans: Array<Trade_In_Plans>;
  /** An aggregate relationship */
  trade_in_plans_aggregate: Trade_In_Plans_Aggregate;
  /** The type of vehicle associated to this order. stocked | custom */
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  /** An array relationship */
  vehicle_order_product_variants: Array<Vehicle_Order_Product_Variants>;
  /** An aggregate relationship */
  vehicle_order_product_variants_aggregate: Vehicle_Order_Product_Variants_Aggregate;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersActivityArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Activity_Order_By>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersActivity_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Activity_Order_By>>;
  where?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersAssetsArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersAssets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assets_Order_By>>;
  where?: Maybe<Assets_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersCustom_Field_ValuesArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersCustom_Field_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Field_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Field_Values_Order_By>>;
  where?: Maybe<Custom_Field_Values_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersDelivery_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersOrder_NotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersOrder_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersPricing_ConfigArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersRegistered_OwnerArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersTrade_In_PlansArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersTrade_In_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Trade_In_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trade_In_Plans_Order_By>>;
  where?: Maybe<Trade_In_Plans_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersVehicle_Order_Product_VariantsArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};


/** columns and relationships of "vehicle_orders" */
export type Vehicle_OrdersVehicle_Order_Product_Variants_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Order_Product_Variants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_Product_Variants_Order_By>>;
  where?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
};

/** aggregated selection of "vehicle_orders" */
export type Vehicle_Orders_Aggregate = {
  __typename?: 'vehicle_orders_aggregate';
  aggregate?: Maybe<Vehicle_Orders_Aggregate_Fields>;
  nodes: Array<Vehicle_Orders>;
};

export type Vehicle_Orders_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Vehicle_Orders_Aggregate_Bool_Exp_Count>;
};

export type Vehicle_Orders_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vehicle_Orders_Select_Column_Vehicle_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicle_Orders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vehicle_Orders_Select_Column_Vehicle_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicle_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicle_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vehicle_orders" */
export type Vehicle_Orders_Aggregate_Fields = {
  __typename?: 'vehicle_orders_aggregate_fields';
  avg?: Maybe<Vehicle_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Orders_Max_Fields>;
  min?: Maybe<Vehicle_Orders_Min_Fields>;
  stddev?: Maybe<Vehicle_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Orders_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Orders_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Orders_Variance_Fields>;
};


/** aggregate fields of "vehicle_orders" */
export type Vehicle_Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_orders" */
export type Vehicle_Orders_Aggregate_Order_By = {
  avg?: Maybe<Vehicle_Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Orders_Max_Order_By>;
  min?: Maybe<Vehicle_Orders_Min_Order_By>;
  stddev?: Maybe<Vehicle_Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Vehicle_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vehicle_Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Vehicle_Orders_Sum_Order_By>;
  var_pop?: Maybe<Vehicle_Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Vehicle_Orders_Var_Samp_Order_By>;
  variance?: Maybe<Vehicle_Orders_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vehicle_Orders_Append_Input = {
  delivery_address?: Maybe<Scalars['jsonb']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  registered_owner?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "vehicle_orders" */
export type Vehicle_Orders_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicle_Orders_Avg_Fields = {
  __typename?: 'vehicle_orders_avg_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Avg_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_orders". All fields are combined with a logical 'AND'. */
export type Vehicle_Orders_Bool_Exp = {
  _and?: Maybe<Array<Vehicle_Orders_Bool_Exp>>;
  _not?: Maybe<Vehicle_Orders_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Orders_Bool_Exp>>;
  actewagl_promotion_selected_benefit?: Maybe<String_Comparison_Exp>;
  activities?: Maybe<Activities_Bool_Exp>;
  activities_aggregate?: Maybe<Activities_Aggregate_Bool_Exp>;
  activity?: Maybe<Vehicle_Order_Activity_Bool_Exp>;
  activity_aggregate?: Maybe<Vehicle_Order_Activity_Aggregate_Bool_Exp>;
  actual_delivery_date?: Maybe<Date_Comparison_Exp>;
  assets?: Maybe<Assets_Bool_Exp>;
  assets_aggregate?: Maybe<Assets_Aggregate_Bool_Exp>;
  awarded_credit_amount?: Maybe<Numeric_Comparison_Exp>;
  awarded_credit_term?: Maybe<Numeric_Comparison_Exp>;
  block_automated_comms?: Maybe<Boolean_Comparison_Exp>;
  business_identifier?: Maybe<String_Comparison_Exp>;
  business_name?: Maybe<String_Comparison_Exp>;
  call_requested_at?: Maybe<Timestamptz_Comparison_Exp>;
  contract_reissued_at?: Maybe<Timestamptz_Comparison_Exp>;
  contract_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  contract_signed_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_field_values?: Maybe<Custom_Field_Values_Bool_Exp>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Bool_Exp>;
  custom_vehicle?: Maybe<Custom_Vehicles_Bool_Exp>;
  custom_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  delivery_address?: Maybe<Jsonb_Comparison_Exp>;
  delivery_method?: Maybe<String_Comparison_Exp>;
  delivery_notes?: Maybe<String_Comparison_Exp>;
  deposit_amount?: Maybe<Numeric_Comparison_Exp>;
  estimated_delivery_date?: Maybe<Date_Comparison_Exp>;
  finance_application?: Maybe<Finance_Applications_Bool_Exp>;
  finance_application_id?: Maybe<Uuid_Comparison_Exp>;
  handover_location?: Maybe<Locations_Bool_Exp>;
  handover_location_id?: Maybe<Uuid_Comparison_Exp>;
  hubspot_deal_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoice_resent_at?: Maybe<Timestamptz_Comparison_Exp>;
  invoice_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  invoicing_id?: Maybe<String_Comparison_Exp>;
  is_actewagl_charger_add_on_enquiry?: Maybe<Boolean_Comparison_Exp>;
  is_actewagl_energy_add_on_enquiry?: Maybe<Boolean_Comparison_Exp>;
  is_commercial?: Maybe<Boolean_Comparison_Exp>;
  is_silenced?: Maybe<Boolean_Comparison_Exp>;
  is_temporary_hold?: Maybe<Boolean_Comparison_Exp>;
  last_step?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  order_notes?: Maybe<Notes_Bool_Exp>;
  order_notes_aggregate?: Maybe<Notes_Aggregate_Bool_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  payments_aggregate?: Maybe<Payments_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  pricing_code?: Maybe<String_Comparison_Exp>;
  pricing_code_record?: Maybe<Pricing_Codes_Bool_Exp>;
  pricing_config?: Maybe<Jsonb_Comparison_Exp>;
  received_nrma_blue_offer_at?: Maybe<Timestamptz_Comparison_Exp>;
  registered_owner?: Maybe<Jsonb_Comparison_Exp>;
  requested_credit_amount?: Maybe<Numeric_Comparison_Exp>;
  requested_credit_balloon?: Maybe<Numeric_Comparison_Exp>;
  requested_credit_term?: Maybe<Numeric_Comparison_Exp>;
  requested_shs_credit_amount?: Maybe<Numeric_Comparison_Exp>;
  requested_shs_credit_term?: Maybe<Numeric_Comparison_Exp>;
  settlement_method?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Showrooms_Bool_Exp>;
  showroom_id?: Maybe<Uuid_Comparison_Exp>;
  showroom_notification_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  signature_request_id?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  subscription_km?: Maybe<Int_Comparison_Exp>;
  subscription_liability_protection?: Maybe<Boolean_Comparison_Exp>;
  subscription_plan_id?: Maybe<String_Comparison_Exp>;
  subscription_provider?: Maybe<String_Comparison_Exp>;
  subscription_term?: Maybe<Int_Comparison_Exp>;
  total_trade_in_value?: Maybe<Numeric_Comparison_Exp>;
  touched?: Maybe<Int_Comparison_Exp>;
  trade_in_plans?: Maybe<Trade_In_Plans_Bool_Exp>;
  trade_in_plans_aggregate?: Maybe<Trade_In_Plans_Aggregate_Bool_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Bool_Exp>;
  vehicle_order_product_variants_aggregate?: Maybe<Vehicle_Order_Product_Variants_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vehicle_orders" */
export enum Vehicle_Orders_Constraint {
  /** unique or primary key constraint on columns "invoicing_id" */
  VehicleOrdersOrderIdKey = 'vehicle_orders_order_id_key',
  /** unique or primary key constraint on columns "id" */
  VehicleOrdersPkey = 'vehicle_orders_pkey',
  /** unique or primary key constraint on columns "source", "source_id", "showroom_id" */
  VehicleOrdersSourceSourceIdShowroomIdKey = 'vehicle_orders_source_source_id_showroom_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vehicle_Orders_Delete_At_Path_Input = {
  delivery_address?: Maybe<Array<Scalars['String']>>;
  pricing_config?: Maybe<Array<Scalars['String']>>;
  registered_owner?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vehicle_Orders_Delete_Elem_Input = {
  delivery_address?: Maybe<Scalars['Int']>;
  pricing_config?: Maybe<Scalars['Int']>;
  registered_owner?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vehicle_Orders_Delete_Key_Input = {
  delivery_address?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['String']>;
  registered_owner?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "vehicle_orders" */
export type Vehicle_Orders_Inc_Input = {
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vehicle_orders" */
export type Vehicle_Orders_Insert_Input = {
  actewagl_promotion_selected_benefit?: Maybe<Scalars['String']>;
  activities?: Maybe<Activities_Arr_Rel_Insert_Input>;
  activity?: Maybe<Vehicle_Order_Activity_Arr_Rel_Insert_Input>;
  actual_delivery_date?: Maybe<Scalars['date']>;
  assets?: Maybe<Assets_Arr_Rel_Insert_Input>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** deprecated */
  block_automated_comms?: Maybe<Scalars['Boolean']>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Scalars['String']>;
  /** The legal name of the business */
  business_name?: Maybe<Scalars['String']>;
  call_requested_at?: Maybe<Scalars['timestamptz']>;
  contract_reissued_at?: Maybe<Scalars['timestamptz']>;
  contract_sent_at?: Maybe<Scalars['timestamptz']>;
  contract_signed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_field_values?: Maybe<Custom_Field_Values_Arr_Rel_Insert_Input>;
  custom_vehicle?: Maybe<Custom_Vehicles_Obj_Rel_Insert_Input>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_address?: Maybe<Scalars['jsonb']>;
  delivery_method?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  estimated_delivery_date?: Maybe<Scalars['date']>;
  finance_application?: Maybe<Finance_Applications_Obj_Rel_Insert_Input>;
  finance_application_id?: Maybe<Scalars['uuid']>;
  handover_location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  handover_location_id?: Maybe<Scalars['uuid']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_resent_at?: Maybe<Scalars['timestamptz']>;
  invoice_sent_at?: Maybe<Scalars['timestamptz']>;
  invoicing_id?: Maybe<Scalars['String']>;
  is_actewagl_charger_add_on_enquiry?: Maybe<Scalars['Boolean']>;
  is_actewagl_energy_add_on_enquiry?: Maybe<Scalars['Boolean']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** Whether the user has silenced (hidden) the continue order banner for the order. */
  is_silenced?: Maybe<Scalars['Boolean']>;
  is_temporary_hold?: Maybe<Scalars['Boolean']>;
  last_step?: Maybe<Scalars['String']>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Scalars['String']>;
  order_notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Scalars['uuid']>;
  payments?: Maybe<Payments_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Scalars['String']>;
  pricing_code_record?: Maybe<Pricing_Codes_Obj_Rel_Insert_Input>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  received_nrma_blue_offer_at?: Maybe<Scalars['timestamptz']>;
  registered_owner?: Maybe<Scalars['jsonb']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Scalars['String']>;
  showroom?: Maybe<Showrooms_Obj_Rel_Insert_Input>;
  showroom_id?: Maybe<Scalars['uuid']>;
  showroom_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_liability_protection?: Maybe<Scalars['Boolean']>;
  subscription_plan_id?: Maybe<Scalars['String']>;
  subscription_provider?: Maybe<Scalars['String']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  trade_in_plans?: Maybe<Trade_In_Plans_Arr_Rel_Insert_Input>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_order_product_variants?: Maybe<Vehicle_Order_Product_Variants_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vehicle_Orders_Max_Fields = {
  __typename?: 'vehicle_orders_max_fields';
  actewagl_promotion_selected_benefit?: Maybe<Scalars['String']>;
  actual_delivery_date?: Maybe<Scalars['date']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Scalars['String']>;
  /** The legal name of the business */
  business_name?: Maybe<Scalars['String']>;
  call_requested_at?: Maybe<Scalars['timestamptz']>;
  contract_reissued_at?: Maybe<Scalars['timestamptz']>;
  contract_sent_at?: Maybe<Scalars['timestamptz']>;
  contract_signed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_method?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  estimated_delivery_date?: Maybe<Scalars['date']>;
  finance_application_id?: Maybe<Scalars['uuid']>;
  handover_location_id?: Maybe<Scalars['uuid']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_resent_at?: Maybe<Scalars['timestamptz']>;
  invoice_sent_at?: Maybe<Scalars['timestamptz']>;
  invoicing_id?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Scalars['String']>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Scalars['String']>;
  received_nrma_blue_offer_at?: Maybe<Scalars['timestamptz']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  showroom_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_plan_id?: Maybe<Scalars['String']>;
  subscription_provider?: Maybe<Scalars['String']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Max_Order_By = {
  actewagl_promotion_selected_benefit?: Maybe<Order_By>;
  actual_delivery_date?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Order_By>;
  /** The legal name of the business */
  business_name?: Maybe<Order_By>;
  call_requested_at?: Maybe<Order_By>;
  contract_reissued_at?: Maybe<Order_By>;
  contract_sent_at?: Maybe<Order_By>;
  contract_signed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_vehicle_id?: Maybe<Order_By>;
  delivery_method?: Maybe<Order_By>;
  delivery_notes?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  estimated_delivery_date?: Maybe<Order_By>;
  finance_application_id?: Maybe<Order_By>;
  handover_location_id?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_resent_at?: Maybe<Order_By>;
  invoice_sent_at?: Maybe<Order_By>;
  invoicing_id?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Order_By>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Order_By>;
  received_nrma_blue_offer_at?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  showroom_notification_sent_at?: Maybe<Order_By>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_plan_id?: Maybe<Order_By>;
  subscription_provider?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Orders_Min_Fields = {
  __typename?: 'vehicle_orders_min_fields';
  actewagl_promotion_selected_benefit?: Maybe<Scalars['String']>;
  actual_delivery_date?: Maybe<Scalars['date']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Scalars['String']>;
  /** The legal name of the business */
  business_name?: Maybe<Scalars['String']>;
  call_requested_at?: Maybe<Scalars['timestamptz']>;
  contract_reissued_at?: Maybe<Scalars['timestamptz']>;
  contract_sent_at?: Maybe<Scalars['timestamptz']>;
  contract_signed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_method?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  estimated_delivery_date?: Maybe<Scalars['date']>;
  finance_application_id?: Maybe<Scalars['uuid']>;
  handover_location_id?: Maybe<Scalars['uuid']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_resent_at?: Maybe<Scalars['timestamptz']>;
  invoice_sent_at?: Maybe<Scalars['timestamptz']>;
  invoicing_id?: Maybe<Scalars['String']>;
  last_step?: Maybe<Scalars['String']>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Scalars['String']>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Scalars['String']>;
  received_nrma_blue_offer_at?: Maybe<Scalars['timestamptz']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  showroom_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_plan_id?: Maybe<Scalars['String']>;
  subscription_provider?: Maybe<Scalars['String']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Min_Order_By = {
  actewagl_promotion_selected_benefit?: Maybe<Order_By>;
  actual_delivery_date?: Maybe<Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Order_By>;
  /** The legal name of the business */
  business_name?: Maybe<Order_By>;
  call_requested_at?: Maybe<Order_By>;
  contract_reissued_at?: Maybe<Order_By>;
  contract_sent_at?: Maybe<Order_By>;
  contract_signed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_vehicle_id?: Maybe<Order_By>;
  delivery_method?: Maybe<Order_By>;
  delivery_notes?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  estimated_delivery_date?: Maybe<Order_By>;
  finance_application_id?: Maybe<Order_By>;
  handover_location_id?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_resent_at?: Maybe<Order_By>;
  invoice_sent_at?: Maybe<Order_By>;
  invoicing_id?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Order_By>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Order_By>;
  received_nrma_blue_offer_at?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  showroom_notification_sent_at?: Maybe<Order_By>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_plan_id?: Maybe<Order_By>;
  subscription_provider?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicle_orders" */
export type Vehicle_Orders_Mutation_Response = {
  __typename?: 'vehicle_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Orders>;
};

/** input type for inserting object relation for remote table "vehicle_orders" */
export type Vehicle_Orders_Obj_Rel_Insert_Input = {
  data: Vehicle_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Orders_On_Conflict>;
};

/** on_conflict condition type for table "vehicle_orders" */
export type Vehicle_Orders_On_Conflict = {
  constraint: Vehicle_Orders_Constraint;
  update_columns?: Array<Vehicle_Orders_Update_Column>;
  where?: Maybe<Vehicle_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_orders". */
export type Vehicle_Orders_Order_By = {
  actewagl_promotion_selected_benefit?: Maybe<Order_By>;
  activities_aggregate?: Maybe<Activities_Aggregate_Order_By>;
  activity_aggregate?: Maybe<Vehicle_Order_Activity_Aggregate_Order_By>;
  actual_delivery_date?: Maybe<Order_By>;
  assets_aggregate?: Maybe<Assets_Aggregate_Order_By>;
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  block_automated_comms?: Maybe<Order_By>;
  business_identifier?: Maybe<Order_By>;
  business_name?: Maybe<Order_By>;
  call_requested_at?: Maybe<Order_By>;
  contract_reissued_at?: Maybe<Order_By>;
  contract_sent_at?: Maybe<Order_By>;
  contract_signed_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_field_values_aggregate?: Maybe<Custom_Field_Values_Aggregate_Order_By>;
  custom_vehicle?: Maybe<Custom_Vehicles_Order_By>;
  custom_vehicle_id?: Maybe<Order_By>;
  delivery_address?: Maybe<Order_By>;
  delivery_method?: Maybe<Order_By>;
  delivery_notes?: Maybe<Order_By>;
  deposit_amount?: Maybe<Order_By>;
  estimated_delivery_date?: Maybe<Order_By>;
  finance_application?: Maybe<Finance_Applications_Order_By>;
  finance_application_id?: Maybe<Order_By>;
  handover_location?: Maybe<Locations_Order_By>;
  handover_location_id?: Maybe<Order_By>;
  hubspot_deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_resent_at?: Maybe<Order_By>;
  invoice_sent_at?: Maybe<Order_By>;
  invoicing_id?: Maybe<Order_By>;
  is_actewagl_charger_add_on_enquiry?: Maybe<Order_By>;
  is_actewagl_energy_add_on_enquiry?: Maybe<Order_By>;
  is_commercial?: Maybe<Order_By>;
  is_silenced?: Maybe<Order_By>;
  is_temporary_hold?: Maybe<Order_By>;
  last_step?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  order_notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  pricing_code?: Maybe<Order_By>;
  pricing_code_record?: Maybe<Pricing_Codes_Order_By>;
  pricing_config?: Maybe<Order_By>;
  received_nrma_blue_offer_at?: Maybe<Order_By>;
  registered_owner?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  requested_shs_credit_amount?: Maybe<Order_By>;
  requested_shs_credit_term?: Maybe<Order_By>;
  settlement_method?: Maybe<Order_By>;
  showroom?: Maybe<Showrooms_Order_By>;
  showroom_id?: Maybe<Order_By>;
  showroom_notification_sent_at?: Maybe<Order_By>;
  signature_request_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_liability_protection?: Maybe<Order_By>;
  subscription_plan_id?: Maybe<Order_By>;
  subscription_provider?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  trade_in_plans_aggregate?: Maybe<Trade_In_Plans_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_order_product_variants_aggregate?: Maybe<Vehicle_Order_Product_Variants_Aggregate_Order_By>;
};

/** primary key columns input for table: vehicle_orders */
export type Vehicle_Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vehicle_Orders_Prepend_Input = {
  delivery_address?: Maybe<Scalars['jsonb']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  registered_owner?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "vehicle_orders" */
export enum Vehicle_Orders_Select_Column {
  /** column name */
  ActewaglPromotionSelectedBenefit = 'actewagl_promotion_selected_benefit',
  /** column name */
  ActualDeliveryDate = 'actual_delivery_date',
  /** column name */
  AwardedCreditAmount = 'awarded_credit_amount',
  /** column name */
  AwardedCreditTerm = 'awarded_credit_term',
  /** column name */
  BlockAutomatedComms = 'block_automated_comms',
  /** column name */
  BusinessIdentifier = 'business_identifier',
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CallRequestedAt = 'call_requested_at',
  /** column name */
  ContractReissuedAt = 'contract_reissued_at',
  /** column name */
  ContractSentAt = 'contract_sent_at',
  /** column name */
  ContractSignedAt = 'contract_signed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomVehicleId = 'custom_vehicle_id',
  /** column name */
  DeliveryAddress = 'delivery_address',
  /** column name */
  DeliveryMethod = 'delivery_method',
  /** column name */
  DeliveryNotes = 'delivery_notes',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  EstimatedDeliveryDate = 'estimated_delivery_date',
  /** column name */
  FinanceApplicationId = 'finance_application_id',
  /** column name */
  HandoverLocationId = 'handover_location_id',
  /** column name */
  HubspotDealId = 'hubspot_deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceResentAt = 'invoice_resent_at',
  /** column name */
  InvoiceSentAt = 'invoice_sent_at',
  /** column name */
  InvoicingId = 'invoicing_id',
  /** column name */
  IsActewaglChargerAddOnEnquiry = 'is_actewagl_charger_add_on_enquiry',
  /** column name */
  IsActewaglEnergyAddOnEnquiry = 'is_actewagl_energy_add_on_enquiry',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsSilenced = 'is_silenced',
  /** column name */
  IsTemporaryHold = 'is_temporary_hold',
  /** column name */
  LastStep = 'last_step',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PricingCode = 'pricing_code',
  /** column name */
  PricingConfig = 'pricing_config',
  /** column name */
  ReceivedNrmaBlueOfferAt = 'received_nrma_blue_offer_at',
  /** column name */
  RegisteredOwner = 'registered_owner',
  /** column name */
  RequestedCreditAmount = 'requested_credit_amount',
  /** column name */
  RequestedCreditBalloon = 'requested_credit_balloon',
  /** column name */
  RequestedCreditTerm = 'requested_credit_term',
  /** column name */
  RequestedShsCreditAmount = 'requested_shs_credit_amount',
  /** column name */
  RequestedShsCreditTerm = 'requested_shs_credit_term',
  /** column name */
  SettlementMethod = 'settlement_method',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  ShowroomNotificationSentAt = 'showroom_notification_sent_at',
  /** column name */
  SignatureRequestId = 'signature_request_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  SubscriptionKm = 'subscription_km',
  /** column name */
  SubscriptionLiabilityProtection = 'subscription_liability_protection',
  /** column name */
  SubscriptionPlanId = 'subscription_plan_id',
  /** column name */
  SubscriptionProvider = 'subscription_provider',
  /** column name */
  SubscriptionTerm = 'subscription_term',
  /** column name */
  TotalTradeInValue = 'total_trade_in_value',
  /** column name */
  Touched = 'touched',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "vehicle_orders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vehicle_orders" */
export enum Vehicle_Orders_Select_Column_Vehicle_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BlockAutomatedComms = 'block_automated_comms',
  /** column name */
  IsActewaglChargerAddOnEnquiry = 'is_actewagl_charger_add_on_enquiry',
  /** column name */
  IsActewaglEnergyAddOnEnquiry = 'is_actewagl_energy_add_on_enquiry',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsSilenced = 'is_silenced',
  /** column name */
  IsTemporaryHold = 'is_temporary_hold',
  /** column name */
  SubscriptionLiabilityProtection = 'subscription_liability_protection'
}

/** select "vehicle_orders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vehicle_orders" */
export enum Vehicle_Orders_Select_Column_Vehicle_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BlockAutomatedComms = 'block_automated_comms',
  /** column name */
  IsActewaglChargerAddOnEnquiry = 'is_actewagl_charger_add_on_enquiry',
  /** column name */
  IsActewaglEnergyAddOnEnquiry = 'is_actewagl_energy_add_on_enquiry',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsSilenced = 'is_silenced',
  /** column name */
  IsTemporaryHold = 'is_temporary_hold',
  /** column name */
  SubscriptionLiabilityProtection = 'subscription_liability_protection'
}

/** input type for updating data in table "vehicle_orders" */
export type Vehicle_Orders_Set_Input = {
  actewagl_promotion_selected_benefit?: Maybe<Scalars['String']>;
  actual_delivery_date?: Maybe<Scalars['date']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** deprecated */
  block_automated_comms?: Maybe<Scalars['Boolean']>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Scalars['String']>;
  /** The legal name of the business */
  business_name?: Maybe<Scalars['String']>;
  call_requested_at?: Maybe<Scalars['timestamptz']>;
  contract_reissued_at?: Maybe<Scalars['timestamptz']>;
  contract_sent_at?: Maybe<Scalars['timestamptz']>;
  contract_signed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_address?: Maybe<Scalars['jsonb']>;
  delivery_method?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  estimated_delivery_date?: Maybe<Scalars['date']>;
  finance_application_id?: Maybe<Scalars['uuid']>;
  handover_location_id?: Maybe<Scalars['uuid']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_resent_at?: Maybe<Scalars['timestamptz']>;
  invoice_sent_at?: Maybe<Scalars['timestamptz']>;
  invoicing_id?: Maybe<Scalars['String']>;
  is_actewagl_charger_add_on_enquiry?: Maybe<Scalars['Boolean']>;
  is_actewagl_energy_add_on_enquiry?: Maybe<Scalars['Boolean']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** Whether the user has silenced (hidden) the continue order banner for the order. */
  is_silenced?: Maybe<Scalars['Boolean']>;
  is_temporary_hold?: Maybe<Scalars['Boolean']>;
  last_step?: Maybe<Scalars['String']>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Scalars['String']>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  received_nrma_blue_offer_at?: Maybe<Scalars['timestamptz']>;
  registered_owner?: Maybe<Scalars['jsonb']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  showroom_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_liability_protection?: Maybe<Scalars['Boolean']>;
  subscription_plan_id?: Maybe<Scalars['String']>;
  subscription_provider?: Maybe<Scalars['String']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Vehicle_Orders_Stddev_Fields = {
  __typename?: 'vehicle_orders_stddev_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Stddev_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Orders_Stddev_Pop_Fields = {
  __typename?: 'vehicle_orders_stddev_pop_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Stddev_Pop_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Orders_Stddev_Samp_Fields = {
  __typename?: 'vehicle_orders_stddev_samp_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Stddev_Samp_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** Streaming cursor of the table "vehicle_orders" */
export type Vehicle_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Orders_Stream_Cursor_Value_Input = {
  actewagl_promotion_selected_benefit?: Maybe<Scalars['String']>;
  actual_delivery_date?: Maybe<Scalars['date']>;
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** deprecated */
  block_automated_comms?: Maybe<Scalars['Boolean']>;
  /** A relevant identifier for the business within its jurisdiction. E.g an ABN (Australia). */
  business_identifier?: Maybe<Scalars['String']>;
  /** The legal name of the business */
  business_name?: Maybe<Scalars['String']>;
  call_requested_at?: Maybe<Scalars['timestamptz']>;
  contract_reissued_at?: Maybe<Scalars['timestamptz']>;
  contract_sent_at?: Maybe<Scalars['timestamptz']>;
  contract_signed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_vehicle_id?: Maybe<Scalars['uuid']>;
  delivery_address?: Maybe<Scalars['jsonb']>;
  delivery_method?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  estimated_delivery_date?: Maybe<Scalars['date']>;
  finance_application_id?: Maybe<Scalars['uuid']>;
  handover_location_id?: Maybe<Scalars['uuid']>;
  hubspot_deal_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_resent_at?: Maybe<Scalars['timestamptz']>;
  invoice_sent_at?: Maybe<Scalars['timestamptz']>;
  invoicing_id?: Maybe<Scalars['String']>;
  is_actewagl_charger_add_on_enquiry?: Maybe<Scalars['Boolean']>;
  is_actewagl_energy_add_on_enquiry?: Maybe<Scalars['Boolean']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** Whether the user has silenced (hidden) the continue order banner for the order. */
  is_silenced?: Maybe<Scalars['Boolean']>;
  is_temporary_hold?: Maybe<Scalars['Boolean']>;
  last_step?: Maybe<Scalars['String']>;
  /** Deprecated - Use the notes association instead. */
  notes?: Maybe<Scalars['String']>;
  /** The user ID of a staff member who is the account owner for this order. */
  owner_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** The pricing code that has been used for this vehicle_order. Use this to locate the appropriate pricing configuration set against the associated stocked_vehicle.pricing_config. */
  pricing_code?: Maybe<Scalars['String']>;
  pricing_config?: Maybe<Scalars['jsonb']>;
  received_nrma_blue_offer_at?: Maybe<Scalars['timestamptz']>;
  registered_owner?: Maybe<Scalars['jsonb']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  /** cash | finance | third-party-finance */
  settlement_method?: Maybe<Scalars['String']>;
  showroom_id?: Maybe<Scalars['uuid']>;
  showroom_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  /** The HelloSign "signature_request_id" */
  signature_request_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  /** online-order | phone-order | finance-and-trade-in | settlement | delivered | cancelled */
  stage?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_liability_protection?: Maybe<Scalars['Boolean']>;
  subscription_plan_id?: Maybe<Scalars['String']>;
  subscription_provider?: Maybe<Scalars['String']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
  /** The type of vehicle associated to this order. stocked | custom */
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Vehicle_Orders_Sum_Fields = {
  __typename?: 'vehicle_orders_sum_fields';
  awarded_credit_amount?: Maybe<Scalars['numeric']>;
  awarded_credit_term?: Maybe<Scalars['numeric']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['numeric']>;
  requested_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['numeric']>;
  requested_credit_term?: Maybe<Scalars['numeric']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['numeric']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['numeric']>;
  subscription_km?: Maybe<Scalars['Int']>;
  subscription_term?: Maybe<Scalars['Int']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['numeric']>;
  touched?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Sum_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** update columns of table "vehicle_orders" */
export enum Vehicle_Orders_Update_Column {
  /** column name */
  ActewaglPromotionSelectedBenefit = 'actewagl_promotion_selected_benefit',
  /** column name */
  ActualDeliveryDate = 'actual_delivery_date',
  /** column name */
  AwardedCreditAmount = 'awarded_credit_amount',
  /** column name */
  AwardedCreditTerm = 'awarded_credit_term',
  /** column name */
  BlockAutomatedComms = 'block_automated_comms',
  /** column name */
  BusinessIdentifier = 'business_identifier',
  /** column name */
  BusinessName = 'business_name',
  /** column name */
  CallRequestedAt = 'call_requested_at',
  /** column name */
  ContractReissuedAt = 'contract_reissued_at',
  /** column name */
  ContractSentAt = 'contract_sent_at',
  /** column name */
  ContractSignedAt = 'contract_signed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomVehicleId = 'custom_vehicle_id',
  /** column name */
  DeliveryAddress = 'delivery_address',
  /** column name */
  DeliveryMethod = 'delivery_method',
  /** column name */
  DeliveryNotes = 'delivery_notes',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  EstimatedDeliveryDate = 'estimated_delivery_date',
  /** column name */
  FinanceApplicationId = 'finance_application_id',
  /** column name */
  HandoverLocationId = 'handover_location_id',
  /** column name */
  HubspotDealId = 'hubspot_deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceResentAt = 'invoice_resent_at',
  /** column name */
  InvoiceSentAt = 'invoice_sent_at',
  /** column name */
  InvoicingId = 'invoicing_id',
  /** column name */
  IsActewaglChargerAddOnEnquiry = 'is_actewagl_charger_add_on_enquiry',
  /** column name */
  IsActewaglEnergyAddOnEnquiry = 'is_actewagl_energy_add_on_enquiry',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  IsSilenced = 'is_silenced',
  /** column name */
  IsTemporaryHold = 'is_temporary_hold',
  /** column name */
  LastStep = 'last_step',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  PricingCode = 'pricing_code',
  /** column name */
  PricingConfig = 'pricing_config',
  /** column name */
  ReceivedNrmaBlueOfferAt = 'received_nrma_blue_offer_at',
  /** column name */
  RegisteredOwner = 'registered_owner',
  /** column name */
  RequestedCreditAmount = 'requested_credit_amount',
  /** column name */
  RequestedCreditBalloon = 'requested_credit_balloon',
  /** column name */
  RequestedCreditTerm = 'requested_credit_term',
  /** column name */
  RequestedShsCreditAmount = 'requested_shs_credit_amount',
  /** column name */
  RequestedShsCreditTerm = 'requested_shs_credit_term',
  /** column name */
  SettlementMethod = 'settlement_method',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  ShowroomNotificationSentAt = 'showroom_notification_sent_at',
  /** column name */
  SignatureRequestId = 'signature_request_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  SubscriptionKm = 'subscription_km',
  /** column name */
  SubscriptionLiabilityProtection = 'subscription_liability_protection',
  /** column name */
  SubscriptionPlanId = 'subscription_plan_id',
  /** column name */
  SubscriptionProvider = 'subscription_provider',
  /** column name */
  SubscriptionTerm = 'subscription_term',
  /** column name */
  TotalTradeInValue = 'total_trade_in_value',
  /** column name */
  Touched = 'touched',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Vehicle_Orders_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Vehicle_Orders_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Vehicle_Orders_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Vehicle_Orders_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Vehicle_Orders_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vehicle_Orders_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Vehicle_Orders_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicle_Orders_Var_Pop_Fields = {
  __typename?: 'vehicle_orders_var_pop_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Var_Pop_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Orders_Var_Samp_Fields = {
  __typename?: 'vehicle_orders_var_samp_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Var_Samp_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Orders_Variance_Fields = {
  __typename?: 'vehicle_orders_variance_fields';
  awarded_credit_amount?: Maybe<Scalars['Float']>;
  awarded_credit_term?: Maybe<Scalars['Float']>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Scalars['Float']>;
  requested_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Scalars['Float']>;
  requested_credit_term?: Maybe<Scalars['Float']>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Scalars['Float']>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Scalars['Float']>;
  subscription_km?: Maybe<Scalars['Float']>;
  subscription_term?: Maybe<Scalars['Float']>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Scalars['Float']>;
  touched?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle_orders" */
export type Vehicle_Orders_Variance_Order_By = {
  awarded_credit_amount?: Maybe<Order_By>;
  awarded_credit_term?: Maybe<Order_By>;
  /** The holding deposit amount at the time of purchase. */
  deposit_amount?: Maybe<Order_By>;
  requested_credit_amount?: Maybe<Order_By>;
  /** The requested balloon payment, represented as a decimal point. E.g. 0.05 = 5% of drive away price. */
  requested_credit_balloon?: Maybe<Order_By>;
  requested_credit_term?: Maybe<Order_By>;
  /** The requested finance amount (in cents) for the ACT Sustainable Household Scheme */
  requested_shs_credit_amount?: Maybe<Order_By>;
  /** The requested finance term (in months) for the ACT Sustainable Household Scheme */
  requested_shs_credit_term?: Maybe<Order_By>;
  subscription_km?: Maybe<Order_By>;
  subscription_term?: Maybe<Order_By>;
  /** The amount the customer will receive as a credit for their trade in */
  total_trade_in_value?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
};

/** describes specifications for a vehicle based on its redbook code */
export type Vehicle_Specifications = {
  __typename?: 'vehicle_specifications';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  adblue_tank_capacity?: Maybe<Scalars['String']>;
  /** ADR defined parent category based on type and use of vehicle */
  adr_vehicle_category?: Maybe<Scalars['String']>;
  /** ADR sub-category based on specification */
  adr_vehicle_sub_category?: Maybe<Scalars['String']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** The Badge Eg Esseesse */
  badge: Scalars['String'];
  /** The Badge description of the vehicle Eg EXECUTIVE */
  badge_part1?: Maybe<Scalars['String']>;
  /** The 2nd Badge description of the vehicle */
  badge_part2?: Maybe<Scalars['String']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The Body Configuration description of the vehicle Eg DUAL CAB */
  body_configuration?: Maybe<Scalars['String']>;
  /** The Body Style description of the vehicle Eg WAGON */
  body_style?: Maybe<Scalars['String']>;
  /** When a vehicle crests a hill, this is the angle at which the front tyres still remain on the ground as the rear wheels continue to climb. */
  break_over_angle?: Maybe<Scalars['String']>;
  /** The Cam description of the vehicle Eg Overhead Cam, Double Overhead Cam */
  cam_shaft?: Maybe<Scalars['String']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Combined Power (kW) */
  combined_power_kw?: Maybe<Scalars['String']>;
  /** Combined Torque (Nm) */
  combined_torque?: Maybe<Scalars['String']>;
  /** Compression ratio expressed to 1 */
  compression_ratio?: Maybe<Scalars['String']>;
  /** Vehicle Import Flag - I = Import, L = Local */
  country_import?: Maybe<Scalars['String']>;
  /** The Import Country of Origin description of the vehicle Eg Japan, USA */
  country_origin?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** Flag to indicate a current release. T = True, F = False */
  current_release?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  custom_vehicles: Array<Custom_Vehicles>;
  /** An aggregate relationship */
  custom_vehicles_aggregate: Custom_Vehicles_Aggregate;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the rear of the vehicle on the ground. */
  departure_angle?: Maybe<Scalars['String']>;
  /** Full Description of the Vehicle */
  description?: Maybe<Scalars['String']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** The Drive description of the vehicle Eg Four Wheel Drive, Front Wheel Drive */
  drive?: Maybe<Scalars['String']>;
  /** Flag to indicate entered NewPrice is Driveaway. T = True, F = False */
  drive_away?: Maybe<Scalars['Boolean']>;
  /** The Drive abbreviation of the vehicle Eg F */
  drive_short?: Maybe<Scalars['String']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  electric_eng_quick_charge_capacity?: Maybe<Scalars['String']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_battery_type?: Maybe<Scalars['String']>;
  electric_engine_charging_method?: Maybe<Scalars['String']>;
  electric_engine_current_type?: Maybe<Scalars['String']>;
  electric_engine_drive?: Maybe<Scalars['String']>;
  electric_engine_km_range?: Maybe<Scalars['String']>;
  electric_engine_location?: Maybe<Scalars['String']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_type?: Maybe<Scalars['String']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** Which Emission standard this vehicle complies to (eg Euro IV) */
  emission_standard?: Maybe<Scalars['String']>;
  /** The vehicle`s electricity consumption per km as measured under testing standards (in Wh/km) */
  energy_consumption?: Maybe<Scalars['String']>;
  /** The Engine Configuration description of the vehicle Eg Inline, V90 */
  engine_configuration?: Maybe<Scalars['String']>;
  /** The Engine Cycle description of the vehicle Eg 2 Stroke, 4 Stroke */
  engine_cycle?: Maybe<Scalars['String']>;
  /** The common engine reference name, blank if there is no common name Eg 3.4 */
  engine_description?: Maybe<Scalars['String']>;
  /** The common engine reference name Eg 3.4i, 2.4DT */
  engine_description_full?: Maybe<Scalars['String']>;
  /** Front or Rear */
  engine_location?: Maybe<Scalars['String']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** The Engine Type description of the vehicle Eg Piston, Rotary */
  engine_type?: Maybe<Scalars['String']>;
  /** Extra Identification information for vehicle eg LWB, SWB */
  extra_identification?: Maybe<Scalars['String']>;
  /** The family name in full Eg COROLLA */
  family_name?: Maybe<Scalars['String']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  /** Interval (months or kilometres) for a free first service */
  free_scheduled_service?: Maybe<Scalars['String']>;
  /** The distance between the center line of the front axle and the front edge of the vehicle (mm) */
  front_overhang?: Maybe<Scalars['String']>;
  front_passenger_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Description of the front rim */
  front_rim_description?: Maybe<Scalars['String']>;
  front_rim_diameter?: Maybe<Scalars['String']>;
  front_rim_extra_info?: Maybe<Scalars['String']>;
  front_rim_width?: Maybe<Scalars['String']>;
  front_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  front_tyre_construction?: Maybe<Scalars['String']>;
  front_tyre_diameter?: Maybe<Scalars['String']>;
  front_tyre_extra_info?: Maybe<Scalars['String']>;
  front_tyre_load_index?: Maybe<Scalars['String']>;
  front_tyre_size?: Maybe<Scalars['String']>;
  front_tyre_speed_rating?: Maybe<Scalars['String']>;
  front_tyre_width?: Maybe<Scalars['String']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  /** The Fuel Delivery description of the vehicle Eg Carburettor Single, Multi-Point Injected */
  fuel_delivery?: Maybe<Scalars['String']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  /** The Method of Delivery description of the vehicle Eg Electronic, Mechanical */
  fuel_method_of_delivery?: Maybe<Scalars['String']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel Type description of the vehicle Eg Petrol - Unleaded (ULP) */
  fuel_type?: Maybe<Scalars['String']>;
  /** Eg Petrol */
  fuel_type_generic?: Maybe<Scalars['String']>;
  /** The Gear Location description of the vehicle Eg Floor, Column */
  gear_location?: Maybe<Scalars['String']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  /** The Gear Type description of the vehicle, Eg Automatic, Manual */
  gear_type?: Maybe<Scalars['String']>;
  generation_name?: Maybe<Scalars['String']>;
  generation_version?: Maybe<Scalars['String']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The total of the gross weight and braked towing capacity (kg) */
  gross_combination_mass?: Maybe<Scalars['String']>;
  /** The maximum laden mass of a motor vehicle as specified by the Manufacturer (kg) */
  gross_vehicle_mass?: Maybe<Scalars['String']>;
  ground_clearance?: Maybe<Scalars['String']>;
  /** The specification of the AC fast charge system for AC Fast Charge Time */
  hv_battery_ac_fast_ch_desc?: Maybe<Scalars['String']>;
  /** The specifcation of the socket type for Normal Charge Time */
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Scalars['String']>;
  /** The maximum capacity of the High Voltage Battery in kWh */
  hv_battery_capacity?: Maybe<Scalars['String']>;
  /** Standard plug type for the main HV battery charge port */
  hv_battery_charge_socket_type?: Maybe<Scalars['String']>;
  /** The socket type on the vehicle suitable for DC charging */
  hv_battery_dc_charge_socket_type?: Maybe<Scalars['String']>;
  /** The specification of the DC fast charge system for DC Fast Charge Time */
  hv_battery_dc_fast_ch_desc?: Maybe<Scalars['String']>;
  /** Maximum output Voltage of the High Voltage Battery */
  hv_battery_voltage?: Maybe<Scalars['String']>;
  /** The number of km the High Voltage Battery warranty covers (-1 = Unlimited) */
  hv_battery_warranty_km?: Maybe<Scalars['String']>;
  /** The number of years for which the High Voltage Battery is warranted against degradation to a given capacity */
  hv_battery_warranty_years?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  hv_warranty_extra_info?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** The Induction description of the vehicle Eg Aspirated, Turbo */
  induction?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** Pure electric driving range in km (As claimed by the vehicle manufacturer) */
  km_range_manufacturer?: Maybe<Scalars['String']>;
  /** Pure electric driving range in km (WLTP standard) */
  km_range_wltp?: Maybe<Scalars['String']>;
  lct_included?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate a limited edition vehicle. T = True, F = False */
  limited_edition?: Maybe<Scalars['Boolean']>;
  /** Location of Storage */
  location_of_storage?: Maybe<Scalars['String']>;
  /** The Make Name of the Vehicle */
  make_name?: Maybe<Scalars['String']>;
  /** The OEM description of the vehicle`s motive power system */
  manufacturer_power_plant_type?: Maybe<Scalars['String']>;
  /** The Maximum Ethanol Blend */
  max_ethanol_blend?: Maybe<Scalars['String']>;
  /** Highest speed capable of travelling at (km/h) */
  maximum_speed?: Maybe<Scalars['String']>;
  maximum_towball_download?: Maybe<Scalars['String']>;
  /** The Model Year Code from the Manufacturer eg MY2009 */
  model_year?: Maybe<Scalars['String']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  no_child_seat_anchor_age_points?: Maybe<Scalars['String']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** Applies to goods carrying light commercials (kg) */
  payload?: Maybe<Scalars['String']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The motive power type description of the vehicle Eg Internal Combustion, Battery Electric */
  power_plant_type?: Maybe<Scalars['String']>;
  /** The start RPM when peak Power is achieved */
  power_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** The distance between the center line of the rear axle and the rear end of the vehicle (mm) */
  rear_overhang?: Maybe<Scalars['String']>;
  /** Description of the rear rim */
  rear_rim_description?: Maybe<Scalars['String']>;
  rear_rim_diameter?: Maybe<Scalars['String']>;
  rear_rim_extra_info?: Maybe<Scalars['String']>;
  rear_rim_width?: Maybe<Scalars['String']>;
  rear_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  rear_tyre_construction?: Maybe<Scalars['String']>;
  rear_tyre_diameter?: Maybe<Scalars['String']>;
  rear_tyre_extra_info?: Maybe<Scalars['String']>;
  rear_tyre_load_index?: Maybe<Scalars['String']>;
  rear_tyre_size?: Maybe<Scalars['String']>;
  rear_tyre_speed_rating?: Maybe<Scalars['String']>;
  rear_tyre_width?: Maybe<Scalars['String']>;
  /** The Red Book Code that uniquely identifies the vehicle eg AUVTOYO1999AADN */
  redbook_code: Scalars['String'];
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Type of the vehicle */
  release_type?: Maybe<Scalars['String']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** Alloy or Steel */
  rim_material?: Maybe<Scalars['String']>;
  /** Conditions covered by the roadside assistance program */
  road_side_assistance_extra_info?: Maybe<Scalars['String']>;
  /** The number of km for which the roadside assistance program is valid */
  road_side_assistance_km?: Maybe<Scalars['String']>;
  /** The number of years for which the vehicle is eligible for the factory roadside assistance program */
  road_side_assistance_years?: Maybe<Scalars['String']>;
  /** The recommended fuel RON rating */
  ron_rating?: Maybe<Scalars['String']>;
  /** Description of roof style, primarily for wagons and vans, ie: low roof, high roof */
  roofline?: Maybe<Scalars['String']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  second_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Series code (if any) eg VE */
  series?: Maybe<Scalars['String']>;
  /** The Steering description of the vehicle Eg 4 Wheel Steering, Rack and Pinion */
  steering?: Maybe<Scalars['String']>;
  /** An array relationship */
  stocked_vehicles: Array<Stocked_Vehicles>;
  /** An aggregate relationship */
  stocked_vehicles_aggregate: Stocked_Vehicles_Aggregate;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** Does the vehicle have Thermal Management */
  thermal_management?: Maybe<Scalars['String']>;
  third_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The start RPM for maximum Torque */
  torque_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the wheel) */
  turning_circle_between_kerbs?: Maybe<Scalars['String']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the front overhang) */
  turning_circle_between_walls?: Maybe<Scalars['String']>;
  type_of_anchor_age?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Does the Vehicle have Vehicle to Grid (V2G) Power Transfer Ability */
  vehicle_to_grid?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Load (V2L) Power Transfer Ability */
  vehicle_to_load?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Vehicle (V2V) Power Transfer Ability */
  vehicle_to_vehicle?: Maybe<Scalars['String']>;
  /** Type of vehicle Eg Passenger, Light, Heavy Commercial */
  vehicle_type?: Maybe<Scalars['String']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The VFacts Class */
  vfacts_class?: Maybe<Scalars['String']>;
  /** The VFacts Pricing Segment */
  vfacts_price?: Maybe<Scalars['String']>;
  /** The VFacts Segment */
  vfacts_segment?: Maybe<Scalars['String']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years for which the vehicle body is warranted against perforation from corrosion */
  warranty_anti_corrosion?: Maybe<Scalars['String']>;
  /** For example roadside assist */
  warranty_customer_assist?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  warranty_extra_info?: Maybe<Scalars['String']>;
  /** The number of km the warranty covers (-1 = Unlimited) */
  warranty_km?: Maybe<Scalars['String']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Wheel Base configuration of the vehicle, ie: LWB, SWB etc */
  wheel_base_configuration?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg March */
  year_group_month_name?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg Mar */
  year_group_month_name_short?: Maybe<Scalars['String']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};


/** describes specifications for a vehicle based on its redbook code */
export type Vehicle_SpecificationsCustom_VehiclesArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


/** describes specifications for a vehicle based on its redbook code */
export type Vehicle_SpecificationsCustom_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Vehicles_Order_By>>;
  where?: Maybe<Custom_Vehicles_Bool_Exp>;
};


/** describes specifications for a vehicle based on its redbook code */
export type Vehicle_SpecificationsStocked_VehiclesArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};


/** describes specifications for a vehicle based on its redbook code */
export type Vehicle_SpecificationsStocked_Vehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Stocked_Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stocked_Vehicles_Order_By>>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
};

/** aggregated selection of "vehicle_specifications" */
export type Vehicle_Specifications_Aggregate = {
  __typename?: 'vehicle_specifications_aggregate';
  aggregate?: Maybe<Vehicle_Specifications_Aggregate_Fields>;
  nodes: Array<Vehicle_Specifications>;
};

/** aggregate fields of "vehicle_specifications" */
export type Vehicle_Specifications_Aggregate_Fields = {
  __typename?: 'vehicle_specifications_aggregate_fields';
  avg?: Maybe<Vehicle_Specifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicle_Specifications_Max_Fields>;
  min?: Maybe<Vehicle_Specifications_Min_Fields>;
  stddev?: Maybe<Vehicle_Specifications_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Specifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Specifications_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Specifications_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Specifications_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Specifications_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Specifications_Variance_Fields>;
};


/** aggregate fields of "vehicle_specifications" */
export type Vehicle_Specifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Specifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vehicle_Specifications_Avg_Fields = {
  __typename?: 'vehicle_specifications_avg_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vehicle_specifications". All fields are combined with a logical 'AND'. */
export type Vehicle_Specifications_Bool_Exp = {
  _and?: Maybe<Array<Vehicle_Specifications_Bool_Exp>>;
  _not?: Maybe<Vehicle_Specifications_Bool_Exp>;
  _or?: Maybe<Array<Vehicle_Specifications_Bool_Exp>>;
  acceleration?: Maybe<Numeric_Comparison_Exp>;
  adblue_tank_capacity?: Maybe<String_Comparison_Exp>;
  adr_vehicle_category?: Maybe<String_Comparison_Exp>;
  adr_vehicle_sub_category?: Maybe<String_Comparison_Exp>;
  air_pollution_rating?: Maybe<Numeric_Comparison_Exp>;
  ancap_rating?: Maybe<Numeric_Comparison_Exp>;
  ancap_year?: Maybe<Numeric_Comparison_Exp>;
  approach_angle?: Maybe<Numeric_Comparison_Exp>;
  badge?: Maybe<String_Comparison_Exp>;
  badge_part1?: Maybe<String_Comparison_Exp>;
  badge_part2?: Maybe<String_Comparison_Exp>;
  battery_normal_charge_time?: Maybe<Numeric_Comparison_Exp>;
  battery_normal_charge_voltage?: Maybe<Numeric_Comparison_Exp>;
  battery_quick_charge_power?: Maybe<Numeric_Comparison_Exp>;
  battery_quick_charge_time?: Maybe<Numeric_Comparison_Exp>;
  battery_quick_charge_voltage?: Maybe<Numeric_Comparison_Exp>;
  body_configuration?: Maybe<String_Comparison_Exp>;
  body_style?: Maybe<String_Comparison_Exp>;
  break_over_angle?: Maybe<String_Comparison_Exp>;
  cam_shaft?: Maybe<String_Comparison_Exp>;
  co2_combined?: Maybe<Numeric_Comparison_Exp>;
  co2_extra_urban?: Maybe<Numeric_Comparison_Exp>;
  co2_urban?: Maybe<Numeric_Comparison_Exp>;
  combined_power_kw?: Maybe<String_Comparison_Exp>;
  combined_torque?: Maybe<String_Comparison_Exp>;
  compression_ratio?: Maybe<String_Comparison_Exp>;
  country_import?: Maybe<String_Comparison_Exp>;
  country_origin?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_release?: Maybe<Boolean_Comparison_Exp>;
  custom_vehicles?: Maybe<Custom_Vehicles_Bool_Exp>;
  custom_vehicles_aggregate?: Maybe<Custom_Vehicles_Aggregate_Bool_Exp>;
  cylinders?: Maybe<Numeric_Comparison_Exp>;
  departure_angle?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  door_no?: Maybe<Numeric_Comparison_Exp>;
  drive?: Maybe<String_Comparison_Exp>;
  drive_away?: Maybe<Boolean_Comparison_Exp>;
  drive_short?: Maybe<String_Comparison_Exp>;
  electric_eng50kwdc_charge_time?: Maybe<Numeric_Comparison_Exp>;
  electric_eng_quick_charge_capacity?: Maybe<String_Comparison_Exp>;
  electric_eng_wallbox_charge_time?: Maybe<Numeric_Comparison_Exp>;
  electric_eng_wallbox_power?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_amp_hours?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_battery_type?: Maybe<String_Comparison_Exp>;
  electric_engine_charging_method?: Maybe<String_Comparison_Exp>;
  electric_engine_current_type?: Maybe<String_Comparison_Exp>;
  electric_engine_drive?: Maybe<String_Comparison_Exp>;
  electric_engine_km_range?: Maybe<String_Comparison_Exp>;
  electric_engine_location?: Maybe<String_Comparison_Exp>;
  electric_engine_power_kw?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_power_rpm_from?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_power_rpm_to?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_top_speed?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_torque?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_torque_rpm_from?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_torque_rpm_to?: Maybe<Numeric_Comparison_Exp>;
  electric_engine_type?: Maybe<String_Comparison_Exp>;
  electric_engine_volts?: Maybe<Numeric_Comparison_Exp>;
  emission_standard?: Maybe<String_Comparison_Exp>;
  energy_consumption?: Maybe<String_Comparison_Exp>;
  engine_configuration?: Maybe<String_Comparison_Exp>;
  engine_cycle?: Maybe<String_Comparison_Exp>;
  engine_description?: Maybe<String_Comparison_Exp>;
  engine_description_full?: Maybe<String_Comparison_Exp>;
  engine_location?: Maybe<String_Comparison_Exp>;
  engine_size?: Maybe<Numeric_Comparison_Exp>;
  engine_size_litres?: Maybe<Numeric_Comparison_Exp>;
  engine_type?: Maybe<String_Comparison_Exp>;
  extra_identification?: Maybe<String_Comparison_Exp>;
  family_name?: Maybe<String_Comparison_Exp>;
  first_service_kms?: Maybe<Numeric_Comparison_Exp>;
  first_service_months?: Maybe<Numeric_Comparison_Exp>;
  free_scheduled_service?: Maybe<String_Comparison_Exp>;
  front_overhang?: Maybe<String_Comparison_Exp>;
  front_passenger_seat_anchor_point_location?: Maybe<String_Comparison_Exp>;
  front_rim_description?: Maybe<String_Comparison_Exp>;
  front_rim_diameter?: Maybe<String_Comparison_Exp>;
  front_rim_extra_info?: Maybe<String_Comparison_Exp>;
  front_rim_width?: Maybe<String_Comparison_Exp>;
  front_tyre_aspect_ratio?: Maybe<String_Comparison_Exp>;
  front_tyre_construction?: Maybe<String_Comparison_Exp>;
  front_tyre_diameter?: Maybe<String_Comparison_Exp>;
  front_tyre_extra_info?: Maybe<String_Comparison_Exp>;
  front_tyre_load_index?: Maybe<String_Comparison_Exp>;
  front_tyre_size?: Maybe<String_Comparison_Exp>;
  front_tyre_speed_rating?: Maybe<String_Comparison_Exp>;
  front_tyre_width?: Maybe<String_Comparison_Exp>;
  fuel_avg_distance?: Maybe<Numeric_Comparison_Exp>;
  fuel_capacity?: Maybe<Numeric_Comparison_Exp>;
  fuel_consumption_city?: Maybe<Numeric_Comparison_Exp>;
  fuel_consumption_combined?: Maybe<Numeric_Comparison_Exp>;
  fuel_consumption_highway?: Maybe<Numeric_Comparison_Exp>;
  fuel_delivery?: Maybe<String_Comparison_Exp>;
  fuel_max_distance?: Maybe<Numeric_Comparison_Exp>;
  fuel_method_of_delivery?: Maybe<String_Comparison_Exp>;
  fuel_min_distance?: Maybe<Numeric_Comparison_Exp>;
  fuel_type?: Maybe<String_Comparison_Exp>;
  fuel_type_generic?: Maybe<String_Comparison_Exp>;
  gear_location?: Maybe<String_Comparison_Exp>;
  gear_no?: Maybe<Numeric_Comparison_Exp>;
  gear_type?: Maybe<String_Comparison_Exp>;
  generation_name?: Maybe<String_Comparison_Exp>;
  generation_version?: Maybe<String_Comparison_Exp>;
  green_house_rating?: Maybe<Numeric_Comparison_Exp>;
  gross_combination_mass?: Maybe<String_Comparison_Exp>;
  gross_vehicle_mass?: Maybe<String_Comparison_Exp>;
  ground_clearance?: Maybe<String_Comparison_Exp>;
  hv_battery_ac_fast_ch_desc?: Maybe<String_Comparison_Exp>;
  hv_battery_ac_power_outlet_ch_desc?: Maybe<String_Comparison_Exp>;
  hv_battery_capacity?: Maybe<String_Comparison_Exp>;
  hv_battery_charge_socket_type?: Maybe<String_Comparison_Exp>;
  hv_battery_dc_charge_socket_type?: Maybe<String_Comparison_Exp>;
  hv_battery_dc_fast_ch_desc?: Maybe<String_Comparison_Exp>;
  hv_battery_voltage?: Maybe<String_Comparison_Exp>;
  hv_battery_warranty_km?: Maybe<String_Comparison_Exp>;
  hv_battery_warranty_years?: Maybe<String_Comparison_Exp>;
  hv_warranty_extra_info?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  induction?: Maybe<String_Comparison_Exp>;
  kerb_weight?: Maybe<Numeric_Comparison_Exp>;
  km_range_manufacturer?: Maybe<String_Comparison_Exp>;
  km_range_wltp?: Maybe<String_Comparison_Exp>;
  lct_included?: Maybe<Boolean_Comparison_Exp>;
  limited_edition?: Maybe<Boolean_Comparison_Exp>;
  location_of_storage?: Maybe<String_Comparison_Exp>;
  make_name?: Maybe<String_Comparison_Exp>;
  manufacturer_power_plant_type?: Maybe<String_Comparison_Exp>;
  max_ethanol_blend?: Maybe<String_Comparison_Exp>;
  maximum_speed?: Maybe<String_Comparison_Exp>;
  maximum_towball_download?: Maybe<String_Comparison_Exp>;
  model_year?: Maybe<String_Comparison_Exp>;
  new_price?: Maybe<Numeric_Comparison_Exp>;
  no_airbags?: Maybe<Numeric_Comparison_Exp>;
  no_child_seat_anchor_age_points?: Maybe<String_Comparison_Exp>;
  overall_green_star_rating?: Maybe<Numeric_Comparison_Exp>;
  payload?: Maybe<String_Comparison_Exp>;
  power_kw?: Maybe<Numeric_Comparison_Exp>;
  power_plant_type?: Maybe<String_Comparison_Exp>;
  power_rpm_from?: Maybe<String_Comparison_Exp>;
  power_rpm_to?: Maybe<Numeric_Comparison_Exp>;
  power_weight_ratio?: Maybe<Numeric_Comparison_Exp>;
  rear_overhang?: Maybe<String_Comparison_Exp>;
  rear_rim_description?: Maybe<String_Comparison_Exp>;
  rear_rim_diameter?: Maybe<String_Comparison_Exp>;
  rear_rim_extra_info?: Maybe<String_Comparison_Exp>;
  rear_rim_width?: Maybe<String_Comparison_Exp>;
  rear_tyre_aspect_ratio?: Maybe<String_Comparison_Exp>;
  rear_tyre_construction?: Maybe<String_Comparison_Exp>;
  rear_tyre_diameter?: Maybe<String_Comparison_Exp>;
  rear_tyre_extra_info?: Maybe<String_Comparison_Exp>;
  rear_tyre_load_index?: Maybe<String_Comparison_Exp>;
  rear_tyre_size?: Maybe<String_Comparison_Exp>;
  rear_tyre_speed_rating?: Maybe<String_Comparison_Exp>;
  rear_tyre_width?: Maybe<String_Comparison_Exp>;
  redbook_code?: Maybe<String_Comparison_Exp>;
  regular_service_kms?: Maybe<Numeric_Comparison_Exp>;
  regular_service_months?: Maybe<Numeric_Comparison_Exp>;
  release_month?: Maybe<Numeric_Comparison_Exp>;
  release_type?: Maybe<String_Comparison_Exp>;
  release_year?: Maybe<Numeric_Comparison_Exp>;
  rim_material?: Maybe<String_Comparison_Exp>;
  road_side_assistance_extra_info?: Maybe<String_Comparison_Exp>;
  road_side_assistance_km?: Maybe<String_Comparison_Exp>;
  road_side_assistance_years?: Maybe<String_Comparison_Exp>;
  ron_rating?: Maybe<String_Comparison_Exp>;
  roofline?: Maybe<String_Comparison_Exp>;
  seat_capacity?: Maybe<Numeric_Comparison_Exp>;
  second_row_centre_seat_anchor_point_location?: Maybe<String_Comparison_Exp>;
  second_row_outboard_seat_anchor_point_location?: Maybe<String_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  steering?: Maybe<String_Comparison_Exp>;
  stocked_vehicles?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicles_aggregate?: Maybe<Stocked_Vehicles_Aggregate_Bool_Exp>;
  tare_mass?: Maybe<Numeric_Comparison_Exp>;
  thermal_management?: Maybe<String_Comparison_Exp>;
  third_row_centre_seat_anchor_point_location?: Maybe<String_Comparison_Exp>;
  third_row_outboard_seat_anchor_point_location?: Maybe<String_Comparison_Exp>;
  torque?: Maybe<Numeric_Comparison_Exp>;
  torque_rpm_from?: Maybe<String_Comparison_Exp>;
  torque_rpm_to?: Maybe<Numeric_Comparison_Exp>;
  towing_capacity_braked?: Maybe<Numeric_Comparison_Exp>;
  towing_capacity_unbraked?: Maybe<Numeric_Comparison_Exp>;
  track_front?: Maybe<Numeric_Comparison_Exp>;
  track_rear?: Maybe<Numeric_Comparison_Exp>;
  turning_circle_between_kerbs?: Maybe<String_Comparison_Exp>;
  turning_circle_between_walls?: Maybe<String_Comparison_Exp>;
  type_of_anchor_age?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  valves_cylinder?: Maybe<Numeric_Comparison_Exp>;
  vehicle_age_months?: Maybe<Numeric_Comparison_Exp>;
  vehicle_age_years?: Maybe<Numeric_Comparison_Exp>;
  vehicle_height?: Maybe<Numeric_Comparison_Exp>;
  vehicle_length?: Maybe<Numeric_Comparison_Exp>;
  vehicle_to_grid?: Maybe<String_Comparison_Exp>;
  vehicle_to_load?: Maybe<String_Comparison_Exp>;
  vehicle_to_vehicle?: Maybe<String_Comparison_Exp>;
  vehicle_type?: Maybe<String_Comparison_Exp>;
  vehicle_width?: Maybe<Numeric_Comparison_Exp>;
  vfacts_class?: Maybe<String_Comparison_Exp>;
  vfacts_price?: Maybe<String_Comparison_Exp>;
  vfacts_segment?: Maybe<String_Comparison_Exp>;
  wading_depth?: Maybe<Numeric_Comparison_Exp>;
  warranty_anti_corrosion?: Maybe<String_Comparison_Exp>;
  warranty_customer_assist?: Maybe<String_Comparison_Exp>;
  warranty_extra_info?: Maybe<String_Comparison_Exp>;
  warranty_km?: Maybe<String_Comparison_Exp>;
  warranty_years?: Maybe<Numeric_Comparison_Exp>;
  wheel_base?: Maybe<Numeric_Comparison_Exp>;
  wheel_base_configuration?: Maybe<String_Comparison_Exp>;
  year_group_month?: Maybe<Numeric_Comparison_Exp>;
  year_group_month_name?: Maybe<String_Comparison_Exp>;
  year_group_month_name_short?: Maybe<String_Comparison_Exp>;
  year_group_sequence_no?: Maybe<Numeric_Comparison_Exp>;
  year_group_year?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_specifications" */
export enum Vehicle_Specifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleSpecificationsPkey = 'vehicle_specifications_pkey',
  /** unique or primary key constraint on columns "redbook_code" */
  VehicleSpecificationsRedbookCodeKey = 'vehicle_specifications_redbook_code_key'
}

/** input type for incrementing numeric columns in table "vehicle_specifications" */
export type Vehicle_Specifications_Inc_Input = {
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "vehicle_specifications" */
export type Vehicle_Specifications_Insert_Input = {
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  adblue_tank_capacity?: Maybe<Scalars['String']>;
  /** ADR defined parent category based on type and use of vehicle */
  adr_vehicle_category?: Maybe<Scalars['String']>;
  /** ADR sub-category based on specification */
  adr_vehicle_sub_category?: Maybe<Scalars['String']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** The Badge Eg Esseesse */
  badge?: Maybe<Scalars['String']>;
  /** The Badge description of the vehicle Eg EXECUTIVE */
  badge_part1?: Maybe<Scalars['String']>;
  /** The 2nd Badge description of the vehicle */
  badge_part2?: Maybe<Scalars['String']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The Body Configuration description of the vehicle Eg DUAL CAB */
  body_configuration?: Maybe<Scalars['String']>;
  /** The Body Style description of the vehicle Eg WAGON */
  body_style?: Maybe<Scalars['String']>;
  /** When a vehicle crests a hill, this is the angle at which the front tyres still remain on the ground as the rear wheels continue to climb. */
  break_over_angle?: Maybe<Scalars['String']>;
  /** The Cam description of the vehicle Eg Overhead Cam, Double Overhead Cam */
  cam_shaft?: Maybe<Scalars['String']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Combined Power (kW) */
  combined_power_kw?: Maybe<Scalars['String']>;
  /** Combined Torque (Nm) */
  combined_torque?: Maybe<Scalars['String']>;
  /** Compression ratio expressed to 1 */
  compression_ratio?: Maybe<Scalars['String']>;
  /** Vehicle Import Flag - I = Import, L = Local */
  country_import?: Maybe<Scalars['String']>;
  /** The Import Country of Origin description of the vehicle Eg Japan, USA */
  country_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Flag to indicate a current release. T = True, F = False */
  current_release?: Maybe<Scalars['Boolean']>;
  custom_vehicles?: Maybe<Custom_Vehicles_Arr_Rel_Insert_Input>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the rear of the vehicle on the ground. */
  departure_angle?: Maybe<Scalars['String']>;
  /** Full Description of the Vehicle */
  description?: Maybe<Scalars['String']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** The Drive description of the vehicle Eg Four Wheel Drive, Front Wheel Drive */
  drive?: Maybe<Scalars['String']>;
  /** Flag to indicate entered NewPrice is Driveaway. T = True, F = False */
  drive_away?: Maybe<Scalars['Boolean']>;
  /** The Drive abbreviation of the vehicle Eg F */
  drive_short?: Maybe<Scalars['String']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  electric_eng_quick_charge_capacity?: Maybe<Scalars['String']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_battery_type?: Maybe<Scalars['String']>;
  electric_engine_charging_method?: Maybe<Scalars['String']>;
  electric_engine_current_type?: Maybe<Scalars['String']>;
  electric_engine_drive?: Maybe<Scalars['String']>;
  electric_engine_km_range?: Maybe<Scalars['String']>;
  electric_engine_location?: Maybe<Scalars['String']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_type?: Maybe<Scalars['String']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** Which Emission standard this vehicle complies to (eg Euro IV) */
  emission_standard?: Maybe<Scalars['String']>;
  /** The vehicle`s electricity consumption per km as measured under testing standards (in Wh/km) */
  energy_consumption?: Maybe<Scalars['String']>;
  /** The Engine Configuration description of the vehicle Eg Inline, V90 */
  engine_configuration?: Maybe<Scalars['String']>;
  /** The Engine Cycle description of the vehicle Eg 2 Stroke, 4 Stroke */
  engine_cycle?: Maybe<Scalars['String']>;
  /** The common engine reference name, blank if there is no common name Eg 3.4 */
  engine_description?: Maybe<Scalars['String']>;
  /** The common engine reference name Eg 3.4i, 2.4DT */
  engine_description_full?: Maybe<Scalars['String']>;
  /** Front or Rear */
  engine_location?: Maybe<Scalars['String']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** The Engine Type description of the vehicle Eg Piston, Rotary */
  engine_type?: Maybe<Scalars['String']>;
  /** Extra Identification information for vehicle eg LWB, SWB */
  extra_identification?: Maybe<Scalars['String']>;
  /** The family name in full Eg COROLLA */
  family_name?: Maybe<Scalars['String']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  /** Interval (months or kilometres) for a free first service */
  free_scheduled_service?: Maybe<Scalars['String']>;
  /** The distance between the center line of the front axle and the front edge of the vehicle (mm) */
  front_overhang?: Maybe<Scalars['String']>;
  front_passenger_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Description of the front rim */
  front_rim_description?: Maybe<Scalars['String']>;
  front_rim_diameter?: Maybe<Scalars['String']>;
  front_rim_extra_info?: Maybe<Scalars['String']>;
  front_rim_width?: Maybe<Scalars['String']>;
  front_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  front_tyre_construction?: Maybe<Scalars['String']>;
  front_tyre_diameter?: Maybe<Scalars['String']>;
  front_tyre_extra_info?: Maybe<Scalars['String']>;
  front_tyre_load_index?: Maybe<Scalars['String']>;
  front_tyre_size?: Maybe<Scalars['String']>;
  front_tyre_speed_rating?: Maybe<Scalars['String']>;
  front_tyre_width?: Maybe<Scalars['String']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  /** The Fuel Delivery description of the vehicle Eg Carburettor Single, Multi-Point Injected */
  fuel_delivery?: Maybe<Scalars['String']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  /** The Method of Delivery description of the vehicle Eg Electronic, Mechanical */
  fuel_method_of_delivery?: Maybe<Scalars['String']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel Type description of the vehicle Eg Petrol - Unleaded (ULP) */
  fuel_type?: Maybe<Scalars['String']>;
  /** Eg Petrol */
  fuel_type_generic?: Maybe<Scalars['String']>;
  /** The Gear Location description of the vehicle Eg Floor, Column */
  gear_location?: Maybe<Scalars['String']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  /** The Gear Type description of the vehicle, Eg Automatic, Manual */
  gear_type?: Maybe<Scalars['String']>;
  generation_name?: Maybe<Scalars['String']>;
  generation_version?: Maybe<Scalars['String']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The total of the gross weight and braked towing capacity (kg) */
  gross_combination_mass?: Maybe<Scalars['String']>;
  /** The maximum laden mass of a motor vehicle as specified by the Manufacturer (kg) */
  gross_vehicle_mass?: Maybe<Scalars['String']>;
  ground_clearance?: Maybe<Scalars['String']>;
  /** The specification of the AC fast charge system for AC Fast Charge Time */
  hv_battery_ac_fast_ch_desc?: Maybe<Scalars['String']>;
  /** The specifcation of the socket type for Normal Charge Time */
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Scalars['String']>;
  /** The maximum capacity of the High Voltage Battery in kWh */
  hv_battery_capacity?: Maybe<Scalars['String']>;
  /** Standard plug type for the main HV battery charge port */
  hv_battery_charge_socket_type?: Maybe<Scalars['String']>;
  /** The socket type on the vehicle suitable for DC charging */
  hv_battery_dc_charge_socket_type?: Maybe<Scalars['String']>;
  /** The specification of the DC fast charge system for DC Fast Charge Time */
  hv_battery_dc_fast_ch_desc?: Maybe<Scalars['String']>;
  /** Maximum output Voltage of the High Voltage Battery */
  hv_battery_voltage?: Maybe<Scalars['String']>;
  /** The number of km the High Voltage Battery warranty covers (-1 = Unlimited) */
  hv_battery_warranty_km?: Maybe<Scalars['String']>;
  /** The number of years for which the High Voltage Battery is warranted against degradation to a given capacity */
  hv_battery_warranty_years?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  hv_warranty_extra_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The Induction description of the vehicle Eg Aspirated, Turbo */
  induction?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** Pure electric driving range in km (As claimed by the vehicle manufacturer) */
  km_range_manufacturer?: Maybe<Scalars['String']>;
  /** Pure electric driving range in km (WLTP standard) */
  km_range_wltp?: Maybe<Scalars['String']>;
  lct_included?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate a limited edition vehicle. T = True, F = False */
  limited_edition?: Maybe<Scalars['Boolean']>;
  /** Location of Storage */
  location_of_storage?: Maybe<Scalars['String']>;
  /** The Make Name of the Vehicle */
  make_name?: Maybe<Scalars['String']>;
  /** The OEM description of the vehicle`s motive power system */
  manufacturer_power_plant_type?: Maybe<Scalars['String']>;
  /** The Maximum Ethanol Blend */
  max_ethanol_blend?: Maybe<Scalars['String']>;
  /** Highest speed capable of travelling at (km/h) */
  maximum_speed?: Maybe<Scalars['String']>;
  maximum_towball_download?: Maybe<Scalars['String']>;
  /** The Model Year Code from the Manufacturer eg MY2009 */
  model_year?: Maybe<Scalars['String']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  no_child_seat_anchor_age_points?: Maybe<Scalars['String']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** Applies to goods carrying light commercials (kg) */
  payload?: Maybe<Scalars['String']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The motive power type description of the vehicle Eg Internal Combustion, Battery Electric */
  power_plant_type?: Maybe<Scalars['String']>;
  /** The start RPM when peak Power is achieved */
  power_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** The distance between the center line of the rear axle and the rear end of the vehicle (mm) */
  rear_overhang?: Maybe<Scalars['String']>;
  /** Description of the rear rim */
  rear_rim_description?: Maybe<Scalars['String']>;
  rear_rim_diameter?: Maybe<Scalars['String']>;
  rear_rim_extra_info?: Maybe<Scalars['String']>;
  rear_rim_width?: Maybe<Scalars['String']>;
  rear_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  rear_tyre_construction?: Maybe<Scalars['String']>;
  rear_tyre_diameter?: Maybe<Scalars['String']>;
  rear_tyre_extra_info?: Maybe<Scalars['String']>;
  rear_tyre_load_index?: Maybe<Scalars['String']>;
  rear_tyre_size?: Maybe<Scalars['String']>;
  rear_tyre_speed_rating?: Maybe<Scalars['String']>;
  rear_tyre_width?: Maybe<Scalars['String']>;
  /** The Red Book Code that uniquely identifies the vehicle eg AUVTOYO1999AADN */
  redbook_code?: Maybe<Scalars['String']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Type of the vehicle */
  release_type?: Maybe<Scalars['String']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** Alloy or Steel */
  rim_material?: Maybe<Scalars['String']>;
  /** Conditions covered by the roadside assistance program */
  road_side_assistance_extra_info?: Maybe<Scalars['String']>;
  /** The number of km for which the roadside assistance program is valid */
  road_side_assistance_km?: Maybe<Scalars['String']>;
  /** The number of years for which the vehicle is eligible for the factory roadside assistance program */
  road_side_assistance_years?: Maybe<Scalars['String']>;
  /** The recommended fuel RON rating */
  ron_rating?: Maybe<Scalars['String']>;
  /** Description of roof style, primarily for wagons and vans, ie: low roof, high roof */
  roofline?: Maybe<Scalars['String']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  second_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Series code (if any) eg VE */
  series?: Maybe<Scalars['String']>;
  /** The Steering description of the vehicle Eg 4 Wheel Steering, Rack and Pinion */
  steering?: Maybe<Scalars['String']>;
  stocked_vehicles?: Maybe<Stocked_Vehicles_Arr_Rel_Insert_Input>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** Does the vehicle have Thermal Management */
  thermal_management?: Maybe<Scalars['String']>;
  third_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The start RPM for maximum Torque */
  torque_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the wheel) */
  turning_circle_between_kerbs?: Maybe<Scalars['String']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the front overhang) */
  turning_circle_between_walls?: Maybe<Scalars['String']>;
  type_of_anchor_age?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Does the Vehicle have Vehicle to Grid (V2G) Power Transfer Ability */
  vehicle_to_grid?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Load (V2L) Power Transfer Ability */
  vehicle_to_load?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Vehicle (V2V) Power Transfer Ability */
  vehicle_to_vehicle?: Maybe<Scalars['String']>;
  /** Type of vehicle Eg Passenger, Light, Heavy Commercial */
  vehicle_type?: Maybe<Scalars['String']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The VFacts Class */
  vfacts_class?: Maybe<Scalars['String']>;
  /** The VFacts Pricing Segment */
  vfacts_price?: Maybe<Scalars['String']>;
  /** The VFacts Segment */
  vfacts_segment?: Maybe<Scalars['String']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years for which the vehicle body is warranted against perforation from corrosion */
  warranty_anti_corrosion?: Maybe<Scalars['String']>;
  /** For example roadside assist */
  warranty_customer_assist?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  warranty_extra_info?: Maybe<Scalars['String']>;
  /** The number of km the warranty covers (-1 = Unlimited) */
  warranty_km?: Maybe<Scalars['String']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Wheel Base configuration of the vehicle, ie: LWB, SWB etc */
  wheel_base_configuration?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg March */
  year_group_month_name?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg Mar */
  year_group_month_name_short?: Maybe<Scalars['String']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Vehicle_Specifications_Max_Fields = {
  __typename?: 'vehicle_specifications_max_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  adblue_tank_capacity?: Maybe<Scalars['String']>;
  /** ADR defined parent category based on type and use of vehicle */
  adr_vehicle_category?: Maybe<Scalars['String']>;
  /** ADR sub-category based on specification */
  adr_vehicle_sub_category?: Maybe<Scalars['String']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** The Badge Eg Esseesse */
  badge?: Maybe<Scalars['String']>;
  /** The Badge description of the vehicle Eg EXECUTIVE */
  badge_part1?: Maybe<Scalars['String']>;
  /** The 2nd Badge description of the vehicle */
  badge_part2?: Maybe<Scalars['String']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The Body Configuration description of the vehicle Eg DUAL CAB */
  body_configuration?: Maybe<Scalars['String']>;
  /** The Body Style description of the vehicle Eg WAGON */
  body_style?: Maybe<Scalars['String']>;
  /** When a vehicle crests a hill, this is the angle at which the front tyres still remain on the ground as the rear wheels continue to climb. */
  break_over_angle?: Maybe<Scalars['String']>;
  /** The Cam description of the vehicle Eg Overhead Cam, Double Overhead Cam */
  cam_shaft?: Maybe<Scalars['String']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Combined Power (kW) */
  combined_power_kw?: Maybe<Scalars['String']>;
  /** Combined Torque (Nm) */
  combined_torque?: Maybe<Scalars['String']>;
  /** Compression ratio expressed to 1 */
  compression_ratio?: Maybe<Scalars['String']>;
  /** Vehicle Import Flag - I = Import, L = Local */
  country_import?: Maybe<Scalars['String']>;
  /** The Import Country of Origin description of the vehicle Eg Japan, USA */
  country_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the rear of the vehicle on the ground. */
  departure_angle?: Maybe<Scalars['String']>;
  /** Full Description of the Vehicle */
  description?: Maybe<Scalars['String']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** The Drive description of the vehicle Eg Four Wheel Drive, Front Wheel Drive */
  drive?: Maybe<Scalars['String']>;
  /** The Drive abbreviation of the vehicle Eg F */
  drive_short?: Maybe<Scalars['String']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  electric_eng_quick_charge_capacity?: Maybe<Scalars['String']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_battery_type?: Maybe<Scalars['String']>;
  electric_engine_charging_method?: Maybe<Scalars['String']>;
  electric_engine_current_type?: Maybe<Scalars['String']>;
  electric_engine_drive?: Maybe<Scalars['String']>;
  electric_engine_km_range?: Maybe<Scalars['String']>;
  electric_engine_location?: Maybe<Scalars['String']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_type?: Maybe<Scalars['String']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** Which Emission standard this vehicle complies to (eg Euro IV) */
  emission_standard?: Maybe<Scalars['String']>;
  /** The vehicle`s electricity consumption per km as measured under testing standards (in Wh/km) */
  energy_consumption?: Maybe<Scalars['String']>;
  /** The Engine Configuration description of the vehicle Eg Inline, V90 */
  engine_configuration?: Maybe<Scalars['String']>;
  /** The Engine Cycle description of the vehicle Eg 2 Stroke, 4 Stroke */
  engine_cycle?: Maybe<Scalars['String']>;
  /** The common engine reference name, blank if there is no common name Eg 3.4 */
  engine_description?: Maybe<Scalars['String']>;
  /** The common engine reference name Eg 3.4i, 2.4DT */
  engine_description_full?: Maybe<Scalars['String']>;
  /** Front or Rear */
  engine_location?: Maybe<Scalars['String']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** The Engine Type description of the vehicle Eg Piston, Rotary */
  engine_type?: Maybe<Scalars['String']>;
  /** Extra Identification information for vehicle eg LWB, SWB */
  extra_identification?: Maybe<Scalars['String']>;
  /** The family name in full Eg COROLLA */
  family_name?: Maybe<Scalars['String']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  /** Interval (months or kilometres) for a free first service */
  free_scheduled_service?: Maybe<Scalars['String']>;
  /** The distance between the center line of the front axle and the front edge of the vehicle (mm) */
  front_overhang?: Maybe<Scalars['String']>;
  front_passenger_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Description of the front rim */
  front_rim_description?: Maybe<Scalars['String']>;
  front_rim_diameter?: Maybe<Scalars['String']>;
  front_rim_extra_info?: Maybe<Scalars['String']>;
  front_rim_width?: Maybe<Scalars['String']>;
  front_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  front_tyre_construction?: Maybe<Scalars['String']>;
  front_tyre_diameter?: Maybe<Scalars['String']>;
  front_tyre_extra_info?: Maybe<Scalars['String']>;
  front_tyre_load_index?: Maybe<Scalars['String']>;
  front_tyre_size?: Maybe<Scalars['String']>;
  front_tyre_speed_rating?: Maybe<Scalars['String']>;
  front_tyre_width?: Maybe<Scalars['String']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  /** The Fuel Delivery description of the vehicle Eg Carburettor Single, Multi-Point Injected */
  fuel_delivery?: Maybe<Scalars['String']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  /** The Method of Delivery description of the vehicle Eg Electronic, Mechanical */
  fuel_method_of_delivery?: Maybe<Scalars['String']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel Type description of the vehicle Eg Petrol - Unleaded (ULP) */
  fuel_type?: Maybe<Scalars['String']>;
  /** Eg Petrol */
  fuel_type_generic?: Maybe<Scalars['String']>;
  /** The Gear Location description of the vehicle Eg Floor, Column */
  gear_location?: Maybe<Scalars['String']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  /** The Gear Type description of the vehicle, Eg Automatic, Manual */
  gear_type?: Maybe<Scalars['String']>;
  generation_name?: Maybe<Scalars['String']>;
  generation_version?: Maybe<Scalars['String']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The total of the gross weight and braked towing capacity (kg) */
  gross_combination_mass?: Maybe<Scalars['String']>;
  /** The maximum laden mass of a motor vehicle as specified by the Manufacturer (kg) */
  gross_vehicle_mass?: Maybe<Scalars['String']>;
  ground_clearance?: Maybe<Scalars['String']>;
  /** The specification of the AC fast charge system for AC Fast Charge Time */
  hv_battery_ac_fast_ch_desc?: Maybe<Scalars['String']>;
  /** The specifcation of the socket type for Normal Charge Time */
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Scalars['String']>;
  /** The maximum capacity of the High Voltage Battery in kWh */
  hv_battery_capacity?: Maybe<Scalars['String']>;
  /** Standard plug type for the main HV battery charge port */
  hv_battery_charge_socket_type?: Maybe<Scalars['String']>;
  /** The socket type on the vehicle suitable for DC charging */
  hv_battery_dc_charge_socket_type?: Maybe<Scalars['String']>;
  /** The specification of the DC fast charge system for DC Fast Charge Time */
  hv_battery_dc_fast_ch_desc?: Maybe<Scalars['String']>;
  /** Maximum output Voltage of the High Voltage Battery */
  hv_battery_voltage?: Maybe<Scalars['String']>;
  /** The number of km the High Voltage Battery warranty covers (-1 = Unlimited) */
  hv_battery_warranty_km?: Maybe<Scalars['String']>;
  /** The number of years for which the High Voltage Battery is warranted against degradation to a given capacity */
  hv_battery_warranty_years?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  hv_warranty_extra_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The Induction description of the vehicle Eg Aspirated, Turbo */
  induction?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** Pure electric driving range in km (As claimed by the vehicle manufacturer) */
  km_range_manufacturer?: Maybe<Scalars['String']>;
  /** Pure electric driving range in km (WLTP standard) */
  km_range_wltp?: Maybe<Scalars['String']>;
  /** Location of Storage */
  location_of_storage?: Maybe<Scalars['String']>;
  /** The Make Name of the Vehicle */
  make_name?: Maybe<Scalars['String']>;
  /** The OEM description of the vehicle`s motive power system */
  manufacturer_power_plant_type?: Maybe<Scalars['String']>;
  /** The Maximum Ethanol Blend */
  max_ethanol_blend?: Maybe<Scalars['String']>;
  /** Highest speed capable of travelling at (km/h) */
  maximum_speed?: Maybe<Scalars['String']>;
  maximum_towball_download?: Maybe<Scalars['String']>;
  /** The Model Year Code from the Manufacturer eg MY2009 */
  model_year?: Maybe<Scalars['String']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  no_child_seat_anchor_age_points?: Maybe<Scalars['String']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** Applies to goods carrying light commercials (kg) */
  payload?: Maybe<Scalars['String']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The motive power type description of the vehicle Eg Internal Combustion, Battery Electric */
  power_plant_type?: Maybe<Scalars['String']>;
  /** The start RPM when peak Power is achieved */
  power_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** The distance between the center line of the rear axle and the rear end of the vehicle (mm) */
  rear_overhang?: Maybe<Scalars['String']>;
  /** Description of the rear rim */
  rear_rim_description?: Maybe<Scalars['String']>;
  rear_rim_diameter?: Maybe<Scalars['String']>;
  rear_rim_extra_info?: Maybe<Scalars['String']>;
  rear_rim_width?: Maybe<Scalars['String']>;
  rear_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  rear_tyre_construction?: Maybe<Scalars['String']>;
  rear_tyre_diameter?: Maybe<Scalars['String']>;
  rear_tyre_extra_info?: Maybe<Scalars['String']>;
  rear_tyre_load_index?: Maybe<Scalars['String']>;
  rear_tyre_size?: Maybe<Scalars['String']>;
  rear_tyre_speed_rating?: Maybe<Scalars['String']>;
  rear_tyre_width?: Maybe<Scalars['String']>;
  /** The Red Book Code that uniquely identifies the vehicle eg AUVTOYO1999AADN */
  redbook_code?: Maybe<Scalars['String']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Type of the vehicle */
  release_type?: Maybe<Scalars['String']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** Alloy or Steel */
  rim_material?: Maybe<Scalars['String']>;
  /** Conditions covered by the roadside assistance program */
  road_side_assistance_extra_info?: Maybe<Scalars['String']>;
  /** The number of km for which the roadside assistance program is valid */
  road_side_assistance_km?: Maybe<Scalars['String']>;
  /** The number of years for which the vehicle is eligible for the factory roadside assistance program */
  road_side_assistance_years?: Maybe<Scalars['String']>;
  /** The recommended fuel RON rating */
  ron_rating?: Maybe<Scalars['String']>;
  /** Description of roof style, primarily for wagons and vans, ie: low roof, high roof */
  roofline?: Maybe<Scalars['String']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  second_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Series code (if any) eg VE */
  series?: Maybe<Scalars['String']>;
  /** The Steering description of the vehicle Eg 4 Wheel Steering, Rack and Pinion */
  steering?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** Does the vehicle have Thermal Management */
  thermal_management?: Maybe<Scalars['String']>;
  third_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The start RPM for maximum Torque */
  torque_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the wheel) */
  turning_circle_between_kerbs?: Maybe<Scalars['String']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the front overhang) */
  turning_circle_between_walls?: Maybe<Scalars['String']>;
  type_of_anchor_age?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Does the Vehicle have Vehicle to Grid (V2G) Power Transfer Ability */
  vehicle_to_grid?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Load (V2L) Power Transfer Ability */
  vehicle_to_load?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Vehicle (V2V) Power Transfer Ability */
  vehicle_to_vehicle?: Maybe<Scalars['String']>;
  /** Type of vehicle Eg Passenger, Light, Heavy Commercial */
  vehicle_type?: Maybe<Scalars['String']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The VFacts Class */
  vfacts_class?: Maybe<Scalars['String']>;
  /** The VFacts Pricing Segment */
  vfacts_price?: Maybe<Scalars['String']>;
  /** The VFacts Segment */
  vfacts_segment?: Maybe<Scalars['String']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years for which the vehicle body is warranted against perforation from corrosion */
  warranty_anti_corrosion?: Maybe<Scalars['String']>;
  /** For example roadside assist */
  warranty_customer_assist?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  warranty_extra_info?: Maybe<Scalars['String']>;
  /** The number of km the warranty covers (-1 = Unlimited) */
  warranty_km?: Maybe<Scalars['String']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Wheel Base configuration of the vehicle, ie: LWB, SWB etc */
  wheel_base_configuration?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg March */
  year_group_month_name?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg Mar */
  year_group_month_name_short?: Maybe<Scalars['String']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Vehicle_Specifications_Min_Fields = {
  __typename?: 'vehicle_specifications_min_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  adblue_tank_capacity?: Maybe<Scalars['String']>;
  /** ADR defined parent category based on type and use of vehicle */
  adr_vehicle_category?: Maybe<Scalars['String']>;
  /** ADR sub-category based on specification */
  adr_vehicle_sub_category?: Maybe<Scalars['String']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** The Badge Eg Esseesse */
  badge?: Maybe<Scalars['String']>;
  /** The Badge description of the vehicle Eg EXECUTIVE */
  badge_part1?: Maybe<Scalars['String']>;
  /** The 2nd Badge description of the vehicle */
  badge_part2?: Maybe<Scalars['String']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The Body Configuration description of the vehicle Eg DUAL CAB */
  body_configuration?: Maybe<Scalars['String']>;
  /** The Body Style description of the vehicle Eg WAGON */
  body_style?: Maybe<Scalars['String']>;
  /** When a vehicle crests a hill, this is the angle at which the front tyres still remain on the ground as the rear wheels continue to climb. */
  break_over_angle?: Maybe<Scalars['String']>;
  /** The Cam description of the vehicle Eg Overhead Cam, Double Overhead Cam */
  cam_shaft?: Maybe<Scalars['String']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Combined Power (kW) */
  combined_power_kw?: Maybe<Scalars['String']>;
  /** Combined Torque (Nm) */
  combined_torque?: Maybe<Scalars['String']>;
  /** Compression ratio expressed to 1 */
  compression_ratio?: Maybe<Scalars['String']>;
  /** Vehicle Import Flag - I = Import, L = Local */
  country_import?: Maybe<Scalars['String']>;
  /** The Import Country of Origin description of the vehicle Eg Japan, USA */
  country_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the rear of the vehicle on the ground. */
  departure_angle?: Maybe<Scalars['String']>;
  /** Full Description of the Vehicle */
  description?: Maybe<Scalars['String']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** The Drive description of the vehicle Eg Four Wheel Drive, Front Wheel Drive */
  drive?: Maybe<Scalars['String']>;
  /** The Drive abbreviation of the vehicle Eg F */
  drive_short?: Maybe<Scalars['String']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  electric_eng_quick_charge_capacity?: Maybe<Scalars['String']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_battery_type?: Maybe<Scalars['String']>;
  electric_engine_charging_method?: Maybe<Scalars['String']>;
  electric_engine_current_type?: Maybe<Scalars['String']>;
  electric_engine_drive?: Maybe<Scalars['String']>;
  electric_engine_km_range?: Maybe<Scalars['String']>;
  electric_engine_location?: Maybe<Scalars['String']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_type?: Maybe<Scalars['String']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** Which Emission standard this vehicle complies to (eg Euro IV) */
  emission_standard?: Maybe<Scalars['String']>;
  /** The vehicle`s electricity consumption per km as measured under testing standards (in Wh/km) */
  energy_consumption?: Maybe<Scalars['String']>;
  /** The Engine Configuration description of the vehicle Eg Inline, V90 */
  engine_configuration?: Maybe<Scalars['String']>;
  /** The Engine Cycle description of the vehicle Eg 2 Stroke, 4 Stroke */
  engine_cycle?: Maybe<Scalars['String']>;
  /** The common engine reference name, blank if there is no common name Eg 3.4 */
  engine_description?: Maybe<Scalars['String']>;
  /** The common engine reference name Eg 3.4i, 2.4DT */
  engine_description_full?: Maybe<Scalars['String']>;
  /** Front or Rear */
  engine_location?: Maybe<Scalars['String']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** The Engine Type description of the vehicle Eg Piston, Rotary */
  engine_type?: Maybe<Scalars['String']>;
  /** Extra Identification information for vehicle eg LWB, SWB */
  extra_identification?: Maybe<Scalars['String']>;
  /** The family name in full Eg COROLLA */
  family_name?: Maybe<Scalars['String']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  /** Interval (months or kilometres) for a free first service */
  free_scheduled_service?: Maybe<Scalars['String']>;
  /** The distance between the center line of the front axle and the front edge of the vehicle (mm) */
  front_overhang?: Maybe<Scalars['String']>;
  front_passenger_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Description of the front rim */
  front_rim_description?: Maybe<Scalars['String']>;
  front_rim_diameter?: Maybe<Scalars['String']>;
  front_rim_extra_info?: Maybe<Scalars['String']>;
  front_rim_width?: Maybe<Scalars['String']>;
  front_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  front_tyre_construction?: Maybe<Scalars['String']>;
  front_tyre_diameter?: Maybe<Scalars['String']>;
  front_tyre_extra_info?: Maybe<Scalars['String']>;
  front_tyre_load_index?: Maybe<Scalars['String']>;
  front_tyre_size?: Maybe<Scalars['String']>;
  front_tyre_speed_rating?: Maybe<Scalars['String']>;
  front_tyre_width?: Maybe<Scalars['String']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  /** The Fuel Delivery description of the vehicle Eg Carburettor Single, Multi-Point Injected */
  fuel_delivery?: Maybe<Scalars['String']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  /** The Method of Delivery description of the vehicle Eg Electronic, Mechanical */
  fuel_method_of_delivery?: Maybe<Scalars['String']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel Type description of the vehicle Eg Petrol - Unleaded (ULP) */
  fuel_type?: Maybe<Scalars['String']>;
  /** Eg Petrol */
  fuel_type_generic?: Maybe<Scalars['String']>;
  /** The Gear Location description of the vehicle Eg Floor, Column */
  gear_location?: Maybe<Scalars['String']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  /** The Gear Type description of the vehicle, Eg Automatic, Manual */
  gear_type?: Maybe<Scalars['String']>;
  generation_name?: Maybe<Scalars['String']>;
  generation_version?: Maybe<Scalars['String']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The total of the gross weight and braked towing capacity (kg) */
  gross_combination_mass?: Maybe<Scalars['String']>;
  /** The maximum laden mass of a motor vehicle as specified by the Manufacturer (kg) */
  gross_vehicle_mass?: Maybe<Scalars['String']>;
  ground_clearance?: Maybe<Scalars['String']>;
  /** The specification of the AC fast charge system for AC Fast Charge Time */
  hv_battery_ac_fast_ch_desc?: Maybe<Scalars['String']>;
  /** The specifcation of the socket type for Normal Charge Time */
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Scalars['String']>;
  /** The maximum capacity of the High Voltage Battery in kWh */
  hv_battery_capacity?: Maybe<Scalars['String']>;
  /** Standard plug type for the main HV battery charge port */
  hv_battery_charge_socket_type?: Maybe<Scalars['String']>;
  /** The socket type on the vehicle suitable for DC charging */
  hv_battery_dc_charge_socket_type?: Maybe<Scalars['String']>;
  /** The specification of the DC fast charge system for DC Fast Charge Time */
  hv_battery_dc_fast_ch_desc?: Maybe<Scalars['String']>;
  /** Maximum output Voltage of the High Voltage Battery */
  hv_battery_voltage?: Maybe<Scalars['String']>;
  /** The number of km the High Voltage Battery warranty covers (-1 = Unlimited) */
  hv_battery_warranty_km?: Maybe<Scalars['String']>;
  /** The number of years for which the High Voltage Battery is warranted against degradation to a given capacity */
  hv_battery_warranty_years?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  hv_warranty_extra_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The Induction description of the vehicle Eg Aspirated, Turbo */
  induction?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** Pure electric driving range in km (As claimed by the vehicle manufacturer) */
  km_range_manufacturer?: Maybe<Scalars['String']>;
  /** Pure electric driving range in km (WLTP standard) */
  km_range_wltp?: Maybe<Scalars['String']>;
  /** Location of Storage */
  location_of_storage?: Maybe<Scalars['String']>;
  /** The Make Name of the Vehicle */
  make_name?: Maybe<Scalars['String']>;
  /** The OEM description of the vehicle`s motive power system */
  manufacturer_power_plant_type?: Maybe<Scalars['String']>;
  /** The Maximum Ethanol Blend */
  max_ethanol_blend?: Maybe<Scalars['String']>;
  /** Highest speed capable of travelling at (km/h) */
  maximum_speed?: Maybe<Scalars['String']>;
  maximum_towball_download?: Maybe<Scalars['String']>;
  /** The Model Year Code from the Manufacturer eg MY2009 */
  model_year?: Maybe<Scalars['String']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  no_child_seat_anchor_age_points?: Maybe<Scalars['String']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** Applies to goods carrying light commercials (kg) */
  payload?: Maybe<Scalars['String']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The motive power type description of the vehicle Eg Internal Combustion, Battery Electric */
  power_plant_type?: Maybe<Scalars['String']>;
  /** The start RPM when peak Power is achieved */
  power_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** The distance between the center line of the rear axle and the rear end of the vehicle (mm) */
  rear_overhang?: Maybe<Scalars['String']>;
  /** Description of the rear rim */
  rear_rim_description?: Maybe<Scalars['String']>;
  rear_rim_diameter?: Maybe<Scalars['String']>;
  rear_rim_extra_info?: Maybe<Scalars['String']>;
  rear_rim_width?: Maybe<Scalars['String']>;
  rear_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  rear_tyre_construction?: Maybe<Scalars['String']>;
  rear_tyre_diameter?: Maybe<Scalars['String']>;
  rear_tyre_extra_info?: Maybe<Scalars['String']>;
  rear_tyre_load_index?: Maybe<Scalars['String']>;
  rear_tyre_size?: Maybe<Scalars['String']>;
  rear_tyre_speed_rating?: Maybe<Scalars['String']>;
  rear_tyre_width?: Maybe<Scalars['String']>;
  /** The Red Book Code that uniquely identifies the vehicle eg AUVTOYO1999AADN */
  redbook_code?: Maybe<Scalars['String']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Type of the vehicle */
  release_type?: Maybe<Scalars['String']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** Alloy or Steel */
  rim_material?: Maybe<Scalars['String']>;
  /** Conditions covered by the roadside assistance program */
  road_side_assistance_extra_info?: Maybe<Scalars['String']>;
  /** The number of km for which the roadside assistance program is valid */
  road_side_assistance_km?: Maybe<Scalars['String']>;
  /** The number of years for which the vehicle is eligible for the factory roadside assistance program */
  road_side_assistance_years?: Maybe<Scalars['String']>;
  /** The recommended fuel RON rating */
  ron_rating?: Maybe<Scalars['String']>;
  /** Description of roof style, primarily for wagons and vans, ie: low roof, high roof */
  roofline?: Maybe<Scalars['String']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  second_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Series code (if any) eg VE */
  series?: Maybe<Scalars['String']>;
  /** The Steering description of the vehicle Eg 4 Wheel Steering, Rack and Pinion */
  steering?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** Does the vehicle have Thermal Management */
  thermal_management?: Maybe<Scalars['String']>;
  third_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The start RPM for maximum Torque */
  torque_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the wheel) */
  turning_circle_between_kerbs?: Maybe<Scalars['String']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the front overhang) */
  turning_circle_between_walls?: Maybe<Scalars['String']>;
  type_of_anchor_age?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Does the Vehicle have Vehicle to Grid (V2G) Power Transfer Ability */
  vehicle_to_grid?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Load (V2L) Power Transfer Ability */
  vehicle_to_load?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Vehicle (V2V) Power Transfer Ability */
  vehicle_to_vehicle?: Maybe<Scalars['String']>;
  /** Type of vehicle Eg Passenger, Light, Heavy Commercial */
  vehicle_type?: Maybe<Scalars['String']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The VFacts Class */
  vfacts_class?: Maybe<Scalars['String']>;
  /** The VFacts Pricing Segment */
  vfacts_price?: Maybe<Scalars['String']>;
  /** The VFacts Segment */
  vfacts_segment?: Maybe<Scalars['String']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years for which the vehicle body is warranted against perforation from corrosion */
  warranty_anti_corrosion?: Maybe<Scalars['String']>;
  /** For example roadside assist */
  warranty_customer_assist?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  warranty_extra_info?: Maybe<Scalars['String']>;
  /** The number of km the warranty covers (-1 = Unlimited) */
  warranty_km?: Maybe<Scalars['String']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Wheel Base configuration of the vehicle, ie: LWB, SWB etc */
  wheel_base_configuration?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg March */
  year_group_month_name?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg Mar */
  year_group_month_name_short?: Maybe<Scalars['String']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "vehicle_specifications" */
export type Vehicle_Specifications_Mutation_Response = {
  __typename?: 'vehicle_specifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Specifications>;
};

/** input type for inserting object relation for remote table "vehicle_specifications" */
export type Vehicle_Specifications_Obj_Rel_Insert_Input = {
  data: Vehicle_Specifications_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicle_Specifications_On_Conflict>;
};

/** on_conflict condition type for table "vehicle_specifications" */
export type Vehicle_Specifications_On_Conflict = {
  constraint: Vehicle_Specifications_Constraint;
  update_columns?: Array<Vehicle_Specifications_Update_Column>;
  where?: Maybe<Vehicle_Specifications_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_specifications". */
export type Vehicle_Specifications_Order_By = {
  acceleration?: Maybe<Order_By>;
  adblue_tank_capacity?: Maybe<Order_By>;
  adr_vehicle_category?: Maybe<Order_By>;
  adr_vehicle_sub_category?: Maybe<Order_By>;
  air_pollution_rating?: Maybe<Order_By>;
  ancap_rating?: Maybe<Order_By>;
  ancap_year?: Maybe<Order_By>;
  approach_angle?: Maybe<Order_By>;
  badge?: Maybe<Order_By>;
  badge_part1?: Maybe<Order_By>;
  badge_part2?: Maybe<Order_By>;
  battery_normal_charge_time?: Maybe<Order_By>;
  battery_normal_charge_voltage?: Maybe<Order_By>;
  battery_quick_charge_power?: Maybe<Order_By>;
  battery_quick_charge_time?: Maybe<Order_By>;
  battery_quick_charge_voltage?: Maybe<Order_By>;
  body_configuration?: Maybe<Order_By>;
  body_style?: Maybe<Order_By>;
  break_over_angle?: Maybe<Order_By>;
  cam_shaft?: Maybe<Order_By>;
  co2_combined?: Maybe<Order_By>;
  co2_extra_urban?: Maybe<Order_By>;
  co2_urban?: Maybe<Order_By>;
  combined_power_kw?: Maybe<Order_By>;
  combined_torque?: Maybe<Order_By>;
  compression_ratio?: Maybe<Order_By>;
  country_import?: Maybe<Order_By>;
  country_origin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_release?: Maybe<Order_By>;
  custom_vehicles_aggregate?: Maybe<Custom_Vehicles_Aggregate_Order_By>;
  cylinders?: Maybe<Order_By>;
  departure_angle?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  door_no?: Maybe<Order_By>;
  drive?: Maybe<Order_By>;
  drive_away?: Maybe<Order_By>;
  drive_short?: Maybe<Order_By>;
  electric_eng50kwdc_charge_time?: Maybe<Order_By>;
  electric_eng_quick_charge_capacity?: Maybe<Order_By>;
  electric_eng_wallbox_charge_time?: Maybe<Order_By>;
  electric_eng_wallbox_power?: Maybe<Order_By>;
  electric_engine_amp_hours?: Maybe<Order_By>;
  electric_engine_battery_type?: Maybe<Order_By>;
  electric_engine_charging_method?: Maybe<Order_By>;
  electric_engine_current_type?: Maybe<Order_By>;
  electric_engine_drive?: Maybe<Order_By>;
  electric_engine_km_range?: Maybe<Order_By>;
  electric_engine_location?: Maybe<Order_By>;
  electric_engine_power_kw?: Maybe<Order_By>;
  electric_engine_power_rpm_from?: Maybe<Order_By>;
  electric_engine_power_rpm_to?: Maybe<Order_By>;
  electric_engine_top_speed?: Maybe<Order_By>;
  electric_engine_torque?: Maybe<Order_By>;
  electric_engine_torque_rpm_from?: Maybe<Order_By>;
  electric_engine_torque_rpm_to?: Maybe<Order_By>;
  electric_engine_type?: Maybe<Order_By>;
  electric_engine_volts?: Maybe<Order_By>;
  emission_standard?: Maybe<Order_By>;
  energy_consumption?: Maybe<Order_By>;
  engine_configuration?: Maybe<Order_By>;
  engine_cycle?: Maybe<Order_By>;
  engine_description?: Maybe<Order_By>;
  engine_description_full?: Maybe<Order_By>;
  engine_location?: Maybe<Order_By>;
  engine_size?: Maybe<Order_By>;
  engine_size_litres?: Maybe<Order_By>;
  engine_type?: Maybe<Order_By>;
  extra_identification?: Maybe<Order_By>;
  family_name?: Maybe<Order_By>;
  first_service_kms?: Maybe<Order_By>;
  first_service_months?: Maybe<Order_By>;
  free_scheduled_service?: Maybe<Order_By>;
  front_overhang?: Maybe<Order_By>;
  front_passenger_seat_anchor_point_location?: Maybe<Order_By>;
  front_rim_description?: Maybe<Order_By>;
  front_rim_diameter?: Maybe<Order_By>;
  front_rim_extra_info?: Maybe<Order_By>;
  front_rim_width?: Maybe<Order_By>;
  front_tyre_aspect_ratio?: Maybe<Order_By>;
  front_tyre_construction?: Maybe<Order_By>;
  front_tyre_diameter?: Maybe<Order_By>;
  front_tyre_extra_info?: Maybe<Order_By>;
  front_tyre_load_index?: Maybe<Order_By>;
  front_tyre_size?: Maybe<Order_By>;
  front_tyre_speed_rating?: Maybe<Order_By>;
  front_tyre_width?: Maybe<Order_By>;
  fuel_avg_distance?: Maybe<Order_By>;
  fuel_capacity?: Maybe<Order_By>;
  fuel_consumption_city?: Maybe<Order_By>;
  fuel_consumption_combined?: Maybe<Order_By>;
  fuel_consumption_highway?: Maybe<Order_By>;
  fuel_delivery?: Maybe<Order_By>;
  fuel_max_distance?: Maybe<Order_By>;
  fuel_method_of_delivery?: Maybe<Order_By>;
  fuel_min_distance?: Maybe<Order_By>;
  fuel_type?: Maybe<Order_By>;
  fuel_type_generic?: Maybe<Order_By>;
  gear_location?: Maybe<Order_By>;
  gear_no?: Maybe<Order_By>;
  gear_type?: Maybe<Order_By>;
  generation_name?: Maybe<Order_By>;
  generation_version?: Maybe<Order_By>;
  green_house_rating?: Maybe<Order_By>;
  gross_combination_mass?: Maybe<Order_By>;
  gross_vehicle_mass?: Maybe<Order_By>;
  ground_clearance?: Maybe<Order_By>;
  hv_battery_ac_fast_ch_desc?: Maybe<Order_By>;
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Order_By>;
  hv_battery_capacity?: Maybe<Order_By>;
  hv_battery_charge_socket_type?: Maybe<Order_By>;
  hv_battery_dc_charge_socket_type?: Maybe<Order_By>;
  hv_battery_dc_fast_ch_desc?: Maybe<Order_By>;
  hv_battery_voltage?: Maybe<Order_By>;
  hv_battery_warranty_km?: Maybe<Order_By>;
  hv_battery_warranty_years?: Maybe<Order_By>;
  hv_warranty_extra_info?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  induction?: Maybe<Order_By>;
  kerb_weight?: Maybe<Order_By>;
  km_range_manufacturer?: Maybe<Order_By>;
  km_range_wltp?: Maybe<Order_By>;
  lct_included?: Maybe<Order_By>;
  limited_edition?: Maybe<Order_By>;
  location_of_storage?: Maybe<Order_By>;
  make_name?: Maybe<Order_By>;
  manufacturer_power_plant_type?: Maybe<Order_By>;
  max_ethanol_blend?: Maybe<Order_By>;
  maximum_speed?: Maybe<Order_By>;
  maximum_towball_download?: Maybe<Order_By>;
  model_year?: Maybe<Order_By>;
  new_price?: Maybe<Order_By>;
  no_airbags?: Maybe<Order_By>;
  no_child_seat_anchor_age_points?: Maybe<Order_By>;
  overall_green_star_rating?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  power_kw?: Maybe<Order_By>;
  power_plant_type?: Maybe<Order_By>;
  power_rpm_from?: Maybe<Order_By>;
  power_rpm_to?: Maybe<Order_By>;
  power_weight_ratio?: Maybe<Order_By>;
  rear_overhang?: Maybe<Order_By>;
  rear_rim_description?: Maybe<Order_By>;
  rear_rim_diameter?: Maybe<Order_By>;
  rear_rim_extra_info?: Maybe<Order_By>;
  rear_rim_width?: Maybe<Order_By>;
  rear_tyre_aspect_ratio?: Maybe<Order_By>;
  rear_tyre_construction?: Maybe<Order_By>;
  rear_tyre_diameter?: Maybe<Order_By>;
  rear_tyre_extra_info?: Maybe<Order_By>;
  rear_tyre_load_index?: Maybe<Order_By>;
  rear_tyre_size?: Maybe<Order_By>;
  rear_tyre_speed_rating?: Maybe<Order_By>;
  rear_tyre_width?: Maybe<Order_By>;
  redbook_code?: Maybe<Order_By>;
  regular_service_kms?: Maybe<Order_By>;
  regular_service_months?: Maybe<Order_By>;
  release_month?: Maybe<Order_By>;
  release_type?: Maybe<Order_By>;
  release_year?: Maybe<Order_By>;
  rim_material?: Maybe<Order_By>;
  road_side_assistance_extra_info?: Maybe<Order_By>;
  road_side_assistance_km?: Maybe<Order_By>;
  road_side_assistance_years?: Maybe<Order_By>;
  ron_rating?: Maybe<Order_By>;
  roofline?: Maybe<Order_By>;
  seat_capacity?: Maybe<Order_By>;
  second_row_centre_seat_anchor_point_location?: Maybe<Order_By>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  steering?: Maybe<Order_By>;
  stocked_vehicles_aggregate?: Maybe<Stocked_Vehicles_Aggregate_Order_By>;
  tare_mass?: Maybe<Order_By>;
  thermal_management?: Maybe<Order_By>;
  third_row_centre_seat_anchor_point_location?: Maybe<Order_By>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Order_By>;
  torque?: Maybe<Order_By>;
  torque_rpm_from?: Maybe<Order_By>;
  torque_rpm_to?: Maybe<Order_By>;
  towing_capacity_braked?: Maybe<Order_By>;
  towing_capacity_unbraked?: Maybe<Order_By>;
  track_front?: Maybe<Order_By>;
  track_rear?: Maybe<Order_By>;
  turning_circle_between_kerbs?: Maybe<Order_By>;
  turning_circle_between_walls?: Maybe<Order_By>;
  type_of_anchor_age?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  valves_cylinder?: Maybe<Order_By>;
  vehicle_age_months?: Maybe<Order_By>;
  vehicle_age_years?: Maybe<Order_By>;
  vehicle_height?: Maybe<Order_By>;
  vehicle_length?: Maybe<Order_By>;
  vehicle_to_grid?: Maybe<Order_By>;
  vehicle_to_load?: Maybe<Order_By>;
  vehicle_to_vehicle?: Maybe<Order_By>;
  vehicle_type?: Maybe<Order_By>;
  vehicle_width?: Maybe<Order_By>;
  vfacts_class?: Maybe<Order_By>;
  vfacts_price?: Maybe<Order_By>;
  vfacts_segment?: Maybe<Order_By>;
  wading_depth?: Maybe<Order_By>;
  warranty_anti_corrosion?: Maybe<Order_By>;
  warranty_customer_assist?: Maybe<Order_By>;
  warranty_extra_info?: Maybe<Order_By>;
  warranty_km?: Maybe<Order_By>;
  warranty_years?: Maybe<Order_By>;
  wheel_base?: Maybe<Order_By>;
  wheel_base_configuration?: Maybe<Order_By>;
  year_group_month?: Maybe<Order_By>;
  year_group_month_name?: Maybe<Order_By>;
  year_group_month_name_short?: Maybe<Order_By>;
  year_group_sequence_no?: Maybe<Order_By>;
  year_group_year?: Maybe<Order_By>;
};

/** primary key columns input for table: vehicle_specifications */
export type Vehicle_Specifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicle_specifications" */
export enum Vehicle_Specifications_Select_Column {
  /** column name */
  Acceleration = 'acceleration',
  /** column name */
  AdblueTankCapacity = 'adblue_tank_capacity',
  /** column name */
  AdrVehicleCategory = 'adr_vehicle_category',
  /** column name */
  AdrVehicleSubCategory = 'adr_vehicle_sub_category',
  /** column name */
  AirPollutionRating = 'air_pollution_rating',
  /** column name */
  AncapRating = 'ancap_rating',
  /** column name */
  AncapYear = 'ancap_year',
  /** column name */
  ApproachAngle = 'approach_angle',
  /** column name */
  Badge = 'badge',
  /** column name */
  BadgePart1 = 'badge_part1',
  /** column name */
  BadgePart2 = 'badge_part2',
  /** column name */
  BatteryNormalChargeTime = 'battery_normal_charge_time',
  /** column name */
  BatteryNormalChargeVoltage = 'battery_normal_charge_voltage',
  /** column name */
  BatteryQuickChargePower = 'battery_quick_charge_power',
  /** column name */
  BatteryQuickChargeTime = 'battery_quick_charge_time',
  /** column name */
  BatteryQuickChargeVoltage = 'battery_quick_charge_voltage',
  /** column name */
  BodyConfiguration = 'body_configuration',
  /** column name */
  BodyStyle = 'body_style',
  /** column name */
  BreakOverAngle = 'break_over_angle',
  /** column name */
  CamShaft = 'cam_shaft',
  /** column name */
  Co2Combined = 'co2_combined',
  /** column name */
  Co2ExtraUrban = 'co2_extra_urban',
  /** column name */
  Co2Urban = 'co2_urban',
  /** column name */
  CombinedPowerKw = 'combined_power_kw',
  /** column name */
  CombinedTorque = 'combined_torque',
  /** column name */
  CompressionRatio = 'compression_ratio',
  /** column name */
  CountryImport = 'country_import',
  /** column name */
  CountryOrigin = 'country_origin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentRelease = 'current_release',
  /** column name */
  Cylinders = 'cylinders',
  /** column name */
  DepartureAngle = 'departure_angle',
  /** column name */
  Description = 'description',
  /** column name */
  DoorNo = 'door_no',
  /** column name */
  Drive = 'drive',
  /** column name */
  DriveAway = 'drive_away',
  /** column name */
  DriveShort = 'drive_short',
  /** column name */
  ElectricEng50kwdcChargeTime = 'electric_eng50kwdc_charge_time',
  /** column name */
  ElectricEngQuickChargeCapacity = 'electric_eng_quick_charge_capacity',
  /** column name */
  ElectricEngWallboxChargeTime = 'electric_eng_wallbox_charge_time',
  /** column name */
  ElectricEngWallboxPower = 'electric_eng_wallbox_power',
  /** column name */
  ElectricEngineAmpHours = 'electric_engine_amp_hours',
  /** column name */
  ElectricEngineBatteryType = 'electric_engine_battery_type',
  /** column name */
  ElectricEngineChargingMethod = 'electric_engine_charging_method',
  /** column name */
  ElectricEngineCurrentType = 'electric_engine_current_type',
  /** column name */
  ElectricEngineDrive = 'electric_engine_drive',
  /** column name */
  ElectricEngineKmRange = 'electric_engine_km_range',
  /** column name */
  ElectricEngineLocation = 'electric_engine_location',
  /** column name */
  ElectricEnginePowerKw = 'electric_engine_power_kw',
  /** column name */
  ElectricEnginePowerRpmFrom = 'electric_engine_power_rpm_from',
  /** column name */
  ElectricEnginePowerRpmTo = 'electric_engine_power_rpm_to',
  /** column name */
  ElectricEngineTopSpeed = 'electric_engine_top_speed',
  /** column name */
  ElectricEngineTorque = 'electric_engine_torque',
  /** column name */
  ElectricEngineTorqueRpmFrom = 'electric_engine_torque_rpm_from',
  /** column name */
  ElectricEngineTorqueRpmTo = 'electric_engine_torque_rpm_to',
  /** column name */
  ElectricEngineType = 'electric_engine_type',
  /** column name */
  ElectricEngineVolts = 'electric_engine_volts',
  /** column name */
  EmissionStandard = 'emission_standard',
  /** column name */
  EnergyConsumption = 'energy_consumption',
  /** column name */
  EngineConfiguration = 'engine_configuration',
  /** column name */
  EngineCycle = 'engine_cycle',
  /** column name */
  EngineDescription = 'engine_description',
  /** column name */
  EngineDescriptionFull = 'engine_description_full',
  /** column name */
  EngineLocation = 'engine_location',
  /** column name */
  EngineSize = 'engine_size',
  /** column name */
  EngineSizeLitres = 'engine_size_litres',
  /** column name */
  EngineType = 'engine_type',
  /** column name */
  ExtraIdentification = 'extra_identification',
  /** column name */
  FamilyName = 'family_name',
  /** column name */
  FirstServiceKms = 'first_service_kms',
  /** column name */
  FirstServiceMonths = 'first_service_months',
  /** column name */
  FreeScheduledService = 'free_scheduled_service',
  /** column name */
  FrontOverhang = 'front_overhang',
  /** column name */
  FrontPassengerSeatAnchorPointLocation = 'front_passenger_seat_anchor_point_location',
  /** column name */
  FrontRimDescription = 'front_rim_description',
  /** column name */
  FrontRimDiameter = 'front_rim_diameter',
  /** column name */
  FrontRimExtraInfo = 'front_rim_extra_info',
  /** column name */
  FrontRimWidth = 'front_rim_width',
  /** column name */
  FrontTyreAspectRatio = 'front_tyre_aspect_ratio',
  /** column name */
  FrontTyreConstruction = 'front_tyre_construction',
  /** column name */
  FrontTyreDiameter = 'front_tyre_diameter',
  /** column name */
  FrontTyreExtraInfo = 'front_tyre_extra_info',
  /** column name */
  FrontTyreLoadIndex = 'front_tyre_load_index',
  /** column name */
  FrontTyreSize = 'front_tyre_size',
  /** column name */
  FrontTyreSpeedRating = 'front_tyre_speed_rating',
  /** column name */
  FrontTyreWidth = 'front_tyre_width',
  /** column name */
  FuelAvgDistance = 'fuel_avg_distance',
  /** column name */
  FuelCapacity = 'fuel_capacity',
  /** column name */
  FuelConsumptionCity = 'fuel_consumption_city',
  /** column name */
  FuelConsumptionCombined = 'fuel_consumption_combined',
  /** column name */
  FuelConsumptionHighway = 'fuel_consumption_highway',
  /** column name */
  FuelDelivery = 'fuel_delivery',
  /** column name */
  FuelMaxDistance = 'fuel_max_distance',
  /** column name */
  FuelMethodOfDelivery = 'fuel_method_of_delivery',
  /** column name */
  FuelMinDistance = 'fuel_min_distance',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  FuelTypeGeneric = 'fuel_type_generic',
  /** column name */
  GearLocation = 'gear_location',
  /** column name */
  GearNo = 'gear_no',
  /** column name */
  GearType = 'gear_type',
  /** column name */
  GenerationName = 'generation_name',
  /** column name */
  GenerationVersion = 'generation_version',
  /** column name */
  GreenHouseRating = 'green_house_rating',
  /** column name */
  GrossCombinationMass = 'gross_combination_mass',
  /** column name */
  GrossVehicleMass = 'gross_vehicle_mass',
  /** column name */
  GroundClearance = 'ground_clearance',
  /** column name */
  HvBatteryAcFastChDesc = 'hv_battery_ac_fast_ch_desc',
  /** column name */
  HvBatteryAcPowerOutletChDesc = 'hv_battery_ac_power_outlet_ch_desc',
  /** column name */
  HvBatteryCapacity = 'hv_battery_capacity',
  /** column name */
  HvBatteryChargeSocketType = 'hv_battery_charge_socket_type',
  /** column name */
  HvBatteryDcChargeSocketType = 'hv_battery_dc_charge_socket_type',
  /** column name */
  HvBatteryDcFastChDesc = 'hv_battery_dc_fast_ch_desc',
  /** column name */
  HvBatteryVoltage = 'hv_battery_voltage',
  /** column name */
  HvBatteryWarrantyKm = 'hv_battery_warranty_km',
  /** column name */
  HvBatteryWarrantyYears = 'hv_battery_warranty_years',
  /** column name */
  HvWarrantyExtraInfo = 'hv_warranty_extra_info',
  /** column name */
  Id = 'id',
  /** column name */
  Induction = 'induction',
  /** column name */
  KerbWeight = 'kerb_weight',
  /** column name */
  KmRangeManufacturer = 'km_range_manufacturer',
  /** column name */
  KmRangeWltp = 'km_range_wltp',
  /** column name */
  LctIncluded = 'lct_included',
  /** column name */
  LimitedEdition = 'limited_edition',
  /** column name */
  LocationOfStorage = 'location_of_storage',
  /** column name */
  MakeName = 'make_name',
  /** column name */
  ManufacturerPowerPlantType = 'manufacturer_power_plant_type',
  /** column name */
  MaxEthanolBlend = 'max_ethanol_blend',
  /** column name */
  MaximumSpeed = 'maximum_speed',
  /** column name */
  MaximumTowballDownload = 'maximum_towball_download',
  /** column name */
  ModelYear = 'model_year',
  /** column name */
  NewPrice = 'new_price',
  /** column name */
  NoAirbags = 'no_airbags',
  /** column name */
  NoChildSeatAnchorAgePoints = 'no_child_seat_anchor_age_points',
  /** column name */
  OverallGreenStarRating = 'overall_green_star_rating',
  /** column name */
  Payload = 'payload',
  /** column name */
  PowerKw = 'power_kw',
  /** column name */
  PowerPlantType = 'power_plant_type',
  /** column name */
  PowerRpmFrom = 'power_rpm_from',
  /** column name */
  PowerRpmTo = 'power_rpm_to',
  /** column name */
  PowerWeightRatio = 'power_weight_ratio',
  /** column name */
  RearOverhang = 'rear_overhang',
  /** column name */
  RearRimDescription = 'rear_rim_description',
  /** column name */
  RearRimDiameter = 'rear_rim_diameter',
  /** column name */
  RearRimExtraInfo = 'rear_rim_extra_info',
  /** column name */
  RearRimWidth = 'rear_rim_width',
  /** column name */
  RearTyreAspectRatio = 'rear_tyre_aspect_ratio',
  /** column name */
  RearTyreConstruction = 'rear_tyre_construction',
  /** column name */
  RearTyreDiameter = 'rear_tyre_diameter',
  /** column name */
  RearTyreExtraInfo = 'rear_tyre_extra_info',
  /** column name */
  RearTyreLoadIndex = 'rear_tyre_load_index',
  /** column name */
  RearTyreSize = 'rear_tyre_size',
  /** column name */
  RearTyreSpeedRating = 'rear_tyre_speed_rating',
  /** column name */
  RearTyreWidth = 'rear_tyre_width',
  /** column name */
  RedbookCode = 'redbook_code',
  /** column name */
  RegularServiceKms = 'regular_service_kms',
  /** column name */
  RegularServiceMonths = 'regular_service_months',
  /** column name */
  ReleaseMonth = 'release_month',
  /** column name */
  ReleaseType = 'release_type',
  /** column name */
  ReleaseYear = 'release_year',
  /** column name */
  RimMaterial = 'rim_material',
  /** column name */
  RoadSideAssistanceExtraInfo = 'road_side_assistance_extra_info',
  /** column name */
  RoadSideAssistanceKm = 'road_side_assistance_km',
  /** column name */
  RoadSideAssistanceYears = 'road_side_assistance_years',
  /** column name */
  RonRating = 'ron_rating',
  /** column name */
  Roofline = 'roofline',
  /** column name */
  SeatCapacity = 'seat_capacity',
  /** column name */
  SecondRowCentreSeatAnchorPointLocation = 'second_row_centre_seat_anchor_point_location',
  /** column name */
  SecondRowOutboardSeatAnchorPointLocation = 'second_row_outboard_seat_anchor_point_location',
  /** column name */
  Series = 'series',
  /** column name */
  Steering = 'steering',
  /** column name */
  TareMass = 'tare_mass',
  /** column name */
  ThermalManagement = 'thermal_management',
  /** column name */
  ThirdRowCentreSeatAnchorPointLocation = 'third_row_centre_seat_anchor_point_location',
  /** column name */
  ThirdRowOutboardSeatAnchorPointLocation = 'third_row_outboard_seat_anchor_point_location',
  /** column name */
  Torque = 'torque',
  /** column name */
  TorqueRpmFrom = 'torque_rpm_from',
  /** column name */
  TorqueRpmTo = 'torque_rpm_to',
  /** column name */
  TowingCapacityBraked = 'towing_capacity_braked',
  /** column name */
  TowingCapacityUnbraked = 'towing_capacity_unbraked',
  /** column name */
  TrackFront = 'track_front',
  /** column name */
  TrackRear = 'track_rear',
  /** column name */
  TurningCircleBetweenKerbs = 'turning_circle_between_kerbs',
  /** column name */
  TurningCircleBetweenWalls = 'turning_circle_between_walls',
  /** column name */
  TypeOfAnchorAge = 'type_of_anchor_age',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValvesCylinder = 'valves_cylinder',
  /** column name */
  VehicleAgeMonths = 'vehicle_age_months',
  /** column name */
  VehicleAgeYears = 'vehicle_age_years',
  /** column name */
  VehicleHeight = 'vehicle_height',
  /** column name */
  VehicleLength = 'vehicle_length',
  /** column name */
  VehicleToGrid = 'vehicle_to_grid',
  /** column name */
  VehicleToLoad = 'vehicle_to_load',
  /** column name */
  VehicleToVehicle = 'vehicle_to_vehicle',
  /** column name */
  VehicleType = 'vehicle_type',
  /** column name */
  VehicleWidth = 'vehicle_width',
  /** column name */
  VfactsClass = 'vfacts_class',
  /** column name */
  VfactsPrice = 'vfacts_price',
  /** column name */
  VfactsSegment = 'vfacts_segment',
  /** column name */
  WadingDepth = 'wading_depth',
  /** column name */
  WarrantyAntiCorrosion = 'warranty_anti_corrosion',
  /** column name */
  WarrantyCustomerAssist = 'warranty_customer_assist',
  /** column name */
  WarrantyExtraInfo = 'warranty_extra_info',
  /** column name */
  WarrantyKm = 'warranty_km',
  /** column name */
  WarrantyYears = 'warranty_years',
  /** column name */
  WheelBase = 'wheel_base',
  /** column name */
  WheelBaseConfiguration = 'wheel_base_configuration',
  /** column name */
  YearGroupMonth = 'year_group_month',
  /** column name */
  YearGroupMonthName = 'year_group_month_name',
  /** column name */
  YearGroupMonthNameShort = 'year_group_month_name_short',
  /** column name */
  YearGroupSequenceNo = 'year_group_sequence_no',
  /** column name */
  YearGroupYear = 'year_group_year'
}

/** input type for updating data in table "vehicle_specifications" */
export type Vehicle_Specifications_Set_Input = {
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  adblue_tank_capacity?: Maybe<Scalars['String']>;
  /** ADR defined parent category based on type and use of vehicle */
  adr_vehicle_category?: Maybe<Scalars['String']>;
  /** ADR sub-category based on specification */
  adr_vehicle_sub_category?: Maybe<Scalars['String']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** The Badge Eg Esseesse */
  badge?: Maybe<Scalars['String']>;
  /** The Badge description of the vehicle Eg EXECUTIVE */
  badge_part1?: Maybe<Scalars['String']>;
  /** The 2nd Badge description of the vehicle */
  badge_part2?: Maybe<Scalars['String']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The Body Configuration description of the vehicle Eg DUAL CAB */
  body_configuration?: Maybe<Scalars['String']>;
  /** The Body Style description of the vehicle Eg WAGON */
  body_style?: Maybe<Scalars['String']>;
  /** When a vehicle crests a hill, this is the angle at which the front tyres still remain on the ground as the rear wheels continue to climb. */
  break_over_angle?: Maybe<Scalars['String']>;
  /** The Cam description of the vehicle Eg Overhead Cam, Double Overhead Cam */
  cam_shaft?: Maybe<Scalars['String']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Combined Power (kW) */
  combined_power_kw?: Maybe<Scalars['String']>;
  /** Combined Torque (Nm) */
  combined_torque?: Maybe<Scalars['String']>;
  /** Compression ratio expressed to 1 */
  compression_ratio?: Maybe<Scalars['String']>;
  /** Vehicle Import Flag - I = Import, L = Local */
  country_import?: Maybe<Scalars['String']>;
  /** The Import Country of Origin description of the vehicle Eg Japan, USA */
  country_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Flag to indicate a current release. T = True, F = False */
  current_release?: Maybe<Scalars['Boolean']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the rear of the vehicle on the ground. */
  departure_angle?: Maybe<Scalars['String']>;
  /** Full Description of the Vehicle */
  description?: Maybe<Scalars['String']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** The Drive description of the vehicle Eg Four Wheel Drive, Front Wheel Drive */
  drive?: Maybe<Scalars['String']>;
  /** Flag to indicate entered NewPrice is Driveaway. T = True, F = False */
  drive_away?: Maybe<Scalars['Boolean']>;
  /** The Drive abbreviation of the vehicle Eg F */
  drive_short?: Maybe<Scalars['String']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  electric_eng_quick_charge_capacity?: Maybe<Scalars['String']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_battery_type?: Maybe<Scalars['String']>;
  electric_engine_charging_method?: Maybe<Scalars['String']>;
  electric_engine_current_type?: Maybe<Scalars['String']>;
  electric_engine_drive?: Maybe<Scalars['String']>;
  electric_engine_km_range?: Maybe<Scalars['String']>;
  electric_engine_location?: Maybe<Scalars['String']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_type?: Maybe<Scalars['String']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** Which Emission standard this vehicle complies to (eg Euro IV) */
  emission_standard?: Maybe<Scalars['String']>;
  /** The vehicle`s electricity consumption per km as measured under testing standards (in Wh/km) */
  energy_consumption?: Maybe<Scalars['String']>;
  /** The Engine Configuration description of the vehicle Eg Inline, V90 */
  engine_configuration?: Maybe<Scalars['String']>;
  /** The Engine Cycle description of the vehicle Eg 2 Stroke, 4 Stroke */
  engine_cycle?: Maybe<Scalars['String']>;
  /** The common engine reference name, blank if there is no common name Eg 3.4 */
  engine_description?: Maybe<Scalars['String']>;
  /** The common engine reference name Eg 3.4i, 2.4DT */
  engine_description_full?: Maybe<Scalars['String']>;
  /** Front or Rear */
  engine_location?: Maybe<Scalars['String']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** The Engine Type description of the vehicle Eg Piston, Rotary */
  engine_type?: Maybe<Scalars['String']>;
  /** Extra Identification information for vehicle eg LWB, SWB */
  extra_identification?: Maybe<Scalars['String']>;
  /** The family name in full Eg COROLLA */
  family_name?: Maybe<Scalars['String']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  /** Interval (months or kilometres) for a free first service */
  free_scheduled_service?: Maybe<Scalars['String']>;
  /** The distance between the center line of the front axle and the front edge of the vehicle (mm) */
  front_overhang?: Maybe<Scalars['String']>;
  front_passenger_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Description of the front rim */
  front_rim_description?: Maybe<Scalars['String']>;
  front_rim_diameter?: Maybe<Scalars['String']>;
  front_rim_extra_info?: Maybe<Scalars['String']>;
  front_rim_width?: Maybe<Scalars['String']>;
  front_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  front_tyre_construction?: Maybe<Scalars['String']>;
  front_tyre_diameter?: Maybe<Scalars['String']>;
  front_tyre_extra_info?: Maybe<Scalars['String']>;
  front_tyre_load_index?: Maybe<Scalars['String']>;
  front_tyre_size?: Maybe<Scalars['String']>;
  front_tyre_speed_rating?: Maybe<Scalars['String']>;
  front_tyre_width?: Maybe<Scalars['String']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  /** The Fuel Delivery description of the vehicle Eg Carburettor Single, Multi-Point Injected */
  fuel_delivery?: Maybe<Scalars['String']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  /** The Method of Delivery description of the vehicle Eg Electronic, Mechanical */
  fuel_method_of_delivery?: Maybe<Scalars['String']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel Type description of the vehicle Eg Petrol - Unleaded (ULP) */
  fuel_type?: Maybe<Scalars['String']>;
  /** Eg Petrol */
  fuel_type_generic?: Maybe<Scalars['String']>;
  /** The Gear Location description of the vehicle Eg Floor, Column */
  gear_location?: Maybe<Scalars['String']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  /** The Gear Type description of the vehicle, Eg Automatic, Manual */
  gear_type?: Maybe<Scalars['String']>;
  generation_name?: Maybe<Scalars['String']>;
  generation_version?: Maybe<Scalars['String']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The total of the gross weight and braked towing capacity (kg) */
  gross_combination_mass?: Maybe<Scalars['String']>;
  /** The maximum laden mass of a motor vehicle as specified by the Manufacturer (kg) */
  gross_vehicle_mass?: Maybe<Scalars['String']>;
  ground_clearance?: Maybe<Scalars['String']>;
  /** The specification of the AC fast charge system for AC Fast Charge Time */
  hv_battery_ac_fast_ch_desc?: Maybe<Scalars['String']>;
  /** The specifcation of the socket type for Normal Charge Time */
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Scalars['String']>;
  /** The maximum capacity of the High Voltage Battery in kWh */
  hv_battery_capacity?: Maybe<Scalars['String']>;
  /** Standard plug type for the main HV battery charge port */
  hv_battery_charge_socket_type?: Maybe<Scalars['String']>;
  /** The socket type on the vehicle suitable for DC charging */
  hv_battery_dc_charge_socket_type?: Maybe<Scalars['String']>;
  /** The specification of the DC fast charge system for DC Fast Charge Time */
  hv_battery_dc_fast_ch_desc?: Maybe<Scalars['String']>;
  /** Maximum output Voltage of the High Voltage Battery */
  hv_battery_voltage?: Maybe<Scalars['String']>;
  /** The number of km the High Voltage Battery warranty covers (-1 = Unlimited) */
  hv_battery_warranty_km?: Maybe<Scalars['String']>;
  /** The number of years for which the High Voltage Battery is warranted against degradation to a given capacity */
  hv_battery_warranty_years?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  hv_warranty_extra_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The Induction description of the vehicle Eg Aspirated, Turbo */
  induction?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** Pure electric driving range in km (As claimed by the vehicle manufacturer) */
  km_range_manufacturer?: Maybe<Scalars['String']>;
  /** Pure electric driving range in km (WLTP standard) */
  km_range_wltp?: Maybe<Scalars['String']>;
  lct_included?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate a limited edition vehicle. T = True, F = False */
  limited_edition?: Maybe<Scalars['Boolean']>;
  /** Location of Storage */
  location_of_storage?: Maybe<Scalars['String']>;
  /** The Make Name of the Vehicle */
  make_name?: Maybe<Scalars['String']>;
  /** The OEM description of the vehicle`s motive power system */
  manufacturer_power_plant_type?: Maybe<Scalars['String']>;
  /** The Maximum Ethanol Blend */
  max_ethanol_blend?: Maybe<Scalars['String']>;
  /** Highest speed capable of travelling at (km/h) */
  maximum_speed?: Maybe<Scalars['String']>;
  maximum_towball_download?: Maybe<Scalars['String']>;
  /** The Model Year Code from the Manufacturer eg MY2009 */
  model_year?: Maybe<Scalars['String']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  no_child_seat_anchor_age_points?: Maybe<Scalars['String']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** Applies to goods carrying light commercials (kg) */
  payload?: Maybe<Scalars['String']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The motive power type description of the vehicle Eg Internal Combustion, Battery Electric */
  power_plant_type?: Maybe<Scalars['String']>;
  /** The start RPM when peak Power is achieved */
  power_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** The distance between the center line of the rear axle and the rear end of the vehicle (mm) */
  rear_overhang?: Maybe<Scalars['String']>;
  /** Description of the rear rim */
  rear_rim_description?: Maybe<Scalars['String']>;
  rear_rim_diameter?: Maybe<Scalars['String']>;
  rear_rim_extra_info?: Maybe<Scalars['String']>;
  rear_rim_width?: Maybe<Scalars['String']>;
  rear_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  rear_tyre_construction?: Maybe<Scalars['String']>;
  rear_tyre_diameter?: Maybe<Scalars['String']>;
  rear_tyre_extra_info?: Maybe<Scalars['String']>;
  rear_tyre_load_index?: Maybe<Scalars['String']>;
  rear_tyre_size?: Maybe<Scalars['String']>;
  rear_tyre_speed_rating?: Maybe<Scalars['String']>;
  rear_tyre_width?: Maybe<Scalars['String']>;
  /** The Red Book Code that uniquely identifies the vehicle eg AUVTOYO1999AADN */
  redbook_code?: Maybe<Scalars['String']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Type of the vehicle */
  release_type?: Maybe<Scalars['String']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** Alloy or Steel */
  rim_material?: Maybe<Scalars['String']>;
  /** Conditions covered by the roadside assistance program */
  road_side_assistance_extra_info?: Maybe<Scalars['String']>;
  /** The number of km for which the roadside assistance program is valid */
  road_side_assistance_km?: Maybe<Scalars['String']>;
  /** The number of years for which the vehicle is eligible for the factory roadside assistance program */
  road_side_assistance_years?: Maybe<Scalars['String']>;
  /** The recommended fuel RON rating */
  ron_rating?: Maybe<Scalars['String']>;
  /** Description of roof style, primarily for wagons and vans, ie: low roof, high roof */
  roofline?: Maybe<Scalars['String']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  second_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Series code (if any) eg VE */
  series?: Maybe<Scalars['String']>;
  /** The Steering description of the vehicle Eg 4 Wheel Steering, Rack and Pinion */
  steering?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** Does the vehicle have Thermal Management */
  thermal_management?: Maybe<Scalars['String']>;
  third_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The start RPM for maximum Torque */
  torque_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the wheel) */
  turning_circle_between_kerbs?: Maybe<Scalars['String']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the front overhang) */
  turning_circle_between_walls?: Maybe<Scalars['String']>;
  type_of_anchor_age?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Does the Vehicle have Vehicle to Grid (V2G) Power Transfer Ability */
  vehicle_to_grid?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Load (V2L) Power Transfer Ability */
  vehicle_to_load?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Vehicle (V2V) Power Transfer Ability */
  vehicle_to_vehicle?: Maybe<Scalars['String']>;
  /** Type of vehicle Eg Passenger, Light, Heavy Commercial */
  vehicle_type?: Maybe<Scalars['String']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The VFacts Class */
  vfacts_class?: Maybe<Scalars['String']>;
  /** The VFacts Pricing Segment */
  vfacts_price?: Maybe<Scalars['String']>;
  /** The VFacts Segment */
  vfacts_segment?: Maybe<Scalars['String']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years for which the vehicle body is warranted against perforation from corrosion */
  warranty_anti_corrosion?: Maybe<Scalars['String']>;
  /** For example roadside assist */
  warranty_customer_assist?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  warranty_extra_info?: Maybe<Scalars['String']>;
  /** The number of km the warranty covers (-1 = Unlimited) */
  warranty_km?: Maybe<Scalars['String']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Wheel Base configuration of the vehicle, ie: LWB, SWB etc */
  wheel_base_configuration?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg March */
  year_group_month_name?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg Mar */
  year_group_month_name_short?: Maybe<Scalars['String']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Vehicle_Specifications_Stddev_Fields = {
  __typename?: 'vehicle_specifications_stddev_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Specifications_Stddev_Pop_Fields = {
  __typename?: 'vehicle_specifications_stddev_pop_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Specifications_Stddev_Samp_Fields = {
  __typename?: 'vehicle_specifications_stddev_samp_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "vehicle_specifications" */
export type Vehicle_Specifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Specifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Specifications_Stream_Cursor_Value_Input = {
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  adblue_tank_capacity?: Maybe<Scalars['String']>;
  /** ADR defined parent category based on type and use of vehicle */
  adr_vehicle_category?: Maybe<Scalars['String']>;
  /** ADR sub-category based on specification */
  adr_vehicle_sub_category?: Maybe<Scalars['String']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** The Badge Eg Esseesse */
  badge?: Maybe<Scalars['String']>;
  /** The Badge description of the vehicle Eg EXECUTIVE */
  badge_part1?: Maybe<Scalars['String']>;
  /** The 2nd Badge description of the vehicle */
  badge_part2?: Maybe<Scalars['String']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The Body Configuration description of the vehicle Eg DUAL CAB */
  body_configuration?: Maybe<Scalars['String']>;
  /** The Body Style description of the vehicle Eg WAGON */
  body_style?: Maybe<Scalars['String']>;
  /** When a vehicle crests a hill, this is the angle at which the front tyres still remain on the ground as the rear wheels continue to climb. */
  break_over_angle?: Maybe<Scalars['String']>;
  /** The Cam description of the vehicle Eg Overhead Cam, Double Overhead Cam */
  cam_shaft?: Maybe<Scalars['String']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Combined Power (kW) */
  combined_power_kw?: Maybe<Scalars['String']>;
  /** Combined Torque (Nm) */
  combined_torque?: Maybe<Scalars['String']>;
  /** Compression ratio expressed to 1 */
  compression_ratio?: Maybe<Scalars['String']>;
  /** Vehicle Import Flag - I = Import, L = Local */
  country_import?: Maybe<Scalars['String']>;
  /** The Import Country of Origin description of the vehicle Eg Japan, USA */
  country_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Flag to indicate a current release. T = True, F = False */
  current_release?: Maybe<Scalars['Boolean']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the rear of the vehicle on the ground. */
  departure_angle?: Maybe<Scalars['String']>;
  /** Full Description of the Vehicle */
  description?: Maybe<Scalars['String']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** The Drive description of the vehicle Eg Four Wheel Drive, Front Wheel Drive */
  drive?: Maybe<Scalars['String']>;
  /** Flag to indicate entered NewPrice is Driveaway. T = True, F = False */
  drive_away?: Maybe<Scalars['Boolean']>;
  /** The Drive abbreviation of the vehicle Eg F */
  drive_short?: Maybe<Scalars['String']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  electric_eng_quick_charge_capacity?: Maybe<Scalars['String']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_battery_type?: Maybe<Scalars['String']>;
  electric_engine_charging_method?: Maybe<Scalars['String']>;
  electric_engine_current_type?: Maybe<Scalars['String']>;
  electric_engine_drive?: Maybe<Scalars['String']>;
  electric_engine_km_range?: Maybe<Scalars['String']>;
  electric_engine_location?: Maybe<Scalars['String']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_type?: Maybe<Scalars['String']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** Which Emission standard this vehicle complies to (eg Euro IV) */
  emission_standard?: Maybe<Scalars['String']>;
  /** The vehicle`s electricity consumption per km as measured under testing standards (in Wh/km) */
  energy_consumption?: Maybe<Scalars['String']>;
  /** The Engine Configuration description of the vehicle Eg Inline, V90 */
  engine_configuration?: Maybe<Scalars['String']>;
  /** The Engine Cycle description of the vehicle Eg 2 Stroke, 4 Stroke */
  engine_cycle?: Maybe<Scalars['String']>;
  /** The common engine reference name, blank if there is no common name Eg 3.4 */
  engine_description?: Maybe<Scalars['String']>;
  /** The common engine reference name Eg 3.4i, 2.4DT */
  engine_description_full?: Maybe<Scalars['String']>;
  /** Front or Rear */
  engine_location?: Maybe<Scalars['String']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** The Engine Type description of the vehicle Eg Piston, Rotary */
  engine_type?: Maybe<Scalars['String']>;
  /** Extra Identification information for vehicle eg LWB, SWB */
  extra_identification?: Maybe<Scalars['String']>;
  /** The family name in full Eg COROLLA */
  family_name?: Maybe<Scalars['String']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  /** Interval (months or kilometres) for a free first service */
  free_scheduled_service?: Maybe<Scalars['String']>;
  /** The distance between the center line of the front axle and the front edge of the vehicle (mm) */
  front_overhang?: Maybe<Scalars['String']>;
  front_passenger_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Description of the front rim */
  front_rim_description?: Maybe<Scalars['String']>;
  front_rim_diameter?: Maybe<Scalars['String']>;
  front_rim_extra_info?: Maybe<Scalars['String']>;
  front_rim_width?: Maybe<Scalars['String']>;
  front_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  front_tyre_construction?: Maybe<Scalars['String']>;
  front_tyre_diameter?: Maybe<Scalars['String']>;
  front_tyre_extra_info?: Maybe<Scalars['String']>;
  front_tyre_load_index?: Maybe<Scalars['String']>;
  front_tyre_size?: Maybe<Scalars['String']>;
  front_tyre_speed_rating?: Maybe<Scalars['String']>;
  front_tyre_width?: Maybe<Scalars['String']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  /** The Fuel Delivery description of the vehicle Eg Carburettor Single, Multi-Point Injected */
  fuel_delivery?: Maybe<Scalars['String']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  /** The Method of Delivery description of the vehicle Eg Electronic, Mechanical */
  fuel_method_of_delivery?: Maybe<Scalars['String']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel Type description of the vehicle Eg Petrol - Unleaded (ULP) */
  fuel_type?: Maybe<Scalars['String']>;
  /** Eg Petrol */
  fuel_type_generic?: Maybe<Scalars['String']>;
  /** The Gear Location description of the vehicle Eg Floor, Column */
  gear_location?: Maybe<Scalars['String']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  /** The Gear Type description of the vehicle, Eg Automatic, Manual */
  gear_type?: Maybe<Scalars['String']>;
  generation_name?: Maybe<Scalars['String']>;
  generation_version?: Maybe<Scalars['String']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The total of the gross weight and braked towing capacity (kg) */
  gross_combination_mass?: Maybe<Scalars['String']>;
  /** The maximum laden mass of a motor vehicle as specified by the Manufacturer (kg) */
  gross_vehicle_mass?: Maybe<Scalars['String']>;
  ground_clearance?: Maybe<Scalars['String']>;
  /** The specification of the AC fast charge system for AC Fast Charge Time */
  hv_battery_ac_fast_ch_desc?: Maybe<Scalars['String']>;
  /** The specifcation of the socket type for Normal Charge Time */
  hv_battery_ac_power_outlet_ch_desc?: Maybe<Scalars['String']>;
  /** The maximum capacity of the High Voltage Battery in kWh */
  hv_battery_capacity?: Maybe<Scalars['String']>;
  /** Standard plug type for the main HV battery charge port */
  hv_battery_charge_socket_type?: Maybe<Scalars['String']>;
  /** The socket type on the vehicle suitable for DC charging */
  hv_battery_dc_charge_socket_type?: Maybe<Scalars['String']>;
  /** The specification of the DC fast charge system for DC Fast Charge Time */
  hv_battery_dc_fast_ch_desc?: Maybe<Scalars['String']>;
  /** Maximum output Voltage of the High Voltage Battery */
  hv_battery_voltage?: Maybe<Scalars['String']>;
  /** The number of km the High Voltage Battery warranty covers (-1 = Unlimited) */
  hv_battery_warranty_km?: Maybe<Scalars['String']>;
  /** The number of years for which the High Voltage Battery is warranted against degradation to a given capacity */
  hv_battery_warranty_years?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  hv_warranty_extra_info?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** The Induction description of the vehicle Eg Aspirated, Turbo */
  induction?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** Pure electric driving range in km (As claimed by the vehicle manufacturer) */
  km_range_manufacturer?: Maybe<Scalars['String']>;
  /** Pure electric driving range in km (WLTP standard) */
  km_range_wltp?: Maybe<Scalars['String']>;
  lct_included?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate a limited edition vehicle. T = True, F = False */
  limited_edition?: Maybe<Scalars['Boolean']>;
  /** Location of Storage */
  location_of_storage?: Maybe<Scalars['String']>;
  /** The Make Name of the Vehicle */
  make_name?: Maybe<Scalars['String']>;
  /** The OEM description of the vehicle`s motive power system */
  manufacturer_power_plant_type?: Maybe<Scalars['String']>;
  /** The Maximum Ethanol Blend */
  max_ethanol_blend?: Maybe<Scalars['String']>;
  /** Highest speed capable of travelling at (km/h) */
  maximum_speed?: Maybe<Scalars['String']>;
  maximum_towball_download?: Maybe<Scalars['String']>;
  /** The Model Year Code from the Manufacturer eg MY2009 */
  model_year?: Maybe<Scalars['String']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  no_child_seat_anchor_age_points?: Maybe<Scalars['String']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** Applies to goods carrying light commercials (kg) */
  payload?: Maybe<Scalars['String']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The motive power type description of the vehicle Eg Internal Combustion, Battery Electric */
  power_plant_type?: Maybe<Scalars['String']>;
  /** The start RPM when peak Power is achieved */
  power_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** The distance between the center line of the rear axle and the rear end of the vehicle (mm) */
  rear_overhang?: Maybe<Scalars['String']>;
  /** Description of the rear rim */
  rear_rim_description?: Maybe<Scalars['String']>;
  rear_rim_diameter?: Maybe<Scalars['String']>;
  rear_rim_extra_info?: Maybe<Scalars['String']>;
  rear_rim_width?: Maybe<Scalars['String']>;
  rear_tyre_aspect_ratio?: Maybe<Scalars['String']>;
  rear_tyre_construction?: Maybe<Scalars['String']>;
  rear_tyre_diameter?: Maybe<Scalars['String']>;
  rear_tyre_extra_info?: Maybe<Scalars['String']>;
  rear_tyre_load_index?: Maybe<Scalars['String']>;
  rear_tyre_size?: Maybe<Scalars['String']>;
  rear_tyre_speed_rating?: Maybe<Scalars['String']>;
  rear_tyre_width?: Maybe<Scalars['String']>;
  /** The Red Book Code that uniquely identifies the vehicle eg AUVTOYO1999AADN */
  redbook_code?: Maybe<Scalars['String']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Type of the vehicle */
  release_type?: Maybe<Scalars['String']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** Alloy or Steel */
  rim_material?: Maybe<Scalars['String']>;
  /** Conditions covered by the roadside assistance program */
  road_side_assistance_extra_info?: Maybe<Scalars['String']>;
  /** The number of km for which the roadside assistance program is valid */
  road_side_assistance_km?: Maybe<Scalars['String']>;
  /** The number of years for which the vehicle is eligible for the factory roadside assistance program */
  road_side_assistance_years?: Maybe<Scalars['String']>;
  /** The recommended fuel RON rating */
  ron_rating?: Maybe<Scalars['String']>;
  /** Description of roof style, primarily for wagons and vans, ie: low roof, high roof */
  roofline?: Maybe<Scalars['String']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  second_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  second_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** Series code (if any) eg VE */
  series?: Maybe<Scalars['String']>;
  /** The Steering description of the vehicle Eg 4 Wheel Steering, Rack and Pinion */
  steering?: Maybe<Scalars['String']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** Does the vehicle have Thermal Management */
  thermal_management?: Maybe<Scalars['String']>;
  third_row_centre_seat_anchor_point_location?: Maybe<Scalars['String']>;
  third_row_outboard_seat_anchor_point_location?: Maybe<Scalars['String']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The start RPM for maximum Torque */
  torque_rpm_from?: Maybe<Scalars['String']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the wheel) */
  turning_circle_between_kerbs?: Maybe<Scalars['String']>;
  /** The minimum diameter of available space to make a circular turn in meters (measured at the front overhang) */
  turning_circle_between_walls?: Maybe<Scalars['String']>;
  type_of_anchor_age?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Does the Vehicle have Vehicle to Grid (V2G) Power Transfer Ability */
  vehicle_to_grid?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Load (V2L) Power Transfer Ability */
  vehicle_to_load?: Maybe<Scalars['String']>;
  /** Does the Vehicle have Vehicle to Vehicle (V2V) Power Transfer Ability */
  vehicle_to_vehicle?: Maybe<Scalars['String']>;
  /** Type of vehicle Eg Passenger, Light, Heavy Commercial */
  vehicle_type?: Maybe<Scalars['String']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The VFacts Class */
  vfacts_class?: Maybe<Scalars['String']>;
  /** The VFacts Pricing Segment */
  vfacts_price?: Maybe<Scalars['String']>;
  /** The VFacts Segment */
  vfacts_segment?: Maybe<Scalars['String']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years for which the vehicle body is warranted against perforation from corrosion */
  warranty_anti_corrosion?: Maybe<Scalars['String']>;
  /** For example roadside assist */
  warranty_customer_assist?: Maybe<Scalars['String']>;
  /** Conditions covered by the vehicle warranty */
  warranty_extra_info?: Maybe<Scalars['String']>;
  /** The number of km the warranty covers (-1 = Unlimited) */
  warranty_km?: Maybe<Scalars['String']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Wheel Base configuration of the vehicle, ie: LWB, SWB etc */
  wheel_base_configuration?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg March */
  year_group_month_name?: Maybe<Scalars['String']>;
  /** The Month Group of the vehicle Eg Mar */
  year_group_month_name_short?: Maybe<Scalars['String']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Vehicle_Specifications_Sum_Fields = {
  __typename?: 'vehicle_specifications_sum_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['numeric']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['numeric']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['numeric']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['numeric']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['numeric']>;
  battery_quick_charge_power?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['numeric']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['numeric']>;
  co2_extra_urban?: Maybe<Scalars['numeric']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['numeric']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['numeric']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['numeric']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['numeric']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['numeric']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['numeric']>;
  electric_engine_amp_hours?: Maybe<Scalars['numeric']>;
  electric_engine_power_kw?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_top_speed?: Maybe<Scalars['numeric']>;
  electric_engine_torque?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['numeric']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['numeric']>;
  electric_engine_volts?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['numeric']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['numeric']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['numeric']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['numeric']>;
  fuel_avg_distance?: Maybe<Scalars['numeric']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['numeric']>;
  fuel_consumption_city?: Maybe<Scalars['numeric']>;
  fuel_consumption_combined?: Maybe<Scalars['numeric']>;
  fuel_consumption_highway?: Maybe<Scalars['numeric']>;
  fuel_max_distance?: Maybe<Scalars['numeric']>;
  fuel_min_distance?: Maybe<Scalars['numeric']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['numeric']>;
  green_house_rating?: Maybe<Scalars['numeric']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['numeric']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['numeric']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['numeric']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['numeric']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['numeric']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['numeric']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['numeric']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['numeric']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['numeric']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['numeric']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['numeric']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['numeric']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['numeric']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['numeric']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['numeric']>;
  towing_capacity_braked?: Maybe<Scalars['numeric']>;
  towing_capacity_unbraked?: Maybe<Scalars['numeric']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['numeric']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['numeric']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['numeric']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['numeric']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['numeric']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['numeric']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['numeric']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['numeric']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['numeric']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['numeric']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['numeric']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['numeric']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['numeric']>;
};

/** update columns of table "vehicle_specifications" */
export enum Vehicle_Specifications_Update_Column {
  /** column name */
  Acceleration = 'acceleration',
  /** column name */
  AdblueTankCapacity = 'adblue_tank_capacity',
  /** column name */
  AdrVehicleCategory = 'adr_vehicle_category',
  /** column name */
  AdrVehicleSubCategory = 'adr_vehicle_sub_category',
  /** column name */
  AirPollutionRating = 'air_pollution_rating',
  /** column name */
  AncapRating = 'ancap_rating',
  /** column name */
  AncapYear = 'ancap_year',
  /** column name */
  ApproachAngle = 'approach_angle',
  /** column name */
  Badge = 'badge',
  /** column name */
  BadgePart1 = 'badge_part1',
  /** column name */
  BadgePart2 = 'badge_part2',
  /** column name */
  BatteryNormalChargeTime = 'battery_normal_charge_time',
  /** column name */
  BatteryNormalChargeVoltage = 'battery_normal_charge_voltage',
  /** column name */
  BatteryQuickChargePower = 'battery_quick_charge_power',
  /** column name */
  BatteryQuickChargeTime = 'battery_quick_charge_time',
  /** column name */
  BatteryQuickChargeVoltage = 'battery_quick_charge_voltage',
  /** column name */
  BodyConfiguration = 'body_configuration',
  /** column name */
  BodyStyle = 'body_style',
  /** column name */
  BreakOverAngle = 'break_over_angle',
  /** column name */
  CamShaft = 'cam_shaft',
  /** column name */
  Co2Combined = 'co2_combined',
  /** column name */
  Co2ExtraUrban = 'co2_extra_urban',
  /** column name */
  Co2Urban = 'co2_urban',
  /** column name */
  CombinedPowerKw = 'combined_power_kw',
  /** column name */
  CombinedTorque = 'combined_torque',
  /** column name */
  CompressionRatio = 'compression_ratio',
  /** column name */
  CountryImport = 'country_import',
  /** column name */
  CountryOrigin = 'country_origin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentRelease = 'current_release',
  /** column name */
  Cylinders = 'cylinders',
  /** column name */
  DepartureAngle = 'departure_angle',
  /** column name */
  Description = 'description',
  /** column name */
  DoorNo = 'door_no',
  /** column name */
  Drive = 'drive',
  /** column name */
  DriveAway = 'drive_away',
  /** column name */
  DriveShort = 'drive_short',
  /** column name */
  ElectricEng50kwdcChargeTime = 'electric_eng50kwdc_charge_time',
  /** column name */
  ElectricEngQuickChargeCapacity = 'electric_eng_quick_charge_capacity',
  /** column name */
  ElectricEngWallboxChargeTime = 'electric_eng_wallbox_charge_time',
  /** column name */
  ElectricEngWallboxPower = 'electric_eng_wallbox_power',
  /** column name */
  ElectricEngineAmpHours = 'electric_engine_amp_hours',
  /** column name */
  ElectricEngineBatteryType = 'electric_engine_battery_type',
  /** column name */
  ElectricEngineChargingMethod = 'electric_engine_charging_method',
  /** column name */
  ElectricEngineCurrentType = 'electric_engine_current_type',
  /** column name */
  ElectricEngineDrive = 'electric_engine_drive',
  /** column name */
  ElectricEngineKmRange = 'electric_engine_km_range',
  /** column name */
  ElectricEngineLocation = 'electric_engine_location',
  /** column name */
  ElectricEnginePowerKw = 'electric_engine_power_kw',
  /** column name */
  ElectricEnginePowerRpmFrom = 'electric_engine_power_rpm_from',
  /** column name */
  ElectricEnginePowerRpmTo = 'electric_engine_power_rpm_to',
  /** column name */
  ElectricEngineTopSpeed = 'electric_engine_top_speed',
  /** column name */
  ElectricEngineTorque = 'electric_engine_torque',
  /** column name */
  ElectricEngineTorqueRpmFrom = 'electric_engine_torque_rpm_from',
  /** column name */
  ElectricEngineTorqueRpmTo = 'electric_engine_torque_rpm_to',
  /** column name */
  ElectricEngineType = 'electric_engine_type',
  /** column name */
  ElectricEngineVolts = 'electric_engine_volts',
  /** column name */
  EmissionStandard = 'emission_standard',
  /** column name */
  EnergyConsumption = 'energy_consumption',
  /** column name */
  EngineConfiguration = 'engine_configuration',
  /** column name */
  EngineCycle = 'engine_cycle',
  /** column name */
  EngineDescription = 'engine_description',
  /** column name */
  EngineDescriptionFull = 'engine_description_full',
  /** column name */
  EngineLocation = 'engine_location',
  /** column name */
  EngineSize = 'engine_size',
  /** column name */
  EngineSizeLitres = 'engine_size_litres',
  /** column name */
  EngineType = 'engine_type',
  /** column name */
  ExtraIdentification = 'extra_identification',
  /** column name */
  FamilyName = 'family_name',
  /** column name */
  FirstServiceKms = 'first_service_kms',
  /** column name */
  FirstServiceMonths = 'first_service_months',
  /** column name */
  FreeScheduledService = 'free_scheduled_service',
  /** column name */
  FrontOverhang = 'front_overhang',
  /** column name */
  FrontPassengerSeatAnchorPointLocation = 'front_passenger_seat_anchor_point_location',
  /** column name */
  FrontRimDescription = 'front_rim_description',
  /** column name */
  FrontRimDiameter = 'front_rim_diameter',
  /** column name */
  FrontRimExtraInfo = 'front_rim_extra_info',
  /** column name */
  FrontRimWidth = 'front_rim_width',
  /** column name */
  FrontTyreAspectRatio = 'front_tyre_aspect_ratio',
  /** column name */
  FrontTyreConstruction = 'front_tyre_construction',
  /** column name */
  FrontTyreDiameter = 'front_tyre_diameter',
  /** column name */
  FrontTyreExtraInfo = 'front_tyre_extra_info',
  /** column name */
  FrontTyreLoadIndex = 'front_tyre_load_index',
  /** column name */
  FrontTyreSize = 'front_tyre_size',
  /** column name */
  FrontTyreSpeedRating = 'front_tyre_speed_rating',
  /** column name */
  FrontTyreWidth = 'front_tyre_width',
  /** column name */
  FuelAvgDistance = 'fuel_avg_distance',
  /** column name */
  FuelCapacity = 'fuel_capacity',
  /** column name */
  FuelConsumptionCity = 'fuel_consumption_city',
  /** column name */
  FuelConsumptionCombined = 'fuel_consumption_combined',
  /** column name */
  FuelConsumptionHighway = 'fuel_consumption_highway',
  /** column name */
  FuelDelivery = 'fuel_delivery',
  /** column name */
  FuelMaxDistance = 'fuel_max_distance',
  /** column name */
  FuelMethodOfDelivery = 'fuel_method_of_delivery',
  /** column name */
  FuelMinDistance = 'fuel_min_distance',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  FuelTypeGeneric = 'fuel_type_generic',
  /** column name */
  GearLocation = 'gear_location',
  /** column name */
  GearNo = 'gear_no',
  /** column name */
  GearType = 'gear_type',
  /** column name */
  GenerationName = 'generation_name',
  /** column name */
  GenerationVersion = 'generation_version',
  /** column name */
  GreenHouseRating = 'green_house_rating',
  /** column name */
  GrossCombinationMass = 'gross_combination_mass',
  /** column name */
  GrossVehicleMass = 'gross_vehicle_mass',
  /** column name */
  GroundClearance = 'ground_clearance',
  /** column name */
  HvBatteryAcFastChDesc = 'hv_battery_ac_fast_ch_desc',
  /** column name */
  HvBatteryAcPowerOutletChDesc = 'hv_battery_ac_power_outlet_ch_desc',
  /** column name */
  HvBatteryCapacity = 'hv_battery_capacity',
  /** column name */
  HvBatteryChargeSocketType = 'hv_battery_charge_socket_type',
  /** column name */
  HvBatteryDcChargeSocketType = 'hv_battery_dc_charge_socket_type',
  /** column name */
  HvBatteryDcFastChDesc = 'hv_battery_dc_fast_ch_desc',
  /** column name */
  HvBatteryVoltage = 'hv_battery_voltage',
  /** column name */
  HvBatteryWarrantyKm = 'hv_battery_warranty_km',
  /** column name */
  HvBatteryWarrantyYears = 'hv_battery_warranty_years',
  /** column name */
  HvWarrantyExtraInfo = 'hv_warranty_extra_info',
  /** column name */
  Id = 'id',
  /** column name */
  Induction = 'induction',
  /** column name */
  KerbWeight = 'kerb_weight',
  /** column name */
  KmRangeManufacturer = 'km_range_manufacturer',
  /** column name */
  KmRangeWltp = 'km_range_wltp',
  /** column name */
  LctIncluded = 'lct_included',
  /** column name */
  LimitedEdition = 'limited_edition',
  /** column name */
  LocationOfStorage = 'location_of_storage',
  /** column name */
  MakeName = 'make_name',
  /** column name */
  ManufacturerPowerPlantType = 'manufacturer_power_plant_type',
  /** column name */
  MaxEthanolBlend = 'max_ethanol_blend',
  /** column name */
  MaximumSpeed = 'maximum_speed',
  /** column name */
  MaximumTowballDownload = 'maximum_towball_download',
  /** column name */
  ModelYear = 'model_year',
  /** column name */
  NewPrice = 'new_price',
  /** column name */
  NoAirbags = 'no_airbags',
  /** column name */
  NoChildSeatAnchorAgePoints = 'no_child_seat_anchor_age_points',
  /** column name */
  OverallGreenStarRating = 'overall_green_star_rating',
  /** column name */
  Payload = 'payload',
  /** column name */
  PowerKw = 'power_kw',
  /** column name */
  PowerPlantType = 'power_plant_type',
  /** column name */
  PowerRpmFrom = 'power_rpm_from',
  /** column name */
  PowerRpmTo = 'power_rpm_to',
  /** column name */
  PowerWeightRatio = 'power_weight_ratio',
  /** column name */
  RearOverhang = 'rear_overhang',
  /** column name */
  RearRimDescription = 'rear_rim_description',
  /** column name */
  RearRimDiameter = 'rear_rim_diameter',
  /** column name */
  RearRimExtraInfo = 'rear_rim_extra_info',
  /** column name */
  RearRimWidth = 'rear_rim_width',
  /** column name */
  RearTyreAspectRatio = 'rear_tyre_aspect_ratio',
  /** column name */
  RearTyreConstruction = 'rear_tyre_construction',
  /** column name */
  RearTyreDiameter = 'rear_tyre_diameter',
  /** column name */
  RearTyreExtraInfo = 'rear_tyre_extra_info',
  /** column name */
  RearTyreLoadIndex = 'rear_tyre_load_index',
  /** column name */
  RearTyreSize = 'rear_tyre_size',
  /** column name */
  RearTyreSpeedRating = 'rear_tyre_speed_rating',
  /** column name */
  RearTyreWidth = 'rear_tyre_width',
  /** column name */
  RedbookCode = 'redbook_code',
  /** column name */
  RegularServiceKms = 'regular_service_kms',
  /** column name */
  RegularServiceMonths = 'regular_service_months',
  /** column name */
  ReleaseMonth = 'release_month',
  /** column name */
  ReleaseType = 'release_type',
  /** column name */
  ReleaseYear = 'release_year',
  /** column name */
  RimMaterial = 'rim_material',
  /** column name */
  RoadSideAssistanceExtraInfo = 'road_side_assistance_extra_info',
  /** column name */
  RoadSideAssistanceKm = 'road_side_assistance_km',
  /** column name */
  RoadSideAssistanceYears = 'road_side_assistance_years',
  /** column name */
  RonRating = 'ron_rating',
  /** column name */
  Roofline = 'roofline',
  /** column name */
  SeatCapacity = 'seat_capacity',
  /** column name */
  SecondRowCentreSeatAnchorPointLocation = 'second_row_centre_seat_anchor_point_location',
  /** column name */
  SecondRowOutboardSeatAnchorPointLocation = 'second_row_outboard_seat_anchor_point_location',
  /** column name */
  Series = 'series',
  /** column name */
  Steering = 'steering',
  /** column name */
  TareMass = 'tare_mass',
  /** column name */
  ThermalManagement = 'thermal_management',
  /** column name */
  ThirdRowCentreSeatAnchorPointLocation = 'third_row_centre_seat_anchor_point_location',
  /** column name */
  ThirdRowOutboardSeatAnchorPointLocation = 'third_row_outboard_seat_anchor_point_location',
  /** column name */
  Torque = 'torque',
  /** column name */
  TorqueRpmFrom = 'torque_rpm_from',
  /** column name */
  TorqueRpmTo = 'torque_rpm_to',
  /** column name */
  TowingCapacityBraked = 'towing_capacity_braked',
  /** column name */
  TowingCapacityUnbraked = 'towing_capacity_unbraked',
  /** column name */
  TrackFront = 'track_front',
  /** column name */
  TrackRear = 'track_rear',
  /** column name */
  TurningCircleBetweenKerbs = 'turning_circle_between_kerbs',
  /** column name */
  TurningCircleBetweenWalls = 'turning_circle_between_walls',
  /** column name */
  TypeOfAnchorAge = 'type_of_anchor_age',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValvesCylinder = 'valves_cylinder',
  /** column name */
  VehicleAgeMonths = 'vehicle_age_months',
  /** column name */
  VehicleAgeYears = 'vehicle_age_years',
  /** column name */
  VehicleHeight = 'vehicle_height',
  /** column name */
  VehicleLength = 'vehicle_length',
  /** column name */
  VehicleToGrid = 'vehicle_to_grid',
  /** column name */
  VehicleToLoad = 'vehicle_to_load',
  /** column name */
  VehicleToVehicle = 'vehicle_to_vehicle',
  /** column name */
  VehicleType = 'vehicle_type',
  /** column name */
  VehicleWidth = 'vehicle_width',
  /** column name */
  VfactsClass = 'vfacts_class',
  /** column name */
  VfactsPrice = 'vfacts_price',
  /** column name */
  VfactsSegment = 'vfacts_segment',
  /** column name */
  WadingDepth = 'wading_depth',
  /** column name */
  WarrantyAntiCorrosion = 'warranty_anti_corrosion',
  /** column name */
  WarrantyCustomerAssist = 'warranty_customer_assist',
  /** column name */
  WarrantyExtraInfo = 'warranty_extra_info',
  /** column name */
  WarrantyKm = 'warranty_km',
  /** column name */
  WarrantyYears = 'warranty_years',
  /** column name */
  WheelBase = 'wheel_base',
  /** column name */
  WheelBaseConfiguration = 'wheel_base_configuration',
  /** column name */
  YearGroupMonth = 'year_group_month',
  /** column name */
  YearGroupMonthName = 'year_group_month_name',
  /** column name */
  YearGroupMonthNameShort = 'year_group_month_name_short',
  /** column name */
  YearGroupSequenceNo = 'year_group_sequence_no',
  /** column name */
  YearGroupYear = 'year_group_year'
}

export type Vehicle_Specifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vehicle_Specifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicle_Specifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Specifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicle_Specifications_Var_Pop_Fields = {
  __typename?: 'vehicle_specifications_var_pop_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vehicle_Specifications_Var_Samp_Fields = {
  __typename?: 'vehicle_specifications_var_samp_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vehicle_Specifications_Variance_Fields = {
  __typename?: 'vehicle_specifications_variance_fields';
  /** Vehicle acceleration 0-100km/h in seconds */
  acceleration?: Maybe<Scalars['Float']>;
  /** The vehicle`s Air Pollution rating (1-10). refer http://www.greenvehicleguide.gov.au/ */
  air_pollution_rating?: Maybe<Scalars['Float']>;
  /** The ANCAP Safety Rating. Values from 1 to 5. */
  ancap_rating?: Maybe<Scalars['Float']>;
  /** The year in which the ANCAP rating was issued. */
  ancap_year?: Maybe<Scalars['Float']>;
  /** The angle at which a 4WD can take a steep climb or descend without scraping the front of the vehicle on the ground. */
  approach_angle?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on normal charge */
  battery_normal_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for normal charge */
  battery_normal_charge_voltage?: Maybe<Scalars['Float']>;
  battery_quick_charge_power?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The number of minutes it takes to charge the battery on quick charge */
  battery_quick_charge_time?: Maybe<Scalars['Float']>;
  /** For Electric Vehicles only - The Voltage used for quick charge */
  battery_quick_charge_voltage?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Combined emissions in g/km */
  co2_combined?: Maybe<Scalars['Float']>;
  co2_extra_urban?: Maybe<Scalars['Float']>;
  /** The amount of CO2 Urban emissions in g/km */
  co2_urban?: Maybe<Scalars['Float']>;
  /** Number of cylinders or rotors */
  cylinders?: Maybe<Scalars['Float']>;
  /** Number of doors not including the boot */
  door_no?: Maybe<Scalars['Float']>;
  /** Charge time in minutes using a 50kW DC charger (up to the indicated charge capacity) */
  electric_eng50kwdc_charge_time?: Maybe<Scalars['Float']>;
  /** The charge time in minutes defined by the manufacturer using the indicated Wall Box output */
  electric_eng_wallbox_charge_time?: Maybe<Scalars['Float']>;
  /** The output in kW of the Wall Box used to define the manufacturer defined AC Wall Box charge time */
  electric_eng_wallbox_power?: Maybe<Scalars['Float']>;
  electric_engine_amp_hours?: Maybe<Scalars['Float']>;
  electric_engine_power_kw?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_power_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_top_speed?: Maybe<Scalars['Float']>;
  electric_engine_torque?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_from?: Maybe<Scalars['Float']>;
  electric_engine_torque_rpm_to?: Maybe<Scalars['Float']>;
  electric_engine_volts?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in cc`s eg 3387 */
  engine_size?: Maybe<Scalars['Float']>;
  /** The exact engine capacity in litre`s eg 1.4 */
  engine_size_litres?: Maybe<Scalars['Float']>;
  /** First service due in km */
  first_service_kms?: Maybe<Scalars['Float']>;
  /** First service due in months */
  first_service_months?: Maybe<Scalars['Float']>;
  fuel_avg_distance?: Maybe<Scalars['Float']>;
  /** The Fuel tank capacity in litres */
  fuel_capacity?: Maybe<Scalars['Float']>;
  fuel_consumption_city?: Maybe<Scalars['Float']>;
  fuel_consumption_combined?: Maybe<Scalars['Float']>;
  fuel_consumption_highway?: Maybe<Scalars['Float']>;
  fuel_max_distance?: Maybe<Scalars['Float']>;
  fuel_min_distance?: Maybe<Scalars['Float']>;
  /** The number of Gears on the vehicle */
  gear_no?: Maybe<Scalars['Float']>;
  green_house_rating?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full (kg) */
  kerb_weight?: Maybe<Scalars['Float']>;
  /** The recommended retail price */
  new_price?: Maybe<Scalars['Float']>;
  /** Total number of SRS airbags fitted to the vehicle */
  no_airbags?: Maybe<Scalars['Float']>;
  /** The vehicle`s overall rating (1-5) based on the sum of the air pollution and greenhouse ratings. refer http://www.greenvehicleguide.gov.au/ */
  overall_green_star_rating?: Maybe<Scalars['Float']>;
  /** The Power in kW of the vehicle */
  power_kw?: Maybe<Scalars['Float']>;
  /** The end RPM when peak Power is achieved */
  power_rpm_to?: Maybe<Scalars['Float']>;
  /** Power to weight ratio calculated from engine power and vehicle weight (kW/t) */
  power_weight_ratio?: Maybe<Scalars['Float']>;
  /** Regular service interval in km */
  regular_service_kms?: Maybe<Scalars['Float']>;
  /** Regular service interval in months */
  regular_service_months?: Maybe<Scalars['Float']>;
  /** The Release Month of the vehicle, 0 = carry over from last year */
  release_month?: Maybe<Scalars['Float']>;
  /** The Release Year of the vehicle */
  release_year?: Maybe<Scalars['Float']>;
  /** The number of occupants that can be legally carried */
  seat_capacity?: Maybe<Scalars['Float']>;
  /** The weight of the vehicle with standard equipment unoccupied and unladen with all fluid reservoirs full except for fuel, which is 10 litres only (kg) */
  tare_mass?: Maybe<Scalars['Float']>;
  /** The maximum Torque of the vehicle */
  torque?: Maybe<Scalars['Float']>;
  /** The end RPM for maximum Torque */
  torque_rpm_to?: Maybe<Scalars['Float']>;
  towing_capacity_braked?: Maybe<Scalars['Float']>;
  towing_capacity_unbraked?: Maybe<Scalars['Float']>;
  /** Distance between front wheels taken from the centre of the tyres (mm) */
  track_front?: Maybe<Scalars['Float']>;
  /** Distance between rear wheels taken from the centre of the tyres (mm) */
  track_rear?: Maybe<Scalars['Float']>;
  /** Number of valves per cylinder */
  valves_cylinder?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of months */
  vehicle_age_months?: Maybe<Scalars['Float']>;
  /** Vehicle age described as number of years */
  vehicle_age_years?: Maybe<Scalars['Float']>;
  /** Height (mm) */
  vehicle_height?: Maybe<Scalars['Float']>;
  /** Length (mm) */
  vehicle_length?: Maybe<Scalars['Float']>;
  /** Width (mm) */
  vehicle_width?: Maybe<Scalars['Float']>;
  /** The deepest water level the vehicle can safely pass */
  wading_depth?: Maybe<Scalars['Float']>;
  /** The number of years on the car warranty */
  warranty_years?: Maybe<Scalars['Float']>;
  /** The distance between the centers of the front and rear wheels (mm) */
  wheel_base?: Maybe<Scalars['Float']>;
  /** The Month Group of the vehicle Eg 3 */
  year_group_month?: Maybe<Scalars['Float']>;
  /** Sequence Number used to order vehicles within their Year Group */
  year_group_sequence_no?: Maybe<Scalars['Float']>;
  /** The Year Group of the vehicle */
  year_group_year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "wish_list" */
export type Wish_List = {
  __typename?: 'wish_list';
  badge?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  /** An object relationship */
  stocked_vehicle?: Maybe<Stocked_Vehicles>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  touched?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "wish_list" */
export type Wish_List_Aggregate = {
  __typename?: 'wish_list_aggregate';
  aggregate?: Maybe<Wish_List_Aggregate_Fields>;
  nodes: Array<Wish_List>;
};

export type Wish_List_Aggregate_Bool_Exp = {
  count?: Maybe<Wish_List_Aggregate_Bool_Exp_Count>;
};

export type Wish_List_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Wish_List_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Wish_List_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "wish_list" */
export type Wish_List_Aggregate_Fields = {
  __typename?: 'wish_list_aggregate_fields';
  avg?: Maybe<Wish_List_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wish_List_Max_Fields>;
  min?: Maybe<Wish_List_Min_Fields>;
  stddev?: Maybe<Wish_List_Stddev_Fields>;
  stddev_pop?: Maybe<Wish_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wish_List_Stddev_Samp_Fields>;
  sum?: Maybe<Wish_List_Sum_Fields>;
  var_pop?: Maybe<Wish_List_Var_Pop_Fields>;
  var_samp?: Maybe<Wish_List_Var_Samp_Fields>;
  variance?: Maybe<Wish_List_Variance_Fields>;
};


/** aggregate fields of "wish_list" */
export type Wish_List_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wish_List_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wish_list" */
export type Wish_List_Aggregate_Order_By = {
  avg?: Maybe<Wish_List_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wish_List_Max_Order_By>;
  min?: Maybe<Wish_List_Min_Order_By>;
  stddev?: Maybe<Wish_List_Stddev_Order_By>;
  stddev_pop?: Maybe<Wish_List_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wish_List_Stddev_Samp_Order_By>;
  sum?: Maybe<Wish_List_Sum_Order_By>;
  var_pop?: Maybe<Wish_List_Var_Pop_Order_By>;
  var_samp?: Maybe<Wish_List_Var_Samp_Order_By>;
  variance?: Maybe<Wish_List_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wish_list" */
export type Wish_List_Arr_Rel_Insert_Input = {
  data: Array<Wish_List_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wish_List_On_Conflict>;
};

/** aggregate avg on columns */
export type Wish_List_Avg_Fields = {
  __typename?: 'wish_list_avg_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wish_list" */
export type Wish_List_Avg_Order_By = {
  touched?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wish_list". All fields are combined with a logical 'AND'. */
export type Wish_List_Bool_Exp = {
  _and?: Maybe<Array<Wish_List_Bool_Exp>>;
  _not?: Maybe<Wish_List_Bool_Exp>;
  _or?: Maybe<Array<Wish_List_Bool_Exp>>;
  badge?: Maybe<String_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  production_year?: Maybe<String_Comparison_Exp>;
  series?: Maybe<String_Comparison_Exp>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Bool_Exp>;
  stocked_vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  touched?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "wish_list" */
export enum Wish_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  WishListPkey = 'wish_list_pkey'
}

/** input type for incrementing numeric columns in table "wish_list" */
export type Wish_List_Inc_Input = {
  touched?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "wish_list" */
export type Wish_List_Insert_Input = {
  badge?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Obj_Rel_Insert_Input>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  touched?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Wish_List_Max_Fields = {
  __typename?: 'wish_list_max_fields';
  badge?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  touched?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "wish_list" */
export type Wish_List_Max_Order_By = {
  badge?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wish_List_Min_Fields = {
  __typename?: 'wish_list_min_fields';
  badge?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  touched?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "wish_list" */
export type Wish_List_Min_Order_By = {
  badge?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wish_list" */
export type Wish_List_Mutation_Response = {
  __typename?: 'wish_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wish_List>;
};

/** on_conflict condition type for table "wish_list" */
export type Wish_List_On_Conflict = {
  constraint: Wish_List_Constraint;
  update_columns?: Array<Wish_List_Update_Column>;
  where?: Maybe<Wish_List_Bool_Exp>;
};

/** Ordering options when selecting data from "wish_list". */
export type Wish_List_Order_By = {
  badge?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  production_year?: Maybe<Order_By>;
  series?: Maybe<Order_By>;
  stocked_vehicle?: Maybe<Stocked_Vehicles_Order_By>;
  stocked_vehicle_id?: Maybe<Order_By>;
  touched?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: wish_list */
export type Wish_List_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "wish_list" */
export enum Wish_List_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Series = 'series',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  Touched = 'touched',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "wish_list" */
export type Wish_List_Set_Input = {
  badge?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  touched?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Wish_List_Stddev_Fields = {
  __typename?: 'wish_list_stddev_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wish_list" */
export type Wish_List_Stddev_Order_By = {
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wish_List_Stddev_Pop_Fields = {
  __typename?: 'wish_list_stddev_pop_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wish_list" */
export type Wish_List_Stddev_Pop_Order_By = {
  touched?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wish_List_Stddev_Samp_Fields = {
  __typename?: 'wish_list_stddev_samp_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wish_list" */
export type Wish_List_Stddev_Samp_Order_By = {
  touched?: Maybe<Order_By>;
};

/** Streaming cursor of the table "wish_list" */
export type Wish_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wish_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wish_List_Stream_Cursor_Value_Input = {
  badge?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  production_year?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  stocked_vehicle_id?: Maybe<Scalars['uuid']>;
  touched?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Wish_List_Sum_Fields = {
  __typename?: 'wish_list_sum_fields';
  touched?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "wish_list" */
export type Wish_List_Sum_Order_By = {
  touched?: Maybe<Order_By>;
};

/** update columns of table "wish_list" */
export enum Wish_List_Update_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Make = 'make',
  /** column name */
  Model = 'model',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProductionYear = 'production_year',
  /** column name */
  Series = 'series',
  /** column name */
  StockedVehicleId = 'stocked_vehicle_id',
  /** column name */
  Touched = 'touched',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Wish_List_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Wish_List_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Wish_List_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wish_List_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wish_List_Var_Pop_Fields = {
  __typename?: 'wish_list_var_pop_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wish_list" */
export type Wish_List_Var_Pop_Order_By = {
  touched?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wish_List_Var_Samp_Fields = {
  __typename?: 'wish_list_var_samp_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wish_list" */
export type Wish_List_Var_Samp_Order_By = {
  touched?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wish_List_Variance_Fields = {
  __typename?: 'wish_list_variance_fields';
  touched?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wish_list" */
export type Wish_List_Variance_Order_By = {
  touched?: Maybe<Order_By>;
};

export type VehiclesPageMakesAndModelsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type VehiclesPageMakesAndModelsQuery = (
  { __typename?: 'query_root' }
  & { makes: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'make'>
  )>, models: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'make' | 'model'>
  )>, badges: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'badge'>
  )>, years: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'production_year'>
  )> }
);

export type CalculatorGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CalculatorGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'email'>
  )> }
);

export type GetMakesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMakesQuery = (
  { __typename?: 'query_root' }
  & { known_vehicles: Array<(
    { __typename?: 'known_vehicles' }
    & Pick<Known_Vehicles, 'make'>
  )> }
);

export type GetModelsQueryVariables = Exact<{
  make?: Maybe<Scalars['String']>;
}>;


export type GetModelsQuery = (
  { __typename?: 'query_root' }
  & { known_vehicles: Array<(
    { __typename?: 'known_vehicles' }
    & Pick<Known_Vehicles, 'model'>
  )> }
);

export type GetReleaseYearsQueryVariables = Exact<{
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
}>;


export type GetReleaseYearsQuery = (
  { __typename?: 'query_root' }
  & { known_vehicles: Array<(
    { __typename?: 'known_vehicles' }
    & Pick<Known_Vehicles, 'id' | 'release_year'>
  )> }
);

export type InsertCalculatorSubmissionMutationVariables = Exact<{
  object: Calculator_Submission_Insert_Input;
}>;


export type InsertCalculatorSubmissionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_calculator_submission_one?: Maybe<(
    { __typename?: 'calculator_submission' }
    & Pick<Calculator_Submission, 'id'>
  )> }
);

export type UpdateCustomVehicleOrderMutationVariables = Exact<{
  customVehicleId: Scalars['uuid'];
  customVehicle: Custom_Vehicles_Set_Input;
}>;


export type UpdateCustomVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_custom_vehicles_by_pk?: Maybe<(
    { __typename?: 'custom_vehicles' }
    & Pick<Custom_Vehicles, 'id'>
  )> }
);

export type InsertAssetsMutationVariables = Exact<{
  assets: Array<Assets_Insert_Input> | Assets_Insert_Input;
}>;


export type InsertAssetsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_assets?: Maybe<(
    { __typename?: 'assets_mutation_response' }
    & { returning: Array<(
      { __typename?: 'assets' }
      & Pick<Assets, 'id'>
    )> }
  )> }
);

export type DeleteAssetsMutationVariables = Exact<{
  customVehicleId: Scalars['uuid'];
  assetIdsToKeep: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteAssetsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_assets?: Maybe<(
    { __typename?: 'assets_mutation_response' }
    & { returning: Array<(
      { __typename?: 'assets' }
      & Pick<Assets, 'id'>
    )> }
  )> }
);

export type GetSimilarStockedVehiclesQueryVariables = Exact<{
  tier1: Stocked_Vehicles_Bool_Exp;
  tier2: Stocked_Vehicles_Bool_Exp;
  tier3: Stocked_Vehicles_Bool_Exp;
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type GetSimilarStockedVehiclesQuery = (
  { __typename?: 'query_root' }
  & { tier1: Array<(
    { __typename?: 'stocked_vehicles' }
    & SimilarStockedVehicleFragment
  )>, tier2: Array<(
    { __typename?: 'stocked_vehicles' }
    & SimilarStockedVehicleFragment
  )>, tier3: Array<(
    { __typename?: 'stocked_vehicles' }
    & SimilarStockedVehicleFragment
  )> }
);

export type SimilarStockedVehicleFragment = (
  { __typename?: 'stocked_vehicles' }
  & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'pricing_config' | 'make' | 'model' | 'badge' | 'interior' | 'media' | 'production_year' | 'odometer' | 'is_reserved' | 'condition' | 'is_sold' | 'useable_battery' | 'body_type' | 'estimated_delivery_delay' | 'available_from' | 'packs' | 'wheel_name' | 'colour'>
);

export type GetSimilarStockedVehiclesByAiQueryVariables = Exact<{
  vehicleIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type GetSimilarStockedVehiclesByAiQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'pricing_config' | 'make' | 'model' | 'badge' | 'interior' | 'media' | 'production_year' | 'odometer' | 'is_reserved' | 'condition' | 'is_sold' | 'useable_battery' | 'body_type' | 'estimated_delivery_delay' | 'available_from' | 'packs' | 'wheel_name' | 'colour'>
  )> }
);

export type DynamicFormLocationsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type DynamicFormLocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'name' | 'address'>
  )> }
);

export type DynamicFormGetFormQueryVariables = Exact<{
  formId: Scalars['uuid'];
}>;


export type DynamicFormGetFormQuery = (
  { __typename?: 'query_root' }
  & { forms_by_pk?: Maybe<(
    { __typename?: 'forms' }
    & Pick<Forms, 'id' | 'name' | 'form_info' | 'on_success_message' | 'button_text' | 'signup_user_on_submit'>
    & { form_fields: Array<(
      { __typename?: 'form_fields' }
      & Pick<Form_Fields, 'name' | 'type' | 'label' | 'help_text' | 'options' | 'order' | 'rules' | 'default_value' | 'col' | 'options_layout' | 'property'>
    )> }
  )> }
);

export type EnergyCalculatorGetKnownVehicleQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type EnergyCalculatorGetKnownVehicleQuery = (
  { __typename?: 'query_root' }
  & { search_known_vehicles: Array<(
    { __typename?: 'known_vehicles' }
    & Pick<Known_Vehicles, 'energy_consumption_wh_per_100km'>
  )> }
);

export type FeaturedVehiclesQueryVariables = Exact<{
  showroomCode?: Maybe<Scalars['String']>;
  pricingCode?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type FeaturedVehiclesQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'name' | 'make' | 'model' | 'badge' | 'code' | 'range' | 'pricing_config' | 'media' | 'production_year' | 'odometer' | 'is_reserved' | 'condition' | 'is_sold' | 'useable_battery' | 'estimated_delivery_delay' | 'available_from'>
  )>, showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'https_enabled' | 'domain'>
  )> }
);

export type FileUploadGetSignedUploadMutationVariables = Exact<{
  isPublic?: Maybe<Scalars['Boolean']>;
}>;


export type FileUploadGetSignedUploadMutation = (
  { __typename?: 'mutation_root' }
  & { media_get_signed_upload: (
    { __typename?: 'MediaSignedUploadOutput' }
    & Pick<MediaSignedUploadOutput, 'base_url' | 'post_url' | 'post_fields'>
  ) }
);

export type FileUploadGetSignedDownloadMutationVariables = Exact<{
  pathname: Scalars['String'];
}>;


export type FileUploadGetSignedDownloadMutation = (
  { __typename?: 'mutation_root' }
  & { media_get_signed_download?: Maybe<(
    { __typename?: 'MediaGetSignedDownloadOutput' }
    & Pick<MediaGetSignedDownloadOutput, 'url'>
  )> }
);

export type InterestsQueryVariables = Exact<{
  userId?: Maybe<Scalars['uuid']>;
  showroomId?: Maybe<Scalars['uuid']>;
}>;


export type InterestsQuery = (
  { __typename?: 'query_root' }
  & { interests: Array<(
    { __typename?: 'interests' }
    & Pick<Interests, 'code' | 'label'>
  )>, user_interests: Array<(
    { __typename?: 'user_interests' }
    & { interest: (
      { __typename?: 'interests' }
      & Pick<Interests, 'code'>
    ) }
  )> }
);

export type MatchToolInsertMatchSubmissionMutationVariables = Exact<{
  object: Match_Submissions_Insert_Input;
}>;


export type MatchToolInsertMatchSubmissionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_match_submissions_one?: Maybe<(
    { __typename?: 'match_submissions' }
    & Pick<Match_Submissions, 'id'>
  )> }
);

export type GetOrderPreviewQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type GetOrderPreviewQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'type' | 'is_temporary_hold' | 'created_at' | 'requested_shs_credit_amount' | 'settlement_method' | 'deposit_amount' | 'pricing_config'>
    & { trade_in_plans: Array<(
      { __typename?: 'trade_in_plans' }
      & Pick<Trade_In_Plans, 'instant_valuation'>
    )>, finance_application?: Maybe<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'requested_credit_term' | 'requested_credit_amount'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'name' | 'code' | 'make' | 'model' | 'badge' | 'series' | 'production_year' | 'media'>
    )>, custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'make' | 'model' | 'badge' | 'series' | 'production_year' | 'media' | 'configurator_code' | 'configurator_values'>
    )> }
  )> }
);

export type TestDrivePageGetDefaultVehicleListUrlQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type TestDrivePageGetDefaultVehicleListUrlQuery = (
  { __typename?: 'query_root' }
  & { vehicle_lists: Array<(
    { __typename?: 'vehicle_lists' }
    & Pick<Vehicle_Lists, 'path'>
  )> }
);

export type VehicleFragment = (
  { __typename?: 'stocked_vehicles' }
  & Pick<Stocked_Vehicles, 'id' | 'name' | 'make' | 'body_type' | 'model' | 'badge' | 'code' | 'range' | 'pricing_config' | 'media' | 'production_year' | 'odometer' | 'is_reserved' | 'condition' | 'is_sold' | 'useable_battery' | 'estimated_delivery_delay' | 'available_from' | 'colour' | 'fuel_type'>
);

export type SimilarVehiclesQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  bodyType?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  rangeLower?: Maybe<Scalars['numeric']>;
  rangeUpper?: Maybe<Scalars['numeric']>;
  showroomId?: Maybe<Scalars['uuid']>;
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type SimilarVehiclesQuery = (
  { __typename?: 'query_root' }
  & { sameMakeModelVehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & VehicleFragment
  )>, sameBodyTypeOrRangeVehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & VehicleFragment
  )> }
);

export type TestDriveContactDetailsGetLocationQueryVariables = Exact<{
  locationId: Scalars['uuid'];
}>;


export type TestDriveContactDetailsGetLocationQuery = (
  { __typename?: 'query_root' }
  & { locations_by_pk?: Maybe<(
    { __typename?: 'locations' }
    & Pick<Locations, 'name' | 'address'>
  )> }
);

export type TestDriveContactDetailsGetUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type TestDriveContactDetailsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name' | 'last_name' | 'email' | 'phone'>
  )> }
);

export type TestDrivePageGetSummaryQueryVariables = Exact<{
  locationId: Scalars['uuid'];
  vehicleId: Scalars['uuid'];
}>;


export type TestDrivePageGetSummaryQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles_by_pk?: Maybe<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series'>
  )>, locations_by_pk?: Maybe<(
    { __typename?: 'locations' }
    & Pick<Locations, 'name' | 'address'>
  )> }
);

export type TestDrivePageUpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Users_Set_Input;
}>;


export type TestDrivePageUpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type TestDrivePageInsertTestDriveMutationVariables = Exact<{
  objects: Array<Test_Drives_Insert_Input> | Test_Drives_Insert_Input;
}>;


export type TestDrivePageInsertTestDriveMutation = (
  { __typename?: 'mutation_root' }
  & { insert_test_drives?: Maybe<(
    { __typename?: 'test_drives_mutation_response' }
    & Pick<Test_Drives_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetTestDriveLocationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTestDriveLocationQuery = (
  { __typename?: 'query_root' }
  & { locations_by_pk?: Maybe<(
    { __typename?: 'locations' }
    & Pick<Locations, 'name'>
  )> }
);

export type TestDrivePageGetVehiclesQueryVariables = Exact<{
  locationId: Scalars['uuid'];
}>;


export type TestDrivePageGetVehiclesQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'production_year' | 'make' | 'model' | 'badge' | 'packs' | 'media'>
  )> }
);

export type TestDrivePageGetLocationsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type TestDrivePageGetLocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'name' | 'address'>
  )> }
);

export type TextEditorGetSignedUploadMutationVariables = Exact<{ [key: string]: never; }>;


export type TextEditorGetSignedUploadMutation = (
  { __typename?: 'mutation_root' }
  & { media_get_signed_upload: (
    { __typename?: 'MediaSignedUploadOutput' }
    & Pick<MediaSignedUploadOutput, 'base_url' | 'post_url' | 'post_fields'>
  ) }
);

export type GetFilterOptionsQueryVariables = Exact<{
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
}>;


export type GetFilterOptionsQuery = (
  { __typename?: 'query_root' }
  & { production_year: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'production_year'>
  )>, model: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'model'>
  )>, badge: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'badge'>
  )>, colour: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'colour' | 'colour_code'>
  )>, interior: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'interior' | 'interior_code'>
  )>, fuel_type: Array<(
    { __typename?: 'vehicle_specifications' }
    & Pick<Vehicle_Specifications, 'fuel_type'>
  )> }
);

export type AddVehiclesToWishlistMutationVariables = Exact<{
  object: Wish_List_Insert_Input;
}>;


export type AddVehiclesToWishlistMutation = (
  { __typename?: 'mutation_root' }
  & { insert_wish_list_one?: Maybe<(
    { __typename?: 'wish_list' }
    & Pick<Wish_List, 'id'>
  )> }
);

export type RemoveVehicleFromWishlistMutationVariables = Exact<{
  userId: Scalars['uuid'];
  stockedVehicleId: Scalars['uuid'];
}>;


export type RemoveVehicleFromWishlistMutation = (
  { __typename?: 'mutation_root' }
  & { delete_wish_list?: Maybe<(
    { __typename?: 'wish_list_mutation_response' }
    & Pick<Wish_List_Mutation_Response, 'affected_rows'>
  )> }
);

export type VehiclesGetWishListQueryVariables = Exact<{
  userId?: Maybe<Scalars['uuid']>;
}>;


export type VehiclesGetWishListQuery = (
  { __typename?: 'query_root' }
  & { wish_list: Array<(
    { __typename?: 'wish_list' }
    & Pick<Wish_List, 'stocked_vehicle_id'>
  )> }
);

export type VehiclesGetStockedVehiclesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  where?: Maybe<Stocked_Vehicles_Bool_Exp>;
  pricingCode?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Stocked_Vehicles_Order_By> | Stocked_Vehicles_Order_By>;
}>;


export type VehiclesGetStockedVehiclesQuery = (
  { __typename?: 'query_root' }
  & { search_stocked_vehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'range' | 'pricing_config' | 'make' | 'model' | 'is_reserved_until' | 'badge' | 'badge_code' | 'media' | 'production_year' | 'odometer' | 'condition' | 'is_sold' | 'packs' | 'pack_codes' | 'useable_battery' | 'body_type' | 'estimated_delivery_delay' | 'available_from' | 'featured_order' | 'colour' | 'series' | 'fuel_type'>
  )> }
);

export type CancelVehicleOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CancelVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { cancel_vehicle_order?: Maybe<(
    { __typename?: 'CancelVehicleOrderOutput' }
    & Pick<CancelVehicleOrderOutput, 'id'>
  )> }
);

export type GetDriverDataQueryVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type GetDriverDataQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'stage' | 'delivery_address' | 'is_commercial' | 'business_identifier' | 'business_name'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'first_name' | 'middle_name' | 'last_name' | 'phone' | 'email' | 'date_of_birth' | 'driving_licence_number' | 'driving_licence_expiry' | 'driving_licence_version' | 'driving_licence_state'>
    ) }
  )> }
);

export type UpdateDriverDataVehicleOrderMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  vehicleOrder?: Maybe<Vehicle_Orders_Set_Input>;
  userId: Scalars['uuid'];
  user?: Maybe<Users_Set_Input>;
}>;


export type UpdateDriverDataVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )>, update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type GetProductsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type GetProductsQuery = (
  { __typename?: 'query_root' }
  & { product_groups: Array<(
    { __typename?: 'product_groups' }
    & { product_groups: Array<(
      { __typename?: 'product_groups' }
      & Product_GroupFragment
    )> }
    & Product_GroupFragment
  )> }
);

export type Product_GroupFragment = (
  { __typename?: 'product_groups' }
  & Pick<Product_Groups, 'id' | 'name' | 'code'>
  & { products: Array<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'name' | 'code' | 'body' | 'description' | 'media' | 'variant_label'>
    & { product_variants: Array<(
      { __typename?: 'product_variants' }
      & Pick<Product_Variants, 'id' | 'name' | 'gross_price' | 'order'>
    )> }
  )> }
);

export type AdminArticleFormGetTagsAndShowroomsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminArticleFormGetTagsAndShowroomsQuery = (
  { __typename?: 'query_root' }
  & { tags: Array<(
    { __typename?: 'articles' }
    & Pick<Articles, 'tags'>
  )>, showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id' | 'name'>
  )> }
);

export type ProductGridGetProductsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type ProductGridGetProductsQuery = (
  { __typename?: 'query_root' }
  & { products: Array<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'name' | 'description' | 'media' | 'variant_label' | 'product_group_id'>
    & { product_variants: Array<(
      { __typename?: 'product_variants' }
      & Pick<Product_Variants, 'id' | 'name' | 'gross_price' | 'description'>
    )> }
  )>, product_groups: Array<(
    { __typename?: 'product_groups' }
    & Pick<Product_Groups, 'id' | 'name'>
  )> }
);

export type DynamicPagePathGetPageQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
  path: Scalars['String'];
}>;


export type DynamicPagePathGetPageQuery = (
  { __typename?: 'query_root' }
  & { pages: Array<(
    { __typename?: 'pages' }
    & Pick<Pages, 'id' | 'path' | 'name' | 'meta_title' | 'meta_description' | 'sections' | 'html' | 'version' | 'created_at' | 'updated_at'>
  )> }
);

export type AccountGetUserQueryVariables = Exact<{
  hasuraUserId: Scalars['uuid'];
}>;


export type AccountGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name' | 'last_name' | 'phone' | 'email' | 'referral_id' | 'referral_count'>
  )> }
);

export type VehicleOrderFragmentFragment = (
  { __typename?: 'vehicle_orders' }
  & Pick<Vehicle_Orders, 'id' | 'stage' | 'last_step' | 'type' | 'created_at' | 'deposit_amount' | 'invoicing_id'>
  & { payments: Array<(
    { __typename?: 'payments' }
    & Pick<Payments, 'received_at'>
  )>, custom_vehicle?: Maybe<(
    { __typename?: 'custom_vehicles' }
    & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
  )>, stocked_vehicle?: Maybe<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'code' | 'name' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'is_published'>
  )> }
);

export type GetLatestDepositPaidOrderQueryVariables = Exact<{
  userId: Scalars['uuid'];
  showroomId: Scalars['uuid'];
}>;


export type GetLatestDepositPaidOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders: Array<(
    { __typename?: 'vehicle_orders' }
    & VehicleOrderFragmentFragment
  )> }
);

export type GetLatestIncompleteOrderQueryVariables = Exact<{
  userId: Scalars['uuid'];
  showroomId: Scalars['uuid'];
}>;


export type GetLatestIncompleteOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders: Array<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'updated_at'>
    & VehicleOrderFragmentFragment
  )> }
);

export type GetTrustedPartnersQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type GetTrustedPartnersQuery = (
  { __typename?: 'query_root' }
  & { partners: Array<(
    { __typename?: 'partners' }
    & Pick<Partners, 'id' | 'logo' | 'name'>
  )> }
);

export type AccountGetShowroomPromotionsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type AccountGetShowroomPromotionsQuery = (
  { __typename?: 'query_root' }
  & { promotions: Array<(
    { __typename?: 'promotions' }
    & Pick<Promotions, 'id' | 'intro' | 'logo' | 'name' | 'background_image' | 'title'>
    & { showrooms_promotions: Array<(
      { __typename?: 'showrooms_promotions' }
      & Pick<Showrooms_Promotions, 'start_date' | 'end_date'>
    )> }
  )> }
);

export type GetVehicleOrderDataSubscriptionVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type GetVehicleOrderDataSubscription = (
  { __typename?: 'subscription_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'invoicing_id' | 'stage' | 'type' | 'created_at' | 'total_trade_in_value' | 'pricing_config' | 'deposit_amount' | 'is_commercial' | 'business_name' | 'business_identifier'>
    & { vehicle_order_product_variants: Array<(
      { __typename?: 'vehicle_order_product_variants' }
      & Pick<Vehicle_Order_Product_Variants, 'product_variant_id'>
    )>, custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_values' | 'configurator_code' | 'configurator'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'name' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'packs' | 'media'>
    )>, user: (
      { __typename?: 'users' }
      & Pick<Users, 'first_name' | 'middle_name' | 'last_name' | 'phone' | 'referral_count' | 'referral_id' | 'driving_licence_number' | 'driving_licence_expiry' | 'driving_licence_state'>
    ), finance_application?: Maybe<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'stage' | 'partner_application_url' | 'finance_partner'>
    )> }
  )> }
);

export type CheckVehicleOrderIdQueryVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type CheckVehicleOrderIdQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type GetDepositPaidOrdersQueryVariables = Exact<{
  userId: Scalars['uuid'];
  showroomId: Scalars['uuid'];
}>;


export type GetDepositPaidOrdersQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders: Array<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'stage' | 'last_step' | 'type' | 'created_at' | 'deposit_amount' | 'invoicing_id'>
    & { custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'code' | 'name' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'is_published'>
    )> }
  )> }
);

export type VehicleOrderUpdateStockedVehicleGetStockedVehiclesQueryVariables = Exact<{
  showroomId?: Maybe<Scalars['uuid']>;
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type VehicleOrderUpdateStockedVehicleGetStockedVehiclesQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'useable_battery' | 'code' | 'estimated_delivery_delay' | 'available_from' | 'condition' | 'is_reserved' | 'is_sold' | 'media' | 'odometer' | 'range' | 'pricing_config' | 'name' | 'badge'>
  )> }
);

export type VehicleOrderUpdateVehicleGetOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderUpdateVehicleGetOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'stage'>
  )> }
);

export type GetShowroomPartnersQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type GetShowroomPartnersQuery = (
  { __typename?: 'query_root' }
  & { partners: Array<(
    { __typename?: 'partners' }
    & Pick<Partners, 'id' | 'logo' | 'name' | 'website' | 'intro'>
  )> }
);

export type SaveProfileMutationVariables = Exact<{
  userId: Scalars['uuid'];
  user?: Maybe<Users_Set_Input>;
  interestCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  interestObjects: Array<User_Interests_Insert_Input> | User_Interests_Insert_Input;
}>;


export type SaveProfileMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )>, delete_user_interests?: Maybe<(
    { __typename?: 'user_interests_mutation_response' }
    & Pick<User_Interests_Mutation_Response, 'affected_rows'>
  )>, insert_user_interests?: Maybe<(
    { __typename?: 'user_interests_mutation_response' }
    & Pick<User_Interests_Mutation_Response, 'affected_rows'>
  )> }
);

export type AccountProfileGetUserQueryVariables = Exact<{
  hasuraUserId?: Maybe<Scalars['uuid']>;
}>;


export type AccountProfileGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name' | 'middle_name' | 'last_name' | 'email' | 'phone' | 'preferred_contact_method'>
  )> }
);

export type GetShowroomPromotionsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type GetShowroomPromotionsQuery = (
  { __typename?: 'query_root' }
  & { promotions: Array<(
    { __typename?: 'promotions' }
    & Pick<Promotions, 'id' | 'intro' | 'logo' | 'name' | 'background_image' | 'title'>
    & { showrooms_promotions: Array<(
      { __typename?: 'showrooms_promotions' }
      & Pick<Showrooms_Promotions, 'start_date' | 'end_date'>
    )> }
  )> }
);

export type GetWishListSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type GetWishListSubscription = (
  { __typename?: 'subscription_root' }
  & { wish_list: Array<(
    { __typename?: 'wish_list' }
    & Pick<Wish_List, 'id' | 'created_at' | 'updated_at' | 'make' | 'email' | 'model' | 'badge' | 'series' | 'comment' | 'touched'>
    & { stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'range' | 'pricing_config' | 'make' | 'model' | 'badge' | 'media' | 'production_year' | 'odometer' | 'condition' | 'is_sold' | 'packs' | 'pack_codes' | 'useable_battery' | 'body_type' | 'estimated_delivery_delay' | 'available_from' | 'featured_order' | 'colour' | 'series'>
    )> }
  )> }
);

export type AccountRemoveVehicleFromWishlistMutationVariables = Exact<{
  userId: Scalars['uuid'];
  stockedVehicleId: Scalars['uuid'];
}>;


export type AccountRemoveVehicleFromWishlistMutation = (
  { __typename?: 'mutation_root' }
  & { delete_wish_list?: Maybe<(
    { __typename?: 'wish_list_mutation_response' }
    & Pick<Wish_List_Mutation_Response, 'affected_rows'>
  )> }
);

export type AdminArticlesUpdateGetArticleQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminArticlesUpdateGetArticleQuery = (
  { __typename?: 'query_root' }
  & { articles_by_pk?: Maybe<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id' | 'name' | 'body' | 'featured_image' | 'description' | 'slug' | 'type' | 'tags' | 'showroom_id'>
    & { showroom?: Maybe<(
      { __typename?: 'showrooms' }
      & Pick<Showrooms, 'https_enabled' | 'domain'>
    )> }
  )> }
);

export type AdminArticlesDeleteArticleMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminArticlesDeleteArticleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_articles_by_pk?: Maybe<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id'>
  )> }
);

export type AdminArticlesUpdateArticleMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: Articles_Set_Input;
}>;


export type AdminArticlesUpdateArticleMutation = (
  { __typename?: 'mutation_root' }
  & { update_articles_by_pk?: Maybe<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id'>
  )> }
);

export type InsertArticleMutationVariables = Exact<{
  object: Articles_Insert_Input;
}>;


export type InsertArticleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_articles_one?: Maybe<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id'>
  )> }
);

export type AdminArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminArticlesQuery = (
  { __typename?: 'query_root' }
  & { articles: Array<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id' | 'name' | 'tags' | 'type' | 'source' | 'created_at'>
  )> }
);

export type AdminShowroomsUpdateGetShowroomQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminShowroomsUpdateGetShowroomQuery = (
  { __typename?: 'query_root' }
  & { showrooms_by_pk?: Maybe<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id' | 'name' | 'code' | 'domain' | 'phone' | 'email' | 'public_settings' | 'private_settings'>
  )> }
);

export type AdminShowroomsUpdateShowroomMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: Showrooms_Set_Input;
}>;


export type AdminShowroomsUpdateShowroomMutation = (
  { __typename?: 'mutation_root' }
  & { update_showrooms_by_pk?: Maybe<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id'>
  )> }
);

export type AdminShowroomsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminShowroomsQuery = (
  { __typename?: 'query_root' }
  & { showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id' | 'name' | 'code' | 'domain'>
  )> }
);

export type CalculatorResultClaimSubmissionMutationVariables = Exact<{
  submissionId: Scalars['uuid'];
  userId?: Maybe<Scalars['uuid']>;
}>;


export type CalculatorResultClaimSubmissionMutation = (
  { __typename?: 'mutation_root' }
  & { update_calculator_submission_by_pk?: Maybe<(
    { __typename?: 'calculator_submission' }
    & Pick<Calculator_Submission, 'id'>
  )> }
);

export type CalculatorResultsCaptureMetricsMutationVariables = Exact<{
  id: Scalars['uuid'];
  set?: Maybe<Calculator_Submission_Set_Input>;
}>;


export type CalculatorResultsCaptureMetricsMutation = (
  { __typename?: 'mutation_root' }
  & { update_calculator_submission_by_pk?: Maybe<(
    { __typename?: 'calculator_submission' }
    & Pick<Calculator_Submission, 'id'>
  )> }
);

export type CalculatorResultQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CalculatorResultQuery = (
  { __typename?: 'query_root' }
  & { calculator_submission_by_pk?: Maybe<(
    { __typename?: 'calculator_submission' }
    & Pick<Calculator_Submission, 'id' | 'registration_number' | 'state_of_registration' | 'driving_distance' | 'registration_lookup_response' | 'make' | 'model' | 'year' | 'vehicle_identifier_type'>
    & { vehicle?: Maybe<(
      { __typename?: 'known_vehicles' }
      & Pick<Known_Vehicles, 'make' | 'model' | 'release_year' | 'engine' | 'body_type' | 'fuel_consumption_litres_per_100km' | 'fuel_lifecycle_co2_grams_per_km'>
    )> }
  )> }
);

export type EvOptionFragment = (
  { __typename?: 'known_vehicles' }
  & Pick<Known_Vehicles, 'id' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'body_type' | 'drive_away_price' | 'energy_consumption_wh_per_100km' | 'fuel_lifecycle_co2_grams_per_km' | 'available_in_au_now'>
);

export type CalculatorResultGetEvOptionsQueryVariables = Exact<{
  orderByClause?: Maybe<Array<Known_Vehicles_Order_By> | Known_Vehicles_Order_By>;
  highQualityWhereClause?: Maybe<Known_Vehicles_Bool_Exp>;
  mediumQualityWhereClause?: Maybe<Known_Vehicles_Bool_Exp>;
  lowQualityWhereClause?: Maybe<Known_Vehicles_Bool_Exp>;
}>;


export type CalculatorResultGetEvOptionsQuery = (
  { __typename?: 'query_root' }
  & { lowQualityMatches: Array<(
    { __typename?: 'known_vehicles' }
    & EvOptionFragment
  )>, mediumQualityMatches: Array<(
    { __typename?: 'known_vehicles' }
    & EvOptionFragment
  )>, highQualityMatches: Array<(
    { __typename?: 'known_vehicles' }
    & EvOptionFragment
  )> }
);

export type SaveProgressMutationVariables = Exact<{
  id: Scalars['uuid'];
  object: Calculator_Submission_Set_Input;
}>;


export type SaveProgressMutation = (
  { __typename?: 'mutation_root' }
  & { update_calculator_submission_by_pk?: Maybe<(
    { __typename?: 'calculator_submission' }
    & Pick<Calculator_Submission, 'id'>
  )> }
);

export type ConfiguratorGetVehicleOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type ConfiguratorGetVehicleOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'custom_vehicle_id' | 'stage'>
  )> }
);

export type ResourceCentreSingleArticleQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  showroomId: Scalars['uuid'];
}>;


export type ResourceCentreSingleArticleQuery = (
  { __typename?: 'query_root' }
  & { articles: Array<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id' | 'created_at' | 'body' | 'description' | 'meta_description' | 'reading_time' | 'featured_image' | 'name' | 'slug' | 'tags' | 'showroom_id'>
  )> }
);

export type ArticleFragmentFragment = (
  { __typename?: 'articles' }
  & Pick<Articles, 'id' | 'created_at' | 'description' | 'reading_time' | 'featured_image' | 'name' | 'slug' | 'tags'>
);

export type ResourceCentreSearchArticlesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  showroomId: Scalars['uuid'];
}>;


export type ResourceCentreSearchArticlesQuery = (
  { __typename?: 'query_root' }
  & { search_articles: Array<(
    { __typename?: 'articles' }
    & ArticleFragmentFragment
  )> }
);

export type ResourceCentreArticlesQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type ResourceCentreArticlesQuery = (
  { __typename?: 'query_root' }
  & { articles: Array<(
    { __typename?: 'articles' }
    & ArticleFragmentFragment
  )> }
);

export type FinanceApplicationSaveCurrentAddressMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplication?: Maybe<Finance_Applications_Set_Input>;
}>;


export type FinanceApplicationSaveCurrentAddressMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationCurrentAddressGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationCurrentAddressGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residential_address' | 'years_at_residential_address'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'delivery_address'>
      )> }
    )> }
  )> }
);

export type DrivaFinanceApplicationEmploymentUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type DrivaFinanceApplicationEmploymentUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationEmploymentGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationEmploymentGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'employment_type' | 'number_of_dependents' | 'employment_duration'>
    )> }
  )> }
);

export type FinanceApplicationLivingSituatonUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type FinanceApplicationLivingSituatonUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationLivingSituationGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationLivingSituationGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residency' | 'visa_code' | 'visa_duration' | 'living_situation'>
    )> }
  )> }
);

export type DrivaFinanceApplicationLoanUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type DrivaFinanceApplicationLoanUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationLoanGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationLoanGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'annual_net_income' | 'employment_type' | 'number_of_dependents' | 'requested_credit_term' | 'requested_credit_amount'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'total_trade_in_value' | 'pricing_config'>
        & { stocked_vehicle?: Maybe<(
          { __typename?: 'stocked_vehicles' }
          & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
        )>, trade_in_plans: Array<(
          { __typename?: 'trade_in_plans' }
          & Pick<Trade_In_Plans, 'instant_valuation'>
        )> }
      )> }
    )> }
  )> }
);

export type DrivaFinanceApplicationPersonalDetailsUpdateMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userObject?: Maybe<Users_Set_Input>;
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type DrivaFinanceApplicationPersonalDetailsUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )>, update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  vehicleOrderObject?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type DrivaFinanceApplicationPersonalDetailsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationPersonalDetailsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'middle_name' | 'last_name' | 'date_of_birth' | 'driving_licence_number'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'is_commercial' | 'business_identifier' | 'business_name' | 'business_duration'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'id' | 'is_commercial' | 'business_identifier' | 'business_name'>
      )> }
    )> }
  )> }
);

export type DrivaFinanceApplicationSavePreviousAddressMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplication?: Maybe<Finance_Applications_Set_Input>;
}>;


export type DrivaFinanceApplicationSavePreviousAddressMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationPreviousAddressGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationPreviousAddressGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residential_address' | 'years_at_residential_address' | 'previous_residential_address' | 'years_at_previous_residential_address'>
    )> }
  )> }
);

export type DrivaFinanceApplicationResultsUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type DrivaFinanceApplicationResultsUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationResultsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationResultsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'stage' | 'requested_credit_amount' | 'requested_credit_term' | 'partner_application_response'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'id'>
        & { stocked_vehicle?: Maybe<(
          { __typename?: 'stocked_vehicles' }
          & Pick<Stocked_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series'>
        )> }
      )> }
    )> }
  )> }
);

export type DrivaFinanceApplicationStartInsertFinanceApplicationMutationVariables = Exact<{
  financeApplication: Finance_Applications_Insert_Input;
}>;


export type DrivaFinanceApplicationStartInsertFinanceApplicationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_finance_applications_one?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationStartUpdateVehicleOrderMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  financeApplictionId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationStartUpdateVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type DrivaFinanceApplicationStartGetVehicleOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationStartGetVehicleOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
    & { stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'id' | 'name'>
    )> }
  )> }
);

export type DrivaFinanceApplicationTermsUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type DrivaFinanceApplicationTermsUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type DrivaFinanceApplicationTermsPollStageQueryVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationTermsPollStageQuery = (
  { __typename?: 'query_root' }
  & { finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id' | 'stage' | 'partner_application_response'>
  )> }
);

export type DrivaFinanceApplicationTermsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type DrivaFinanceApplicationTermsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id'>
    )> }
  )> }
);

export type FaqArticlesQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type FaqArticlesQuery = (
  { __typename?: 'query_root' }
  & { articles: Array<(
    { __typename?: 'articles' }
    & Pick<Articles, 'id' | 'name' | 'description' | 'tags'>
  )> }
);

export type GenericFinanceApplicationCurrentAddressGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationCurrentAddressGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residential_address' | 'years_at_residential_address' | 'postal_address'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'delivery_address'>
      )> }
    )> }
  )> }
);

export type GenericFinanceApplicationEmploymentUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  applicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationEmploymentUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationEmploymentGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationEmploymentGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'number_of_dependents' | 'employment_type' | 'employment_duration' | 'employer_name' | 'previous_employment_type' | 'previous_employment_duration' | 'previous_employer_name' | 'previous_occupation' | 'occupation' | 'annual_net_income' | 'spouse_annual_net_income' | 'is_income_likely_to_change_within_12_months' | 'is_income_likely_to_change_within_12_months_details'>
    )> }
  )> }
);

export type GenericFinanceApplicationAssetsUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  applicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationAssetsUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationAssetsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationAssetsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'income' | 'assets' | 'liabilities' | 'expenses'>
    )> }
  )> }
);

export type GenericFinanceApplicationTermsAndConditionsUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  applicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationTermsAndConditionsUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationTermsAndConditionsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationTermsAndConditionsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id'>
    )> }
  )> }
);

export type GenericFinanceApplicationLivingSituatonUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  applicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationLivingSituatonUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationLivingSituationGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationLivingSituationGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residency' | 'visa_code' | 'visa_duration' | 'living_situation' | 'mortgage_value' | 'home_value' | 'relationship_status' | 'home_rent_or_mortgage_repayment' | 'number_of_dependents' | 'age_of_dependents' | 'country_of_birth' | 'country_of_citizenship' | 'secondary_country_of_citizenship'>
    )> }
  )> }
);

export type GenericFinanceApplicationLoanUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  applicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationLoanUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationLoanGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationLoanGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'requested_credit_term' | 'requested_credit_amount' | 'requested_credit_balloon' | 'annual_driving_distance' | 'include_incentives_in_repayment'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'pricing_config' | 'type' | 'settlement_method'>
        & { stocked_vehicle?: Maybe<(
          { __typename?: 'stocked_vehicles' }
          & Pick<Stocked_Vehicles, 'id' | 'make' | 'model' | 'badge_code' | 'pack_codes' | 'condition'>
        )>, custom_vehicle?: Maybe<(
          { __typename?: 'custom_vehicles' }
          & Pick<Custom_Vehicles, 'id' | 'make' | 'model' | 'configurator_values'>
        )> }
      )> }
    )> }
  )> }
);

export type GenericFinanceApplicationPersonalDetailsUpdateMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userObject?: Maybe<Users_Set_Input>;
  financeApplicationId: Scalars['uuid'];
  applicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationPersonalDetailsUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )>, update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationPersonalDetailsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationPersonalDetailsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'middle_name' | 'last_name' | 'date_of_birth' | 'driving_licence_number' | 'driving_licence_version' | 'driving_licence_expiry' | 'driving_licence_state' | 'driving_licence_type'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'is_commercial' | 'business_identifier' | 'business_name' | 'business_duration'>
    )> }
  )> }
);

export type GenericFinanceApplicationSavePreviousAddressMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplication?: Maybe<Finance_Applications_Set_Input>;
}>;


export type GenericFinanceApplicationSavePreviousAddressMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type GenericFinanceApplicationPreviousAddressGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationPreviousAddressGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residential_address' | 'years_at_residential_address' | 'previous_residential_address' | 'years_at_previous_residential_address'>
    )> }
  )> }
);

export type GenericFinanceApplicationResultsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type GenericFinanceApplicationResultsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'id'>
      )> }
    )> }
  )> }
);

export type FinanceApplicationApplicationSubmittedThankYouGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type FinanceApplicationApplicationSubmittedThankYouGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'stage' | 'partner_application_url'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'id'>
      )> }
    )> }
  )> }
);

export type FinanceApplicationSaveAddressMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplication?: Maybe<Finance_Applications_Set_Input>;
}>;


export type FinanceApplicationSaveAddressMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type FinanceApplicationCurrentAddressGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type FinanceApplicationCurrentAddressGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residential_address' | 'years_at_residential_address'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'delivery_address'>
      )> }
    )> }
  )> }
);

export type FinanceApplicationFinanceUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type FinanceApplicationFinanceUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type FinanceApplicationFinanceGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type FinanceApplicationFinanceGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'annual_net_income' | 'employment_type' | 'number_of_dependents' | 'living_situation'>
    )> }
  )> }
);

export type PlentiFinanceApplicationLoanUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type PlentiFinanceApplicationLoanUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type PlentiFinanceApplicationLoanGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type PlentiFinanceApplicationLoanGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'annual_net_income' | 'employment_type' | 'number_of_dependents' | 'requested_credit_term' | 'requested_credit_amount'>
      & { vehicle_orders: Array<(
        { __typename?: 'vehicle_orders' }
        & Pick<Vehicle_Orders, 'total_trade_in_value' | 'pricing_config' | 'deposit_amount'>
        & { stocked_vehicle?: Maybe<(
          { __typename?: 'stocked_vehicles' }
          & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
        )>, trade_in_plans: Array<(
          { __typename?: 'trade_in_plans' }
          & Pick<Trade_In_Plans, 'instant_valuation'>
        )> }
      )> }
    )> }
  )> }
);

export type FinanceApplicationPersonalDetailsUpdateUserMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userObject?: Maybe<Users_Set_Input>;
}>;


export type FinanceApplicationPersonalDetailsUpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type FinanceApplicationPersonalDetailsGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type FinanceApplicationPersonalDetailsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'middle_name' | 'last_name' | 'date_of_birth'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id'>
    )> }
  )> }
);

export type FinanceApplicationSavePreviousAddressMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplication?: Maybe<Finance_Applications_Set_Input>;
}>;


export type FinanceApplicationSavePreviousAddressMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type FinanceApplicationPreviousAddressGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type FinanceApplicationPreviousAddressGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'residential_address' | 'years_at_residential_address' | 'previous_residential_address' | 'years_at_previous_residential_address'>
    )> }
  )> }
);

export type FinanceApplicationStartInsertFinanceApplicationMutationVariables = Exact<{
  financeApplication: Finance_Applications_Insert_Input;
}>;


export type FinanceApplicationStartInsertFinanceApplicationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_finance_applications_one?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type FinanceApplicationStartUpdateVehicleOrderMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  financeApplicationId: Scalars['uuid'];
}>;


export type FinanceApplicationStartUpdateVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type FinanceApplicationStartGetVehicleOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type FinanceApplicationStartGetVehicleOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
    & { stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'id' | 'name'>
    )> }
  )> }
);

export type FinanceApplicationStartGetFinanceApplicationQueryVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
}>;


export type FinanceApplicationStartGetFinanceApplicationQuery = (
  { __typename?: 'query_root' }
  & { finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id' | 'finance_partner' | 'stage'>
  )> }
);

export type FinanceApplicationLoanUpdateMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  financeApplicationObject?: Maybe<Finance_Applications_Set_Input>;
}>;


export type FinanceApplicationLoanUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type FinanceApplicationPollStageQueryVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
}>;


export type FinanceApplicationPollStageQuery = (
  { __typename?: 'query_root' }
  & { finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id' | 'stage' | 'partner_application_url'>
  )> }
);

export type FinanceApplicationTermGetUserQueryVariables = Exact<{
  financeApplicationId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type FinanceApplicationTermGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { finance_applications: Array<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id'>
    )> }
  )> }
);

export type SiteMapQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type SiteMapQuery = (
  { __typename?: 'query_root' }
  & { articles: Array<(
    { __typename?: 'articles' }
    & Pick<Articles, 'slug' | 'updated_at' | 'created_at'>
  )>, stockedVehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'code' | 'updated_at'>
  )>, makes: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'make'>
  )>, makeModels: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'make' | 'model'>
  )> }
);

export type TestDriveGetUserDataQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type TestDriveGetUserDataQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'driving_licence_number' | 'driving_licence_version' | 'driving_licence_expiry' | 'driving_licence_state'>
  )> }
);

export type VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  vehicleOrder?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type VehicleOrderActSustainableHouseholdSchemeGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId: Scalars['uuid'];
}>;


export type VehicleOrderActSustainableHouseholdSchemeGetUserQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'phone' | 'total_trade_in_value' | 'requested_shs_credit_amount' | 'requested_shs_credit_term' | 'pricing_config' | 'deposit_amount' | 'type'>
      & { finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
      )>, custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'address_line_1' | 'address_line_2' | 'address_line_3' | 'city' | 'country_code' | 'country' | 'formatted_address' | 'google_place_id' | 'postcode' | 'state' | 'latitude' | 'longitude' | 'instant_valuation'>
      )> }
    )> }
  )> }
);

export type VehicleOrderActewAglChargerAddOnMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  object?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type VehicleOrderActewAglChargerAddOnMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type VehicleOrderActewAglChargerAddOnGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
}>;


export type VehicleOrderActewAglChargerAddOnGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'phone' | 'stage' | 'pricing_config' | 'deposit_amount' | 'type'>
      & { finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
      )>, custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'address_line_1' | 'address_line_2' | 'address_line_3' | 'city' | 'country_code' | 'country' | 'formatted_address' | 'google_place_id' | 'postcode' | 'state' | 'latitude' | 'longitude' | 'instant_valuation'>
      )> }
    )> }
  )> }
);

export type VehicleOrderActewAglEnergyAddOnMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  object?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type VehicleOrderActewAglEnergyAddOnMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type VehicleOrderActewAglEnergyAddOnGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
}>;


export type VehicleOrderActewAglEnergyAddOnGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'phone' | 'stage' | 'pricing_config' | 'deposit_amount' | 'type'>
      & { finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
      )>, custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'address_line_1' | 'address_line_2' | 'address_line_3' | 'city' | 'country_code' | 'country' | 'formatted_address' | 'google_place_id' | 'postcode' | 'state' | 'latitude' | 'longitude' | 'instant_valuation'>
      )> }
    )> }
  )> }
);

export type VehicleOrderAppStepGetAppActionsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
  appCode?: Maybe<Scalars['String']>;
}>;


export type VehicleOrderAppStepGetAppActionsQuery = (
  { __typename?: 'query_root' }
  & { app_actions: Array<(
    { __typename?: 'app_actions' }
    & Pick<App_Actions, 'type' | 'config'>
  )> }
);

export type VehicleOrderDeliverySaveDeliveryMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  object?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type VehicleOrderDeliverySaveDeliveryMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type VehicleOrderDeliverySaveLastEnquiryLocationMutationVariables = Exact<{
  userId: Scalars['uuid'];
  lastEnquiryLocationId: Scalars['uuid'];
}>;


export type VehicleOrderDeliverySaveLastEnquiryLocationMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type HandoverPageGetLocationsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type HandoverPageGetLocationsQuery = (
  { __typename?: 'query_root' }
  & { locations: Array<(
    { __typename?: 'locations' }
    & Pick<Locations, 'id' | 'name' | 'address'>
  )> }
);

export type VehicleOrderDeliveryGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
}>;


export type VehicleOrderDeliveryGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'phone' | 'delivery_address' | 'requested_shs_credit_amount' | 'pricing_config' | 'deposit_amount' | 'type'>
      & { finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
      )>, custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'address_line_1' | 'address_line_2' | 'address_line_3' | 'city' | 'country_code' | 'country' | 'formatted_address' | 'google_place_id' | 'postcode' | 'state' | 'latitude' | 'longitude' | 'instant_valuation'>
      )> }
    )> }
  )> }
);

export type VehicleOrderHoldingDepositThankYouSubscriptionVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderHoldingDepositThankYouSubscription = (
  { __typename?: 'subscription_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'settlement_method' | 'stage' | 'invoicing_id' | 'total_trade_in_value' | 'estimated_delivery_date' | 'is_actewagl_charger_add_on_enquiry' | 'created_at' | 'deposit_amount' | 'requested_shs_credit_amount' | 'pricing_config' | 'type'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'first_name'>
    ), finance_application?: Maybe<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'driva_offer_id' | 'finance_partner' | 'partner_application_response' | 'requested_credit_amount' | 'requested_credit_term'>
    )>, custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
    )>, trade_in_plans: Array<(
      { __typename?: 'trade_in_plans' }
      & Pick<Trade_In_Plans, 'id' | 'instant_valuation'>
    )> }
  )> }
);

export type VehicleOrderHowToPayMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  object?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type VehicleOrderHowToPayMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type VehicleOrderHowToPayInsertFinanceApplicationMutationVariables = Exact<{
  financeApplication: Finance_Applications_Insert_Input;
}>;


export type VehicleOrderHowToPayInsertFinanceApplicationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_finance_applications_one?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type VehicleOrderHowToPayGetLoopitSubscriptionUrlMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderHowToPayGetLoopitSubscriptionUrlMutation = (
  { __typename?: 'mutation_root' }
  & { get_loopit_subscription_url: (
    { __typename?: 'LoopitSubscriptionUrl' }
    & Pick<LoopitSubscriptionUrl, 'url'>
  ) }
);

export type VehicleOrderHowToPayGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
  maxFinanceApplicationAge?: Maybe<Scalars['timestamptz']>;
}>;


export type VehicleOrderHowToPayGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name' | 'email'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'requested_shs_credit_amount' | 'pricing_config' | 'deposit_amount' | 'is_commercial' | 'settlement_method' | 'subscription_provider' | 'subscription_plan_id' | 'subscription_term' | 'subscription_km' | 'subscription_liability_protection' | 'type'>
      & { finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
      )>, custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'condition' | 'badge_code' | 'pack_codes'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'id' | 'instant_valuation'>
      )> }
    )> }
  )> }
);

export type VehicleOrderInvoiceGetOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderInvoiceGetOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'invoicing_id' | 'created_at' | 'actual_delivery_date' | 'settlement_method' | 'total_trade_in_value' | 'is_commercial' | 'business_name' | 'business_identifier' | 'pricing_config' | 'delivery_address'>
    & { stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'name' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'packs' | 'condition' | 'odometer' | 'vin' | 'engine_number' | 'colour' | 'registration_number' | 'build_date' | 'compliance_date'>
    )>, finance_application?: Maybe<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term' | 'awarded_credit_amount' | 'awarded_credit_term' | 'finance_partner'>
    )>, user: (
      { __typename?: 'users' }
      & Pick<Users, 'first_name' | 'last_name' | 'email' | 'phone' | 'driving_licence_number' | 'driving_licence_state'>
    ), payments: Array<(
      { __typename?: 'payments' }
      & Pick<Payments, 'code' | 'name' | 'net_amount' | 'received_amount' | 'received_at'>
    )> }
  )> }
);

export type VehicleOrderManageGetOrderSubscriptionVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderManageGetOrderSubscription = (
  { __typename?: 'subscription_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'invoicing_id' | 'stage' | 'type' | 'created_at' | 'total_trade_in_value' | 'pricing_config' | 'deposit_amount' | 'is_commercial' | 'business_name' | 'business_identifier'>
    & { custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_values' | 'configurator'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'name' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'packs' | 'media'>
    )>, user: (
      { __typename?: 'users' }
      & Pick<Users, 'first_name' | 'middle_name' | 'last_name' | 'phone' | 'referral_count' | 'referral_id' | 'driving_licence_number' | 'driving_licence_expiry' | 'driving_licence_state'>
    ), finance_application?: Maybe<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'id' | 'stage' | 'partner_application_url' | 'finance_partner'>
    )> }
  )> }
);

export type VehicleOrderCancelMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type VehicleOrderCancelMutation = (
  { __typename?: 'mutation_root' }
  & { cancel_vehicle_order?: Maybe<(
    { __typename?: 'CancelVehicleOrderOutput' }
    & Pick<CancelVehicleOrderOutput, 'id'>
  )> }
);

export type VehicleOrderCancelGetOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderCancelGetOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'stage' | 'type' | 'invoicing_id'>
    & { stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series'>
    )>, custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series'>
    )> }
  )> }
);

export type VehicleOrderUpdateCustomerSaveMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  vehicleOrder?: Maybe<Vehicle_Orders_Set_Input>;
  userId: Scalars['uuid'];
  user?: Maybe<Users_Set_Input>;
}>;


export type VehicleOrderUpdateCustomerSaveMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )>, update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type VehicleOrderUpdateCustomerGetOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderUpdateCustomerGetOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'stage' | 'delivery_address' | 'is_commercial' | 'business_identifier' | 'business_name'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'first_name' | 'middle_name' | 'last_name' | 'phone' | 'date_of_birth' | 'driving_licence_number' | 'driving_licence_expiry' | 'driving_licence_version' | 'driving_licence_state'>
    ) }
  )> }
);

export type VehicleOrderUpdateOrderGetOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderUpdateOrderGetOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'stage' | 'type'>
    & { custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'id' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'id' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
    )>, vehicle_order_product_variants: Array<(
      { __typename?: 'vehicle_order_product_variants' }
      & { product_variant: (
        { __typename?: 'product_variants' }
        & Pick<Product_Variants, 'id'>
      ) }
    )> }
  )> }
);

export type VehicleOrderPersonalDetailsUpdateUserAndOrderMutationVariables = Exact<{
  userId: Scalars['uuid'];
  vehicleOrderId: Scalars['uuid'];
  userObject?: Maybe<Users_Set_Input>;
  vehicleOrderObject?: Maybe<Vehicle_Orders_Set_Input>;
}>;


export type VehicleOrderPersonalDetailsUpdateUserAndOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )>, update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutation = (
  { __typename?: 'mutation_root' }
  & { get_loopit_subscription_url: (
    { __typename?: 'LoopitSubscriptionUrl' }
    & Pick<LoopitSubscriptionUrl, 'url'>
  ) }
);

export type VehicleOrderPersonalDetailsGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type VehicleOrderPersonalDetailsGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name' | 'date_of_birth' | 'driving_licence_number' | 'driving_licence_expiry' | 'driving_licence_state' | 'driving_licence_version'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'is_commercial' | 'business_identifier' | 'business_name' | 'requested_shs_credit_amount' | 'pricing_config' | 'type' | 'deposit_amount' | 'is_temporary_hold' | 'created_at' | 'settlement_method'>
      & { finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'name' | 'code' | 'media'>
      )>, custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'id' | 'instant_valuation'>
      )> }
    )> }
  )> }
);

export type VehicleOrderReviewPaymentUrlSubscriptionVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  paymentCode: Payment_Codes_Enum;
}>;


export type VehicleOrderReviewPaymentUrlSubscription = (
  { __typename?: 'subscription_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & { payments: Array<(
      { __typename?: 'payments' }
      & Pick<Payments, 'payment_url'>
    )> }
  )> }
);

export type VehicleOrderReviewGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
  depositPaymentCode: Payment_Codes_Enum;
}>;


export type VehicleOrderReviewGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'phone' | 'first_name' | 'last_name' | 'email' | 'created_at'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'registered_owner' | 'total_trade_in_value' | 'stage' | 'is_actewagl_charger_add_on_enquiry' | 'estimated_delivery_date' | 'pricing_config' | 'type' | 'created_at' | 'deposit_amount' | 'is_temporary_hold' | 'settlement_method'>
      & { custom_vehicle?: Maybe<(
        { __typename?: 'custom_vehicles' }
        & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
      )>, stocked_vehicle?: Maybe<(
        { __typename?: 'stocked_vehicles' }
        & Pick<Stocked_Vehicles, 'id' | 'code' | 'name' | 'media' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'estimated_delivery_delay' | 'condition' | 'odometer'>
      )>, trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'id' | 'instant_valuation' | 'year' | 'make' | 'model'>
      )>, payments: Array<(
        { __typename?: 'payments' }
        & Pick<Payments, 'payment_url'>
      )>, finance_application?: Maybe<(
        { __typename?: 'finance_applications' }
        & Pick<Finance_Applications, 'id' | 'finance_partner' | 'requested_credit_amount' | 'requested_credit_term' | 'partner_application_response' | 'driva_offer_id'>
      )> }
    )> }
  )> }
);

export type VehicleOrderStartGetStockedVehicleQueryVariables = Exact<{
  stockedVehicleId: Scalars['uuid'];
}>;


export type VehicleOrderStartGetStockedVehicleQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles_by_pk?: Maybe<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'name' | 'code' | 'media' | 'production_year' | 'make' | 'model' | 'badge' | 'series'>
  )> }
);

export type VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutationVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
}>;


export type VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutation = (
  { __typename?: 'mutation_root' }
  & { update_trade_in_plans?: Maybe<(
    { __typename?: 'trade_in_plans_mutation_response' }
    & Pick<Trade_In_Plans_Mutation_Response, 'affected_rows'>
  )> }
);

export type TradeInConditionUpdateTradeInPlanMutationVariables = Exact<{
  tradeInPlanId: Scalars['uuid'];
  set?: Maybe<Trade_In_Plans_Set_Input>;
}>;


export type TradeInConditionUpdateTradeInPlanMutation = (
  { __typename?: 'mutation_root' }
  & { update_trade_in_plans_by_pk?: Maybe<(
    { __typename?: 'trade_in_plans' }
    & Pick<Trade_In_Plans, 'id'>
  )> }
);

export type VehicleOrderTradeInValuationGetUserQueryVariables = Exact<{
  vehicleOrderId?: Maybe<Scalars['uuid']>;
  hasuraUserId?: Maybe<Scalars['uuid']>;
}>;


export type VehicleOrderTradeInValuationGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id'>
      & { trade_in_plans: Array<(
        { __typename?: 'trade_in_plans' }
        & Pick<Trade_In_Plans, 'id' | 'title' | 'year' | 'make' | 'model' | 'has_instant_trade_in_option' | 'instant_valuation' | 'on_valuation_result_vehicle_order_id' | 'additional_valuations_requested_at'>
      )> }
    )> }
  )> }
);

export type VehicleOrderTradeInInsertTradeInPlanMutationVariables = Exact<{
  object: Trade_In_Plans_Insert_Input;
}>;


export type VehicleOrderTradeInInsertTradeInPlanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_trade_in_plans_one?: Maybe<(
    { __typename?: 'trade_in_plans' }
    & Pick<Trade_In_Plans, 'id'>
  )> }
);

export type VehicleOrderTradeInUpdateTradeInPlanMutationVariables = Exact<{
  tradeInPlanId: Scalars['uuid'];
  tradeInPlan?: Maybe<Trade_In_Plans_Set_Input>;
}>;


export type VehicleOrderTradeInUpdateTradeInPlanMutation = (
  { __typename?: 'mutation_root' }
  & { update_trade_in_plans_by_pk?: Maybe<(
    { __typename?: 'trade_in_plans' }
    & Pick<Trade_In_Plans, 'id'>
  )> }
);

export type VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutationVariables = Exact<{
  trade_in_plan_id: Scalars['uuid'];
  registration_number: Scalars['String'];
  state_of_registration?: Maybe<Scalars['String']>;
}>;


export type VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutation = (
  { __typename?: 'mutation_root' }
  & { get_autograb_vehicle_by_reg_number?: Maybe<(
    { __typename?: 'GetAutoGrabVehicleByRegNumberOutput' }
    & Pick<GetAutoGrabVehicleByRegNumberOutput, 'success'>
  )> }
);

export type VehicleOrderTradeInGetValuationMutationVariables = Exact<{
  tradeInPlanId: Scalars['uuid'];
}>;


export type VehicleOrderTradeInGetValuationMutation = (
  { __typename?: 'mutation_root' }
  & { get_autograb_valuation: (
    { __typename?: 'GetAutoGrabValuationOutput' }
    & Pick<GetAutoGrabValuationOutput, 'success'>
  ) }
);

export type VehicleOrderTradeInGetUserQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  hasuraUserId?: Maybe<Scalars['uuid']>;
  maxAgeTradeInPlan?: Maybe<Scalars['timestamptz']>;
}>;


export type VehicleOrderTradeInGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
    & { trade_in_plans: Array<(
      { __typename?: 'trade_in_plans' }
      & Pick<Trade_In_Plans, 'id' | 'title' | 'instant_valuation' | 'created_at'>
    )> }
  )>, vehicle_orders: Array<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id' | 'phone' | 'pricing_config' | 'deposit_amount' | 'type'>
    & { finance_application?: Maybe<(
      { __typename?: 'finance_applications' }
      & Pick<Finance_Applications, 'requested_credit_amount' | 'requested_credit_term'>
    )>, custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media' | 'configurator_code' | 'configurator_values'>
    )>, stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'name' | 'code' | 'production_year' | 'make' | 'model' | 'badge' | 'series' | 'media'>
    )>, trade_in_plans: Array<(
      { __typename?: 'trade_in_plans' }
      & Pick<Trade_In_Plans, 'id' | 'instant_valuation'>
    )> }
  )> }
);

export type DefaultVehicleListQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type DefaultVehicleListQuery = (
  { __typename?: 'query_root' }
  & { vehicle_lists: Array<(
    { __typename?: 'vehicle_lists' }
    & Pick<Vehicle_Lists, 'code'>
  )> }
);

export type VehicleListGetListQueryVariables = Exact<{
  showroomId?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
}>;


export type VehicleListGetListQuery = (
  { __typename?: 'query_root' }
  & { vehicle_lists: Array<(
    { __typename?: 'vehicle_lists' }
    & Pick<Vehicle_Lists, 'title' | 'description' | 'header_image_url' | 'meta_title' | 'meta_description' | 'where_clause' | 'drop_in_ads' | 'header_text_color' | 'header_size' | 'default_settlement_method'>
    & { filter_fields: Array<(
      { __typename?: 'filter_fields' }
      & Pick<Filter_Fields, 'label' | 'name' | 'options' | 'location'>
    )>, showroom: (
      { __typename?: 'showrooms' }
      & Pick<Showrooms, 'public_settings'>
    ) }
  )> }
);

export type StockedVehicleGetVehicleQueryVariables = Exact<{
  stockedVehicleCode?: Maybe<Scalars['String']>;
  showroomCode?: Maybe<Scalars['String']>;
  pricingCode?: Maybe<Scalars['String']>;
}>;


export type StockedVehicleGetVehicleQuery = (
  { __typename?: 'query_root' }
  & { stocked_vehicles: Array<(
    { __typename?: 'stocked_vehicles' }
    & Pick<Stocked_Vehicles, 'id' | 'name' | 'description' | 'make' | 'model' | 'code' | 'range' | 'colour' | 'packs' | 'badge_code' | 'pack_codes' | 'useable_battery' | 'battery_health' | 'media' | 'seats' | 'odometer' | 'fuel_type' | 'production_year' | 'is_sold' | 'is_reserved' | 'is_reserved_by' | 'is_illustrative_media' | 'condition' | 'estimated_delivery_delay' | 'available_from' | 'features' | 'body_type' | 'transmission' | 'doors' | 'badge' | 'series' | 'battery_type' | 'charge_port_ac' | 'charge_port_dc' | 'charge_port_location' | 'charge_power_ac' | 'charge_power_dc' | 'charge_speed_ac' | 'charge_speed_dc' | 'charge_time_ac' | 'charge_time_dc' | 'stock_id' | 'features_version' | 'additional_features' | 'interior' | 'safety_rating_authority' | 'safety_rating' | 'number_of_airbags' | 'length' | 'width' | 'height' | 'gross_weight' | 'boot_capacity_seats_up' | 'boot_capacity_seats_down' | 'towing_capacity_unbraked' | 'towing_capacity_braked' | 'acceleration_0_100_km' | 'top_speed' | 'torque' | 'drive_type' | 'efficiency' | 'motor_size' | 'mechanical_warranty_years' | 'mechanical_warranty_distance' | 'battery_warranty_years' | 'battery_warranty_distance' | 'accessories_warranty_years' | 'accessories_warranty_distance' | 'included_home_trickle_charger' | 'pricing_config' | 'registration_expiry_date' | 'vin' | 'wheel_name' | 'source_name'>
    & { vehicle_specification?: Maybe<(
      { __typename?: 'vehicle_specifications' }
      & Pick<Vehicle_Specifications, 'id' | 'acceleration' | 'ancap_rating' | 'ancap_year' | 'fuel_type' | 'maximum_speed' | 'electric_engine_top_speed' | 'torque' | 'electric_engine_torque' | 'gear_type' | 'body_style' | 'electric_engine_km_range' | 'engine_type'>
    )>, location?: Maybe<(
      { __typename?: 'locations' }
      & Pick<Locations, 'id' | 'name' | 'address'>
    )> }
  )> }
);

export type StockedVehicleGetUserQueryVariables = Exact<{
  hasuraUserId?: Maybe<Scalars['uuid']>;
}>;


export type StockedVehicleGetUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'first_name' | 'last_name' | 'phone'>
    & { vehicle_orders: Array<(
      { __typename?: 'vehicle_orders' }
      & Pick<Vehicle_Orders, 'id' | 'last_step' | 'stocked_vehicle_id'>
    )> }
  )> }
);

export type GetShowroomAppActionsServerSideQueryVariables = Exact<{
  domain?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetShowroomAppActionsServerSideQuery = (
  { __typename?: 'query_root' }
  & { showrooms: Array<(
    { __typename?: 'showrooms' }
    & { showroom_apps: Array<(
      { __typename?: 'showroom_apps' }
      & { installed_version?: Maybe<(
        { __typename?: 'app_versions' }
        & { app_actions: Array<(
          { __typename?: 'app_actions' }
          & Pick<App_Actions, 'type' | 'config'>
        )> }
      )>, inputs: Array<(
        { __typename?: 'showroom_apps_inputs' }
        & Pick<Showroom_Apps_Inputs, 'name' | 'value'>
      )> }
    )> }
  )> }
);

export type GetShowroomQueryVariables = Exact<{
  domain?: Maybe<Scalars['String']>;
}>;


export type GetShowroomQuery = (
  { __typename?: 'query_root' }
  & { showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id' | 'name' | 'code' | 'domain' | 'phone' | 'email' | 'address' | 'https_enabled' | 'login_methods' | 'default_pricing_code' | 'public_settings'>
    & { showroom_pricing_codes: Array<(
      { __typename?: 'showroom_pricing_codes' }
      & { pricing_code: (
        { __typename?: 'pricing_codes' }
        & Pick<Pricing_Codes, 'code' | 'country' | 'region' | 'currency' | 'short_name'>
      ) }
    )> }
  )> }
);

export type GetShowroomSettingsByIdClientSideQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetShowroomSettingsByIdClientSideQuery = (
  { __typename?: 'query_root' }
  & { showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id'>
  )> }
);

export type GetShowroomSettingsServerSideQueryVariables = Exact<{
  domain?: Maybe<Scalars['String']>;
}>;


export type GetShowroomSettingsServerSideQuery = (
  { __typename?: 'query_root' }
  & { showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id'>
  )> }
);

export type GetShowroomSettingsClientSideQueryVariables = Exact<{
  domain?: Maybe<Scalars['String']>;
}>;


export type GetShowroomSettingsClientSideQuery = (
  { __typename?: 'query_root' }
  & { showrooms: Array<(
    { __typename?: 'showrooms' }
    & Pick<Showrooms, 'id'>
  )> }
);

export type UseDefaultVehicleListQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type UseDefaultVehicleListQuery = (
  { __typename?: 'query_root' }
  & { vehicle_lists: Array<(
    { __typename?: 'vehicle_lists' }
    & Pick<Vehicle_Lists, 'path' | 'is_default'>
  )> }
);

export type SetLastFinanceApplicationStepMutationVariables = Exact<{
  financeApplicationId: Scalars['uuid'];
  lastStep?: Maybe<Scalars['String']>;
}>;


export type SetLastFinanceApplicationStepMutation = (
  { __typename?: 'mutation_root' }
  & { update_finance_applications_by_pk?: Maybe<(
    { __typename?: 'finance_applications' }
    & Pick<Finance_Applications, 'id'>
  )> }
);

export type SetLastTradeInPlanStepMutationVariables = Exact<{
  tradeInPlanId: Scalars['uuid'];
  lastStep?: Maybe<Scalars['String']>;
}>;


export type SetLastTradeInPlanStepMutation = (
  { __typename?: 'mutation_root' }
  & { update_trade_in_plans_by_pk?: Maybe<(
    { __typename?: 'trade_in_plans' }
    & Pick<Trade_In_Plans, 'id'>
  )> }
);

export type SetLastVehicleOrderStepMutationVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
  lastStep?: Maybe<Scalars['String']>;
}>;


export type SetLastVehicleOrderStepMutation = (
  { __typename?: 'mutation_root' }
  & { update_vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'id'>
  )> }
);

export type UseVehicleOrderStepsGetAppActionsQueryVariables = Exact<{
  showroomId: Scalars['uuid'];
}>;


export type UseVehicleOrderStepsGetAppActionsQuery = (
  { __typename?: 'query_root' }
  & { app_actions: Array<(
    { __typename?: 'app_actions' }
    & Pick<App_Actions, 'type' | 'config'>
    & { app: (
      { __typename?: 'apps' }
      & Pick<Apps, 'code'>
    ) }
  )> }
);

export type UseVehicleOrderStepsGetVehicleOrderQueryVariables = Exact<{
  vehicleOrderId: Scalars['uuid'];
}>;


export type UseVehicleOrderStepsGetVehicleOrderQuery = (
  { __typename?: 'query_root' }
  & { vehicle_orders_by_pk?: Maybe<(
    { __typename?: 'vehicle_orders' }
    & Pick<Vehicle_Orders, 'pricing_config' | 'type'>
    & { stocked_vehicle?: Maybe<(
      { __typename?: 'stocked_vehicles' }
      & Pick<Stocked_Vehicles, 'condition' | 'make' | 'fuel_type'>
    )>, custom_vehicle?: Maybe<(
      { __typename?: 'custom_vehicles' }
      & Pick<Custom_Vehicles, 'configurator_code' | 'configurator_values'>
    )>, vehicle_order_product_variants: Array<(
      { __typename?: 'vehicle_order_product_variants' }
      & { product_variant: (
        { __typename?: 'product_variants' }
        & Pick<Product_Variants, 'id'>
      ) }
    )> }
  )> }
);

export const SimilarStockedVehicleFragmentDoc = gql`
    fragment SimilarStockedVehicle on stocked_vehicles {
  id
  name
  code
  pricing_config(path: $pricingCode)
  make
  model
  badge
  interior
  media
  production_year
  odometer
  is_reserved
  condition
  is_sold
  useable_battery
  body_type
  estimated_delivery_delay
  available_from
  odometer
  packs
  wheel_name
  interior
  colour
}
    `;
export const VehicleFragmentDoc = gql`
    fragment Vehicle on stocked_vehicles {
  id
  name
  make
  body_type
  model
  badge
  code
  range
  pricing_config(path: $pricingCode)
  media
  production_year
  odometer
  is_reserved
  condition
  is_sold
  useable_battery
  estimated_delivery_delay
  available_from
  colour
  fuel_type
}
    `;
export const Product_GroupFragmentDoc = gql`
    fragment product_group on product_groups {
  id
  name
  code
  products(where: {is_listed: {_eq: true}}) {
    id
    name
    code
    body
    description
    media
    variant_label
    product_variants {
      id
      name
      gross_price
      order
    }
  }
}
    `;
export const VehicleOrderFragmentFragmentDoc = gql`
    fragment VehicleOrderFragment on vehicle_orders {
  id
  stage
  last_step
  type
  created_at
  deposit_amount
  invoicing_id
  payments {
    received_at
  }
  custom_vehicle {
    production_year
    make
    model
    badge
    series
    media
  }
  stocked_vehicle {
    code
    name
    production_year
    make
    model
    badge
    series
    media
    is_published
  }
}
    `;
export const EvOptionFragmentDoc = gql`
    fragment EvOption on known_vehicles {
  id
  code
  production_year
  make
  model
  badge
  series
  body_type
  drive_away_price
  energy_consumption_wh_per_100km
  fuel_lifecycle_co2_grams_per_km
  available_in_au_now
}
    `;
export const ArticleFragmentFragmentDoc = gql`
    fragment ArticleFragment on articles {
  id
  created_at
  description
  reading_time
  featured_image
  name
  slug
  tags
}
    `;
export const VehiclesPageMakesAndModelsDocument = gql`
    query VehiclesPageMakesAndModels($showroomId: uuid!) {
  makes: stocked_vehicles(
    distinct_on: make
    order_by: {make: asc}
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    make
  }
  models: stocked_vehicles(
    distinct_on: model
    order_by: {model: asc}
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    make
    model
  }
  badges: stocked_vehicles(
    distinct_on: badge
    order_by: {badge: asc}
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    badge
  }
  years: stocked_vehicles(
    distinct_on: production_year
    order_by: {production_year: desc}
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    production_year
  }
}
    `;
export type VehiclesPageMakesAndModelsQueryResult = Apollo.QueryResult<VehiclesPageMakesAndModelsQuery, VehiclesPageMakesAndModelsQueryVariables>;
export const CalculatorGetUserDocument = gql`
    query CalculatorGetUser {
  users {
    id
    email
  }
}
    `;
export type CalculatorGetUserQueryResult = Apollo.QueryResult<CalculatorGetUserQuery, CalculatorGetUserQueryVariables>;
export const GetMakesDocument = gql`
    query GetMakes {
  known_vehicles(distinct_on: make, order_by: {make: asc}) {
    make
  }
}
    `;
export type GetMakesQueryResult = Apollo.QueryResult<GetMakesQuery, GetMakesQueryVariables>;
export const GetModelsDocument = gql`
    query GetModels($make: String) {
  known_vehicles(
    where: {make: {_eq: $make}}
    distinct_on: model
    order_by: {model: asc}
  ) {
    model
  }
}
    `;
export type GetModelsQueryResult = Apollo.QueryResult<GetModelsQuery, GetModelsQueryVariables>;
export const GetReleaseYearsDocument = gql`
    query GetReleaseYears($make: String, $model: String) {
  known_vehicles(
    where: {make: {_eq: $make}, model: {_eq: $model}}
    distinct_on: release_year
    order_by: {release_year: asc}
  ) {
    id
    release_year
  }
}
    `;
export type GetReleaseYearsQueryResult = Apollo.QueryResult<GetReleaseYearsQuery, GetReleaseYearsQueryVariables>;
export const InsertCalculatorSubmissionDocument = gql`
    mutation insertCalculatorSubmission($object: calculator_submission_insert_input!) {
  insert_calculator_submission_one(object: $object) {
    id
  }
}
    `;
export type InsertCalculatorSubmissionMutationFn = Apollo.MutationFunction<InsertCalculatorSubmissionMutation, InsertCalculatorSubmissionMutationVariables>;
export type InsertCalculatorSubmissionMutationResult = Apollo.MutationResult<InsertCalculatorSubmissionMutation>;
export type InsertCalculatorSubmissionMutationOptions = Apollo.BaseMutationOptions<InsertCalculatorSubmissionMutation, InsertCalculatorSubmissionMutationVariables>;
export const UpdateCustomVehicleOrderDocument = gql`
    mutation UpdateCustomVehicleOrder($customVehicleId: uuid!, $customVehicle: custom_vehicles_set_input!) {
  update_custom_vehicles_by_pk(
    pk_columns: {id: $customVehicleId}
    _set: $customVehicle
  ) {
    id
  }
}
    `;
export type UpdateCustomVehicleOrderMutationFn = Apollo.MutationFunction<UpdateCustomVehicleOrderMutation, UpdateCustomVehicleOrderMutationVariables>;
export type UpdateCustomVehicleOrderMutationResult = Apollo.MutationResult<UpdateCustomVehicleOrderMutation>;
export type UpdateCustomVehicleOrderMutationOptions = Apollo.BaseMutationOptions<UpdateCustomVehicleOrderMutation, UpdateCustomVehicleOrderMutationVariables>;
export const InsertAssetsDocument = gql`
    mutation InsertAssets($assets: [assets_insert_input!]!) {
  insert_assets(objects: $assets) {
    returning {
      id
    }
  }
}
    `;
export type InsertAssetsMutationFn = Apollo.MutationFunction<InsertAssetsMutation, InsertAssetsMutationVariables>;
export type InsertAssetsMutationResult = Apollo.MutationResult<InsertAssetsMutation>;
export type InsertAssetsMutationOptions = Apollo.BaseMutationOptions<InsertAssetsMutation, InsertAssetsMutationVariables>;
export const DeleteAssetsDocument = gql`
    mutation DeleteAssets($customVehicleId: uuid!, $assetIdsToKeep: [uuid!]!) {
  delete_assets(
    where: {custom_vehicle_id: {_eq: $customVehicleId}, id: {_nin: $assetIdsToKeep}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeleteAssetsMutationFn = Apollo.MutationFunction<DeleteAssetsMutation, DeleteAssetsMutationVariables>;
export type DeleteAssetsMutationResult = Apollo.MutationResult<DeleteAssetsMutation>;
export type DeleteAssetsMutationOptions = Apollo.BaseMutationOptions<DeleteAssetsMutation, DeleteAssetsMutationVariables>;
export const GetSimilarStockedVehiclesDocument = gql`
    query GetSimilarStockedVehicles($tier1: stocked_vehicles_bool_exp!, $tier2: stocked_vehicles_bool_exp!, $tier3: stocked_vehicles_bool_exp!, $pricingCode: String) {
  tier1: stocked_vehicles(where: $tier1, limit: 3) {
    ...SimilarStockedVehicle
  }
  tier2: stocked_vehicles(where: $tier2, limit: 3) {
    ...SimilarStockedVehicle
  }
  tier3: stocked_vehicles(where: $tier3, limit: 3) {
    ...SimilarStockedVehicle
  }
}
    ${SimilarStockedVehicleFragmentDoc}`;
export type GetSimilarStockedVehiclesQueryResult = Apollo.QueryResult<GetSimilarStockedVehiclesQuery, GetSimilarStockedVehiclesQueryVariables>;
export const GetSimilarStockedVehiclesByAiDocument = gql`
    query GetSimilarStockedVehiclesByAi($vehicleIds: [uuid!], $pricingCode: String) {
  stocked_vehicles(where: {id: {_in: $vehicleIds}}) {
    id
    name
    code
    pricing_config(path: $pricingCode)
    make
    model
    badge
    interior
    media
    production_year
    odometer
    is_reserved
    condition
    is_sold
    useable_battery
    body_type
    estimated_delivery_delay
    available_from
    odometer
    packs
    wheel_name
    interior
    colour
  }
}
    `;
export type GetSimilarStockedVehiclesByAiQueryResult = Apollo.QueryResult<GetSimilarStockedVehiclesByAiQuery, GetSimilarStockedVehiclesByAiQueryVariables>;
export const DynamicFormLocationsDocument = gql`
    query DynamicFormLocations($showroomId: uuid!) {
  locations(where: {showroom_id: {_eq: $showroomId}}) {
    id
    name
    address
  }
}
    `;
export type DynamicFormLocationsQueryResult = Apollo.QueryResult<DynamicFormLocationsQuery, DynamicFormLocationsQueryVariables>;
export const DynamicFormGetFormDocument = gql`
    query DynamicFormGetForm($formId: uuid!) {
  forms_by_pk(id: $formId) {
    id
    name
    form_info
    on_success_message
    button_text
    signup_user_on_submit
    form_fields {
      name
      type
      label
      help_text
      options
      order
      rules
      default_value
      col
      options_layout
      property
    }
  }
}
    `;
export type DynamicFormGetFormQueryResult = Apollo.QueryResult<DynamicFormGetFormQuery, DynamicFormGetFormQueryVariables>;
export const EnergyCalculatorGetKnownVehicleDocument = gql`
    query EnergyCalculatorGetKnownVehicle($search: String) {
  search_known_vehicles(args: {search: $search}, limit: 1) {
    energy_consumption_wh_per_100km
  }
}
    `;
export type EnergyCalculatorGetKnownVehicleQueryResult = Apollo.QueryResult<EnergyCalculatorGetKnownVehicleQuery, EnergyCalculatorGetKnownVehicleQueryVariables>;
export const FeaturedVehiclesDocument = gql`
    query FeaturedVehicles($showroomCode: String, $pricingCode: String, $limit: Int) {
  stocked_vehicles(
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom: {code: {_eq: $showroomCode}}}}
    order_by: {featured_order: asc, is_sold: asc}
    limit: $limit
  ) {
    id
    name
    make
    model
    badge
    code
    range
    pricing_config(path: $pricingCode)
    media
    production_year
    odometer
    is_reserved
    condition
    is_sold
    useable_battery
    estimated_delivery_delay
    available_from
  }
  showrooms(where: {code: {_eq: $showroomCode}}, limit: $limit) {
    https_enabled
    domain
  }
}
    `;
export type FeaturedVehiclesQueryResult = Apollo.QueryResult<FeaturedVehiclesQuery, FeaturedVehiclesQueryVariables>;
export const FileUploadGetSignedUploadDocument = gql`
    mutation FileUploadGetSignedUpload($isPublic: Boolean) {
  media_get_signed_upload(is_public: $isPublic) {
    base_url
    post_url
    post_fields
  }
}
    `;
export type FileUploadGetSignedUploadMutationFn = Apollo.MutationFunction<FileUploadGetSignedUploadMutation, FileUploadGetSignedUploadMutationVariables>;
export type FileUploadGetSignedUploadMutationResult = Apollo.MutationResult<FileUploadGetSignedUploadMutation>;
export type FileUploadGetSignedUploadMutationOptions = Apollo.BaseMutationOptions<FileUploadGetSignedUploadMutation, FileUploadGetSignedUploadMutationVariables>;
export const FileUploadGetSignedDownloadDocument = gql`
    mutation FileUploadGetSignedDownload($pathname: String!) {
  media_get_signed_download(pathname: $pathname) {
    url
  }
}
    `;
export type FileUploadGetSignedDownloadMutationFn = Apollo.MutationFunction<FileUploadGetSignedDownloadMutation, FileUploadGetSignedDownloadMutationVariables>;
export type FileUploadGetSignedDownloadMutationResult = Apollo.MutationResult<FileUploadGetSignedDownloadMutation>;
export type FileUploadGetSignedDownloadMutationOptions = Apollo.BaseMutationOptions<FileUploadGetSignedDownloadMutation, FileUploadGetSignedDownloadMutationVariables>;
export const InterestsDocument = gql`
    query Interests($userId: uuid, $showroomId: uuid) {
  interests(
    where: {_or: [{showroom_id: {_is_null: true}}, {showroom_id: {_eq: $showroomId}}]}
  ) {
    code
    label
  }
  user_interests(where: {user_id: {_eq: $userId}}) {
    interest {
      code
    }
  }
}
    `;
export type InterestsQueryResult = Apollo.QueryResult<InterestsQuery, InterestsQueryVariables>;
export const MatchToolInsertMatchSubmissionDocument = gql`
    mutation MatchToolInsertMatchSubmission($object: match_submissions_insert_input!) {
  insert_match_submissions_one(object: $object) {
    id
  }
}
    `;
export type MatchToolInsertMatchSubmissionMutationFn = Apollo.MutationFunction<MatchToolInsertMatchSubmissionMutation, MatchToolInsertMatchSubmissionMutationVariables>;
export type MatchToolInsertMatchSubmissionMutationResult = Apollo.MutationResult<MatchToolInsertMatchSubmissionMutation>;
export type MatchToolInsertMatchSubmissionMutationOptions = Apollo.BaseMutationOptions<MatchToolInsertMatchSubmissionMutation, MatchToolInsertMatchSubmissionMutationVariables>;
export const GetOrderPreviewDocument = gql`
    query GetOrderPreview($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    type
    is_temporary_hold
    created_at
    requested_shs_credit_amount
    settlement_method
    deposit_amount
    trade_in_plans {
      instant_valuation
    }
    finance_application {
      requested_credit_term
      requested_credit_amount
    }
    pricing_config
    stocked_vehicle {
      name
      code
      make
      model
      badge
      series
      production_year
      media
    }
    custom_vehicle {
      make
      model
      badge
      series
      production_year
      media
      configurator_code
      configurator_values
    }
  }
}
    `;
export type GetOrderPreviewQueryResult = Apollo.QueryResult<GetOrderPreviewQuery, GetOrderPreviewQueryVariables>;
export const TestDrivePageGetDefaultVehicleListUrlDocument = gql`
    query TestDrivePageGetDefaultVehicleListUrl($showroomId: uuid!) {
  vehicle_lists(where: {showroom_id: {_eq: $showroomId}, is_default: {_eq: true}}) {
    path
  }
}
    `;
export type TestDrivePageGetDefaultVehicleListUrlQueryResult = Apollo.QueryResult<TestDrivePageGetDefaultVehicleListUrlQuery, TestDrivePageGetDefaultVehicleListUrlQueryVariables>;
export const SimilarVehiclesDocument = gql`
    query SimilarVehicles($id: uuid, $bodyType: String, $make: String, $model: String, $rangeLower: numeric, $rangeUpper: numeric, $showroomId: uuid, $pricingCode: String) {
  sameMakeModelVehicles: stocked_vehicles(
    where: {id: {_neq: $id}, is_listed: {_eq: true}, make: {_eq: $make}, model: {_eq: $model}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
    order_by: {featured_order: asc, is_sold: asc}
    limit: 4
  ) {
    ...Vehicle
  }
  sameBodyTypeOrRangeVehicles: stocked_vehicles(
    where: {id: {_neq: $id}, is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}, _or: [{body_type: {_eq: $bodyType}}, {range: {_gte: $rangeLower, _lte: $rangeUpper}}]}
    order_by: {featured_order: asc, is_sold: asc}
    limit: 4
  ) {
    ...Vehicle
  }
}
    ${VehicleFragmentDoc}`;
export type SimilarVehiclesQueryResult = Apollo.QueryResult<SimilarVehiclesQuery, SimilarVehiclesQueryVariables>;
export const TestDriveContactDetailsGetLocationDocument = gql`
    query TestDriveContactDetailsGetLocation($locationId: uuid!) {
  locations_by_pk(id: $locationId) {
    name
    address
  }
}
    `;
export type TestDriveContactDetailsGetLocationQueryResult = Apollo.QueryResult<TestDriveContactDetailsGetLocationQuery, TestDriveContactDetailsGetLocationQueryVariables>;
export const TestDriveContactDetailsGetUserDocument = gql`
    query TestDriveContactDetailsGetUser($userId: uuid!) {
  users_by_pk(id: $userId) {
    id
    first_name
    last_name
    email
    phone
  }
}
    `;
export type TestDriveContactDetailsGetUserQueryResult = Apollo.QueryResult<TestDriveContactDetailsGetUserQuery, TestDriveContactDetailsGetUserQueryVariables>;
export const TestDrivePageGetSummaryDocument = gql`
    query TestDrivePageGetSummary($locationId: uuid!, $vehicleId: uuid!) {
  stocked_vehicles_by_pk(id: $vehicleId) {
    production_year
    make
    model
    badge
    series
  }
  locations_by_pk(id: $locationId) {
    name
    address
  }
}
    `;
export type TestDrivePageGetSummaryQueryResult = Apollo.QueryResult<TestDrivePageGetSummaryQuery, TestDrivePageGetSummaryQueryVariables>;
export const TestDrivePageUpdateUserDocument = gql`
    mutation TestDrivePageUpdateUser($id: uuid!, $object: users_set_input!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
    `;
export type TestDrivePageUpdateUserMutationFn = Apollo.MutationFunction<TestDrivePageUpdateUserMutation, TestDrivePageUpdateUserMutationVariables>;
export type TestDrivePageUpdateUserMutationResult = Apollo.MutationResult<TestDrivePageUpdateUserMutation>;
export type TestDrivePageUpdateUserMutationOptions = Apollo.BaseMutationOptions<TestDrivePageUpdateUserMutation, TestDrivePageUpdateUserMutationVariables>;
export const TestDrivePageInsertTestDriveDocument = gql`
    mutation TestDrivePageInsertTestDrive($objects: [test_drives_insert_input!]!) {
  insert_test_drives(objects: $objects) {
    affected_rows
  }
}
    `;
export type TestDrivePageInsertTestDriveMutationFn = Apollo.MutationFunction<TestDrivePageInsertTestDriveMutation, TestDrivePageInsertTestDriveMutationVariables>;
export type TestDrivePageInsertTestDriveMutationResult = Apollo.MutationResult<TestDrivePageInsertTestDriveMutation>;
export type TestDrivePageInsertTestDriveMutationOptions = Apollo.BaseMutationOptions<TestDrivePageInsertTestDriveMutation, TestDrivePageInsertTestDriveMutationVariables>;
export const GetTestDriveLocationDocument = gql`
    query GetTestDriveLocation($id: uuid!) {
  locations_by_pk(id: $id) {
    name
  }
}
    `;
export type GetTestDriveLocationQueryResult = Apollo.QueryResult<GetTestDriveLocationQuery, GetTestDriveLocationQueryVariables>;
export const TestDrivePageGetVehiclesDocument = gql`
    query TestDrivePageGetVehicles($locationId: uuid!) {
  stocked_vehicles(
    where: {location_id: {_eq: $locationId}, is_available_for_test_drives: {_eq: true}}
    order_by: {featured_order: asc}
  ) {
    id
    production_year
    make
    model
    badge
    packs
    media
  }
}
    `;
export type TestDrivePageGetVehiclesQueryResult = Apollo.QueryResult<TestDrivePageGetVehiclesQuery, TestDrivePageGetVehiclesQueryVariables>;
export const TestDrivePageGetLocationsDocument = gql`
    query TestDrivePageGetLocations($showroomId: uuid!) {
  locations(
    where: {showroom_id: {_eq: $showroomId}, is_available_for_test_drives: {_eq: true}}
    order_by: {name: asc}
  ) {
    id
    name
    address
  }
}
    `;
export type TestDrivePageGetLocationsQueryResult = Apollo.QueryResult<TestDrivePageGetLocationsQuery, TestDrivePageGetLocationsQueryVariables>;
export const TextEditorGetSignedUploadDocument = gql`
    mutation TextEditorGetSignedUpload {
  media_get_signed_upload(is_public: true) {
    base_url
    post_url
    post_fields
  }
}
    `;
export type TextEditorGetSignedUploadMutationFn = Apollo.MutationFunction<TextEditorGetSignedUploadMutation, TextEditorGetSignedUploadMutationVariables>;
export type TextEditorGetSignedUploadMutationResult = Apollo.MutationResult<TextEditorGetSignedUploadMutation>;
export type TextEditorGetSignedUploadMutationOptions = Apollo.BaseMutationOptions<TextEditorGetSignedUploadMutation, TextEditorGetSignedUploadMutationVariables>;
export const GetFilterOptionsDocument = gql`
    query GetFilterOptions($where: stocked_vehicles_bool_exp) {
  production_year: stocked_vehicles(
    distinct_on: production_year
    order_by: {production_year: asc}
    where: $where
  ) {
    production_year
  }
  model: stocked_vehicles(
    distinct_on: model
    order_by: {model: asc}
    where: $where
  ) {
    model
  }
  badge: stocked_vehicles(
    distinct_on: badge
    order_by: {badge: asc}
    where: $where
  ) {
    badge
  }
  colour: stocked_vehicles(distinct_on: colour_code, where: $where) {
    colour
    colour_code
  }
  interior: stocked_vehicles(distinct_on: interior_code, where: $where) {
    interior
    interior_code
  }
  fuel_type: vehicle_specifications(
    distinct_on: fuel_type
    where: {stocked_vehicles: $where}
  ) {
    fuel_type
  }
}
    `;
export type GetFilterOptionsQueryResult = Apollo.QueryResult<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>;
export const AddVehiclesToWishlistDocument = gql`
    mutation AddVehiclesToWishlist($object: wish_list_insert_input!) {
  insert_wish_list_one(object: $object) {
    id
  }
}
    `;
export type AddVehiclesToWishlistMutationFn = Apollo.MutationFunction<AddVehiclesToWishlistMutation, AddVehiclesToWishlistMutationVariables>;
export type AddVehiclesToWishlistMutationResult = Apollo.MutationResult<AddVehiclesToWishlistMutation>;
export type AddVehiclesToWishlistMutationOptions = Apollo.BaseMutationOptions<AddVehiclesToWishlistMutation, AddVehiclesToWishlistMutationVariables>;
export const RemoveVehicleFromWishlistDocument = gql`
    mutation RemoveVehicleFromWishlist($userId: uuid!, $stockedVehicleId: uuid!) {
  delete_wish_list(
    where: {stocked_vehicle_id: {_eq: $stockedVehicleId}, user_id: {_eq: $userId}}
  ) {
    affected_rows
  }
}
    `;
export type RemoveVehicleFromWishlistMutationFn = Apollo.MutationFunction<RemoveVehicleFromWishlistMutation, RemoveVehicleFromWishlistMutationVariables>;
export type RemoveVehicleFromWishlistMutationResult = Apollo.MutationResult<RemoveVehicleFromWishlistMutation>;
export type RemoveVehicleFromWishlistMutationOptions = Apollo.BaseMutationOptions<RemoveVehicleFromWishlistMutation, RemoveVehicleFromWishlistMutationVariables>;
export const VehiclesGetWishListDocument = gql`
    query VehiclesGetWishList($userId: uuid) {
  wish_list(
    where: {user_id: {_eq: $userId}, stocked_vehicle_id: {_is_null: false}}
  ) {
    stocked_vehicle_id
  }
}
    `;
export type VehiclesGetWishListQueryResult = Apollo.QueryResult<VehiclesGetWishListQuery, VehiclesGetWishListQueryVariables>;
export const VehiclesGetStockedVehiclesDocument = gql`
    query VehiclesGetStockedVehicles($search: String, $where: stocked_vehicles_bool_exp, $pricingCode: String, $sort: [stocked_vehicles_order_by!]) {
  search_stocked_vehicles(args: {search: $search}, where: $where, order_by: $sort) {
    id
    name
    code
    range
    pricing_config(path: $pricingCode)
    make
    model
    is_reserved_until
    badge
    badge_code
    media
    production_year
    odometer
    condition
    is_sold
    packs
    pack_codes
    useable_battery
    body_type
    estimated_delivery_delay
    available_from
    featured_order
    colour
    series
    fuel_type
  }
}
    `;
export type VehiclesGetStockedVehiclesQueryResult = Apollo.QueryResult<VehiclesGetStockedVehiclesQuery, VehiclesGetStockedVehiclesQueryVariables>;
export const CancelVehicleOrderDocument = gql`
    mutation CancelVehicleOrder($id: uuid!) {
  cancel_vehicle_order(id: $id) {
    id
  }
}
    `;
export type CancelVehicleOrderMutationFn = Apollo.MutationFunction<CancelVehicleOrderMutation, CancelVehicleOrderMutationVariables>;
export type CancelVehicleOrderMutationResult = Apollo.MutationResult<CancelVehicleOrderMutation>;
export type CancelVehicleOrderMutationOptions = Apollo.BaseMutationOptions<CancelVehicleOrderMutation, CancelVehicleOrderMutationVariables>;
export const GetDriverDataDocument = gql`
    query GetDriverData($orderId: uuid!) {
  vehicle_orders_by_pk(id: $orderId) {
    id
    stage
    delivery_address
    is_commercial
    business_identifier
    business_name
    user {
      id
      first_name
      middle_name
      last_name
      phone
      email
      date_of_birth
      driving_licence_number
      driving_licence_expiry
      driving_licence_version
      driving_licence_state
    }
  }
}
    `;
export type GetDriverDataQueryResult = Apollo.QueryResult<GetDriverDataQuery, GetDriverDataQueryVariables>;
export const UpdateDriverDataVehicleOrderDocument = gql`
    mutation UpdateDriverDataVehicleOrder($vehicleOrderId: uuid!, $vehicleOrder: vehicle_orders_set_input, $userId: uuid!, $user: users_set_input) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: $vehicleOrder
  ) {
    id
  }
  update_users_by_pk(pk_columns: {id: $userId}, _set: $user) {
    id
  }
}
    `;
export type UpdateDriverDataVehicleOrderMutationFn = Apollo.MutationFunction<UpdateDriverDataVehicleOrderMutation, UpdateDriverDataVehicleOrderMutationVariables>;
export type UpdateDriverDataVehicleOrderMutationResult = Apollo.MutationResult<UpdateDriverDataVehicleOrderMutation>;
export type UpdateDriverDataVehicleOrderMutationOptions = Apollo.BaseMutationOptions<UpdateDriverDataVehicleOrderMutation, UpdateDriverDataVehicleOrderMutationVariables>;
export const GetProductsDocument = gql`
    query GetProducts($showroomId: uuid!) {
  product_groups(where: {showroom_id: {_eq: $showroomId}, code: {_eq: "extras"}}) {
    ...product_group
    product_groups {
      ...product_group
    }
  }
}
    ${Product_GroupFragmentDoc}`;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const AdminArticleFormGetTagsAndShowroomsDocument = gql`
    query AdminArticleFormGetTagsAndShowrooms {
  tags: articles(distinct_on: [tags]) {
    tags
  }
  showrooms(order_by: {name: asc}) {
    id
    name
  }
}
    `;
export type AdminArticleFormGetTagsAndShowroomsQueryResult = Apollo.QueryResult<AdminArticleFormGetTagsAndShowroomsQuery, AdminArticleFormGetTagsAndShowroomsQueryVariables>;
export const ProductGridGetProductsDocument = gql`
    query ProductGridGetProducts($showroomId: uuid!) {
  products(where: {showroom_id: {_eq: $showroomId}, is_listed: {_eq: true}}) {
    id
    name
    description
    media
    variant_label
    product_group_id
    product_variants(order_by: {order: asc}) {
      id
      name
      gross_price
      description
    }
  }
  product_groups(where: {showroom_id: {_eq: $showroomId}}) {
    id
    name
  }
}
    `;
export type ProductGridGetProductsQueryResult = Apollo.QueryResult<ProductGridGetProductsQuery, ProductGridGetProductsQueryVariables>;
export const DynamicPagePathGetPageDocument = gql`
    query DynamicPagePathGetPage($showroomId: uuid!, $path: String!) {
  pages(
    where: {showroom_id: {_eq: $showroomId}, path: {_eq: $path}, is_published: {_eq: true}}
  ) {
    id
    path
    name
    meta_title
    meta_description
    sections
    html
    version
    created_at
    updated_at
  }
}
    `;
export type DynamicPagePathGetPageQueryResult = Apollo.QueryResult<DynamicPagePathGetPageQuery, DynamicPagePathGetPageQueryVariables>;
export const AccountGetUserDocument = gql`
    query AccountGetUser($hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    last_name
    phone
    email
    referral_id
    referral_count
  }
}
    `;
export type AccountGetUserQueryResult = Apollo.QueryResult<AccountGetUserQuery, AccountGetUserQueryVariables>;
export const GetLatestDepositPaidOrderDocument = gql`
    query GetLatestDepositPaidOrder($userId: uuid!, $showroomId: uuid!) {
  vehicle_orders(
    where: {user_id: {_eq: $userId}, stage: {_neq: "cancelled"}, payments: {received_at: {_is_null: false}}, showroom_id: {_eq: $showroomId}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    ...VehicleOrderFragment
  }
}
    ${VehicleOrderFragmentFragmentDoc}`;
export type GetLatestDepositPaidOrderQueryResult = Apollo.QueryResult<GetLatestDepositPaidOrderQuery, GetLatestDepositPaidOrderQueryVariables>;
export const GetLatestIncompleteOrderDocument = gql`
    query GetLatestIncompleteOrder($userId: uuid!, $showroomId: uuid!) {
  vehicle_orders(
    where: {user_id: {_eq: $userId}, stage: {_neq: "cancelled"}, payments: {received_at: {_is_null: true}}, showroom_id: {_eq: $showroomId}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    updated_at
    ...VehicleOrderFragment
  }
}
    ${VehicleOrderFragmentFragmentDoc}`;
export type GetLatestIncompleteOrderQueryResult = Apollo.QueryResult<GetLatestIncompleteOrderQuery, GetLatestIncompleteOrderQueryVariables>;
export const GetTrustedPartnersDocument = gql`
    query GetTrustedPartners($showroomId: uuid!) {
  partners(where: {partner_showroom: {showroom_id: {_eq: $showroomId}}}, limit: 5) {
    id
    logo
    name
  }
}
    `;
export type GetTrustedPartnersQueryResult = Apollo.QueryResult<GetTrustedPartnersQuery, GetTrustedPartnersQueryVariables>;
export const AccountGetShowroomPromotionsDocument = gql`
    query AccountGetShowroomPromotions($showroomId: uuid!) {
  promotions(where: {showrooms_promotions: {showroom_id: {_eq: $showroomId}}}) {
    id
    intro
    logo
    name
    background_image
    title
    showrooms_promotions {
      start_date
      end_date
    }
  }
}
    `;
export type AccountGetShowroomPromotionsQueryResult = Apollo.QueryResult<AccountGetShowroomPromotionsQuery, AccountGetShowroomPromotionsQueryVariables>;
export const GetVehicleOrderDataDocument = gql`
    subscription GetVehicleOrderData($orderId: uuid!) {
  vehicle_orders_by_pk(id: $orderId) {
    id
    invoicing_id
    stage
    type
    created_at
    total_trade_in_value
    pricing_config
    deposit_amount
    is_commercial
    business_name
    business_identifier
    vehicle_order_product_variants {
      product_variant_id
    }
    custom_vehicle {
      production_year
      make
      model
      badge
      series
      media
      configurator_values
      configurator_code
      configurator
    }
    stocked_vehicle {
      name
      production_year
      make
      model
      badge
      series
      packs
      media
    }
    user {
      first_name
      middle_name
      last_name
      phone
      referral_count
      referral_id
      driving_licence_number
      driving_licence_expiry
      driving_licence_state
    }
    finance_application {
      id
      stage
      partner_application_url
      finance_partner
    }
  }
}
    `;
export type GetVehicleOrderDataSubscriptionResult = Apollo.SubscriptionResult<GetVehicleOrderDataSubscription>;
export const CheckVehicleOrderIdDocument = gql`
    query CheckVehicleOrderId($orderId: uuid!) {
  vehicle_orders_by_pk(id: $orderId) {
    id
  }
}
    `;
export type CheckVehicleOrderIdQueryResult = Apollo.QueryResult<CheckVehicleOrderIdQuery, CheckVehicleOrderIdQueryVariables>;
export const GetDepositPaidOrdersDocument = gql`
    query GetDepositPaidOrders($userId: uuid!, $showroomId: uuid!) {
  vehicle_orders(
    where: {user_id: {_eq: $userId}, payments: {received_at: {_is_null: false}}, showroom_id: {_eq: $showroomId}}
  ) {
    id
    stage
    last_step
    type
    created_at
    deposit_amount
    invoicing_id
    custom_vehicle {
      production_year
      make
      model
      badge
      series
      media
    }
    stocked_vehicle {
      code
      name
      production_year
      make
      model
      badge
      series
      media
      is_published
    }
  }
}
    `;
export type GetDepositPaidOrdersQueryResult = Apollo.QueryResult<GetDepositPaidOrdersQuery, GetDepositPaidOrdersQueryVariables>;
export const VehicleOrderUpdateStockedVehicleGetStockedVehiclesDocument = gql`
    query VehicleOrderUpdateStockedVehicleGetStockedVehicles($showroomId: uuid, $pricingCode: String) {
  stocked_vehicles(
    where: {showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}, is_listed: {_eq: true}}
  ) {
    id
    useable_battery
    code
    estimated_delivery_delay
    available_from
    condition
    is_reserved
    is_sold
    media
    odometer
    range
    pricing_config(path: $pricingCode)
    name
    badge
  }
}
    `;
export type VehicleOrderUpdateStockedVehicleGetStockedVehiclesQueryResult = Apollo.QueryResult<VehicleOrderUpdateStockedVehicleGetStockedVehiclesQuery, VehicleOrderUpdateStockedVehicleGetStockedVehiclesQueryVariables>;
export const VehicleOrderUpdateVehicleGetOrderDocument = gql`
    query VehicleOrderUpdateVehicleGetOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    stage
  }
}
    `;
export type VehicleOrderUpdateVehicleGetOrderQueryResult = Apollo.QueryResult<VehicleOrderUpdateVehicleGetOrderQuery, VehicleOrderUpdateVehicleGetOrderQueryVariables>;
export const GetShowroomPartnersDocument = gql`
    query GetShowroomPartners($showroomId: uuid!) {
  partners(where: {partner_showroom: {showroom_id: {_eq: $showroomId}}}) {
    id
    logo
    name
    website
    intro
  }
}
    `;
export type GetShowroomPartnersQueryResult = Apollo.QueryResult<GetShowroomPartnersQuery, GetShowroomPartnersQueryVariables>;
export const SaveProfileDocument = gql`
    mutation SaveProfile($userId: uuid!, $user: users_set_input, $interestCodes: [String!], $interestObjects: [user_interests_insert_input!]!) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $user) {
    id
  }
  delete_user_interests(
    where: {user_id: {_eq: $userId}, interest_code: {_nin: $interestCodes}}
  ) {
    affected_rows
  }
  insert_user_interests(
    objects: $interestObjects
    on_conflict: {constraint: user_interests_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type SaveProfileMutationFn = Apollo.MutationFunction<SaveProfileMutation, SaveProfileMutationVariables>;
export type SaveProfileMutationResult = Apollo.MutationResult<SaveProfileMutation>;
export type SaveProfileMutationOptions = Apollo.BaseMutationOptions<SaveProfileMutation, SaveProfileMutationVariables>;
export const AccountProfileGetUserDocument = gql`
    query AccountProfileGetUser($hasuraUserId: uuid) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    first_name
    middle_name
    last_name
    email
    phone
    preferred_contact_method
  }
}
    `;
export type AccountProfileGetUserQueryResult = Apollo.QueryResult<AccountProfileGetUserQuery, AccountProfileGetUserQueryVariables>;
export const GetShowroomPromotionsDocument = gql`
    query GetShowroomPromotions($showroomId: uuid!) {
  promotions(where: {showrooms_promotions: {showroom_id: {_eq: $showroomId}}}) {
    id
    intro
    logo
    name
    background_image
    title
    showrooms_promotions {
      start_date
      end_date
    }
  }
}
    `;
export type GetShowroomPromotionsQueryResult = Apollo.QueryResult<GetShowroomPromotionsQuery, GetShowroomPromotionsQueryVariables>;
export const GetWishListDocument = gql`
    subscription GetWishList($userId: uuid!, $pricingCode: String) {
  wish_list(where: {user_id: {_eq: $userId}}, order_by: {updated_at: desc}) {
    id
    created_at
    updated_at
    make
    email
    model
    badge
    series
    comment
    touched
    stocked_vehicle {
      id
      name
      code
      range
      pricing_config(path: $pricingCode)
      make
      model
      badge
      media
      production_year
      odometer
      condition
      is_sold
      packs
      pack_codes
      useable_battery
      body_type
      estimated_delivery_delay
      available_from
      featured_order
      colour
      series
    }
  }
}
    `;
export type GetWishListSubscriptionResult = Apollo.SubscriptionResult<GetWishListSubscription>;
export const AccountRemoveVehicleFromWishlistDocument = gql`
    mutation AccountRemoveVehicleFromWishlist($userId: uuid!, $stockedVehicleId: uuid!) {
  delete_wish_list(
    where: {stocked_vehicle_id: {_eq: $stockedVehicleId}, user_id: {_eq: $userId}}
  ) {
    affected_rows
  }
}
    `;
export type AccountRemoveVehicleFromWishlistMutationFn = Apollo.MutationFunction<AccountRemoveVehicleFromWishlistMutation, AccountRemoveVehicleFromWishlistMutationVariables>;
export type AccountRemoveVehicleFromWishlistMutationResult = Apollo.MutationResult<AccountRemoveVehicleFromWishlistMutation>;
export type AccountRemoveVehicleFromWishlistMutationOptions = Apollo.BaseMutationOptions<AccountRemoveVehicleFromWishlistMutation, AccountRemoveVehicleFromWishlistMutationVariables>;
export const AdminArticlesUpdateGetArticleDocument = gql`
    query AdminArticlesUpdateGetArticle($id: uuid!) {
  articles_by_pk(id: $id) {
    id
    name
    body
    featured_image
    description
    slug
    type
    tags
    showroom_id
    showroom {
      https_enabled
      domain
    }
  }
}
    `;
export type AdminArticlesUpdateGetArticleQueryResult = Apollo.QueryResult<AdminArticlesUpdateGetArticleQuery, AdminArticlesUpdateGetArticleQueryVariables>;
export const AdminArticlesDeleteArticleDocument = gql`
    mutation AdminArticlesDeleteArticle($id: uuid!) {
  delete_articles_by_pk(id: $id) {
    id
  }
}
    `;
export type AdminArticlesDeleteArticleMutationFn = Apollo.MutationFunction<AdminArticlesDeleteArticleMutation, AdminArticlesDeleteArticleMutationVariables>;
export type AdminArticlesDeleteArticleMutationResult = Apollo.MutationResult<AdminArticlesDeleteArticleMutation>;
export type AdminArticlesDeleteArticleMutationOptions = Apollo.BaseMutationOptions<AdminArticlesDeleteArticleMutation, AdminArticlesDeleteArticleMutationVariables>;
export const AdminArticlesUpdateArticleDocument = gql`
    mutation AdminArticlesUpdateArticle($id: uuid!, $set: articles_set_input!) {
  update_articles_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type AdminArticlesUpdateArticleMutationFn = Apollo.MutationFunction<AdminArticlesUpdateArticleMutation, AdminArticlesUpdateArticleMutationVariables>;
export type AdminArticlesUpdateArticleMutationResult = Apollo.MutationResult<AdminArticlesUpdateArticleMutation>;
export type AdminArticlesUpdateArticleMutationOptions = Apollo.BaseMutationOptions<AdminArticlesUpdateArticleMutation, AdminArticlesUpdateArticleMutationVariables>;
export const InsertArticleDocument = gql`
    mutation InsertArticle($object: articles_insert_input!) {
  insert_articles_one(object: $object) {
    id
  }
}
    `;
export type InsertArticleMutationFn = Apollo.MutationFunction<InsertArticleMutation, InsertArticleMutationVariables>;
export type InsertArticleMutationResult = Apollo.MutationResult<InsertArticleMutation>;
export type InsertArticleMutationOptions = Apollo.BaseMutationOptions<InsertArticleMutation, InsertArticleMutationVariables>;
export const AdminArticlesDocument = gql`
    query AdminArticles {
  articles {
    id
    name
    tags
    type
    source
    created_at
  }
}
    `;
export type AdminArticlesQueryResult = Apollo.QueryResult<AdminArticlesQuery, AdminArticlesQueryVariables>;
export const AdminShowroomsUpdateGetShowroomDocument = gql`
    query AdminShowroomsUpdateGetShowroom($id: uuid!) {
  showrooms_by_pk(id: $id) {
    id
    name
    code
    domain
    phone
    email
    public_settings
    private_settings
  }
}
    `;
export type AdminShowroomsUpdateGetShowroomQueryResult = Apollo.QueryResult<AdminShowroomsUpdateGetShowroomQuery, AdminShowroomsUpdateGetShowroomQueryVariables>;
export const AdminShowroomsUpdateShowroomDocument = gql`
    mutation AdminShowroomsUpdateShowroom($id: uuid!, $set: showrooms_set_input!) {
  update_showrooms_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type AdminShowroomsUpdateShowroomMutationFn = Apollo.MutationFunction<AdminShowroomsUpdateShowroomMutation, AdminShowroomsUpdateShowroomMutationVariables>;
export type AdminShowroomsUpdateShowroomMutationResult = Apollo.MutationResult<AdminShowroomsUpdateShowroomMutation>;
export type AdminShowroomsUpdateShowroomMutationOptions = Apollo.BaseMutationOptions<AdminShowroomsUpdateShowroomMutation, AdminShowroomsUpdateShowroomMutationVariables>;
export const AdminShowroomsDocument = gql`
    query AdminShowrooms {
  showrooms {
    id
    name
    code
    domain
  }
}
    `;
export type AdminShowroomsQueryResult = Apollo.QueryResult<AdminShowroomsQuery, AdminShowroomsQueryVariables>;
export const CalculatorResultClaimSubmissionDocument = gql`
    mutation CalculatorResultClaimSubmission($submissionId: uuid!, $userId: uuid) {
  update_calculator_submission_by_pk(
    pk_columns: {id: $submissionId}
    _set: {user_id: $userId}
  ) {
    id
  }
}
    `;
export type CalculatorResultClaimSubmissionMutationFn = Apollo.MutationFunction<CalculatorResultClaimSubmissionMutation, CalculatorResultClaimSubmissionMutationVariables>;
export type CalculatorResultClaimSubmissionMutationResult = Apollo.MutationResult<CalculatorResultClaimSubmissionMutation>;
export type CalculatorResultClaimSubmissionMutationOptions = Apollo.BaseMutationOptions<CalculatorResultClaimSubmissionMutation, CalculatorResultClaimSubmissionMutationVariables>;
export const CalculatorResultsCaptureMetricsDocument = gql`
    mutation CalculatorResultsCaptureMetrics($id: uuid!, $set: calculator_submission_set_input) {
  update_calculator_submission_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type CalculatorResultsCaptureMetricsMutationFn = Apollo.MutationFunction<CalculatorResultsCaptureMetricsMutation, CalculatorResultsCaptureMetricsMutationVariables>;
export type CalculatorResultsCaptureMetricsMutationResult = Apollo.MutationResult<CalculatorResultsCaptureMetricsMutation>;
export type CalculatorResultsCaptureMetricsMutationOptions = Apollo.BaseMutationOptions<CalculatorResultsCaptureMetricsMutation, CalculatorResultsCaptureMetricsMutationVariables>;
export const CalculatorResultDocument = gql`
    query CalculatorResult($id: uuid!) {
  calculator_submission_by_pk(id: $id) {
    id
    registration_number
    state_of_registration
    driving_distance
    registration_lookup_response
    make
    model
    year
    vehicle_identifier_type
    vehicle {
      make
      model
      release_year
      engine
      body_type
      fuel_consumption_litres_per_100km
      fuel_lifecycle_co2_grams_per_km
    }
  }
}
    `;
export type CalculatorResultQueryResult = Apollo.QueryResult<CalculatorResultQuery, CalculatorResultQueryVariables>;
export const CalculatorResultGetEvOptionsDocument = gql`
    query CalculatorResultGetEvOptions($orderByClause: [known_vehicles_order_by!], $highQualityWhereClause: known_vehicles_bool_exp, $mediumQualityWhereClause: known_vehicles_bool_exp, $lowQualityWhereClause: known_vehicles_bool_exp) {
  lowQualityMatches: known_vehicles(
    where: $lowQualityWhereClause
    order_by: $orderByClause
  ) {
    ...EvOption
  }
  mediumQualityMatches: known_vehicles(
    where: $mediumQualityWhereClause
    order_by: $orderByClause
  ) {
    ...EvOption
  }
  highQualityMatches: known_vehicles(
    where: $highQualityWhereClause
    order_by: $orderByClause
  ) {
    ...EvOption
  }
}
    ${EvOptionFragmentDoc}`;
export type CalculatorResultGetEvOptionsQueryResult = Apollo.QueryResult<CalculatorResultGetEvOptionsQuery, CalculatorResultGetEvOptionsQueryVariables>;
export const SaveProgressDocument = gql`
    mutation SaveProgress($id: uuid!, $object: calculator_submission_set_input!) {
  update_calculator_submission_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
    `;
export type SaveProgressMutationFn = Apollo.MutationFunction<SaveProgressMutation, SaveProgressMutationVariables>;
export type SaveProgressMutationResult = Apollo.MutationResult<SaveProgressMutation>;
export type SaveProgressMutationOptions = Apollo.BaseMutationOptions<SaveProgressMutation, SaveProgressMutationVariables>;
export const ConfiguratorGetVehicleOrderDocument = gql`
    query ConfiguratorGetVehicleOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    custom_vehicle_id
    stage
  }
}
    `;
export type ConfiguratorGetVehicleOrderQueryResult = Apollo.QueryResult<ConfiguratorGetVehicleOrderQuery, ConfiguratorGetVehicleOrderQueryVariables>;
export const ResourceCentreSingleArticleDocument = gql`
    query ResourceCentreSingleArticle($slug: String, $showroomId: uuid!) {
  articles(where: {slug: {_eq: $slug}, showroom_id: {_eq: $showroomId}}, limit: 1) {
    id
    created_at
    body
    description
    meta_description
    reading_time
    featured_image
    name
    slug
    tags
    showroom_id
  }
}
    `;
export type ResourceCentreSingleArticleQueryResult = Apollo.QueryResult<ResourceCentreSingleArticleQuery, ResourceCentreSingleArticleQueryVariables>;
export const ResourceCentreSearchArticlesDocument = gql`
    query ResourceCentreSearchArticles($search: String, $showroomId: uuid!) {
  search_articles(
    args: {search: $search}
    where: {type: {_eq: "resources"}, showroom_id: {_eq: $showroomId}}
    order_by: {created_at: desc}
    limit: 6
  ) {
    ...ArticleFragment
  }
}
    ${ArticleFragmentFragmentDoc}`;
export type ResourceCentreSearchArticlesQueryResult = Apollo.QueryResult<ResourceCentreSearchArticlesQuery, ResourceCentreSearchArticlesQueryVariables>;
export const ResourceCentreArticlesDocument = gql`
    query ResourceCentreArticles($showroomId: uuid!) {
  articles(
    where: {type: {_eq: "resources"}, showroom_id: {_eq: $showroomId}}
    order_by: {created_at: desc}
  ) {
    ...ArticleFragment
  }
}
    ${ArticleFragmentFragmentDoc}`;
export type ResourceCentreArticlesQueryResult = Apollo.QueryResult<ResourceCentreArticlesQuery, ResourceCentreArticlesQueryVariables>;
export const FinanceApplicationSaveCurrentAddressDocument = gql`
    mutation FinanceApplicationSaveCurrentAddress($financeApplicationId: uuid!, $financeApplication: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplication
  ) {
    id
  }
}
    `;
export type FinanceApplicationSaveCurrentAddressMutationFn = Apollo.MutationFunction<FinanceApplicationSaveCurrentAddressMutation, FinanceApplicationSaveCurrentAddressMutationVariables>;
export type FinanceApplicationSaveCurrentAddressMutationResult = Apollo.MutationResult<FinanceApplicationSaveCurrentAddressMutation>;
export type FinanceApplicationSaveCurrentAddressMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationSaveCurrentAddressMutation, FinanceApplicationSaveCurrentAddressMutationVariables>;
export const DrivaFinanceApplicationCurrentAddressGetUserDocument = gql`
    query DrivaFinanceApplicationCurrentAddressGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    last_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residential_address
      years_at_residential_address
      vehicle_orders(limit: 1, order_by: {created_at: desc}) {
        delivery_address
      }
    }
  }
}
    `;
export type DrivaFinanceApplicationCurrentAddressGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationCurrentAddressGetUserQuery, DrivaFinanceApplicationCurrentAddressGetUserQueryVariables>;
export const DrivaFinanceApplicationEmploymentUpdateDocument = gql`
    mutation DrivaFinanceApplicationEmploymentUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationEmploymentUpdateMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationEmploymentUpdateMutation, DrivaFinanceApplicationEmploymentUpdateMutationVariables>;
export type DrivaFinanceApplicationEmploymentUpdateMutationResult = Apollo.MutationResult<DrivaFinanceApplicationEmploymentUpdateMutation>;
export type DrivaFinanceApplicationEmploymentUpdateMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationEmploymentUpdateMutation, DrivaFinanceApplicationEmploymentUpdateMutationVariables>;
export const DrivaFinanceApplicationEmploymentGetUserDocument = gql`
    query DrivaFinanceApplicationEmploymentGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      employment_type
      number_of_dependents
      employment_duration
    }
  }
}
    `;
export type DrivaFinanceApplicationEmploymentGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationEmploymentGetUserQuery, DrivaFinanceApplicationEmploymentGetUserQueryVariables>;
export const FinanceApplicationLivingSituatonUpdateDocument = gql`
    mutation FinanceApplicationLivingSituatonUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type FinanceApplicationLivingSituatonUpdateMutationFn = Apollo.MutationFunction<FinanceApplicationLivingSituatonUpdateMutation, FinanceApplicationLivingSituatonUpdateMutationVariables>;
export type FinanceApplicationLivingSituatonUpdateMutationResult = Apollo.MutationResult<FinanceApplicationLivingSituatonUpdateMutation>;
export type FinanceApplicationLivingSituatonUpdateMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationLivingSituatonUpdateMutation, FinanceApplicationLivingSituatonUpdateMutationVariables>;
export const DrivaFinanceApplicationLivingSituationGetUserDocument = gql`
    query DrivaFinanceApplicationLivingSituationGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residency
      visa_code
      visa_duration
      living_situation
    }
  }
}
    `;
export type DrivaFinanceApplicationLivingSituationGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationLivingSituationGetUserQuery, DrivaFinanceApplicationLivingSituationGetUserQueryVariables>;
export const DrivaFinanceApplicationLoanUpdateDocument = gql`
    mutation DrivaFinanceApplicationLoanUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationLoanUpdateMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationLoanUpdateMutation, DrivaFinanceApplicationLoanUpdateMutationVariables>;
export type DrivaFinanceApplicationLoanUpdateMutationResult = Apollo.MutationResult<DrivaFinanceApplicationLoanUpdateMutation>;
export type DrivaFinanceApplicationLoanUpdateMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationLoanUpdateMutation, DrivaFinanceApplicationLoanUpdateMutationVariables>;
export const DrivaFinanceApplicationLoanGetUserDocument = gql`
    query DrivaFinanceApplicationLoanGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      annual_net_income
      employment_type
      number_of_dependents
      requested_credit_term
      requested_credit_amount
      vehicle_orders(limit: 1, order_by: {updated_at: desc}) {
        total_trade_in_value
        pricing_config
        stocked_vehicle {
          id
          name
          code
          production_year
          make
          model
          badge
          series
          media
        }
        trade_in_plans {
          instant_valuation
        }
      }
    }
  }
}
    `;
export type DrivaFinanceApplicationLoanGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationLoanGetUserQuery, DrivaFinanceApplicationLoanGetUserQueryVariables>;
export const DrivaFinanceApplicationPersonalDetailsUpdateDocument = gql`
    mutation DrivaFinanceApplicationPersonalDetailsUpdate($userId: uuid!, $userObject: users_set_input, $financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $userObject) {
    id
  }
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationPersonalDetailsUpdateMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationPersonalDetailsUpdateMutation, DrivaFinanceApplicationPersonalDetailsUpdateMutationVariables>;
export type DrivaFinanceApplicationPersonalDetailsUpdateMutationResult = Apollo.MutationResult<DrivaFinanceApplicationPersonalDetailsUpdateMutation>;
export type DrivaFinanceApplicationPersonalDetailsUpdateMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationPersonalDetailsUpdateMutation, DrivaFinanceApplicationPersonalDetailsUpdateMutationVariables>;
export const DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderDocument = gql`
    mutation DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrder($vehicleOrderId: uuid!, $vehicleOrderObject: vehicle_orders_set_input) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: $vehicleOrderObject
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutation, DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutationVariables>;
export type DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutationResult = Apollo.MutationResult<DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutation>;
export type DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutation, DrivaFinanceApplicationPersonalDetailsUpdateVehicleOrderMutationVariables>;
export const DrivaFinanceApplicationPersonalDetailsGetUserDocument = gql`
    query DrivaFinanceApplicationPersonalDetailsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    middle_name
    last_name
    date_of_birth
    driving_licence_number
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      is_commercial
      business_identifier
      business_name
      business_duration
      vehicle_orders(limit: 1, order_by: {created_at: desc}) {
        id
        is_commercial
        business_identifier
        business_name
      }
    }
  }
}
    `;
export type DrivaFinanceApplicationPersonalDetailsGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationPersonalDetailsGetUserQuery, DrivaFinanceApplicationPersonalDetailsGetUserQueryVariables>;
export const DrivaFinanceApplicationSavePreviousAddressDocument = gql`
    mutation DrivaFinanceApplicationSavePreviousAddress($financeApplicationId: uuid!, $financeApplication: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplication
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationSavePreviousAddressMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationSavePreviousAddressMutation, DrivaFinanceApplicationSavePreviousAddressMutationVariables>;
export type DrivaFinanceApplicationSavePreviousAddressMutationResult = Apollo.MutationResult<DrivaFinanceApplicationSavePreviousAddressMutation>;
export type DrivaFinanceApplicationSavePreviousAddressMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationSavePreviousAddressMutation, DrivaFinanceApplicationSavePreviousAddressMutationVariables>;
export const DrivaFinanceApplicationPreviousAddressGetUserDocument = gql`
    query DrivaFinanceApplicationPreviousAddressGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    last_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residential_address
      years_at_residential_address
      previous_residential_address
      years_at_previous_residential_address
    }
  }
}
    `;
export type DrivaFinanceApplicationPreviousAddressGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationPreviousAddressGetUserQuery, DrivaFinanceApplicationPreviousAddressGetUserQueryVariables>;
export const DrivaFinanceApplicationResultsUpdateDocument = gql`
    mutation DrivaFinanceApplicationResultsUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationResultsUpdateMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationResultsUpdateMutation, DrivaFinanceApplicationResultsUpdateMutationVariables>;
export type DrivaFinanceApplicationResultsUpdateMutationResult = Apollo.MutationResult<DrivaFinanceApplicationResultsUpdateMutation>;
export type DrivaFinanceApplicationResultsUpdateMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationResultsUpdateMutation, DrivaFinanceApplicationResultsUpdateMutationVariables>;
export const DrivaFinanceApplicationResultsGetUserDocument = gql`
    query DrivaFinanceApplicationResultsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(limit: 1, where: {id: {_eq: $financeApplicationId}}) {
      id
      stage
      requested_credit_amount
      requested_credit_term
      partner_application_response
      vehicle_orders(order_by: {created_at: desc}, limit: 1) {
        id
        stocked_vehicle {
          production_year
          make
          model
          badge
          series
        }
      }
    }
  }
}
    `;
export type DrivaFinanceApplicationResultsGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationResultsGetUserQuery, DrivaFinanceApplicationResultsGetUserQueryVariables>;
export const DrivaFinanceApplicationStartInsertFinanceApplicationDocument = gql`
    mutation DrivaFinanceApplicationStartInsertFinanceApplication($financeApplication: finance_applications_insert_input!) {
  insert_finance_applications_one(object: $financeApplication) {
    id
  }
}
    `;
export type DrivaFinanceApplicationStartInsertFinanceApplicationMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationStartInsertFinanceApplicationMutation, DrivaFinanceApplicationStartInsertFinanceApplicationMutationVariables>;
export type DrivaFinanceApplicationStartInsertFinanceApplicationMutationResult = Apollo.MutationResult<DrivaFinanceApplicationStartInsertFinanceApplicationMutation>;
export type DrivaFinanceApplicationStartInsertFinanceApplicationMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationStartInsertFinanceApplicationMutation, DrivaFinanceApplicationStartInsertFinanceApplicationMutationVariables>;
export const DrivaFinanceApplicationStartUpdateVehicleOrderDocument = gql`
    mutation DrivaFinanceApplicationStartUpdateVehicleOrder($vehicleOrderId: uuid!, $financeApplictionId: uuid!) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: {finance_application_id: $financeApplictionId}
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationStartUpdateVehicleOrderMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationStartUpdateVehicleOrderMutation, DrivaFinanceApplicationStartUpdateVehicleOrderMutationVariables>;
export type DrivaFinanceApplicationStartUpdateVehicleOrderMutationResult = Apollo.MutationResult<DrivaFinanceApplicationStartUpdateVehicleOrderMutation>;
export type DrivaFinanceApplicationStartUpdateVehicleOrderMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationStartUpdateVehicleOrderMutation, DrivaFinanceApplicationStartUpdateVehicleOrderMutationVariables>;
export const DrivaFinanceApplicationStartGetVehicleOrderDocument = gql`
    query DrivaFinanceApplicationStartGetVehicleOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    stocked_vehicle {
      id
      name
    }
  }
}
    `;
export type DrivaFinanceApplicationStartGetVehicleOrderQueryResult = Apollo.QueryResult<DrivaFinanceApplicationStartGetVehicleOrderQuery, DrivaFinanceApplicationStartGetVehicleOrderQueryVariables>;
export const DrivaFinanceApplicationTermsUpdateDocument = gql`
    mutation DrivaFinanceApplicationTermsUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type DrivaFinanceApplicationTermsUpdateMutationFn = Apollo.MutationFunction<DrivaFinanceApplicationTermsUpdateMutation, DrivaFinanceApplicationTermsUpdateMutationVariables>;
export type DrivaFinanceApplicationTermsUpdateMutationResult = Apollo.MutationResult<DrivaFinanceApplicationTermsUpdateMutation>;
export type DrivaFinanceApplicationTermsUpdateMutationOptions = Apollo.BaseMutationOptions<DrivaFinanceApplicationTermsUpdateMutation, DrivaFinanceApplicationTermsUpdateMutationVariables>;
export const DrivaFinanceApplicationTermsPollStageDocument = gql`
    query DrivaFinanceApplicationTermsPollStage($financeApplicationId: uuid!) {
  finance_applications_by_pk(id: $financeApplicationId) {
    id
    stage
    partner_application_response
  }
}
    `;
export type DrivaFinanceApplicationTermsPollStageQueryResult = Apollo.QueryResult<DrivaFinanceApplicationTermsPollStageQuery, DrivaFinanceApplicationTermsPollStageQueryVariables>;
export const DrivaFinanceApplicationTermsGetUserDocument = gql`
    query DrivaFinanceApplicationTermsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
    }
  }
}
    `;
export type DrivaFinanceApplicationTermsGetUserQueryResult = Apollo.QueryResult<DrivaFinanceApplicationTermsGetUserQuery, DrivaFinanceApplicationTermsGetUserQueryVariables>;
export const FaqArticlesDocument = gql`
    query FAQArticles($showroomId: uuid!) {
  articles(
    where: {type: {_eq: "faq"}, showroom_id: {_eq: $showroomId}}
    order_by: {created_at: desc}
  ) {
    id
    name
    description
    tags
  }
}
    `;
export type FaqArticlesQueryResult = Apollo.QueryResult<FaqArticlesQuery, FaqArticlesQueryVariables>;
export const GenericFinanceApplicationCurrentAddressGetUserDocument = gql`
    query GenericFinanceApplicationCurrentAddressGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    last_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residential_address
      years_at_residential_address
      postal_address
      vehicle_orders(limit: 1, order_by: {created_at: desc}) {
        delivery_address
      }
    }
  }
}
    `;
export type GenericFinanceApplicationCurrentAddressGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationCurrentAddressGetUserQuery, GenericFinanceApplicationCurrentAddressGetUserQueryVariables>;
export const GenericFinanceApplicationEmploymentUpdateDocument = gql`
    mutation GenericFinanceApplicationEmploymentUpdate($financeApplicationId: uuid!, $applicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $applicationObject
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationEmploymentUpdateMutationFn = Apollo.MutationFunction<GenericFinanceApplicationEmploymentUpdateMutation, GenericFinanceApplicationEmploymentUpdateMutationVariables>;
export type GenericFinanceApplicationEmploymentUpdateMutationResult = Apollo.MutationResult<GenericFinanceApplicationEmploymentUpdateMutation>;
export type GenericFinanceApplicationEmploymentUpdateMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationEmploymentUpdateMutation, GenericFinanceApplicationEmploymentUpdateMutationVariables>;
export const GenericFinanceApplicationEmploymentGetUserDocument = gql`
    query GenericFinanceApplicationEmploymentGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      number_of_dependents
      employment_type
      employment_duration
      employer_name
      previous_employment_type
      previous_employment_duration
      previous_employer_name
      previous_occupation
      occupation
      annual_net_income
      spouse_annual_net_income
      occupation
      employer_name
      is_income_likely_to_change_within_12_months
      is_income_likely_to_change_within_12_months_details
    }
  }
}
    `;
export type GenericFinanceApplicationEmploymentGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationEmploymentGetUserQuery, GenericFinanceApplicationEmploymentGetUserQueryVariables>;
export const GenericFinanceApplicationAssetsUpdateDocument = gql`
    mutation GenericFinanceApplicationAssetsUpdate($financeApplicationId: uuid!, $applicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $applicationObject
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationAssetsUpdateMutationFn = Apollo.MutationFunction<GenericFinanceApplicationAssetsUpdateMutation, GenericFinanceApplicationAssetsUpdateMutationVariables>;
export type GenericFinanceApplicationAssetsUpdateMutationResult = Apollo.MutationResult<GenericFinanceApplicationAssetsUpdateMutation>;
export type GenericFinanceApplicationAssetsUpdateMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationAssetsUpdateMutation, GenericFinanceApplicationAssetsUpdateMutationVariables>;
export const GenericFinanceApplicationAssetsGetUserDocument = gql`
    query GenericFinanceApplicationAssetsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      income
      assets
      liabilities
      expenses
    }
  }
}
    `;
export type GenericFinanceApplicationAssetsGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationAssetsGetUserQuery, GenericFinanceApplicationAssetsGetUserQueryVariables>;
export const GenericFinanceApplicationTermsAndConditionsUpdateDocument = gql`
    mutation GenericFinanceApplicationTermsAndConditionsUpdate($financeApplicationId: uuid!, $applicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $applicationObject
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationTermsAndConditionsUpdateMutationFn = Apollo.MutationFunction<GenericFinanceApplicationTermsAndConditionsUpdateMutation, GenericFinanceApplicationTermsAndConditionsUpdateMutationVariables>;
export type GenericFinanceApplicationTermsAndConditionsUpdateMutationResult = Apollo.MutationResult<GenericFinanceApplicationTermsAndConditionsUpdateMutation>;
export type GenericFinanceApplicationTermsAndConditionsUpdateMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationTermsAndConditionsUpdateMutation, GenericFinanceApplicationTermsAndConditionsUpdateMutationVariables>;
export const GenericFinanceApplicationTermsAndConditionsGetUserDocument = gql`
    query GenericFinanceApplicationTermsAndConditionsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
    }
  }
}
    `;
export type GenericFinanceApplicationTermsAndConditionsGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationTermsAndConditionsGetUserQuery, GenericFinanceApplicationTermsAndConditionsGetUserQueryVariables>;
export const GenericFinanceApplicationLivingSituatonUpdateDocument = gql`
    mutation GenericFinanceApplicationLivingSituatonUpdate($financeApplicationId: uuid!, $applicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $applicationObject
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationLivingSituatonUpdateMutationFn = Apollo.MutationFunction<GenericFinanceApplicationLivingSituatonUpdateMutation, GenericFinanceApplicationLivingSituatonUpdateMutationVariables>;
export type GenericFinanceApplicationLivingSituatonUpdateMutationResult = Apollo.MutationResult<GenericFinanceApplicationLivingSituatonUpdateMutation>;
export type GenericFinanceApplicationLivingSituatonUpdateMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationLivingSituatonUpdateMutation, GenericFinanceApplicationLivingSituatonUpdateMutationVariables>;
export const GenericFinanceApplicationLivingSituationGetUserDocument = gql`
    query GenericFinanceApplicationLivingSituationGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residency
      visa_code
      visa_duration
      living_situation
      mortgage_value
      home_value
      relationship_status
      home_rent_or_mortgage_repayment
      number_of_dependents
      age_of_dependents
      country_of_birth
      country_of_citizenship
      secondary_country_of_citizenship
    }
  }
}
    `;
export type GenericFinanceApplicationLivingSituationGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationLivingSituationGetUserQuery, GenericFinanceApplicationLivingSituationGetUserQueryVariables>;
export const GenericFinanceApplicationLoanUpdateDocument = gql`
    mutation GenericFinanceApplicationLoanUpdate($financeApplicationId: uuid!, $applicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $applicationObject
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationLoanUpdateMutationFn = Apollo.MutationFunction<GenericFinanceApplicationLoanUpdateMutation, GenericFinanceApplicationLoanUpdateMutationVariables>;
export type GenericFinanceApplicationLoanUpdateMutationResult = Apollo.MutationResult<GenericFinanceApplicationLoanUpdateMutation>;
export type GenericFinanceApplicationLoanUpdateMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationLoanUpdateMutation, GenericFinanceApplicationLoanUpdateMutationVariables>;
export const GenericFinanceApplicationLoanGetUserDocument = gql`
    query GenericFinanceApplicationLoanGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      requested_credit_term
      requested_credit_amount
      requested_credit_balloon
      annual_driving_distance
      include_incentives_in_repayment
      vehicle_orders(limit: 1, order_by: {updated_at: desc}) {
        pricing_config
        type
        settlement_method
        stocked_vehicle {
          id
          make
          model
          badge_code
          pack_codes
          condition
        }
        custom_vehicle {
          id
          make
          model
          configurator_values
        }
      }
    }
  }
}
    `;
export type GenericFinanceApplicationLoanGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationLoanGetUserQuery, GenericFinanceApplicationLoanGetUserQueryVariables>;
export const GenericFinanceApplicationPersonalDetailsUpdateDocument = gql`
    mutation GenericFinanceApplicationPersonalDetailsUpdate($userId: uuid!, $userObject: users_set_input, $financeApplicationId: uuid!, $applicationObject: finance_applications_set_input) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $userObject) {
    id
  }
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $applicationObject
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationPersonalDetailsUpdateMutationFn = Apollo.MutationFunction<GenericFinanceApplicationPersonalDetailsUpdateMutation, GenericFinanceApplicationPersonalDetailsUpdateMutationVariables>;
export type GenericFinanceApplicationPersonalDetailsUpdateMutationResult = Apollo.MutationResult<GenericFinanceApplicationPersonalDetailsUpdateMutation>;
export type GenericFinanceApplicationPersonalDetailsUpdateMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationPersonalDetailsUpdateMutation, GenericFinanceApplicationPersonalDetailsUpdateMutationVariables>;
export const GenericFinanceApplicationPersonalDetailsGetUserDocument = gql`
    query GenericFinanceApplicationPersonalDetailsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    middle_name
    last_name
    date_of_birth
    driving_licence_number
    driving_licence_version
    driving_licence_expiry
    driving_licence_state
    driving_licence_type
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      is_commercial
      business_identifier
      business_name
      business_duration
    }
  }
}
    `;
export type GenericFinanceApplicationPersonalDetailsGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationPersonalDetailsGetUserQuery, GenericFinanceApplicationPersonalDetailsGetUserQueryVariables>;
export const GenericFinanceApplicationSavePreviousAddressDocument = gql`
    mutation GenericFinanceApplicationSavePreviousAddress($financeApplicationId: uuid!, $financeApplication: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplication
  ) {
    id
  }
}
    `;
export type GenericFinanceApplicationSavePreviousAddressMutationFn = Apollo.MutationFunction<GenericFinanceApplicationSavePreviousAddressMutation, GenericFinanceApplicationSavePreviousAddressMutationVariables>;
export type GenericFinanceApplicationSavePreviousAddressMutationResult = Apollo.MutationResult<GenericFinanceApplicationSavePreviousAddressMutation>;
export type GenericFinanceApplicationSavePreviousAddressMutationOptions = Apollo.BaseMutationOptions<GenericFinanceApplicationSavePreviousAddressMutation, GenericFinanceApplicationSavePreviousAddressMutationVariables>;
export const GenericFinanceApplicationPreviousAddressGetUserDocument = gql`
    query GenericFinanceApplicationPreviousAddressGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    last_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residential_address
      years_at_residential_address
      previous_residential_address
      years_at_previous_residential_address
    }
  }
}
    `;
export type GenericFinanceApplicationPreviousAddressGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationPreviousAddressGetUserQuery, GenericFinanceApplicationPreviousAddressGetUserQueryVariables>;
export const GenericFinanceApplicationResultsGetUserDocument = gql`
    query GenericFinanceApplicationResultsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(limit: 1, where: {id: {_eq: $financeApplicationId}}) {
      id
      vehicle_orders(order_by: {created_at: desc}, limit: 1) {
        id
      }
    }
  }
}
    `;
export type GenericFinanceApplicationResultsGetUserQueryResult = Apollo.QueryResult<GenericFinanceApplicationResultsGetUserQuery, GenericFinanceApplicationResultsGetUserQueryVariables>;
export const FinanceApplicationApplicationSubmittedThankYouGetUserDocument = gql`
    query FinanceApplicationApplicationSubmittedThankYouGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(limit: 1, where: {id: {_eq: $financeApplicationId}}) {
      id
      stage
      partner_application_url
      vehicle_orders(limit: 1, order_by: {created_at: desc}) {
        id
      }
    }
  }
}
    `;
export type FinanceApplicationApplicationSubmittedThankYouGetUserQueryResult = Apollo.QueryResult<FinanceApplicationApplicationSubmittedThankYouGetUserQuery, FinanceApplicationApplicationSubmittedThankYouGetUserQueryVariables>;
export const FinanceApplicationSaveAddressDocument = gql`
    mutation FinanceApplicationSaveAddress($financeApplicationId: uuid!, $financeApplication: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplication
  ) {
    id
  }
}
    `;
export type FinanceApplicationSaveAddressMutationFn = Apollo.MutationFunction<FinanceApplicationSaveAddressMutation, FinanceApplicationSaveAddressMutationVariables>;
export type FinanceApplicationSaveAddressMutationResult = Apollo.MutationResult<FinanceApplicationSaveAddressMutation>;
export type FinanceApplicationSaveAddressMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationSaveAddressMutation, FinanceApplicationSaveAddressMutationVariables>;
export const FinanceApplicationCurrentAddressGetUserDocument = gql`
    query FinanceApplicationCurrentAddressGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    last_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residential_address
      years_at_residential_address
      vehicle_orders(limit: 1, order_by: {created_at: desc}) {
        delivery_address
      }
    }
  }
}
    `;
export type FinanceApplicationCurrentAddressGetUserQueryResult = Apollo.QueryResult<FinanceApplicationCurrentAddressGetUserQuery, FinanceApplicationCurrentAddressGetUserQueryVariables>;
export const FinanceApplicationFinanceUpdateDocument = gql`
    mutation FinanceApplicationFinanceUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type FinanceApplicationFinanceUpdateMutationFn = Apollo.MutationFunction<FinanceApplicationFinanceUpdateMutation, FinanceApplicationFinanceUpdateMutationVariables>;
export type FinanceApplicationFinanceUpdateMutationResult = Apollo.MutationResult<FinanceApplicationFinanceUpdateMutation>;
export type FinanceApplicationFinanceUpdateMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationFinanceUpdateMutation, FinanceApplicationFinanceUpdateMutationVariables>;
export const FinanceApplicationFinanceGetUserDocument = gql`
    query FinanceApplicationFinanceGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      annual_net_income
      employment_type
      number_of_dependents
      living_situation
    }
  }
}
    `;
export type FinanceApplicationFinanceGetUserQueryResult = Apollo.QueryResult<FinanceApplicationFinanceGetUserQuery, FinanceApplicationFinanceGetUserQueryVariables>;
export const PlentiFinanceApplicationLoanUpdateDocument = gql`
    mutation PlentiFinanceApplicationLoanUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type PlentiFinanceApplicationLoanUpdateMutationFn = Apollo.MutationFunction<PlentiFinanceApplicationLoanUpdateMutation, PlentiFinanceApplicationLoanUpdateMutationVariables>;
export type PlentiFinanceApplicationLoanUpdateMutationResult = Apollo.MutationResult<PlentiFinanceApplicationLoanUpdateMutation>;
export type PlentiFinanceApplicationLoanUpdateMutationOptions = Apollo.BaseMutationOptions<PlentiFinanceApplicationLoanUpdateMutation, PlentiFinanceApplicationLoanUpdateMutationVariables>;
export const PlentiFinanceApplicationLoanGetUserDocument = gql`
    query PlentiFinanceApplicationLoanGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!, $pricingCode: String) {
  users_by_pk(id: $hasuraUserId) {
    id
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      annual_net_income
      employment_type
      number_of_dependents
      requested_credit_term
      requested_credit_amount
      vehicle_orders(limit: 1, order_by: {updated_at: desc}) {
        total_trade_in_value
        pricing_config
        deposit_amount
        stocked_vehicle {
          id
          name
          code
          production_year
          make
          model
          badge
          series
          media
        }
        trade_in_plans {
          instant_valuation
        }
      }
    }
  }
}
    `;
export type PlentiFinanceApplicationLoanGetUserQueryResult = Apollo.QueryResult<PlentiFinanceApplicationLoanGetUserQuery, PlentiFinanceApplicationLoanGetUserQueryVariables>;
export const FinanceApplicationPersonalDetailsUpdateUserDocument = gql`
    mutation FinanceApplicationPersonalDetailsUpdateUser($userId: uuid!, $userObject: users_set_input) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $userObject) {
    id
  }
}
    `;
export type FinanceApplicationPersonalDetailsUpdateUserMutationFn = Apollo.MutationFunction<FinanceApplicationPersonalDetailsUpdateUserMutation, FinanceApplicationPersonalDetailsUpdateUserMutationVariables>;
export type FinanceApplicationPersonalDetailsUpdateUserMutationResult = Apollo.MutationResult<FinanceApplicationPersonalDetailsUpdateUserMutation>;
export type FinanceApplicationPersonalDetailsUpdateUserMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationPersonalDetailsUpdateUserMutation, FinanceApplicationPersonalDetailsUpdateUserMutationVariables>;
export const FinanceApplicationPersonalDetailsGetUserDocument = gql`
    query FinanceApplicationPersonalDetailsGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    middle_name
    last_name
    date_of_birth
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
    }
  }
}
    `;
export type FinanceApplicationPersonalDetailsGetUserQueryResult = Apollo.QueryResult<FinanceApplicationPersonalDetailsGetUserQuery, FinanceApplicationPersonalDetailsGetUserQueryVariables>;
export const FinanceApplicationSavePreviousAddressDocument = gql`
    mutation FinanceApplicationSavePreviousAddress($financeApplicationId: uuid!, $financeApplication: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplication
  ) {
    id
  }
}
    `;
export type FinanceApplicationSavePreviousAddressMutationFn = Apollo.MutationFunction<FinanceApplicationSavePreviousAddressMutation, FinanceApplicationSavePreviousAddressMutationVariables>;
export type FinanceApplicationSavePreviousAddressMutationResult = Apollo.MutationResult<FinanceApplicationSavePreviousAddressMutation>;
export type FinanceApplicationSavePreviousAddressMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationSavePreviousAddressMutation, FinanceApplicationSavePreviousAddressMutationVariables>;
export const FinanceApplicationPreviousAddressGetUserDocument = gql`
    query FinanceApplicationPreviousAddressGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    phone
    first_name
    last_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
      residential_address
      years_at_residential_address
      previous_residential_address
      years_at_previous_residential_address
    }
  }
}
    `;
export type FinanceApplicationPreviousAddressGetUserQueryResult = Apollo.QueryResult<FinanceApplicationPreviousAddressGetUserQuery, FinanceApplicationPreviousAddressGetUserQueryVariables>;
export const FinanceApplicationStartInsertFinanceApplicationDocument = gql`
    mutation FinanceApplicationStartInsertFinanceApplication($financeApplication: finance_applications_insert_input!) {
  insert_finance_applications_one(object: $financeApplication) {
    id
  }
}
    `;
export type FinanceApplicationStartInsertFinanceApplicationMutationFn = Apollo.MutationFunction<FinanceApplicationStartInsertFinanceApplicationMutation, FinanceApplicationStartInsertFinanceApplicationMutationVariables>;
export type FinanceApplicationStartInsertFinanceApplicationMutationResult = Apollo.MutationResult<FinanceApplicationStartInsertFinanceApplicationMutation>;
export type FinanceApplicationStartInsertFinanceApplicationMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationStartInsertFinanceApplicationMutation, FinanceApplicationStartInsertFinanceApplicationMutationVariables>;
export const FinanceApplicationStartUpdateVehicleOrderDocument = gql`
    mutation FinanceApplicationStartUpdateVehicleOrder($vehicleOrderId: uuid!, $financeApplicationId: uuid!) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: {finance_application_id: $financeApplicationId}
  ) {
    id
  }
}
    `;
export type FinanceApplicationStartUpdateVehicleOrderMutationFn = Apollo.MutationFunction<FinanceApplicationStartUpdateVehicleOrderMutation, FinanceApplicationStartUpdateVehicleOrderMutationVariables>;
export type FinanceApplicationStartUpdateVehicleOrderMutationResult = Apollo.MutationResult<FinanceApplicationStartUpdateVehicleOrderMutation>;
export type FinanceApplicationStartUpdateVehicleOrderMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationStartUpdateVehicleOrderMutation, FinanceApplicationStartUpdateVehicleOrderMutationVariables>;
export const FinanceApplicationStartGetVehicleOrderDocument = gql`
    query FinanceApplicationStartGetVehicleOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    stocked_vehicle {
      id
      name
    }
  }
}
    `;
export type FinanceApplicationStartGetVehicleOrderQueryResult = Apollo.QueryResult<FinanceApplicationStartGetVehicleOrderQuery, FinanceApplicationStartGetVehicleOrderQueryVariables>;
export const FinanceApplicationStartGetFinanceApplicationDocument = gql`
    query FinanceApplicationStartGetFinanceApplication($financeApplicationId: uuid!) {
  finance_applications_by_pk(id: $financeApplicationId) {
    id
    finance_partner
    stage
  }
}
    `;
export type FinanceApplicationStartGetFinanceApplicationQueryResult = Apollo.QueryResult<FinanceApplicationStartGetFinanceApplicationQuery, FinanceApplicationStartGetFinanceApplicationQueryVariables>;
export const FinanceApplicationLoanUpdateDocument = gql`
    mutation FinanceApplicationLoanUpdate($financeApplicationId: uuid!, $financeApplicationObject: finance_applications_set_input) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: $financeApplicationObject
  ) {
    id
  }
}
    `;
export type FinanceApplicationLoanUpdateMutationFn = Apollo.MutationFunction<FinanceApplicationLoanUpdateMutation, FinanceApplicationLoanUpdateMutationVariables>;
export type FinanceApplicationLoanUpdateMutationResult = Apollo.MutationResult<FinanceApplicationLoanUpdateMutation>;
export type FinanceApplicationLoanUpdateMutationOptions = Apollo.BaseMutationOptions<FinanceApplicationLoanUpdateMutation, FinanceApplicationLoanUpdateMutationVariables>;
export const FinanceApplicationPollStageDocument = gql`
    query FinanceApplicationPollStage($financeApplicationId: uuid!) {
  finance_applications_by_pk(id: $financeApplicationId) {
    id
    stage
    partner_application_url
  }
}
    `;
export type FinanceApplicationPollStageQueryResult = Apollo.QueryResult<FinanceApplicationPollStageQuery, FinanceApplicationPollStageQueryVariables>;
export const FinanceApplicationTermGetUserDocument = gql`
    query FinanceApplicationTermGetUser($financeApplicationId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    first_name
    finance_applications(
      limit: 1
      where: {id: {_eq: $financeApplicationId}, stage: {_eq: "vyro-application"}}
    ) {
      id
    }
  }
}
    `;
export type FinanceApplicationTermGetUserQueryResult = Apollo.QueryResult<FinanceApplicationTermGetUserQuery, FinanceApplicationTermGetUserQueryVariables>;
export const SiteMapDocument = gql`
    query SiteMap($showroomId: uuid!) {
  articles(where: {type: {_eq: "resources"}, showroom_id: {_eq: $showroomId}}) {
    slug
    updated_at
    created_at
  }
  stockedVehicles: stocked_vehicles(
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    code
    updated_at
  }
  makes: stocked_vehicles(
    distinct_on: make
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    make
  }
  makeModels: stocked_vehicles(
    distinct_on: model
    where: {is_listed: {_eq: true}, showroom_stocked_vehicles: {showroom_id: {_eq: $showroomId}}}
  ) {
    make
    model
  }
}
    `;
export type SiteMapQueryResult = Apollo.QueryResult<SiteMapQuery, SiteMapQueryVariables>;
export const TestDriveGetUserDataDocument = gql`
    query TestDriveGetUserData($userId: uuid!) {
  users_by_pk(id: $userId) {
    id
    first_name
    last_name
    email
    phone
    driving_licence_number
    driving_licence_version
    driving_licence_expiry
    driving_licence_state
  }
}
    `;
export type TestDriveGetUserDataQueryResult = Apollo.QueryResult<TestDriveGetUserDataQuery, TestDriveGetUserDataQueryVariables>;
export const VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderDocument = gql`
    mutation VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrder($vehicleOrderId: uuid!, $vehicleOrder: vehicle_orders_set_input) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: $vehicleOrder
  ) {
    id
  }
}
    `;
export type VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutationFn = Apollo.MutationFunction<VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutation, VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutationVariables>;
export type VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutationResult = Apollo.MutationResult<VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutation>;
export type VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutationOptions = Apollo.BaseMutationOptions<VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutation, VehicleOrderActSustainableHouseholdSchemeUpdateVehicleOrderMutationVariables>;
export const VehicleOrderActSustainableHouseholdSchemeGetUserDocument = gql`
    query VehicleOrderActSustainableHouseholdSchemeGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid!) {
  users_by_pk(id: $hasuraUserId) {
    id
    vehicle_orders(
      where: {id: {_eq: $vehicleOrderId}, stage: {_eq: "online-order"}}
    ) {
      id
      phone
      total_trade_in_value
      requested_shs_credit_amount
      requested_shs_credit_term
      pricing_config
      deposit_amount
      finance_application {
        requested_credit_amount
        requested_credit_term
      }
      type
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
        configurator_code
        configurator_values
      }
      stocked_vehicle {
        id
        name
        code
        production_year
        make
        model
        badge
        series
        media
      }
      trade_in_plans {
        address_line_1
        address_line_2
        address_line_3
        city
        country_code
        country
        formatted_address
        google_place_id
        postcode
        state
        latitude
        longitude
        instant_valuation
      }
    }
  }
}
    `;
export type VehicleOrderActSustainableHouseholdSchemeGetUserQueryResult = Apollo.QueryResult<VehicleOrderActSustainableHouseholdSchemeGetUserQuery, VehicleOrderActSustainableHouseholdSchemeGetUserQueryVariables>;
export const VehicleOrderActewAglChargerAddOnDocument = gql`
    mutation VehicleOrderActewAglChargerAddOn($vehicleOrderId: uuid!, $object: vehicle_orders_set_input) {
  update_vehicle_orders_by_pk(pk_columns: {id: $vehicleOrderId}, _set: $object) {
    id
  }
}
    `;
export type VehicleOrderActewAglChargerAddOnMutationFn = Apollo.MutationFunction<VehicleOrderActewAglChargerAddOnMutation, VehicleOrderActewAglChargerAddOnMutationVariables>;
export type VehicleOrderActewAglChargerAddOnMutationResult = Apollo.MutationResult<VehicleOrderActewAglChargerAddOnMutation>;
export type VehicleOrderActewAglChargerAddOnMutationOptions = Apollo.BaseMutationOptions<VehicleOrderActewAglChargerAddOnMutation, VehicleOrderActewAglChargerAddOnMutationVariables>;
export const VehicleOrderActewAglChargerAddOnGetUserDocument = gql`
    query VehicleOrderActewAglChargerAddOnGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    vehicle_orders(where: {id: {_eq: $vehicleOrderId}}) {
      id
      phone
      stage
      pricing_config
      deposit_amount
      finance_application {
        requested_credit_amount
        requested_credit_term
      }
      type
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
        configurator_code
        configurator_values
      }
      stocked_vehicle {
        id
        name
        code
        production_year
        make
        model
        badge
        series
        media
      }
      trade_in_plans {
        address_line_1
        address_line_2
        address_line_3
        city
        country_code
        country
        formatted_address
        google_place_id
        postcode
        state
        latitude
        longitude
        instant_valuation
      }
    }
  }
}
    `;
export type VehicleOrderActewAglChargerAddOnGetUserQueryResult = Apollo.QueryResult<VehicleOrderActewAglChargerAddOnGetUserQuery, VehicleOrderActewAglChargerAddOnGetUserQueryVariables>;
export const VehicleOrderActewAglEnergyAddOnDocument = gql`
    mutation VehicleOrderActewAglEnergyAddOn($vehicleOrderId: uuid!, $object: vehicle_orders_set_input) {
  update_vehicle_orders_by_pk(pk_columns: {id: $vehicleOrderId}, _set: $object) {
    id
  }
}
    `;
export type VehicleOrderActewAglEnergyAddOnMutationFn = Apollo.MutationFunction<VehicleOrderActewAglEnergyAddOnMutation, VehicleOrderActewAglEnergyAddOnMutationVariables>;
export type VehicleOrderActewAglEnergyAddOnMutationResult = Apollo.MutationResult<VehicleOrderActewAglEnergyAddOnMutation>;
export type VehicleOrderActewAglEnergyAddOnMutationOptions = Apollo.BaseMutationOptions<VehicleOrderActewAglEnergyAddOnMutation, VehicleOrderActewAglEnergyAddOnMutationVariables>;
export const VehicleOrderActewAglEnergyAddOnGetUserDocument = gql`
    query VehicleOrderActewAglEnergyAddOnGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    vehicle_orders(where: {id: {_eq: $vehicleOrderId}}) {
      id
      phone
      stage
      pricing_config
      deposit_amount
      finance_application {
        requested_credit_amount
        requested_credit_term
      }
      type
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
        configurator_code
        configurator_values
      }
      stocked_vehicle {
        id
        name
        code
        production_year
        make
        model
        badge
        series
        media
      }
      trade_in_plans {
        address_line_1
        address_line_2
        address_line_3
        city
        country_code
        country
        formatted_address
        google_place_id
        postcode
        state
        latitude
        longitude
        instant_valuation
      }
    }
  }
}
    `;
export type VehicleOrderActewAglEnergyAddOnGetUserQueryResult = Apollo.QueryResult<VehicleOrderActewAglEnergyAddOnGetUserQuery, VehicleOrderActewAglEnergyAddOnGetUserQueryVariables>;
export const VehicleOrderAppStepGetAppActionsDocument = gql`
    query VehicleOrderAppStepGetAppActions($showroomId: uuid!, $appCode: String) {
  app_actions(
    where: {type: {_eq: "insert_vehicle_order_step"}, app_version: {showroom_apps: {showroom_id: {_eq: $showroomId}}, app: {code: {_eq: $appCode}}}}
  ) {
    type
    config
  }
}
    `;
export type VehicleOrderAppStepGetAppActionsQueryResult = Apollo.QueryResult<VehicleOrderAppStepGetAppActionsQuery, VehicleOrderAppStepGetAppActionsQueryVariables>;
export const VehicleOrderDeliverySaveDeliveryDocument = gql`
    mutation VehicleOrderDeliverySaveDelivery($vehicleOrderId: uuid!, $object: vehicle_orders_set_input) {
  update_vehicle_orders_by_pk(pk_columns: {id: $vehicleOrderId}, _set: $object) {
    id
  }
}
    `;
export type VehicleOrderDeliverySaveDeliveryMutationFn = Apollo.MutationFunction<VehicleOrderDeliverySaveDeliveryMutation, VehicleOrderDeliverySaveDeliveryMutationVariables>;
export type VehicleOrderDeliverySaveDeliveryMutationResult = Apollo.MutationResult<VehicleOrderDeliverySaveDeliveryMutation>;
export type VehicleOrderDeliverySaveDeliveryMutationOptions = Apollo.BaseMutationOptions<VehicleOrderDeliverySaveDeliveryMutation, VehicleOrderDeliverySaveDeliveryMutationVariables>;
export const VehicleOrderDeliverySaveLastEnquiryLocationDocument = gql`
    mutation VehicleOrderDeliverySaveLastEnquiryLocation($userId: uuid!, $lastEnquiryLocationId: uuid!) {
  update_users_by_pk(
    pk_columns: {id: $userId}
    _set: {last_enquiry_location_id: $lastEnquiryLocationId}
  ) {
    id
  }
}
    `;
export type VehicleOrderDeliverySaveLastEnquiryLocationMutationFn = Apollo.MutationFunction<VehicleOrderDeliverySaveLastEnquiryLocationMutation, VehicleOrderDeliverySaveLastEnquiryLocationMutationVariables>;
export type VehicleOrderDeliverySaveLastEnquiryLocationMutationResult = Apollo.MutationResult<VehicleOrderDeliverySaveLastEnquiryLocationMutation>;
export type VehicleOrderDeliverySaveLastEnquiryLocationMutationOptions = Apollo.BaseMutationOptions<VehicleOrderDeliverySaveLastEnquiryLocationMutation, VehicleOrderDeliverySaveLastEnquiryLocationMutationVariables>;
export const HandoverPageGetLocationsDocument = gql`
    query HandoverPageGetLocations($showroomId: uuid!) {
  locations(
    where: {showroom_id: {_eq: $showroomId}, is_handover_enabled: {_eq: true}}
    order_by: {display_order: asc}
  ) {
    id
    name
    address
  }
}
    `;
export type HandoverPageGetLocationsQueryResult = Apollo.QueryResult<HandoverPageGetLocationsQuery, HandoverPageGetLocationsQueryVariables>;
export const VehicleOrderDeliveryGetUserDocument = gql`
    query VehicleOrderDeliveryGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    vehicle_orders(
      where: {id: {_eq: $vehicleOrderId}, stage: {_eq: "online-order"}}
    ) {
      id
      phone
      delivery_address
      requested_shs_credit_amount
      finance_application {
        requested_credit_amount
        requested_credit_term
      }
      pricing_config
      deposit_amount
      type
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
        configurator_code
        configurator_values
      }
      stocked_vehicle {
        id
        name
        code
        production_year
        make
        model
        badge
        series
        media
      }
      trade_in_plans {
        address_line_1
        address_line_2
        address_line_3
        city
        country_code
        country
        formatted_address
        google_place_id
        postcode
        state
        latitude
        longitude
        instant_valuation
      }
    }
  }
}
    `;
export type VehicleOrderDeliveryGetUserQueryResult = Apollo.QueryResult<VehicleOrderDeliveryGetUserQuery, VehicleOrderDeliveryGetUserQueryVariables>;
export const VehicleOrderHoldingDepositThankYouDocument = gql`
    subscription VehicleOrderHoldingDepositThankYou($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    settlement_method
    stage
    invoicing_id
    total_trade_in_value
    estimated_delivery_date
    is_actewagl_charger_add_on_enquiry
    created_at
    deposit_amount
    user {
      first_name
    }
    finance_application {
      id
      driva_offer_id
      finance_partner
      partner_application_response
      requested_credit_amount
      requested_credit_term
    }
    requested_shs_credit_amount
    pricing_config
    type
    custom_vehicle {
      production_year
      make
      model
      badge
      series
      media
      configurator_code
      configurator_values
    }
    stocked_vehicle {
      name
      code
      production_year
      make
      model
      badge
      series
      media
    }
    trade_in_plans {
      id
      instant_valuation
    }
  }
}
    `;
export type VehicleOrderHoldingDepositThankYouSubscriptionResult = Apollo.SubscriptionResult<VehicleOrderHoldingDepositThankYouSubscription>;
export const VehicleOrderHowToPayDocument = gql`
    mutation VehicleOrderHowToPay($vehicleOrderId: uuid!, $object: vehicle_orders_set_input) {
  update_vehicle_orders_by_pk(pk_columns: {id: $vehicleOrderId}, _set: $object) {
    id
  }
}
    `;
export type VehicleOrderHowToPayMutationFn = Apollo.MutationFunction<VehicleOrderHowToPayMutation, VehicleOrderHowToPayMutationVariables>;
export type VehicleOrderHowToPayMutationResult = Apollo.MutationResult<VehicleOrderHowToPayMutation>;
export type VehicleOrderHowToPayMutationOptions = Apollo.BaseMutationOptions<VehicleOrderHowToPayMutation, VehicleOrderHowToPayMutationVariables>;
export const VehicleOrderHowToPayInsertFinanceApplicationDocument = gql`
    mutation VehicleOrderHowToPayInsertFinanceApplication($financeApplication: finance_applications_insert_input!) {
  insert_finance_applications_one(object: $financeApplication) {
    id
  }
}
    `;
export type VehicleOrderHowToPayInsertFinanceApplicationMutationFn = Apollo.MutationFunction<VehicleOrderHowToPayInsertFinanceApplicationMutation, VehicleOrderHowToPayInsertFinanceApplicationMutationVariables>;
export type VehicleOrderHowToPayInsertFinanceApplicationMutationResult = Apollo.MutationResult<VehicleOrderHowToPayInsertFinanceApplicationMutation>;
export type VehicleOrderHowToPayInsertFinanceApplicationMutationOptions = Apollo.BaseMutationOptions<VehicleOrderHowToPayInsertFinanceApplicationMutation, VehicleOrderHowToPayInsertFinanceApplicationMutationVariables>;
export const VehicleOrderHowToPayGetLoopitSubscriptionUrlDocument = gql`
    mutation VehicleOrderHowToPayGetLoopitSubscriptionUrl($vehicleOrderId: uuid!) {
  get_loopit_subscription_url(vehicleOrderId: $vehicleOrderId) {
    url
  }
}
    `;
export type VehicleOrderHowToPayGetLoopitSubscriptionUrlMutationFn = Apollo.MutationFunction<VehicleOrderHowToPayGetLoopitSubscriptionUrlMutation, VehicleOrderHowToPayGetLoopitSubscriptionUrlMutationVariables>;
export type VehicleOrderHowToPayGetLoopitSubscriptionUrlMutationResult = Apollo.MutationResult<VehicleOrderHowToPayGetLoopitSubscriptionUrlMutation>;
export type VehicleOrderHowToPayGetLoopitSubscriptionUrlMutationOptions = Apollo.BaseMutationOptions<VehicleOrderHowToPayGetLoopitSubscriptionUrlMutation, VehicleOrderHowToPayGetLoopitSubscriptionUrlMutationVariables>;
export const VehicleOrderHowToPayGetUserDocument = gql`
    query VehicleOrderHowToPayGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid, $maxFinanceApplicationAge: timestamptz) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    phone
    first_name
    last_name
    email
    vehicle_orders(
      where: {id: {_eq: $vehicleOrderId}, stage: {_eq: "online-order"}}
    ) {
      id
      requested_shs_credit_amount
      pricing_config
      deposit_amount
      is_commercial
      settlement_method
      finance_application {
        requested_credit_amount
        requested_credit_term
      }
      subscription_provider
      subscription_plan_id
      subscription_term
      subscription_km
      subscription_liability_protection
      type
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
        configurator_code
        configurator_values
      }
      stocked_vehicle {
        name
        code
        production_year
        make
        model
        badge
        series
        media
        condition
        badge_code
        pack_codes
      }
      trade_in_plans {
        id
        instant_valuation
      }
    }
  }
}
    `;
export type VehicleOrderHowToPayGetUserQueryResult = Apollo.QueryResult<VehicleOrderHowToPayGetUserQuery, VehicleOrderHowToPayGetUserQueryVariables>;
export const VehicleOrderInvoiceGetOrderDocument = gql`
    query VehicleOrderInvoiceGetOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    invoicing_id
    created_at
    actual_delivery_date
    settlement_method
    total_trade_in_value
    is_commercial
    business_name
    business_identifier
    pricing_config
    stocked_vehicle {
      name
      production_year
      make
      model
      badge
      series
      packs
      condition
      odometer
      vin
      engine_number
      colour
      registration_number
      build_date
      compliance_date
    }
    finance_application {
      requested_credit_amount
      requested_credit_term
      awarded_credit_amount
      awarded_credit_term
      finance_partner
    }
    user {
      first_name
      last_name
      email
      phone
      driving_licence_number
      driving_licence_state
    }
    delivery_address
    payments {
      code
      name
      net_amount
      received_amount
      received_at
    }
  }
}
    `;
export type VehicleOrderInvoiceGetOrderQueryResult = Apollo.QueryResult<VehicleOrderInvoiceGetOrderQuery, VehicleOrderInvoiceGetOrderQueryVariables>;
export const VehicleOrderManageGetOrderDocument = gql`
    subscription VehicleOrderManageGetOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    invoicing_id
    stage
    type
    created_at
    total_trade_in_value
    pricing_config
    deposit_amount
    is_commercial
    business_name
    business_identifier
    custom_vehicle {
      production_year
      make
      model
      badge
      series
      media
      configurator_values
      configurator
    }
    stocked_vehicle {
      name
      production_year
      make
      model
      badge
      series
      packs
      media
    }
    user {
      first_name
      middle_name
      last_name
      phone
      referral_count
      referral_id
      driving_licence_number
      driving_licence_expiry
      driving_licence_state
    }
    finance_application {
      id
      stage
      partner_application_url
      finance_partner
    }
  }
}
    `;
export type VehicleOrderManageGetOrderSubscriptionResult = Apollo.SubscriptionResult<VehicleOrderManageGetOrderSubscription>;
export const VehicleOrderCancelDocument = gql`
    mutation VehicleOrderCancel($id: uuid!) {
  cancel_vehicle_order(id: $id) {
    id
  }
}
    `;
export type VehicleOrderCancelMutationFn = Apollo.MutationFunction<VehicleOrderCancelMutation, VehicleOrderCancelMutationVariables>;
export type VehicleOrderCancelMutationResult = Apollo.MutationResult<VehicleOrderCancelMutation>;
export type VehicleOrderCancelMutationOptions = Apollo.BaseMutationOptions<VehicleOrderCancelMutation, VehicleOrderCancelMutationVariables>;
export const VehicleOrderCancelGetOrderDocument = gql`
    query VehicleOrderCancelGetOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    stage
    type
    invoicing_id
    stocked_vehicle {
      production_year
      make
      model
      badge
      series
    }
    custom_vehicle {
      production_year
      make
      model
      badge
      series
    }
  }
}
    `;
export type VehicleOrderCancelGetOrderQueryResult = Apollo.QueryResult<VehicleOrderCancelGetOrderQuery, VehicleOrderCancelGetOrderQueryVariables>;
export const VehicleOrderUpdateCustomerSaveDocument = gql`
    mutation VehicleOrderUpdateCustomerSave($vehicleOrderId: uuid!, $vehicleOrder: vehicle_orders_set_input, $userId: uuid!, $user: users_set_input) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: $vehicleOrder
  ) {
    id
  }
  update_users_by_pk(pk_columns: {id: $userId}, _set: $user) {
    id
  }
}
    `;
export type VehicleOrderUpdateCustomerSaveMutationFn = Apollo.MutationFunction<VehicleOrderUpdateCustomerSaveMutation, VehicleOrderUpdateCustomerSaveMutationVariables>;
export type VehicleOrderUpdateCustomerSaveMutationResult = Apollo.MutationResult<VehicleOrderUpdateCustomerSaveMutation>;
export type VehicleOrderUpdateCustomerSaveMutationOptions = Apollo.BaseMutationOptions<VehicleOrderUpdateCustomerSaveMutation, VehicleOrderUpdateCustomerSaveMutationVariables>;
export const VehicleOrderUpdateCustomerGetOrderDocument = gql`
    query VehicleOrderUpdateCustomerGetOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    stage
    delivery_address
    is_commercial
    business_identifier
    business_name
    user {
      id
      first_name
      middle_name
      last_name
      phone
      date_of_birth
      driving_licence_number
      driving_licence_expiry
      driving_licence_version
      driving_licence_state
    }
  }
}
    `;
export type VehicleOrderUpdateCustomerGetOrderQueryResult = Apollo.QueryResult<VehicleOrderUpdateCustomerGetOrderQuery, VehicleOrderUpdateCustomerGetOrderQueryVariables>;
export const VehicleOrderUpdateOrderGetOrderDocument = gql`
    query VehicleOrderUpdateOrderGetOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    id
    stage
    type
    custom_vehicle {
      id
      production_year
      make
      model
      badge
      series
      media
      configurator_code
      configurator_values
    }
    stocked_vehicle {
      id
      production_year
      make
      model
      badge
      series
      media
    }
    vehicle_order_product_variants {
      product_variant {
        id
      }
    }
  }
}
    `;
export type VehicleOrderUpdateOrderGetOrderQueryResult = Apollo.QueryResult<VehicleOrderUpdateOrderGetOrderQuery, VehicleOrderUpdateOrderGetOrderQueryVariables>;
export const VehicleOrderPersonalDetailsUpdateUserAndOrderDocument = gql`
    mutation VehicleOrderPersonalDetailsUpdateUserAndOrder($userId: uuid!, $vehicleOrderId: uuid!, $userObject: users_set_input, $vehicleOrderObject: vehicle_orders_set_input) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $userObject) {
    id
  }
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: $vehicleOrderObject
  ) {
    id
  }
}
    `;
export type VehicleOrderPersonalDetailsUpdateUserAndOrderMutationFn = Apollo.MutationFunction<VehicleOrderPersonalDetailsUpdateUserAndOrderMutation, VehicleOrderPersonalDetailsUpdateUserAndOrderMutationVariables>;
export type VehicleOrderPersonalDetailsUpdateUserAndOrderMutationResult = Apollo.MutationResult<VehicleOrderPersonalDetailsUpdateUserAndOrderMutation>;
export type VehicleOrderPersonalDetailsUpdateUserAndOrderMutationOptions = Apollo.BaseMutationOptions<VehicleOrderPersonalDetailsUpdateUserAndOrderMutation, VehicleOrderPersonalDetailsUpdateUserAndOrderMutationVariables>;
export const VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlDocument = gql`
    mutation VehicleOrderPersonalDetailsGetLoopitSubscriptionUrl($vehicleOrderId: uuid!) {
  get_loopit_subscription_url(vehicleOrderId: $vehicleOrderId) {
    url
  }
}
    `;
export type VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutationFn = Apollo.MutationFunction<VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutation, VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutationVariables>;
export type VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutationResult = Apollo.MutationResult<VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutation>;
export type VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutationOptions = Apollo.BaseMutationOptions<VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutation, VehicleOrderPersonalDetailsGetLoopitSubscriptionUrlMutationVariables>;
export const VehicleOrderPersonalDetailsGetUserDocument = gql`
    query VehicleOrderPersonalDetailsGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid, $pricingCode: String) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    phone
    first_name
    last_name
    date_of_birth
    driving_licence_number
    driving_licence_expiry
    driving_licence_state
    driving_licence_version
    vehicle_orders(
      limit: 1
      where: {id: {_eq: $vehicleOrderId}, stage: {_eq: "online-order"}}
    ) {
      id
      is_commercial
      business_identifier
      business_name
      requested_shs_credit_amount
      pricing_config
      type
      deposit_amount
      is_temporary_hold
      created_at
      finance_application {
        requested_credit_amount
        requested_credit_term
      }
      settlement_method
      stocked_vehicle {
        production_year
        make
        model
        badge
        series
        name
        code
        media
      }
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
        configurator_code
        configurator_values
      }
      trade_in_plans {
        id
        instant_valuation
      }
    }
  }
}
    `;
export type VehicleOrderPersonalDetailsGetUserQueryResult = Apollo.QueryResult<VehicleOrderPersonalDetailsGetUserQuery, VehicleOrderPersonalDetailsGetUserQueryVariables>;
export const VehicleOrderReviewPaymentUrlDocument = gql`
    subscription VehicleOrderReviewPaymentUrl($vehicleOrderId: uuid!, $paymentCode: payment_codes_enum!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    payments(where: {code: {_eq: $paymentCode}}) {
      payment_url
    }
  }
}
    `;
export type VehicleOrderReviewPaymentUrlSubscriptionResult = Apollo.SubscriptionResult<VehicleOrderReviewPaymentUrlSubscription>;
export const VehicleOrderReviewGetUserDocument = gql`
    query VehicleOrderReviewGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid, $depositPaymentCode: payment_codes_enum!) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    phone
    first_name
    last_name
    email
    created_at
    vehicle_orders(where: {id: {_eq: $vehicleOrderId}}) {
      id
      registered_owner
      total_trade_in_value
      stage
      is_actewagl_charger_add_on_enquiry
      estimated_delivery_date
      pricing_config
      type
      created_at
      deposit_amount
      is_temporary_hold
      custom_vehicle {
        production_year
        make
        model
        badge
        series
        media
      }
      stocked_vehicle {
        id
        code
        name
        media
        production_year
        make
        model
        badge
        series
        estimated_delivery_delay
        condition
        odometer
      }
      trade_in_plans {
        id
        instant_valuation
        year
        make
        model
      }
      payments(where: {code: {_eq: $depositPaymentCode}}) {
        payment_url
      }
      settlement_method
      finance_application {
        id
        finance_partner
        requested_credit_amount
        requested_credit_term
        partner_application_response
        driva_offer_id
      }
    }
  }
}
    `;
export type VehicleOrderReviewGetUserQueryResult = Apollo.QueryResult<VehicleOrderReviewGetUserQuery, VehicleOrderReviewGetUserQueryVariables>;
export const VehicleOrderStartGetStockedVehicleDocument = gql`
    query VehicleOrderStartGetStockedVehicle($stockedVehicleId: uuid!) {
  stocked_vehicles_by_pk(id: $stockedVehicleId) {
    id
    name
    code
    media
    production_year
    make
    model
    badge
    series
  }
}
    `;
export type VehicleOrderStartGetStockedVehicleQueryResult = Apollo.QueryResult<VehicleOrderStartGetStockedVehicleQuery, VehicleOrderStartGetStockedVehicleQueryVariables>;
export const VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansDocument = gql`
    mutation VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlans($vehicleOrderId: uuid) {
  update_trade_in_plans(
    where: {vehicle_order_id: {_eq: $vehicleOrderId}}
    _set: {vehicle_order_id: null}
  ) {
    affected_rows
  }
}
    `;
export type VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutationFn = Apollo.MutationFunction<VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutation, VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutationVariables>;
export type VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutationResult = Apollo.MutationResult<VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutation>;
export type VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutationOptions = Apollo.BaseMutationOptions<VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutation, VehicleOrderTradeInClearVehicleOrderIdOnTradeInPlansMutationVariables>;
export const TradeInConditionUpdateTradeInPlanDocument = gql`
    mutation TradeInConditionUpdateTradeInPlan($tradeInPlanId: uuid!, $set: trade_in_plans_set_input) {
  update_trade_in_plans_by_pk(pk_columns: {id: $tradeInPlanId}, _set: $set) {
    id
  }
}
    `;
export type TradeInConditionUpdateTradeInPlanMutationFn = Apollo.MutationFunction<TradeInConditionUpdateTradeInPlanMutation, TradeInConditionUpdateTradeInPlanMutationVariables>;
export type TradeInConditionUpdateTradeInPlanMutationResult = Apollo.MutationResult<TradeInConditionUpdateTradeInPlanMutation>;
export type TradeInConditionUpdateTradeInPlanMutationOptions = Apollo.BaseMutationOptions<TradeInConditionUpdateTradeInPlanMutation, TradeInConditionUpdateTradeInPlanMutationVariables>;
export const VehicleOrderTradeInValuationGetUserDocument = gql`
    query VehicleOrderTradeInValuationGetUser($vehicleOrderId: uuid, $hasuraUserId: uuid) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    first_name
    vehicle_orders(where: {id: {_eq: $vehicleOrderId}}) {
      id
      trade_in_plans {
        id
        title
        year
        make
        model
        has_instant_trade_in_option
        instant_valuation
        on_valuation_result_vehicle_order_id
        additional_valuations_requested_at
      }
    }
  }
}
    `;
export type VehicleOrderTradeInValuationGetUserQueryResult = Apollo.QueryResult<VehicleOrderTradeInValuationGetUserQuery, VehicleOrderTradeInValuationGetUserQueryVariables>;
export const VehicleOrderTradeInInsertTradeInPlanDocument = gql`
    mutation VehicleOrderTradeInInsertTradeInPlan($object: trade_in_plans_insert_input!) {
  insert_trade_in_plans_one(object: $object) {
    id
  }
}
    `;
export type VehicleOrderTradeInInsertTradeInPlanMutationFn = Apollo.MutationFunction<VehicleOrderTradeInInsertTradeInPlanMutation, VehicleOrderTradeInInsertTradeInPlanMutationVariables>;
export type VehicleOrderTradeInInsertTradeInPlanMutationResult = Apollo.MutationResult<VehicleOrderTradeInInsertTradeInPlanMutation>;
export type VehicleOrderTradeInInsertTradeInPlanMutationOptions = Apollo.BaseMutationOptions<VehicleOrderTradeInInsertTradeInPlanMutation, VehicleOrderTradeInInsertTradeInPlanMutationVariables>;
export const VehicleOrderTradeInUpdateTradeInPlanDocument = gql`
    mutation VehicleOrderTradeInUpdateTradeInPlan($tradeInPlanId: uuid!, $tradeInPlan: trade_in_plans_set_input) {
  update_trade_in_plans_by_pk(
    pk_columns: {id: $tradeInPlanId}
    _set: $tradeInPlan
  ) {
    id
  }
}
    `;
export type VehicleOrderTradeInUpdateTradeInPlanMutationFn = Apollo.MutationFunction<VehicleOrderTradeInUpdateTradeInPlanMutation, VehicleOrderTradeInUpdateTradeInPlanMutationVariables>;
export type VehicleOrderTradeInUpdateTradeInPlanMutationResult = Apollo.MutationResult<VehicleOrderTradeInUpdateTradeInPlanMutation>;
export type VehicleOrderTradeInUpdateTradeInPlanMutationOptions = Apollo.BaseMutationOptions<VehicleOrderTradeInUpdateTradeInPlanMutation, VehicleOrderTradeInUpdateTradeInPlanMutationVariables>;
export const VehicleOrderTradeInGetAutoGrabVehicleByRegNumberDocument = gql`
    mutation VehicleOrderTradeInGetAutoGrabVehicleByRegNumber($trade_in_plan_id: uuid!, $registration_number: String!, $state_of_registration: String) {
  get_autograb_vehicle_by_reg_number(
    trade_in_plan_id: $trade_in_plan_id
    registration_number: $registration_number
    state_of_registration: $state_of_registration
  ) {
    success
  }
}
    `;
export type VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutationFn = Apollo.MutationFunction<VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutation, VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutationVariables>;
export type VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutationResult = Apollo.MutationResult<VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutation>;
export type VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutationOptions = Apollo.BaseMutationOptions<VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutation, VehicleOrderTradeInGetAutoGrabVehicleByRegNumberMutationVariables>;
export const VehicleOrderTradeInGetValuationDocument = gql`
    mutation VehicleOrderTradeInGetValuation($tradeInPlanId: uuid!) {
  get_autograb_valuation(trade_in_plan_id: $tradeInPlanId) {
    success
  }
}
    `;
export type VehicleOrderTradeInGetValuationMutationFn = Apollo.MutationFunction<VehicleOrderTradeInGetValuationMutation, VehicleOrderTradeInGetValuationMutationVariables>;
export type VehicleOrderTradeInGetValuationMutationResult = Apollo.MutationResult<VehicleOrderTradeInGetValuationMutation>;
export type VehicleOrderTradeInGetValuationMutationOptions = Apollo.BaseMutationOptions<VehicleOrderTradeInGetValuationMutation, VehicleOrderTradeInGetValuationMutationVariables>;
export const VehicleOrderTradeInGetUserDocument = gql`
    query VehicleOrderTradeInGetUser($vehicleOrderId: uuid!, $hasuraUserId: uuid, $maxAgeTradeInPlan: timestamptz) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    trade_in_plans(
      where: {instant_valuation: {_is_null: false}, created_at: {_gte: $maxAgeTradeInPlan}, vehicle_order_id: {_is_null: true}}
      order_by: {created_at: desc}
      limit: 1
    ) {
      id
      title
      instant_valuation
      created_at
    }
  }
  vehicle_orders(
    where: {id: {_eq: $vehicleOrderId}, stage: {_eq: "online-order"}}
  ) {
    id
    phone
    pricing_config
    deposit_amount
    finance_application {
      requested_credit_amount
      requested_credit_term
    }
    type
    custom_vehicle {
      production_year
      make
      model
      badge
      series
      media
      configurator_code
      configurator_values
    }
    stocked_vehicle {
      name
      code
      production_year
      make
      model
      badge
      series
      media
    }
    trade_in_plans {
      id
      instant_valuation
    }
  }
}
    `;
export type VehicleOrderTradeInGetUserQueryResult = Apollo.QueryResult<VehicleOrderTradeInGetUserQuery, VehicleOrderTradeInGetUserQueryVariables>;
export const DefaultVehicleListDocument = gql`
    query DefaultVehicleList($showroomId: uuid!) {
  vehicle_lists(where: {showroom_id: {_eq: $showroomId}, is_default: {_eq: true}}) {
    code
  }
}
    `;
export type DefaultVehicleListQueryResult = Apollo.QueryResult<DefaultVehicleListQuery, DefaultVehicleListQueryVariables>;
export const VehicleListGetListDocument = gql`
    query VehicleListGetList($showroomId: uuid, $code: String) {
  vehicle_lists(where: {showroom_id: {_eq: $showroomId}, code: {_eq: $code}}) {
    title
    description
    header_image_url
    meta_title
    meta_description
    where_clause
    drop_in_ads
    header_text_color
    header_size
    default_settlement_method
    filter_fields(order_by: {order: asc}) {
      label
      name
      options
      location
    }
    showroom {
      public_settings
    }
  }
}
    `;
export type VehicleListGetListQueryResult = Apollo.QueryResult<VehicleListGetListQuery, VehicleListGetListQueryVariables>;
export const StockedVehicleGetVehicleDocument = gql`
    query StockedVehicleGetVehicle($stockedVehicleCode: String, $showroomCode: String, $pricingCode: String) {
  stocked_vehicles(
    where: {code: {_eq: $stockedVehicleCode}, is_published: {_eq: true}, showroom_stocked_vehicles: {showroom: {code: {_eq: $showroomCode}}}}
  ) {
    id
    name
    description
    make
    model
    code
    range
    colour
    packs
    badge_code
    pack_codes
    useable_battery
    battery_health
    media
    seats
    odometer
    fuel_type
    production_year
    is_sold
    is_reserved
    is_reserved_by
    is_illustrative_media
    condition
    estimated_delivery_delay
    available_from
    features
    body_type
    transmission
    doors
    badge
    series
    battery_type
    charge_port_ac
    charge_port_dc
    charge_port_location
    charge_power_ac
    charge_power_dc
    charge_speed_ac
    charge_speed_dc
    charge_time_ac
    charge_time_dc
    stock_id
    features_version
    additional_features
    interior
    safety_rating_authority
    safety_rating
    number_of_airbags
    length
    width
    height
    gross_weight
    boot_capacity_seats_up
    boot_capacity_seats_down
    towing_capacity_unbraked
    towing_capacity_braked
    acceleration_0_100_km
    top_speed
    torque
    drive_type
    efficiency
    motor_size
    mechanical_warranty_years
    mechanical_warranty_distance
    battery_warranty_years
    battery_warranty_distance
    accessories_warranty_years
    accessories_warranty_distance
    included_home_trickle_charger
    pricing_config(path: $pricingCode)
    registration_expiry_date
    vin
    wheel_name
    source_name
    vehicle_specification {
      id
      acceleration
      ancap_rating
      ancap_year
      fuel_type
      maximum_speed
      electric_engine_top_speed
      torque
      electric_engine_torque
      gear_type
      body_style
      electric_engine_km_range
      engine_type
    }
    location {
      id
      name
      address
    }
  }
}
    `;
export type StockedVehicleGetVehicleQueryResult = Apollo.QueryResult<StockedVehicleGetVehicleQuery, StockedVehicleGetVehicleQueryVariables>;
export const StockedVehicleGetUserDocument = gql`
    query StockedVehicleGetUser($hasuraUserId: uuid) {
  users(where: {id: {_eq: $hasuraUserId}}) {
    id
    first_name
    last_name
    phone
    vehicle_orders(limit: 1, order_by: {updated_at: desc}) {
      id
      last_step
      stocked_vehicle_id
    }
  }
}
    `;
export type StockedVehicleGetUserQueryResult = Apollo.QueryResult<StockedVehicleGetUserQuery, StockedVehicleGetUserQueryVariables>;
export const GetShowroomAppActionsServerSideDocument = gql`
    query GetShowroomAppActionsServerSide($domain: String, $types: [String!]) {
  showrooms(where: {domain: {_eq: $domain}}) {
    showroom_apps {
      installed_version {
        app_actions(where: {type: {_in: $types}}) {
          type
          config
        }
      }
      inputs {
        name
        value
      }
    }
  }
}
    `;
export type GetShowroomAppActionsServerSideQueryResult = Apollo.QueryResult<GetShowroomAppActionsServerSideQuery, GetShowroomAppActionsServerSideQueryVariables>;
export const GetShowroomDocument = gql`
    query GetShowroom($domain: String) {
  showrooms(where: {domain: {_eq: $domain}}) {
    id
    name
    code
    domain
    phone
    email
    address
    https_enabled
    login_methods
    default_pricing_code
    public_settings
    showroom_pricing_codes(order_by: {pricing_code: {short_name: asc}}) {
      pricing_code {
        code
        country
        region
        currency
        short_name
      }
    }
  }
}
    `;
export type GetShowroomQueryResult = Apollo.QueryResult<GetShowroomQuery, GetShowroomQueryVariables>;
export const GetShowroomSettingsByIdClientSideDocument = gql`
    query GetShowroomSettingsByIdClientSide($id: uuid!) {
  showrooms(where: {id: {_eq: $id}}) {
    id
  }
}
    `;
export type GetShowroomSettingsByIdClientSideQueryResult = Apollo.QueryResult<GetShowroomSettingsByIdClientSideQuery, GetShowroomSettingsByIdClientSideQueryVariables>;
export const GetShowroomSettingsServerSideDocument = gql`
    query GetShowroomSettingsServerSide($domain: String) {
  showrooms(where: {domain: {_eq: $domain}}) {
    id
  }
}
    `;
export type GetShowroomSettingsServerSideQueryResult = Apollo.QueryResult<GetShowroomSettingsServerSideQuery, GetShowroomSettingsServerSideQueryVariables>;
export const GetShowroomSettingsClientSideDocument = gql`
    query GetShowroomSettingsClientSide($domain: String) {
  showrooms(where: {domain: {_eq: $domain}}) {
    id
  }
}
    `;
export type GetShowroomSettingsClientSideQueryResult = Apollo.QueryResult<GetShowroomSettingsClientSideQuery, GetShowroomSettingsClientSideQueryVariables>;
export const UseDefaultVehicleListDocument = gql`
    query UseDefaultVehicleList($showroomId: uuid!) {
  vehicle_lists(
    where: {showroom_id: {_eq: $showroomId}}
    order_by: {is_default: desc}
    limit: 1
  ) {
    path
    is_default
  }
}
    `;
export type UseDefaultVehicleListQueryResult = Apollo.QueryResult<UseDefaultVehicleListQuery, UseDefaultVehicleListQueryVariables>;
export const SetLastFinanceApplicationStepDocument = gql`
    mutation SetLastFinanceApplicationStep($financeApplicationId: uuid!, $lastStep: String) {
  update_finance_applications_by_pk(
    pk_columns: {id: $financeApplicationId}
    _set: {last_step: $lastStep}
  ) {
    id
  }
}
    `;
export type SetLastFinanceApplicationStepMutationFn = Apollo.MutationFunction<SetLastFinanceApplicationStepMutation, SetLastFinanceApplicationStepMutationVariables>;
export type SetLastFinanceApplicationStepMutationResult = Apollo.MutationResult<SetLastFinanceApplicationStepMutation>;
export type SetLastFinanceApplicationStepMutationOptions = Apollo.BaseMutationOptions<SetLastFinanceApplicationStepMutation, SetLastFinanceApplicationStepMutationVariables>;
export const SetLastTradeInPlanStepDocument = gql`
    mutation SetLastTradeInPlanStep($tradeInPlanId: uuid!, $lastStep: String) {
  update_trade_in_plans_by_pk(
    pk_columns: {id: $tradeInPlanId}
    _set: {last_step: $lastStep}
  ) {
    id
  }
}
    `;
export type SetLastTradeInPlanStepMutationFn = Apollo.MutationFunction<SetLastTradeInPlanStepMutation, SetLastTradeInPlanStepMutationVariables>;
export type SetLastTradeInPlanStepMutationResult = Apollo.MutationResult<SetLastTradeInPlanStepMutation>;
export type SetLastTradeInPlanStepMutationOptions = Apollo.BaseMutationOptions<SetLastTradeInPlanStepMutation, SetLastTradeInPlanStepMutationVariables>;
export const SetLastVehicleOrderStepDocument = gql`
    mutation SetLastVehicleOrderStep($vehicleOrderId: uuid!, $lastStep: String) {
  update_vehicle_orders_by_pk(
    pk_columns: {id: $vehicleOrderId}
    _set: {last_step: $lastStep}
  ) {
    id
  }
}
    `;
export type SetLastVehicleOrderStepMutationFn = Apollo.MutationFunction<SetLastVehicleOrderStepMutation, SetLastVehicleOrderStepMutationVariables>;
export type SetLastVehicleOrderStepMutationResult = Apollo.MutationResult<SetLastVehicleOrderStepMutation>;
export type SetLastVehicleOrderStepMutationOptions = Apollo.BaseMutationOptions<SetLastVehicleOrderStepMutation, SetLastVehicleOrderStepMutationVariables>;
export const UseVehicleOrderStepsGetAppActionsDocument = gql`
    query UseVehicleOrderStepsGetAppActions($showroomId: uuid!) {
  app_actions(
    where: {type: {_eq: "insert_vehicle_order_step"}, app_version: {showroom_apps: {showroom_id: {_eq: $showroomId}}}}
  ) {
    type
    config
    app {
      code
    }
  }
}
    `;
export type UseVehicleOrderStepsGetAppActionsQueryResult = Apollo.QueryResult<UseVehicleOrderStepsGetAppActionsQuery, UseVehicleOrderStepsGetAppActionsQueryVariables>;
export const UseVehicleOrderStepsGetVehicleOrderDocument = gql`
    query UseVehicleOrderStepsGetVehicleOrder($vehicleOrderId: uuid!) {
  vehicle_orders_by_pk(id: $vehicleOrderId) {
    pricing_config
    type
    stocked_vehicle {
      condition
      make
      fuel_type
    }
    custom_vehicle {
      configurator_code
      configurator_values
    }
    vehicle_order_product_variants {
      product_variant {
        id
      }
    }
  }
}
    `;
export type UseVehicleOrderStepsGetVehicleOrderQueryResult = Apollo.QueryResult<UseVehicleOrderStepsGetVehicleOrderQuery, UseVehicleOrderStepsGetVehicleOrderQueryVariables>;