import { PricingConfigV2 } from "@vyro-x/pricing-client";
import { useShowroomSettings } from "../showrooms/useShowroomSettings";

type PricingSubTextProps = {
  pricingConfig: PricingConfigV2;
};

export const PricingSubText = ({ pricingConfig }: PricingSubTextProps) => {
  const settings = useShowroomSettings(['pricing_excl_on_roads_sub_text', 'pricing_drive_away_sub_text']);
  return <>{getPricingSubText(pricingConfig, settings)}</>;
};

export const getPricingSubText = (
  pricingConfig: PricingConfigV2,
  settings: {
    pricing_excl_on_roads_sub_text: string;
    pricing_drive_away_sub_text: string;
  }
): string => {
  return pricingConfig?.isExclOnRoads
    ? settings.pricing_excl_on_roads_sub_text || "Excl. Govt. charges"
    : settings.pricing_drive_away_sub_text || "";
};
